export const ExpandIcon = ({ className }: React.SVGAttributes<React.ReactSVGElement>) => {
  return (
    <svg
      className={className}
      fill="none"
      height="13"
      viewBox="0 0 12 13"
      width="12"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.579224 5.09339C0.24554 5.09339 0 4.83526 0 4.50787V1.01364C0 0.371459 0.371459 0 1.01364 0H4.50787C4.84785 0 5.09969 0.239244 5.09969 0.579224C5.09969 0.919203 4.84155 1.15215 4.50787 1.15215H3.98531L1.78174 1.02623L3.52571 2.68206L5.47114 4.6212C5.58447 4.73452 5.64113 4.88562 5.64113 5.05561C5.64113 5.40818 5.37671 5.64113 5.01784 5.64113C4.86674 5.64113 4.72823 5.59077 4.6212 5.47744L2.68206 3.52571L1.02623 1.78174L1.15215 3.97901V4.50787C1.15215 4.84155 0.912907 5.09339 0.579224 5.09339ZM7.48583 12.0126C7.15215 12.0126 6.89402 11.7733 6.89402 11.4334C6.89402 11.0934 7.15215 10.8604 7.48583 10.8604H8.01469L10.2183 10.9864L8.468 9.33054L6.52886 7.3914C6.41553 7.27807 6.35257 7.12697 6.35257 6.95698C6.35257 6.60441 6.62329 6.37146 6.97587 6.37146C7.12697 6.37146 7.27177 6.42183 7.3788 6.53515L9.31165 8.48688L10.9738 10.2308L10.8416 8.03358V7.50472C10.8416 7.17104 11.0871 6.9192 11.4145 6.9192C11.7545 6.9192 12 7.17733 12 7.50472V10.999C12 11.6411 11.6222 12.0126 10.9801 12.0126H7.48583Z"
        fill="currentColor"
      />
    </svg>
  );
};
