import { LAYER_FORMAT_TYPE, LayerFormatType } from '@/constants/layer';
import { SystemKindNodeType } from '@/stores/systemNodes';

export const getNodeIcon = (ext: string | null, kind?: SystemKindNodeType) => {
  if (!ext) {
    return kind === 'LinkNode' ? 'i-fs-file-link' : 'i-fs-file';
  }

  if (kind === 'LinkNode') {
    switch (ext.toLowerCase()) {
      case 'folder':
        return 'i-fs-folder-link';
      case 'zip':
        return 'i-fs-zip-link';
      case 'png':
        return 'i-fs-png-link';
      case 'jpg':
      case 'jpeg':
        return 'i-fs-image-link';
      case 'xslx':
        return 'i-fs-xlsx-link';
      case 'docx':
        return 'i-fs-docx-link';
      case 'pptx':
        return 'i-fs-pptx-link';
      case 'dwg':
        return 'i-fs-dwg-link';
      case 'e57':
        return 'i-fs-e57-link';
      case 'pdf':
        return 'i-fs-pdf-link';
      default:
        return 'i-fs-file-link';
    }
  } else {
    switch (ext.toLowerCase()) {
      case 'folder':
        return 'i-fs-folder';
      case 'zip':
        return 'i-fs-zip';
      case 'png':
        return 'i-fs-png';
      case 'jpg':
      case 'jpeg':
        return 'i-fs-image';
      case 'xslx':
        return 'i-fs-xlsx';
      case 'docx':
        return 'i-fs-docx';
      case 'pptx':
        return 'i-fs-pptx';
      case 'dwg':
        return 'i-fs-dwg';
      case 'e57':
        return 'i-fs-e57';
      case 'pdf':
        return 'i-fs-pdf';
      default:
        return 'i-fs-file';
    }
  }
};

export const getSystemCreatedNodeIcon = (ext: string | null, kind: SystemKindNodeType) => {
  if (!ext) {
    return kind === 'FileNode' ? 'i-fs-file-sync' : 'i-fs-folder-sync';
  }

  switch (ext.toLowerCase()) {
    case 'folder':
      return 'i-fs-folder-sync';
    case 'zip':
      return 'i-fs-zip-sync';
    case 'png':
      return 'i-fs-png-sync';
    case 'jpg':
    case 'jpeg':
      return 'i-fs-image-sync';
    case 'xslx':
      return 'i-fs-xlsx-sync';
    case 'docx':
      return 'i-fs-docx-sync';
    case 'pptx':
      return 'i-fs-pptx-sync';
    case 'dwg':
      return 'i-fs-dwg-sync';
    case 'e57':
      return 'i-fs-e57-sync';
    case 'pdf':
      return 'i-fs-pdf-sync';
    default:
      return 'i-fs-file-sync';
  }
};

export const getLayerIcon = (type: LayerFormatType) => {
  switch (type) {
    case LAYER_FORMAT_TYPE.IFC:
      return 'i-skand-ifc';
    case LAYER_FORMAT_TYPE.OBJ:
      return 'i-skand-obj';
    case LAYER_FORMAT_TYPE.POINT_CLOUD:
      return 'i-skand-pointcloud';
    case LAYER_FORMAT_TYPE.MESH_3D:
      return 'i-skand-mesh3d';
    case LAYER_FORMAT_TYPE.ORTHO_2D:
    case LAYER_FORMAT_TYPE.TERRAIN:
      return 'i-skand-ortho';
    case LAYER_FORMAT_TYPE.IMAGES:
      return 'i-skand-image';
    case LAYER_FORMAT_TYPE.DXF:
      return 'i-skand-dxf';
    case LAYER_FORMAT_TYPE.BIM_CAD_MODEL:
      return 'i-skand-bim';
    case LAYER_FORMAT_TYPE.GAUSSIAN_SPLAT:
      return 'i-skand-gaussiansplat';
  }
};
