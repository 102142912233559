import { MultiView } from '@/components/MultiView';
import { LAYER_FORMAT_TYPE } from '@/constants/layer';
import { useAnnotationTemplates } from '@/hooks/useAnnotationTemplates';
import { useFetchAnnotations } from '@/hooks/useFetchAnnotations';
import { useFetchPhotos } from '@/hooks/useFetchPhotos';
import { useFetchProcesses } from '@/hooks/useFetchProcesses';
import { useFetchSceneEntities } from '@/hooks/useFetchSceneEntities';
import { startUpdateAnnotation, useExplore } from '@/stores/explore';
import { useLayout } from '@/stores/layout';
import {
  Layer,
  LayerGroup,
  Panorama,
  Photo2D,
  ViewerState,
  addViewerEventListener,
  disableViewerGlobeUI,
  enableViewerGlobeUI,
  loadLayerModel,
  removeViewerEventListener,
  resolvePhotoGroup,
  setTargetMeasurement,
  setViewer2DSettings,
  setViewerAPI2D,
  startPanoramaWalkthrough,
  startPhotoOverlay,
  updatePhotoUrls,
  useViewer,
} from '@/stores/viewer';
import { persist } from '@/utils/Persist';
import { Sketch2 } from '@/utils/Viewer2D';
import { cn } from '@/utils/classname';
import { sortAndFilterRibbonPhotos } from '@/utils/photos';
import { getShareLinkToken } from '@/utils/shareLink';
import { MULTITHREADING_OBJ } from '@/utils/split';
import {
  CameraMotionCallback,
  CursorListener,
  GlobeMode,
  Imagery,
  ImageryBaseMap,
  Model,
  ModelNode,
  Panorama as PanoramaModel,
  PhotoCamera,
  SceneNode,
  Sketch,
  Terrain,
  Tileset,
} from '@skand/viewer-component-v2';
import { useTreatments } from '@splitsoftware/splitio-react';
import { CSSProperties, useCallback, useEffect, useRef } from 'react';
import { Color, Vector3 } from 'three';
import { ImageRibbon } from './ImageRibbon';
import { PopupLayer } from './PopupLayer';
import { Viewer2D } from './Viewer2D';
import { Viewer3D } from './Viewer3D';

export const Viewer = ({ style }: { style?: CSSProperties }) => {
  const initialCameraCallback = useRef(true);
  const initialCameraOverride = useRef(true);
  const initialImageOverride = useRef(true);

  const isShowingLeftSideBarI = useLayout(state => state.isShowingLeftSideBarI);
  const isShowingRightSideBar = useLayout(state => state.isShowingRightSideBar);
  const showLocationAlertModal = useExplore(state => state.showLocationAlertModal);
  const enabled2D = useViewer(state => state.enabled2D);
  const enabledImageRibbon = useViewer(state => state.enabledImageRibbon);
  const enabledDraw3D = useViewer(state => state.enabledDraw3D);
  const enabledPanoramaWalkthrough = useViewer(state => state.enabledPanoramaWalkthrough);
  const loadingMutex = useViewer(state => state.loadingMutex);

  const api3D = useViewer(state => state.api3D);
  const api2D = useViewer(state => state.api2D);
  const layers = useViewer(state => state.layers);

  const annotationGroups = useViewer(state => state.annotationGroups);
  const photo2DGroups = useViewer(state => state.photo2DGroups);
  const panoramaGroups = useViewer(state => state.panoramaGroups);
  const targetPhoto = useViewer(state => state.targetPhoto);
  const targetAnnotation3D = useViewer(state => state.targetAnnotation3D);
  const targetProcess = useViewer(state => state.targetProcess);

  const globeMode = useViewer(state => state.globeMode);
  const baseMapType = useViewer(state => state.baseMapType);
  const navigationMode = useViewer(state => state.navigationMode);
  const projectionMode = useViewer(state => state.projectionMode);

  const measurements = useViewer(state => state.measurements);
  const visibleLayers = useViewer(state => state.visibleLayers);
  const visibleAnnotations = useViewer(state => state.visibleAnnotations);
  const visiblePhotoGroups = useViewer(state => state.visiblePhotoGroups);
  const filteredLayers = useViewer(state => state.filteredLayers);
  const filteredAnnotations = useViewer(state => state.filteredAnnotations);
  const filteredPhotos = useViewer(state => state.filteredPhotos);

  const viewer3DSettings = useViewer(state => state.viewer3DSettings);
  const viewer2DSettings = useViewer(state => state.viewer2DSettings);
  const tilesetSettings = useViewer(state => state.tilesetSettings);
  const measurementUnit = useExplore(state => state.measurementUnit);

  const listeners = useViewer(state => state.listeners);
  const hotkeys = useExplore(state => state.hotkeys);
  const annotationDraft = useExplore(state => state.annotationDraft);
  const epsg = useExplore(state => state.epsg);

  // Get fetch handlers
  const fetchSceneEntities = useFetchSceneEntities();
  const { fetch: fetchAnnotations } = useFetchAnnotations();
  const { fetch: fetchPhotos } = useFetchPhotos();
  const { response: queryAnnotationTemplates } = useAnnotationTemplates();
  const fetchProcesses = useFetchProcesses();

  // Feature flags
  const treatment = useTreatments([MULTITHREADING_OBJ]);
  const multithreadingObjFlag = treatment[MULTITHREADING_OBJ].treatment === 'on';

  // Set the initial camera position and annotation select from persist storage
  useEffect(() => {
    if (!api3D || !api2D || !initialCameraOverride.current) return;

    const persistAnnotation = persist.get('annotation');
    const persistCameraPosition = persist.get('cameraPosition');
    const persistCameraRotation = persist.get('cameraRotation');
    const persistCameraState = persist.get('orthoMatrix');

    const annotation = annotationGroups
      .flatMap(group => group.annotations)
      .find(annotation => annotation.id === persistAnnotation);

    if (persistCameraPosition && persistCameraRotation) {
      api3D.navigation.moveTo(persistCameraPosition, persistCameraRotation);
      if (persistCameraState) {
        api3D.navigation.setOrthoMatrixParams(persistCameraState);
      }
      if (annotation?.sketch3D) {
        startUpdateAnnotation(annotation.metadata, annotation.template);
        initialCameraOverride.current = false;
      }
    } else if (persistAnnotation) {
      if (annotation?.sketch3D) {
        api3D.navigation.lookAt(annotation.sketch3D, false);
        startUpdateAnnotation(annotation.metadata, annotation.template);
        initialCameraOverride.current = false;
      } else if (annotation?.sketch2D) {
        api2D.editor.lookAt(annotation.sketch2D);
        startUpdateAnnotation(annotation.metadata, annotation.template);
        initialCameraOverride.current = false;
      }
    } else {
      initialCameraOverride.current = false;
    }
  }, [annotationGroups, api2D, api3D]);

  // Update persist storage
  useEffect(() => {
    persist.set('layers', [...visibleLayers]);
    if (annotationDraft?.annotationId) {
      persist.set('annotation', annotationDraft.annotationId);
    }

    // Persist image if both image viewer is opened and target photo is selected.
    if (targetPhoto?.id) {
      persist.set('image', targetPhoto.id);
    }

    persist.set('globe', globeMode);
    persist.set('imageryBase', baseMapType);
    persist.set('navigation', navigationMode);
    persist.set('projection', projectionMode);

    // Save the camera parameters together with the current projection mode
    if (api3D) {
      const state = api3D.navigation.getOrthoMatrixParams();
      persist.set('orthoMatrix', state);
    }

    persist.set('panoramaIconSize', viewer2DSettings.panoramaIconSize);
    persist.set('panoramaCameraHeight', viewer2DSettings.panoramaCameraHeight);
    persist.set('backgroundColor', viewer3DSettings.backgroundColor);
    persist.set('maxNetworkRequests', viewer3DSettings.maxNetworkRequests);
    persist.set('networkRequestSorting', viewer3DSettings.networkRequestSorting);
    persist.set('tileRequestCancelling', viewer3DSettings.tileRequestCancelling);
    persist.set('localCacheEnabled', viewer3DSettings.localCacheEnabled);
    persist.set('tileMemoryBudget', viewer3DSettings.tileMemoryBudget);
    persist.set('pointBudget', viewer3DSettings.pointBudget);
    persist.set('showLabels', viewer3DSettings.annotationNameVisibility);
    persist.set('showMeasurements', viewer3DSettings.annotationMeasurementVisibility);
    persist.set('edl', viewer3DSettings.eyeDomeLighting);
    persist.set('pointSizeAttenuation', viewer3DSettings.pointSizeAttenuation);
    persist.set('orthoNearPlaneClipping', viewer3DSettings.orthoNearPlaneClipping);
    persist.set('globeClipping', viewer3DSettings.globeClipping);
    persist.set('srs', epsg);

    const persistPhotoGroupColors = persist.get('photoGroupColors') ?? {};
    for (const group of photo2DGroups) {
      persistPhotoGroupColors[group.id] = group.color;
    }
    for (const group of panoramaGroups) {
      persistPhotoGroupColors[group.id] = group.color;
    }
    persist.set('photoGroupColors', persistPhotoGroupColors);

    const persistTilesetSettings = persist.get('tilesetSettings') ?? {};
    for (const [layerId, settings] of tilesetSettings) {
      persistTilesetSettings[layerId] = settings;
    }
    persist.set('tilesetSettings', persistTilesetSettings);
    persist.url.refreshURL();
  }, [
    annotationDraft,
    api3D,
    baseMapType,
    globeMode,
    navigationMode,
    projectionMode,
    tilesetSettings,
    viewer3DSettings,
    visibleLayers,
    epsg,
    targetPhoto,
    enabled2D,
    viewer2DSettings.panoramaIconSize,
    viewer2DSettings.panoramaCameraHeight,
    photo2DGroups,
    panoramaGroups,
  ]);

  // Resolve fetched layers
  const resolveLayers = (
    state: ViewerState,
    layers: Layer[],
    layerGroups: LayerGroup[],
  ): Partial<ViewerState> => {
    const oldLayersMap = new Map(state.layers.map(layer => [layer.id, layer]));
    const newLayersMap = new Map(layers.map(layer => [layer.id, layer]));

    // Destroy old layers
    for (const [id, oldLayer] of oldLayersMap) {
      if (
        !newLayersMap.has(id) &&
        (!(oldLayer.sceneNode instanceof SceneNode) || oldLayer.sceneNode instanceof ModelNode)
      ) {
        oldLayersMap.delete(id);
        oldLayer.sceneNode.destroy();
      }
    }

    // Sort layers by capture date
    layers.sort((a, b) => b.captureDate.getDate() - a.captureDate.getDate());

    // Open default layer if no layers are open, making sure all layer ids are valid
    const visibleLayers = new Set(state.visibleLayers);
    if (layers.length) {
      for (const layerId of visibleLayers) {
        const existLayer = layers.find(layer => layer.id === layerId);
        const existLayerGroup = layerGroups.find(layerGroup => layerGroup.id === layerId);
        if (!existLayer && !existLayerGroup) {
          visibleLayers.delete(layerId);
        }
      }
    }

    const shareLinkToken = getShareLinkToken();
    for (let i = 0; i < layers.length && visibleLayers.size === 0 && !shareLinkToken; i++) {
      if (
        layers[i].formatType === LAYER_FORMAT_TYPE.MESH_3D ||
        layers[i].formatType === LAYER_FORMAT_TYPE.POINT_CLOUD ||
        layers[i].formatType === LAYER_FORMAT_TYPE.BIM_CAD_MODEL ||
        layers[i].formatType === LAYER_FORMAT_TYPE.ORTHO_2D
      ) {
        visibleLayers.add(layers[i].id);
      }
    }
    if (visibleLayers.size === 0 && layers.length && !shareLinkToken) {
      visibleLayers.add(layers[0].id);
    }

    return { layers, visibleLayers };
  };

  // Destroy previous annotations before new annotations get created.
  const destroyPreviousAnnotations = useCallback(
    (prevState: ViewerState) => {
      for (const group of prevState.annotationGroups) {
        for (const annotation of group.annotations) {
          if (prevState.visibleAnnotations.has(annotation.id)) {
            annotation.sketch3D?.destroy();
            if (api2D && annotation.sketch2D) {
              api2D.editor.hide(annotation.sketch2D);
            }
          }
        }
      }
    },
    [api2D],
  );

  // Destroy previous photos before new photos get created.
  const destroyPreviousPhotos = useCallback((prevState: ViewerState) => {
    for (const group of prevState.photo2DGroups) {
      for (const photo of group.photos) {
        photo.widget?.destroy();
      }
    }
    for (const group of prevState.panoramaGroups) {
      for (const photo of group.photos) {
        photo.widget?.destroy();
      }
    }
  }, []);

  // Fetch scene entities and update viewer state
  useEffect(() => {
    fetchSceneEntities().then(viewerState => {
      useViewer.setState(prevState => {
        const resolvedLayers = resolveLayers(
          prevState,
          viewerState.layers,
          viewerState.layerGroups,
        );
        destroyPreviousAnnotations(prevState);
        destroyPreviousPhotos(prevState);
        return {
          ...viewerState,
          ...resolvedLayers,
        };
      });
    });
  }, [destroyPreviousAnnotations, destroyPreviousPhotos, fetchSceneEntities]);

  // Fetch photos and update viewer state
  useEffect(() => {
    if (
      api2D &&
      api3D &&
      (photo2DGroups.some(group => group.loadState === 'pending') ||
        panoramaGroups.some(group => group.loadState === 'pending'))
    ) {
      fetchPhotos().map(async result => {
        resolvePhotoGroup(await result);
      });
    }
  }, [api2D, api3D, fetchPhotos, panoramaGroups, photo2DGroups]);

  // Fetch annotations and update viewer state
  useEffect(() => {
    if (
      api2D &&
      api3D &&
      queryAnnotationTemplates.isSuccess &&
      annotationGroups.some(group => group.loadState === 'pending')
    ) {
      fetchAnnotations(annotationGroups);
    }
  }, [annotationGroups, api2D, api3D, fetchAnnotations, queryAnnotationTemplates.isSuccess]);

  // Fetch processes and update viewer state
  useEffect(() => {
    fetchProcesses().then(processes => {
      useViewer.setState(prevState => {
        const currTarget = prevState.targetProcess;
        const targetProcess = currTarget
          ? processes.find(process => process.id === currTarget.id)
          : null;
        return { processes, targetProcess };
      });
    });
  }, [fetchProcesses]);

  // Toggle globe map modes
  useEffect(() => {
    const cb = async () => {
      if (api3D) {
        const currentBaseMapType: ImageryBaseMap = api3D.globe.getImageryBaseMap();
        if (currentBaseMapType !== baseMapType) {
          disableViewerGlobeUI();
          await api3D.globe.setImageryBaseMap(baseMapType);
          enableViewerGlobeUI();
        }

        const currentGlobeMode: GlobeMode = api3D.globe.getGlobeMode();
        if (currentGlobeMode !== globeMode) {
          disableViewerGlobeUI();
          await api3D.globe.setGlobeMode(globeMode);
          enableViewerGlobeUI();
        }
      }
    };
    cb();
  }, [api3D, baseMapType, globeMode]);

  // Toggle visibility of layers
  useEffect(() => {
    const toggle = async () => {
      disableViewerGlobeUI();
      for (const layer of layers) {
        if (visibleLayers.has(layer.id) && !filteredLayers.has(layer.id)) {
          // Show the model and load if not loaded
          if (
            (layer.sceneNode instanceof ModelNode ||
              layer.sceneNode instanceof Terrain ||
              layer.sceneNode instanceof Imagery) &&
            !layer.sceneNode.isVisible()
          ) {
            await layer.sceneNode.show();
          } else if (layer.sceneNode.constructor === SceneNode) {
            const error = await loadLayerModel(layer);
            if (error) {
              useExplore.setState({ placeLayerTarget: layer });
              showLocationAlertModal();
            }
          }

          // Update globe mode if layer is imagery or terrain
          if (layer.sceneNode instanceof Imagery && globeMode === 'default') {
            useViewer.setState({ globeMode: 'imagery' });
          } else if (layer.sceneNode instanceof Terrain && globeMode !== 'terrain') {
            useViewer.setState({ globeMode: 'terrain' });
          }
        } else if (
          (layer.sceneNode instanceof ModelNode ||
            layer.sceneNode instanceof Terrain ||
            layer.sceneNode instanceof Imagery) &&
          layer.sceneNode.isVisible()
        ) {
          await layer.sceneNode.hide();
        }
      }
      enableViewerGlobeUI();
    };
    toggle();
  }, [
    filteredLayers,
    layers,
    showLocationAlertModal,
    visibleLayers,
    api3D,
    photo2DGroups,
    panoramaGroups,
    globeMode,
    enabled2D,
  ]);

  // Sort and filter photos
  useEffect(() => {
    sortAndFilterRibbonPhotos(
      [...photo2DGroups, ...panoramaGroups],
      visiblePhotoGroups,
      filteredPhotos,
      viewer2DSettings,
      annotationDraft === null,
      targetProcess,
    ).then(ribbonPhotos => useViewer.setState({ ribbonPhotos }));
  }, [
    annotationDraft,
    photo2DGroups,
    enabled2D,
    targetProcess,
    enabledImageRibbon,
    panoramaGroups,
    viewer2DSettings,
    visiblePhotoGroups,
    filteredPhotos,
  ]);

  // Toggle visibility of 3D annotations
  useEffect(() => {
    for (const group of annotationGroups) {
      for (const annotation of group.annotations) {
        // Hide target annotation currently being edited
        if (
          visibleAnnotations.has(annotation.id) &&
          !filteredAnnotations.has(annotation.id) &&
          annotation !== targetAnnotation3D
        ) {
          annotation.sketch3D?.show();
        } else {
          annotation.sketch3D?.hide();
        }
      }
    }
  }, [annotationGroups, filteredAnnotations, targetAnnotation3D, visibleAnnotations]);

  // Toggle visibility of 3D measurements
  useEffect(() => {
    for (const measurement of measurements) {
      measurement.sketch.showMeasurements();
      measurement.sketch.show();
    }
  }, [measurements]);

  // Register explore-defined event listeners to the viewer
  useEffect(() => {
    const models: Model[] = [];
    for (const layer of layers) {
      if (!(layer.sceneNode instanceof SceneNode && !(layer.sceneNode instanceof ModelNode))) {
        models.push(layer.sceneNode);
      }
    }
    for (const group of annotationGroups) {
      for (const annotation of group.annotations) {
        if (annotation.sketch3D) {
          models.push(annotation.sketch3D);
        }
      }
    }
    for (const group of photo2DGroups) {
      for (const photo of group.photos) {
        if (photo.widget) {
          models.push(photo.widget);
        }
      }
    }
    for (const group of panoramaGroups) {
      for (const photo of group.photos) {
        if (photo.widget) {
          models.push(photo.widget);
        }
      }
    }
    for (const measurement of measurements) {
      models.push(measurement.sketch);
    }

    api3D?.utility.setCursorHoverCallback((position, model, userData) => {
      for (const handler of listeners.onCursorHover) {
        handler(position, model, userData);
      }
    }, models);

    api3D?.utility.setCursorTapCallback((position, model, userData) => {
      for (const handler of listeners.onCursorTap) {
        handler(position, model, userData);
      }
    }, models);

    api3D?.navigation.setCameraMotionCallback((position, rotation, params) => {
      for (const handler of listeners.onCameraMotion) {
        handler(position, rotation, params);
      }
    });

    api3D?.navigation.setCameraStopCallback((position, rotation, params) => {
      for (const handler of listeners.onCameraMotionFinished) {
        handler(position, rotation, params);
      }
    });

    api2D?.editor.setListener(sketch => {
      for (const handler of listeners.onSketch2DTap) {
        handler(sketch);
      }
    });
  }, [
    annotationGroups,
    api2D,
    api3D,
    enabledPanoramaWalkthrough,
    layers,
    listeners,
    measurements,
    panoramaGroups,
    photo2DGroups,
  ]);

  // Enable 2D Viewer and Panorama with URL image field
  useEffect(() => {
    const showDefaultPhoto = async () => {
      if (!initialImageOverride.current) return;
      for (const group of photo2DGroups) {
        const photo = group.photos.find(photo => photo.id === persistImage) as Photo2D;
        if (photo) {
          initialImageOverride.current = false;
          await updatePhotoUrls(photo);
          await startPhotoOverlay(photo);
          break;
        }
      }
      for (const group of panoramaGroups) {
        const photo = group.photos.find(photo => photo.id === persistImage) as Panorama;
        if (photo) {
          initialImageOverride.current = false;
          await updatePhotoUrls(photo);
          if (photo.widget) {
            api3D?.panorama
              .getWalkthrough()
              .setPanoramas([photo.widget.getModel() as PanoramaModel]);
          }
          await startPanoramaWalkthrough(photo);
          break;
        }
      }
    };
    const persistImage = persist.get('image');
    if (persistImage) {
      showDefaultPhoto();
    }
  }, [api3D, panoramaGroups, photo2DGroups]);

  // Apply 3D viewer settings
  useEffect(() => {
    api3D?.draw.setMeasurementUnit(measurementUnit);
    api3D?.utility.setNetworkRequestSortingEnabled(viewer3DSettings.networkRequestSorting);
    api3D?.utility.setRequestCancellingEnabled(viewer3DSettings.tileRequestCancelling);
    api3D?.utility.setShowTilePrioritiesEnabled(viewer3DSettings.showRequestPriorities);
    api3D?.utility.setMaxNetworkRequestsInFlight(viewer3DSettings.maxNetworkRequests);
    api3D?.utility.setCacheEnabled(viewer3DSettings.localCacheEnabled);
    api3D?.utility.setBackgroundColor(viewer3DSettings.backgroundColor);
    api3D?.navigation.setOrthoNearPlaneClipping(viewer3DSettings.orthoNearPlaneClipping);
    api3D?.utility.setEyeDomeLightingEnabled(viewer3DSettings.eyeDomeLighting);
    api3D?.globe.setGlobeClipping(viewer3DSettings.globeClipping);
    api3D?.utility.setMultithreadedOBJEnabled(multithreadingObjFlag);
    api3D?.panorama.getWalkthrough().setSelectColor(new Color(0x0040ff));
    for (const layer of layers) {
      if (layer.sceneNode instanceof ModelNode) {
        const model = layer.sceneNode.getModel();
        if (model instanceof Tileset) {
          const style = model.getStyle();
          style.pointSizeAttenuation = viewer3DSettings.pointSizeAttenuation;
          model.setMemoryBudget(viewer3DSettings.tileMemoryBudget * (1024 * 1024));
          model.setPointBudget(viewer3DSettings.pointBudget);
          model.setStyle(style);
        }
      }
    }
    for (const group of panoramaGroups) {
      for (const photo of group.photos) {
        if (photo.widget) {
          photo.widget.setScale(
            new Vector3(
              viewer2DSettings.panoramaIconSize,
              viewer2DSettings.panoramaIconSize,
              viewer2DSettings.panoramaIconSize,
            ),
          );
          const panorama = photo.widget.getModel() as PanoramaModel;
          panorama.setCameraHeight(viewer2DSettings.panoramaCameraHeight);
          panorama.setOpacity360(viewer3DSettings.overlayOpacity);
        }
      }
    }
    for (const group of annotationGroups) {
      for (const annotation of group.annotations) {
        if (annotation.sketch3D instanceof ModelNode) {
          const sketch3D = annotation.sketch3D.getModel() as Sketch;
          sketch3D.setMeasurementUnit(measurementUnit);

          const shouldShowName =
            viewer3DSettings.annotationNameVisibility &&
            (!annotationDraft || annotation.id === annotationDraft.annotationId);
          const shouldShowMeasurements =
            viewer3DSettings.annotationMeasurementVisibility &&
            (!annotationDraft || annotation.id === annotationDraft.annotationId);

          if (shouldShowName) {
            sketch3D.showName();
          } else {
            sketch3D.hideName();
          }

          if (shouldShowMeasurements) {
            sketch3D.showMeasurements();
          } else {
            sketch3D.hideMeasurements();
          }
        }
      }
    }
    api3D?.deproject.setOpacity(viewer3DSettings.overlayOpacity);
  }, [
    annotationGroups,
    api3D,
    layers,
    measurementUnit,
    panoramaGroups,
    viewer3DSettings,
    multithreadingObjFlag,
    annotationDraft,
    viewer2DSettings.panoramaIconSize,
    viewer2DSettings.panoramaCameraHeight,
  ]);

  // Handle 3D click events
  useEffect(() => {
    const handleCursorTap: CursorListener = async (position, model) => {
      // Update sort point for image viewer
      if (!viewer2DSettings.lockSorting && !enabledPanoramaWalkthrough) {
        setViewer2DSettings({ sortPoint: position });
      }
      if (model instanceof ModelNode) {
        model = model.getModel();
      }

      // Click on a camera widget
      let contextTarget = null;
      if (model instanceof PhotoCamera && !enabledPanoramaWalkthrough) {
        for (const group of photo2DGroups) {
          const photo = group.photos.find(photo => photo.widget?.getModel() === model);
          if (photo && !loadingMutex) {
            contextTarget = photo;
            break;
          }
        }
      }
      if (model instanceof PanoramaModel && !enabledPanoramaWalkthrough) {
        for (const group of panoramaGroups) {
          const photo = group.photos.find(photo => photo.widget?.getModel() === model);
          if (photo) {
            contextTarget = photo;
            break;
          }
        }
      }
      useViewer.setState({ contextTarget });

      // Click an annotation sketch
      if (model instanceof Sketch && !enabledDraw3D) {
        for (const group of annotationGroups) {
          const annotation = group.annotations.find(query => query.sketch3D?.getModel() === model);
          if (annotation) {
            startUpdateAnnotation(annotation.metadata, annotation.template);
            break;
          }
        }
        for (const measurement of measurements) {
          if (measurement.sketch === model) {
            setTargetMeasurement(measurement);
            break;
          }
        }
      }
    };

    addViewerEventListener('onCursorTap', handleCursorTap);
    return () => {
      removeViewerEventListener('onCursorTap', handleCursorTap);
    };
  }, [
    annotationGroups,
    api3D,
    enabledPanoramaWalkthrough,
    enabledDraw3D,
    measurements,
    panoramaGroups,
    photo2DGroups,
    viewer2DSettings.lockSorting,
    loadingMutex,
  ]);

  // Handle 2D click events
  useEffect(() => {
    const handleCursorTap = (sketch: Sketch2) => {
      for (const group of annotationGroups) {
        const annotation = group.annotations.find(query => query.sketch2D === sketch);
        if (annotation) {
          startUpdateAnnotation(annotation.metadata, annotation.template);
        }
      }
    };

    addViewerEventListener('onSketch2DTap', handleCursorTap);
    return () => {
      removeViewerEventListener('onSketch2DTap', handleCursorTap);
    };
  }, [annotationGroups]);

  // Handle hover events
  useEffect(() => {
    const handleCursorHover: CursorListener = (_, model) => {
      if (model instanceof ModelNode) {
        model = model.getModel();
      }
      for (const group of photo2DGroups) {
        for (const photo of group.photos) {
          if (photo.widget) {
            const camera = photo.widget.getModel() as PhotoCamera;
            if (model === camera) {
              camera.setColor(new Color(0xffffff));
            } else {
              camera.setColor(group.color);
            }
          }
        }
      }
      for (const group of panoramaGroups) {
        for (const photo of group.photos) {
          if (photo.widget) {
            const camera = photo.widget.getModel() as PanoramaModel;
            if (model === camera) {
              camera.setColor(new Color(0x0040ff));
            } else {
              camera.setColor(group.color);
            }
          }
        }
      }
    };

    addViewerEventListener('onCursorHover', handleCursorHover);
    return () => {
      removeViewerEventListener('onCursorHover', handleCursorHover);
    };
  }, [photo2DGroups, panoramaGroups, targetPhoto]);

  // Handle keyboard shortcut events
  useEffect(() => {
    const toggle = (key: string) => {
      if (key === hotkeys.lockSorting) {
        setViewer2DSettings(prev => ({ lockSorting: !prev.lockSorting }));
      }
    };

    let shiftKeyPressed = false;
    let holdModifier = false;
    const hotkeyDownListener = (event: KeyboardEvent) => {
      if (!event.repeat) {
        if (event.key === 'Shift') {
          shiftKeyPressed = true;
        } else if (shiftKeyPressed) {
          holdModifier = true;
        }
        toggle(event.key.toUpperCase());
      }
    };
    const hotkeyUpListener = (event: KeyboardEvent) => {
      if (!event.repeat) {
        if (event.key === 'Shift') {
          shiftKeyPressed = false;
        } else if (shiftKeyPressed && holdModifier) {
          toggle(event.key.toUpperCase());
          holdModifier = false;
        }
      }
    };
    window.addEventListener('keydown', hotkeyDownListener);
    window.addEventListener('keyup', hotkeyUpListener);

    return () => {
      window.removeEventListener('keydown', hotkeyDownListener);
      window.removeEventListener('keyup', hotkeyUpListener);
    };
  }, [hotkeys]);

  // Handle camera motion finish events
  useEffect(() => {
    // Ignore first camera motion finish event
    const cb: CameraMotionCallback = (position, rotation, params) => {
      if (!initialCameraCallback.current) {
        persist.set('cameraPosition', position);
        persist.set('cameraRotation', rotation);
        persist.set('orthoMatrix', params);
        persist.url.refreshURL();
      }
      initialCameraCallback.current = false;

      // Update sort point for image viewer
      if (
        !viewer2DSettings.lockSorting &&
        viewer2DSettings.sortPoint.distanceToSquared(position) &&
        viewer2DSettings.sortMode === 'distance to camera'
      ) {
        setViewer2DSettings({ sortPoint: position });
      }
    };
    addViewerEventListener('onCameraMotionFinished', cb);
    return () => removeViewerEventListener('onCameraMotionFinished', cb);
  }, [
    enabledPanoramaWalkthrough,
    viewer2DSettings.lockSorting,
    viewer2DSettings.sortMode,
    viewer2DSettings.sortPoint,
  ]);

  return (
    <div
      className={cn(
        'h-full',
        'flex',
        'flex-1',
        isShowingLeftSideBarI && 'ml-400px',
        isShowingRightSideBar && 'mr-400px',
      )}
      style={style}
    >
      <MultiView
        viewIndex={enabled2D ? 1 : 0}
        views={[<Viewer3D key={0} />, <Viewer2D key={1} ref={setViewerAPI2D} />]}
      />
      {enabledImageRibbon && <ImageRibbon />}
      <PopupLayer />
    </div>
  );
};
