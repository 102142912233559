import { ANNOTATION_FIELD_TYPE, AnnotationShapeType } from '@/LegacyExplore/constants/annotation';
import {
  Annotation,
  AnnotationFieldInput,
  AnnotationSelectField,
  AnnotationTemplate,
  AnnotationTemplateSelectField,
} from '@/LegacyExplore/graphql/codegen/graphql';
import { Annotation as ViewerAnnotation } from '@/LegacyExplore/stores/viewer';
import { abbreviate } from '@/LegacyExplore/utils/abbreviate';
import { Geometry, Sketch } from '@skand/viewer-component-v2';
import { Color } from 'three';
import { adjectives, animals, uniqueNamesGenerator } from 'unique-names-generator';

export interface CreateCSVAnnotation {
  annotations: AnnotationFieldInput[];
  namesOfMissingFields: AnnotationFieldInput['name'][];
  incompatibleAnnotations: AnnotationFieldInput[];
}

export interface CSVAnnotation {
  annotation3d: Annotation['annotation3d'];
  fields: AnnotationFieldInput[];
  name: string;
  color: string;
}

export const inferShapeType = (isClosed: boolean, pointCount: number): AnnotationShapeType => {
  if (pointCount <= 1) return 'POINT';
  if (isClosed) return 'POLYGON';
  return 'POLYLINE';
};

export const shapeTypeToGeometry = (shapeType: AnnotationShapeType): Geometry => {
  switch (shapeType) {
    case 'POINT':
      return 'point';
    case 'POLYLINE':
      return 'lines';
    case 'POLYGON':
      return 'polygon';
  }
};

export const setColor = (viewerAnnotation: ViewerAnnotation, color: string) => {
  const model2D = viewerAnnotation.sketch2D;
  if (model2D) model2D.setColor(new Color(color));

  const model3D = viewerAnnotation.sketch3D?.getModel();
  if (model3D) (model3D as Sketch).setColor(new Color(color));
};

export const sortByOrder = <T extends null | undefined | { order?: null | undefined | number }>(
  fields: T[],
) => {
  return fields.sort((a, b) => {
    return (a?.order ?? 0) - (b?.order ?? 0);
  });
};

export const findAnnotationColorFromTemplate = (
  annotation: null | undefined | Annotation,
  template: AnnotationTemplate,
) => {
  let color: null | undefined | string = undefined;
  if (template?.colorFromField) {
    const tempField = template.fields?.find(item => item?.name === template.colorFromField);
    const annoField = annotation?.fields?.find(item => item?.fieldId === tempField?.id);
    if (tempField?.type === ANNOTATION_FIELD_TYPE.SELECT) {
      const options = (tempField as undefined | AnnotationTemplateSelectField)?.options;
      const optionId = (annoField as undefined | AnnotationSelectField)?.optionId;

      color = options?.find(item => item?.id === optionId)?.color;
    }
  }

  return color;
};

export const findAnnotationColorFromTemplates = (
  annotation: Annotation,
  templates: AnnotationTemplate[],
) => {
  const template = templates.find(item => item.id === annotation.templateId);
  return template && findAnnotationColorFromTemplate(annotation, template);
};

const randomName = () => {
  return uniqueNamesGenerator({
    dictionaries: [adjectives, animals],
    length: 2,
    separator: ' ',
  });
};

export const createRandomName = () => {
  return randomName()
    .split(' ')
    .map(w => w[0].toUpperCase() + w.substring(1).toLowerCase())
    .join(' ');
};

export const createOrderedName = (template: AnnotationTemplate, count: number) => {
  if (!template.name) return createRandomName();
  const abbr = abbreviate(template.name);
  const id = `${count}`.padStart(5, '0');
  return `${abbr} - ${id}`;
};
