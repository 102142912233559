import { useFetchImageUrls } from '@/LegacyExplore/hooks/useFetchImageUrls';
import { Photo, setTargetPhoto, useViewer } from '@/LegacyExplore/stores/viewer';
import { cn } from '@/LegacyExplore/utils/classname';
import { QuickList, QuickListAPI, QuickListItemProps } from '@skand/ui';
import { Panorama } from '@skand/viewer-component-v2';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Thumbnail } from './Thumbnail';

interface RibbonListProps {
  photos: Photo[];
}

export const RibbonList = ({ photos }: RibbonListProps) => {
  const targetPhoto = useViewer(state => state.targetPhoto);

  const [, setReady] = useState(false);
  const [quickListAPI, setQuickListAPI] = useState<QuickListAPI | null>(null);

  // List of visible photos in the ribbon
  const visiblePhotos = useMemo(() => {
    const result: Photo[] = [];

    if (!quickListAPI) return result;
    for (let i = quickListAPI.start; i < quickListAPI.stop; i++) {
      result.push(photos[i]);
    }
    return result;
  }, [photos, quickListAPI]);

  const noUrlPhotos = useMemo(
    () => visiblePhotos.filter(photo => !photo.url).map(photo => photo.id),
    [visiblePhotos],
  );
  const fetchUrls = useFetchImageUrls(noUrlPhotos, false);

  const noThumbnailPhotos = useMemo(
    () => visiblePhotos.filter(photo => !photo.thumbnailUrl).map(photo => photo.id),
    [visiblePhotos],
  );
  const fetchThumbnailUrls = useFetchImageUrls(noThumbnailPhotos, true);

  // Fetch urls in bulk for photos in the sublist
  useEffect(() => {
    const fetchPhotoUrls = async () => {
      setReady(false);
      const urls: Map<string, string> = noUrlPhotos.length ? await fetchUrls() : new Map();
      const thumbnailUrls: Map<string, string> = noThumbnailPhotos.length
        ? await fetchThumbnailUrls()
        : new Map();

      for (const photo of photos) {
        const url = urls.get(photo.id);
        const thumbnailUrl = thumbnailUrls.get(photo.id);

        const widget = photo.widget?.getModel();
        if (url) {
          photo.url = url;
          if (widget instanceof Panorama && !photo.tileset) {
            widget.setUrl(url);
          }
        }
        if (thumbnailUrl) {
          photo.thumbnailUrl = thumbnailUrl;
          if (widget instanceof Panorama) {
            widget.setThumbnailUrl(thumbnailUrl);
          }
        }
      }
      setReady(true);
    };
    fetchPhotoUrls();
  }, [fetchThumbnailUrls, fetchUrls, noThumbnailPhotos, noUrlPhotos, photos]);

  // Scroll to selected photo
  useEffect(() => {
    const index = photos.findIndex(photo => photo === targetPhoto);
    if (quickListAPI) {
      quickListAPI.scrollTo(index * quickListAPI.itemSize);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [photos, targetPhoto]);

  // Handler for keyboard input
  const onKeyDown = (event: React.KeyboardEvent) => {
    event.preventDefault();
    const { key } = event;
    const index = photos.findIndex(photo => photo === targetPhoto);
    if (index < 0) {
      return;
    } else if (key === 'ArrowUp' && index > 0) {
      setTargetPhoto(photos[index - 1]);
    } else if (key === 'ArrowDown' && index < photos.length - 1) {
      setTargetPhoto(photos[index + 1]);
    }
  };

  // List item component wrapper
  const ListItem = useCallback(
    ({ index }: QuickListItemProps) => {
      const photo = photos[index];
      return (
        <div key={`photo-${index}`}>
          <Thumbnail photo={photo} />
        </div>
      );
    },
    [photos],
  );

  return (
    <div className={cn('h-full overflow-scroll w-full mt-2')} onKeyDown={onKeyDown} tabIndex={0}>
      <QuickList itemCount={photos.length} ref={setQuickListAPI}>
        {ListItem}
      </QuickList>
    </div>
  );
};
