import { UserV2 } from '@/graphql/codegen/graphql';
export interface User {
  type: 'user';
  id: string;
  accountId: string;
  firstName: string;
  lastName: string;
  email: string;
  isSuperAdmin?: boolean | null;
}

export const displayName = (user: UserV2) =>
  user?.firstName && user?.lastName ? `${user?.firstName} ${user?.lastName}` : 'Unknown';
