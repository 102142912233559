import { Button, cn, Modal } from '@skand/ui';

export interface PlayerData {
  id: string;
  name: string;
  score: number;
  lives: number;
}

interface InfoCardProps {
  gameId: string;
  players: PlayerData[];
  onLeave: () => void;
  onClose: () => void;
}

export const InfoCard = ({ gameId, players, onLeave, onClose }: InfoCardProps) => {
  return (
    <Modal className="pointer-events-auto h-75% w-75% p-4">
      <></>
      <div className="h-full w-full flex flex-grow-1 flex-col justify-between">
        <div className="flex items-center justify-between">
          <p className="color-neutral-800 typo-text-l">Players</p>
          <div
            className={cn(
              'text-12px',
              'i-skand-close',
              'transform-rotate-90',
              'text-neutral-400',
              'hover:text-neutral-600',
              'cursor-pointer',
            )}
            onClick={onClose}
          />
        </div>
        <hr className="mt-4" />
        <div
          className={cn(
            'w-full',
            'overflow-scroll',
            'color-neutral-8',
            'typo-text-m',
            'flex-grow-2',
          )}
        >
          <div className={cn('w-full', 'flex', 'flex-row', 'justify-between', 'p-4')}>
            <div className="flex-grow-2">Name</div>
            <div className="flex-grow-1">Score</div>
            <div className="flex-grow-1">Lives</div>
          </div>
          <div className={cn('color-neutral-6', 'typo-text-m')}>
            {players.map((row, i) => (
              <div
                className={cn(
                  'w-full',
                  'flex',
                  'flex-row',
                  'justify-between',
                  'p-4',
                  'bg-neutral-1',
                )}
                key={i}
              >
                <div className="flex-grow-2">{row.name}</div>
                <div className="flex-grow-1">{row.score}</div>
                <div className="flex-grow-1">{row.lives}</div>
              </div>
            ))}
          </div>
        </div>

        <div className={cn('flex', 'flex-row', 'pt-4', 'gap-4')}>
          <div
            className={cn(
              'flex-1',
              'p-x-4',
              'p-y-2',
              'rounded-2',
              'typo-text-m',
              'border-solid',
              'border-2',
              'border-neutral-500',
              'color-neutral-6',
            )}
          >
            Room Code: {gameId}
          </div>
          <Button className={cn('flex-1')} filled onClick={onLeave} primary size="m">
            Leave
          </Button>
        </div>
      </div>
    </Modal>
  );
};
