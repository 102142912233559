import { Checkbox } from '@/components/Checkbox';
import { Photo, setImageViewerSettings, useViewer } from '@/stores/viewer';
import { cn } from '@/utils/classname';
import { Button, Input, Slider } from '@skand/ui';
import { useState } from 'react';

export interface SettingsMenuProps {
  currentTab: Photo['type'];
  closeModal: () => void;
}

export const SettingsMenu = ({ currentTab, closeModal }: SettingsMenuProps) => {
  const photo2DViewerSettings = useViewer(state => state.photo2DViewerSettings);
  const panoramaViewerSettings = useViewer(state => state.panoramaViewerSettings);
  const viewerSettings = currentTab === 'photo2D' ? photo2DViewerSettings : panoramaViewerSettings;

  const [count, setCount] = useState(viewerSettings.count);
  const [showAll, setShowAll] = useState(viewerSettings.showAll);
  const [sortWeight, setSortWeight] = useState(viewerSettings.sortWeight);
  const [gapSize, setGapSize] = useState(viewerSettings.gapSize);

  // Handle toggling lock sorting
  const setLockSorting = (lockSorting: boolean) => {
    setImageViewerSettings({ lockSorting }, currentTab);
  };

  // Handle toggling show cameras
  const setShowCameras = (showCameras: boolean) => {
    setImageViewerSettings({ showCameras }, currentTab);
  };

  // Handle toggling auto orient 3D
  const setAutoOrient3D = (autoOrient3D: boolean) => {
    setImageViewerSettings({ autoOrient3D }, currentTab);
  };

  // Handle applying count settings
  const handleApply = () => {
    setImageViewerSettings({ count, showAll, sortWeight, gapSize }, currentTab);
    closeModal();
  };

  return (
    <div className={cn('relative w-auto')}>
      <div
        className={cn(
          'flex flex-col p-3 absolute z1 p-1 w-full  bg-neutral-100 rounded-lg border border-1 border-neutral-400 border-solid',
        )}
      >
        <div
          className="i-skand-close absolute right-3 h-12px w-12px cursor-pointer color-neutral-400"
          onClick={closeModal}
        />
        <div>
          <p className={cn('typo-text-medium text-neutral-800')}>
            {currentTab === 'photo2D' ? '2D image settings' : 'Panoramic image settings'}
          </p>
        </div>
        <div
          className={cn('flex flex-row gap-4 mt-12px mb-12px justify-between items-center pr-1')}
        >
          <div className="flex-1">
            <Input
              label="Number of images"
              onChange={value => setCount(value.length ? parseInt(value) : 0)}
              type="number"
              value={count}
            />
          </div>
          <Checkbox checked={showAll} setToggleCheckbox={() => setShowAll(!showAll)} />
          <p className={cn('typo-text-small text-neutral-800')}>All</p>
        </div>
        <div className={cn('flex flex-row mb-8px')}>
          <p className={cn('typo-text-small text-neutral-800')}>Distance angle weight</p>
          <p className={cn('ml-1 typo-text-small text-neutral-400')}>{'(0-1)'}</p>
        </div>
        <div className="flex items-center gap-2">
          <Slider
            className="flex-1"
            max={1}
            min={0}
            onValueChange={e => setSortWeight(e[0])}
            step={0.01}
            value={[sortWeight]}
          />
          <div className="w-fit border border-1 border-neutral-400 rounded border-solid px-8px py-4px">
            <input
              className={cn(
                'w-32px flex items-center justify-center border-none text-center text-neutral-800 outline-none typo-text-small',
                '[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none',
              )}
              max={1}
              min={0}
              onChange={e => setSortWeight(parseFloat(e.target.value))}
              step={0.01}
              type="number"
              value={sortWeight}
            />
          </div>
        </div>
        <div className={cn('flex flex-row mb-8px')}>
          <p className={cn('typo-text-small text-neutral-800')}>Minimum gap distance in meters</p>
          <p className={cn('ml-1 typo-text-small text-neutral-400')}>{'(0-50)'}</p>
        </div>
        <div className="flex items-center gap-2">
          <Slider
            className="flex-1"
            max={50}
            min={0}
            onValueChange={e => setGapSize(e[0])}
            step={1}
            value={[gapSize]}
          />
          <div className="w-fit border border-1 border-neutral-400 rounded border-solid px-8px py-4px">
            <input
              className={cn(
                'w-32px flex items-center justify-center border-none text-center text-neutral-800 outline-none typo-text-small',
                '[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none',
              )}
              max={50}
              min={0}
              onChange={e => setGapSize(parseFloat(e.target.value))}
              step={1}
              type="number"
              value={gapSize}
            />
          </div>
        </div>
        <div className={cn('flex flex-row mt-12px self-start items-center')}>
          <Checkbox
            checked={viewerSettings.lockSorting}
            setToggleCheckbox={() => setLockSorting(!viewerSettings.lockSorting)}
          />
          <p className={cn('typo-text-small text-neutral-800 ml-8px')}>Lock sorting</p>
          <p className={cn('ml-1 typo-text-small text-neutral-400')}>{'(Z)'}</p>
        </div>
        <div className={cn('flex flex-row mt-12px self-start items-center')}>
          <Checkbox
            checked={viewerSettings.showCameras}
            setToggleCheckbox={() => setShowCameras(!viewerSettings.showCameras)}
          />
          <p className={cn('typo-text-small text-neutral-800 ml-8px')}>Show cameras</p>
          <p className={cn('ml-1 typo-text-small text-neutral-400')}>{'(X)'}</p>
        </div>
        <div className={cn('flex flex-row mb-12px mt-12px self-start items-center')}>
          <Checkbox
            checked={viewerSettings.autoOrient3D}
            setToggleCheckbox={() => setAutoOrient3D(!viewerSettings.autoOrient3D)}
          />
          <p className={cn('typo-text-small text-neutral-800 ml-8px')}>Auto-orient 3D scene</p>
          <p className={cn('ml-1 typo-text-small text-neutral-400')}>{'(C)'}</p>
        </div>

        <div className={cn('flex gap-2')}>
          <Button className="w-full" onClick={closeModal} size="s">
            Cancel
          </Button>

          <Button className="w-full" filled onClick={handleApply} primary size="s">
            Apply
          </Button>
        </div>
      </div>
    </div>
  );
};
