import { downloadStore } from '@/stores/downloadStore';
import { cn } from '@/utils/classname';
import { useAtomValue } from 'jotai';
import { DownloadTaskManagerRow } from './DownloadTaskManagerRow';

export const DownloaderV2 = () => {
  const isOpen = useAtomValue(downloadStore.isOpenAtom);
  const taskManagers = useAtomValue(downloadStore.taskManagersAtom);

  if (!isOpen) return null;
  return (
    <div
      className={cn(
        'b-1 b-neutral-600 b-solid rounded-2',
        'shadow-[0px_2px_2px_0px_rgba(0,0,0,0.15)]',
        'bg-neutral-100',
        'flex flex-col p-3 overflow-auto',
        'z-1 fixed max-h-50dvh',
        // extra small screen
        'inset-r-4 inset-b-10 w-[calc(100dvw-16px*2)] ',
        // small screen or larger
        'sm-w-480px',
      )}
    >
      <div className="mb-3 flex items-center justify-between">
        <h3 className="color-neutral-500 typo-text-m">Download status</h3>
        <div
          className={cn('i-skand-close text-3 color-neutral-400', 'hover:cursor-pointer')}
          onClick={downloadStore.closeDialog.bind(downloadStore)}
        />
      </div>

      {taskManagers.length !== 0 && (
        <div className="grid grid-cols-10 flex-none items-center gap-2">
          {taskManagers.map(taskManager => (
            <DownloadTaskManagerRow key={taskManager.id} taskManager={taskManager} />
          ))}
        </div>
      )}
    </div>
  );
};
