import { SystemNode } from '@/stores/systemNodes';
import { Spinner } from '@skand/ui';
import React, { useCallback, useEffect, useRef, useState } from 'react';

export interface LazyLoaderProps {
  hasMore: boolean;
  isLoading: boolean;
  onLoadMore: () => void;
  children: (items: SystemNode[]) => React.ReactNode;
  items: SystemNode[];
  className?: string;
}

export const LazyLoader = ({
  hasMore,
  isLoading,
  onLoadMore,
  children,
  items,
  className,
}: LazyLoaderProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const threshold = 50;
  const [lastVisibleItemIndex, setLastVisibleItemIndex] = useState<number | null>(null);

  const handleScroll = useCallback(() => {
    const getLastVisibleItemIndex = () => {
      const container = containerRef.current;
      if (container) {
        const { scrollTop, clientHeight } = container;
        let cumulativeHeight = 0;
        for (let i = 0; i < items.length; i++) {
          const item = document.getElementById(`item-${i}`);
          if (item) {
            cumulativeHeight += item.offsetHeight;
            if (cumulativeHeight > scrollTop + clientHeight) {
              return i - 1;
            }
          }
        }
      }
      return items.length - 1;
    };

    const container = containerRef.current;
    if (container) {
      const { scrollTop, scrollHeight, clientHeight } = container;
      // Trigger onLoadMore when scrolled near the bottom
      if (scrollTop + clientHeight >= scrollHeight - threshold && hasMore && !isLoading) {
        setLastVisibleItemIndex(getLastVisibleItemIndex());
        onLoadMore();
      }
    }
  }, [hasMore, isLoading, onLoadMore, items]);

  useEffect(() => {
    const container = containerRef.current;
    if (container && lastVisibleItemIndex !== null) {
      const lastItem = document.getElementById(`item-${lastVisibleItemIndex}`);
      if (lastItem) {
        const offsetTop = lastItem.offsetTop;
        container.scrollTop = offsetTop;
      }
    }
  }, [items, lastVisibleItemIndex]);

  return (
    <div className={className || 'overflow-auto h-full'} onScroll={handleScroll} ref={containerRef}>
      {children(items)}
      {isLoading && (
        <div className="flex justify-center pb-2">
          <Spinner className="h-4 w-4 animate-spin" />
        </div>
      )}
    </div>
  );
};
