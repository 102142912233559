import { PolicyActionTypeInput, PolicyObjectTypeInput } from '@/graphql/codegen/graphql';
import { useFindSystemNodeMostPermissiveActionTypeBySystemNodeId } from '@/hooks/useFindSystemNodeMostPermissiveActionTypeBySystemNodeId';
import { useMemo } from 'react';
import { useFetchPermissionPolicies } from './useFetchPermissionPolicies';
import { useIsSuperAdmin } from './useIsSuperAdmin';

export const useSystemNodesUserActionsPermissionsBySystemNodeIds = ({
  systemNodeIds,
  parentNodeId,
}: {
  systemNodeIds: string[];
  parentNodeId?: string;
}) => {
  const systemNodePolicies = useFetchPermissionPolicies(
    PolicyObjectTypeInput.SystemNode,
    systemNodeIds,
  );

  const { actionType: parentNodeHighestActionType } =
    useFindSystemNodeMostPermissiveActionTypeBySystemNodeId(parentNodeId ?? null);

  const isSuperAdmin = useIsSuperAdmin();

  const userActionsPermission = useMemo(() => {
    const checkActionTypeExistsInPermissionsList = (actionType: PolicyActionTypeInput) => {
      if (systemNodeIds.length === 0) return false;

      return systemNodeIds.every(systemNodeId =>
        systemNodePolicies.some(
          policy => policy.objectId === systemNodeId && policy.actionType === actionType,
        ),
      );
    };

    const userHasAdminPermission =
      isSuperAdmin ||
      parentNodeHighestActionType === PolicyActionTypeInput.Admin ||
      checkActionTypeExistsInPermissionsList(PolicyActionTypeInput.Admin);

    const userHasDeletePermission =
      userHasAdminPermission ||
      parentNodeHighestActionType === PolicyActionTypeInput.Delete ||
      checkActionTypeExistsInPermissionsList(PolicyActionTypeInput.Delete);

    const userHasMovePermission =
      userHasDeletePermission ||
      parentNodeHighestActionType === PolicyActionTypeInput.Move ||
      checkActionTypeExistsInPermissionsList(PolicyActionTypeInput.Move);

    const userHasEditPermission =
      userHasMovePermission ||
      parentNodeHighestActionType === PolicyActionTypeInput.Edit ||
      checkActionTypeExistsInPermissionsList(PolicyActionTypeInput.Edit);

    const userHasReadPermission =
      userHasEditPermission ||
      parentNodeHighestActionType === PolicyActionTypeInput.Read ||
      checkActionTypeExistsInPermissionsList(PolicyActionTypeInput.Read);

    return {
      userHasAdminPermission,
      userHasDeletePermission,
      userHasMovePermission,
      userHasEditPermission,
      userHasReadPermission,
    };
  }, [isSuperAdmin, parentNodeHighestActionType, systemNodeIds, systemNodePolicies]);

  return userActionsPermission;
};
