import { useState } from 'react';
import { cn } from '@/utils/classname';
import { Draggable } from '@skand/ui';

export interface DraggableMenuProps {
  closeMenu?: () => void;
  children?: React.ReactNode;
  x?: number;
  y?: number;
  disableCloseButton?: boolean;
  containerStyles?: string;
  disableDragY?: boolean;
  disableDragX?: boolean;
  onDragEnd?: (x: number, y: number) => void;
}

export const DraggableMenu = ({
  closeMenu,
  children,
  x = 390,
  y = 0,
  disableDragY = false,
  disableDragX = false,
  disableCloseButton = false,
  containerStyles,
  onDragEnd,
}: DraggableMenuProps) => {
  const [enabledDrag, setEnabledDrag] = useState(false);

  return (
    <Draggable
      className="fixed z-4"
      disableDragX={disableDragX}
      disableDragY={disableDragY}
      onDragEnd={(x, y) => {
        setEnabledDrag(false);
        if (onDragEnd) {
          onDragEnd(x, y);
        }
      }}
      onDragStart={() => setEnabledDrag(true)}
      x={x}
      y={y}
    >
      <div
        className={cn(
          containerStyles
            ? containerStyles
            : cn(
                'flex',
                'w-62',
                'bg-neutral-100',
                enabledDrag && 'outline  outline-2px outline-#0040FF',
                'p-3',
                'rounded-2',
                'border-1px',
                'border-solid',
                'border-neutral-400',
              ),
        )}
      >
        {!disableCloseButton && (
          <button
            className={cn('cursor-pointer bg-transparent b-none absolute right-12px')}
            onClick={closeMenu}
          >
            <div className={cn('text-12px text-right text-gray-400 i-skand-close')} />
          </button>
        )}
        {children}
      </div>
    </Draggable>
  );
};
