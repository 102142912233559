import logger from '@/utils/logger';
import { GqlOperationFactory, GqlQueryFactory } from '@skand/graphql-tools';
import { toast } from '@skand/ui';
import { MutationCache, QueryCache, QueryClient } from '@tanstack/react-query5';
import { request } from './request';

export const queryClient5 = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  },

  queryCache: new QueryCache({
    onError: error => {
      logger.error(error);
      toast({
        type: 'warn',
        message: `${error}`,
        lifespan: 10000,
        clickToDismiss: true,
      });
    },
  }),

  mutationCache: new MutationCache({
    onError: error => {
      logger.error(error);
      toast({
        type: 'warn',
        message: `${error}`,
        lifespan: 10000,
        clickToDismiss: true,
      });
    },
  }),
});

export const gqlOperationFactory = new GqlOperationFactory({
  request,
});

export const gqlQueryFactory = new GqlQueryFactory({
  gqlOperationFactory,
  queryClient: queryClient5,
});
