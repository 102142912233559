import {
  DrawIcon,
  IconButton,
  MapIcon,
  OrbitIcon,
  OrthographicIcon,
  PanoGroupIcon,
  PerspectiveIcon,
  PhotoGroupIcon,
  SuperheroIcon,
} from '@/components/IconButton';
import { Label } from '@/components/Label';
import { ANALYTICS_EVENT_OBJECT } from '@/constants/analytics';
import { useExplore } from '@/stores/explore';
import {
  Panorama,
  Photo2D,
  startDraw3D,
  startPanoramaWalkthrough,
  startPhotoOverlay,
  stopDraw3D,
  stopPanoramaView,
  stopPhotoOverlay,
  useViewer,
} from '@/stores/viewer';
import { cn } from '@/utils/classname';
import { getClosestTarget } from '@/utils/photos';
import { getShareLinkToken } from '@/utils/shareLink';
import { AI_PREDICTOR } from '@/utils/split';
import { Button, toast } from '@skand/ui';
import { useTreatments } from '@splitsoftware/splitio-react';
import { useEffect, useState } from 'react';
import { SettingsMenu } from '../../../SettingsMenu';
import { GlobeControlMenu } from '../GlobeControlMenu';

export interface ActionButtonsProps {
  togglePredictor: () => void;
  enabledPredictor: boolean;
}

export const ActionButtons = ({ togglePredictor, enabledPredictor }: ActionButtonsProps) => {
  const treatment = useTreatments([AI_PREDICTOR]);
  const processTabFlag = treatment[AI_PREDICTOR].treatment === 'on';

  const api3D = useViewer(state => state.api3D);
  const enabledDraw3D = useViewer(state => state.enabledDraw3D);
  const enabledDraw2D = useViewer(state => state.enabledDraw2D);
  const photo2DGroups = useViewer(state => state.photo2DGroups);
  const panoramaGroups = useViewer(state => state.panoramaGroups);
  const enabledPhotoOverlay = useViewer(state => state.enabledPhotoOverlay);
  const enabledPanoramaWalkthrough = useViewer(state => state.enabledPanoramaWalkthrough);
  const enabled3DMode = !enabledPhotoOverlay && !enabledPanoramaWalkthrough;
  const loadingMutex = useViewer(state => state.loadingMutex);
  const navigationMode = useViewer(state => state.navigationMode);
  const projectionMode = useViewer(state => state.projectionMode);
  const photos = useViewer(state => state.ribbonPhotos);

  const hasShareLinkToken = getShareLinkToken();
  const annotationDraft = useExplore(state => state.annotationDraft);

  const [navigationModeTooltip, setNavigationModeTooltip] = useState(false);
  const [cameraProjectionModeTooltip, setCameraProjectionModeTooltip] = useState(false);
  const [globeControlMenuTooltip, setIsGlobeControlMenuTooltip] = useState(false);
  const [drawModeTooltip, setDrawModeTooltip] = useState(false);
  const [settingsTooltip, setSettingsTooltip] = useState(false);

  const [enabledGlobeControlMenu, setEnabledGlobeControlMenu] = useState(false);
  const [enabledSettingsMenu, setEnabledSettingsMenu] = useState(false);

  useEffect(() => {
    api3D?.navigation.setNavigationMode(navigationMode);
    api3D?.navigation.setProjectionMode(projectionMode);
  }, [api3D?.navigation, navigationMode, projectionMode]);

  // Toggle the camera navigation controls
  const toggleNavigationMode = () => {
    if (navigationMode === 'orbit') {
      useViewer.setState({ navigationMode: 'superman' });
    } else {
      useViewer.setState({ navigationMode: 'orbit' });
    }
  };

  // Toggle the camera projection mode
  const toggleCameraProjection = () => {
    if (projectionMode === 'perspective') {
      useViewer.setState({ projectionMode: 'orthographic' });
    } else {
      useViewer.setState({ projectionMode: 'perspective' });
    }
  };

  // Toggle the draw tool
  const toggleDrawTool = () => {
    if (!enabledDraw3D) {
      startDraw3D();
    } else {
      stopDraw3D();
    }
  };

  // Toggle the globe control menu
  const toggleGlobeControlMene = () => {
    setEnabledSettingsMenu(false);
    setEnabledGlobeControlMenu(!enabledGlobeControlMenu);
  };

  // Toggle the settings menu
  const toggleSettingsMenu = () => {
    setEnabledGlobeControlMenu(false);
    setEnabledSettingsMenu(!enabledSettingsMenu);
  };

  // Enter 3D mode (stop image views)
  const handle3DMode = () => {
    if (!loadingMutex) {
      stopPhotoOverlay();
      stopPanoramaView();
    }
  };

  // Start the image overlay at prioritized image
  const handlePhotoOverlay = async () => {
    const { enabled2D } = useViewer.getState();
    if (!api3D || loadingMutex) return;

    const targets = photos.filter(photo => photo.type === 'photo2D' && photo.widget) as Photo2D[];
    if (targets.length > 0) {
      handle3DMode();
      startPhotoOverlay(targets[0], false, !enabled2D);
    } else {
      const cameraPosition = api3D.navigation.getPosition();
      const closestTarget = getClosestTarget(photo2DGroups, cameraPosition);
      if (closestTarget) {
        toast({
          type: 'info',
          message: 'No 2D image groups toggled. Opening the closest one.',
          lifespan: 5000,
        });
        handle3DMode();
        startPhotoOverlay(closestTarget as Photo2D);
        useViewer.setState(prev => {
          const visiblePhotoGroups = new Set(prev.visiblePhotoGroups);
          visiblePhotoGroups.add(closestTarget.group.id);
          return { visiblePhotoGroups };
        });
      } else {
        toast({
          type: 'info',
          message: 'Could not start image overlay. Toggle the layer/s with 2D images.',
          lifespan: 5000,
        });
      }
    }
  };

  // Start the panorama walkthrough at prioritized image
  const handlePanoramaWalkthrough = async () => {
    if (!api3D || loadingMutex) return;

    const targets = photos.filter(photo => photo.type === 'panorama' && photo.widget) as Panorama[];
    if (targets.length > 0) {
      handle3DMode();
      startPanoramaWalkthrough(targets[0]);
    } else {
      const cameraPosition = api3D.navigation.getPosition();
      const closestTarget = getClosestTarget(panoramaGroups, cameraPosition);
      if (closestTarget) {
        toast({
          type: 'info',
          message: 'No panorama groups toggled. Opening the closest one.',
          lifespan: 5000,
        });
        handle3DMode();
        startPanoramaWalkthrough(closestTarget as Panorama);
        useViewer.setState(prev => {
          const visiblePhotoGroups = new Set(prev.visiblePhotoGroups);
          visiblePhotoGroups.add(closestTarget.group.id);
          return { visiblePhotoGroups };
        });
      } else {
        toast({
          type: 'info',
          message:
            'Could not start panorama walkthrough. Toggle the layer/s with panoramic images.',
          lifespan: 5000,
        });
      }
    }
  };

  return (
    <div className="absolute right-0 top-0 mr-3">
      <div
        className={cn(
          'pt-12px',
          'flex',
          'justify-start',
          'items-center',
          'z-1',
          'pointer-events-auto',
        )}
      >
        <div className={cn('relative mr-10px')}>
          {enabledPhotoOverlay && !hasShareLinkToken && processTabFlag && (
            <Button
              className="cursor-pointer"
              filled
              onClick={togglePredictor}
              primary={enabledPredictor}
            >
              Predict
            </Button>
          )}
        </div>

        <div className={cn('relative')}>
          <IconButton
            analyticsEventObject={ANALYTICS_EVENT_OBJECT.DRAW_TOOL_3D}
            buttonIcon={<DrawIcon />}
            buttonState={enabledDraw3D ? 'active' : 'default'}
            className={cn(
              'h-30px',
              'mr-10px',
              enabledDraw3D || drawModeTooltip ? 'bg-neutral-400' : 'bg-neutral-900 bg-opacity-10',
            )}
            disabled={enabledDraw2D || annotationDraft?.annotation3d !== undefined}
            onClick={toggleDrawTool}
            onMouseEnter={() => setDrawModeTooltip(true)}
            onMouseLeave={() => setDrawModeTooltip(false)}
          />
          {drawModeTooltip && (
            <div className={cn('absolute w-250px', 'left--20px', 'mt-2')}>
              <Label
                css="bg-neutral-100"
                ellipsis={false}
                labelTitle="Draw tool"
                labelType="default"
              />
            </div>
          )}
        </div>

        <div className={cn('relative')}>
          <IconButton
            analyticsEventObject={
              projectionMode === 'perspective'
                ? ANALYTICS_EVENT_OBJECT.ORTHOGRAPHIC_VIEW
                : ANALYTICS_EVENT_OBJECT.PERSPECTIVE_VIEW
            }
            buttonIcon={
              projectionMode === 'perspective' ? <PerspectiveIcon /> : <OrthographicIcon />
            }
            buttonState="default"
            className={cn(
              'h-30px',
              'mr-10px',
              cameraProjectionModeTooltip ? 'bg-neutral-400' : 'bg-neutral-900 bg-opacity-10',
            )}
            onClick={toggleCameraProjection}
            onMouseEnter={() => setCameraProjectionModeTooltip(true)}
            onMouseLeave={() => setCameraProjectionModeTooltip(false)}
          />
          {cameraProjectionModeTooltip && (
            <div className={cn('absolute w-250px', 'left--30px', 'mt-2')}>
              <Label
                css="bg-neutral-100"
                ellipsis={false}
                labelTitle={projectionMode === 'perspective' ? 'Orthographic' : 'Perspective'}
                labelType="default"
              />
            </div>
          )}
        </div>

        <div className={cn('relative')}>
          <IconButton
            analyticsEventObject={
              navigationMode === 'superman'
                ? ANALYTICS_EVENT_OBJECT.ORBIT_MODE
                : ANALYTICS_EVENT_OBJECT.SUPERHERO_MODE
            }
            buttonIcon={navigationMode === 'superman' ? <SuperheroIcon /> : <OrbitIcon />}
            buttonState="default"
            className={cn(
              'h-30px',
              'mr-10px',
              navigationModeTooltip ? 'bg-neutral-400' : 'bg-neutral-900 bg-opacity-10',
            )}
            onClick={toggleNavigationMode}
            onMouseEnter={() => setNavigationModeTooltip(true)}
            onMouseLeave={() => setNavigationModeTooltip(false)}
          />
          {navigationModeTooltip && (
            <div className={cn('absolute w-250px', 'left--80px', 'mt-2')}>
              <Label
                ellipsis={false}
                labelTitle={`Switch to ${
                  navigationMode === 'superman' ? 'orbit' : 'superhero'
                } mode here`}
                labelType="info"
                textLength={30}
              />
            </div>
          )}
        </div>

        <div className={cn('relative')}>
          <IconButton
            analyticsEventObject={ANALYTICS_EVENT_OBJECT.GLOBE_CONTROL_MENU}
            buttonIcon={<MapIcon />}
            buttonState={enabledGlobeControlMenu ? 'active' : 'default'}
            className={cn(
              'h-30px',
              'mr-10px',
              enabledGlobeControlMenu || globeControlMenuTooltip
                ? 'bg-neutral-400'
                : 'bg-neutral-900 bg-opacity-10',
            )}
            onClick={toggleGlobeControlMene}
            onMouseEnter={() => setIsGlobeControlMenuTooltip(true)}
            onMouseLeave={() => setIsGlobeControlMenuTooltip(false)}
          />
          {globeControlMenuTooltip && !enabledGlobeControlMenu && (
            <div className={cn('absolute w-250px', 'left--30px', 'mt-2')}>
              <Label
                css="bg-neutral-100"
                ellipsis={false}
                labelTitle="Globe Control Menu"
                labelType="default"
              />
            </div>
          )}
          {enabledGlobeControlMenu && (
            <div className={cn('absolute w-284px', 'h-292px', 'right-0', 'mt-2')}>
              <GlobeControlMenu />
            </div>
          )}
        </div>

        <div className={cn('relative')}>
          <IconButton
            analyticsEventObject={ANALYTICS_EVENT_OBJECT.VIEWER_SETTINGS}
            buttonIcon={<div className="i-skand-gear text-18px" />}
            buttonState="default"
            className={cn(
              'h-30px',
              'mr-10px',
              enabledSettingsMenu || settingsTooltip
                ? 'bg-neutral-400'
                : 'bg-neutral-900 bg-opacity-10',
            )}
            onClick={toggleSettingsMenu}
            onMouseEnter={() => setSettingsTooltip(true)}
            onMouseLeave={() => setSettingsTooltip(false)}
          />
          {settingsTooltip && !enabledSettingsMenu && (
            <div className={cn('absolute w-250px', 'left--20px', 'mt-2')}>
              <Label
                css="bg-neutral-100"
                ellipsis={false}
                labelTitle="Settings"
                labelType="default"
              />
            </div>
          )}
          {enabledSettingsMenu && (
            <div className={cn('absolute w-260px', 'h-524px', 'right-0', 'mt-2')}>
              <SettingsMenu />
            </div>
          )}
        </div>

        <div className={cn('relative flex flex-row')}>
          <button
            className={cn(
              'transition',
              'duration-300',
              'ease-out',
              'h-[30px]',
              'w-[30px]',
              'flex',
              'justify-center',
              'items-center',
              !enabled3DMode ? 'bg-transparent' : 'bg-neutral-700',
              'border-l-1',
              'border-r-0px',
              'border-t-1',
              'border-b-1',
              'rounded-bl-1',
              'rounded-tl-1',
              'border-solid',
              'fill-none',
              'border-neutral-100',
            )}
            data-analytics-event-object={ANALYTICS_EVENT_OBJECT.VIEW_3D}
            disabled={enabled3DMode}
            onClick={handle3DMode}
          >
            <div className={cn('color-neutral-100 typo-text-s')}>3D</div>
          </button>
          <button
            className={cn(
              'transition',
              'duration-300',
              'ease-out',
              'h-[30px]',
              'w-[30px]',
              'flex',
              'justify-center',
              'items-center',
              !enabledPanoramaWalkthrough ? 'bg-transparent' : 'bg-neutral-700',
              'border-l-1',
              'border-r-1',
              'border-t-1',
              'border-b-1',
              'border-solid',
              'fill-none',
              'border-neutral-100',
            )}
            data-analytics-event-object={ANALYTICS_EVENT_OBJECT.VIEW_PANORAMA}
            disabled={enabledPanoramaWalkthrough}
            onClick={handlePanoramaWalkthrough}
          >
            <PanoGroupIcon className="color-neutral-100" />
          </button>
          <button
            className={cn(
              'transition',
              'duration-300',
              'ease-out',
              'h-[30px]',
              'w-[30px]',
              'flex',
              'justify-center',
              'items-center',
              !enabledPhotoOverlay ? 'bg-transparent' : 'bg-neutral-700',
              'border-l-1',
              'border-r-1',
              'border-t-1',
              'border-b-1',
              'rounded-br-1',
              'rounded-tr-1',
              'border-solid',
              'fill-none',
              'border-neutral-100',
            )}
            data-analytics-event-object={ANALYTICS_EVENT_OBJECT.IMAGE_VIEWER_2D_IMAGE}
            disabled={enabledPhotoOverlay}
            onClick={handlePhotoOverlay}
          >
            <PhotoGroupIcon className="color-neutral-100" />
          </button>
        </div>
      </div>
    </div>
  );
};
