import { createRandomName } from '@/utils/annotation';
import { Button, cn } from '@skand/ui';
import { ViewerAPI } from '@skand/viewer-component-v2';
import { ChangeEventHandler, useEffect, useState } from 'react';

interface MainMenuProps {
  api3D: ViewerAPI;
}

export const MainMenu = ({ api3D }: MainMenuProps) => {
  const [name, setName] = useState<string>(createRandomName());
  const [joinCode, setJoinCode] = useState<string>('');

  // Update player name
  useEffect(() => {
    api3D.game.setName(name);
  }, [api3D.game, name]);

  // Handle hosting a game
  const handleHost = () => {
    api3D.game.host();
  };

  // Handle joining a game
  const handleJoin = () => {
    api3D.game.join(joinCode);
  };

  // Handle entering name
  const handleNameInput: ChangeEventHandler<HTMLInputElement> = e => {
    setName(e.target.value);
  };

  // Handle entering the room code
  const handleCodeInput: ChangeEventHandler<HTMLInputElement> = e => {
    setJoinCode(e.target.value.slice(0, 5).toUpperCase());
  };

  return (
    <div className={cn('w-75', 'h-full', 'relative')}>
      <div
        className={cn(
          'w-full',
          'typo-text-l',
          'bg-neutral-1',
          'p-4',
          'absolute',
          'bottom-60',
          'left-0',
          'pointer-events-auto',
          'flex',
          'flex-row',
          'rounded-r',
          'justify-between',
        )}
      >
        <input
          className="h-full w-full px-1 py-2"
          onChange={handleNameInput}
          placeholder="User Name"
          value={name}
        />
      </div>
      <div
        className={cn(
          'w-full',
          'typo-text-l',
          'bg-neutral-1',
          'p-4',
          'absolute',
          'bottom-35',
          'left-0',
          'pointer-events-auto',
          'flex',
          'flex-row',
          'rounded-r',
          'justify-between',
        )}
      >
        <Button className="w-full" filled onClick={handleHost} primary size="m" v1>
          Create Room
        </Button>
      </div>
      <div
        className={cn(
          'w-full',
          'typo-text-l',
          'bg-neutral-1',
          'p-4',
          'absolute',
          'bottom-10',
          'left-0',
          'pointer-events-auto',
          'flex',
          'flex-row',
          'rounded-r',
          'justify-between',
          'gap-4',
        )}
      >
        <Button disabled={joinCode.length !== 5} filled onClick={handleJoin} primary size="m">
          Join
        </Button>
        <input
          className="w-full px-1"
          onChange={handleCodeInput}
          placeholder="Enter Room Code"
          value={joinCode}
        />
      </div>
    </div>
  );
};
