import { UploadSession, useUploadSession } from '@skand/uploader';
import { UPLOADED_STAGES } from '../../Upload/constants';

export const UploadStatusIcon = ({ uploadSession }: { uploadSession: UploadSession }) => {
  const { stage } = useUploadSession(uploadSession);

  if (UPLOADED_STAGES.includes(stage)) {
    return (
      <div className="flex justify-end">
        <div className="i-skand-done text-4 color-success-400">Upload complete</div>
      </div>
    );
  }
};
