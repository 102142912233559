import { ColorPickerMenu } from '@/components/ColorPickerMenu';
import { PhotoGroup, useViewer } from '@/stores/viewer';
import { PhotoCamera } from '@skand/viewer-component-v2';
import { Color } from 'three';

export interface ColorMenuProps {
  group: PhotoGroup;
  closeMenu: () => void;
}

export const ColorMenu = ({ closeMenu, group }: ColorMenuProps) => {
  // Update the color of the group
  const handleSetColor = (color: Color) => {
    useViewer.setState(state => {
      group.color = color;
      for (const photo of group.photos) {
        if (photo.widget) {
          const camera = photo.widget.getModel() as PhotoCamera;
          camera.setColor(color);
        }
      }
      return {
        photo2DGroups: [...state.photo2DGroups],
        panoramaGroups: [...state.panoramaGroups],
      };
    });
  };

  return (
    <ColorPickerMenu
      closeMenu={closeMenu}
      color={group.color}
      selectColor={handleSetColor}
      x={390}
      y={0}
    />
  );
};
