/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
};

export type Awss3ApplicationCredential = {
  __typename?: 'AWSS3ApplicationCredential';
  accountId?: Maybe<Scalars['ID']['output']>;
  application?: Maybe<Scalars['String']['output']>;
  bucketName?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdByUserId?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  purpose?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
};

export type Awss3ApplicationCredentialInput = {
  accessKeyId?: InputMaybe<Scalars['String']['input']>;
  application?: InputMaybe<Scalars['String']['input']>;
  bucketName?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  purpose?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  secretAccessKey?: InputMaybe<Scalars['String']['input']>;
};

export type Account = {
  __typename?: 'Account';
  activeStatus?: Maybe<Scalars['Boolean']['output']>;
  bannerId?: Maybe<Scalars['ID']['output']>;
  bannerLogoStorageId?: Maybe<Scalars['ID']['output']>;
  bannerLogoStorageUrl?: Maybe<Scalars['String']['output']>;
  bannerUrl?: Maybe<Scalars['String']['output']>;
  brandingColor?: Maybe<Scalars['String']['output']>;
  brandingStatus?: Maybe<Scalars['Boolean']['output']>;
  captures?: Maybe<Array<Maybe<Capture>>>;
  code?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  hasBanner?: Maybe<Scalars['Boolean']['output']>;
  hasSkandWatermark?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  logoId?: Maybe<Scalars['ID']['output']>;
  logoUrl?: Maybe<Scalars['String']['output']>;
  mailingListValidStatus?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  sites?: Maybe<Array<Maybe<Site>>>;
  storageUsage?: Maybe<Scalars['Float']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  users?: Maybe<Array<Maybe<User>>>;
};

export type AccountBranding = {
  __typename?: 'AccountBranding';
  color?: Maybe<Scalars['String']['output']>;
  hasSkandWatermark?: Maybe<Scalars['Boolean']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  reportBannerFile?: Maybe<File>;
  reportBannerFileId?: Maybe<Scalars['ID']['output']>;
  reportBannerStorageId?: Maybe<Scalars['ID']['output']>;
  webappBannerFile?: Maybe<File>;
  webappBannerFileId?: Maybe<Scalars['ID']['output']>;
  webappBannerStorageId?: Maybe<Scalars['ID']['output']>;
  webappLogoFile?: Maybe<File>;
  webappLogoFileId?: Maybe<Scalars['ID']['output']>;
  webappLogoStorageId?: Maybe<Scalars['ID']['output']>;
};

export type AccountFileUsage = {
  __typename?: 'AccountFileUsage';
  accountId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isMigrationComplete?: Maybe<Scalars['Boolean']['output']>;
  totalFileUsage?: Maybe<Scalars['Float']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type AccountIdentityProvider = {
  __typename?: 'AccountIdentityProvider';
  id?: Maybe<Scalars['String']['output']>;
  providerName?: Maybe<Scalars['String']['output']>;
};

export type AccountOwner = {
  __typename?: 'AccountOwner';
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
};

export type AccountSubscription = {
  __typename?: 'AccountSubscription';
  customerId?: Maybe<Scalars['String']['output']>;
  resourceVersion?: Maybe<Scalars['Int']['output']>;
};

export type AccountV2 = {
  __typename?: 'AccountV2';
  acquisitionChannel?: Maybe<Scalars['String']['output']>;
  branding?: Maybe<AccountBranding>;
  code?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  identityProviders?: Maybe<Array<Maybe<AccountIdentityProvider>>>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  owner?: Maybe<AccountOwner>;
  storageUsage?: Maybe<Scalars['Float']['output']>;
  subscription?: Maybe<AccountSubscription>;
};

export type Annotation = {
  __typename?: 'Annotation';
  accountId?: Maybe<Scalars['ID']['output']>;
  annotation2d?: Maybe<Annotation2d>;
  annotation3d?: Maybe<Annotation3d>;
  annotationId?: Maybe<Scalars['ID']['output']>;
  color?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdByUserId?: Maybe<Scalars['ID']['output']>;
  fields?: Maybe<Array<Maybe<AnnotationField>>>;
  groupId?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isActiveVersion?: Maybe<Scalars['Boolean']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  projectId?: Maybe<Scalars['ID']['output']>;
  rootFolderNodeId?: Maybe<Scalars['ID']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  templateId?: Maybe<Scalars['ID']['output']>;
};

export type Annotation2d = {
  __typename?: 'Annotation2d';
  imageFile?: Maybe<File>;
  imageFileId?: Maybe<Scalars['ID']['output']>;
  points?: Maybe<Array<Maybe<Annotation2dPoint>>>;
  shapeType?: Maybe<Scalars['String']['output']>;
};

export type Annotation2dInput = {
  imageFileId?: InputMaybe<Scalars['ID']['input']>;
  points?: InputMaybe<Array<InputMaybe<Annotation2dPointInput>>>;
  shapeType?: InputMaybe<Scalars['String']['input']>;
};

export type Annotation2dPoint = {
  __typename?: 'Annotation2dPoint';
  x?: Maybe<Scalars['Float']['output']>;
  y?: Maybe<Scalars['Float']['output']>;
};

export type Annotation2dPointInput = {
  x?: InputMaybe<Scalars['Float']['input']>;
  y?: InputMaybe<Scalars['Float']['input']>;
};

export type Annotation3d = {
  __typename?: 'Annotation3d';
  positions?: Maybe<Array<Maybe<Annotation3dPosition>>>;
  rotations?: Maybe<Array<Maybe<Annotation3dRotation>>>;
  shapeType?: Maybe<Scalars['String']['output']>;
};

export type Annotation3dInput = {
  positions?: InputMaybe<Array<InputMaybe<Annotation3dPositionInput>>>;
  rotations?: InputMaybe<Array<InputMaybe<Annotation3dRotationInput>>>;
  shapeType?: InputMaybe<Scalars['String']['input']>;
};

export type Annotation3dPosition = {
  __typename?: 'Annotation3dPosition';
  x?: Maybe<Scalars['Float']['output']>;
  y?: Maybe<Scalars['Float']['output']>;
  z?: Maybe<Scalars['Float']['output']>;
};

export type Annotation3dPositionInput = {
  x?: InputMaybe<Scalars['Float']['input']>;
  y?: InputMaybe<Scalars['Float']['input']>;
  z?: InputMaybe<Scalars['Float']['input']>;
};

export type Annotation3dRotation = {
  __typename?: 'Annotation3dRotation';
  w?: Maybe<Scalars['Float']['output']>;
  x?: Maybe<Scalars['Float']['output']>;
  y?: Maybe<Scalars['Float']['output']>;
  z?: Maybe<Scalars['Float']['output']>;
};

export type Annotation3dRotationInput = {
  w?: InputMaybe<Scalars['Float']['input']>;
  x?: InputMaybe<Scalars['Float']['input']>;
  y?: InputMaybe<Scalars['Float']['input']>;
  z?: InputMaybe<Scalars['Float']['input']>;
};

export type AnnotationDateField = AnnotationFieldBase & {
  __typename?: 'AnnotationDateField';
  end?: Maybe<Scalars['String']['output']>;
  fieldId?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  start?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type AnnotationField =
  | AnnotationDateField
  | AnnotationFileField
  | AnnotationImageField
  | AnnotationSelectField
  | AnnotationTextField
  | AnnotationUrlField;

export type AnnotationFieldBase = {
  fieldId?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type AnnotationFieldInput = {
  end?: InputMaybe<Scalars['String']['input']>;
  fieldId?: InputMaybe<Scalars['String']['input']>;
  fileId?: InputMaybe<Scalars['String']['input']>;
  files?: InputMaybe<Array<InputMaybe<AnnotationFileInput>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  optionId?: InputMaybe<Scalars['String']['input']>;
  start?: InputMaybe<Scalars['String']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  urls?: InputMaybe<Array<InputMaybe<AnnotationUrlInput>>>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type AnnotationFile = {
  __typename?: 'AnnotationFile';
  fileId?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type AnnotationFileField = AnnotationFieldBase & {
  __typename?: 'AnnotationFileField';
  fieldId?: Maybe<Scalars['String']['output']>;
  files?: Maybe<Array<Maybe<AnnotationFile>>>;
  name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type AnnotationFileInput = {
  fileId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type AnnotationIdWithPositionAndRotation = {
  annotationId?: InputMaybe<Scalars['ID']['input']>;
  positions?: InputMaybe<Array<InputMaybe<Annotation3dPositionInput>>>;
  rotations?: InputMaybe<Array<InputMaybe<Annotation3dRotationInput>>>;
};

export type AnnotationImageField = AnnotationFieldBase & {
  __typename?: 'AnnotationImageField';
  fieldId?: Maybe<Scalars['String']['output']>;
  file?: Maybe<File>;
  fileId?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type AnnotationProcess = {
  __typename?: 'AnnotationProcess';
  accountId?: Maybe<Scalars['ID']['output']>;
  annotationIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  captureId?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdByUserId?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  imagesWithStatus?: Maybe<Array<Maybe<AnnotationProcessImageWithStatusField>>>;
  kind?: Maybe<Scalars['String']['output']>;
  lastStep?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  projectId?: Maybe<Scalars['ID']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  renderObjectId?: Maybe<Scalars['ID']['output']>;
  sceneEntityId?: Maybe<Scalars['ID']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  steps?: Maybe<Array<Maybe<StepField>>>;
};

export type AnnotationProcessImageWithStatusField = {
  __typename?: 'AnnotationProcessImageWithStatusField';
  fileId?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type AnnotationRenderObject = {
  __typename?: 'AnnotationRenderObject';
  accountId?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  kind?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  rootFolderNodeId?: Maybe<Scalars['ID']['output']>;
  usedBySceneEntityIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
};

export type AnnotationSelectField = AnnotationFieldBase & {
  __typename?: 'AnnotationSelectField';
  fieldId?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  optionId?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export enum AnnotationStatusInput {
  Created = 'CREATED',
  Creating = 'CREATING',
  Deleted = 'DELETED',
  Deleting = 'DELETING',
}

export type AnnotationTemplate = {
  __typename?: 'AnnotationTemplate';
  accountId?: Maybe<Scalars['ID']['output']>;
  colorFromField?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByUserId?: Maybe<Scalars['ID']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  fields?: Maybe<Array<Maybe<AnnotationTemplateField>>>;
  id?: Maybe<Scalars['ID']['output']>;
  level?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  projectId?: Maybe<Scalars['ID']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedByUserId?: Maybe<Scalars['ID']['output']>;
};

export type AnnotationTemplateDateField = AnnotationTemplateFieldBase & {
  __typename?: 'AnnotationTemplateDateField';
  id?: Maybe<Scalars['String']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  isVisible?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type AnnotationTemplateField =
  | AnnotationTemplateDateField
  | AnnotationTemplateFileField
  | AnnotationTemplateImageField
  | AnnotationTemplateSelectField
  | AnnotationTemplateTextField
  | AnnotationTemplateUrlField;

export type AnnotationTemplateFieldBase = {
  id?: Maybe<Scalars['String']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  isVisible?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type AnnotationTemplateFieldInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isVisible?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  options?: InputMaybe<Array<InputMaybe<AnnotationTemplateSelectOptionInput>>>;
  order?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type AnnotationTemplateFileField = AnnotationTemplateFieldBase & {
  __typename?: 'AnnotationTemplateFileField';
  id?: Maybe<Scalars['String']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  isVisible?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type AnnotationTemplateImageField = AnnotationTemplateFieldBase & {
  __typename?: 'AnnotationTemplateImageField';
  id?: Maybe<Scalars['String']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  isVisible?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type AnnotationTemplateSelectField = AnnotationTemplateFieldBase & {
  __typename?: 'AnnotationTemplateSelectField';
  id?: Maybe<Scalars['String']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  isVisible?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  options?: Maybe<Array<Maybe<AnnotationTemplateSelectOption>>>;
  order?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type AnnotationTemplateSelectOption = {
  __typename?: 'AnnotationTemplateSelectOption';
  color?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type AnnotationTemplateSelectOptionInput = {
  color?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type AnnotationTemplateTextField = AnnotationTemplateFieldBase & {
  __typename?: 'AnnotationTemplateTextField';
  id?: Maybe<Scalars['String']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  isVisible?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type AnnotationTemplateUrlField = AnnotationTemplateFieldBase & {
  __typename?: 'AnnotationTemplateUrlField';
  id?: Maybe<Scalars['String']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  isVisible?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type AnnotationTextField = AnnotationFieldBase & {
  __typename?: 'AnnotationTextField';
  fieldId?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type AnnotationToolLog = {
  __typename?: 'AnnotationToolLog';
  annotations?: Maybe<Array<Maybe<AnnotationToolLogAnnotation>>>;
  createdAt?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  imageId?: Maybe<Scalars['ID']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
  userId?: Maybe<Scalars['ID']['output']>;
};

export type AnnotationToolLogAnnotation = {
  __typename?: 'AnnotationToolLogAnnotation';
  annotationPoints?: Maybe<Array<Maybe<Array<Scalars['Float']['output']>>>>;
  annotationToolId?: Maybe<Scalars['String']['output']>;
  buildingId?: Maybe<Scalars['ID']['output']>;
  comments?: Maybe<Scalars['String']['output']>;
  defectTypeId?: Maybe<Scalars['ID']['output']>;
  elementId?: Maybe<Scalars['ID']['output']>;
  priorityId?: Maybe<Scalars['ID']['output']>;
  taskTypeId?: Maybe<Scalars['ID']['output']>;
};

export type AnnotationUrl = {
  __typename?: 'AnnotationUrl';
  name?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type AnnotationUrlField = AnnotationFieldBase & {
  __typename?: 'AnnotationUrlField';
  fieldId?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  urls?: Maybe<Array<Maybe<AnnotationUrl>>>;
};

export type AnnotationUrlInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type AppendixInputField = {
  conditionAssessment?: InputMaybe<Scalars['Boolean']['input']>;
  roofDefects?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ApplicationCredential = Awss3ApplicationCredential;

export type Asset = {
  __typename?: 'Asset';
  accountId?: Maybe<Scalars['ID']['output']>;
  assetStorageField?: Maybe<AssetStorageField>;
  capture?: Maybe<Capture>;
  captureId?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  siteId?: Maybe<Scalars['ID']['output']>;
};

export type AssetFile = {
  __typename?: 'AssetFile';
  accountId?: Maybe<Scalars['ID']['output']>;
  canDelete?: Maybe<Scalars['Boolean']['output']>;
  captures?: Maybe<Array<Maybe<Capture>>>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  httpUrl?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  kind?: Maybe<Scalars['String']['output']>;
  renderObjectIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  renderObjects?: Maybe<Array<Maybe<RenderObject>>>;
  signedGetObjectDownloadUrl?: Maybe<Scalars['String']['output']>;
  signedGetObjectUrl?: Maybe<Scalars['String']['output']>;
  sites?: Maybe<Array<Maybe<Site>>>;
  storage?: Maybe<FileStorageField>;
  storageStatus?: Maybe<Scalars['String']['output']>;
};

export type AssetStorageField = {
  __typename?: 'AssetStorageField';
  bucket?: Maybe<Scalars['String']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  size?: Maybe<Scalars['Float']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  usageType?: Maybe<Scalars['String']['output']>;
};

export type BucketAndRegion = {
  __typename?: 'BucketAndRegion';
  bucket?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
};

export type Building = {
  __typename?: 'Building';
  boundary?: Maybe<Array<Maybe<Array<Maybe<Array<Scalars['Float']['output']>>>>>>;
  code?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  location?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  name?: Maybe<Scalars['String']['output']>;
  roofConstructionType?: Maybe<RoofConstructionType>;
  roofConstructionTypeId?: Maybe<Scalars['ID']['output']>;
  roofMaterialType?: Maybe<RoofMaterialType>;
  roofMaterialTypeId?: Maybe<Scalars['ID']['output']>;
  site?: Maybe<Site>;
  siteId?: Maybe<Scalars['ID']['output']>;
  squareMeters?: Maybe<Scalars['Float']['output']>;
};

export type CameraDistortion = {
  k1?: InputMaybe<Scalars['Float']['input']>;
  k2?: InputMaybe<Scalars['Float']['input']>;
  k3?: InputMaybe<Scalars['Float']['input']>;
  p1?: InputMaybe<Scalars['Float']['input']>;
  p2?: InputMaybe<Scalars['Float']['input']>;
};

export type CameraDistortionField = {
  __typename?: 'CameraDistortionField';
  k1?: Maybe<Scalars['Float']['output']>;
  k2?: Maybe<Scalars['Float']['output']>;
  k3?: Maybe<Scalars['Float']['output']>;
  p1?: Maybe<Scalars['Float']['output']>;
  p2?: Maybe<Scalars['Float']['output']>;
};

export type CameraModel = {
  distortion?: InputMaybe<CameraDistortion>;
  focalLength?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  imageSize?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  principalPoint?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  projectionString?: InputMaybe<Scalars['String']['input']>;
  sensorSize?: InputMaybe<Scalars['Float']['input']>;
  skew?: InputMaybe<Scalars['Float']['input']>;
};

export type CameraModelField = {
  __typename?: 'CameraModelField';
  distortion?: Maybe<CameraDistortionField>;
  focalLength?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  imageSize?: Maybe<Array<Scalars['Float']['output']>>;
  principalPoint?: Maybe<Array<Scalars['Float']['output']>>;
  projectionString?: Maybe<Scalars['String']['output']>;
  sensorSize?: Maybe<Scalars['Float']['output']>;
  skew?: Maybe<Scalars['Float']['output']>;
};

export type Capture = {
  __typename?: 'Capture';
  account?: Maybe<Account>;
  accountId?: Maybe<Scalars['ID']['output']>;
  addonIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  addons?: Maybe<Array<Maybe<CaptureAddon>>>;
  aligner?: Maybe<User>;
  alignerId?: Maybe<Scalars['String']['output']>;
  applyDefectOffset?: Maybe<Scalars['String']['output']>;
  approver?: Maybe<User>;
  approverId?: Maybe<Scalars['String']['output']>;
  bentleyXmlUrl?: Maybe<Scalars['String']['output']>;
  captureDate?: Maybe<Scalars['String']['output']>;
  captureSourceIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  captureSources?: Maybe<Array<Maybe<CaptureSource>>>;
  centerPoint?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  centerRadius?: Maybe<Scalars['Float']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  coordinateSystem?: Maybe<CaptureCoordinateSystem>;
  coreStatus?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdUser?: Maybe<User>;
  createdUserId?: Maybe<Scalars['ID']['output']>;
  defaultMaxHeight?: Maybe<Scalars['Float']['output']>;
  defaultMinHeight?: Maybe<Scalars['Float']['output']>;
  defects?: Maybe<Array<Maybe<Defect>>>;
  emailToUser?: Maybe<User>;
  emailToUserId?: Maybe<Scalars['ID']['output']>;
  files?: Maybe<Array<Maybe<CaptureFile>>>;
  formatType?: Maybe<Scalars['String']['output']>;
  generalInformation?: Maybe<Scalars['String']['output']>;
  historic?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  images?: Maybe<Array<Maybe<Image>>>;
  ingestStatus?: Maybe<Scalars['String']['output']>;
  isLegacy?: Maybe<Scalars['Boolean']['output']>;
  isUserCreated?: Maybe<Scalars['Boolean']['output']>;
  legacyCreatedAt?: Maybe<Scalars['String']['output']>;
  legacyUpdatedAt?: Maybe<Scalars['String']['output']>;
  mainSceneEntity?: Maybe<SceneEntity>;
  mainSceneEntityId?: Maybe<Scalars['ID']['output']>;
  measurements?: Maybe<Array<Maybe<Measurement>>>;
  migratedStatus?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  objectOfInterests?: Maybe<Array<Maybe<ObjectOfInterest>>>;
  position?: Maybe<CapturePosition>;
  processes?: Maybe<Array<Maybe<Process>>>;
  published?: Maybe<Scalars['Boolean']['output']>;
  publisher?: Maybe<User>;
  publisherId?: Maybe<Scalars['String']['output']>;
  rendererType?: Maybe<Scalars['String']['output']>;
  site?: Maybe<Site>;
  siteId?: Maybe<Scalars['ID']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  storages?: Maybe<Array<Maybe<Storage>>>;
  storagesFromAssets?: Maybe<Array<Maybe<Storage>>>;
  supportingSceneEntities?: Maybe<Array<Maybe<SceneEntity>>>;
  supportingSceneEntityIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  taggerIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  taggers?: Maybe<Array<Maybe<User>>>;
  tileHeight2d?: Maybe<Scalars['Float']['output']>;
  tileHeight3d?: Maybe<Scalars['Float']['output']>;
  tilesetHeight?: Maybe<Scalars['Float']['output']>;
  tilesetUrl?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  worldPosition?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  worldRotation?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
};

export type CaptureAddon = {
  __typename?: 'CaptureAddon';
  createdAt?: Maybe<Scalars['String']['output']>;
  finalizedAt?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type CaptureCoordinateSystem = {
  __typename?: 'CaptureCoordinateSystem';
  epsgCode?: Maybe<Scalars['Float']['output']>;
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
};

export type CaptureFile = {
  __typename?: 'CaptureFile';
  accountId?: Maybe<Scalars['ID']['output']>;
  bucket?: Maybe<Scalars['String']['output']>;
  captureId?: Maybe<Scalars['ID']['output']>;
  fileId?: Maybe<Scalars['ID']['output']>;
  fileSourceModel?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  signedGetObjectDownloadUrl?: Maybe<Scalars['String']['output']>;
  signedGetObjectUrl?: Maybe<Scalars['String']['output']>;
  siteId?: Maybe<Scalars['ID']['output']>;
  size?: Maybe<Scalars['Float']['output']>;
  storageId?: Maybe<Scalars['ID']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  usageType?: Maybe<Scalars['String']['output']>;
};

export type CaptureFileSignedGetObjectDownloadUrlArgs = {
  expires?: InputMaybe<Scalars['Float']['input']>;
};

export type CaptureFileSignedGetObjectUrlArgs = {
  expires?: InputMaybe<Scalars['Float']['input']>;
};

export type CapturePosition = {
  __typename?: 'CapturePosition';
  epsgCode?: Maybe<Scalars['Float']['output']>;
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  pitch?: Maybe<Scalars['Float']['output']>;
  roll?: Maybe<Scalars['Float']['output']>;
  tileHeight2d?: Maybe<Scalars['Float']['output']>;
  tileHeight3d?: Maybe<Scalars['Float']['output']>;
  yaw?: Maybe<Scalars['Float']['output']>;
};

export type CaptureSource = {
  __typename?: 'CaptureSource';
  captureType?: Maybe<CaptureType>;
  captureTypeId?: Maybe<Scalars['ID']['output']>;
  defects?: Maybe<Array<Maybe<Defect>>>;
  id?: Maybe<Scalars['ID']['output']>;
  images?: Maybe<Array<Maybe<Image>>>;
  silverbraneDataSourceId?: Maybe<Scalars['Int']['output']>;
  silverbraneImageQueueId?: Maybe<Scalars['Int']['output']>;
  silverbraneProjectId?: Maybe<Scalars['Int']['output']>;
  uri?: Maybe<Scalars['String']['output']>;
};

export type CaptureStorageUsage = {
  __typename?: 'CaptureStorageUsage';
  captureId?: Maybe<Scalars['ID']['output']>;
  totalCount?: Maybe<Scalars['Float']['output']>;
  totalSize?: Maybe<Scalars['Float']['output']>;
};

export type CaptureType = {
  __typename?: 'CaptureType';
  defectTypeIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  defectTypes?: Maybe<Array<Maybe<DefectType>>>;
  elementIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  elements?: Maybe<Array<Maybe<Element>>>;
  id?: Maybe<Scalars['ID']['output']>;
  imageFormat?: Maybe<Scalars['String']['output']>;
  imageType?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  priorities?: Maybe<Array<Maybe<Priority>>>;
  priorityIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  taskTypeIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  taskTypes?: Maybe<Array<Maybe<TaskType>>>;
};

export type Cesium = {
  __typename?: 'Cesium';
  cartographicPosition?: Maybe<Array<Maybe<Array<Scalars['Float']['output']>>>>;
  colorHex?: Maybe<Scalars['String']['output']>;
  entityType?: Maybe<Scalars['String']['output']>;
  migratedCartographicPosition?: Maybe<Array<Maybe<Array<Scalars['Float']['output']>>>>;
};

export type CesiumEntity = {
  __typename?: 'CesiumEntity';
  entityType?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  positions?: Maybe<Array<Maybe<Array<Scalars['Float']['output']>>>>;
};

export type CesiumIonProcess = {
  __typename?: 'CesiumIonProcess';
  account?: Maybe<Account>;
  accountId?: Maybe<Scalars['ID']['output']>;
  captureId?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdByUserId?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  kind?: Maybe<Scalars['String']['output']>;
  lastStep?: Maybe<Scalars['String']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  renderObjectId?: Maybe<Scalars['ID']['output']>;
  sceneEntityId?: Maybe<Scalars['ID']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  steps?: Maybe<Array<Maybe<StepField>>>;
  user?: Maybe<User>;
};

export type CesiumIonRenderObject = {
  __typename?: 'CesiumIonRenderObject';
  accountId?: Maybe<Scalars['ID']['output']>;
  cesiumIonId?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  kind?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  process?: Maybe<Process>;
  processId?: Maybe<Scalars['ID']['output']>;
  rootFolderNodeId?: Maybe<Scalars['ID']['output']>;
  usedBySceneEntityIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
};

export type CreateAccountAnnotationTemplateInput = {
  colorFromField?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  fields?: InputMaybe<Array<InputMaybe<AnnotationTemplateFieldInput>>>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type CreateAnnotationGroupInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  projectId?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateAnnotationInput = {
  annotation2d?: InputMaybe<Annotation2dInput>;
  annotation3d?: InputMaybe<Annotation3dInput>;
  color?: InputMaybe<Scalars['String']['input']>;
  fields?: InputMaybe<Array<InputMaybe<AnnotationFieldInput>>>;
  groupId?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  processId?: InputMaybe<Scalars['ID']['input']>;
  projectId?: InputMaybe<Scalars['ID']['input']>;
  templateId?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateAssetInput = {
  bucket?: InputMaybe<Scalars['String']['input']>;
  captureId?: InputMaybe<Scalars['String']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Float']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  usageType?: InputMaybe<Scalars['String']['input']>;
};

export type CreateCaptureInput = {
  captureDate: Scalars['String']['input'];
  epsgCode?: InputMaybe<Scalars['Float']['input']>;
  formatType: Scalars['String']['input'];
  generalInformation?: InputMaybe<Scalars['String']['input']>;
  isUserCreated?: InputMaybe<Scalars['Boolean']['input']>;
  latitude?: InputMaybe<Scalars['Float']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  name: Scalars['String']['input'];
  rendererType?: InputMaybe<Scalars['String']['input']>;
  siteId: Scalars['ID']['input'];
};

export type CreateDefectInput = {
  annotationPoints?: InputMaybe<Array<InputMaybe<Array<Scalars['Float']['input']>>>>;
  buildingId?: InputMaybe<Scalars['ID']['input']>;
  captureId?: InputMaybe<Scalars['ID']['input']>;
  cesiumEntityType?: InputMaybe<Scalars['String']['input']>;
  comments?: InputMaybe<Scalars['String']['input']>;
  defectStatusId?: InputMaybe<Scalars['ID']['input']>;
  defectTypeId?: InputMaybe<Scalars['ID']['input']>;
  elementId?: InputMaybe<Scalars['ID']['input']>;
  fileId?: InputMaybe<Scalars['ID']['input']>;
  isUserDefined?: InputMaybe<Scalars['Boolean']['input']>;
  location3d?: InputMaybe<Array<InputMaybe<Array<Scalars['Float']['input']>>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  priorityId?: InputMaybe<Scalars['ID']['input']>;
  relativePositions?: InputMaybe<Array<InputMaybe<Array<Scalars['Float']['input']>>>>;
  roofConstructionTypeId?: InputMaybe<Scalars['ID']['input']>;
  roofMaterialTypeId?: InputMaybe<Scalars['ID']['input']>;
  sceneCaptures?: InputMaybe<CreateDefectSceneCaptures>;
  shapeType2d?: InputMaybe<Scalars['String']['input']>;
  siteId?: InputMaybe<Scalars['ID']['input']>;
  storageIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  taskTypeId?: InputMaybe<Scalars['ID']['input']>;
  userDefinedColor?: InputMaybe<Scalars['String']['input']>;
  webLinks?: InputMaybe<Array<InputMaybe<CreateDefectWebLinks>>>;
};

export type CreateDefectSceneCaptures = {
  customizedSceneCaptureId?: InputMaybe<Scalars['ID']['input']>;
  defaultSceneCaptureId?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateDefectWebLinks = {
  name?: InputMaybe<Scalars['String']['input']>;
  uri?: InputMaybe<Scalars['String']['input']>;
};

export type CreateFileInput = {
  bucket?: InputMaybe<Scalars['String']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  kind?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  parentNodeId?: InputMaybe<Scalars['ID']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Float']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type CreateFileNodeInput = {
  fileId?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  parentNodeId?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateFolderNodeInput = {
  name: Scalars['String']['input'];
  parentNodeId?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateImageInput = {
  bucket?: InputMaybe<Scalars['String']['input']>;
  captureId?: InputMaybe<Scalars['String']['input']>;
  imageFormat?: InputMaybe<Scalars['String']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Float']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  usageType?: InputMaybe<Scalars['String']['input']>;
};

export type CreateLeadInput = {
  company?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
};

export type CreateLegacyReportInput = {
  buildingIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  captureIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  defectStatusIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  defectStatusesSelectAll?: InputMaybe<Scalars['Boolean']['input']>;
  defectTypeIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  defectTypesSelectAll?: InputMaybe<Scalars['Boolean']['input']>;
  elementIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  elementsSelectAll?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  prioritiesSelectAll?: InputMaybe<Scalars['Boolean']['input']>;
  priorityIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  roofMaterialTypeIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  roofMaterialTypesSelectAll?: InputMaybe<Scalars['Boolean']['input']>;
  siteIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type CreateMultipartUploadObjectType = {
  __typename?: 'CreateMultipartUploadObjectType';
  bucket?: Maybe<Scalars['String']['output']>;
  fileId?: Maybe<Scalars['String']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  uploadId?: Maybe<Scalars['String']['output']>;
};

export type CreateProjectAnnotationTemplateInput = {
  colorFromField?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  fields?: InputMaybe<Array<InputMaybe<AnnotationTemplateFieldInput>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  projectId?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateReportInput = {
  appendix?: InputMaybe<AppendixInputField>;
  buildingIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  captureIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  defectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  objectOfInterestIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  renderStatus?: InputMaybe<RenderStatusInputField>;
  siteIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  summary?: InputMaybe<Scalars['String']['input']>;
};

export type CreateSiteInput = {
  country?: InputMaybe<Scalars['String']['input']>;
  date?: InputMaybe<Scalars['String']['input']>;
  geoid?: InputMaybe<Scalars['String']['input']>;
  latitude?: InputMaybe<Scalars['Float']['input']>;
  locality?: InputMaybe<Scalars['String']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  name: Scalars['String']['input'];
  postalCode?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  siteAssetsNeeded?: InputMaybe<Scalars['Boolean']['input']>;
  streetAddress?: InputMaybe<Scalars['String']['input']>;
};

export type CreateStorageInput = {
  bucket?: InputMaybe<Scalars['String']['input']>;
  captureId?: InputMaybe<Scalars['ID']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Float']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  usageType?: InputMaybe<Scalars['String']['input']>;
};

export type CreateSystemFolderNodeInput = {
  folderNodeDependants?: InputMaybe<Array<InputMaybe<SystemNodeDependantInput>>>;
  name: Scalars['String']['input'];
  parentNodeId?: InputMaybe<Scalars['ID']['input']>;
  tags?: InputMaybe<Array<InputMaybe<TagFieldInput>>>;
};

export type CreateTileset3dLinkInput = {
  bucketName: Scalars['String']['input'];
  bucketRegion: Scalars['String']['input'];
  captureId: Scalars['String']['input'];
  rootJsonFileKey: Scalars['String']['input'];
  usageType: Scalars['String']['input'];
};

export type CreateUpdateObjectOfInterestInput = {
  accountId?: InputMaybe<Scalars['ID']['input']>;
  annotationPoints?: InputMaybe<Array<InputMaybe<Array<Scalars['Float']['input']>>>>;
  attachments?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  captureId?: InputMaybe<Scalars['ID']['input']>;
  category?: InputMaybe<Scalars['String']['input']>;
  cesium?: InputMaybe<ObjectOfInterestCesiumInput>;
  fields?: InputMaybe<ObjectOfInterestFieldsInput>;
  fileId?: InputMaybe<Scalars['ID']['input']>;
  relativePositions?: InputMaybe<Array<InputMaybe<Array<Scalars['Float']['input']>>>>;
  sceneCaptures?: InputMaybe<ObjectOfInterestSceneCapturesInput>;
  shapeType2d?: InputMaybe<Scalars['String']['input']>;
  siteId?: InputMaybe<Scalars['ID']['input']>;
  webLinks?: InputMaybe<Array<InputMaybe<ObjectOfInterestWebLinksInput>>>;
};

export type CreateUserInSiteInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  siteId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type DataImportRequest = {
  __typename?: 'DataImportRequest';
  accountId?: Maybe<Scalars['ID']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  kind?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  parentNodeId?: Maybe<Scalars['ID']['output']>;
  persistType?: Maybe<Scalars['String']['output']>;
  priority?: Maybe<Scalars['String']['output']>;
  rootFolderImportRequestId?: Maybe<Scalars['ID']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type Defect = {
  __typename?: 'Defect';
  annotationPoints?: Maybe<Array<Maybe<Array<Scalars['Float']['output']>>>>;
  annotationToolId?: Maybe<Scalars['String']['output']>;
  belongsToObjectOfInterestGroup?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  buildingId?: Maybe<Scalars['ID']['output']>;
  capture?: Maybe<Capture>;
  captureId?: Maybe<Scalars['ID']['output']>;
  cesiumEntityType?: Maybe<Scalars['String']['output']>;
  cluster?: Maybe<Scalars['Boolean']['output']>;
  comments?: Maybe<Scalars['String']['output']>;
  customImageUrl?: Maybe<Scalars['String']['output']>;
  defectStatus?: Maybe<DefectStatus>;
  defectStatusId?: Maybe<Scalars['ID']['output']>;
  defectType?: Maybe<DefectType>;
  defectTypeId?: Maybe<Scalars['ID']['output']>;
  element?: Maybe<Element>;
  elementId?: Maybe<Scalars['ID']['output']>;
  file?: Maybe<File>;
  fileId?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  image?: Maybe<Image>;
  imageDownloadUrl?: Maybe<Scalars['String']['output']>;
  imageId?: Maybe<Scalars['ID']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  isUserDefined?: Maybe<Scalars['Boolean']['output']>;
  location?: Maybe<DefectLocation>;
  location3d?: Maybe<Array<Maybe<Array<Scalars['Float']['output']>>>>;
  lockedPolygon?: Maybe<Scalars['Boolean']['output']>;
  migratedLocation3d?: Maybe<Array<Maybe<Array<Scalars['Float']['output']>>>>;
  name?: Maybe<Scalars['String']['output']>;
  priority?: Maybe<Priority>;
  priorityId?: Maybe<Scalars['ID']['output']>;
  relativePositions?: Maybe<Array<Maybe<Array<Scalars['Float']['output']>>>>;
  relativeRotations?: Maybe<Array<Maybe<Array<Scalars['Float']['output']>>>>;
  roofConstructionType?: Maybe<RoofConstructionType>;
  roofConstructionTypeId?: Maybe<Scalars['ID']['output']>;
  roofMaterialType?: Maybe<RoofMaterialType>;
  roofMaterialTypeId?: Maybe<Scalars['ID']['output']>;
  sceneCaptures?: Maybe<DefectSceneCaptures>;
  shapeType2d?: Maybe<Scalars['String']['output']>;
  site?: Maybe<Site>;
  siteId?: Maybe<Scalars['ID']['output']>;
  storageIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  storages?: Maybe<Array<Maybe<Storage>>>;
  taskType?: Maybe<TaskType>;
  taskTypeId?: Maybe<Scalars['ID']['output']>;
  thumbnailImageUrl?: Maybe<Scalars['String']['output']>;
  userDefinedColor?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['ID']['output']>;
  webLinks?: Maybe<Array<Maybe<DefectWebLinks>>>;
};

export type DefectCustomImageUrlArgs = {
  expires?: InputMaybe<Scalars['Float']['input']>;
};

export type DefectImageDownloadUrlArgs = {
  expires?: InputMaybe<Scalars['Float']['input']>;
};

export type DefectImageUrlArgs = {
  expires?: InputMaybe<Scalars['Float']['input']>;
};

export type DefectThumbnailImageUrlArgs = {
  expires?: InputMaybe<Scalars['Float']['input']>;
};

export type DefectAnnotationField = {
  annotationPoints?: InputMaybe<Array<InputMaybe<Array<Scalars['Float']['input']>>>>;
  annotationToolId?: InputMaybe<Scalars['String']['input']>;
  buildingId?: InputMaybe<Scalars['ID']['input']>;
  comments?: InputMaybe<Scalars['String']['input']>;
  defectTypeId?: InputMaybe<Scalars['ID']['input']>;
  elementId?: InputMaybe<Scalars['ID']['input']>;
  priorityId?: InputMaybe<Scalars['ID']['input']>;
  taskTypeId?: InputMaybe<Scalars['ID']['input']>;
};

export type DefectLocation = {
  __typename?: 'DefectLocation';
  coordinates?: Maybe<Array<Scalars['Float']['output']>>;
  type?: Maybe<Scalars['String']['output']>;
};

export type DefectProcess = {
  __typename?: 'DefectProcess';
  account?: Maybe<Account>;
  accountId?: Maybe<Scalars['ID']['output']>;
  captureId?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdByUserId?: Maybe<Scalars['ID']['output']>;
  defects?: Maybe<Array<Maybe<Defect>>>;
  id?: Maybe<Scalars['ID']['output']>;
  imageProjectionRenderObjectId?: Maybe<Scalars['ID']['output']>;
  images?: Maybe<Array<Maybe<DefectProcessImage>>>;
  kind?: Maybe<Scalars['String']['output']>;
  lastStep?: Maybe<Scalars['String']['output']>;
  lastStepIsDone?: Maybe<Scalars['Boolean']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  renderObjectId?: Maybe<Scalars['ID']['output']>;
  sceneEntityId?: Maybe<Scalars['ID']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  steps?: Maybe<Array<Maybe<StepField>>>;
  user?: Maybe<User>;
  xmlFile?: Maybe<AssetFile>;
};

export type DefectProcessImage = {
  __typename?: 'DefectProcessImage';
  annotationToolLogs?: Maybe<Array<Maybe<AnnotationToolLog>>>;
  cameraRelativePosition?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  cameraRelativeRotation?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  defects?: Maybe<Array<Maybe<Defect>>>;
  fileName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  latitude?: Maybe<Scalars['String']['output']>;
  longitude?: Maybe<Scalars['String']['output']>;
  originalUrl?: Maybe<Scalars['String']['output']>;
  projectedRelativePosition?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  projectedRelativeRotation?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  status?: Maybe<Scalars['String']['output']>;
  thumbnailUrl?: Maybe<Scalars['String']['output']>;
};

export type DefectSceneCaptures = {
  __typename?: 'DefectSceneCaptures';
  customizedSceneCapture?: Maybe<Storage>;
  customizedSceneCaptureId?: Maybe<Scalars['ID']['output']>;
  defaultSceneCapture?: Maybe<Storage>;
  defaultSceneCaptureId?: Maybe<Scalars['ID']['output']>;
};

export type DefectStatus = {
  __typename?: 'DefectStatus';
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type DefectType = {
  __typename?: 'DefectType';
  color?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type DefectWebLinks = {
  __typename?: 'DefectWebLinks';
  name?: Maybe<Scalars['String']['output']>;
  uri?: Maybe<Scalars['String']['output']>;
};

export type DeleteSystemNodesByParentAndNodeIdsResult = {
  __typename?: 'DeleteSystemNodesByParentAndNodeIdsResult';
  deletedSystemNodeIds: Array<Maybe<Scalars['ID']['output']>>;
  nonDeletableSystemNodeIdsWithErrorDetails: Array<
    Maybe<NonDeletableSystemNodeIdsWithErrorDetails>
  >;
  success: Scalars['Boolean']['output'];
};

export type DeleteSystemNodesByParentAndNodeIdsResultErrorDetails = {
  __typename?: 'DeleteSystemNodesByParentAndNodeIdsResultErrorDetails';
  childNodeIds: Array<Maybe<Scalars['ID']['output']>>;
  dependants: Array<Maybe<SystemNodeDependant>>;
  linkNodeIds: Array<Maybe<Scalars['ID']['output']>>;
};

export type DeleteUserInSiteInput = {
  siteId: Scalars['ID']['input'];
  userInSiteId: Scalars['ID']['input'];
};

export type DxfProcess = {
  __typename?: 'DxfProcess';
  account?: Maybe<Account>;
  accountId?: Maybe<Scalars['ID']['output']>;
  captureId?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdByUserId?: Maybe<Scalars['ID']['output']>;
  epsgCode?: Maybe<Scalars['String']['output']>;
  fileId?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  kind?: Maybe<Scalars['String']['output']>;
  lastStep?: Maybe<Scalars['String']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  renderObjectId?: Maybe<Scalars['ID']['output']>;
  sceneEntityId?: Maybe<Scalars['ID']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  steps?: Maybe<Array<Maybe<StepField>>>;
  user?: Maybe<User>;
  worldPosition?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
};

export type DxfRenderObject = {
  __typename?: 'DxfRenderObject';
  accountId?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  epsgCode?: Maybe<Scalars['String']['output']>;
  file?: Maybe<File>;
  fileId?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  kind?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  process?: Maybe<Process>;
  processId?: Maybe<Scalars['ID']['output']>;
  rootFolderNodeId?: Maybe<Scalars['ID']['output']>;
  usedBySceneEntityIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  worldPosition?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
};

export type Element = {
  __typename?: 'Element';
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type Event = {
  __typename?: 'Event';
  eventName?: Maybe<Scalars['String']['output']>;
  invokeId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type ExternalShare = {
  __typename?: 'ExternalShare';
  expiredAt?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  objectOfInterestGroup?: Maybe<ObjectOfInterestGroup>;
  objectOfInterestGroupId?: Maybe<Scalars['ID']['output']>;
  revokedAt?: Maybe<Scalars['String']['output']>;
  sharedTo?: Maybe<Array<Maybe<ExternalSharedToInformation>>>;
  site?: Maybe<Site>;
  siteId?: Maybe<Scalars['ID']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type ExternalSharedToInformation = {
  __typename?: 'ExternalSharedToInformation';
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type FeatureEntitlement = {
  __typename?: 'FeatureEntitlement';
  enabled?: Maybe<Scalars['Boolean']['output']>;
  infinity?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  used?: Maybe<Scalars['Float']['output']>;
};

export type File = AssetFile | ImageFile | ObjFile | Ortho2dFile | Tileset3dFile;

export type FileCreatedData = {
  __typename?: 'FileCreatedData';
  file?: Maybe<File>;
  node?: Maybe<FileNode>;
};

export type FileIdempotencyKeyStorageAndMetadataInput = {
  idempotencyKey: Scalars['String']['input'];
  storage: FileStorageInput;
};

export type FileImportRequest = {
  __typename?: 'FileImportRequest';
  fileId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  kind?: Maybe<Scalars['String']['output']>;
};

export type FileItemInput = {
  fileIdempotencyKey: Scalars['String']['input'];
  fileKind: Scalars['String']['input'];
  fileName: Scalars['String']['input'];
  metadata: FileMetadataInput;
};

export enum FileKindInput {
  Asset = 'ASSET',
  Image = 'IMAGE',
  ImageThumbnail = 'IMAGE_THUMBNAIL',
  Obj = 'OBJ',
  Ortho_2D = 'ORTHO_2D',
  Tileset_3D = 'TILESET_3D',
}

export type FileMetadataInput = {
  name: Scalars['String']['input'];
  size: Scalars['Float']['input'];
};

export type FileNode = {
  __typename?: 'FileNode';
  accountId?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdUser?: Maybe<UserV2>;
  createdUserId?: Maybe<Scalars['ID']['output']>;
  dependants?: Maybe<Array<Maybe<SystemNodeDependant>>>;
  file?: Maybe<File>;
  fileId?: Maybe<Scalars['ID']['output']>;
  hasLinkNodes?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isDeletable?: Maybe<Scalars['Boolean']['output']>;
  isDeletableV2?: Maybe<Scalars['Boolean']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  kind?: Maybe<Scalars['String']['output']>;
  lastDownloadedAt?: Maybe<Scalars['String']['output']>;
  lastDownloadedUser?: Maybe<UserV2>;
  lastDownloadedUserId?: Maybe<Scalars['ID']['output']>;
  linkNodeIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  name?: Maybe<Scalars['String']['output']>;
  parentNodeId?: Maybe<Scalars['ID']['output']>;
  size?: Maybe<Scalars['Float']['output']>;
  tags?: Maybe<Array<Maybe<TagField>>>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type FileStorageField = {
  __typename?: 'FileStorageField';
  bucket?: Maybe<Scalars['String']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  size?: Maybe<Scalars['Float']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type FileStorageInput = {
  bucket: Scalars['String']['input'];
  key: Scalars['String']['input'];
  name: Scalars['String']['input'];
  size: Scalars['Float']['input'];
  type: Scalars['String']['input'];
};

export type FileWithPagination = {
  __typename?: 'FileWithPagination';
  data?: Maybe<Array<Maybe<File>>>;
  totalNumberOfFiles?: Maybe<Scalars['Int']['output']>;
  totalNumberOfPages?: Maybe<Scalars['Int']['output']>;
};

export type FindFolderImportRequestByDataImportRequestInput = {
  dataImportRequestId?: InputMaybe<Scalars['ID']['input']>;
  limit: Scalars['Int']['input'];
  skip: Scalars['Int']['input'];
};

export type FolderIdempotencyKeyAndNameInput = {
  idempotencyKey: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type FolderImportRequest = {
  __typename?: 'FolderImportRequest';
  dataImportRequestId?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type FolderNode = {
  __typename?: 'FolderNode';
  accountId?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdUser?: Maybe<UserV2>;
  createdUserId?: Maybe<Scalars['ID']['output']>;
  dependants?: Maybe<Array<Maybe<SystemNodeDependant>>>;
  hasLinkNodes?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isAddable?: Maybe<Scalars['Boolean']['output']>;
  isDeletable?: Maybe<Scalars['Boolean']['output']>;
  isDeletableV2?: Maybe<Scalars['Boolean']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  kind?: Maybe<Scalars['String']['output']>;
  lastDownloadedAt?: Maybe<Scalars['String']['output']>;
  lastDownloadedUser?: Maybe<UserV2>;
  lastDownloadedUserId?: Maybe<Scalars['ID']['output']>;
  linkNodeIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  name?: Maybe<Scalars['String']['output']>;
  parentNodeId?: Maybe<Scalars['ID']['output']>;
  tags?: Maybe<Array<Maybe<TagField>>>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type GroupOfCaptures = {
  __typename?: 'GroupOfCaptures';
  captureIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type GroupingPolicy = {
  __typename?: 'GroupingPolicy';
  accountId?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  sourceId?: Maybe<Scalars['ID']['output']>;
  sourceType?: Maybe<Scalars['String']['output']>;
  targetId?: Maybe<Scalars['ID']['output']>;
  targetType?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type GroupingPolicyInput = {
  accountId: Scalars['String']['input'];
  sourceId: Scalars['String']['input'];
  sourceType: PolicySubjectTypeInput;
  targetId: Scalars['String']['input'];
  targetType: PolicySubjectTypeInput;
};

export enum Hierarchy {
  Account = 'ACCOUNT',
  Capture = 'CAPTURE',
  Site = 'SITE',
  System = 'SYSTEM',
}

export type IfcProcess = {
  __typename?: 'IfcProcess';
  account?: Maybe<Account>;
  accountId?: Maybe<Scalars['ID']['output']>;
  captureId?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdByUserId?: Maybe<Scalars['ID']['output']>;
  epsgCode?: Maybe<Scalars['String']['output']>;
  fileId?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  kind?: Maybe<Scalars['String']['output']>;
  lastStep?: Maybe<Scalars['String']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  renderObjectId?: Maybe<Scalars['ID']['output']>;
  sceneEntityId?: Maybe<Scalars['ID']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  steps?: Maybe<Array<Maybe<StepField>>>;
  user?: Maybe<User>;
  worldPosition?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
};

export type IfcRenderObject = {
  __typename?: 'IfcRenderObject';
  accountId?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  epsgCode?: Maybe<Scalars['String']['output']>;
  file?: Maybe<File>;
  fileId?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  kind?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  process?: Maybe<Process>;
  processId?: Maybe<Scalars['ID']['output']>;
  rootFolderNodeId?: Maybe<Scalars['ID']['output']>;
  usedBySceneEntityIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  worldPosition?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
};

export type Image = {
  __typename?: 'Image';
  altitude?: Maybe<Scalars['String']['output']>;
  annotationToolLogs?: Maybe<Array<Maybe<AnnotationToolLog>>>;
  bearing?: Maybe<Scalars['Float']['output']>;
  building?: Maybe<Building>;
  buildingId?: Maybe<Scalars['ID']['output']>;
  cameraPosition?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  capture?: Maybe<Capture>;
  captureId?: Maybe<Scalars['ID']['output']>;
  captureSource?: Maybe<CaptureSource>;
  captureSourceId?: Maybe<Scalars['ID']['output']>;
  defects?: Maybe<Array<Maybe<Defect>>>;
  excluded?: Maybe<Scalars['Boolean']['output']>;
  failures?: Maybe<Scalars['String']['output']>;
  fileName?: Maybe<Scalars['String']['output']>;
  flip?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  imageFormat?: Maybe<Scalars['String']['output']>;
  imageType?: Maybe<Scalars['String']['output']>;
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  originalDownloadUrl?: Maybe<Scalars['String']['output']>;
  originalImageStorageField?: Maybe<OriginalImageStorageField>;
  originalUrl?: Maybe<Scalars['String']['output']>;
  pitch?: Maybe<Scalars['Float']['output']>;
  projectedPosition?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  published?: Maybe<Scalars['Boolean']['output']>;
  silverbraneImageId?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  thumbnailUrl?: Maybe<Scalars['String']['output']>;
  uploaded?: Maybe<Scalars['Boolean']['output']>;
  userTouch?: Maybe<Scalars['String']['output']>;
  wrongAltitude?: Maybe<Scalars['Boolean']['output']>;
  wrongBearing?: Maybe<Scalars['Boolean']['output']>;
};

export type ImageOriginalDownloadUrlArgs = {
  expires?: InputMaybe<Scalars['Float']['input']>;
};

export type ImageOriginalUrlArgs = {
  expires?: InputMaybe<Scalars['Float']['input']>;
};

export type ImageThumbnailUrlArgs = {
  expires?: InputMaybe<Scalars['Float']['input']>;
};

export type ImageField = {
  __typename?: 'ImageField';
  cameraModelId?: Maybe<Scalars['ID']['output']>;
  cameraRelativePosition?: Maybe<Array<Scalars['Float']['output']>>;
  cameraRelativeRotation?: Maybe<Array<Scalars['Float']['output']>>;
  extrinsicPosition?: Maybe<Array<Scalars['Float']['output']>>;
  extrinsicRotation?: Maybe<Array<Scalars['Float']['output']>>;
  extrinsicScale?: Maybe<Array<Scalars['Float']['output']>>;
  file?: Maybe<File>;
  fileId?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  projectedRelativePosition?: Maybe<Array<Scalars['Float']['output']>>;
  projectedRelativeRotation?: Maybe<Array<Scalars['Float']['output']>>;
};

export type ImageFile = {
  __typename?: 'ImageFile';
  accountId?: Maybe<Scalars['ID']['output']>;
  canDelete?: Maybe<Scalars['Boolean']['output']>;
  captures?: Maybe<Array<Maybe<Capture>>>;
  createThumbnailEvent?: Maybe<Event>;
  createTilesEvent?: Maybe<Event>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  fileName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  kind?: Maybe<Scalars['String']['output']>;
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  originalUrl?: Maybe<Scalars['String']['output']>;
  renderObjectIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  renderObjects?: Maybe<Array<Maybe<RenderObject>>>;
  signedGetObjectDownloadUrl?: Maybe<Scalars['String']['output']>;
  signedGetObjectUrl?: Maybe<Scalars['String']['output']>;
  sites?: Maybe<Array<Maybe<Site>>>;
  storage?: Maybe<FileStorageField>;
  storageStatus?: Maybe<Scalars['String']['output']>;
  thumbnailUrl?: Maybe<Scalars['String']['output']>;
  tiles?: Maybe<ImageTilesMetadata>;
};

export type ImageProjectionProcess = {
  __typename?: 'ImageProjectionProcess';
  account?: Maybe<Account>;
  accountId?: Maybe<Scalars['ID']['output']>;
  captureId?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdByUserId?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  imageFiles?: Maybe<Array<Maybe<ImageFile>>>;
  imagesWithStatus?: Maybe<Array<Maybe<ImagesWithStatusField>>>;
  kind?: Maybe<Scalars['String']['output']>;
  lastStep?: Maybe<Scalars['String']['output']>;
  lastStepIsDone?: Maybe<Scalars['Boolean']['output']>;
  projectId?: Maybe<Scalars['ID']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  renderObjectId?: Maybe<Scalars['ID']['output']>;
  sceneEntityId?: Maybe<Scalars['ID']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  steps?: Maybe<Array<Maybe<StepField>>>;
  user?: Maybe<User>;
  xmlFile?: Maybe<AssetFile>;
};

export type ImageProjectionRenderObject = {
  __typename?: 'ImageProjectionRenderObject';
  accountId?: Maybe<Scalars['ID']['output']>;
  cameraModels?: Maybe<Array<Maybe<CameraModelField>>>;
  createdAt?: Maybe<Scalars['String']['output']>;
  hasCameraModels?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  images?: Maybe<Array<Maybe<ImageField>>>;
  kind?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  process?: Maybe<Process>;
  processId?: Maybe<Scalars['ID']['output']>;
  rootFolderNodeId?: Maybe<Scalars['ID']['output']>;
  usedBySceneEntityIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
};

export type ImageProjectionRenderObjectImage = {
  cameraModelId?: InputMaybe<Scalars['ID']['input']>;
  cameraRelativePosition?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  cameraRelativeRotation?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  extrinsicPosition?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  extrinsicRotation?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  extrinsicScale?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  fileId?: InputMaybe<Scalars['ID']['input']>;
  imageProjectionRenderObjectId?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  projectedRelativePosition?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  projectedRelativeRotation?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
};

export type ImageTile = {
  __typename?: 'ImageTile';
  col?: Maybe<Scalars['Int']['output']>;
  row?: Maybe<Scalars['Int']['output']>;
  signedUrl?: Maybe<Scalars['String']['output']>;
};

export type ImageTiles = {
  __typename?: 'ImageTiles';
  fileId?: Maybe<Scalars['String']['output']>;
  tilesWithSignedUrls?: Maybe<Array<Maybe<ImageTile>>>;
};

export type ImageTilesMetadata = {
  __typename?: 'ImageTilesMetadata';
  cols?: Maybe<Scalars['Int']['output']>;
  rows?: Maybe<Scalars['Int']['output']>;
};

export type ImagesWithStatusField = {
  __typename?: 'ImagesWithStatusField';
  cameraRelativePosition?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  cameraRelativeRotation?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  fileId?: Maybe<Scalars['ID']['output']>;
  fileName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  projectedRelativePosition?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  projectedRelativeRotation?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  signedGetObjectUrl?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  thumbnailUrl?: Maybe<Scalars['String']['output']>;
};

export type InputTagInput = {
  key: Scalars['String']['input'];
  operator: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export enum Kind {
  Number = 'NUMBER',
  Select = 'SELECT',
  String = 'STRING',
}

export type KmlProcess = {
  __typename?: 'KmlProcess';
  account?: Maybe<Account>;
  accountId?: Maybe<Scalars['ID']['output']>;
  captureId?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdByUserId?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  kind?: Maybe<Scalars['String']['output']>;
  lastStep?: Maybe<Scalars['String']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  renderObjectId?: Maybe<Scalars['ID']['output']>;
  sceneEntityId?: Maybe<Scalars['ID']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  steps?: Maybe<Array<Maybe<StepField>>>;
  user?: Maybe<User>;
};

export type KmlRenderObject = {
  __typename?: 'KmlRenderObject';
  accountId?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  file?: Maybe<File>;
  fileId?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  kind?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  process?: Maybe<Process>;
  processId?: Maybe<Scalars['ID']['output']>;
  rootFolderNodeId?: Maybe<Scalars['ID']['output']>;
  usedBySceneEntityIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
};

export type Layer = {
  __typename?: 'Layer';
  accountId?: Maybe<Scalars['ID']['output']>;
  captureDate?: Maybe<Scalars['String']['output']>;
  defaultMaxHeight?: Maybe<Scalars['Float']['output']>;
  defaultMinHeight?: Maybe<Scalars['Float']['output']>;
  formatType?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  mainSceneEntityId?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  projectId?: Maybe<Scalars['ID']['output']>;
  rendererType?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  supportingSceneEntityIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  worldPosition?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  worldRotation?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
};

export enum LayerStatusInput {
  Deleted = 'DELETED',
  Deleting = 'DELETING',
  Processing = 'PROCESSING',
  Published = 'PUBLISHED',
  Unpublished = 'UNPUBLISHED',
}

export type Lead = {
  __typename?: 'Lead';
  company?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type LinkNode = {
  __typename?: 'LinkNode';
  accountId?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdUser?: Maybe<UserV2>;
  createdUserId?: Maybe<Scalars['ID']['output']>;
  dependants?: Maybe<Array<Maybe<SystemNodeDependant>>>;
  id?: Maybe<Scalars['ID']['output']>;
  isDeletableV2?: Maybe<Scalars['Boolean']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  kind?: Maybe<Scalars['String']['output']>;
  lastDownloadedAt?: Maybe<Scalars['String']['output']>;
  lastDownloadedUser?: Maybe<UserV2>;
  lastDownloadedUserId?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  parentNodeId?: Maybe<Scalars['ID']['output']>;
  sourceNode?: Maybe<Node>;
  sourceNodeId?: Maybe<Scalars['ID']['output']>;
  tags?: Maybe<Array<Maybe<TagField>>>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type LoginToken = {
  __typename?: 'LoginToken';
  accountId?: Maybe<Scalars['ID']['output']>;
  bearerToken?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
};

export type Measurement = {
  __typename?: 'Measurement';
  captureId?: Maybe<Scalars['ID']['output']>;
  cesiumEntity?: Maybe<CesiumEntity>;
  cesiumEntityId?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

export type MigrationResult = {
  __typename?: 'MigrationResult';
  errorMessages?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  numOfFulfilled?: Maybe<Scalars['Int']['output']>;
};

export type Model = {
  __typename?: 'Model';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type MultiPartInput = {
  etag?: InputMaybe<Scalars['String']['input']>;
  partNumber?: InputMaybe<Scalars['Int']['input']>;
};

export type MultipartUploadPart = {
  __typename?: 'MultipartUploadPart';
  ETag?: Maybe<Scalars['String']['output']>;
  PartNumber?: Maybe<Scalars['String']['output']>;
  Size?: Maybe<Scalars['String']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  AddFilesToImageProjectionProcess?: Maybe<ImageProjectionProcess>;
  AddParentToSceneEntity?: Maybe<SceneEntity>;
  CreateSceneEntityGroup?: Maybe<SceneEntity>;
  DeleteSceneEntityGroup?: Maybe<Scalars['String']['output']>;
  RemoveParentFromSceneEntity?: Maybe<SceneEntity>;
  abortMultipartUpload?: Maybe<Scalars['Boolean']['output']>;
  abortMultipartUploadForFileImportRequest?: Maybe<Scalars['Boolean']['output']>;
  acceptInvitation?: Maybe<User>;
  activateUser?: Maybe<Scalars['String']['output']>;
  addDependantsToSystemNodes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  addFilesIntoSite?: Maybe<Scalars['Boolean']['output']>;
  addLegacyAnnotationTemplatesToProject?: Maybe<Scalars['Boolean']['output']>;
  addProcessStep?: Maybe<Process>;
  backfillAnnotationFieldWithoutFieldId?: Maybe<Scalars['String']['output']>;
  backfillAnnotationFieldWithoutName?: Maybe<Scalars['String']['output']>;
  backfillAnnotationTemplateSelectOptionWithoutId?: Maybe<Scalars['String']['output']>;
  backfillAnnotationTemplateWithoutFieldId?: Maybe<Scalars['String']['output']>;
  bulkSubmitImagesByCaptureSource?: Maybe<Scalars['String']['output']>;
  completeMultipartUpload?: Maybe<Scalars['String']['output']>;
  completeMultipartUploadForFileImportRequest?: Maybe<Scalars['String']['output']>;
  createAccountAnnotationTemplate?: Maybe<AnnotationTemplate>;
  createAccountFiles?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  createAnnotation?: Maybe<Annotation>;
  createAnnotationGroup?: Maybe<SceneEntity>;
  createAnnotationProcess?: Maybe<AnnotationProcess>;
  createAnnotationSystemNodes?: Maybe<Scalars['Boolean']['output']>;
  createAsset?: Maybe<Asset>;
  createAssetLinkToS3?: Maybe<Asset>;
  createCapture?: Maybe<Capture>;
  createCaptureAddon?: Maybe<Capture>;
  createCaptureFiles?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  createCesiumIonProcess?: Maybe<CesiumIonProcess>;
  createCesiumIonProcessWithoutCapture?: Maybe<CesiumIonProcess>;
  createDataImportRequest?: Maybe<Scalars['String']['output']>;
  createDataImportRequestV2?: Maybe<Scalars['String']['output']>;
  createDefaultFoldersForProjects?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  createDefect?: Maybe<Defect>;
  createDefectProcess?: Maybe<DefectProcess>;
  createDxfProcess?: Maybe<DxfProcess>;
  createExternalShare?: Maybe<ExternalShare>;
  createFile?: Maybe<File>;
  createFileNode?: Maybe<FileNode>;
  createFileNodeForFolderImportRequest?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  createFileV2?: Maybe<FileCreatedData>;
  createFolderImportRequestForDataImportRequest?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  createFolderNode?: Maybe<FolderNode>;
  createFolderNodeForFolderImportRequest?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  createGroupingPolicies?: Maybe<Scalars['Boolean']['output']>;
  createIfcProcess?: Maybe<IfcProcess>;
  createImage?: Maybe<Image>;
  createImageLinkToS3?: Maybe<Image>;
  createImageProjectionProcess?: Maybe<ImageProjectionProcess>;
  createImageProjectionProcessForRenderObject?: Maybe<ImageProjectionProcess>;
  createImageProjectionProcessV2?: Maybe<ImageProjectionProcess>;
  createImageThumbnailsForPersistedImagesByIds?: Maybe<Scalars['Int']['output']>;
  createImageTilesForPersistedImagesByIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  createKmlProcess?: Maybe<KmlProcess>;
  createLead?: Maybe<Lead>;
  createLegacyReport?: Maybe<Report>;
  createLinkNodesBySourceNodeIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  createManyAnnotations?: Maybe<Scalars['Boolean']['output']>;
  createModel?: Maybe<Scalars['String']['output']>;
  createMultipartUpload?: Maybe<CreateMultipartUploadObjectType>;
  createMultipartUploadForFileImportRequest?: Maybe<CreateMultipartUploadObjectType>;
  createNodeShareLink?: Maybe<ShareLink>;
  createObjProcess?: Maybe<ObjProcess>;
  createObjectOfInterest?: Maybe<ObjectOfInterest>;
  createObjectOfInterestGroup?: Maybe<ObjectOfInterestGroup>;
  createOrtho2dProcess?: Maybe<Ortho2dProcess>;
  createPanoramicImage?: Maybe<PanoramicImage>;
  /** @deprecated This mutation will be renamed. Use createPanoramicProcessWithPanoramicWorkflow instead. */
  createPanoramicLayer?: Maybe<PanoramicProcess>;
  createPanoramicProcess?: Maybe<PanoramicProcess>;
  createPanoramicProcessV2?: Maybe<PanoramicProcess>;
  createPanoramicProcessWithPanoramicWorkflow?: Maybe<PanoramicProcess>;
  createPanoramicVideoLayer?: Maybe<PanoramicVideoProcess>;
  createPlatformManagedAWSS3ApplicationCredential?: Maybe<ApplicationCredential>;
  createPortalSession?: Maybe<Scalars['String']['output']>;
  createPresignedPartUrlForFileImportRequest?: Maybe<Scalars['String']['output']>;
  createPresignedUploadPartUrl?: Maybe<Scalars['String']['output']>;
  createProject?: Maybe<Project>;
  createProjectAnnotationTemplate?: Maybe<AnnotationTemplate>;
  createProjectFileNodesFromFiles?: Maybe<Scalars['Boolean']['output']>;
  createProjectFileNodesWithNodeTree?: Maybe<ProjectFileNode>;
  createProjectFolderNode?: Maybe<ProjectFolderNode>;
  createProjectGroup?: Maybe<ProjectGroup>;
  createProjectV2?: Maybe<Project>;
  createQuoteForCharges?: Maybe<Scalars['Boolean']['output']>;
  createRenderObjectRootFolderNode?: Maybe<Scalars['String']['output']>;
  createRenderObjectSystemNodesForFileIdsUseCase?: Maybe<Scalars['String']['output']>;
  createReport?: Maybe<Report>;
  createReportV2?: Maybe<ReportV2>;
  createRole?: Maybe<Scalars['ID']['output']>;
  createRootFolderForAnnotations?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  createRootFolderForRenderObjects?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  createShareLink?: Maybe<ShareLink>;
  createShareSiteAnalytics?: Maybe<ShareSiteAnalytics>;
  createSite?: Maybe<Site>;
  createStorage?: Maybe<Storage>;
  createSubscriptionPortalSession?: Maybe<Scalars['String']['output']>;
  createSystemFolderNode?: Maybe<FolderNode>;
  createSystemFolderNodeV2?: Maybe<FolderNode>;
  createSystemNodesForAnnotationFiles?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  createSystemNodesForRenderObjectFilesByProjectIdAndRenderObjectId?: Maybe<
    Scalars['Boolean']['output']
  >;
  createSystemNodesForRenderObjectFilesByRenderObjectId?: Maybe<Scalars['Boolean']['output']>;
  createSystemNodesForRenderObjectsFiles?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  createTileset3dFromPointCloudAssets?: Maybe<Scalars['Boolean']['output']>;
  createTileset3dFromZipAsset?: Maybe<Scalars['Boolean']['output']>;
  createTileset3dLinkToS3?: Maybe<Tileset3d>;
  createTileset3dProcessFromImages?: Maybe<Tileset3dProcess>;
  createTileset3dProcessFromPointCloud?: Maybe<Tileset3dProcess>;
  createTileset3dProcessFromZip?: Maybe<Tileset3dProcess>;
  createUserGroup?: Maybe<Scalars['ID']['output']>;
  createUserInSite?: Maybe<Array<Maybe<UsersInSite>>>;
  createZipRequestByCaptureId?: Maybe<Scalars['Boolean']['output']>;
  createZipRequestByCaptureIdAndStorageIds?: Maybe<Scalars['Boolean']['output']>;
  deactivateUser?: Maybe<Scalars['String']['output']>;
  deleteAnnotation?: Maybe<Annotation>;
  deleteAnnotationGroup?: Maybe<Scalars['Boolean']['output']>;
  deleteAnnotationProcess?: Maybe<Scalars['String']['output']>;
  deleteAnnotationTemplate?: Maybe<Scalars['String']['output']>;
  deleteAnnotationV2?: Maybe<Annotation>;
  deleteAnnotations?: Maybe<Array<Maybe<Annotation>>>;
  deleteAssetLinkToS3?: Maybe<Scalars['Boolean']['output']>;
  deleteAssets?: Maybe<Scalars['Boolean']['output']>;
  deleteBuilding?: Maybe<Building>;
  deleteCapture?: Maybe<Scalars['Boolean']['output']>;
  deleteCaptureAndSceneEntities?: Maybe<Scalars['Boolean']['output']>;
  deleteDefect?: Maybe<Defect>;
  deleteExternalShareById?: Maybe<ExternalShare>;
  deleteFiles?: Maybe<Scalars['Boolean']['output']>;
  deleteGroupingPolicies?: Maybe<Scalars['Boolean']['output']>;
  deleteImageLinkToS3?: Maybe<Scalars['Boolean']['output']>;
  deleteImages?: Maybe<Scalars['Boolean']['output']>;
  deleteLayer?: Maybe<Scalars['String']['output']>;
  deleteNodesAndFiles?: Maybe<Scalars['Boolean']['output']>;
  deleteObjectOfInterest?: Maybe<ObjectOfInterest>;
  deletePanoramicImage?: Maybe<PanoramicImage>;
  deletePermissionPolicies?: Maybe<Scalars['Boolean']['output']>;
  deleteProject?: Maybe<Scalars['String']['output']>;
  deleteProjectGroup?: Maybe<Scalars['String']['output']>;
  deleteProjectNodes?: Maybe<Scalars['Boolean']['output']>;
  deleteProjectSystemNodesByParentAndNodeIds?: Maybe<DeleteSystemNodesByParentAndNodeIdsResult>;
  deleteProjectV2?: Maybe<Scalars['String']['output']>;
  deleteRenderObject?: Maybe<RenderObject>;
  deleteReport?: Maybe<Report>;
  deleteReportV2?: Maybe<Scalars['Boolean']['output']>;
  deleteRole?: Maybe<Scalars['ID']['output']>;
  deleteSceneEntityWithId?: Maybe<SceneEntity>;
  deleteShareLink?: Maybe<Scalars['String']['output']>;
  deleteSite?: Maybe<Scalars['Boolean']['output']>;
  deleteStorage?: Maybe<Scalars['String']['output']>;
  deleteSystemNode?: Maybe<Scalars['Boolean']['output']>;
  deleteSystemNodesByParentAndNodeIds?: Maybe<DeleteSystemNodesByParentAndNodeIdsResult>;
  deleteTileset3dLinkToS3?: Maybe<Scalars['Boolean']['output']>;
  deleteTileset3ds?: Maybe<Scalars['Boolean']['output']>;
  deleteUser?: Maybe<User>;
  deleteUserGroup?: Maybe<Scalars['ID']['output']>;
  deleteUserInSite?: Maybe<Scalars['Boolean']['output']>;
  downloadNodes?: Maybe<Scalars['Boolean']['output']>;
  downloadProjectNodes?: Maybe<Scalars['Boolean']['output']>;
  downloadRenderObjectFiles?: Maybe<Scalars['Boolean']['output']>;
  downloadSites?: Maybe<Scalars['Boolean']['output']>;
  downloadSystemNodes?: Maybe<Scalars['Boolean']['output']>;
  fixExistingRenderObjectsRootFolderNodeParent?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  fixSystemFileNodesWithSameFileId?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  generateReportPdfV2?: Maybe<ReportV2>;
  hardDeleteNodesAndFiles?: Maybe<Scalars['Boolean']['output']>;
  hardDeleteNodesAndFilesV2?: Maybe<Scalars['Boolean']['output']>;
  insertManyImageProjectionProcessImagesStatus?: Maybe<Scalars['Boolean']['output']>;
  insertManyImageProjectionRenderObjectImages?: Maybe<Scalars['Boolean']['output']>;
  insertManyPanoramicProcessImagesStatus?: Maybe<Scalars['Boolean']['output']>;
  insertManyPanoramicRenderObjectImages?: Maybe<Scalars['Boolean']['output']>;
  inviteUser?: Maybe<User>;
  inviteUserV2?: Maybe<UserV2>;
  launchPanoramicVideoWorkflowWithFileNodeId?: Maybe<PanoramicVideoProcess>;
  launchPanoramicWorkflowWithFileId?: Maybe<PanoramicProcess>;
  linkIdentity?: Maybe<Scalars['Boolean']['output']>;
  linkIdentityIssuer?: Maybe<Scalars['Boolean']['output']>;
  linkTilesetWithCapture?: Maybe<Capture>;
  linkXmlWithCapture?: Maybe<Capture>;
  login?: Maybe<LoginToken>;
  loginWithIdpToken?: Maybe<LoginToken>;
  loginWithOneTimeToken?: Maybe<LoginToken>;
  logout?: Maybe<Scalars['String']['output']>;
  migrateAnnotations?: Maybe<MigrationResult>;
  migrateProjectFileNodesIntoSystemNodes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  migrateProjectFolderNodesIntoSystemFolderNodes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  moveAnnotationsToGroup?: Maybe<Array<Maybe<Annotation>>>;
  moveProjectsToGroup?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  persistAssets?: Maybe<Scalars['Boolean']['output']>;
  persistCaptureFilesInTemporaryBucket?: Maybe<Capture>;
  persistFiles?: Maybe<Scalars['Boolean']['output']>;
  persistImages?: Maybe<Scalars['Boolean']['output']>;
  persistNonPersistedFilesWithAccountId?: Maybe<Scalars['Boolean']['output']>;
  prepareProjectFolderNodesForSystemFolderNodesMigration?: Maybe<
    Array<Maybe<Scalars['String']['output']>>
  >;
  publishCapture?: Maybe<Capture>;
  recoverFiles?: Maybe<Scalars['Boolean']['output']>;
  recoverNodesAndFiles?: Maybe<Scalars['Boolean']['output']>;
  refreshReport?: Maybe<Report>;
  removeDuplicateAnnotationActiveVersionByDocumentId?: Maybe<Annotation>;
  removeFilesFromSite?: Maybe<Scalars['Boolean']['output']>;
  removeMigrationDuplicatedLinkNodes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  removeMigrationDuplicatedLinkNodesV2?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  removeSupportingSceneEntityFromCapture?: Maybe<Capture>;
  resendInvitationToUser?: Maybe<Scalars['Boolean']['output']>;
  resetAccountFileUsage?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  resetPassword?: Maybe<User>;
  resetPasswordRequest?: Maybe<Scalars['String']['output']>;
  resolveAllSystemNodesNameConflicts?: Maybe<Scalars['Boolean']['output']>;
  restoreAnnotationToHistoryVersion?: Maybe<Annotation>;
  restoreProjectFileNodeSourceSystemFileNodes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  sendOneTimeLoginToken?: Maybe<Scalars['Boolean']['output']>;
  submitAnnotationProcessImagesStatus?: Maybe<Scalars['Boolean']['output']>;
  submitDefectToDefectProcess?: Maybe<DefectProcess>;
  submitImageAnnotation?: Maybe<Image>;
  submitImageProjectionProcessImagesStatus?: Maybe<Scalars['Boolean']['output']>;
  submitPanoramicProcessImagesStatus?: Maybe<Scalars['Boolean']['output']>;
  trackAccountFileUsage?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  trackFilesForFolderImportRequest?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  triggerPrediction?: Maybe<Array<Maybe<TriggerPredictionResponseType>>>;
  triggerPredictionV2?: Maybe<Array<Maybe<TriggerPredictionResponseType>>>;
  triggerTrain?: Maybe<Scalars['String']['output']>;
  triggerTrainV2?: Maybe<Scalars['String']['output']>;
  unlinkIdentity?: Maybe<Scalars['Boolean']['output']>;
  unlinkIdentityIssuer?: Maybe<Scalars['Boolean']['output']>;
  untrackFilesStorage?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  updateAccount?: Maybe<Account>;
  updateAccountBranding?: Maybe<AccountV2>;
  updateAnnotation?: Maybe<Annotation>;
  updateAnnotationProcessName?: Maybe<AnnotationProcess>;
  updateAnnotationStatus?: Maybe<Scalars['Boolean']['output']>;
  updateAnnotationTemplate?: Maybe<AnnotationTemplate>;
  updateBuilding?: Maybe<Building>;
  updateCapture?: Maybe<Capture>;
  updateCaptureAddon?: Maybe<Capture>;
  updateCaptureMainSceneEntity?: Maybe<Capture>;
  updateCaptureWorldPositionAndWorldRotation?: Maybe<Capture>;
  updateCesiumIonProcessToDone?: Maybe<CesiumIonProcess>;
  updateDefect?: Maybe<Defect>;
  updateDefectRelativePositionsAndRelativeRotations?: Maybe<Defect>;
  updateDefects?: Maybe<Array<Maybe<Defect>>>;
  updateExternalShareById?: Maybe<ExternalShare>;
  updateFileNodeName?: Maybe<Scalars['Boolean']['output']>;
  updateFolderNodeName?: Maybe<Scalars['Boolean']['output']>;
  updateImageProjectionPositions?: Maybe<Image>;
  updateImageProjectionRenderObjectCameraModels?: Maybe<RenderObject>;
  updateImageProjectionRenderObjectImagePosition?: Maybe<RenderObject>;
  updateImageProjectionRenderObjectImages?: Maybe<RenderObject>;
  updateKmlProcessToCreate?: Maybe<KmlProcess>;
  updateKmlProcessToDone?: Maybe<KmlProcess>;
  updateKmlProcessToProcessing?: Maybe<KmlProcess>;
  updateKmlRenderingFile?: Maybe<KmlProcess>;
  updateLayerStatus?: Maybe<Scalars['String']['output']>;
  updateLegacyReport?: Maybe<Report>;
  updateNodesLastDownload?: Maybe<Scalars['Boolean']['output']>;
  updateNodesPosition?: Maybe<Scalars['Boolean']['output']>;
  updateObjectOfInterest?: Maybe<ObjectOfInterest>;
  updateObjectOfInterestGroup?: Maybe<ObjectOfInterestGroup>;
  updatePanoramicImage?: Maybe<PanoramicImage>;
  updatePanoramicProcessToReadyToDone?: Maybe<PanoramicProcess>;
  updatePanoramicProcessToReadyToReview?: Maybe<PanoramicProcess>;
  updatePanoramicRenderObjectImagePosition?: Maybe<RenderObject>;
  updatePanoramicRenderObjectImagePositions?: Maybe<RenderObject>;
  updatePermanentStorageApplicationCredential?: Maybe<Account>;
  updateProcessStep?: Maybe<Process>;
  updateProject?: Maybe<Project>;
  updateProjectGroupNameAndDescription?: Maybe<ProjectGroup>;
  updateProjectNodeName?: Maybe<ProjectNode>;
  updateProjectNodesPosition?: Maybe<Scalars['Boolean']['output']>;
  updateProjectStatus?: Maybe<Scalars['String']['output']>;
  updateReport?: Maybe<Report>;
  updateReportV2?: Maybe<ReportV2>;
  updateRole?: Maybe<Scalars['ID']['output']>;
  updateSceneEntityName?: Maybe<SceneEntity>;
  updateSceneEntityPositionAndRotation?: Maybe<SceneEntity>;
  updateShareLink?: Maybe<ShareLink>;
  updateSite?: Maybe<Site>;
  updateStatusForDataImportRequest?: Maybe<Scalars['Boolean']['output']>;
  updateStatusForFolderImportRequest?: Maybe<Scalars['String']['output']>;
  updateSuperAdminUser?: Maybe<Scalars['String']['output']>;
  updateSystemNodeName?: Maybe<Scalars['Boolean']['output']>;
  updateSystemNodesPosition?: Maybe<Scalars['Boolean']['output']>;
  updateSystemNodesPositionV2?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  updateTemporaryStorageApplicationCredential?: Maybe<Account>;
  updateTileset3dProcessToCreate?: Maybe<Tileset3dProcess>;
  updateTileset3dProcessToDone?: Maybe<Tileset3dProcess>;
  updateTileset3dProcessToProcessing?: Maybe<Tileset3dProcess>;
  updateUser?: Maybe<User>;
  updateUserById?: Maybe<User>;
  updateUserGroup?: Maybe<Scalars['ID']['output']>;
  updateUserInSite?: Maybe<Array<Maybe<UsersInSite>>>;
  uploadZipToTileset3dProcess?: Maybe<Tileset3dProcess>;
  upsertAnnotationProcessImagesStatusWithImageProjectionRenderObject?: Maybe<
    Scalars['Boolean']['output']
  >;
  upsertPermissionPolicies?: Maybe<Scalars['Boolean']['output']>;
  validateAndSubmitIngest?: Maybe<Capture>;
};

export type MutationAddFilesToImageProjectionProcessArgs = {
  imageFileIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  processId: Scalars['ID']['input'];
  xmlFileId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationAddParentToSceneEntityArgs = {
  parentSceneEntityId: Scalars['ID']['input'];
  position: SceneEntityPositionInput;
  projectId: Scalars['ID']['input'];
  rotation: SceneEntityRotationInput;
  sceneEntityId: Scalars['ID']['input'];
};

export type MutationCreateSceneEntityGroupArgs = {
  accountId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  parentSceneEntityId?: InputMaybe<Scalars['ID']['input']>;
  projectId: Scalars['ID']['input'];
};

export type MutationDeleteSceneEntityGroupArgs = {
  projectId: Scalars['ID']['input'];
  sceneEntityId: Scalars['ID']['input'];
};

export type MutationRemoveParentFromSceneEntityArgs = {
  position: SceneEntityPositionInput;
  projectId: Scalars['ID']['input'];
  rotation: SceneEntityRotationInput;
  sceneEntityId: Scalars['ID']['input'];
};

export type MutationAbortMultipartUploadArgs = {
  bucket: Scalars['String']['input'];
  key: Scalars['String']['input'];
  uploadId: Scalars['String']['input'];
};

export type MutationAbortMultipartUploadForFileImportRequestArgs = {
  bucket: Scalars['String']['input'];
  key: Scalars['String']['input'];
  uploadId: Scalars['String']['input'];
};

export type MutationAcceptInvitationArgs = {
  invitationToken?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
};

export type MutationActivateUserArgs = {
  userId: Scalars['ID']['input'];
};

export type MutationAddDependantsToSystemNodesArgs = {
  dependants?: InputMaybe<Array<InputMaybe<SystemNodeDependantInput>>>;
  systemNodeIds: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type MutationAddFilesIntoSiteArgs = {
  fileIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  siteId: Scalars['ID']['input'];
};

export type MutationAddLegacyAnnotationTemplatesToProjectArgs = {
  projectId: Scalars['ID']['input'];
};

export type MutationAddProcessStepArgs = {
  comment?: InputMaybe<Scalars['String']['input']>;
  processId: Scalars['ID']['input'];
  stepName: Scalars['String']['input'];
};

export type MutationBackfillAnnotationFieldWithoutFieldIdArgs = {
  annotationId: Scalars['ID']['input'];
};

export type MutationBackfillAnnotationFieldWithoutNameArgs = {
  annotationId: Scalars['ID']['input'];
};

export type MutationBackfillAnnotationTemplateSelectOptionWithoutIdArgs = {
  annotationTemplateId: Scalars['ID']['input'];
};

export type MutationBackfillAnnotationTemplateWithoutFieldIdArgs = {
  annotationTemplateId: Scalars['ID']['input'];
};

export type MutationBulkSubmitImagesByCaptureSourceArgs = {
  captureSourceId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationCompleteMultipartUploadArgs = {
  bucket: Scalars['String']['input'];
  key: Scalars['String']['input'];
  parts: Array<InputMaybe<MultiPartInput>>;
  uploadId: Scalars['String']['input'];
};

export type MutationCompleteMultipartUploadForFileImportRequestArgs = {
  bucket: Scalars['String']['input'];
  key: Scalars['String']['input'];
  parts: Array<InputMaybe<MultiPartInput>>;
  uploadId: Scalars['String']['input'];
};

export type MutationCreateAccountAnnotationTemplateArgs = {
  request?: InputMaybe<CreateAccountAnnotationTemplateInput>;
};

export type MutationCreateAccountFilesArgs = {
  storageIds: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type MutationCreateAnnotationArgs = {
  request?: InputMaybe<CreateAnnotationInput>;
};

export type MutationCreateAnnotationGroupArgs = {
  request?: InputMaybe<CreateAnnotationGroupInput>;
};

export type MutationCreateAnnotationProcessArgs = {
  imageProjectionRenderObjectId: Scalars['ID']['input'];
  layerId: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  requestQuote?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationCreateAnnotationSystemNodesArgs = {
  annotationId: Scalars['ID']['input'];
};

export type MutationCreateAssetArgs = {
  input?: InputMaybe<CreateAssetInput>;
};

export type MutationCreateAssetLinkToS3Args = {
  input?: InputMaybe<CreateAssetInput>;
};

export type MutationCreateCaptureArgs = {
  input?: InputMaybe<CreateCaptureInput>;
};

export type MutationCreateCaptureAddonArgs = {
  addonType: Scalars['String']['input'];
  captureId: Scalars['ID']['input'];
};

export type MutationCreateCaptureFilesArgs = {
  captureId: Scalars['ID']['input'];
  storageIds: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type MutationCreateCesiumIonProcessArgs = {
  captureId?: InputMaybe<Scalars['ID']['input']>;
  fileIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  rendererType?: InputMaybe<Scalars['String']['input']>;
};

export type MutationCreateCesiumIonProcessWithoutCaptureArgs = {
  cesiumIonId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type MutationCreateDataImportRequestArgs = {
  featureType: Scalars['String']['input'];
  name: Scalars['String']['input'];
  persistType: Scalars['String']['input'];
  priority: Scalars['String']['input'];
  rootFolderIdempotencyKeyAndName: FolderIdempotencyKeyAndNameInput;
  systemFolderNodeId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationCreateDataImportRequestV2Args = {
  dependants?: InputMaybe<Array<InputMaybe<SystemNodeDependantInput>>>;
  featureType: Scalars['String']['input'];
  name: Scalars['String']['input'];
  persistType: Scalars['String']['input'];
  priority: Scalars['String']['input'];
  rootFolderIdempotencyKeyAndName: FolderIdempotencyKeyAndNameInput;
  systemFolderNodeId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationCreateDefaultFoldersForProjectsArgs = {
  size?: InputMaybe<Scalars['Int']['input']>;
  start?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationCreateDefectArgs = {
  input?: InputMaybe<CreateDefectInput>;
};

export type MutationCreateDefectProcessArgs = {
  captureId: Scalars['ID']['input'];
  imageFileIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  imageProjectionRenderObjectId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationCreateDxfProcessArgs = {
  captureId?: InputMaybe<Scalars['ID']['input']>;
  epsgCode?: InputMaybe<Scalars['String']['input']>;
  fileId: Scalars['ID']['input'];
  worldPosition?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
};

export type MutationCreateExternalShareArgs = {
  name: Scalars['String']['input'];
  objectOfInterestGroupId?: InputMaybe<Scalars['ID']['input']>;
  siteId: Scalars['ID']['input'];
  withDefaultShare?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationCreateFileArgs = {
  accountId: Scalars['ID']['input'];
  bucket: Scalars['String']['input'];
  fileId: Scalars['ID']['input'];
  fileKind: FileKindInput;
  key: Scalars['String']['input'];
  name: Scalars['String']['input'];
  size: Scalars['Float']['input'];
  type: Scalars['String']['input'];
};

export type MutationCreateFileNodeArgs = {
  input?: InputMaybe<CreateFileNodeInput>;
};

export type MutationCreateFileNodeForFolderImportRequestArgs = {
  dataImportRequestId: Scalars['ID']['input'];
  fileIdempotencyKeyStorageAndMetadata: Array<
    InputMaybe<FileIdempotencyKeyStorageAndMetadataInput>
  >;
  folderImportRequestId: Scalars['ID']['input'];
};

export type MutationCreateFileV2Args = {
  input?: InputMaybe<CreateFileInput>;
};

export type MutationCreateFolderImportRequestForDataImportRequestArgs = {
  childFolderNodeIdempotencyKeyAndNames: Array<InputMaybe<FolderIdempotencyKeyAndNameInput>>;
  dataImportRequestId?: InputMaybe<Scalars['ID']['input']>;
  parentFolderNodeIdempotencyKey?: InputMaybe<Scalars['String']['input']>;
};

export type MutationCreateFolderNodeArgs = {
  input?: InputMaybe<CreateFolderNodeInput>;
};

export type MutationCreateFolderNodeForFolderImportRequestArgs = {
  childFolderImportRequestIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  dataImportRequestId?: InputMaybe<Scalars['ID']['input']>;
  parentFolderImportRequestId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationCreateGroupingPoliciesArgs = {
  groupingPolicies?: InputMaybe<Array<InputMaybe<GroupingPolicyInput>>>;
};

export type MutationCreateIfcProcessArgs = {
  captureId?: InputMaybe<Scalars['ID']['input']>;
  epsgCode?: InputMaybe<Scalars['String']['input']>;
  fileId: Scalars['ID']['input'];
  worldPosition?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
};

export type MutationCreateImageArgs = {
  input?: InputMaybe<CreateImageInput>;
};

export type MutationCreateImageLinkToS3Args = {
  input?: InputMaybe<CreateImageInput>;
};

export type MutationCreateImageProjectionProcessArgs = {
  captureId?: InputMaybe<Scalars['ID']['input']>;
  imageFileIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  xmlFileId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationCreateImageProjectionProcessForRenderObjectArgs = {
  captureId: Scalars['ID']['input'];
  renderObjectId: Scalars['ID']['input'];
  xmlFileId: Scalars['ID']['input'];
};

export type MutationCreateImageProjectionProcessV2Args = {
  imageFileIds: Array<InputMaybe<Scalars['ID']['input']>>;
  layerId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  projectId: Scalars['ID']['input'];
  xmlFileId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationCreateImageThumbnailsForPersistedImagesByIdsArgs = {
  fileIds: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type MutationCreateImageTilesForPersistedImagesByIdsArgs = {
  fileIds: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type MutationCreateKmlProcessArgs = {
  captureId?: InputMaybe<Scalars['ID']['input']>;
  fileId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationCreateLeadArgs = {
  lead?: InputMaybe<CreateLeadInput>;
};

export type MutationCreateLegacyReportArgs = {
  input?: InputMaybe<CreateLegacyReportInput>;
};

export type MutationCreateLinkNodesBySourceNodeIdsArgs = {
  dependants?: InputMaybe<Array<InputMaybe<SystemNodeDependantInput>>>;
  parentNodeId?: InputMaybe<Scalars['ID']['input']>;
  sourceNodeIds: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type MutationCreateManyAnnotationsArgs = {
  requests?: InputMaybe<Array<InputMaybe<CreateAnnotationInput>>>;
};

export type MutationCreateModelArgs = {
  modelName: Scalars['String']['input'];
};

export type MutationCreateMultipartUploadArgs = {
  fileName: Scalars['String']['input'];
};

export type MutationCreateMultipartUploadForFileImportRequestArgs = {
  dataImportRequestId: Scalars['ID']['input'];
  fileImportRequestId: Scalars['ID']['input'];
};

export type MutationCreateNodeShareLinkArgs = {
  actionType: Scalars['String']['input'];
  expiresAt?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  nodeId: Scalars['ID']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
};

export type MutationCreateObjProcessArgs = {
  captureId?: InputMaybe<Scalars['ID']['input']>;
  epsgCode?: InputMaybe<Scalars['String']['input']>;
  fileIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type MutationCreateObjectOfInterestArgs = {
  input?: InputMaybe<CreateUpdateObjectOfInterestInput>;
};

export type MutationCreateObjectOfInterestGroupArgs = {
  defectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  objectOfInterestIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  siteId: Scalars['ID']['input'];
};

export type MutationCreateOrtho2dProcessArgs = {
  captureId?: InputMaybe<Scalars['ID']['input']>;
  epsgCode?: InputMaybe<Scalars['String']['input']>;
  fileId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationCreatePanoramicImageArgs = {
  captureId?: InputMaybe<Scalars['ID']['input']>;
  cartographicPosition?: InputMaybe<Array<InputMaybe<Array<Scalars['Float']['input']>>>>;
  siteId?: InputMaybe<Scalars['ID']['input']>;
  textureId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationCreatePanoramicLayerArgs = {
  captureDate: Scalars['String']['input'];
  epsg?: InputMaybe<Scalars['String']['input']>;
  layerName: Scalars['String']['input'];
  panoramicFormat: Scalars['String']['input'];
  projectId: Scalars['ID']['input'];
};

export type MutationCreatePanoramicProcessArgs = {
  captureId?: InputMaybe<Scalars['ID']['input']>;
  epsg?: InputMaybe<Scalars['String']['input']>;
  format?: InputMaybe<Scalars['String']['input']>;
  formatFileId?: InputMaybe<Scalars['ID']['input']>;
  panoramicImageIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type MutationCreatePanoramicProcessV2Args = {
  epsg?: InputMaybe<Scalars['String']['input']>;
  format?: InputMaybe<Scalars['String']['input']>;
  formatFileId?: InputMaybe<Scalars['ID']['input']>;
  imageFileIds: Array<InputMaybe<Scalars['ID']['input']>>;
  layerId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  projectId: Scalars['ID']['input'];
};

export type MutationCreatePanoramicProcessWithPanoramicWorkflowArgs = {
  captureDate: Scalars['String']['input'];
  epsg?: InputMaybe<Scalars['String']['input']>;
  layerName: Scalars['String']['input'];
  panoramicFormat: Scalars['String']['input'];
  projectId: Scalars['ID']['input'];
  renderObjectFolderNodeParentNodeId?: InputMaybe<Scalars['ID']['input']>;
  useRenderObjectFolderNodeParentNodeId?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationCreatePanoramicVideoLayerArgs = {
  captureDate: Scalars['String']['input'];
  layerName: Scalars['String']['input'];
  modelTypes: Array<InputMaybe<Scalars['String']['input']>>;
  projectId: Scalars['ID']['input'];
};

export type MutationCreatePlatformManagedAwss3ApplicationCredentialArgs = {
  applicationCredential?: InputMaybe<Awss3ApplicationCredentialInput>;
};

export type MutationCreatePresignedPartUrlForFileImportRequestArgs = {
  bucket: Scalars['String']['input'];
  key: Scalars['String']['input'];
  partNumber: Scalars['Int']['input'];
  uploadId: Scalars['String']['input'];
};

export type MutationCreatePresignedUploadPartUrlArgs = {
  bucket: Scalars['String']['input'];
  key: Scalars['String']['input'];
  partNumber: Scalars['Int']['input'];
  uploadId: Scalars['String']['input'];
};

export type MutationCreateProjectArgs = {
  address: Scalars['String']['input'];
  description: Scalars['String']['input'];
  geoid: Scalars['String']['input'];
  latitude?: InputMaybe<Scalars['Float']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  name: Scalars['String']['input'];
  projectGroupId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationCreateProjectAnnotationTemplateArgs = {
  request?: InputMaybe<CreateProjectAnnotationTemplateInput>;
};

export type MutationCreateProjectFileNodesFromFilesArgs = {
  fileIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  parentNodeId?: InputMaybe<Scalars['ID']['input']>;
  siteId: Scalars['ID']['input'];
};

export type MutationCreateProjectFileNodesWithNodeTreeArgs = {
  nodeIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  parentNodeId?: InputMaybe<Scalars['ID']['input']>;
  siteId: Scalars['ID']['input'];
};

export type MutationCreateProjectFolderNodeArgs = {
  name: Scalars['String']['input'];
  parentNodeId?: InputMaybe<Scalars['ID']['input']>;
  siteId: Scalars['ID']['input'];
};

export type MutationCreateProjectGroupArgs = {
  accountId: Scalars['ID']['input'];
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
  projectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type MutationCreateProjectV2Args = {
  address: Scalars['String']['input'];
  description: Scalars['String']['input'];
  geoid: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  latitude?: InputMaybe<Scalars['Float']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  name: Scalars['String']['input'];
  projectGroupId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationCreateQuoteForChargesArgs = {
  captureId: Scalars['ID']['input'];
  charges: Array<InputMaybe<Charge>>;
  siteId: Scalars['ID']['input'];
};

export type MutationCreateRenderObjectRootFolderNodeArgs = {
  projectId: Scalars['ID']['input'];
  renderObjectId: Scalars['ID']['input'];
};

export type MutationCreateRenderObjectSystemNodesForFileIdsUseCaseArgs = {
  fileIds: Array<InputMaybe<Scalars['ID']['input']>>;
  projectId: Scalars['ID']['input'];
  renderObjectId: Scalars['ID']['input'];
};

export type MutationCreateReportArgs = {
  input?: InputMaybe<CreateReportInput>;
};

export type MutationCreateReportV2Args = {
  accountId: Scalars['ID']['input'];
  annotationIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  projectId: Scalars['ID']['input'];
};

export type MutationCreateRoleArgs = {
  createdBy?: InputMaybe<Scalars['String']['input']>;
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type MutationCreateRootFolderForAnnotationsArgs = {
  size?: InputMaybe<Scalars['Int']['input']>;
  start?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationCreateRootFolderForRenderObjectsArgs = {
  size?: InputMaybe<Scalars['Int']['input']>;
  start?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationCreateShareLinkArgs = {
  name: Scalars['String']['input'];
  permissions?: InputMaybe<Array<InputMaybe<PermissionPolicyWithOutSubjectInput>>>;
  projectId: Scalars['ID']['input'];
};

export type MutationCreateShareSiteAnalyticsArgs = {
  accountId?: InputMaybe<Scalars['ID']['input']>;
  action: Scalars['String']['input'];
  numberOfSelectedDefects?: InputMaybe<Scalars['Int']['input']>;
  numberOfSelectedObjectOfInterests?: InputMaybe<Scalars['Int']['input']>;
  numberOfTotalDefects?: InputMaybe<Scalars['Int']['input']>;
  numberOfTotalObjectOfInterests?: InputMaybe<Scalars['Int']['input']>;
  siteId?: InputMaybe<Scalars['ID']['input']>;
  siteName?: InputMaybe<Scalars['String']['input']>;
  url: Scalars['String']['input'];
};

export type MutationCreateSiteArgs = {
  input?: InputMaybe<CreateSiteInput>;
};

export type MutationCreateStorageArgs = {
  input?: InputMaybe<CreateStorageInput>;
};

export type MutationCreateSystemFolderNodeArgs = {
  input: CreateSystemFolderNodeInput;
};

export type MutationCreateSystemFolderNodeV2Args = {
  input: CreateSystemFolderNodeInput;
};

export type MutationCreateSystemNodesForAnnotationFilesArgs = {
  size?: InputMaybe<Scalars['Int']['input']>;
  start?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationCreateSystemNodesForRenderObjectFilesByProjectIdAndRenderObjectIdArgs = {
  projectId: Scalars['ID']['input'];
  renderObjectId: Scalars['ID']['input'];
};

export type MutationCreateSystemNodesForRenderObjectFilesByRenderObjectIdArgs = {
  renderObjectId: Scalars['ID']['input'];
};

export type MutationCreateSystemNodesForRenderObjectsFilesArgs = {
  size?: InputMaybe<Scalars['Int']['input']>;
  start?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationCreateTileset3dFromPointCloudAssetsArgs = {
  assetIds: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type MutationCreateTileset3dFromZipAssetArgs = {
  assetId: Scalars['ID']['input'];
};

export type MutationCreateTileset3dLinkToS3Args = {
  input?: InputMaybe<CreateTileset3dLinkInput>;
};

export type MutationCreateTileset3dProcessFromImagesArgs = {
  captureId?: InputMaybe<Scalars['ID']['input']>;
  fileIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type MutationCreateTileset3dProcessFromPointCloudArgs = {
  captureId?: InputMaybe<Scalars['ID']['input']>;
  epsgCode?: InputMaybe<Scalars['String']['input']>;
  fileIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type MutationCreateTileset3dProcessFromZipArgs = {
  captureId?: InputMaybe<Scalars['ID']['input']>;
  fileId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationCreateUserGroupArgs = {
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type MutationCreateUserInSiteArgs = {
  input?: InputMaybe<CreateUserInSiteInput>;
};

export type MutationCreateZipRequestByCaptureIdArgs = {
  captureId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationCreateZipRequestByCaptureIdAndStorageIdsArgs = {
  captureId: Scalars['ID']['input'];
  storageIds: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type MutationDeactivateUserArgs = {
  userId: Scalars['ID']['input'];
};

export type MutationDeleteAnnotationArgs = {
  annotationId: Scalars['String']['input'];
};

export type MutationDeleteAnnotationGroupArgs = {
  annotationGroupSceneEntityId: Scalars['ID']['input'];
};

export type MutationDeleteAnnotationProcessArgs = {
  processId: Scalars['ID']['input'];
};

export type MutationDeleteAnnotationTemplateArgs = {
  annotationTemplateId: Scalars['ID']['input'];
};

export type MutationDeleteAnnotationV2Args = {
  annotationId: Scalars['String']['input'];
};

export type MutationDeleteAnnotationsArgs = {
  annotationIds: Array<InputMaybe<Scalars['String']['input']>>;
};

export type MutationDeleteAssetLinkToS3Args = {
  captureId: Scalars['ID']['input'];
  key: Scalars['String']['input'];
};

export type MutationDeleteAssetsArgs = {
  assetIds: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type MutationDeleteBuildingArgs = {
  buildingId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationDeleteCaptureArgs = {
  captureId: Scalars['ID']['input'];
};

export type MutationDeleteCaptureAndSceneEntitiesArgs = {
  captureId: Scalars['ID']['input'];
};

export type MutationDeleteDefectArgs = {
  defectId: Scalars['ID']['input'];
};

export type MutationDeleteExternalShareByIdArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteFilesArgs = {
  fileIds: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type MutationDeleteGroupingPoliciesArgs = {
  groupingPolicies?: InputMaybe<Array<InputMaybe<GroupingPolicyInput>>>;
};

export type MutationDeleteImageLinkToS3Args = {
  captureId: Scalars['ID']['input'];
  key: Scalars['String']['input'];
};

export type MutationDeleteImagesArgs = {
  imageIds: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type MutationDeleteLayerArgs = {
  layerId: Scalars['ID']['input'];
};

export type MutationDeleteNodesAndFilesArgs = {
  nodeIds: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type MutationDeleteObjectOfInterestArgs = {
  objectOfInterestId: Scalars['ID']['input'];
};

export type MutationDeletePanoramicImageArgs = {
  panoramicImageId: Scalars['ID']['input'];
};

export type MutationDeletePermissionPoliciesArgs = {
  permissionPolicies?: InputMaybe<Array<InputMaybe<PermissionPolicyInput>>>;
};

export type MutationDeleteProjectArgs = {
  projectId: Scalars['ID']['input'];
};

export type MutationDeleteProjectGroupArgs = {
  projectGroupId: Scalars['ID']['input'];
};

export type MutationDeleteProjectNodesArgs = {
  projectNodeIds: Array<InputMaybe<Scalars['ID']['input']>>;
  siteId: Scalars['ID']['input'];
};

export type MutationDeleteProjectSystemNodesByParentAndNodeIdsArgs = {
  associatedEntity?: InputMaybe<SystemNodeAssociatedEntityInput>;
  parentNodeId?: InputMaybe<Scalars['ID']['input']>;
  projectId: Scalars['ID']['input'];
  systemNodeIds: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type MutationDeleteProjectV2Args = {
  projectId: Scalars['ID']['input'];
};

export type MutationDeleteRenderObjectArgs = {
  renderObjectId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationDeleteReportArgs = {
  reportId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationDeleteReportV2Args = {
  reportId: Scalars['ID']['input'];
};

export type MutationDeleteRoleArgs = {
  roleId: Scalars['ID']['input'];
};

export type MutationDeleteSceneEntityWithIdArgs = {
  sceneEntityId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationDeleteShareLinkArgs = {
  shareLinkId: Scalars['ID']['input'];
};

export type MutationDeleteSiteArgs = {
  siteId: Scalars['ID']['input'];
};

export type MutationDeleteStorageArgs = {
  storageId: Scalars['ID']['input'];
};

export type MutationDeleteSystemNodeArgs = {
  systemNodeId: Scalars['ID']['input'];
};

export type MutationDeleteSystemNodesByParentAndNodeIdsArgs = {
  parentNodeId?: InputMaybe<Scalars['ID']['input']>;
  systemNodeIds: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type MutationDeleteTileset3dLinkToS3Args = {
  captureId: Scalars['ID']['input'];
  key: Scalars['String']['input'];
};

export type MutationDeleteTileset3dsArgs = {
  tileset3dIds: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type MutationDeleteUserArgs = {
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationDeleteUserGroupArgs = {
  userGroupId: Scalars['ID']['input'];
};

export type MutationDeleteUserInSiteArgs = {
  input?: InputMaybe<DeleteUserInSiteInput>;
};

export type MutationDownloadNodesArgs = {
  inputTag?: InputMaybe<InputTagInput>;
  nodeIds: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type MutationDownloadProjectNodesArgs = {
  inputTag?: InputMaybe<InputTagInput>;
  projectNodeIds: Array<InputMaybe<Scalars['ID']['input']>>;
  siteId: Scalars['ID']['input'];
};

export type MutationDownloadRenderObjectFilesArgs = {
  renderObjectId: Scalars['ID']['input'];
};

export type MutationDownloadSitesArgs = {
  siteIds: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type MutationDownloadSystemNodesArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
  nodeIds: Array<InputMaybe<Scalars['ID']['input']>>;
  note?: InputMaybe<Scalars['String']['input']>;
};

export type MutationFixExistingRenderObjectsRootFolderNodeParentArgs = {
  size?: InputMaybe<Scalars['Int']['input']>;
  start?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationFixSystemFileNodesWithSameFileIdArgs = {
  accountIds: Array<InputMaybe<Scalars['ID']['input']>>;
  fileIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  size?: InputMaybe<Scalars['Int']['input']>;
  start?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationGenerateReportPdfV2Args = {
  reportId: Scalars['ID']['input'];
};

export type MutationHardDeleteNodesAndFilesArgs = {
  nodeIds: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type MutationHardDeleteNodesAndFilesV2Args = {
  nodeIds: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type MutationInsertManyImageProjectionProcessImagesStatusArgs = {
  imageFileIds: Array<InputMaybe<Scalars['ID']['input']>>;
  imageProjectionProcessId: Scalars['ID']['input'];
};

export type MutationInsertManyImageProjectionRenderObjectImagesArgs = {
  imageFileIds: Array<InputMaybe<Scalars['ID']['input']>>;
  renderObjectId: Scalars['ID']['input'];
};

export type MutationInsertManyPanoramicProcessImagesStatusArgs = {
  imageFileIds: Array<InputMaybe<Scalars['ID']['input']>>;
  panoramicProcessId: Scalars['ID']['input'];
};

export type MutationInsertManyPanoramicRenderObjectImagesArgs = {
  imageFileIds: Array<InputMaybe<Scalars['ID']['input']>>;
  renderObjectId: Scalars['ID']['input'];
};

export type MutationInviteUserArgs = {
  firstName?: InputMaybe<Scalars['String']['input']>;
  hostDomain?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  newUserEmail?: InputMaybe<Scalars['String']['input']>;
};

export type MutationInviteUserV2Args = {
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  newUserEmail?: InputMaybe<Scalars['String']['input']>;
  roleId?: InputMaybe<Scalars['String']['input']>;
  userGroupId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationLaunchPanoramicVideoWorkflowWithFileNodeIdArgs = {
  processId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
  sourceFileNodeId: Scalars['ID']['input'];
};

export type MutationLaunchPanoramicWorkflowWithFileIdArgs = {
  processId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
  sourceFileId: Scalars['ID']['input'];
};

export type MutationLinkIdentityArgs = {
  identityId: Scalars['String']['input'];
  provider: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};

export type MutationLinkIdentityIssuerArgs = {
  identityIssuerId: Scalars['String']['input'];
  provider: Scalars['String']['input'];
};

export type MutationLinkTilesetWithCaptureArgs = {
  captureId: Scalars['ID']['input'];
  storageId: Scalars['ID']['input'];
};

export type MutationLinkXmlWithCaptureArgs = {
  captureId: Scalars['ID']['input'];
  storageId: Scalars['ID']['input'];
};

export type MutationLoginArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
};

export type MutationLoginWithOneTimeTokenArgs = {
  token?: InputMaybe<Scalars['String']['input']>;
};

export type MutationMigrateAnnotationsArgs = {
  accountId: Scalars['ID']['input'];
};

export type MutationMigrateProjectFileNodesIntoSystemNodesArgs = {
  projectFileNodeIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  projectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  size?: InputMaybe<Scalars['Int']['input']>;
  start?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationMigrateProjectFolderNodesIntoSystemFolderNodesArgs = {
  size?: InputMaybe<Scalars['Int']['input']>;
  start?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationMoveAnnotationsToGroupArgs = {
  annotationIdsWithPositionAndRotation: Array<InputMaybe<AnnotationIdWithPositionAndRotation>>;
  groupId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationMoveProjectsToGroupArgs = {
  groupId: Scalars['ID']['input'];
  projectIds: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type MutationPersistAssetsArgs = {
  assetIds: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type MutationPersistCaptureFilesInTemporaryBucketArgs = {
  captureId: Scalars['ID']['input'];
};

export type MutationPersistFilesArgs = {
  fileIds: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type MutationPersistImagesArgs = {
  imageIds: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type MutationPrepareProjectFolderNodesForSystemFolderNodesMigrationArgs = {
  size?: InputMaybe<Scalars['Int']['input']>;
  start?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationPublishCaptureArgs = {
  captureId: Scalars['ID']['input'];
};

export type MutationRecoverFilesArgs = {
  fileIds: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type MutationRecoverNodesAndFilesArgs = {
  nodeIds: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type MutationRefreshReportArgs = {
  reportId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationRemoveDuplicateAnnotationActiveVersionByDocumentIdArgs = {
  annotationDocumentId: Scalars['ID']['input'];
};

export type MutationRemoveFilesFromSiteArgs = {
  fileIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  siteId: Scalars['ID']['input'];
};

export type MutationRemoveMigrationDuplicatedLinkNodesArgs = {
  projectId: Scalars['ID']['input'];
  size?: InputMaybe<Scalars['Int']['input']>;
  start?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationRemoveMigrationDuplicatedLinkNodesV2Args = {
  systemLinkNodeIds: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type MutationRemoveSupportingSceneEntityFromCaptureArgs = {
  captureId?: InputMaybe<Scalars['ID']['input']>;
  supportingSceneEntityId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationResendInvitationToUserArgs = {
  userId: Scalars['ID']['input'];
};

export type MutationResetAccountFileUsageArgs = {
  size?: InputMaybe<Scalars['Int']['input']>;
  start?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationResetPasswordArgs = {
  password?: InputMaybe<Scalars['String']['input']>;
  resetPasswordToken?: InputMaybe<Scalars['String']['input']>;
};

export type MutationResetPasswordRequestArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  hostDomain?: InputMaybe<Scalars['String']['input']>;
};

export type MutationRestoreAnnotationToHistoryVersionArgs = {
  annotationHistoryDocumentId: Scalars['ID']['input'];
};

export type MutationRestoreProjectFileNodeSourceSystemFileNodesArgs = {
  size?: InputMaybe<Scalars['Int']['input']>;
  start?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationSendOneTimeLoginTokenArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  hostUrl?: InputMaybe<Scalars['String']['input']>;
};

export type MutationSubmitAnnotationProcessImagesStatusArgs = {
  annotationProcessImageStatusIds: Array<InputMaybe<Scalars['ID']['input']>>;
  status: Scalars['String']['input'];
};

export type MutationSubmitDefectToDefectProcessArgs = {
  input?: InputMaybe<SubmitImageAnnotationInput>;
  processId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationSubmitImageAnnotationArgs = {
  input?: InputMaybe<SubmitImageAnnotationInput>;
};

export type MutationSubmitImageProjectionProcessImagesStatusArgs = {
  imageProjectionProcessImageStatusIds: Array<InputMaybe<Scalars['ID']['input']>>;
  status: Scalars['String']['input'];
};

export type MutationSubmitPanoramicProcessImagesStatusArgs = {
  panoramicProcessImageStatusIds: Array<InputMaybe<Scalars['ID']['input']>>;
  status: Scalars['String']['input'];
};

export type MutationTrackAccountFileUsageArgs = {
  size?: InputMaybe<Scalars['Int']['input']>;
  start?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationTrackFilesForFolderImportRequestArgs = {
  dataImportRequestId?: InputMaybe<Scalars['ID']['input']>;
  files?: InputMaybe<Array<InputMaybe<FileItemInput>>>;
  folderImportRequestId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationTriggerPredictionArgs = {
  request?: InputMaybe<TriggerPredictionInput>;
};

export type MutationTriggerPredictionV2Args = {
  request?: InputMaybe<TriggerPredictionV2Input>;
};

export type MutationTriggerTrainArgs = {
  request?: InputMaybe<TriggerTrainInput>;
};

export type MutationTriggerTrainV2Args = {
  request?: InputMaybe<TriggerTrainV2Input>;
};

export type MutationUnlinkIdentityArgs = {
  identityId: Scalars['String']['input'];
  provider: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};

export type MutationUnlinkIdentityIssuerArgs = {
  identityIssuerId: Scalars['String']['input'];
  provider: Scalars['String']['input'];
};

export type MutationUntrackFilesStorageArgs = {
  size?: InputMaybe<Scalars['Int']['input']>;
  start?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationUpdateAccountArgs = {
  input?: InputMaybe<UpdateAccountInput>;
};

export type MutationUpdateAccountBrandingArgs = {
  accountId: Scalars['ID']['input'];
  color?: InputMaybe<Scalars['String']['input']>;
  hasSkandWatermark?: InputMaybe<Scalars['Boolean']['input']>;
  reportBannerFileId?: InputMaybe<Scalars['ID']['input']>;
  webappBannerFileId?: InputMaybe<Scalars['ID']['input']>;
  webappLogoFileId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationUpdateAnnotationArgs = {
  request?: InputMaybe<UpdateAnnotationInput>;
};

export type MutationUpdateAnnotationProcessNameArgs = {
  name: Scalars['String']['input'];
  processId: Scalars['ID']['input'];
};

export type MutationUpdateAnnotationStatusArgs = {
  annotationId: Scalars['String']['input'];
  status: AnnotationStatusInput;
};

export type MutationUpdateAnnotationTemplateArgs = {
  request?: InputMaybe<UpdateAnnotationTemplateInput>;
};

export type MutationUpdateBuildingArgs = {
  input?: InputMaybe<UpdateBuildingInput>;
};

export type MutationUpdateCaptureArgs = {
  input?: InputMaybe<UpdateCaptureInput>;
};

export type MutationUpdateCaptureAddonArgs = {
  addonType: Scalars['String']['input'];
  captureId: Scalars['ID']['input'];
  status: Scalars['String']['input'];
};

export type MutationUpdateCaptureMainSceneEntityArgs = {
  captureId: Scalars['ID']['input'];
  renderObjectId: Scalars['ID']['input'];
};

export type MutationUpdateCaptureWorldPositionAndWorldRotationArgs = {
  captureId: Scalars['ID']['input'];
  worldPosition: Array<InputMaybe<Scalars['Float']['input']>>;
  worldRotation: Array<InputMaybe<Scalars['Float']['input']>>;
};

export type MutationUpdateCesiumIonProcessToDoneArgs = {
  cesiumIonId?: InputMaybe<Scalars['String']['input']>;
  processId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationUpdateDefectArgs = {
  defectId: Scalars['ID']['input'];
  input?: InputMaybe<CreateDefectInput>;
};

export type MutationUpdateDefectRelativePositionsAndRelativeRotationsArgs = {
  defectId: Scalars['ID']['input'];
  relativePositions: Array<Array<Scalars['Float']['input']>>;
  relativeRotations: Array<Array<Scalars['Float']['input']>>;
};

export type MutationUpdateDefectsArgs = {
  defectIds: Array<InputMaybe<Scalars['ID']['input']>>;
  defectStatusId?: InputMaybe<Scalars['ID']['input']>;
  defectTypeId?: InputMaybe<Scalars['ID']['input']>;
  elementId?: InputMaybe<Scalars['ID']['input']>;
  priorityId?: InputMaybe<Scalars['ID']['input']>;
  roofConstructionTypeId?: InputMaybe<Scalars['ID']['input']>;
  roofMaterialTypeId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationUpdateExternalShareByIdArgs = {
  expiredAt?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  revokedAt?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdateFileNodeNameArgs = {
  name: Scalars['String']['input'];
  nodeId: Scalars['ID']['input'];
};

export type MutationUpdateFolderNodeNameArgs = {
  name: Scalars['String']['input'];
  nodeId: Scalars['ID']['input'];
};

export type MutationUpdateImageProjectionPositionsArgs = {
  cameraPosition?: InputMaybe<Array<Scalars['Float']['input']>>;
  imageId: Scalars['ID']['input'];
  projectedPosition?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type MutationUpdateImageProjectionRenderObjectCameraModelsArgs = {
  cameraModels?: InputMaybe<Array<InputMaybe<CameraModel>>>;
  renderObjectId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationUpdateImageProjectionRenderObjectImagePositionArgs = {
  cameraRelativePosition?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  cameraRelativeRotation?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  fileId?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  processId?: InputMaybe<Scalars['ID']['input']>;
  projectedRelativePosition?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  projectedRelativeRotation?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  renderObjectId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationUpdateImageProjectionRenderObjectImagesArgs = {
  images?: InputMaybe<Array<InputMaybe<ImageProjectionRenderObjectImage>>>;
  processId?: InputMaybe<Scalars['ID']['input']>;
  renderObjectId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationUpdateKmlProcessToCreateArgs = {
  processId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationUpdateKmlProcessToDoneArgs = {
  processId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationUpdateKmlProcessToProcessingArgs = {
  processId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationUpdateKmlRenderingFileArgs = {
  fileId?: InputMaybe<Scalars['ID']['input']>;
  processId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationUpdateLayerStatusArgs = {
  layerId: Scalars['ID']['input'];
  status: LayerStatusInput;
};

export type MutationUpdateLegacyReportArgs = {
  input?: InputMaybe<UpdateLegacyReportInput>;
};

export type MutationUpdateNodesLastDownloadArgs = {
  nodeIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type MutationUpdateNodesPositionArgs = {
  nodeIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  parentNodeId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationUpdateObjectOfInterestArgs = {
  input?: InputMaybe<CreateUpdateObjectOfInterestInput>;
  objectOfInterestId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationUpdateObjectOfInterestGroupArgs = {
  defectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  objectOfInterestGroupId: Scalars['ID']['input'];
  objectOfInterestIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  siteId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationUpdatePanoramicImageArgs = {
  captureId?: InputMaybe<Scalars['ID']['input']>;
  cartographicPosition?: InputMaybe<Array<InputMaybe<Array<Scalars['Float']['input']>>>>;
  panoramicImageId: Scalars['ID']['input'];
  siteId?: InputMaybe<Scalars['ID']['input']>;
  textureId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationUpdatePanoramicProcessToReadyToDoneArgs = {
  panoramicProcessId: Scalars['ID']['input'];
};

export type MutationUpdatePanoramicProcessToReadyToReviewArgs = {
  panoramicProcessId: Scalars['ID']['input'];
};

export type MutationUpdatePanoramicRenderObjectImagePositionArgs = {
  fileId?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  processId?: InputMaybe<Scalars['ID']['input']>;
  relativePosition?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  relativeRotation?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  renderObjectId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationUpdatePanoramicRenderObjectImagePositionsArgs = {
  panoramicImages?: InputMaybe<Array<InputMaybe<PanoramicRenderObjectImage>>>;
  processId?: InputMaybe<Scalars['ID']['input']>;
  renderObjectId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationUpdatePermanentStorageApplicationCredentialArgs = {
  accountId: Scalars['ID']['input'];
  applicationCredentialId: Scalars['ID']['input'];
};

export type MutationUpdateProcessStepArgs = {
  processId?: InputMaybe<Scalars['ID']['input']>;
  stepName?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdateProjectArgs = {
  address?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  geoid?: InputMaybe<Scalars['String']['input']>;
  latitude?: InputMaybe<Scalars['Float']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  projectGroupId?: InputMaybe<Scalars['ID']['input']>;
  projectId: Scalars['ID']['input'];
};

export type MutationUpdateProjectGroupNameAndDescriptionArgs = {
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
  projectGroupId: Scalars['ID']['input'];
};

export type MutationUpdateProjectNodeNameArgs = {
  name: Scalars['String']['input'];
  projectNodeId: Scalars['ID']['input'];
};

export type MutationUpdateProjectNodesPositionArgs = {
  nodeIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  parentNodeId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationUpdateProjectStatusArgs = {
  projectId: Scalars['ID']['input'];
  status: ProjectStatusInput;
};

export type MutationUpdateReportArgs = {
  input?: InputMaybe<UpdateReportInput>;
};

export type MutationUpdateReportV2Args = {
  annotationIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  reportId: Scalars['ID']['input'];
};

export type MutationUpdateRoleArgs = {
  request?: InputMaybe<UpdateRoleInput>;
};

export type MutationUpdateSceneEntityNameArgs = {
  name: Scalars['String']['input'];
  projectId: Scalars['ID']['input'];
  sceneEntityId: Scalars['ID']['input'];
};

export type MutationUpdateSceneEntityPositionAndRotationArgs = {
  position: SceneEntityPositionInput;
  projectId: Scalars['ID']['input'];
  rotation: SceneEntityRotationInput;
  sceneEntityId: Scalars['ID']['input'];
};

export type MutationUpdateShareLinkArgs = {
  isActivated?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<Array<InputMaybe<PermissionPolicyInput>>>;
  shareLinkId: Scalars['ID']['input'];
};

export type MutationUpdateSiteArgs = {
  input?: InputMaybe<UpdateSiteInput>;
};

export type MutationUpdateStatusForDataImportRequestArgs = {
  dataImportRequestId?: InputMaybe<Scalars['ID']['input']>;
  status: Scalars['String']['input'];
};

export type MutationUpdateStatusForFolderImportRequestArgs = {
  input?: InputMaybe<UpdateStatusForFolderImportRequestInput>;
};

export type MutationUpdateSuperAdminUserArgs = {
  isSuperAdmin: Scalars['Boolean']['input'];
  userId: Scalars['ID']['input'];
};

export type MutationUpdateSystemNodeNameArgs = {
  name: Scalars['String']['input'];
  systemNodeId: Scalars['ID']['input'];
};

export type MutationUpdateSystemNodesPositionArgs = {
  parentSystemNodeId: Scalars['ID']['input'];
  systemNodeIds: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type MutationUpdateSystemNodesPositionV2Args = {
  parentSystemNodeId: Scalars['ID']['input'];
  systemNodeIds: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type MutationUpdateTemporaryStorageApplicationCredentialArgs = {
  accountId: Scalars['ID']['input'];
  applicationCredentialId: Scalars['ID']['input'];
};

export type MutationUpdateTileset3dProcessToCreateArgs = {
  processId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationUpdateTileset3dProcessToDoneArgs = {
  processId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationUpdateTileset3dProcessToProcessingArgs = {
  processId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationUpdateUserArgs = {
  user?: InputMaybe<UpdateUserInput>;
};

export type MutationUpdateUserByIdArgs = {
  user?: InputMaybe<UpdateUserInput>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationUpdateUserGroupArgs = {
  request?: InputMaybe<UpdateUserGroupInput>;
};

export type MutationUpdateUserInSiteArgs = {
  input?: InputMaybe<UpdateUserInSiteInput>;
};

export type MutationUploadZipToTileset3dProcessArgs = {
  fileId?: InputMaybe<Scalars['ID']['input']>;
  processId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationUpsertAnnotationProcessImagesStatusWithImageProjectionRenderObjectArgs = {
  imageProjectionRenderObjectId: Scalars['ID']['input'];
  processId: Scalars['ID']['input'];
};

export type MutationUpsertPermissionPoliciesArgs = {
  permissionPolicies?: InputMaybe<Array<InputMaybe<PermissionPolicyInput>>>;
};

export type MutationValidateAndSubmitIngestArgs = {
  captureId: Scalars['ID']['input'];
};

export type Node = FileNode | FolderNode | LinkNode;

export type NodeWithPagination = {
  __typename?: 'NodeWithPagination';
  data?: Maybe<Array<Maybe<Node>>>;
  totalNumberOfFiles?: Maybe<Scalars['Int']['output']>;
  totalNumberOfPages?: Maybe<Scalars['Int']['output']>;
};

export type NonDeletableSystemNodeIdsWithErrorDetails = {
  __typename?: 'NonDeletableSystemNodeIdsWithErrorDetails';
  errorDetails?: Maybe<DeleteSystemNodesByParentAndNodeIdsResultErrorDetails>;
  systemNodeId: Scalars['ID']['output'];
};

export type ObjFile = {
  __typename?: 'ObjFile';
  accountId?: Maybe<Scalars['ID']['output']>;
  canDelete?: Maybe<Scalars['Boolean']['output']>;
  captures?: Maybe<Array<Maybe<Capture>>>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  httpUrl?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  kind?: Maybe<Scalars['String']['output']>;
  renderObjectIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  renderObjects?: Maybe<Array<Maybe<RenderObject>>>;
  signedGetObjectDownloadUrl?: Maybe<Scalars['String']['output']>;
  signedGetObjectUrl?: Maybe<Scalars['String']['output']>;
  sites?: Maybe<Array<Maybe<Site>>>;
  storage?: Maybe<FileStorageField>;
  storageStatus?: Maybe<Scalars['String']['output']>;
};

export type ObjProcess = {
  __typename?: 'ObjProcess';
  account?: Maybe<Account>;
  accountId?: Maybe<Scalars['ID']['output']>;
  captureId?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdByUserId?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  kind?: Maybe<Scalars['String']['output']>;
  lastStep?: Maybe<Scalars['String']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  renderObjectId?: Maybe<Scalars['ID']['output']>;
  sceneEntityId?: Maybe<Scalars['ID']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  steps?: Maybe<Array<Maybe<StepField>>>;
  user?: Maybe<User>;
};

export type ObjRenderObject = {
  __typename?: 'ObjRenderObject';
  accountId?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  epsgCode?: Maybe<Scalars['String']['output']>;
  file?: Maybe<File>;
  fileId?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  kind?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  process?: Maybe<Process>;
  processId?: Maybe<Scalars['ID']['output']>;
  rootFolderNodeId?: Maybe<Scalars['ID']['output']>;
  usedBySceneEntityIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
};

export type ObjectOfInterest = {
  __typename?: 'ObjectOfInterest';
  accountId?: Maybe<Scalars['ID']['output']>;
  annotationPoints?: Maybe<Array<Maybe<Array<Scalars['Float']['output']>>>>;
  attachmentIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  attachments?: Maybe<Array<Maybe<Storage>>>;
  belongsToObjectOfInterestGroup?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  captureId?: Maybe<Scalars['ID']['output']>;
  category?: Maybe<Scalars['String']['output']>;
  cesium?: Maybe<Cesium>;
  fields?: Maybe<ObjectOfInterestFields>;
  fileId?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  relativePositions?: Maybe<Array<Maybe<Array<Scalars['Float']['output']>>>>;
  relativeRotations?: Maybe<Array<Maybe<Array<Scalars['Float']['output']>>>>;
  sceneCaptures?: Maybe<ObjectOfInterestSceneCaptures>;
  shapeType2d?: Maybe<Scalars['String']['output']>;
  siteId?: Maybe<Scalars['ID']['output']>;
  webLinks?: Maybe<Array<Maybe<ObjectOfInterestWebLinks>>>;
};

export type ObjectOfInterestCesiumInput = {
  cartographicPosition?: InputMaybe<Array<InputMaybe<Array<Scalars['Float']['input']>>>>;
  colorHex?: InputMaybe<Scalars['String']['input']>;
  entityType?: InputMaybe<Scalars['String']['input']>;
};

export type ObjectOfInterestField = {
  __typename?: 'ObjectOfInterestField';
  editable?: Maybe<Scalars['Boolean']['output']>;
  hidden?: Maybe<Scalars['Boolean']['output']>;
  hierarchy?: Maybe<Hierarchy>;
  kind?: Maybe<Kind>;
  name?: Maybe<Scalars['String']['output']>;
  valueMeta?: Maybe<ObjectOfInterestFieldValueMeta>;
};

export type ObjectOfInterestFieldInput = {
  editable?: InputMaybe<Scalars['Boolean']['input']>;
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  hierarchy?: InputMaybe<Hierarchy>;
  kind?: InputMaybe<Kind>;
  name?: InputMaybe<Scalars['String']['input']>;
  valueMeta?: InputMaybe<ObjectOfInterestFieldValueMetaInput>;
};

export type ObjectOfInterestFieldOrder = {
  __typename?: 'ObjectOfInterestFieldOrder';
  hierarchy?: Maybe<Hierarchy>;
  name?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
};

export type ObjectOfInterestFieldValueMeta =
  | ObjectOfInterestNumberField
  | ObjectOfInterestSelectField
  | ObjectOfInterestStringField;

export type ObjectOfInterestFieldValueMetaInput = {
  kind?: InputMaybe<Kind>;
  options?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type ObjectOfInterestFields = {
  __typename?: 'ObjectOfInterestFields';
  accountLevel?: Maybe<Array<Maybe<ObjectOfInterestField>>>;
  captureLevel?: Maybe<Array<Maybe<ObjectOfInterestField>>>;
  order?: Maybe<Array<Maybe<ObjectOfInterestFieldOrder>>>;
  siteLevel?: Maybe<Array<Maybe<ObjectOfInterestField>>>;
  systemLevel?: Maybe<Array<Maybe<ObjectOfInterestField>>>;
};

export type ObjectOfInterestFieldsInput = {
  accountLevel?: InputMaybe<Array<InputMaybe<ObjectOfInterestFieldInput>>>;
  captureLevel?: InputMaybe<Array<InputMaybe<ObjectOfInterestFieldInput>>>;
  order?: InputMaybe<Array<InputMaybe<ObjectOfInterestOrderInput>>>;
  siteLevel?: InputMaybe<Array<InputMaybe<ObjectOfInterestFieldInput>>>;
  systemLevel?: InputMaybe<Array<InputMaybe<ObjectOfInterestFieldInput>>>;
};

export type ObjectOfInterestGroup = {
  __typename?: 'ObjectOfInterestGroup';
  defectIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  defects?: Maybe<Array<Maybe<Defect>>>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  objectOfInterestIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  objectOfInterests?: Maybe<Array<Maybe<ObjectOfInterest>>>;
  site?: Maybe<Site>;
  siteId?: Maybe<Scalars['ID']['output']>;
};

export type ObjectOfInterestNumberField = {
  __typename?: 'ObjectOfInterestNumberField';
  kind?: Maybe<Kind>;
  value?: Maybe<Scalars['Float']['output']>;
};

export type ObjectOfInterestOrderInput = {
  hierarchy?: InputMaybe<Hierarchy>;
  name?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
};

export type ObjectOfInterestSceneCaptures = {
  __typename?: 'ObjectOfInterestSceneCaptures';
  customizedSceneCapture?: Maybe<Storage>;
  customizedSceneCaptureId?: Maybe<Scalars['ID']['output']>;
  defaultSceneCapture?: Maybe<Storage>;
  defaultSceneCaptureId?: Maybe<Scalars['ID']['output']>;
};

export type ObjectOfInterestSceneCapturesInput = {
  customizedSceneCaptureId?: InputMaybe<Scalars['ID']['input']>;
  defaultSceneCaptureId?: InputMaybe<Scalars['ID']['input']>;
};

export type ObjectOfInterestSelectField = {
  __typename?: 'ObjectOfInterestSelectField';
  kind?: Maybe<Kind>;
  options?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  value?: Maybe<Scalars['String']['output']>;
};

export type ObjectOfInterestStringField = {
  __typename?: 'ObjectOfInterestStringField';
  kind?: Maybe<Kind>;
  value?: Maybe<Scalars['String']['output']>;
};

export type ObjectOfInterestWebLinks = {
  __typename?: 'ObjectOfInterestWebLinks';
  name?: Maybe<Scalars['String']['output']>;
  uri?: Maybe<Scalars['String']['output']>;
};

export type ObjectOfInterestWebLinksInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  uri?: InputMaybe<Scalars['String']['input']>;
};

export type OriginalImageStorageField = {
  __typename?: 'OriginalImageStorageField';
  bucket?: Maybe<Scalars['String']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  size?: Maybe<Scalars['Float']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  usageType?: Maybe<Scalars['String']['output']>;
};

export type Ortho2dFile = {
  __typename?: 'Ortho2dFile';
  accountId?: Maybe<Scalars['ID']['output']>;
  canDelete?: Maybe<Scalars['Boolean']['output']>;
  captures?: Maybe<Array<Maybe<Capture>>>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  httpUrl?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  kind?: Maybe<Scalars['String']['output']>;
  renderObjectIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  renderObjects?: Maybe<Array<Maybe<RenderObject>>>;
  signedGetObjectDownloadUrl?: Maybe<Scalars['String']['output']>;
  signedGetObjectUrl?: Maybe<Scalars['String']['output']>;
  sites?: Maybe<Array<Maybe<Site>>>;
  storage?: Maybe<FileStorageField>;
  storageStatus?: Maybe<Scalars['String']['output']>;
};

export type Ortho2dProcess = {
  __typename?: 'Ortho2dProcess';
  account?: Maybe<Account>;
  accountId?: Maybe<Scalars['ID']['output']>;
  captureId?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdByUserId?: Maybe<Scalars['ID']['output']>;
  epsgCode?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  kind?: Maybe<Scalars['String']['output']>;
  lastStep?: Maybe<Scalars['String']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  renderObjectId?: Maybe<Scalars['ID']['output']>;
  sceneEntityId?: Maybe<Scalars['ID']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  steps?: Maybe<Array<Maybe<StepField>>>;
  user?: Maybe<User>;
};

export type Ortho2dRenderObject = {
  __typename?: 'Ortho2dRenderObject';
  accountId?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  file?: Maybe<File>;
  fileId?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  kind?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  process?: Maybe<Process>;
  processId?: Maybe<Scalars['ID']['output']>;
  rootFolderNodeId?: Maybe<Scalars['ID']['output']>;
  usedBySceneEntityIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
};

export type PanoramicImage = {
  __typename?: 'PanoramicImage';
  captureId?: Maybe<Scalars['ID']['output']>;
  cartographicPosition?: Maybe<Array<Maybe<Array<Scalars['Float']['output']>>>>;
  id?: Maybe<Scalars['ID']['output']>;
  siteId?: Maybe<Scalars['ID']['output']>;
  texture?: Maybe<Storage>;
  textureId?: Maybe<Scalars['ID']['output']>;
};

export type PanoramicImageField = {
  __typename?: 'PanoramicImageField';
  file?: Maybe<File>;
  fileId?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  relativePosition?: Maybe<Array<Scalars['Float']['output']>>;
  relativeRotation?: Maybe<Array<Scalars['Float']['output']>>;
};

export type PanoramicProcess = {
  __typename?: 'PanoramicProcess';
  account?: Maybe<Account>;
  accountId?: Maybe<Scalars['ID']['output']>;
  captureId?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdByUserId?: Maybe<Scalars['ID']['output']>;
  epsg?: Maybe<Scalars['String']['output']>;
  format?: Maybe<Scalars['String']['output']>;
  formatFile?: Maybe<File>;
  formatFileId?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  imagesWithStatus?: Maybe<Array<Maybe<ImagesWithStatusField>>>;
  kind?: Maybe<Scalars['String']['output']>;
  lastStep?: Maybe<Scalars['String']['output']>;
  lastStepIsDone?: Maybe<Scalars['Boolean']['output']>;
  projectId?: Maybe<Scalars['ID']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  renderObjectId?: Maybe<Scalars['ID']['output']>;
  sceneEntityId?: Maybe<Scalars['ID']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  steps?: Maybe<Array<Maybe<StepField>>>;
  user?: Maybe<User>;
};

export type PanoramicRenderObject = {
  __typename?: 'PanoramicRenderObject';
  accountId?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  kind?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  panoramicImages?: Maybe<Array<Maybe<PanoramicImageField>>>;
  process?: Maybe<Process>;
  processId?: Maybe<Scalars['ID']['output']>;
  rootFolderNodeId?: Maybe<Scalars['ID']['output']>;
  usedBySceneEntityIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
};

export type PanoramicRenderObjectImage = {
  fileId?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  panoramicRenderObjectId?: InputMaybe<Scalars['ID']['input']>;
  relativePosition?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  relativeRotation?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
};

export type PanoramicVideoProcess = {
  __typename?: 'PanoramicVideoProcess';
  account?: Maybe<Account>;
  accountId?: Maybe<Scalars['ID']['output']>;
  captureId?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdByUserId?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  kind?: Maybe<Scalars['String']['output']>;
  lastStep?: Maybe<Scalars['String']['output']>;
  lastStepIsDone?: Maybe<Scalars['Boolean']['output']>;
  modelTypes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  projectId?: Maybe<Scalars['ID']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  renderObjectId?: Maybe<Scalars['ID']['output']>;
  sceneEntityId?: Maybe<Scalars['ID']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  steps?: Maybe<Array<Maybe<StepField>>>;
  user?: Maybe<User>;
};

export type PanoramicVideoRenderObject = {
  __typename?: 'PanoramicVideoRenderObject';
  accountId?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  kind?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  process?: Maybe<Process>;
  processId?: Maybe<Scalars['ID']['output']>;
  rootFolderNodeId?: Maybe<Scalars['ID']['output']>;
  usedBySceneEntityIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
};

export type PartInput = {
  ETag?: InputMaybe<Scalars['String']['input']>;
  PartNumber?: InputMaybe<Scalars['Float']['input']>;
};

export type PermissionPolicy = {
  __typename?: 'PermissionPolicy';
  accountId?: Maybe<Scalars['ID']['output']>;
  actionType?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  objectId?: Maybe<Scalars['ID']['output']>;
  objectType?: Maybe<Scalars['String']['output']>;
  subjectId?: Maybe<Scalars['ID']['output']>;
  subjectType?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type PermissionPolicyInput = {
  accountId: Scalars['String']['input'];
  actionType: PolicyActionTypeInput;
  objectId: Scalars['String']['input'];
  objectType: PolicyObjectTypeInput;
  subjectId: Scalars['String']['input'];
  subjectType: PolicySubjectTypeInput;
};

export type PermissionPolicyWithOutSubjectInput = {
  accountId: Scalars['String']['input'];
  actionType: PolicyActionTypeInput;
  objectId: Scalars['String']['input'];
  objectType: PolicyObjectTypeInput;
  subjectId?: InputMaybe<Scalars['String']['input']>;
  subjectType: PolicySubjectTypeInput;
};

export enum PolicyActionTypeInput {
  Admin = 'ADMIN',
  Delete = 'DELETE',
  Edit = 'EDIT',
  EditRequester = 'EDIT_REQUESTER',
  List = 'LIST',
  Move = 'MOVE',
  Read = 'READ',
}

export enum PolicyObjectTypeInput {
  All = 'ALL',
  Project = 'PROJECT',
  ProjectGroup = 'PROJECT_GROUP',
  ProjectNode = 'PROJECT_NODE',
  SceneEntity = 'SCENE_ENTITY',
  SystemNode = 'SYSTEM_NODE',
}

export enum PolicySubjectTypeInput {
  Group = 'GROUP',
  Role = 'ROLE',
  ShareLink = 'SHARE_LINK',
  User = 'USER',
}

export type Position = {
  __typename?: 'Position';
  x?: Maybe<Scalars['Float']['output']>;
  y?: Maybe<Scalars['Float']['output']>;
  z?: Maybe<Scalars['Float']['output']>;
};

export type PresignedPutUrl = {
  __typename?: 'PresignedPutUrl';
  bucket?: Maybe<Scalars['String']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  method?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type Priority = {
  __typename?: 'Priority';
  action?: Maybe<Scalars['String']['output']>;
  color?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  rating?: Maybe<Scalars['String']['output']>;
  reportable?: Maybe<Scalars['Boolean']['output']>;
  risk?: Maybe<Scalars['String']['output']>;
};

export type Process =
  | AnnotationProcess
  | CesiumIonProcess
  | DefectProcess
  | DxfProcess
  | IfcProcess
  | ImageProjectionProcess
  | KmlProcess
  | ObjProcess
  | Ortho2dProcess
  | PanoramicProcess
  | PanoramicVideoProcess
  | Tileset3dProcess;

export type Project = {
  __typename?: 'Project';
  accountId?: Maybe<Scalars['ID']['output']>;
  address?: Maybe<Scalars['String']['output']>;
  annotationsRootFolderNodeId?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdByUserId?: Maybe<Scalars['ID']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  geoid?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  projectGroupId?: Maybe<Scalars['ID']['output']>;
  renderObjectsRootFolderNodeId?: Maybe<Scalars['ID']['output']>;
  rootFolderNodeId?: Maybe<Scalars['ID']['output']>;
  status?: Maybe<ProjectStatus>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  userFilesRootFolderNodeId?: Maybe<Scalars['ID']['output']>;
};

export type ProjectFileNode = {
  __typename?: 'ProjectFileNode';
  accountId?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  file?: Maybe<File>;
  fileId?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isDeletable?: Maybe<Scalars['Boolean']['output']>;
  kind?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  parentNodeId?: Maybe<Scalars['ID']['output']>;
  siteId?: Maybe<Scalars['ID']['output']>;
  tags?: Maybe<Array<Maybe<ProjectTagField>>>;
};

export type ProjectFolderNode = {
  __typename?: 'ProjectFolderNode';
  accountId?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isDeletable?: Maybe<Scalars['Boolean']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  kind?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  parentNodeId?: Maybe<Scalars['ID']['output']>;
  siteId?: Maybe<Scalars['ID']['output']>;
  tags?: Maybe<Array<Maybe<ProjectTagField>>>;
};

export type ProjectGroup = {
  __typename?: 'ProjectGroup';
  accountId?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type ProjectNode = ProjectFileNode | ProjectFolderNode;

export type ProjectNodeWithPagination = {
  __typename?: 'ProjectNodeWithPagination';
  data?: Maybe<Array<Maybe<ProjectNode>>>;
  totalNumberOfFiles?: Maybe<Scalars['Int']['output']>;
  totalNumberOfPages?: Maybe<Scalars['Int']['output']>;
};

export enum ProjectStatus {
  Deleted = 'DELETED',
  Deleting = 'DELETING',
}

export enum ProjectStatusInput {
  Deleted = 'DELETED',
  Deleting = 'DELETING',
}

export type ProjectTagField = {
  __typename?: 'ProjectTagField';
  key?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type Query = {
  __typename?: 'Query';
  abortMultipartUpload?: Maybe<Scalars['Boolean']['output']>;
  accountByContext?: Maybe<Account>;
  accounts?: Maybe<Array<Maybe<Account>>>;
  allChildNodesByParentNodeId?: Maybe<Array<Maybe<Node>>>;
  allChildProjectNodesByParentNodeId?: Maybe<Array<Maybe<ProjectNode>>>;
  allDefectTypes?: Maybe<Array<Maybe<DefectType>>>;
  asset?: Maybe<Asset>;
  assetsByCaptureId?: Maybe<Array<Maybe<Asset>>>;
  batchPresignedPutUrls?: Maybe<Array<Maybe<PresignedPutUrl>>>;
  batchUploadFileMetadata?: Maybe<Array<Maybe<UploadFileMetadata>>>;
  bucketAndRegion?: Maybe<BucketAndRegion>;
  buildings?: Maybe<Array<Maybe<Building>>>;
  capture?: Maybe<Capture>;
  captureSource?: Maybe<CaptureSource>;
  captureTypes?: Maybe<Array<Maybe<CaptureType>>>;
  captures?: Maybe<Array<Maybe<Capture>>>;
  capturesAllBySiteId?: Maybe<Array<Maybe<Capture>>>;
  capturesByAccountId?: Maybe<Array<Maybe<Capture>>>;
  capturesByIds?: Maybe<Array<Maybe<Capture>>>;
  capturesBySiteId?: Maybe<Array<Maybe<Capture>>>;
  capturesStorageUsageWithSharedSitesByAccountId?: Maybe<Array<Maybe<CaptureStorageUsage>>>;
  capturesWithSharedSitesByAccountId?: Maybe<Array<Maybe<Capture>>>;
  checkSuperAdmin?: Maybe<Scalars['Boolean']['output']>;
  childNodeIdsByParentNodeId?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  childProjectNodeIdsByParentNodeId?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  childProjectNodeIdsByRenderObjectIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  completeMultipartUpload?: Maybe<Scalars['String']['output']>;
  countActiveAnnotationsByTemplateId?: Maybe<Scalars['Int']['output']>;
  countFileImportRequestsByDataImportRequestId?: Maybe<Scalars['Float']['output']>;
  countFilesToDownloadByNodeIds?: Maybe<Scalars['Int']['output']>;
  countFilesToDownloadByProjectNodeIds?: Maybe<Scalars['Int']['output']>;
  countFilesToDownloadBySiteIds?: Maybe<Scalars['Int']['output']>;
  countNumberOfNodesForParentNodes?: Maybe<Scalars['Int']['output']>;
  defect?: Maybe<Defect>;
  defectByObjectOfInterestGroupId?: Maybe<Defect>;
  defectProcess?: Maybe<DefectProcess>;
  defectStatuses?: Maybe<Array<Maybe<DefectStatus>>>;
  defectTypes?: Maybe<Array<Maybe<DefectType>>>;
  defectsByCaptureIds?: Maybe<Array<Maybe<Defect>>>;
  defectsByCaptureIdsForReportCreation?: Maybe<Array<Maybe<Defect>>>;
  elements?: Maybe<Array<Maybe<Element>>>;
  externalShare?: Maybe<ExternalShare>;
  externalShareByIdAndSharedToId?: Maybe<ExternalShare>;
  externalShares?: Maybe<Array<Maybe<ExternalShare>>>;
  externalSharesBySiteId?: Maybe<Array<Maybe<ExternalShare>>>;
  file?: Maybe<File>;
  filesByIds?: Maybe<Array<Maybe<File>>>;
  filesByIdsWithSharedToIdAndSiteId?: Maybe<Array<Maybe<File>>>;
  filesByRenderObjectId?: Maybe<Array<Maybe<File>>>;
  filesBySiteId?: Maybe<Array<Maybe<File>>>;
  filesBySiteIdWithSearchAndPagination?: Maybe<FileWithPagination>;
  findAccountFileUsageByAccountContext?: Maybe<AccountFileUsage>;
  findActiveAnnotationsByAnnotationIds?: Maybe<Array<Maybe<Annotation>>>;
  findActiveVersionByAnnotationId?: Maybe<Annotation>;
  findDataImportRequestByDataImportRequestId?: Maybe<DataImportRequest>;
  findFileImportRequestsByDataImportRequestId?: Maybe<Array<Maybe<FileImportRequest>>>;
  findFolderImportRequestData?: Maybe<Array<Maybe<FolderImportRequest>>>;
  findPlatformManagedAWSS3ApplicationCredentialsByBucketName?: Maybe<
    Array<Maybe<ApplicationCredential>>
  >;
  findReportV2ById?: Maybe<ReportV2>;
  findSceneEntitiesByIds?: Maybe<Array<Maybe<SceneEntity>>>;
  findSceneEntityById?: Maybe<SceneEntity>;
  findSystemFileNodeByFileId?: Maybe<Node>;
  findSystemNodeMostPermissiveActionTypeFromParentNodeChainWithSystemNodeId?: Maybe<PolicyActionTypeInput>;
  findSystemNodesByIds?: Maybe<Array<Maybe<Node>>>;
  findTrainModelsByAccountId?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  folderNodesOfAccountByParentNodeId?: Maybe<Array<Maybe<FolderNode>>>;
  folderProjectNodesOfAccountByParentNodeId?: Maybe<Array<Maybe<ProjectFolderNode>>>;
  getSystemFolderNodeTopologicallySortedDescendantFolderNodes?: Maybe<Array<Maybe<Node>>>;
  hasAllFilesBeenPersistedByIds?: Maybe<Scalars['Boolean']['output']>;
  image?: Maybe<Image>;
  imageFilesByCaptureIds?: Maybe<Array<Maybe<Image>>>;
  imageProjectionSceneEntitiesByCaptureIds?: Maybe<Array<Maybe<SceneEntity>>>;
  imageProjectionSceneEntitiesBySharedToIdAndCaptureIds?: Maybe<Array<Maybe<SceneEntity>>>;
  imagesByCaptureId?: Maybe<Array<Maybe<Image>>>;
  imagesByCaptureIds?: Maybe<Array<Maybe<Image>>>;
  imagesByFilenameAndCaptureId?: Maybe<Array<Maybe<Image>>>;
  imagesByIds?: Maybe<Array<Maybe<Image>>>;
  listAccountIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  listActionTypesByObjectType?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  listAnnotationProcessesByProjectId?: Maybe<Array<Maybe<Process>>>;
  listAnnotationTemplatesByAccountContext?: Maybe<Array<Maybe<AnnotationTemplate>>>;
  listAnnotationTemplatesByProjectId?: Maybe<Array<Maybe<AnnotationTemplate>>>;
  listAnnotationsByAnnotationGroupId?: Maybe<Array<Maybe<Annotation>>>;
  listAnnotationsByProjectId?: Maybe<Array<Maybe<Annotation>>>;
  listFeatureEntitlementsByAccountContext?: Maybe<Array<Maybe<FeatureEntitlement>>>;
  listGroupingPoliciesWithAccountContext?: Maybe<Array<Maybe<GroupingPolicy>>>;
  listHistoryByAnnotationId?: Maybe<Array<Maybe<Annotation>>>;
  listImageTilesByFileIds?: Maybe<Array<Maybe<ImageTiles>>>;
  listLayersByLayerIds?: Maybe<Array<Maybe<Layer>>>;
  listLayersByProjectId?: Maybe<Array<Maybe<Layer>>>;
  listLayersByRenderObjectIds?: Maybe<Array<Maybe<Layer>>>;
  listManualProcessesByProjectId?: Maybe<Array<Maybe<Process>>>;
  listModelsByAccountId?: Maybe<Array<Maybe<Model>>>;
  listNonePersistedNodes?: Maybe<Array<Maybe<Node>>>;
  listPartsForMultipartUpload?: Maybe<Array<Maybe<MultipartUploadPart>>>;
  listPartsForMultipartUploadForFileImportRequest?: Maybe<Array<Maybe<MultipartUploadPart>>>;
  listPermissionPoliciesForObjectIdAndTypeWithAccountContext?: Maybe<
    Array<Maybe<PermissionPolicy>>
  >;
  listPermissionPoliciesWithAccountContext?: Maybe<Array<Maybe<PermissionPolicy>>>;
  listProcessesByLayerIds?: Maybe<Array<Maybe<Process>>>;
  listProjectGroupsByAccountContext?: Maybe<Array<Maybe<ProjectGroup>>>;
  listProjectIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  listProjectsByAccountContext?: Maybe<Array<Maybe<Project>>>;
  listRenderObjectByIds?: Maybe<Array<Maybe<RenderObject>>>;
  listRenderObjectsBySceneEntityIds?: Maybe<Array<Maybe<RenderObject>>>;
  listReportsV2ByAccountContext?: Maybe<Array<Maybe<ReportV2>>>;
  listRolesByAccountContext?: Maybe<Array<Maybe<Role>>>;
  listSceneEntitiesByProjectIdAndRendererTypes?: Maybe<Array<Maybe<SceneEntity>>>;
  listShareLinksByProjectId?: Maybe<Array<Maybe<ShareLink>>>;
  listSubjectToObjectsPermissionPoliciesWithExpandedSubjectIds?: Maybe<
    Array<Maybe<PermissionPolicy>>
  >;
  listSystemNodesByParentNodeId?: Maybe<NodeWithPagination>;
  listTrainingsByModelId?: Maybe<Array<Maybe<Training>>>;
  listUploadParts?: Maybe<Array<Maybe<UploadPart>>>;
  listUserGroupsByAccountContext?: Maybe<Array<Maybe<UserGroup>>>;
  listUsersByAccountId?: Maybe<Array<Maybe<UserV2>>>;
  matchObjectSize?: Maybe<Scalars['Boolean']['output']>;
  multipartUploadId?: Maybe<Scalars['String']['output']>;
  node?: Maybe<Node>;
  nodesByIds?: Maybe<Array<Maybe<Node>>>;
  nodesOfAccountByParentNodeId?: Maybe<Array<Maybe<Node>>>;
  nodesOfNonPersistedFilesWithSearchAndPagination?: Maybe<NodeWithPagination>;
  nodesOfParentNodeIdWithSearchAndPagination?: Maybe<NodeWithPagination>;
  objectOfInterest?: Maybe<ObjectOfInterest>;
  objectOfInterestByObjectOfInterestGroupId?: Maybe<ObjectOfInterest>;
  objectOfInterestGroup?: Maybe<ObjectOfInterestGroup>;
  objectOfInterestGroupByIdAndSharedToId?: Maybe<ObjectOfInterestGroup>;
  /** @deprecated `objectOfInterestsByCaptureIds` is a typo, use `objectsOfInterestByCaptureIds` instead */
  objectOfInterestsByCaptureIds?: Maybe<Array<Maybe<ObjectOfInterest>>>;
  objectsOfInterestByCaptureIdsForReportCreation?: Maybe<Array<Maybe<ObjectOfInterest>>>;
  objectsOfInterestsByCaptureIds?: Maybe<Array<Maybe<ObjectOfInterest>>>;
  panoramicImagesByCaptureId?: Maybe<Array<Maybe<PanoramicImage>>>;
  panoramicImagesBySiteId?: Maybe<Array<Maybe<PanoramicImage>>>;
  parentNodesChainByParentNodeId?: Maybe<Array<Maybe<FolderNode>>>;
  parentNodesChainByParentNodeIdV2?: Maybe<Array<Maybe<Node>>>;
  parentProjectNodesChainByParentNodeId?: Maybe<Array<Maybe<ProjectFolderNode>>>;
  presignedMultipartUrl?: Maybe<Scalars['String']['output']>;
  presignedPutUrl?: Maybe<PresignedPutUrl>;
  priorities?: Maybe<Array<Maybe<Priority>>>;
  processes?: Maybe<Array<Maybe<Process>>>;
  processesByCaptureId?: Maybe<Array<Maybe<Process>>>;
  processesWithoutErrorByCaptureId?: Maybe<Array<Maybe<Process>>>;
  processesWithoutErrorByCaptureIds?: Maybe<Array<Maybe<Process>>>;
  projectNodesByIds?: Maybe<Array<Maybe<ProjectNode>>>;
  projectNodesByRenderObjectIdsWithPagination?: Maybe<ProjectNodeWithPagination>;
  projectNodesOfParentNodeIdWithSearchAndPagination?: Maybe<ProjectNodeWithPagination>;
  readAccountByContext?: Maybe<AccountV2>;
  readProcessById?: Maybe<Process>;
  readProjectById?: Maybe<Project>;
  readReportV2ByIdWithPayload?: Maybe<ReportV2>;
  readRoleById?: Maybe<Role>;
  readShareLinkById?: Maybe<ShareLink>;
  readShareLinkByShareToken?: Maybe<ShareLink>;
  readUserByContext?: Maybe<UserV2>;
  readUserById?: Maybe<UserV2>;
  readUserGroupById?: Maybe<UserGroup>;
  renderObjectsByAccountId?: Maybe<Array<Maybe<RenderObject>>>;
  report?: Maybe<Report>;
  reportByShareId?: Maybe<Report>;
  reportsByAccountId?: Maybe<Array<Maybe<Report>>>;
  roofConstructionTypes?: Maybe<Array<Maybe<RoofConstructionType>>>;
  roofMaterialTypes?: Maybe<Array<Maybe<RoofMaterialType>>>;
  searchSystemNodesRecursive?: Maybe<NodeWithPagination>;
  sendCaptureProcessingEmail?: Maybe<Scalars['Boolean']['output']>;
  sendCapturePublishedEmail?: Maybe<Scalars['Boolean']['output']>;
  sendOpsDataUploadedEmail?: Maybe<Scalars['Boolean']['output']>;
  session?: Maybe<Session>;
  shareSiteAnalytics?: Maybe<Array<Maybe<ShareSiteAnalytics>>>;
  site?: Maybe<Site>;
  sites?: Maybe<Array<Maybe<Site>>>;
  sitesByAccountId?: Maybe<Array<Maybe<Site>>>;
  storage?: Maybe<Storage>;
  storagesByIds?: Maybe<Array<Maybe<Storage>>>;
  structuredZipRequest?: Maybe<StructuredZipRequest>;
  subscriptionsByAccountId?: Maybe<Array<Maybe<Subscription>>>;
  taskTypes?: Maybe<Array<Maybe<TaskType>>>;
  user?: Maybe<User>;
  userByAccountId?: Maybe<User>;
  users?: Maybe<Array<Maybe<User>>>;
  usersByAccountId?: Maybe<Array<Maybe<User>>>;
  usersByAccountIdWithDeactivated?: Maybe<Array<Maybe<User>>>;
  zipRequest?: Maybe<ZipRequest>;
};

export type QueryAbortMultipartUploadArgs = {
  bucket?: InputMaybe<Scalars['String']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  uploadId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryAllChildNodesByParentNodeIdArgs = {
  parentNodeId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryAllChildProjectNodesByParentNodeIdArgs = {
  parentNodeId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryAssetArgs = {
  assetId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryAssetsByCaptureIdArgs = {
  captureId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryBatchPresignedPutUrlsArgs = {
  fileNames?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryBatchUploadFileMetadataArgs = {
  fileNames?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryCaptureArgs = {
  captureId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryCaptureSourceArgs = {
  captureSourceId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryCapturesAllBySiteIdArgs = {
  siteId: Scalars['ID']['input'];
};

export type QueryCapturesByIdsArgs = {
  captureIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryCapturesBySiteIdArgs = {
  siteId: Scalars['ID']['input'];
};

export type QueryChildNodeIdsByParentNodeIdArgs = {
  inputTag?: InputMaybe<InputTagInput>;
  nodesWithDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  parentNodeId?: InputMaybe<Scalars['ID']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
};

export type QueryChildProjectNodeIdsByParentNodeIdArgs = {
  inputTag?: InputMaybe<InputTagInput>;
  parentNodeId?: InputMaybe<Scalars['ID']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  siteId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryChildProjectNodeIdsByRenderObjectIdsArgs = {
  inputTag?: InputMaybe<InputTagInput>;
  renderObjectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  siteId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryCompleteMultipartUploadArgs = {
  bucket?: InputMaybe<Scalars['String']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  parts?: InputMaybe<Array<InputMaybe<PartInput>>>;
  uploadId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryCountActiveAnnotationsByTemplateIdArgs = {
  filterDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  templateId: Scalars['ID']['input'];
};

export type QueryCountFileImportRequestsByDataImportRequestIdArgs = {
  dataImportRequestId: Scalars['String']['input'];
  fileKind?: InputMaybe<Scalars['String']['input']>;
};

export type QueryCountFilesToDownloadByNodeIdsArgs = {
  nodeIds: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type QueryCountFilesToDownloadByProjectNodeIdsArgs = {
  nodeIds: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type QueryCountFilesToDownloadBySiteIdsArgs = {
  siteIds: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type QueryCountNumberOfNodesForParentNodesArgs = {
  parentNodeIds: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type QueryDefectArgs = {
  defectId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryDefectByObjectOfInterestGroupIdArgs = {
  defectId?: InputMaybe<Scalars['ID']['input']>;
  objectOfInterestGroupId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryDefectProcessArgs = {
  processId: Scalars['String']['input'];
};

export type QueryDefectStatusesArgs = {
  sharedToId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryDefectTypesArgs = {
  sharedToId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryDefectsByCaptureIdsArgs = {
  captureIds: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type QueryDefectsByCaptureIdsForReportCreationArgs = {
  captureIds: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type QueryElementsArgs = {
  sharedToId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryExternalShareArgs = {
  externalShareId: Scalars['ID']['input'];
};

export type QueryExternalShareByIdAndSharedToIdArgs = {
  externalShareId?: InputMaybe<Scalars['ID']['input']>;
  sharedToId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryExternalSharesBySiteIdArgs = {
  siteId: Scalars['ID']['input'];
};

export type QueryFileArgs = {
  fileId: Scalars['ID']['input'];
};

export type QueryFilesByIdsArgs = {
  fileIds: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type QueryFilesByIdsWithSharedToIdAndSiteIdArgs = {
  fileIds: Array<InputMaybe<Scalars['ID']['input']>>;
  sharedToId: Scalars['ID']['input'];
  siteId: Scalars['ID']['input'];
};

export type QueryFilesByRenderObjectIdArgs = {
  renderObjectId: Scalars['ID']['input'];
};

export type QueryFilesBySiteIdArgs = {
  siteId: Scalars['ID']['input'];
};

export type QueryFilesBySiteIdWithSearchAndPaginationArgs = {
  pageIndex: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  siteId: Scalars['ID']['input'];
};

export type QueryFindActiveAnnotationsByAnnotationIdsArgs = {
  annotationIds: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type QueryFindActiveVersionByAnnotationIdArgs = {
  annotationId: Scalars['ID']['input'];
};

export type QueryFindDataImportRequestByDataImportRequestIdArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryFindFileImportRequestsByDataImportRequestIdArgs = {
  dataImportRequestId: Scalars['String']['input'];
  fileKind?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  start?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryFindFolderImportRequestDataArgs = {
  filter?: InputMaybe<FindFolderImportRequestByDataImportRequestInput>;
};

export type QueryFindPlatformManagedAwss3ApplicationCredentialsByBucketNameArgs = {
  bucketName: Scalars['String']['input'];
};

export type QueryFindReportV2ByIdArgs = {
  reportId: Scalars['ID']['input'];
};

export type QueryFindSceneEntitiesByIdsArgs = {
  sceneEntityIds: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type QueryFindSceneEntityByIdArgs = {
  sceneEntityId: Scalars['ID']['input'];
};

export type QueryFindSystemFileNodeByFileIdArgs = {
  fileId: Scalars['ID']['input'];
};

export type QueryFindSystemNodeMostPermissiveActionTypeFromParentNodeChainWithSystemNodeIdArgs = {
  systemNodeId: Scalars['String']['input'];
};

export type QueryFindSystemNodesByIdsArgs = {
  systemNodeIds: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type QueryFolderNodesOfAccountByParentNodeIdArgs = {
  parentNodeId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryFolderProjectNodesOfAccountByParentNodeIdArgs = {
  parentNodeId?: InputMaybe<Scalars['ID']['input']>;
  siteId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryGetSystemFolderNodeTopologicallySortedDescendantFolderNodesArgs = {
  systemFolderNodeId: Scalars['ID']['input'];
};

export type QueryHasAllFilesBeenPersistedByIdsArgs = {
  fileIds: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type QueryImageArgs = {
  imageId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryImageFilesByCaptureIdsArgs = {
  captureIds: Array<InputMaybe<Scalars['ID']['input']>>;
  sharedToId?: InputMaybe<Scalars['ID']['input']>;
  siteId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryImageProjectionSceneEntitiesByCaptureIdsArgs = {
  captureIds: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type QueryImageProjectionSceneEntitiesBySharedToIdAndCaptureIdsArgs = {
  captureIds: Array<InputMaybe<Scalars['ID']['input']>>;
  sharedToId: Scalars['ID']['input'];
  siteId: Scalars['ID']['input'];
};

export type QueryImagesByCaptureIdArgs = {
  captureId: Scalars['ID']['input'];
  sharedToId?: InputMaybe<Scalars['ID']['input']>;
  siteId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryImagesByCaptureIdsArgs = {
  captureIds: Array<InputMaybe<Scalars['ID']['input']>>;
  sharedToId?: InputMaybe<Scalars['ID']['input']>;
  siteId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryImagesByFilenameAndCaptureIdArgs = {
  captureId?: InputMaybe<Scalars['ID']['input']>;
  filename?: InputMaybe<Scalars['String']['input']>;
};

export type QueryImagesByIdsArgs = {
  imageIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryListAccountIdsArgs = {
  pageIndex: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
};

export type QueryListActionTypesByObjectTypeArgs = {
  objectType: Scalars['String']['input'];
};

export type QueryListAnnotationProcessesByProjectIdArgs = {
  projectId: Scalars['ID']['input'];
};

export type QueryListAnnotationTemplatesByProjectIdArgs = {
  projectId: Scalars['ID']['input'];
};

export type QueryListAnnotationsByAnnotationGroupIdArgs = {
  annotationGroupId: Scalars['ID']['input'];
};

export type QueryListAnnotationsByProjectIdArgs = {
  projectId: Scalars['ID']['input'];
};

export type QueryListGroupingPoliciesWithAccountContextArgs = {
  sourceId?: InputMaybe<Scalars['String']['input']>;
  sourceType?: InputMaybe<PolicySubjectTypeInput>;
  targetId?: InputMaybe<Scalars['String']['input']>;
  targetType?: InputMaybe<PolicySubjectTypeInput>;
};

export type QueryListHistoryByAnnotationIdArgs = {
  annotationId: Scalars['ID']['input'];
};

export type QueryListImageTilesByFileIdsArgs = {
  fileIds: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type QueryListLayersByLayerIdsArgs = {
  layerIds: Array<Scalars['ID']['input']>;
};

export type QueryListLayersByProjectIdArgs = {
  projectId: Scalars['ID']['input'];
};

export type QueryListLayersByRenderObjectIdsArgs = {
  renderObjectIds: Array<Scalars['ID']['input']>;
};

export type QueryListManualProcessesByProjectIdArgs = {
  projectId: Scalars['ID']['input'];
};

export type QueryListNonePersistedNodesArgs = {
  parentNodeId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryListPartsForMultipartUploadArgs = {
  bucket: Scalars['String']['input'];
  key: Scalars['String']['input'];
  uploadId: Scalars['String']['input'];
};

export type QueryListPartsForMultipartUploadForFileImportRequestArgs = {
  bucket: Scalars['String']['input'];
  key: Scalars['String']['input'];
  uploadId: Scalars['String']['input'];
};

export type QueryListPermissionPoliciesForObjectIdAndTypeWithAccountContextArgs = {
  objectId: Scalars['String']['input'];
  objectType: PolicyObjectTypeInput;
};

export type QueryListPermissionPoliciesWithAccountContextArgs = {
  actionType?: InputMaybe<PolicyActionTypeInput>;
  objectId?: InputMaybe<Scalars['String']['input']>;
  objectType?: InputMaybe<PolicyObjectTypeInput>;
  subjectId?: InputMaybe<Scalars['String']['input']>;
  subjectType?: InputMaybe<PolicySubjectTypeInput>;
};

export type QueryListProcessesByLayerIdsArgs = {
  layerIds: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type QueryListProjectIdsArgs = {
  pageIndex: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
};

export type QueryListRenderObjectByIdsArgs = {
  renderObjectIds: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type QueryListRenderObjectsBySceneEntityIdsArgs = {
  sceneEntityIds: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type QueryListSceneEntitiesByProjectIdAndRendererTypesArgs = {
  projectId: Scalars['ID']['input'];
  rendererTypes: Array<InputMaybe<Scalars['String']['input']>>;
};

export type QueryListShareLinksByProjectIdArgs = {
  projectId: Scalars['ID']['input'];
};

export type QueryListSubjectToObjectsPermissionPoliciesWithExpandedSubjectIdsArgs = {
  actionTypes?: InputMaybe<Array<InputMaybe<PolicyActionTypeInput>>>;
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  objectTypes?: InputMaybe<Array<InputMaybe<PolicyObjectTypeInput>>>;
  subjectId: Scalars['String']['input'];
};

export type QueryListSystemNodesByParentNodeIdArgs = {
  hydrateFiles?: InputMaybe<Scalars['Boolean']['input']>;
  includeHidden?: InputMaybe<Scalars['Boolean']['input']>;
  includeMigratedData?: InputMaybe<Scalars['Boolean']['input']>;
  nodeKinds?: InputMaybe<Array<InputMaybe<SystemNodeKindInput>>>;
  pageIndex: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  parentNodeId?: InputMaybe<Scalars['ID']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
};

export type QueryListTrainingsByModelIdArgs = {
  modelId: Scalars['String']['input'];
};

export type QueryListUploadPartsArgs = {
  bucket?: InputMaybe<Scalars['String']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  uploadId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryMatchObjectSizeArgs = {
  bucket?: InputMaybe<Scalars['String']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Float']['input']>;
};

export type QueryMultipartUploadIdArgs = {
  bucket?: InputMaybe<Scalars['String']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
};

export type QueryNodeArgs = {
  nodeId: Scalars['ID']['input'];
};

export type QueryNodesByIdsArgs = {
  nodeIds: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type QueryNodesOfAccountByParentNodeIdArgs = {
  parentNodeId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryNodesOfNonPersistedFilesWithSearchAndPaginationArgs = {
  endDate?: InputMaybe<Scalars['String']['input']>;
  fileStorageStatus?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  pageIndex: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
};

export type QueryNodesOfParentNodeIdWithSearchAndPaginationArgs = {
  inputTag?: InputMaybe<InputTagInput>;
  nodesWithDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  pageIndex: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  parentNodeId?: InputMaybe<Scalars['ID']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
};

export type QueryObjectOfInterestArgs = {
  objectOfInterestId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryObjectOfInterestByObjectOfInterestGroupIdArgs = {
  objectOfInterestGroupId?: InputMaybe<Scalars['ID']['input']>;
  objectOfInterestId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryObjectOfInterestGroupArgs = {
  objectOfInterestGroupId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryObjectOfInterestGroupByIdAndSharedToIdArgs = {
  objectOfInterestGroupId?: InputMaybe<Scalars['ID']['input']>;
  sharedToId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryObjectOfInterestsByCaptureIdsArgs = {
  captureIds: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type QueryObjectsOfInterestByCaptureIdsForReportCreationArgs = {
  captureIds: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type QueryObjectsOfInterestsByCaptureIdsArgs = {
  captureIds: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type QueryPanoramicImagesByCaptureIdArgs = {
  captureId: Scalars['ID']['input'];
};

export type QueryPanoramicImagesBySiteIdArgs = {
  siteID: Scalars['ID']['input'];
};

export type QueryParentNodesChainByParentNodeIdArgs = {
  parentNodeId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryParentNodesChainByParentNodeIdV2Args = {
  accessedByLinkNodeId?: InputMaybe<Scalars['ID']['input']>;
  parentNodeId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryParentProjectNodesChainByParentNodeIdArgs = {
  parentNodeId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryPresignedMultipartUrlArgs = {
  key?: InputMaybe<Scalars['String']['input']>;
  partNumber?: InputMaybe<Scalars['Float']['input']>;
  uploadId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryPresignedPutUrlArgs = {
  fileName?: InputMaybe<Scalars['String']['input']>;
};

export type QueryPrioritiesArgs = {
  sharedToId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryProcessesByCaptureIdArgs = {
  captureId: Scalars['ID']['input'];
};

export type QueryProcessesWithoutErrorByCaptureIdArgs = {
  captureId: Scalars['ID']['input'];
};

export type QueryProcessesWithoutErrorByCaptureIdsArgs = {
  captureIds: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type QueryProjectNodesByIdsArgs = {
  projectNodeIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type QueryProjectNodesByRenderObjectIdsWithPaginationArgs = {
  inputTag?: InputMaybe<InputTagInput>;
  pageIndex: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  renderObjectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  siteId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryProjectNodesOfParentNodeIdWithSearchAndPaginationArgs = {
  inputTag?: InputMaybe<InputTagInput>;
  pageIndex: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  parentNodeId?: InputMaybe<Scalars['ID']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  siteId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryReadProcessByIdArgs = {
  processId: Scalars['ID']['input'];
};

export type QueryReadProjectByIdArgs = {
  projectId: Scalars['ID']['input'];
};

export type QueryReadReportV2ByIdWithPayloadArgs = {
  reportId: Scalars['ID']['input'];
};

export type QueryReadRoleByIdArgs = {
  roleId: Scalars['ID']['input'];
};

export type QueryReadShareLinkByIdArgs = {
  shareLinkId: Scalars['ID']['input'];
};

export type QueryReadShareLinkByShareTokenArgs = {
  shareToken: Scalars['String']['input'];
};

export type QueryReadUserByIdArgs = {
  userId: Scalars['String']['input'];
};

export type QueryReadUserGroupByIdArgs = {
  userGroupId: Scalars['ID']['input'];
};

export type QueryReportArgs = {
  reportId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryReportByShareIdArgs = {
  shareId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryRoofConstructionTypesArgs = {
  sharedToId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryRoofMaterialTypesArgs = {
  sharedToId?: InputMaybe<Scalars['ID']['input']>;
};

export type QuerySearchSystemNodesRecursiveArgs = {
  hydrateFiles?: InputMaybe<Scalars['Boolean']['input']>;
  includeHidden?: InputMaybe<Scalars['Boolean']['input']>;
  includeMigratedData?: InputMaybe<Scalars['Boolean']['input']>;
  nodeKinds?: InputMaybe<Array<InputMaybe<SystemNodeKindInput>>>;
  pageIndex: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  parentNodeId?: InputMaybe<Scalars['ID']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
};

export type QuerySendCaptureProcessingEmailArgs = {
  captureId: Scalars['ID']['input'];
};

export type QuerySendCapturePublishedEmailArgs = {
  captureId: Scalars['ID']['input'];
};

export type QuerySendOpsDataUploadedEmailArgs = {
  addonMessage?: InputMaybe<Scalars['String']['input']>;
  captureId: Scalars['ID']['input'];
  isNewSite: Scalars['Boolean']['input'];
};

export type QuerySiteArgs = {
  siteId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryStorageArgs = {
  storageId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryStoragesByIdsArgs = {
  storageIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryStructuredZipRequestArgs = {
  structuredZipRequestId: Scalars['ID']['input'];
};

export type QueryUserArgs = {
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryZipRequestArgs = {
  zipRequestId?: InputMaybe<Scalars['String']['input']>;
};

export type RenderObject =
  | AnnotationRenderObject
  | CesiumIonRenderObject
  | DxfRenderObject
  | IfcRenderObject
  | ImageProjectionRenderObject
  | KmlRenderObject
  | ObjRenderObject
  | Ortho2dRenderObject
  | PanoramicRenderObject
  | PanoramicVideoRenderObject
  | Tileset3dRenderObject;

export type RenderStatusInputField = {
  defectStatus?: InputMaybe<Scalars['Boolean']['input']>;
  defectType?: InputMaybe<Scalars['Boolean']['input']>;
  defects?: InputMaybe<RenderStatusOfDefectsInputField>;
  element?: InputMaybe<Scalars['Boolean']['input']>;
  objectsOfInterest?: InputMaybe<RenderStatusOfObjectsOfInteretInputField>;
  priority?: InputMaybe<Scalars['Boolean']['input']>;
  roofConstructionType?: InputMaybe<Scalars['Boolean']['input']>;
  roofMaterialType?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RenderStatusOfDefectsInputField = {
  comment?: InputMaybe<Scalars['Boolean']['input']>;
  defectStatus?: InputMaybe<Scalars['Boolean']['input']>;
  defectType?: InputMaybe<Scalars['Boolean']['input']>;
  element?: InputMaybe<Scalars['Boolean']['input']>;
  priority?: InputMaybe<Scalars['Boolean']['input']>;
  roofConstructionType?: InputMaybe<Scalars['Boolean']['input']>;
  roofMaterialType?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RenderStatusOfObjectsOfInteretInputField = {
  comment?: InputMaybe<Scalars['Boolean']['input']>;
  entityType?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Report = {
  __typename?: 'Report';
  accountId?: Maybe<Scalars['ID']['output']>;
  appendix?: Maybe<ReportAppendix>;
  createdAt?: Maybe<Scalars['String']['output']>;
  defectIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  defects?: Maybe<Array<Maybe<Defect>>>;
  fields?: Maybe<ReportFields>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  objectOfInterestIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  objectsOfInterest?: Maybe<Array<Maybe<ObjectOfInterest>>>;
  pdfGenerating?: Maybe<Scalars['Boolean']['output']>;
  pdfStatus?: Maybe<ReportPdfStatus>;
  pdfStorage?: Maybe<Storage>;
  pdfStorageId?: Maybe<Scalars['ID']['output']>;
  renderStatus?: Maybe<ReportRenderStatus>;
  shareId?: Maybe<Scalars['ID']['output']>;
  summary?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
  userId?: Maybe<Scalars['ID']['output']>;
};

export type ReportAppendix = {
  __typename?: 'ReportAppendix';
  conditionAssessmentResourceUrl?: Maybe<Scalars['String']['output']>;
  conditionAssessmentToShow?: Maybe<Scalars['Boolean']['output']>;
  roofDefectsResourceUrl?: Maybe<Scalars['String']['output']>;
  roofDefectsToShow?: Maybe<Scalars['Boolean']['output']>;
};

export type ReportFields = {
  __typename?: 'ReportFields';
  buildingIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  buildings?: Maybe<Array<Maybe<Building>>>;
  captureIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  captures?: Maybe<Array<Maybe<Capture>>>;
  defectStatusIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  defectStatuses?: Maybe<Array<Maybe<DefectStatus>>>;
  defectTypeIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  defectTypes?: Maybe<Array<Maybe<DefectType>>>;
  elementIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  elements?: Maybe<Array<Maybe<Element>>>;
  priorities?: Maybe<Array<Maybe<Priority>>>;
  priorityIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  roofMaterialTypeIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  roofMaterialTypes?: Maybe<Array<Maybe<RoofMaterialType>>>;
  siteIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  sites?: Maybe<Array<Maybe<Site>>>;
};

export type ReportPdfGenerateEvent = {
  __typename?: 'ReportPdfGenerateEvent';
  errorMessage?: Maybe<Scalars['String']['output']>;
  invokeId?: Maybe<Scalars['String']['output']>;
  pdfFileId?: Maybe<Scalars['ID']['output']>;
  requestedAt?: Maybe<Scalars['String']['output']>;
  requestedByUserId?: Maybe<Scalars['ID']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export enum ReportPdfStatus {
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  NoEvent = 'NO_EVENT',
  Success = 'SUCCESS',
}

export type ReportRenderStatus = {
  __typename?: 'ReportRenderStatus';
  /** @deprecated renderStatus.defectStatus is no longer supported, use renderStatus.defects.defectStatus */
  defectStatus?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated renderStatus.defectType is no longer supported, use renderStatus.defects.defectType */
  defectType?: Maybe<Scalars['Boolean']['output']>;
  defects?: Maybe<ReportRenderStatusOfDefects>;
  /** @deprecated renderStatus.element is no longer supported, use renderStatus.defects.element */
  element?: Maybe<Scalars['Boolean']['output']>;
  objectsOfInterest?: Maybe<ReportRenderStatusOfObjectsOfInterest>;
  /** @deprecated renderStatus.priority is no longer supported, use renderStatus.defects.priority */
  priority?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated renderStatus.roofConstructionType is no longer supported, use renderStatus.defects.roofConstructionType */
  roofConstructionType?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated renderStatus.roofMaterialType is no longer supported, use renderStatus.defects.roofMaterialType */
  roofMaterialType?: Maybe<Scalars['Boolean']['output']>;
};

export type ReportRenderStatusOfDefects = {
  __typename?: 'ReportRenderStatusOfDefects';
  comment?: Maybe<Scalars['Boolean']['output']>;
  defectStatus?: Maybe<Scalars['Boolean']['output']>;
  defectType?: Maybe<Scalars['Boolean']['output']>;
  element?: Maybe<Scalars['Boolean']['output']>;
  priority?: Maybe<Scalars['Boolean']['output']>;
  roofConstructionType?: Maybe<Scalars['Boolean']['output']>;
  roofMaterialType?: Maybe<Scalars['Boolean']['output']>;
};

export type ReportRenderStatusOfObjectsOfInterest = {
  __typename?: 'ReportRenderStatusOfObjectsOfInterest';
  comment?: Maybe<Scalars['Boolean']['output']>;
  entityType?: Maybe<Scalars['Boolean']['output']>;
};

export type ReportV2 = {
  __typename?: 'ReportV2';
  accountId?: Maybe<Scalars['ID']['output']>;
  annotationIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  annotationTemplates?: Maybe<Array<Maybe<AnnotationTemplate>>>;
  annotations?: Maybe<Array<Maybe<Annotation>>>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdByUserId?: Maybe<Scalars['ID']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  fileUrlsByIds?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  isPdfGenerating?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  pdfFile?: Maybe<File>;
  pdfFileId?: Maybe<Scalars['ID']['output']>;
  pdfGenerateEvents?: Maybe<Array<Maybe<ReportPdfGenerateEvent>>>;
  projectId?: Maybe<Scalars['ID']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedByUserId?: Maybe<Scalars['ID']['output']>;
};

export type Role = {
  __typename?: 'Role';
  accountId?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  permissionPolicies?: Maybe<Array<Maybe<PermissionPolicy>>>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  userGroupToRolePolicies?: Maybe<Array<Maybe<GroupingPolicy>>>;
  userToRolePolicies?: Maybe<Array<Maybe<GroupingPolicy>>>;
};

export type RoofConstructionType = {
  __typename?: 'RoofConstructionType';
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type RoofMaterialType = {
  __typename?: 'RoofMaterialType';
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type Rotation = {
  __typename?: 'Rotation';
  w?: Maybe<Scalars['Float']['output']>;
  x?: Maybe<Scalars['Float']['output']>;
  y?: Maybe<Scalars['Float']['output']>;
  z?: Maybe<Scalars['Float']['output']>;
};

export type SceneEntity = {
  __typename?: 'SceneEntity';
  accountId?: Maybe<Scalars['ID']['output']>;
  captureId?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  parentSceneEntityId?: Maybe<Scalars['ID']['output']>;
  position?: Maybe<Position>;
  projectId?: Maybe<Scalars['ID']['output']>;
  relativePosition?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  relativeRotation?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  renderObject?: Maybe<RenderObject>;
  renderObjectId?: Maybe<Scalars['ID']['output']>;
  rendererType?: Maybe<Scalars['String']['output']>;
  rotation?: Maybe<Rotation>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type SceneEntityPositionInput = {
  x?: InputMaybe<Scalars['Float']['input']>;
  y?: InputMaybe<Scalars['Float']['input']>;
  z?: InputMaybe<Scalars['Float']['input']>;
};

export type SceneEntityRotationInput = {
  w?: InputMaybe<Scalars['Float']['input']>;
  x?: InputMaybe<Scalars['Float']['input']>;
  y?: InputMaybe<Scalars['Float']['input']>;
  z?: InputMaybe<Scalars['Float']['input']>;
};

export type Session = {
  __typename?: 'Session';
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type ShareLink = {
  __typename?: 'ShareLink';
  accountId?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdByUserId?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isActivated?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  projectId?: Maybe<Scalars['ID']['output']>;
  shareToken?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedByUserId?: Maybe<Scalars['ID']['output']>;
};

export type ShareSiteAnalytics = {
  __typename?: 'ShareSiteAnalytics';
  accountId?: Maybe<Scalars['ID']['output']>;
  accountName?: Maybe<Scalars['String']['output']>;
  action?: Maybe<Scalars['String']['output']>;
  ip?: Maybe<Scalars['String']['output']>;
  numberOfSelectedDefects?: Maybe<Scalars['Int']['output']>;
  numberOfSelectedObjectOfInterests?: Maybe<Scalars['Int']['output']>;
  numberOfTotalDefects?: Maybe<Scalars['Int']['output']>;
  numberOfTotalObjectOfInterests?: Maybe<Scalars['Int']['output']>;
  siteId?: Maybe<Scalars['ID']['output']>;
  siteName?: Maybe<Scalars['String']['output']>;
  time?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  userEmail?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['ID']['output']>;
};

export type Site = {
  __typename?: 'Site';
  account?: Maybe<Account>;
  accountId?: Maybe<Scalars['ID']['output']>;
  address?: Maybe<Scalars['String']['output']>;
  allCaptures?: Maybe<Array<Maybe<Capture>>>;
  buildingIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  buildings?: Maybe<Array<Maybe<Building>>>;
  captures?: Maybe<Array<Maybe<Capture>>>;
  code?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['String']['output']>;
  geoid?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  latitude?: Maybe<Scalars['Float']['output']>;
  locality?: Maybe<Scalars['String']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  manager?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  projectGroupId?: Maybe<Scalars['ID']['output']>;
  projectNodesExist?: Maybe<Scalars['Boolean']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  siteAssetsNeeded?: Maybe<Scalars['Boolean']['output']>;
  storageUsage?: Maybe<Scalars['Float']['output']>;
  streetAddress?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  usersInSite?: Maybe<Array<Maybe<UsersInSite>>>;
};

export type StepField = {
  __typename?: 'StepField';
  comment?: Maybe<Scalars['String']['output']>;
  stepName?: Maybe<Scalars['String']['output']>;
};

export type Storage = {
  __typename?: 'Storage';
  accountId?: Maybe<Scalars['ID']['output']>;
  bucket?: Maybe<Scalars['String']['output']>;
  captureId?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  signedGetObjectDownloadUrl?: Maybe<Scalars['String']['output']>;
  signedGetObjectUrl?: Maybe<Scalars['String']['output']>;
  siteId?: Maybe<Scalars['ID']['output']>;
  size?: Maybe<Scalars['Float']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  usageType?: Maybe<Scalars['String']['output']>;
};

export type StorageSignedGetObjectDownloadUrlArgs = {
  expires?: InputMaybe<Scalars['Float']['input']>;
};

export type StorageSignedGetObjectUrlArgs = {
  expires?: InputMaybe<Scalars['Float']['input']>;
};

export type StructuredZipRequest = {
  __typename?: 'StructuredZipRequest';
  emailToUser?: Maybe<User>;
  expiresAt?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  signedGetObjectUrl?: Maybe<Scalars['String']['output']>;
  size?: Maybe<Scalars['Float']['output']>;
};

export type SubmitImageAnnotationInput = {
  defects?: InputMaybe<Array<InputMaybe<DefectAnnotationField>>>;
  imageId?: InputMaybe<Scalars['ID']['input']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  accountId?: Maybe<Scalars['ID']['output']>;
  billingPeriodUnit?: Maybe<Scalars['String']['output']>;
  deleted?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isFeatureEntitlementValid?: Maybe<Scalars['Boolean']['output']>;
  isValid?: Maybe<Scalars['Boolean']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  storageFeatureEntitlementValue?: Maybe<Scalars['Int']['output']>;
  userFeatureEntitlementValue?: Maybe<Scalars['Int']['output']>;
};

export enum SystemNodeAssociatedDomain {
  Annotation = 'Annotation',
  RenderObject = 'RenderObject',
}

export type SystemNodeAssociatedEntityInput = {
  domainName: SystemNodeAssociatedDomain;
  entityId: Scalars['String']['input'];
};

export type SystemNodeDependant = {
  __typename?: 'SystemNodeDependant';
  domainName: SystemNodeDependantDomain;
  entityId: Scalars['ID']['output'];
};

export enum SystemNodeDependantDomain {
  Annotation = 'Annotation',
  Project = 'Project',
  RenderObject = 'RenderObject',
}

export type SystemNodeDependantInput = {
  domainName: SystemNodeDependantDomain;
  entityId: Scalars['ID']['input'];
};

export enum SystemNodeKindInput {
  FileNode = 'FILE_NODE',
  FolderNode = 'FOLDER_NODE',
  LinkNode = 'LINK_NODE',
}

export type TagField = {
  __typename?: 'TagField';
  key?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type TagFieldInput = {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type TaskType = {
  __typename?: 'TaskType';
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type Tileset3d = {
  __typename?: 'Tileset3d';
  accountId?: Maybe<Scalars['ID']['output']>;
  capture?: Maybe<Capture>;
  captureId?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  rootJsonFileName?: Maybe<Scalars['String']['output']>;
  siteId?: Maybe<Scalars['ID']['output']>;
  storage?: Maybe<Tileset3dStorageField>;
};

export type Tileset3dFile = {
  __typename?: 'Tileset3dFile';
  accountId?: Maybe<Scalars['ID']['output']>;
  canDelete?: Maybe<Scalars['Boolean']['output']>;
  captures?: Maybe<Array<Maybe<Capture>>>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  httpUrl?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  kind?: Maybe<Scalars['String']['output']>;
  renderObjectIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  renderObjects?: Maybe<Array<Maybe<RenderObject>>>;
  signedGetObjectDownloadUrl?: Maybe<Scalars['String']['output']>;
  signedGetObjectUrl?: Maybe<Scalars['String']['output']>;
  sites?: Maybe<Array<Maybe<Site>>>;
  storage?: Maybe<FileStorageField>;
  storageStatus?: Maybe<Scalars['String']['output']>;
};

export type Tileset3dProcess = {
  __typename?: 'Tileset3dProcess';
  account?: Maybe<Account>;
  accountId?: Maybe<Scalars['ID']['output']>;
  captureId?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdByUserId?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  kind?: Maybe<Scalars['String']['output']>;
  lastStep?: Maybe<Scalars['String']['output']>;
  lastStepIsDone?: Maybe<Scalars['Boolean']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  renderObjectId?: Maybe<Scalars['ID']['output']>;
  sceneEntityId?: Maybe<Scalars['ID']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  steps?: Maybe<Array<Maybe<StepField>>>;
  user?: Maybe<User>;
};

export type Tileset3dRenderObject = {
  __typename?: 'Tileset3dRenderObject';
  accountId?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  file?: Maybe<File>;
  fileId?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  kind?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  process?: Maybe<Process>;
  processId?: Maybe<Scalars['ID']['output']>;
  rootFolderNodeId?: Maybe<Scalars['ID']['output']>;
  usedBySceneEntityIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
};

export type Tileset3dStorageField = {
  __typename?: 'Tileset3dStorageField';
  bucket?: Maybe<Scalars['String']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  size?: Maybe<Scalars['Float']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  usageType?: Maybe<Scalars['String']['output']>;
};

export type Training = {
  __typename?: 'Training';
  duration?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  modelId?: Maybe<Scalars['String']['output']>;
  modelName?: Maybe<Scalars['String']['output']>;
  startTime?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type TriggerPredictionInput = {
  imageUrls?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  modelName?: InputMaybe<Scalars['String']['input']>;
};

export type TriggerPredictionResponseType = {
  __typename?: 'TriggerPredictionResponseType';
  predicted_boxes: Array<Array<Scalars['Float']['output']>>;
  predicted_labels: Array<Maybe<Scalars['Float']['output']>>;
};

export type TriggerPredictionV2Input = {
  deploymentId?: InputMaybe<Scalars['String']['input']>;
  imageUrls?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type TriggerTrainInput = {
  accountId?: InputMaybe<Scalars['ID']['input']>;
  annotationTemplateId?: InputMaybe<Scalars['String']['input']>;
  labelFieldId?: InputMaybe<Scalars['String']['input']>;
  modelName?: InputMaybe<Scalars['String']['input']>;
  testAnnotationProcessId?: InputMaybe<Scalars['String']['input']>;
  trainAnnotationProcessId?: InputMaybe<Scalars['String']['input']>;
};

export type TriggerTrainV2Input = {
  accountId?: InputMaybe<Scalars['ID']['input']>;
  annotationTemplateId?: InputMaybe<Scalars['String']['input']>;
  labelFieldId?: InputMaybe<Scalars['String']['input']>;
  modelId?: InputMaybe<Scalars['String']['input']>;
  testAnnotationProcessIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  trainAnnotationProcessIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  trainConfig?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateAccountInput = {
  accountId?: InputMaybe<Scalars['ID']['input']>;
  bannerId?: InputMaybe<Scalars['String']['input']>;
  bannerLogoStorageId?: InputMaybe<Scalars['String']['input']>;
  brandingColor?: InputMaybe<Scalars['String']['input']>;
  hasSkandWatermark?: InputMaybe<Scalars['Boolean']['input']>;
  logoId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateAnnotationInput = {
  annotation2d?: InputMaybe<Annotation2dInput>;
  annotation3d?: InputMaybe<Annotation3dInput>;
  annotationId?: InputMaybe<Scalars['ID']['input']>;
  color?: InputMaybe<Scalars['String']['input']>;
  fields?: InputMaybe<Array<InputMaybe<AnnotationFieldInput>>>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateAnnotationTemplateInput = {
  annotationTemplateId: Scalars['ID']['input'];
  colorFromField?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  fields?: InputMaybe<Array<InputMaybe<AnnotationTemplateFieldInput>>>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateBuildingInput = {
  buildingId?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  roofConstructionTypeId?: InputMaybe<Scalars['ID']['input']>;
  roofMaterialTypeId?: InputMaybe<Scalars['ID']['input']>;
};

export type UpdateCaptureInput = {
  captureDate?: InputMaybe<Scalars['String']['input']>;
  captureId?: InputMaybe<Scalars['ID']['input']>;
  defaultMaxHeight?: InputMaybe<Scalars['Float']['input']>;
  defaultMinHeight?: InputMaybe<Scalars['Float']['input']>;
  epsgCode?: InputMaybe<Scalars['Float']['input']>;
  generalInformation?: InputMaybe<Scalars['String']['input']>;
  latitude?: InputMaybe<Scalars['Float']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  pitch?: InputMaybe<Scalars['Float']['input']>;
  roll?: InputMaybe<Scalars['Float']['input']>;
  siteId?: InputMaybe<Scalars['ID']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  tileHeight2d?: InputMaybe<Scalars['Float']['input']>;
  tileHeight3d?: InputMaybe<Scalars['Float']['input']>;
  tilesetUrl?: InputMaybe<Scalars['String']['input']>;
  worldPosition?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  worldRotation?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  yaw?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateLegacyReportInput = {
  buildingIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  captureIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  defectStatusIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  defectStatusesSelectAll?: InputMaybe<Scalars['Boolean']['input']>;
  defectTypeIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  defectTypesSelectAll?: InputMaybe<Scalars['Boolean']['input']>;
  elementIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  elementsSelectAll?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  prioritiesSelectAll?: InputMaybe<Scalars['Boolean']['input']>;
  priorityIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  reportId?: InputMaybe<Scalars['ID']['input']>;
  roofMaterialTypeIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  roofMaterialTypesSelectAll?: InputMaybe<Scalars['Boolean']['input']>;
  siteIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type UpdateProcessInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  processId?: InputMaybe<Scalars['ID']['input']>;
  stepName?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateReportInput = {
  appendix?: InputMaybe<AppendixInputField>;
  buildingIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  captureIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  defectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  objectOfInterestIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  renderStatus?: InputMaybe<RenderStatusInputField>;
  reportId?: InputMaybe<Scalars['ID']['input']>;
  siteIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  summary?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateRoleInput = {
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
  roleId: Scalars['ID']['input'];
};

export type UpdateSiteInput = {
  country?: InputMaybe<Scalars['String']['input']>;
  date?: InputMaybe<Scalars['String']['input']>;
  geoid?: InputMaybe<Scalars['String']['input']>;
  latitude?: InputMaybe<Scalars['Float']['input']>;
  locality?: InputMaybe<Scalars['String']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  siteAssetsNeeded?: InputMaybe<Scalars['Boolean']['input']>;
  siteId?: InputMaybe<Scalars['ID']['input']>;
  streetAddress?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateStatusForFolderImportRequestInput = {
  folderImportRequestId?: InputMaybe<Scalars['ID']['input']>;
  status: Scalars['String']['input'];
};

export type UpdateUserGroupInput = {
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
  userGroupId: Scalars['ID']['input'];
};

export type UpdateUserInSiteInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  siteId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
  userInSiteId: Scalars['ID']['input'];
};

export type UpdateUserInput = {
  currentPassword?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  isAdmin?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  measurementSystem?: InputMaybe<Scalars['String']['input']>;
  newPassword?: InputMaybe<Scalars['String']['input']>;
  passwordConfirm?: InputMaybe<Scalars['String']['input']>;
  roleId?: InputMaybe<Scalars['String']['input']>;
};

export type UploadFileMetadata = {
  __typename?: 'UploadFileMetadata';
  bucket?: Maybe<Scalars['String']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
};

export type UploadPart = {
  __typename?: 'UploadPart';
  ETag?: Maybe<Scalars['String']['output']>;
  PartNumber?: Maybe<Scalars['String']['output']>;
  Size?: Maybe<Scalars['String']['output']>;
};

export type User = {
  __typename?: 'User';
  account?: Maybe<Account>;
  accountId?: Maybe<Scalars['ID']['output']>;
  activeStatus?: Maybe<Scalars['Boolean']['output']>;
  discardedAt?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isAdmin?: Maybe<Scalars['Boolean']['output']>;
  lastLoginAt?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  lastSignInAt?: Maybe<Scalars['String']['output']>;
  measurementSystem?: Maybe<Scalars['String']['output']>;
  roleIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  roles?: Maybe<Array<Maybe<UserRole>>>;
  signInCount?: Maybe<Scalars['Int']['output']>;
  slackUsername?: Maybe<Scalars['String']['output']>;
};

export type UserGroup = {
  __typename?: 'UserGroup';
  accountId?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  permissionPolicies?: Maybe<Array<Maybe<PermissionPolicy>>>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  userGroupToRolePolicies?: Maybe<Array<Maybe<GroupingPolicy>>>;
  userToUserGroupPolicies?: Maybe<Array<Maybe<GroupingPolicy>>>;
};

export type UserName = {
  __typename?: 'UserName';
  first?: Maybe<Scalars['String']['output']>;
  last?: Maybe<Scalars['String']['output']>;
};

export type UserRole = {
  __typename?: 'UserRole';
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export enum UserStatus {
  Active = 'ACTIVE',
  Deactivated = 'DEACTIVATED',
  Invited = 'INVITED',
}

export type UserV2 = {
  __typename?: 'UserV2';
  accountId?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  invitationAcceptedAt?: Maybe<Scalars['String']['output']>;
  invitationCreatedAt?: Maybe<Scalars['String']['output']>;
  invitationToken?: Maybe<Scalars['String']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isAdmin?: Maybe<Scalars['Boolean']['output']>;
  isSuperAdmin?: Maybe<Scalars['Boolean']['output']>;
  lastLoginAt?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  name?: Maybe<UserName>;
  status?: Maybe<UserStatus>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type UsersInSite = {
  __typename?: 'UsersInSite';
  comment?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  user?: Maybe<User>;
};

export type ZipRequest = {
  __typename?: 'ZipRequest';
  emailToUser?: Maybe<User>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  signedGetObjectUrl?: Maybe<Scalars['String']['output']>;
  size?: Maybe<Scalars['Float']['output']>;
};

export type ZipRequestSignedGetObjectUrlArgs = {
  expires?: InputMaybe<Scalars['Float']['input']>;
};

export type Charge = {
  priceId?: InputMaybe<Scalars['String']['input']>;
  quantity?: InputMaybe<Scalars['Int']['input']>;
};

export type LoginMutationVariables = Exact<{
  email?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
}>;

export type LoginMutation = {
  __typename?: 'Mutation';
  login?: {
    __typename?: 'LoginToken';
    bearerToken?: string | null;
    email?: string | null;
    accountId?: string | null;
  } | null;
};

export type CreateAnnotationMutationVariables = Exact<{
  annotation: CreateAnnotationInput;
}>;

export type CreateAnnotationMutation = {
  __typename?: 'Mutation';
  createAnnotation?: {
    __typename?: 'Annotation';
    annotationId?: string | null;
    name?: string | null;
  } | null;
};

export type CreateManyAnnotationsMutationVariables = Exact<{
  annotations: Array<InputMaybe<CreateAnnotationInput>> | InputMaybe<CreateAnnotationInput>;
}>;

export type CreateManyAnnotationsMutation = {
  __typename?: 'Mutation';
  createManyAnnotations?: boolean | null;
};

export type CreateAccountAnnotationTemplateMutationVariables = Exact<{
  template: CreateAccountAnnotationTemplateInput;
}>;

export type CreateAccountAnnotationTemplateMutation = {
  __typename?: 'Mutation';
  createAccountAnnotationTemplate?: {
    __typename?: 'AnnotationTemplate';
    id?: string | null;
    name?: string | null;
  } | null;
};

export type CreateProjectAnnotationTemplateMutationVariables = Exact<{
  template: CreateProjectAnnotationTemplateInput;
}>;

export type CreateProjectAnnotationTemplateMutation = {
  __typename?: 'Mutation';
  createProjectAnnotationTemplate?: {
    __typename?: 'AnnotationTemplate';
    id?: string | null;
    name?: string | null;
  } | null;
};

export type UpdateAnnotationTemplateMutationVariables = Exact<{
  template: UpdateAnnotationTemplateInput;
}>;

export type UpdateAnnotationTemplateMutation = {
  __typename?: 'Mutation';
  updateAnnotationTemplate?: {
    __typename?: 'AnnotationTemplate';
    id?: string | null;
    name?: string | null;
  } | null;
};

export type CreateAnnotationGroupMutationVariables = Exact<{
  annotationGroup: CreateAnnotationGroupInput;
}>;

export type CreateAnnotationGroupMutation = {
  __typename?: 'Mutation';
  createAnnotationGroup?: {
    __typename?: 'SceneEntity';
    id?: string | null;
    name?: string | null;
  } | null;
};

export type RenameSceneEntityMutationVariables = Exact<{
  sceneEntityId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
}>;

export type RenameSceneEntityMutation = {
  __typename?: 'Mutation';
  updateSceneEntityName?: {
    __typename?: 'SceneEntity';
    id?: string | null;
    name?: string | null;
  } | null;
};

export type DeleteAnnotationGroupMutationVariables = Exact<{
  annotationGroupId: Scalars['ID']['input'];
}>;

export type DeleteAnnotationGroupMutation = {
  __typename?: 'Mutation';
  deleteAnnotationGroup?: boolean | null;
};

export type CreateProjectGroupMutationVariables = Exact<{
  accountId: Scalars['ID']['input'];
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
  projectIds?: InputMaybe<
    Array<InputMaybe<Scalars['ID']['input']>> | InputMaybe<Scalars['ID']['input']>
  >;
}>;

export type CreateProjectGroupMutation = {
  __typename?: 'Mutation';
  createProjectGroup?: {
    __typename?: 'ProjectGroup';
    id?: string | null;
    name?: string | null;
  } | null;
};

export type UpdateProjectGroupMutationVariables = Exact<{
  projectGroupId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  description: Scalars['String']['input'];
}>;

export type UpdateProjectGroupMutation = {
  __typename?: 'Mutation';
  updateProjectGroupNameAndDescription?: {
    __typename?: 'ProjectGroup';
    id?: string | null;
    name?: string | null;
  } | null;
};

export type MoveAnnotationsToGroupMutationVariables = Exact<{
  annotationIdsWithPositionAndRotation:
    | Array<InputMaybe<AnnotationIdWithPositionAndRotation>>
    | InputMaybe<AnnotationIdWithPositionAndRotation>;
  groupId: Scalars['String']['input'];
}>;

export type MoveAnnotationsToGroupMutation = {
  __typename?: 'Mutation';
  moveAnnotationsToGroup?: Array<{
    __typename?: 'Annotation';
    id?: string | null;
    name?: string | null;
  } | null> | null;
};

export type UpdateProjectMutationVariables = Exact<{
  projectId: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  address?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  geoid?: InputMaybe<Scalars['String']['input']>;
  projectGroupId?: InputMaybe<Scalars['ID']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  latitude?: InputMaybe<Scalars['Float']['input']>;
}>;

export type UpdateProjectMutation = {
  __typename?: 'Mutation';
  updateProject?: { __typename?: 'Project'; id?: string | null } | null;
};

export type DeleteProjectGroupMutationVariables = Exact<{
  projectGroupId: Scalars['ID']['input'];
}>;

export type DeleteProjectGroupMutation = {
  __typename?: 'Mutation';
  deleteProjectGroup?: string | null;
};

export type MoveProjectsToGroupMutationVariables = Exact<{
  groupId: Scalars['ID']['input'];
  projectIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type MoveProjectsToGroupMutation = {
  __typename?: 'Mutation';
  moveProjectsToGroup?: Array<string | null> | null;
};

export type UpdateAnnotationMutationVariables = Exact<{
  annotation: UpdateAnnotationInput;
}>;

export type UpdateAnnotationMutation = {
  __typename?: 'Mutation';
  updateAnnotation?: {
    __typename?: 'Annotation';
    annotationId?: string | null;
    name?: string | null;
    color?: string | null;
  } | null;
};

export type DeleteAnnotationMutationVariables = Exact<{
  annotationId: Scalars['String']['input'];
}>;

export type DeleteAnnotationMutation = {
  __typename?: 'Mutation';
  deleteAnnotation?: {
    __typename?: 'Annotation';
    id?: string | null;
    name?: string | null;
    groupId?: string | null;
  } | null;
};

export type DeleteAnnotationsMutationVariables = Exact<{
  annotationIds:
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>;
}>;

export type DeleteAnnotationsMutation = {
  __typename?: 'Mutation';
  deleteAnnotations?: Array<{
    __typename?: 'Annotation';
    id?: string | null;
    name?: string | null;
    groupId?: string | null;
  } | null> | null;
};

export type SetParentSceneEntityMutationVariables = Exact<{
  sceneEntityId: Scalars['ID']['input'];
  parentSceneEntityId: Scalars['ID']['input'];
  position: SceneEntityPositionInput;
  rotation: SceneEntityRotationInput;
  projectId: Scalars['ID']['input'];
}>;

export type SetParentSceneEntityMutation = {
  __typename?: 'Mutation';
  AddParentToSceneEntity?: { __typename?: 'SceneEntity'; id?: string | null } | null;
};

export type RemoveParentSceneEntityMutationVariables = Exact<{
  sceneEntityId: Scalars['ID']['input'];
  position: SceneEntityPositionInput;
  rotation: SceneEntityRotationInput;
  projectId: Scalars['ID']['input'];
}>;

export type RemoveParentSceneEntityMutation = {
  __typename?: 'Mutation';
  RemoveParentFromSceneEntity?: { __typename?: 'SceneEntity'; id?: string | null } | null;
};

export type CreateShareLinkMutationVariables = Exact<{
  projectId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  permissions?: InputMaybe<
    | Array<InputMaybe<PermissionPolicyWithOutSubjectInput>>
    | InputMaybe<PermissionPolicyWithOutSubjectInput>
  >;
}>;

export type CreateShareLinkMutation = {
  __typename?: 'Mutation';
  createShareLink?: {
    __typename?: 'ShareLink';
    id?: string | null;
    shareToken?: string | null;
  } | null;
};

export type UpdateShareLinkMutationVariables = Exact<{
  shareLinkId: Scalars['ID']['input'];
  isActivated?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<
    Array<InputMaybe<PermissionPolicyInput>> | InputMaybe<PermissionPolicyInput>
  >;
}>;

export type UpdateShareLinkMutation = {
  __typename?: 'Mutation';
  updateShareLink?: { __typename?: 'ShareLink'; id?: string | null } | null;
};

export type DeleteShareLinkMutationVariables = Exact<{
  shareLinkId: Scalars['ID']['input'];
}>;

export type DeleteShareLinkMutation = { __typename?: 'Mutation'; deleteShareLink?: string | null };

export type CreateProjectMutationVariables = Exact<{
  name: Scalars['String']['input'];
  address: Scalars['String']['input'];
  description: Scalars['String']['input'];
  geoid: Scalars['String']['input'];
  projectGroupId?: InputMaybe<Scalars['ID']['input']>;
  longitude: Scalars['Float']['input'];
  latitude: Scalars['Float']['input'];
}>;

export type CreateProjectMutation = {
  __typename?: 'Mutation';
  createProject?: { __typename?: 'Project'; id?: string | null } | null;
};

export type CreateProjectFileNodesWithNodeTreeMutationVariables = Exact<{
  nodeIds?: InputMaybe<
    Array<InputMaybe<Scalars['ID']['input']>> | InputMaybe<Scalars['ID']['input']>
  >;
  siteId: Scalars['ID']['input'];
  parentNodeId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type CreateProjectFileNodesWithNodeTreeMutation = {
  __typename?: 'Mutation';
  createProjectFileNodesWithNodeTree?: {
    __typename?: 'ProjectFileNode';
    id?: string | null;
  } | null;
};

export type DeleteProjectMutationVariables = Exact<{
  projectId: Scalars['ID']['input'];
}>;

export type DeleteProjectMutation = { __typename?: 'Mutation'; deleteProject?: string | null };

export type TransformSceneEntityMutationVariables = Exact<{
  sceneEntityId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
  position: SceneEntityPositionInput;
  rotation: SceneEntityRotationInput;
}>;

export type TransformSceneEntityMutation = {
  __typename?: 'Mutation';
  updateSceneEntityPositionAndRotation?: { __typename?: 'SceneEntity'; id?: string | null } | null;
};

export type CreateSceneEntityGroupMutationVariables = Exact<{
  accountId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  parentSceneEntityId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type CreateSceneEntityGroupMutation = {
  __typename?: 'Mutation';
  CreateSceneEntityGroup?: { __typename?: 'SceneEntity'; id?: string | null } | null;
};

export type DeleteSceneEntityGroupMutationVariables = Exact<{
  sceneEntityId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
}>;

export type DeleteSceneEntityGroupMutation = {
  __typename?: 'Mutation';
  DeleteSceneEntityGroup?: string | null;
};

export type CreateProjectFileNodesFromFilesMutationVariables = Exact<{
  projectId: Scalars['ID']['input'];
  parentNodeId?: InputMaybe<Scalars['ID']['input']>;
  nodeIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type CreateProjectFileNodesFromFilesMutation = {
  __typename?: 'Mutation';
  createProjectFileNodesWithNodeTree?: {
    __typename?: 'ProjectFileNode';
    id?: string | null;
    kind?: string | null;
    name?: string | null;
  } | null;
};

export type CreateProjectFolderNodeMutationVariables = Exact<{
  projectId: Scalars['ID']['input'];
  parentNodeId?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
}>;

export type CreateProjectFolderNodeMutation = {
  __typename?: 'Mutation';
  createProjectFolderNode?: {
    __typename?: 'ProjectFolderNode';
    id?: string | null;
    kind?: string | null;
    name?: string | null;
  } | null;
};

export type CreateSystemFolderNodeMutationVariables = Exact<{
  input: CreateSystemFolderNodeInput;
}>;

export type CreateSystemFolderNodeMutation = {
  __typename?: 'Mutation';
  createSystemFolderNode?: {
    __typename?: 'FolderNode';
    id?: string | null;
    kind?: string | null;
    parentNodeId?: string | null;
    name?: string | null;
    createdAt?: string | null;
    lastDownloadedAt?: string | null;
    createdUser?: {
      __typename?: 'UserV2';
      id?: string | null;
      displayName?: string | null;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
    lastDownloadedUser?: {
      __typename?: 'UserV2';
      id?: string | null;
      displayName?: string | null;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
  } | null;
};

export type UpdateProcessStepMutationVariables = Exact<{
  processId: Scalars['ID']['input'];
  stepName: Scalars['String']['input'];
}>;

export type UpdateProcessStepMutation = {
  __typename?: 'Mutation';
  addProcessStep?:
    | { __typename?: 'AnnotationProcess'; id?: string | null }
    | { __typename?: 'CesiumIonProcess' }
    | { __typename?: 'DefectProcess' }
    | { __typename?: 'DxfProcess' }
    | { __typename?: 'IfcProcess' }
    | { __typename?: 'ImageProjectionProcess' }
    | { __typename?: 'KmlProcess' }
    | { __typename?: 'ObjProcess' }
    | { __typename?: 'Ortho2dProcess' }
    | { __typename?: 'PanoramicProcess' }
    | { __typename?: 'PanoramicVideoProcess' }
    | { __typename?: 'Tileset3dProcess' }
    | null;
};

export type UpdateProcessImagesStatusMutationVariables = Exact<{
  annotationProcessImageStatusIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  status: Scalars['String']['input'];
}>;

export type UpdateProcessImagesStatusMutation = {
  __typename?: 'Mutation';
  submitAnnotationProcessImagesStatus?: boolean | null;
};

export type UpdatePanoramicImageTransformMutationVariables = Exact<{
  renderObjectId: Scalars['ID']['input'];
  fileId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  position: Array<InputMaybe<Scalars['Float']['input']>> | InputMaybe<Scalars['Float']['input']>;
  rotation: Array<InputMaybe<Scalars['Float']['input']>> | InputMaybe<Scalars['Float']['input']>;
}>;

export type UpdatePanoramicImageTransformMutation = {
  __typename?: 'Mutation';
  updatePanoramicRenderObjectImagePosition?:
    | { __typename?: 'AnnotationRenderObject' }
    | { __typename?: 'CesiumIonRenderObject' }
    | { __typename?: 'DxfRenderObject' }
    | { __typename?: 'IfcRenderObject' }
    | { __typename?: 'ImageProjectionRenderObject' }
    | { __typename?: 'KmlRenderObject' }
    | { __typename?: 'ObjRenderObject' }
    | { __typename?: 'Ortho2dRenderObject' }
    | { __typename?: 'PanoramicRenderObject'; id?: string | null }
    | { __typename?: 'PanoramicVideoRenderObject' }
    | { __typename?: 'Tileset3dRenderObject' }
    | null;
};

export type UpdatePanoramicImagesStatusMutationVariables = Exact<{
  panoramicProcessImageStatusIds:
    | Array<InputMaybe<Scalars['ID']['input']>>
    | InputMaybe<Scalars['ID']['input']>;
  status: Scalars['String']['input'];
}>;

export type UpdatePanoramicImagesStatusMutation = {
  __typename?: 'Mutation';
  submitPanoramicProcessImagesStatus?: boolean | null;
};

export type DebugCreateImageTilesByFileIdsMutationVariables = Exact<{
  fileIds: Array<InputMaybe<Scalars['ID']['input']>> | InputMaybe<Scalars['ID']['input']>;
}>;

export type DebugCreateImageTilesByFileIdsMutation = {
  __typename?: 'Mutation';
  createImageTilesForPersistedImagesByIds?: Array<string | null> | null;
};

export type AbortMultiPartUploadMutationVariables = Exact<{
  bucket: Scalars['String']['input'];
  key: Scalars['String']['input'];
  uploadId: Scalars['String']['input'];
}>;

export type AbortMultiPartUploadMutation = {
  __typename?: 'Mutation';
  abortMultipartUpload?: boolean | null;
};

export type CreateMultipartUploadForFileImportRequestMutationVariables = Exact<{
  dataImportRequestId: Scalars['ID']['input'];
  fileImportRequestId: Scalars['ID']['input'];
}>;

export type CreateMultipartUploadForFileImportRequestMutation = {
  __typename?: 'Mutation';
  createMultipartUploadForFileImportRequest?: {
    __typename?: 'CreateMultipartUploadObjectType';
    bucket?: string | null;
    key?: string | null;
    uploadId?: string | null;
  } | null;
};

export type CreatePresignedUploadPartUrlMutationVariables = Exact<{
  bucket: Scalars['String']['input'];
  key: Scalars['String']['input'];
  partNumber: Scalars['Int']['input'];
  uploadId: Scalars['String']['input'];
}>;

export type CreatePresignedUploadPartUrlMutation = {
  __typename?: 'Mutation';
  createPresignedUploadPartUrl?: string | null;
};

export type CompleteMultipartUploadMutationVariables = Exact<{
  bucket: Scalars['String']['input'];
  key: Scalars['String']['input'];
  parts: Array<MultiPartInput> | MultiPartInput;
  uploadId: Scalars['String']['input'];
}>;

export type CompleteMultipartUploadMutation = {
  __typename?: 'Mutation';
  completeMultipartUpload?: string | null;
};

export type CreateDataImportRequestMutationVariables = Exact<{
  featureType: Scalars['String']['input'];
  name: Scalars['String']['input'];
  persistType: Scalars['String']['input'];
  priority: Scalars['String']['input'];
  rootFolderIdempotencyKeyAndName: FolderIdempotencyKeyAndNameInput;
  systemFolderNodeId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type CreateDataImportRequestMutation = {
  __typename?: 'Mutation';
  createDataImportRequest?: string | null;
};

export type CreateDataImportRequestV2MutationVariables = Exact<{
  dependants?: InputMaybe<Array<SystemNodeDependantInput> | SystemNodeDependantInput>;
  featureType: Scalars['String']['input'];
  name: Scalars['String']['input'];
  persistType: Scalars['String']['input'];
  priority: Scalars['String']['input'];
  rootFolderIdempotencyKeyAndName: FolderIdempotencyKeyAndNameInput;
  systemFolderNodeId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type CreateDataImportRequestV2Mutation = {
  __typename?: 'Mutation';
  createDataImportRequestV2?: string | null;
};

export type UpdateStatusForDataImportRequestMutationVariables = Exact<{
  status: Scalars['String']['input'];
  dataImportRequestId: Scalars['ID']['input'];
}>;

export type UpdateStatusForDataImportRequestMutation = {
  __typename?: 'Mutation';
  updateStatusForDataImportRequest?: boolean | null;
};

export type CreateFolderImportRequestForDataImportRequestMutationVariables = Exact<{
  dataImportRequestId: Scalars['ID']['input'];
  parentFolderNodeIdempotencyKey: Scalars['String']['input'];
  childFolderNodeIdempotencyKeyAndNames:
    | Array<FolderIdempotencyKeyAndNameInput>
    | FolderIdempotencyKeyAndNameInput;
}>;

export type CreateFolderImportRequestForDataImportRequestMutation = {
  __typename?: 'Mutation';
  createFolderImportRequestForDataImportRequest?: Array<string | null> | null;
};

export type UpdateStatusForFolderImportRequestMutationVariables = Exact<{
  input: UpdateStatusForFolderImportRequestInput;
}>;

export type UpdateStatusForFolderImportRequestMutation = {
  __typename?: 'Mutation';
  updateStatusForFolderImportRequest?: string | null;
};

export type CreateFileNodeForFolderImportRequestMutationVariables = Exact<{
  dataImportRequestId: Scalars['ID']['input'];
  fileIdempotencyKeyStorageAndMetadata:
    | Array<FileIdempotencyKeyStorageAndMetadataInput>
    | FileIdempotencyKeyStorageAndMetadataInput;
  folderImportRequestId: Scalars['ID']['input'];
}>;

export type CreateFileNodeForFolderImportRequestMutation = {
  __typename?: 'Mutation';
  createFileNodeForFolderImportRequest?: Array<string | null> | null;
};

export type CreateFolderNodeForFolderImportRequestMutationVariables = Exact<{
  childFolderImportRequestIds:
    | Array<InputMaybe<Scalars['ID']['input']>>
    | InputMaybe<Scalars['ID']['input']>;
  dataImportRequestId: Scalars['ID']['input'];
  parentFolderImportRequestId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type CreateFolderNodeForFolderImportRequestMutation = {
  __typename?: 'Mutation';
  createFolderNodeForFolderImportRequest?: Array<string | null> | null;
};

export type TrackFilesForFolderImportRequestMutationVariables = Exact<{
  dataImportRequestId: Scalars['ID']['input'];
  folderImportRequestId: Scalars['ID']['input'];
  files: Array<FileItemInput> | FileItemInput;
}>;

export type TrackFilesForFolderImportRequestMutation = {
  __typename?: 'Mutation';
  trackFilesForFolderImportRequest?: Array<string | null> | null;
};

export type CreateMultipartUploadMutationVariables = Exact<{
  fileName: Scalars['String']['input'];
}>;

export type CreateMultipartUploadMutation = {
  __typename?: 'Mutation';
  createMultipartUpload?: {
    __typename?: 'CreateMultipartUploadObjectType';
    bucket?: string | null;
    fileId?: string | null;
    key?: string | null;
    uploadId?: string | null;
  } | null;
};

export type CreateFileMutationVariables = Exact<{
  accountId: Scalars['ID']['input'];
  bucket: Scalars['String']['input'];
  fileId: Scalars['ID']['input'];
  fileKind: FileKindInput;
  key: Scalars['String']['input'];
  name: Scalars['String']['input'];
  size: Scalars['Float']['input'];
  type: Scalars['String']['input'];
}>;

export type CreateFileMutation = {
  __typename?: 'Mutation';
  createFile?:
    | { __typename: 'AssetFile'; id?: string | null }
    | { __typename: 'ImageFile'; id?: string | null }
    | { __typename: 'ObjFile'; id?: string | null }
    | { __typename: 'Ortho2dFile'; id?: string | null }
    | { __typename: 'Tileset3dFile'; id?: string | null }
    | null;
};

export type CreateImageThumbnailsForPersistedImagesByIdsMutationVariables = Exact<{
  fileIds: Array<InputMaybe<Scalars['ID']['input']>> | InputMaybe<Scalars['ID']['input']>;
}>;

export type CreateImageThumbnailsForPersistedImagesByIdsMutation = {
  __typename?: 'Mutation';
  createImageThumbnailsForPersistedImagesByIds?: number | null;
};

export type CreateModelMutationVariables = Exact<{
  modelName: Scalars['String']['input'];
}>;

export type CreateModelMutation = { __typename?: 'Mutation'; createModel?: string | null };

export type TriggerTrainV2MutationVariables = Exact<{
  request: TriggerTrainV2Input;
}>;

export type TriggerTrainV2Mutation = { __typename?: 'Mutation'; triggerTrainV2?: string | null };

export type TriggerPredictionV2MutationVariables = Exact<{
  request: TriggerPredictionV2Input;
}>;

export type TriggerPredictionV2Mutation = {
  __typename?: 'Mutation';
  triggerPredictionV2?: Array<{
    __typename?: 'TriggerPredictionResponseType';
    predicted_boxes: Array<Array<number>>;
    predicted_labels: Array<number | null>;
  } | null> | null;
};

export type TileImagesMutationVariables = Exact<{
  fileIds: Array<InputMaybe<Scalars['ID']['input']>> | InputMaybe<Scalars['ID']['input']>;
}>;

export type TileImagesMutation = {
  __typename?: 'Mutation';
  createImageTilesForPersistedImagesByIds?: Array<string | null> | null;
  createImageThumbnailsForPersistedImagesByIds?: number | null;
};

export type UpdateImageProjectionRenderObjectImagesMutationVariables = Exact<{
  processId: Scalars['ID']['input'];
  renderObjectId: Scalars['ID']['input'];
  images: Array<ImageProjectionRenderObjectImage> | ImageProjectionRenderObjectImage;
}>;

export type UpdateImageProjectionRenderObjectImagesMutation = {
  __typename?: 'Mutation';
  updateImageProjectionRenderObjectImages?:
    | { __typename?: 'AnnotationRenderObject' }
    | { __typename?: 'CesiumIonRenderObject' }
    | { __typename?: 'DxfRenderObject' }
    | { __typename?: 'IfcRenderObject' }
    | { __typename?: 'ImageProjectionRenderObject'; id?: string | null }
    | { __typename?: 'KmlRenderObject' }
    | { __typename?: 'ObjRenderObject' }
    | { __typename?: 'Ortho2dRenderObject' }
    | { __typename?: 'PanoramicRenderObject' }
    | { __typename?: 'PanoramicVideoRenderObject' }
    | { __typename?: 'Tileset3dRenderObject' }
    | null;
};

export type UpdateImageProjectionRenderObjectCameraModelsMutationVariables = Exact<{
  renderObjectId: Scalars['ID']['input'];
  cameraModels: Array<CameraModel> | CameraModel;
}>;

export type UpdateImageProjectionRenderObjectCameraModelsMutation = {
  __typename?: 'Mutation';
  updateImageProjectionRenderObjectCameraModels?:
    | { __typename?: 'AnnotationRenderObject' }
    | { __typename?: 'CesiumIonRenderObject' }
    | { __typename?: 'DxfRenderObject' }
    | { __typename?: 'IfcRenderObject' }
    | { __typename?: 'ImageProjectionRenderObject'; id?: string | null }
    | { __typename?: 'KmlRenderObject' }
    | { __typename?: 'ObjRenderObject' }
    | { __typename?: 'Ortho2dRenderObject' }
    | { __typename?: 'PanoramicRenderObject' }
    | { __typename?: 'PanoramicVideoRenderObject' }
    | { __typename?: 'Tileset3dRenderObject' }
    | null;
};

export type CreateLinkNodesBySourceNodeIdsMutationVariables = Exact<{
  sourceNodeIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  parentNodeId?: InputMaybe<Scalars['ID']['input']>;
  dependants?: InputMaybe<
    Array<InputMaybe<SystemNodeDependantInput>> | InputMaybe<SystemNodeDependantInput>
  >;
}>;

export type CreateLinkNodesBySourceNodeIdsMutation = {
  __typename?: 'Mutation';
  createLinkNodesBySourceNodeIds?: Array<string | null> | null;
};

export type UpdateSystemNodeNameMutationVariables = Exact<{
  systemNodeId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
}>;

export type UpdateSystemNodeNameMutation = {
  __typename?: 'Mutation';
  updateSystemNodeName?: boolean | null;
};

export type DownloadSystemNodesMutationVariables = Exact<{
  nodeIds: Array<InputMaybe<Scalars['ID']['input']>> | InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
}>;

export type DownloadSystemNodesMutation = {
  __typename?: 'Mutation';
  downloadSystemNodes?: boolean | null;
};

export type UpsertPermissionPoliciesMutationVariables = Exact<{
  permissionPolicies?: InputMaybe<
    Array<InputMaybe<PermissionPolicyInput>> | InputMaybe<PermissionPolicyInput>
  >;
}>;

export type UpsertPermissionPoliciesMutation = {
  __typename?: 'Mutation';
  upsertPermissionPolicies?: boolean | null;
};

export type DeletePermissionPoliciesMutationVariables = Exact<{
  permissionPolicies?: InputMaybe<
    Array<InputMaybe<PermissionPolicyInput>> | InputMaybe<PermissionPolicyInput>
  >;
}>;

export type DeletePermissionPoliciesMutation = {
  __typename?: 'Mutation';
  deletePermissionPolicies?: boolean | null;
};

export type AccountByContextQueryVariables = Exact<{ [key: string]: never }>;

export type AccountByContextQuery = {
  __typename?: 'Query';
  accountByContext?: { __typename?: 'Account'; id?: string | null } | null;
};

export type UserByContextQueryVariables = Exact<{ [key: string]: never }>;

export type UserByContextQuery = {
  __typename?: 'Query';
  readUserByContext?: {
    __typename?: 'UserV2';
    id?: string | null;
    accountId?: string | null;
    email?: string | null;
    isAdmin?: boolean | null;
    isActive?: boolean | null;
    isSuperAdmin?: boolean | null;
    name?: { __typename?: 'UserName'; first?: string | null; last?: string | null } | null;
  } | null;
};

export type ListSubjectToObjectsPermissionPoliciesWithExpandedSubjectIdsQueryVariables = Exact<{
  subjectId: Scalars['String']['input'];
  objectIds?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>
  >;
  objectTypes?: InputMaybe<
    Array<InputMaybe<PolicyObjectTypeInput>> | InputMaybe<PolicyObjectTypeInput>
  >;
  actionTypes?: InputMaybe<
    Array<InputMaybe<PolicyActionTypeInput>> | InputMaybe<PolicyActionTypeInput>
  >;
}>;

export type ListSubjectToObjectsPermissionPoliciesWithExpandedSubjectIdsQuery = {
  __typename?: 'Query';
  listSubjectToObjectsPermissionPoliciesWithExpandedSubjectIds?: Array<{
    __typename?: 'PermissionPolicy';
    accountId?: string | null;
    subjectId?: string | null;
    subjectType?: string | null;
    objectId?: string | null;
    objectType?: string | null;
    actionType?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null> | null;
};

export type ListProjectsByAccountContextQueryVariables = Exact<{ [key: string]: never }>;

export type ListProjectsByAccountContextQuery = {
  __typename?: 'Query';
  listProjectsByAccountContext?: Array<{
    __typename?: 'Project';
    accountId?: string | null;
    address?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    description?: string | null;
    geoid?: string | null;
    id?: string | null;
    name?: string | null;
    projectGroupId?: string | null;
    longitude?: number | null;
    latitude?: number | null;
    rootFolderNodeId?: string | null;
    annotationsRootFolderNodeId?: string | null;
    renderObjectsRootFolderNodeId?: string | null;
    userFilesRootFolderNodeId?: string | null;
  } | null> | null;
};

export type FindProjectByProjectIdQueryVariables = Exact<{
  projectId: Scalars['ID']['input'];
}>;

export type FindProjectByProjectIdQuery = {
  __typename?: 'Query';
  readProjectById?: {
    __typename?: 'Project';
    accountId?: string | null;
    address?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    description?: string | null;
    geoid?: string | null;
    id?: string | null;
    name?: string | null;
    projectGroupId?: string | null;
    longitude?: number | null;
    latitude?: number | null;
    rootFolderNodeId?: string | null;
    annotationsRootFolderNodeId?: string | null;
    renderObjectsRootFolderNodeId?: string | null;
    userFilesRootFolderNodeId?: string | null;
  } | null;
};

export type ListProjectGroupsByAccountContextQueryVariables = Exact<{ [key: string]: never }>;

export type ListProjectGroupsByAccountContextQuery = {
  __typename?: 'Query';
  listProjectGroupsByAccountContext?: Array<{
    __typename?: 'ProjectGroup';
    accountId?: string | null;
    createdAt?: string | null;
    description?: string | null;
    id?: string | null;
    name?: string | null;
    updatedAt?: string | null;
  } | null> | null;
};

export type ListLayerByProjectIdQueryVariables = Exact<{
  projectId: Scalars['ID']['input'];
}>;

export type ListLayerByProjectIdQuery = {
  __typename?: 'Query';
  listLayersByProjectId?: Array<{
    __typename?: 'Layer';
    id?: string | null;
    name?: string | null;
    captureDate?: string | null;
    worldPosition?: Array<number | null> | null;
    worldRotation?: Array<number | null> | null;
    mainSceneEntityId?: string | null;
    status?: string | null;
    formatType?: string | null;
    defaultMaxHeight?: number | null;
    defaultMinHeight?: number | null;
  } | null> | null;
};

export type ListAnnotationsByAnnotationGroupIdQueryVariables = Exact<{
  annotationGroupId: Scalars['ID']['input'];
}>;

export type ListAnnotationsByAnnotationGroupIdQuery = {
  __typename?: 'Query';
  listAnnotationsByAnnotationGroupId?: Array<{
    __typename?: 'Annotation';
    color?: string | null;
    groupId?: string | null;
    id?: string | null;
    name?: string | null;
    templateId?: string | null;
    annotationId?: string | null;
    createdByUserId?: string | null;
    createdAt?: string | null;
    annotation2d?: {
      __typename?: 'Annotation2d';
      imageFileId?: string | null;
      shapeType?: string | null;
      points?: Array<{
        __typename?: 'Annotation2dPoint';
        x?: number | null;
        y?: number | null;
      } | null> | null;
    } | null;
    annotation3d?: {
      __typename?: 'Annotation3d';
      shapeType?: string | null;
      positions?: Array<{
        __typename?: 'Annotation3dPosition';
        x?: number | null;
        y?: number | null;
        z?: number | null;
      } | null> | null;
      rotations?: Array<{
        __typename?: 'Annotation3dRotation';
        w?: number | null;
        x?: number | null;
        y?: number | null;
        z?: number | null;
      } | null> | null;
    } | null;
    fields?: Array<
      | {
          __typename: 'AnnotationDateField';
          fieldId?: string | null;
          end?: string | null;
          start?: string | null;
          type?: string | null;
        }
      | {
          __typename: 'AnnotationFileField';
          fieldId?: string | null;
          type?: string | null;
          files?: Array<{
            __typename?: 'AnnotationFile';
            fileId?: string | null;
            name?: string | null;
          } | null> | null;
        }
      | {
          __typename: 'AnnotationImageField';
          fieldId?: string | null;
          fileId?: string | null;
          type?: string | null;
          file?:
            | { __typename?: 'AssetFile' }
            | {
                __typename: 'ImageFile';
                fileName?: string | null;
                id?: string | null;
                kind?: string | null;
                originalUrl?: string | null;
                thumbnailUrl?: string | null;
              }
            | { __typename?: 'ObjFile' }
            | { __typename?: 'Ortho2dFile' }
            | { __typename?: 'Tileset3dFile' }
            | null;
        }
      | {
          __typename: 'AnnotationSelectField';
          fieldId?: string | null;
          optionId?: string | null;
          type?: string | null;
        }
      | {
          __typename: 'AnnotationTextField';
          fieldId?: string | null;
          text?: string | null;
          type?: string | null;
        }
      | {
          __typename: 'AnnotationUrlField';
          fieldId?: string | null;
          type?: string | null;
          urls?: Array<{
            __typename?: 'AnnotationUrl';
            url?: string | null;
            name?: string | null;
          } | null> | null;
        }
      | null
    > | null;
  } | null> | null;
};

export type FindActiveVersionByAnnotationIdQueryVariables = Exact<{
  annotationId: Scalars['ID']['input'];
}>;

export type FindActiveVersionByAnnotationIdQuery = {
  __typename?: 'Query';
  findActiveVersionByAnnotationId?: {
    __typename?: 'Annotation';
    color?: string | null;
    groupId?: string | null;
    id?: string | null;
    name?: string | null;
    templateId?: string | null;
    annotationId?: string | null;
    annotation2d?: {
      __typename?: 'Annotation2d';
      imageFileId?: string | null;
      shapeType?: string | null;
      points?: Array<{
        __typename?: 'Annotation2dPoint';
        x?: number | null;
        y?: number | null;
      } | null> | null;
    } | null;
    annotation3d?: {
      __typename?: 'Annotation3d';
      shapeType?: string | null;
      positions?: Array<{
        __typename?: 'Annotation3dPosition';
        x?: number | null;
        y?: number | null;
        z?: number | null;
      } | null> | null;
      rotations?: Array<{
        __typename?: 'Annotation3dRotation';
        w?: number | null;
        x?: number | null;
        y?: number | null;
        z?: number | null;
      } | null> | null;
    } | null;
    fields?: Array<
      | {
          __typename: 'AnnotationDateField';
          fieldId?: string | null;
          end?: string | null;
          start?: string | null;
          type?: string | null;
        }
      | {
          __typename: 'AnnotationFileField';
          fieldId?: string | null;
          type?: string | null;
          files?: Array<{
            __typename?: 'AnnotationFile';
            fileId?: string | null;
            name?: string | null;
          } | null> | null;
        }
      | {
          __typename: 'AnnotationImageField';
          fieldId?: string | null;
          fileId?: string | null;
          type?: string | null;
          file?:
            | { __typename?: 'AssetFile' }
            | {
                __typename: 'ImageFile';
                fileName?: string | null;
                id?: string | null;
                kind?: string | null;
                originalUrl?: string | null;
                thumbnailUrl?: string | null;
              }
            | { __typename?: 'ObjFile' }
            | { __typename?: 'Ortho2dFile' }
            | { __typename?: 'Tileset3dFile' }
            | null;
        }
      | {
          __typename: 'AnnotationSelectField';
          fieldId?: string | null;
          optionId?: string | null;
          type?: string | null;
        }
      | {
          __typename: 'AnnotationTextField';
          fieldId?: string | null;
          text?: string | null;
          type?: string | null;
        }
      | {
          __typename: 'AnnotationUrlField';
          fieldId?: string | null;
          type?: string | null;
          urls?: Array<{
            __typename?: 'AnnotationUrl';
            url?: string | null;
            name?: string | null;
          } | null> | null;
        }
      | null
    > | null;
  } | null;
};

export type ListHistoryByAnnotationIdQueryVariables = Exact<{
  annotationId: Scalars['ID']['input'];
}>;

export type ListHistoryByAnnotationIdQuery = {
  __typename?: 'Query';
  listHistoryByAnnotationId?: Array<{
    __typename?: 'Annotation';
    id?: string | null;
    isActiveVersion?: boolean | null;
    name?: string | null;
    createdAt?: string | null;
    createdByUserId?: string | null;
    annotationId?: string | null;
    color?: string | null;
    annotation2d?: {
      __typename?: 'Annotation2d';
      imageFileId?: string | null;
      shapeType?: string | null;
      points?: Array<{
        __typename?: 'Annotation2dPoint';
        x?: number | null;
        y?: number | null;
      } | null> | null;
    } | null;
    annotation3d?: {
      __typename?: 'Annotation3d';
      shapeType?: string | null;
      positions?: Array<{
        __typename?: 'Annotation3dPosition';
        x?: number | null;
        y?: number | null;
        z?: number | null;
      } | null> | null;
      rotations?: Array<{
        __typename?: 'Annotation3dRotation';
        w?: number | null;
        x?: number | null;
        y?: number | null;
        z?: number | null;
      } | null> | null;
    } | null;
    fields?: Array<
      | {
          __typename: 'AnnotationDateField';
          fieldId?: string | null;
          end?: string | null;
          name?: string | null;
          start?: string | null;
          type?: string | null;
        }
      | {
          __typename: 'AnnotationFileField';
          fieldId?: string | null;
          name?: string | null;
          type?: string | null;
          files?: Array<{
            __typename?: 'AnnotationFile';
            fileId?: string | null;
            name?: string | null;
          } | null> | null;
        }
      | {
          __typename: 'AnnotationImageField';
          fieldId?: string | null;
          fileId?: string | null;
          name?: string | null;
          type?: string | null;
          file?:
            | { __typename?: 'AssetFile' }
            | {
                __typename: 'ImageFile';
                fileName?: string | null;
                id?: string | null;
                kind?: string | null;
                originalUrl?: string | null;
                thumbnailUrl?: string | null;
              }
            | { __typename?: 'ObjFile' }
            | { __typename?: 'Ortho2dFile' }
            | { __typename?: 'Tileset3dFile' }
            | null;
        }
      | {
          __typename: 'AnnotationSelectField';
          fieldId?: string | null;
          name?: string | null;
          type?: string | null;
          optionId?: string | null;
          value?: string | null;
        }
      | {
          __typename: 'AnnotationTextField';
          fieldId?: string | null;
          name?: string | null;
          text?: string | null;
          type?: string | null;
        }
      | {
          __typename: 'AnnotationUrlField';
          fieldId?: string | null;
          name?: string | null;
          type?: string | null;
          urls?: Array<{
            __typename?: 'AnnotationUrl';
            url?: string | null;
            name?: string | null;
          } | null> | null;
        }
      | null
    > | null;
  } | null> | null;
};

export type CountActiveAnnotationsByTemplateIdQueryVariables = Exact<{
  templateId: Scalars['ID']['input'];
  filterDeleted?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type CountActiveAnnotationsByTemplateIdQuery = {
  __typename?: 'Query';
  countActiveAnnotationsByTemplateId?: number | null;
};

export type ListAnnotationTemplatesByProjectIdQueryVariables = Exact<{
  projectId: Scalars['ID']['input'];
}>;

export type ListAnnotationTemplatesByProjectIdQuery = {
  __typename?: 'Query';
  listAnnotationTemplatesByProjectId?: Array<{
    __typename?: 'AnnotationTemplate';
    id?: string | null;
    accountId?: string | null;
    projectId?: string | null;
    level?: string | null;
    colorFromField?: string | null;
    name?: string | null;
    description?: string | null;
    createdBy?: string | null;
    createdByUserId?: string | null;
    createdAt?: string | null;
    updatedByUserId?: string | null;
    updatedAt?: string | null;
    fields?: Array<
      | {
          __typename?: 'AnnotationTemplateDateField';
          id?: string | null;
          name?: string | null;
          order?: number | null;
          type?: string | null;
          isDeleted?: boolean | null;
          isVisible?: boolean | null;
        }
      | {
          __typename?: 'AnnotationTemplateFileField';
          id?: string | null;
          name?: string | null;
          order?: number | null;
          type?: string | null;
          isDeleted?: boolean | null;
          isVisible?: boolean | null;
        }
      | {
          __typename?: 'AnnotationTemplateImageField';
          id?: string | null;
          name?: string | null;
          order?: number | null;
          type?: string | null;
          isDeleted?: boolean | null;
          isVisible?: boolean | null;
        }
      | {
          __typename?: 'AnnotationTemplateSelectField';
          id?: string | null;
          name?: string | null;
          order?: number | null;
          type?: string | null;
          isDeleted?: boolean | null;
          isVisible?: boolean | null;
          options?: Array<{
            __typename?: 'AnnotationTemplateSelectOption';
            color?: string | null;
            id?: string | null;
            value?: string | null;
          } | null> | null;
        }
      | {
          __typename?: 'AnnotationTemplateTextField';
          id?: string | null;
          name?: string | null;
          order?: number | null;
          type?: string | null;
          isDeleted?: boolean | null;
          isVisible?: boolean | null;
        }
      | {
          __typename?: 'AnnotationTemplateUrlField';
          id?: string | null;
          name?: string | null;
          order?: number | null;
          type?: string | null;
          isDeleted?: boolean | null;
          isVisible?: boolean | null;
        }
      | null
    > | null;
  } | null> | null;
};

export type ListAnnotationTemplatesByAccountContextQueryVariables = Exact<{ [key: string]: never }>;

export type ListAnnotationTemplatesByAccountContextQuery = {
  __typename?: 'Query';
  listAnnotationTemplatesByAccountContext?: Array<{
    __typename?: 'AnnotationTemplate';
    id?: string | null;
    accountId?: string | null;
    projectId?: string | null;
    level?: string | null;
    colorFromField?: string | null;
    name?: string | null;
    description?: string | null;
    createdBy?: string | null;
    createdByUserId?: string | null;
    createdAt?: string | null;
    updatedByUserId?: string | null;
    updatedAt?: string | null;
    fields?: Array<
      | {
          __typename?: 'AnnotationTemplateDateField';
          id?: string | null;
          name?: string | null;
          order?: number | null;
          type?: string | null;
          isDeleted?: boolean | null;
          isVisible?: boolean | null;
        }
      | {
          __typename?: 'AnnotationTemplateFileField';
          id?: string | null;
          name?: string | null;
          order?: number | null;
          type?: string | null;
          isDeleted?: boolean | null;
          isVisible?: boolean | null;
        }
      | {
          __typename?: 'AnnotationTemplateImageField';
          id?: string | null;
          name?: string | null;
          order?: number | null;
          type?: string | null;
          isDeleted?: boolean | null;
          isVisible?: boolean | null;
        }
      | {
          __typename?: 'AnnotationTemplateSelectField';
          id?: string | null;
          name?: string | null;
          order?: number | null;
          type?: string | null;
          isDeleted?: boolean | null;
          isVisible?: boolean | null;
          options?: Array<{
            __typename?: 'AnnotationTemplateSelectOption';
            color?: string | null;
            id?: string | null;
            value?: string | null;
          } | null> | null;
        }
      | {
          __typename?: 'AnnotationTemplateTextField';
          id?: string | null;
          name?: string | null;
          order?: number | null;
          type?: string | null;
          isDeleted?: boolean | null;
          isVisible?: boolean | null;
        }
      | {
          __typename?: 'AnnotationTemplateUrlField';
          id?: string | null;
          name?: string | null;
          order?: number | null;
          type?: string | null;
          isDeleted?: boolean | null;
          isVisible?: boolean | null;
        }
      | null
    > | null;
  } | null> | null;
};

export type ListSceneEntitiesByProjectIdAndRendererTypesQueryVariables = Exact<{
  projectId: Scalars['ID']['input'];
  rendererTypes:
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>;
}>;

export type ListSceneEntitiesByProjectIdAndRendererTypesQuery = {
  __typename?: 'Query';
  listSceneEntitiesByProjectIdAndRendererTypes?: Array<{
    __typename?: 'SceneEntity';
    id?: string | null;
    name?: string | null;
    rendererType?: string | null;
    parentSceneEntityId?: string | null;
    relativePosition?: Array<number | null> | null;
    relativeRotation?: Array<number | null> | null;
    position?: {
      __typename?: 'Position';
      x?: number | null;
      y?: number | null;
      z?: number | null;
    } | null;
    rotation?: {
      __typename?: 'Rotation';
      x?: number | null;
      y?: number | null;
      z?: number | null;
      w?: number | null;
    } | null;
    renderObject?:
      | { __typename: 'AnnotationRenderObject'; id?: string | null; createdAt?: string | null }
      | {
          __typename: 'CesiumIonRenderObject';
          id?: string | null;
          kind?: string | null;
          cesiumIonId?: string | null;
          process?:
            | { __typename?: 'AnnotationProcess' }
            | {
                __typename?: 'CesiumIonProcess';
                steps?: Array<{
                  __typename?: 'StepField';
                  stepName?: string | null;
                  comment?: string | null;
                } | null> | null;
              }
            | { __typename?: 'DefectProcess' }
            | { __typename?: 'DxfProcess' }
            | { __typename?: 'IfcProcess' }
            | { __typename?: 'ImageProjectionProcess' }
            | { __typename?: 'KmlProcess' }
            | { __typename?: 'ObjProcess' }
            | { __typename?: 'Ortho2dProcess' }
            | { __typename?: 'PanoramicProcess' }
            | { __typename?: 'PanoramicVideoProcess' }
            | { __typename?: 'Tileset3dProcess' }
            | null;
        }
      | {
          __typename: 'DxfRenderObject';
          id?: string | null;
          epsgCode?: string | null;
          file?:
            | { __typename?: 'AssetFile'; id?: string | null; signedGetObjectUrl?: string | null }
            | { __typename?: 'ImageFile' }
            | { __typename?: 'ObjFile' }
            | { __typename?: 'Ortho2dFile' }
            | { __typename?: 'Tileset3dFile' }
            | null;
        }
      | {
          __typename: 'IfcRenderObject';
          id?: string | null;
          epsgCode?: string | null;
          file?:
            | {
                __typename?: 'AssetFile';
                id?: string | null;
                signedGetObjectUrl?: string | null;
                storage?: { __typename?: 'FileStorageField'; size?: number | null } | null;
              }
            | { __typename?: 'ImageFile' }
            | { __typename?: 'ObjFile' }
            | { __typename?: 'Ortho2dFile' }
            | { __typename?: 'Tileset3dFile' }
            | null;
        }
      | {
          __typename: 'ImageProjectionRenderObject';
          id?: string | null;
          kind?: string | null;
          processId?: string | null;
        }
      | {
          __typename: 'KmlRenderObject';
          id?: string | null;
          file?:
            | { __typename?: 'AssetFile'; id?: string | null; signedGetObjectUrl?: string | null }
            | { __typename?: 'ImageFile' }
            | { __typename?: 'ObjFile' }
            | { __typename?: 'Ortho2dFile' }
            | { __typename?: 'Tileset3dFile' }
            | null;
        }
      | {
          __typename: 'ObjRenderObject';
          id?: string | null;
          epsgCode?: string | null;
          kind?: string | null;
          process?:
            | { __typename?: 'AnnotationProcess' }
            | { __typename?: 'CesiumIonProcess' }
            | { __typename?: 'DefectProcess' }
            | { __typename?: 'DxfProcess' }
            | { __typename?: 'IfcProcess' }
            | { __typename?: 'ImageProjectionProcess' }
            | { __typename?: 'KmlProcess' }
            | {
                __typename?: 'ObjProcess';
                steps?: Array<{
                  __typename?: 'StepField';
                  stepName?: string | null;
                  comment?: string | null;
                } | null> | null;
              }
            | { __typename?: 'Ortho2dProcess' }
            | { __typename?: 'PanoramicProcess' }
            | { __typename?: 'PanoramicVideoProcess' }
            | { __typename?: 'Tileset3dProcess' }
            | null;
          file?:
            | { __typename?: 'AssetFile'; id?: string | null; httpUrl?: string | null }
            | { __typename?: 'ImageFile' }
            | { __typename?: 'ObjFile'; id?: string | null; httpUrl?: string | null }
            | { __typename?: 'Ortho2dFile' }
            | { __typename?: 'Tileset3dFile' }
            | null;
        }
      | {
          __typename: 'Ortho2dRenderObject';
          id?: string | null;
          kind?: string | null;
          process?:
            | { __typename?: 'AnnotationProcess' }
            | { __typename?: 'CesiumIonProcess' }
            | { __typename?: 'DefectProcess' }
            | { __typename?: 'DxfProcess' }
            | { __typename?: 'IfcProcess' }
            | { __typename?: 'ImageProjectionProcess' }
            | { __typename?: 'KmlProcess' }
            | { __typename?: 'ObjProcess' }
            | {
                __typename?: 'Ortho2dProcess';
                steps?: Array<{
                  __typename?: 'StepField';
                  stepName?: string | null;
                  comment?: string | null;
                } | null> | null;
              }
            | { __typename?: 'PanoramicProcess' }
            | { __typename?: 'PanoramicVideoProcess' }
            | { __typename?: 'Tileset3dProcess' }
            | null;
          file?:
            | { __typename?: 'AssetFile'; id?: string | null; httpUrl?: string | null }
            | { __typename?: 'ImageFile' }
            | { __typename?: 'ObjFile' }
            | { __typename?: 'Ortho2dFile'; id?: string | null; httpUrl?: string | null }
            | { __typename?: 'Tileset3dFile' }
            | null;
        }
      | {
          __typename: 'PanoramicRenderObject';
          id?: string | null;
          kind?: string | null;
          processId?: string | null;
        }
      | { __typename?: 'PanoramicVideoRenderObject' }
      | {
          __typename: 'Tileset3dRenderObject';
          id?: string | null;
          kind?: string | null;
          file?:
            | { __typename?: 'AssetFile' }
            | { __typename?: 'ImageFile' }
            | { __typename?: 'ObjFile' }
            | { __typename?: 'Ortho2dFile' }
            | { __typename?: 'Tileset3dFile'; id?: string | null; httpUrl?: string | null }
            | null;
          process?:
            | { __typename?: 'AnnotationProcess' }
            | { __typename?: 'CesiumIonProcess' }
            | { __typename?: 'DefectProcess' }
            | { __typename?: 'DxfProcess' }
            | { __typename?: 'IfcProcess' }
            | { __typename?: 'ImageProjectionProcess' }
            | { __typename?: 'KmlProcess' }
            | { __typename?: 'ObjProcess' }
            | { __typename?: 'Ortho2dProcess' }
            | { __typename?: 'PanoramicProcess' }
            | { __typename?: 'PanoramicVideoProcess' }
            | { __typename?: 'Tileset3dProcess'; lastStepIsDone?: boolean | null }
            | null;
        }
      | null;
  } | null> | null;
};

export type ListRenderObjectsByIdsQueryVariables = Exact<{
  renderObjectIds: Array<InputMaybe<Scalars['ID']['input']>> | InputMaybe<Scalars['ID']['input']>;
}>;

export type ListRenderObjectsByIdsQuery = {
  __typename?: 'Query';
  listRenderObjectByIds?: Array<
    | { __typename?: 'AnnotationRenderObject' }
    | { __typename?: 'CesiumIonRenderObject' }
    | { __typename?: 'DxfRenderObject' }
    | { __typename?: 'IfcRenderObject' }
    | {
        __typename: 'ImageProjectionRenderObject';
        id?: string | null;
        kind?: string | null;
        processId?: string | null;
        cameraModels?: Array<{
          __typename?: 'CameraModelField';
          imageSize?: Array<number> | null;
          principalPoint?: Array<number> | null;
          id?: string | null;
          focalLength?: number | null;
          projectionString?: string | null;
          sensorSize?: number | null;
          skew?: number | null;
          distortion?: {
            __typename?: 'CameraDistortionField';
            k1?: number | null;
            k2?: number | null;
            k3?: number | null;
            p1?: number | null;
            p2?: number | null;
          } | null;
        } | null> | null;
        images?: Array<{
          __typename?: 'ImageField';
          id?: string | null;
          cameraModelId?: string | null;
          cameraRelativePosition?: Array<number> | null;
          cameraRelativeRotation?: Array<number> | null;
          projectedRelativePosition?: Array<number> | null;
          projectedRelativeRotation?: Array<number> | null;
          fileId?: string | null;
          file?:
            | { __typename?: 'AssetFile' }
            | {
                __typename: 'ImageFile';
                fileName?: string | null;
                createThumbnailEvent?: { __typename?: 'Event'; status?: string | null } | null;
                createTilesEvent?: { __typename?: 'Event'; status?: string | null } | null;
                tiles?: {
                  __typename?: 'ImageTilesMetadata';
                  rows?: number | null;
                  cols?: number | null;
                } | null;
              }
            | { __typename?: 'ObjFile' }
            | { __typename?: 'Ortho2dFile' }
            | { __typename?: 'Tileset3dFile' }
            | null;
        } | null> | null;
      }
    | { __typename?: 'KmlRenderObject' }
    | { __typename?: 'ObjRenderObject' }
    | { __typename?: 'Ortho2dRenderObject' }
    | {
        __typename: 'PanoramicRenderObject';
        id?: string | null;
        kind?: string | null;
        processId?: string | null;
        panoramicImages?: Array<{
          __typename?: 'PanoramicImageField';
          id?: string | null;
          name?: string | null;
          relativePosition?: Array<number> | null;
          relativeRotation?: Array<number> | null;
          fileId?: string | null;
          file?:
            | { __typename?: 'AssetFile' }
            | {
                __typename: 'ImageFile';
                fileName?: string | null;
                createThumbnailEvent?: { __typename?: 'Event'; status?: string | null } | null;
                createTilesEvent?: { __typename?: 'Event'; status?: string | null } | null;
                tiles?: {
                  __typename?: 'ImageTilesMetadata';
                  rows?: number | null;
                  cols?: number | null;
                } | null;
              }
            | { __typename?: 'ObjFile' }
            | { __typename?: 'Ortho2dFile' }
            | { __typename?: 'Tileset3dFile' }
            | null;
        } | null> | null;
      }
    | { __typename?: 'PanoramicVideoRenderObject' }
    | { __typename?: 'Tileset3dRenderObject' }
    | null
  > | null;
};

export type GetImageUrlsByFileIdsQueryVariables = Exact<{
  fileIds: Array<InputMaybe<Scalars['ID']['input']>> | InputMaybe<Scalars['ID']['input']>;
}>;

export type GetImageUrlsByFileIdsQuery = {
  __typename?: 'Query';
  filesByIds?: Array<
    | { __typename?: 'AssetFile' }
    | { __typename: 'ImageFile'; id?: string | null; signedGetObjectUrl?: string | null }
    | { __typename?: 'ObjFile' }
    | { __typename?: 'Ortho2dFile' }
    | { __typename?: 'Tileset3dFile' }
    | null
  > | null;
};

export type GetDownloadUrlsByFileIdsQueryVariables = Exact<{
  fileIds: Array<InputMaybe<Scalars['ID']['input']>> | InputMaybe<Scalars['ID']['input']>;
}>;

export type GetDownloadUrlsByFileIdsQuery = {
  __typename?: 'Query';
  filesByIds?: Array<
    | { __typename?: 'AssetFile'; id?: string | null; signedGetObjectDownloadUrl?: string | null }
    | { __typename?: 'ImageFile'; id?: string | null; signedGetObjectDownloadUrl?: string | null }
    | { __typename?: 'ObjFile'; id?: string | null; signedGetObjectDownloadUrl?: string | null }
    | { __typename?: 'Ortho2dFile'; id?: string | null; signedGetObjectDownloadUrl?: string | null }
    | {
        __typename?: 'Tileset3dFile';
        id?: string | null;
        signedGetObjectDownloadUrl?: string | null;
      }
    | null
  > | null;
};

export type GetImageThumbnailsByFileIdsQueryVariables = Exact<{
  fileIds: Array<InputMaybe<Scalars['ID']['input']>> | InputMaybe<Scalars['ID']['input']>;
}>;

export type GetImageThumbnailsByFileIdsQuery = {
  __typename?: 'Query';
  filesByIds?: Array<
    | { __typename?: 'AssetFile' }
    | { __typename: 'ImageFile'; id?: string | null; thumbnailUrl?: string | null }
    | { __typename?: 'ObjFile' }
    | { __typename?: 'Ortho2dFile' }
    | { __typename?: 'Tileset3dFile' }
    | null
  > | null;
};

export type GetUserByAccountIdQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserByAccountIdQuery = {
  __typename?: 'Query';
  userByAccountId?: {
    __typename?: 'User';
    id?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    email?: string | null;
  } | null;
};

export type DeleteAnnotationTemplateMutationVariables = Exact<{
  annotationTemplateId: Scalars['ID']['input'];
}>;

export type DeleteAnnotationTemplateMutation = {
  __typename?: 'Mutation';
  deleteAnnotationTemplate?: string | null;
};

export type ListShareLinksByProjectIdQueryVariables = Exact<{
  projectId: Scalars['ID']['input'];
}>;

export type ListShareLinksByProjectIdQuery = {
  __typename?: 'Query';
  listShareLinksByProjectId?: Array<{
    __typename?: 'ShareLink';
    id?: string | null;
    shareToken?: string | null;
    accountId?: string | null;
    projectId?: string | null;
    name?: string | null;
    isActivated?: boolean | null;
    createdByUserId?: string | null;
    updatedByUserId?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null> | null;
};

export type ListPermissionPoliciesWithAccountContextQueryVariables = Exact<{
  subjectId?: InputMaybe<Scalars['String']['input']>;
  objectId?: InputMaybe<Scalars['String']['input']>;
  subjectType?: InputMaybe<PolicySubjectTypeInput>;
  objectType?: InputMaybe<PolicyObjectTypeInput>;
  actionType?: InputMaybe<PolicyActionTypeInput>;
}>;

export type ListPermissionPoliciesWithAccountContextQuery = {
  __typename?: 'Query';
  listPermissionPoliciesWithAccountContext?: Array<{
    __typename?: 'PermissionPolicy';
    accountId?: string | null;
    subjectId?: string | null;
    subjectType?: string | null;
    objectId?: string | null;
    objectType?: string | null;
    actionType?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null> | null;
};

export type ListSystemNodesByParentNodeIdQueryVariables = Exact<{
  pageSize: Scalars['Int']['input'];
  pageIndex: Scalars['Int']['input'];
  parentNodeId?: InputMaybe<Scalars['ID']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  includeMigratedData?: InputMaybe<Scalars['Boolean']['input']>;
  nodeKinds?: InputMaybe<Array<InputMaybe<SystemNodeKindInput>> | InputMaybe<SystemNodeKindInput>>;
}>;

export type ListSystemNodesByParentNodeIdQuery = {
  __typename?: 'Query';
  listSystemNodesByParentNodeId?: {
    __typename?: 'NodeWithPagination';
    totalNumberOfPages?: number | null;
    totalNumberOfFiles?: number | null;
    data?: Array<
      | {
          __typename: 'FileNode';
          id?: string | null;
          kind?: string | null;
          parentNodeId?: string | null;
          name?: string | null;
          createdAt?: string | null;
          lastDownloadedAt?: string | null;
          updatedAt?: string | null;
          fileId?: string | null;
          createdUser?: {
            __typename?: 'UserV2';
            id?: string | null;
            displayName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
          } | null;
          lastDownloadedUser?: {
            __typename?: 'UserV2';
            id?: string | null;
            displayName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
          } | null;
        }
      | {
          __typename: 'FolderNode';
          id?: string | null;
          kind?: string | null;
          parentNodeId?: string | null;
          name?: string | null;
          createdAt?: string | null;
          lastDownloadedAt?: string | null;
          updatedAt?: string | null;
          createdUser?: {
            __typename?: 'UserV2';
            id?: string | null;
            displayName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
          } | null;
          lastDownloadedUser?: {
            __typename?: 'UserV2';
            id?: string | null;
            displayName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
          } | null;
        }
      | {
          __typename: 'LinkNode';
          id?: string | null;
          kind?: string | null;
          name?: string | null;
          sourceNodeId?: string | null;
          parentNodeId?: string | null;
          createdAt?: string | null;
          lastDownloadedAt?: string | null;
          updatedAt?: string | null;
          createdUser?: {
            __typename?: 'UserV2';
            id?: string | null;
            displayName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
          } | null;
          lastDownloadedUser?: {
            __typename?: 'UserV2';
            id?: string | null;
            displayName?: string | null;
            firstName?: string | null;
            lastName?: string | null;
          } | null;
          sourceNode?:
            | {
                __typename: 'FileNode';
                id?: string | null;
                kind?: string | null;
                parentNodeId?: string | null;
                name?: string | null;
                createdAt?: string | null;
                lastDownloadedAt?: string | null;
                fileId?: string | null;
              }
            | {
                __typename: 'FolderNode';
                id?: string | null;
                kind?: string | null;
                parentNodeId?: string | null;
                name?: string | null;
                createdAt?: string | null;
                lastDownloadedAt?: string | null;
              }
            | { __typename?: 'LinkNode' }
            | null;
        }
      | null
    > | null;
  } | null;
};

export type ParentNodesChainByParentNodeIdQueryVariables = Exact<{
  parentNodeId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type ParentNodesChainByParentNodeIdQuery = {
  __typename?: 'Query';
  parentNodesChainByParentNodeId?: Array<{
    __typename?: 'FolderNode';
    id?: string | null;
    kind?: string | null;
    parentNodeId?: string | null;
    name?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    lastDownloadedAt?: string | null;
  } | null> | null;
};

export type FileSignedGetObjectDownloadUrlByIdQueryVariables = Exact<{
  fileId: Scalars['ID']['input'];
}>;

export type FileSignedGetObjectDownloadUrlByIdQuery = {
  __typename?: 'Query';
  file?:
    | { __typename?: 'AssetFile'; id?: string | null; signedGetObjectDownloadUrl?: string | null }
    | { __typename?: 'ImageFile'; id?: string | null; signedGetObjectDownloadUrl?: string | null }
    | { __typename?: 'ObjFile'; id?: string | null; signedGetObjectDownloadUrl?: string | null }
    | { __typename?: 'Ortho2dFile'; id?: string | null; signedGetObjectDownloadUrl?: string | null }
    | {
        __typename?: 'Tileset3dFile';
        id?: string | null;
        signedGetObjectDownloadUrl?: string | null;
      }
    | null;
};

export type ListUsersByAccountIdQueryVariables = Exact<{ [key: string]: never }>;

export type ListUsersByAccountIdQuery = {
  __typename?: 'Query';
  listUsersByAccountId?: Array<{
    __typename?: 'UserV2';
    id?: string | null;
    accountId?: string | null;
    email?: string | null;
    name?: { __typename?: 'UserName'; first?: string | null; last?: string | null } | null;
  } | null> | null;
};

export type GetImageFileByIdQueryVariables = Exact<{
  fileIds: Array<InputMaybe<Scalars['ID']['input']>> | InputMaybe<Scalars['ID']['input']>;
}>;

export type GetImageFileByIdQuery = {
  __typename?: 'Query';
  filesByIds?: Array<
    | { __typename?: 'AssetFile' }
    | {
        __typename: 'ImageFile';
        fileName?: string | null;
        id?: string | null;
        kind?: string | null;
        originalUrl?: string | null;
        signedGetObjectUrl?: string | null;
        thumbnailUrl?: string | null;
      }
    | { __typename?: 'ObjFile' }
    | { __typename?: 'Ortho2dFile' }
    | { __typename?: 'Tileset3dFile' }
    | null
  > | null;
};

export type ListFeatureEntitlementsByAccountContextQueryVariables = Exact<{ [key: string]: never }>;

export type ListFeatureEntitlementsByAccountContextQuery = {
  __typename?: 'Query';
  listFeatureEntitlementsByAccountContext?: Array<{
    __typename?: 'FeatureEntitlement';
    enabled?: boolean | null;
    infinity?: boolean | null;
    name?: string | null;
    total?: number | null;
    type?: string | null;
    used?: number | null;
  } | null> | null;
};

export type GetFilesByIdsQueryVariables = Exact<{
  fileIds: Array<InputMaybe<Scalars['ID']['input']>> | InputMaybe<Scalars['ID']['input']>;
}>;

export type GetFilesByIdsQuery = {
  __typename?: 'Query';
  filesByIds?: Array<
    | {
        __typename?: 'AssetFile';
        id?: string | null;
        signedGetObjectUrl?: string | null;
        signedGetObjectDownloadUrl?: string | null;
        storage?: {
          __typename?: 'FileStorageField';
          name?: string | null;
          size?: number | null;
        } | null;
      }
    | {
        __typename?: 'ImageFile';
        id?: string | null;
        signedGetObjectUrl?: string | null;
        signedGetObjectDownloadUrl?: string | null;
        storage?: {
          __typename?: 'FileStorageField';
          name?: string | null;
          size?: number | null;
        } | null;
      }
    | {
        __typename?: 'ObjFile';
        id?: string | null;
        signedGetObjectUrl?: string | null;
        signedGetObjectDownloadUrl?: string | null;
        storage?: {
          __typename?: 'FileStorageField';
          name?: string | null;
          size?: number | null;
        } | null;
      }
    | {
        __typename?: 'Ortho2dFile';
        id?: string | null;
        signedGetObjectUrl?: string | null;
        signedGetObjectDownloadUrl?: string | null;
        storage?: {
          __typename?: 'FileStorageField';
          name?: string | null;
          size?: number | null;
        } | null;
      }
    | {
        __typename?: 'Tileset3dFile';
        id?: string | null;
        signedGetObjectUrl?: string | null;
        signedGetObjectDownloadUrl?: string | null;
        storage?: {
          __typename?: 'FileStorageField';
          name?: string | null;
          size?: number | null;
        } | null;
      }
    | null
  > | null;
};

export type ListImageTilesByFileIdsQueryVariables = Exact<{
  fileIds: Array<InputMaybe<Scalars['ID']['input']>> | InputMaybe<Scalars['ID']['input']>;
}>;

export type ListImageTilesByFileIdsQuery = {
  __typename?: 'Query';
  listImageTilesByFileIds?: Array<{
    __typename?: 'ImageTiles';
    fileId?: string | null;
    tilesWithSignedUrls?: Array<{
      __typename?: 'ImageTile';
      row?: number | null;
      col?: number | null;
      signedUrl?: string | null;
    } | null> | null;
  } | null> | null;
};

export type ListManualProcessesByProjectIdQueryVariables = Exact<{
  projectId: Scalars['ID']['input'];
}>;

export type ListManualProcessesByProjectIdQuery = {
  __typename?: 'Query';
  listManualProcessesByProjectId?: Array<
    | {
        __typename: 'AnnotationProcess';
        id?: string | null;
        name?: string | null;
        createdAt?: string | null;
        captureId?: string | null;
        accountId?: string | null;
        lastStep?: string | null;
        annotationIds?: Array<string | null> | null;
        imagesWithStatus?: Array<{
          __typename?: 'AnnotationProcessImageWithStatusField';
          id?: string | null;
          status?: string | null;
          fileId?: string | null;
        } | null> | null;
      }
    | { __typename?: 'CesiumIonProcess' }
    | { __typename?: 'DefectProcess' }
    | { __typename?: 'DxfProcess' }
    | { __typename?: 'IfcProcess' }
    | { __typename?: 'ImageProjectionProcess' }
    | { __typename?: 'KmlProcess' }
    | { __typename?: 'ObjProcess' }
    | { __typename?: 'Ortho2dProcess' }
    | {
        __typename: 'PanoramicProcess';
        id?: string | null;
        createdAt?: string | null;
        captureId?: string | null;
        accountId?: string | null;
        lastStep?: string | null;
        imagesWithStatus?: Array<{
          __typename?: 'ImagesWithStatusField';
          id?: string | null;
          status?: string | null;
          fileId?: string | null;
        } | null> | null;
      }
    | { __typename?: 'PanoramicVideoProcess' }
    | { __typename?: 'Tileset3dProcess' }
    | null
  > | null;
};

export type AccountV2ByContextQueryVariables = Exact<{ [key: string]: never }>;

export type AccountV2ByContextQuery = {
  __typename?: 'Query';
  readAccountByContext?: {
    __typename?: 'AccountV2';
    id?: string | null;
    branding?: {
      __typename?: 'AccountBranding';
      webappBannerFileId?: string | null;
      webappBannerFile?:
        | { __typename: 'AssetFile' }
        | { __typename: 'ImageFile'; originalUrl?: string | null }
        | { __typename: 'ObjFile' }
        | { __typename: 'Ortho2dFile' }
        | { __typename: 'Tileset3dFile' }
        | null;
    } | null;
  } | null;
};

export type CheckSuperAdminQueryVariables = Exact<{ [key: string]: never }>;

export type CheckSuperAdminQuery = { __typename?: 'Query'; checkSuperAdmin?: boolean | null };

export type FindDataImportRequestByDataImportRequestIdQueryVariables = Exact<{
  dataImportRequestId: Scalars['ID']['input'];
}>;

export type FindDataImportRequestByDataImportRequestIdQuery = {
  __typename?: 'Query';
  findDataImportRequestByDataImportRequestId?: {
    __typename?: 'DataImportRequest';
    rootFolderImportRequestId?: string | null;
  } | null;
};

export type CountFileImportRequestsByDataImportRequestIdQueryVariables = Exact<{
  dataImportRequestId: Scalars['String']['input'];
  fileKind?: InputMaybe<Scalars['String']['input']>;
}>;

export type CountFileImportRequestsByDataImportRequestIdQuery = {
  __typename?: 'Query';
  countFileImportRequestsByDataImportRequestId?: number | null;
};

export type FindFileImportRequestsByDataImportRequestIdQueryVariables = Exact<{
  dataImportRequestId: Scalars['String']['input'];
  fileKind?: InputMaybe<Scalars['String']['input']>;
  start?: InputMaybe<Scalars['Int']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
}>;

export type FindFileImportRequestsByDataImportRequestIdQuery = {
  __typename?: 'Query';
  findFileImportRequestsByDataImportRequestId?: Array<{
    __typename?: 'FileImportRequest';
    id?: string | null;
    kind?: string | null;
    fileId?: string | null;
  } | null> | null;
};

export type ListModelsByAccountIdQueryVariables = Exact<{ [key: string]: never }>;

export type ListModelsByAccountIdQuery = {
  __typename?: 'Query';
  listModelsByAccountId?: Array<{
    __typename?: 'Model';
    id?: string | null;
    name?: string | null;
  } | null> | null;
};

export type ListTrainingsByModelIdQueryVariables = Exact<{
  modelId: Scalars['String']['input'];
}>;

export type ListTrainingsByModelIdQuery = {
  __typename?: 'Query';
  listTrainingsByModelId?: Array<{
    __typename?: 'Training';
    id?: string | null;
    modelId?: string | null;
    status?: string | null;
    startTime?: string | null;
    duration?: string | null;
  } | null> | null;
};

export type GetZipRequestByIdQueryVariables = Exact<{
  zipRequestId: Scalars['ID']['input'];
}>;

export type GetZipRequestByIdQuery = {
  __typename?: 'Query';
  structuredZipRequest?: {
    __typename?: 'StructuredZipRequest';
    name?: string | null;
    signedGetObjectUrl?: string | null;
    size?: number | null;
    note?: string | null;
    expiresAt?: string | null;
  } | null;
};

export type ReadProcessByIdQueryVariables = Exact<{
  processId: Scalars['ID']['input'];
}>;

export type ReadProcessByIdQuery = {
  __typename?: 'Query';
  readProcessById?:
    | { __typename?: 'AnnotationProcess' }
    | { __typename?: 'CesiumIonProcess' }
    | { __typename?: 'DefectProcess' }
    | { __typename?: 'DxfProcess' }
    | { __typename?: 'IfcProcess' }
    | {
        __typename?: 'ImageProjectionProcess';
        id?: string | null;
        imagesWithStatus?: Array<{
          __typename?: 'ImagesWithStatusField';
          fileId?: string | null;
          fileName?: string | null;
          status?: string | null;
        } | null> | null;
        xmlFile?: { __typename?: 'AssetFile'; signedGetObjectUrl?: string | null } | null;
      }
    | { __typename?: 'KmlProcess' }
    | { __typename?: 'ObjProcess' }
    | { __typename?: 'Ortho2dProcess' }
    | {
        __typename?: 'PanoramicProcess';
        id?: string | null;
        imagesWithStatus?: Array<{
          __typename?: 'ImagesWithStatusField';
          fileId?: string | null;
          fileName?: string | null;
          status?: string | null;
        } | null> | null;
        formatFile?:
          | { __typename?: 'AssetFile'; signedGetObjectUrl?: string | null }
          | { __typename?: 'ImageFile' }
          | { __typename?: 'ObjFile' }
          | { __typename?: 'Ortho2dFile' }
          | { __typename?: 'Tileset3dFile' }
          | null;
      }
    | { __typename?: 'PanoramicVideoProcess' }
    | { __typename?: 'Tileset3dProcess' }
    | null;
};

export type FindSystemFileNodeByFileIdQueryVariables = Exact<{
  fileId: Scalars['ID']['input'];
}>;

export type FindSystemFileNodeByFileIdQuery = {
  __typename?: 'Query';
  findSystemFileNodeByFileId?:
    | {
        __typename?: 'FileNode';
        file?:
          | {
              __typename?: 'AssetFile';
              signedGetObjectDownloadUrl?: string | null;
              storage?: { __typename?: 'FileStorageField'; size?: number | null } | null;
            }
          | {
              __typename?: 'ImageFile';
              signedGetObjectDownloadUrl?: string | null;
              storage?: { __typename?: 'FileStorageField'; size?: number | null } | null;
            }
          | {
              __typename?: 'ObjFile';
              signedGetObjectDownloadUrl?: string | null;
              storage?: { __typename?: 'FileStorageField'; size?: number | null } | null;
            }
          | {
              __typename?: 'Ortho2dFile';
              signedGetObjectDownloadUrl?: string | null;
              storage?: { __typename?: 'FileStorageField'; size?: number | null } | null;
            }
          | {
              __typename?: 'Tileset3dFile';
              signedGetObjectDownloadUrl?: string | null;
              storage?: { __typename?: 'FileStorageField'; size?: number | null } | null;
            }
          | null;
      }
    | { __typename?: 'FolderNode' }
    | { __typename?: 'LinkNode' }
    | null;
};

export type ListUserGroupsByAccountContextQueryVariables = Exact<{ [key: string]: never }>;

export type ListUserGroupsByAccountContextQuery = {
  __typename?: 'Query';
  listUserGroupsByAccountContext?: Array<{
    __typename?: 'UserGroup';
    createdAt?: string | null;
    description?: string | null;
    id?: string | null;
    accountId?: string | null;
    name?: string | null;
    updatedAt?: string | null;
    permissionPolicies?: Array<{
      __typename?: 'PermissionPolicy';
      accountId?: string | null;
      actionType?: string | null;
      objectId?: string | null;
      objectType?: string | null;
      subjectId?: string | null;
      subjectType?: string | null;
    } | null> | null;
    userGroupToRolePolicies?: Array<{
      __typename?: 'GroupingPolicy';
      accountId?: string | null;
      sourceId?: string | null;
      sourceType?: string | null;
      targetId?: string | null;
      targetType?: string | null;
    } | null> | null;
    userToUserGroupPolicies?: Array<{
      __typename?: 'GroupingPolicy';
      accountId?: string | null;
      sourceId?: string | null;
      sourceType?: string | null;
      targetId?: string | null;
      targetType?: string | null;
    } | null> | null;
  } | null> | null;
};

export type ListActionTypesByObjectTypeQueryVariables = Exact<{
  objectType: Scalars['String']['input'];
}>;

export type ListActionTypesByObjectTypeQuery = {
  __typename?: 'Query';
  listActionTypesByObjectType?: Array<string | null> | null;
};

export type FindSystemNodeMostPermissiveActionTypeFromParentNodeChainWithSystemNodeIdQueryVariables =
  Exact<{
    systemNodeId: Scalars['String']['input'];
  }>;

export type FindSystemNodeMostPermissiveActionTypeFromParentNodeChainWithSystemNodeIdQuery = {
  __typename?: 'Query';
  findSystemNodeMostPermissiveActionTypeFromParentNodeChainWithSystemNodeId?: PolicyActionTypeInput | null;
};

export const LoginDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Login' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'password' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'login' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'password' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'password' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'bearerToken' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LoginMutation, LoginMutationVariables>;
export const CreateAnnotationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateAnnotation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'annotation' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateAnnotationInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createAnnotation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'request' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'annotation' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'annotationId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateAnnotationMutation, CreateAnnotationMutationVariables>;
export const CreateManyAnnotationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateManyAnnotations' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'annotations' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateAnnotationInput' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createManyAnnotations' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'requests' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'annotations' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateManyAnnotationsMutation, CreateManyAnnotationsMutationVariables>;
export const CreateAccountAnnotationTemplateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateAccountAnnotationTemplate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'template' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateAccountAnnotationTemplateInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createAccountAnnotationTemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'request' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'template' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateAccountAnnotationTemplateMutation,
  CreateAccountAnnotationTemplateMutationVariables
>;
export const CreateProjectAnnotationTemplateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateProjectAnnotationTemplate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'template' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateProjectAnnotationTemplateInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createProjectAnnotationTemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'request' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'template' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateProjectAnnotationTemplateMutation,
  CreateProjectAnnotationTemplateMutationVariables
>;
export const UpdateAnnotationTemplateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateAnnotationTemplate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'template' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateAnnotationTemplateInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateAnnotationTemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'request' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'template' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateAnnotationTemplateMutation,
  UpdateAnnotationTemplateMutationVariables
>;
export const CreateAnnotationGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateAnnotationGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'annotationGroup' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateAnnotationGroupInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createAnnotationGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'request' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'annotationGroup' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateAnnotationGroupMutation, CreateAnnotationGroupMutationVariables>;
export const RenameSceneEntityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RenameSceneEntity' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sceneEntityId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateSceneEntityName' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sceneEntityId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sceneEntityId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RenameSceneEntityMutation, RenameSceneEntityMutationVariables>;
export const DeleteAnnotationGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteAnnotationGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'annotationGroupId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteAnnotationGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'annotationGroupSceneEntityId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'annotationGroupId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteAnnotationGroupMutation, DeleteAnnotationGroupMutationVariables>;
export const CreateProjectGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateProjectGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'description' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectIds' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createProjectGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accountId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'description' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'description' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectIds' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateProjectGroupMutation, CreateProjectGroupMutationVariables>;
export const UpdateProjectGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateProjectGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectGroupId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'description' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateProjectGroupNameAndDescription' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectGroupId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectGroupId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'description' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'description' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateProjectGroupMutation, UpdateProjectGroupMutationVariables>;
export const MoveAnnotationsToGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'MoveAnnotationsToGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'annotationIdsWithPositionAndRotation' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'AnnotationIdWithPositionAndRotation' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'groupId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'moveAnnotationsToGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'annotationIdsWithPositionAndRotation' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'annotationIdsWithPositionAndRotation' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'groupId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MoveAnnotationsToGroupMutation,
  MoveAnnotationsToGroupMutationVariables
>;
export const UpdateProjectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateProject' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'address' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'description' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'geoid' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectGroupId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'longitude' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'latitude' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateProject' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'address' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'address' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'description' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'description' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'geoid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'geoid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectGroupId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectGroupId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'longitude' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'longitude' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'latitude' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'latitude' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateProjectMutation, UpdateProjectMutationVariables>;
export const DeleteProjectGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteProjectGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectGroupId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteProjectGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectGroupId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectGroupId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteProjectGroupMutation, DeleteProjectGroupMutationVariables>;
export const MoveProjectsToGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'MoveProjectsToGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'groupId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'moveProjectsToGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'groupId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectIds' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MoveProjectsToGroupMutation, MoveProjectsToGroupMutationVariables>;
export const UpdateAnnotationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateAnnotation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'annotation' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateAnnotationInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateAnnotation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'request' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'annotation' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'annotationId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateAnnotationMutation, UpdateAnnotationMutationVariables>;
export const DeleteAnnotationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteAnnotation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'annotationId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteAnnotation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'annotationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'annotationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteAnnotationMutation, DeleteAnnotationMutationVariables>;
export const DeleteAnnotationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteAnnotations' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'annotationIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteAnnotations' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'annotationIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'annotationIds' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteAnnotationsMutation, DeleteAnnotationsMutationVariables>;
export const SetParentSceneEntityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SetParentSceneEntity' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sceneEntityId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'parentSceneEntityId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'position' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'SceneEntityPositionInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'rotation' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'SceneEntityRotationInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'AddParentToSceneEntity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sceneEntityId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sceneEntityId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'parentSceneEntityId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'parentSceneEntityId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'position' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'position' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'rotation' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'rotation' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SetParentSceneEntityMutation, SetParentSceneEntityMutationVariables>;
export const RemoveParentSceneEntityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RemoveParentSceneEntity' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sceneEntityId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'position' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'SceneEntityPositionInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'rotation' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'SceneEntityRotationInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'RemoveParentFromSceneEntity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sceneEntityId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sceneEntityId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'position' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'position' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'rotation' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'rotation' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RemoveParentSceneEntityMutation,
  RemoveParentSceneEntityMutationVariables
>;
export const CreateShareLinkDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateShareLink' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'permissions' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PermissionPolicyWithOutSubjectInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createShareLink' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'permissions' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'permissions' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shareToken' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateShareLinkMutation, CreateShareLinkMutationVariables>;
export const UpdateShareLinkDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateShareLink' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'shareLinkId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'isActivated' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'permissions' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'PermissionPolicyInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateShareLink' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'shareLinkId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'shareLinkId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'isActivated' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'isActivated' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'permissions' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'permissions' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateShareLinkMutation, UpdateShareLinkMutationVariables>;
export const DeleteShareLinkDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteShareLink' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'shareLinkId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteShareLink' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'shareLinkId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'shareLinkId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteShareLinkMutation, DeleteShareLinkMutationVariables>;
export const CreateProjectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateProject' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'address' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'description' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'geoid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectGroupId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'longitude' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'latitude' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createProject' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'address' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'address' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'description' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'description' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'geoid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'geoid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectGroupId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectGroupId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'longitude' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'longitude' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'latitude' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'latitude' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateProjectMutation, CreateProjectMutationVariables>;
export const CreateProjectFileNodesWithNodeTreeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateProjectFileNodesWithNodeTree' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'nodeIds' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'siteId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'parentNodeId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createProjectFileNodesWithNodeTree' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'nodeIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'nodeIds' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'siteId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'siteId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'parentNodeId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'parentNodeId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateProjectFileNodesWithNodeTreeMutation,
  CreateProjectFileNodesWithNodeTreeMutationVariables
>;
export const DeleteProjectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteProject' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteProject' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteProjectMutation, DeleteProjectMutationVariables>;
export const TransformSceneEntityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'TransformSceneEntity' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sceneEntityId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'position' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'SceneEntityPositionInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'rotation' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'SceneEntityRotationInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateSceneEntityPositionAndRotation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sceneEntityId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sceneEntityId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'position' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'position' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'rotation' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'rotation' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TransformSceneEntityMutation, TransformSceneEntityMutationVariables>;
export const CreateSceneEntityGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateSceneEntityGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'parentSceneEntityId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'CreateSceneEntityGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accountId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'parentSceneEntityId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'parentSceneEntityId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateSceneEntityGroupMutation,
  CreateSceneEntityGroupMutationVariables
>;
export const DeleteSceneEntityGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteSceneEntityGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sceneEntityId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'DeleteSceneEntityGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sceneEntityId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sceneEntityId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteSceneEntityGroupMutation,
  DeleteSceneEntityGroupMutationVariables
>;
export const CreateProjectFileNodesFromFilesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateProjectFileNodesFromFiles' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'parentNodeId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'nodeIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createProjectFileNodesWithNodeTree' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'nodeIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'nodeIds' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'siteId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'parentNodeId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'parentNodeId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateProjectFileNodesFromFilesMutation,
  CreateProjectFileNodesFromFilesMutationVariables
>;
export const CreateProjectFolderNodeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateProjectFolderNode' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'parentNodeId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createProjectFolderNode' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'parentNodeId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'parentNodeId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'siteId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateProjectFolderNodeMutation,
  CreateProjectFolderNodeMutationVariables
>;
export const CreateSystemFolderNodeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateSystemFolderNode' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateSystemFolderNodeInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createSystemFolderNode' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
                { kind: 'Field', name: { kind: 'Name', value: 'parentNodeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'createdUser' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'lastDownloadedAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lastDownloadedUser' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateSystemFolderNodeMutation,
  CreateSystemFolderNodeMutationVariables
>;
export const UpdateProcessStepDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateProcessStep' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'processId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'stepName' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addProcessStep' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'processId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'processId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'stepName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'stepName' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'AnnotationProcess' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateProcessStepMutation, UpdateProcessStepMutationVariables>;
export const UpdateProcessImagesStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateProcessImagesStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'annotationProcessImageStatusIds' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submitAnnotationProcessImagesStatus' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'annotationProcessImageStatusIds' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'annotationProcessImageStatusIds' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'status' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateProcessImagesStatusMutation,
  UpdateProcessImagesStatusMutationVariables
>;
export const UpdatePanoramicImageTransformDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdatePanoramicImageTransform' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'renderObjectId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fileId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'position' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'rotation' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatePanoramicRenderObjectImagePosition' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'renderObjectId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'renderObjectId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fileId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fileId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'relativePosition' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'position' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'relativeRotation' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'rotation' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'PanoramicRenderObject' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdatePanoramicImageTransformMutation,
  UpdatePanoramicImageTransformMutationVariables
>;
export const UpdatePanoramicImagesStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdatePanoramicImagesStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'panoramicProcessImageStatusIds' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submitPanoramicProcessImagesStatus' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'panoramicProcessImageStatusIds' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'panoramicProcessImageStatusIds' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'status' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdatePanoramicImagesStatusMutation,
  UpdatePanoramicImagesStatusMutationVariables
>;
export const DebugCreateImageTilesByFileIdsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DebugCreateImageTilesByFileIds' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fileIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createImageTilesForPersistedImagesByIds' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fileIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fileIds' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DebugCreateImageTilesByFileIdsMutation,
  DebugCreateImageTilesByFileIdsMutationVariables
>;
export const AbortMultiPartUploadDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AbortMultiPartUpload' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bucket' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'key' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uploadId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'abortMultipartUpload' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bucket' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bucket' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'key' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'key' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uploadId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uploadId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AbortMultiPartUploadMutation, AbortMultiPartUploadMutationVariables>;
export const CreateMultipartUploadForFileImportRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateMultipartUploadForFileImportRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dataImportRequestId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fileImportRequestId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createMultipartUploadForFileImportRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dataImportRequestId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dataImportRequestId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fileImportRequestId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fileImportRequestId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'bucket' } },
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'uploadId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateMultipartUploadForFileImportRequestMutation,
  CreateMultipartUploadForFileImportRequestMutationVariables
>;
export const CreatePresignedUploadPartUrlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreatePresignedUploadPartUrl' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bucket' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'key' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'partNumber' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uploadId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createPresignedUploadPartUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bucket' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bucket' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'key' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'key' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'partNumber' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'partNumber' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uploadId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uploadId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreatePresignedUploadPartUrlMutation,
  CreatePresignedUploadPartUrlMutationVariables
>;
export const CompleteMultipartUploadDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CompleteMultipartUpload' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bucket' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'key' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'parts' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'MultiPartInput' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uploadId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'completeMultipartUpload' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bucket' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bucket' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'key' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'key' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'parts' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'parts' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uploadId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uploadId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CompleteMultipartUploadMutation,
  CompleteMultipartUploadMutationVariables
>;
export const CreateDataImportRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateDataImportRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'featureType' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'persistType' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'priority' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'rootFolderIdempotencyKeyAndName' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FolderIdempotencyKeyAndNameInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'systemFolderNodeId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createDataImportRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'featureType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'featureType' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'persistType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'persistType' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'priority' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'priority' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'rootFolderIdempotencyKeyAndName' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'rootFolderIdempotencyKeyAndName' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'systemFolderNodeId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'systemFolderNodeId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateDataImportRequestMutation,
  CreateDataImportRequestMutationVariables
>;
export const CreateDataImportRequestV2Document = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateDataImportRequestV2' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dependants' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'SystemNodeDependantInput' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'featureType' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'persistType' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'priority' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'rootFolderIdempotencyKeyAndName' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FolderIdempotencyKeyAndNameInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'systemFolderNodeId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createDataImportRequestV2' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dependants' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dependants' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'featureType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'featureType' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'persistType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'persistType' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'priority' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'priority' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'rootFolderIdempotencyKeyAndName' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'rootFolderIdempotencyKeyAndName' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'systemFolderNodeId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'systemFolderNodeId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateDataImportRequestV2Mutation,
  CreateDataImportRequestV2MutationVariables
>;
export const UpdateStatusForDataImportRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateStatusForDataImportRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dataImportRequestId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateStatusForDataImportRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'status' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dataImportRequestId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dataImportRequestId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateStatusForDataImportRequestMutation,
  UpdateStatusForDataImportRequestMutationVariables
>;
export const CreateFolderImportRequestForDataImportRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateFolderImportRequestForDataImportRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dataImportRequestId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'parentFolderNodeIdempotencyKey' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'childFolderNodeIdempotencyKeyAndNames' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'FolderIdempotencyKeyAndNameInput' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createFolderImportRequestForDataImportRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dataImportRequestId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dataImportRequestId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'parentFolderNodeIdempotencyKey' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'parentFolderNodeIdempotencyKey' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'childFolderNodeIdempotencyKeyAndNames' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'childFolderNodeIdempotencyKeyAndNames' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateFolderImportRequestForDataImportRequestMutation,
  CreateFolderImportRequestForDataImportRequestMutationVariables
>;
export const UpdateStatusForFolderImportRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateStatusForFolderImportRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateStatusForFolderImportRequestInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateStatusForFolderImportRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateStatusForFolderImportRequestMutation,
  UpdateStatusForFolderImportRequestMutationVariables
>;
export const CreateFileNodeForFolderImportRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateFileNodeForFolderImportRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dataImportRequestId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'fileIdempotencyKeyStorageAndMetadata' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'FileIdempotencyKeyStorageAndMetadataInput' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'folderImportRequestId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createFileNodeForFolderImportRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dataImportRequestId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dataImportRequestId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fileIdempotencyKeyStorageAndMetadata' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'fileIdempotencyKeyStorageAndMetadata' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'folderImportRequestId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'folderImportRequestId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateFileNodeForFolderImportRequestMutation,
  CreateFileNodeForFolderImportRequestMutationVariables
>;
export const CreateFolderNodeForFolderImportRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateFolderNodeForFolderImportRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'childFolderImportRequestIds' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dataImportRequestId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'parentFolderImportRequestId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createFolderNodeForFolderImportRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'childFolderImportRequestIds' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'childFolderImportRequestIds' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dataImportRequestId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dataImportRequestId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'parentFolderImportRequestId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'parentFolderImportRequestId' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateFolderNodeForFolderImportRequestMutation,
  CreateFolderNodeForFolderImportRequestMutationVariables
>;
export const TrackFilesForFolderImportRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'TrackFilesForFolderImportRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dataImportRequestId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'folderImportRequestId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'files' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'FileItemInput' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'trackFilesForFolderImportRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dataImportRequestId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dataImportRequestId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'folderImportRequestId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'folderImportRequestId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'files' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'files' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  TrackFilesForFolderImportRequestMutation,
  TrackFilesForFolderImportRequestMutationVariables
>;
export const CreateMultipartUploadDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateMultipartUpload' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fileName' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createMultipartUpload' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fileName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fileName' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'bucket' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fileId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'uploadId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateMultipartUploadMutation, CreateMultipartUploadMutationVariables>;
export const CreateFileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateFile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bucket' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fileId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fileKind' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'FileKindInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'key' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'size' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createFile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accountId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bucket' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bucket' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fileId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fileId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fileKind' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fileKind' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'key' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'key' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'size' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'size' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'type' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AssetFile' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ImageFile' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjFile' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Ortho2dFile' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Tileset3dFile' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateFileMutation, CreateFileMutationVariables>;
export const CreateImageThumbnailsForPersistedImagesByIdsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateImageThumbnailsForPersistedImagesByIds' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fileIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createImageThumbnailsForPersistedImagesByIds' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fileIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fileIds' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateImageThumbnailsForPersistedImagesByIdsMutation,
  CreateImageThumbnailsForPersistedImagesByIdsMutationVariables
>;
export const CreateModelDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateModel' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'modelName' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createModel' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'modelName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'modelName' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateModelMutation, CreateModelMutationVariables>;
export const TriggerTrainV2Document = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'TriggerTrainV2' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'request' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'TriggerTrainV2Input' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'triggerTrainV2' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'request' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'request' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TriggerTrainV2Mutation, TriggerTrainV2MutationVariables>;
export const TriggerPredictionV2Document = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'TriggerPredictionV2' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'request' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'TriggerPredictionV2Input' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'triggerPredictionV2' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'request' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'request' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'predicted_boxes' } },
                { kind: 'Field', name: { kind: 'Name', value: 'predicted_labels' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TriggerPredictionV2Mutation, TriggerPredictionV2MutationVariables>;
export const TileImagesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'TileImages' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fileIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createImageTilesForPersistedImagesByIds' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fileIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fileIds' } },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createImageThumbnailsForPersistedImagesByIds' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fileIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fileIds' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TileImagesMutation, TileImagesMutationVariables>;
export const UpdateImageProjectionRenderObjectImagesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateImageProjectionRenderObjectImages' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'processId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'renderObjectId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'images' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'ImageProjectionRenderObjectImage' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateImageProjectionRenderObjectImages' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'processId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'processId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'renderObjectId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'renderObjectId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'images' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'images' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ImageProjectionRenderObject' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateImageProjectionRenderObjectImagesMutation,
  UpdateImageProjectionRenderObjectImagesMutationVariables
>;
export const UpdateImageProjectionRenderObjectCameraModelsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateImageProjectionRenderObjectCameraModels' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'renderObjectId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cameraModels' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'CameraModel' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateImageProjectionRenderObjectCameraModels' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'renderObjectId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'renderObjectId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'cameraModels' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'cameraModels' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ImageProjectionRenderObject' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateImageProjectionRenderObjectCameraModelsMutation,
  UpdateImageProjectionRenderObjectCameraModelsMutationVariables
>;
export const CreateLinkNodesBySourceNodeIdsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateLinkNodesBySourceNodeIds' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sourceNodeIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'parentNodeId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dependants' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'SystemNodeDependantInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createLinkNodesBySourceNodeIds' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sourceNodeIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sourceNodeIds' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'parentNodeId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'parentNodeId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dependants' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dependants' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateLinkNodesBySourceNodeIdsMutation,
  CreateLinkNodesBySourceNodeIdsMutationVariables
>;
export const UpdateSystemNodeNameDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateSystemNodeName' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'systemNodeId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateSystemNodeName' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'systemNodeId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'systemNodeId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateSystemNodeNameMutation, UpdateSystemNodeNameMutationVariables>;
export const DownloadSystemNodesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DownloadSystemNodes' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'nodeIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'note' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'downloadSystemNodes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'nodeIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'nodeIds' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'note' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'note' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DownloadSystemNodesMutation, DownloadSystemNodesMutationVariables>;
export const UpsertPermissionPoliciesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertPermissionPolicies' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'permissionPolicies' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'PermissionPolicyInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertPermissionPolicies' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'permissionPolicies' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'permissionPolicies' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpsertPermissionPoliciesMutation,
  UpsertPermissionPoliciesMutationVariables
>;
export const DeletePermissionPoliciesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeletePermissionPolicies' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'permissionPolicies' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'PermissionPolicyInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deletePermissionPolicies' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'permissionPolicies' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'permissionPolicies' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeletePermissionPoliciesMutation,
  DeletePermissionPoliciesMutationVariables
>;
export const AccountByContextDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AccountByContext' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountByContext' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AccountByContextQuery, AccountByContextQueryVariables>;
export const UserByContextDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UserByContext' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'readUserByContext' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'name' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'first' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'last' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isAdmin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isSuperAdmin' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserByContextQuery, UserByContextQueryVariables>;
export const ListSubjectToObjectsPermissionPoliciesWithExpandedSubjectIdsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ListSubjectToObjectsPermissionPoliciesWithExpandedSubjectIds' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'subjectId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'objectIds' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'objectTypes' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'PolicyObjectTypeInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'actionTypes' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'PolicyActionTypeInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'listSubjectToObjectsPermissionPoliciesWithExpandedSubjectIds',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'subjectId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'subjectId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objectIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'objectIds' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objectTypes' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'objectTypes' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'actionTypes' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'actionTypes' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subjectId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subjectType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'objectId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'objectType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'actionType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ListSubjectToObjectsPermissionPoliciesWithExpandedSubjectIdsQuery,
  ListSubjectToObjectsPermissionPoliciesWithExpandedSubjectIdsQueryVariables
>;
export const ListProjectsByAccountContextDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ListProjectsByAccountContext' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'listProjectsByAccountContext' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'geoid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'projectGroupId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
                { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rootFolderNodeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'annotationsRootFolderNodeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'renderObjectsRootFolderNodeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userFilesRootFolderNodeId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ListProjectsByAccountContextQuery,
  ListProjectsByAccountContextQueryVariables
>;
export const FindProjectByProjectIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FindProjectByProjectId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'readProjectById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'geoid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'projectGroupId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
                { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rootFolderNodeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'annotationsRootFolderNodeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'renderObjectsRootFolderNodeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userFilesRootFolderNodeId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FindProjectByProjectIdQuery, FindProjectByProjectIdQueryVariables>;
export const ListProjectGroupsByAccountContextDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ListProjectGroupsByAccountContext' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'listProjectGroupsByAccountContext' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ListProjectGroupsByAccountContextQuery,
  ListProjectGroupsByAccountContextQueryVariables
>;
export const ListLayerByProjectIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ListLayerByProjectId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'listLayersByProjectId' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'captureDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'worldPosition' } },
                { kind: 'Field', name: { kind: 'Name', value: 'worldRotation' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mainSceneEntityId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'formatType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'defaultMaxHeight' } },
                { kind: 'Field', name: { kind: 'Name', value: 'defaultMinHeight' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ListLayerByProjectIdQuery, ListLayerByProjectIdQueryVariables>;
export const ListAnnotationsByAnnotationGroupIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ListAnnotationsByAnnotationGroupId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'annotationGroupId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'listAnnotationsByAnnotationGroupId' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'annotationGroupId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'annotationGroupId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'templateId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'annotationId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdByUserId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'annotation2d' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'imageFileId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'points' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'y' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'shapeType' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'annotation3d' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'positions' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'y' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'z' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rotations' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'w' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'y' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'z' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'shapeType' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fields' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'AnnotationDateField' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'fieldId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'end' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'start' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'AnnotationFileField' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'fieldId' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'files' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'fileId' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'AnnotationImageField' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'fieldId' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'file' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'ImageFile' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: '__typename' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'fileName' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'originalUrl' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'thumbnailUrl' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'fileId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'AnnotationSelectField' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'fieldId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'optionId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'AnnotationTextField' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'fieldId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'AnnotationUrlField' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'fieldId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'urls' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ListAnnotationsByAnnotationGroupIdQuery,
  ListAnnotationsByAnnotationGroupIdQueryVariables
>;
export const FindActiveVersionByAnnotationIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FindActiveVersionByAnnotationId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'annotationId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'findActiveVersionByAnnotationId' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'annotationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'annotationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'templateId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'annotationId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'annotation2d' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'imageFileId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'points' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'y' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'shapeType' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'annotation3d' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'positions' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'y' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'z' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rotations' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'w' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'y' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'z' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'shapeType' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fields' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'AnnotationDateField' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'fieldId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'end' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'start' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'AnnotationFileField' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'fieldId' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'files' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'fileId' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'AnnotationImageField' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'fieldId' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'file' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'ImageFile' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: '__typename' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'fileName' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'originalUrl' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'thumbnailUrl' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'fileId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'AnnotationSelectField' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'fieldId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'optionId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'AnnotationTextField' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'fieldId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'AnnotationUrlField' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'fieldId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'urls' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FindActiveVersionByAnnotationIdQuery,
  FindActiveVersionByAnnotationIdQueryVariables
>;
export const ListHistoryByAnnotationIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ListHistoryByAnnotationId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'annotationId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'listHistoryByAnnotationId' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'annotationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'annotationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isActiveVersion' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdByUserId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'annotationId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'annotation2d' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'imageFileId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'points' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'y' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'shapeType' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'annotation3d' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'positions' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'y' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'z' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rotations' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'w' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'y' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'z' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'shapeType' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fields' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'AnnotationDateField' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'fieldId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'end' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'start' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'AnnotationFileField' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'fieldId' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'files' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'fileId' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'AnnotationImageField' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'fieldId' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'file' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'ImageFile' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: '__typename' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'fileName' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'originalUrl' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'thumbnailUrl' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'fileId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'AnnotationSelectField' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'fieldId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'optionId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'AnnotationTextField' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'fieldId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'AnnotationUrlField' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'fieldId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'urls' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ListHistoryByAnnotationIdQuery,
  ListHistoryByAnnotationIdQueryVariables
>;
export const CountActiveAnnotationsByTemplateIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CountActiveAnnotationsByTemplateId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'templateId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filterDeleted' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'countActiveAnnotationsByTemplateId' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'templateId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'templateId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filterDeleted' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filterDeleted' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CountActiveAnnotationsByTemplateIdQuery,
  CountActiveAnnotationsByTemplateIdQueryVariables
>;
export const ListAnnotationTemplatesByProjectIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ListAnnotationTemplatesByProjectId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'listAnnotationTemplatesByProjectId' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'projectId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'level' } },
                { kind: 'Field', name: { kind: 'Name', value: 'colorFromField' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fields' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'AnnotationTemplateTextField' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isDeleted' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isVisible' } },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'AnnotationTemplateFileField' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isDeleted' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isVisible' } },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'AnnotationTemplateUrlField' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isDeleted' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isVisible' } },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'AnnotationTemplateDateField' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isDeleted' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isVisible' } },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'AnnotationTemplateSelectField' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isDeleted' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isVisible' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'options' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'AnnotationTemplateImageField' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isDeleted' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isVisible' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdByUserId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedByUserId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ListAnnotationTemplatesByProjectIdQuery,
  ListAnnotationTemplatesByProjectIdQueryVariables
>;
export const ListAnnotationTemplatesByAccountContextDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ListAnnotationTemplatesByAccountContext' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'listAnnotationTemplatesByAccountContext' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'projectId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'level' } },
                { kind: 'Field', name: { kind: 'Name', value: 'colorFromField' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fields' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'AnnotationTemplateTextField' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isDeleted' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isVisible' } },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'AnnotationTemplateFileField' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isDeleted' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isVisible' } },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'AnnotationTemplateUrlField' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isDeleted' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isVisible' } },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'AnnotationTemplateDateField' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isDeleted' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isVisible' } },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'AnnotationTemplateSelectField' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isDeleted' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isVisible' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'options' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'AnnotationTemplateImageField' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isDeleted' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isVisible' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdByUserId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedByUserId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ListAnnotationTemplatesByAccountContextQuery,
  ListAnnotationTemplatesByAccountContextQueryVariables
>;
export const ListSceneEntitiesByProjectIdAndRendererTypesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ListSceneEntitiesByProjectIdAndRendererTypes' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'rendererTypes' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'listSceneEntitiesByProjectIdAndRendererTypes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'rendererTypes' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'rendererTypes' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'position' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'y' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'z' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rotation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'y' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'z' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'w' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'rendererType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'parentSceneEntityId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'renderObject' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'KmlRenderObject' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'file' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'AssetFile' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'signedGetObjectUrl' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'IfcRenderObject' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'epsgCode' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'file' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'AssetFile' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'signedGetObjectUrl' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'storage' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'size' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'DxfRenderObject' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'epsgCode' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'file' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'AssetFile' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'signedGetObjectUrl' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'Tileset3dRenderObject' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'file' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'Tileset3dFile' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'httpUrl' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'process' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'Tileset3dProcess' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'lastStepIsDone' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'Ortho2dRenderObject' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'process' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'Ortho2dProcess' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'steps' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'stepName' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'comment' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'file' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'Ortho2dFile' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'httpUrl' } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'AssetFile' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'httpUrl' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'ObjRenderObject' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'epsgCode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'process' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'ObjProcess' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'steps' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'stepName' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'comment' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'file' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'ObjFile' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'httpUrl' } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'AssetFile' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'httpUrl' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'CesiumIonRenderObject' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'cesiumIonId' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'process' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'CesiumIonProcess' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'steps' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'stepName' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'comment' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'PanoramicRenderObject' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'processId' } },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'ImageProjectionRenderObject' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'processId' } },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'AnnotationRenderObject' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'relativePosition' } },
                { kind: 'Field', name: { kind: 'Name', value: 'relativeRotation' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ListSceneEntitiesByProjectIdAndRendererTypesQuery,
  ListSceneEntitiesByProjectIdAndRendererTypesQueryVariables
>;
export const ListRenderObjectsByIdsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ListRenderObjectsByIds' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'renderObjectIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'listRenderObjectByIds' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'renderObjectIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'renderObjectIds' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'PanoramicRenderObject' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'processId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'panoramicImages' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'relativePosition' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'relativeRotation' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'fileId' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'file' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'ImageFile' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: '__typename' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'fileName' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'createThumbnailEvent' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'status' },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'createTilesEvent' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'status' },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'tiles' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'rows' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'cols' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ImageProjectionRenderObject' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'processId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cameraModels' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'imageSize' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'principalPoint' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'distortion' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'k1' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'k2' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'k3' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'p1' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'p2' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'focalLength' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'projectionString' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'sensorSize' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'skew' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'images' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'cameraModelId' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cameraRelativePosition' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cameraRelativeRotation' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'projectedRelativePosition' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'projectedRelativeRotation' },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'fileId' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'file' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'ImageFile' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: '__typename' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'fileName' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'createThumbnailEvent' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'status' },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'createTilesEvent' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'status' },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'tiles' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'rows' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'cols' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ListRenderObjectsByIdsQuery, ListRenderObjectsByIdsQueryVariables>;
export const GetImageUrlsByFileIdsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetImageUrlsByFileIds' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fileIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'filesByIds' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fileIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fileIds' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ImageFile' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'signedGetObjectUrl' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetImageUrlsByFileIdsQuery, GetImageUrlsByFileIdsQueryVariables>;
export const GetDownloadUrlsByFileIdsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetDownloadUrlsByFileIds' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fileIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'filesByIds' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fileIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fileIds' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AssetFile' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'signedGetObjectDownloadUrl' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ImageFile' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'signedGetObjectDownloadUrl' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjFile' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'signedGetObjectDownloadUrl' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Ortho2dFile' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'signedGetObjectDownloadUrl' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Tileset3dFile' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'signedGetObjectDownloadUrl' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetDownloadUrlsByFileIdsQuery, GetDownloadUrlsByFileIdsQueryVariables>;
export const GetImageThumbnailsByFileIdsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetImageThumbnailsByFileIds' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fileIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'filesByIds' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fileIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fileIds' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ImageFile' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'thumbnailUrl' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetImageThumbnailsByFileIdsQuery,
  GetImageThumbnailsByFileIdsQueryVariables
>;
export const GetUserByAccountIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetUserByAccountId' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userByAccountId' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUserByAccountIdQuery, GetUserByAccountIdQueryVariables>;
export const DeleteAnnotationTemplateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteAnnotationTemplate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'annotationTemplateId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteAnnotationTemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'annotationTemplateId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'annotationTemplateId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteAnnotationTemplateMutation,
  DeleteAnnotationTemplateMutationVariables
>;
export const ListShareLinksByProjectIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ListShareLinksByProjectId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'listShareLinksByProjectId' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shareToken' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'projectId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isActivated' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdByUserId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedByUserId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ListShareLinksByProjectIdQuery,
  ListShareLinksByProjectIdQueryVariables
>;
export const ListPermissionPoliciesWithAccountContextDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ListPermissionPoliciesWithAccountContext' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'subjectId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'objectId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'subjectType' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PolicySubjectTypeInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'objectType' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PolicyObjectTypeInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'actionType' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PolicyActionTypeInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'listPermissionPoliciesWithAccountContext' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'subjectId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'subjectId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objectId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'objectId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'subjectType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'subjectType' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objectType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'objectType' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'actionType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'actionType' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subjectId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subjectType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'objectId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'objectType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'actionType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ListPermissionPoliciesWithAccountContextQuery,
  ListPermissionPoliciesWithAccountContextQueryVariables
>;
export const ListSystemNodesByParentNodeIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ListSystemNodesByParentNodeId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pageIndex' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'parentNodeId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'searchTerm' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'includeMigratedData' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'nodeKinds' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'SystemNodeKindInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'listSystemNodesByParentNodeId' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pageSize' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pageIndex' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'pageIndex' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'parentNodeId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'parentNodeId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'searchTerm' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'searchTerm' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'includeMigratedData' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'includeMigratedData' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'nodeKinds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'nodeKinds' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalNumberOfPages' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalNumberOfFiles' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'FileNode' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'parentNodeId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'createdUser' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastDownloadedAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lastDownloadedUser' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'fileId' } },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'FolderNode' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'parentNodeId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'createdUser' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastDownloadedAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lastDownloadedUser' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'LinkNode' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'sourceNodeId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'parentNodeId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastDownloadedAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'createdUser' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lastDownloadedUser' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sourceNode' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'FileNode' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: '__typename' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'parentNodeId' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'createdAt' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'lastDownloadedAt' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'fileId' } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'FolderNode' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: '__typename' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'parentNodeId' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'createdAt' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'lastDownloadedAt' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ListSystemNodesByParentNodeIdQuery,
  ListSystemNodesByParentNodeIdQueryVariables
>;
export const ParentNodesChainByParentNodeIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ParentNodesChainByParentNodeId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'parentNodeId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parentNodesChainByParentNodeId' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'parentNodeId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'parentNodeId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
                { kind: 'Field', name: { kind: 'Name', value: 'parentNodeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastDownloadedAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ParentNodesChainByParentNodeIdQuery,
  ParentNodesChainByParentNodeIdQueryVariables
>;
export const FileSignedGetObjectDownloadUrlByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FileSignedGetObjectDownloadUrlById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fileId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'file' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fileId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fileId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AssetFile' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'signedGetObjectDownloadUrl' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ImageFile' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'signedGetObjectDownloadUrl' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjFile' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'signedGetObjectDownloadUrl' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Ortho2dFile' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'signedGetObjectDownloadUrl' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Tileset3dFile' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'signedGetObjectDownloadUrl' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FileSignedGetObjectDownloadUrlByIdQuery,
  FileSignedGetObjectDownloadUrlByIdQueryVariables
>;
export const ListUsersByAccountIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ListUsersByAccountId' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'listUsersByAccountId' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'name' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'first' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'last' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ListUsersByAccountIdQuery, ListUsersByAccountIdQueryVariables>;
export const GetImageFileByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetImageFileById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fileIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'filesByIds' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fileIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fileIds' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ImageFile' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'originalUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'signedGetObjectUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'thumbnailUrl' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetImageFileByIdQuery, GetImageFileByIdQueryVariables>;
export const ListFeatureEntitlementsByAccountContextDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ListFeatureEntitlementsByAccountContext' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'listFeatureEntitlementsByAccountContext' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'infinity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'used' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ListFeatureEntitlementsByAccountContextQuery,
  ListFeatureEntitlementsByAccountContextQueryVariables
>;
export const GetFilesByIdsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetFilesByIds' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fileIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'filesByIds' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fileIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fileIds' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AssetFile' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'signedGetObjectUrl' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'signedGetObjectDownloadUrl' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'storage' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ImageFile' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'signedGetObjectUrl' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'signedGetObjectDownloadUrl' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'storage' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjFile' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'signedGetObjectUrl' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'signedGetObjectDownloadUrl' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'storage' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Ortho2dFile' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'signedGetObjectUrl' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'signedGetObjectDownloadUrl' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'storage' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Tileset3dFile' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'signedGetObjectUrl' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'signedGetObjectDownloadUrl' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'storage' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetFilesByIdsQuery, GetFilesByIdsQueryVariables>;
export const ListImageTilesByFileIdsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ListImageTilesByFileIds' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fileIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'listImageTilesByFileIds' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fileIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fileIds' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'fileId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tilesWithSignedUrls' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'row' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'col' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ListImageTilesByFileIdsQuery, ListImageTilesByFileIdsQueryVariables>;
export const ListManualProcessesByProjectIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ListManualProcessesByProjectId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'listManualProcessesByProjectId' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'AnnotationProcess' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'captureId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastStep' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'annotationIds' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'imagesWithStatus' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'fileId' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'PanoramicProcess' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'captureId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastStep' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'imagesWithStatus' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'fileId' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ListManualProcessesByProjectIdQuery,
  ListManualProcessesByProjectIdQueryVariables
>;
export const AccountV2ByContextDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AccountV2ByContext' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'readAccountByContext' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'branding' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'webappBannerFileId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'webappBannerFile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'ImageFile' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'originalUrl' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AccountV2ByContextQuery, AccountV2ByContextQueryVariables>;
export const CheckSuperAdminDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CheckSuperAdmin' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'checkSuperAdmin' } }],
      },
    },
  ],
} as unknown as DocumentNode<CheckSuperAdminQuery, CheckSuperAdminQueryVariables>;
export const FindDataImportRequestByDataImportRequestIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FindDataImportRequestByDataImportRequestId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dataImportRequestId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'findDataImportRequestByDataImportRequestId' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dataImportRequestId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'rootFolderImportRequestId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FindDataImportRequestByDataImportRequestIdQuery,
  FindDataImportRequestByDataImportRequestIdQueryVariables
>;
export const CountFileImportRequestsByDataImportRequestIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CountFileImportRequestsByDataImportRequestId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dataImportRequestId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fileKind' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'countFileImportRequestsByDataImportRequestId' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dataImportRequestId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dataImportRequestId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fileKind' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fileKind' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CountFileImportRequestsByDataImportRequestIdQuery,
  CountFileImportRequestsByDataImportRequestIdQueryVariables
>;
export const FindFileImportRequestsByDataImportRequestIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FindFileImportRequestsByDataImportRequestId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dataImportRequestId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fileKind' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'start' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'size' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'findFileImportRequestsByDataImportRequestId' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dataImportRequestId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dataImportRequestId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fileKind' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fileKind' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'start' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'start' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'size' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'size' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fileId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FindFileImportRequestsByDataImportRequestIdQuery,
  FindFileImportRequestsByDataImportRequestIdQueryVariables
>;
export const ListModelsByAccountIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ListModelsByAccountId' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'listModelsByAccountId' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ListModelsByAccountIdQuery, ListModelsByAccountIdQueryVariables>;
export const ListTrainingsByModelIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ListTrainingsByModelId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'modelId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'listTrainingsByModelId' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'modelId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'modelId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modelId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startTime' } },
                { kind: 'Field', name: { kind: 'Name', value: 'duration' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ListTrainingsByModelIdQuery, ListTrainingsByModelIdQueryVariables>;
export const GetZipRequestByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetZipRequestById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'zipRequestId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'structuredZipRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'structuredZipRequestId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'zipRequestId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'signedGetObjectUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                { kind: 'Field', name: { kind: 'Name', value: 'note' } },
                { kind: 'Field', name: { kind: 'Name', value: 'expiresAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetZipRequestByIdQuery, GetZipRequestByIdQueryVariables>;
export const ReadProcessByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ReadProcessById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'processId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'readProcessById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'processId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'processId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'PanoramicProcess' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'imagesWithStatus' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'fileId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'formatFile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'AssetFile' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'signedGetObjectUrl' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ImageProjectionProcess' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'imagesWithStatus' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'fileId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'xmlFile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'AssetFile' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'signedGetObjectUrl' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReadProcessByIdQuery, ReadProcessByIdQueryVariables>;
export const FindSystemFileNodeByFileIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FindSystemFileNodeByFileId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fileId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'findSystemFileNodeByFileId' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fileId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fileId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FileNode' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'file' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'AssetFile' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'signedGetObjectDownloadUrl' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'storage' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'ImageFile' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'signedGetObjectDownloadUrl' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'storage' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'ObjFile' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'signedGetObjectDownloadUrl' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'storage' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'Ortho2dFile' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'signedGetObjectDownloadUrl' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'storage' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'Tileset3dFile' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'signedGetObjectDownloadUrl' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'storage' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FindSystemFileNodeByFileIdQuery,
  FindSystemFileNodeByFileIdQueryVariables
>;
export const ListUserGroupsByAccountContextDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ListUserGroupsByAccountContext' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'listUserGroupsByAccountContext' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'permissionPolicies' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'actionType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'objectId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'objectType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'subjectId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'subjectType' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userGroupToRolePolicies' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sourceId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sourceType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'targetId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'targetType' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userToUserGroupPolicies' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sourceId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sourceType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'targetId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'targetType' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ListUserGroupsByAccountContextQuery,
  ListUserGroupsByAccountContextQueryVariables
>;
export const ListActionTypesByObjectTypeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ListActionTypesByObjectType' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'objectType' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'listActionTypesByObjectType' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objectType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'objectType' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ListActionTypesByObjectTypeQuery,
  ListActionTypesByObjectTypeQueryVariables
>;
export const FindSystemNodeMostPermissiveActionTypeFromParentNodeChainWithSystemNodeIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {
        kind: 'Name',
        value: 'FindSystemNodeMostPermissiveActionTypeFromParentNodeChainWithSystemNodeId',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'systemNodeId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'findSystemNodeMostPermissiveActionTypeFromParentNodeChainWithSystemNodeId',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'systemNodeId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'systemNodeId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FindSystemNodeMostPermissiveActionTypeFromParentNodeChainWithSystemNodeIdQuery,
  FindSystemNodeMostPermissiveActionTypeFromParentNodeChainWithSystemNodeIdQueryVariables
>;
