import { queryClient } from '@/graphql/client';
import { resetBeforeClose, resetUploadStore, setIsOpen, useUploadStore } from '@/stores/upload';
import { cn } from '@/utils/classname';
import * as AlertDialog from '@radix-ui/react-alert-dialog';
import { Button } from '@skand/ui';
import {
  AwsMultipartUploaderFactory,
  useCreateNewUploadSession,
  useUploadSession,
} from '@skand/uploader';
import { useCallback, useEffect, useMemo, useState } from 'react';
import '../UploadLegacy/globals';
import { FileSection } from './FileSection/FileSection';
import { OptionSection } from './OptionSection';
import { TargetSection } from './TargetSection';
import {
  CHUNK_SIZE_OPTION,
  ChunkSizeOption,
  FAILURE_STAGES,
  PREPARED_STAGES,
  PREPARING_STAGES,
  UPLOADING_STAGES,
} from './constants';
import { useCreateProjectNodeFiles, useCreateProjectNodeFolder } from './mutations';
import { awsMultipartRequestService, uploadSessionRequestService } from './requestServices';
import { createProjectNodesFromFileTreeNode, displayErrors } from './utils';

export const Upload = () => {
  const [chunkSizeOption, setChunkSizeOption] = useState<ChunkSizeOption>('500M');
  const uploaderFactory = useMemo(() => {
    const chunkSize = CHUNK_SIZE_OPTION[chunkSizeOption];
    return new AwsMultipartUploaderFactory(chunkSize, awsMultipartRequestService);
  }, [chunkSizeOption]);

  const { uploadSession, createNewUploadSession } = useCreateNewUploadSession(
    uploaderFactory,
    uploadSessionRequestService,
    {
      pauseBeforeUploadFiles: true,
      rateLimiterOptions: { maxConcurrency: 5 },
    },
  );

  const isOpen = useUploadStore(state => state.isOpen);
  const projectId = useUploadStore(state => state.projectId);
  const projectParentNodeId = useUploadStore(state => state.projectParentNodeId);
  const targetNodeId = useUploadStore(state => state.targetNodeId);
  const beforeClose = useUploadStore(state => state.beforeClose);

  const [projectError, setProjectError] = useState<null | unknown>(null);
  const { error: uploadSessionError, stage } = useUploadSession(uploadSession);
  const error = (uploadSessionError || projectError) as null | Error;

  const canCancel = [
    ...PREPARING_STAGES,
    ...PREPARED_STAGES,
    ...UPLOADING_STAGES,
    ...FAILURE_STAGES,
  ].includes(stage);
  const canUpload = stage === 'fileImportRequests_creating_success';
  const canSave = stage === 'thumbnails_creating_success';
  const canChangeOptions = [...PREPARING_STAGES, ...PREPARED_STAGES].includes(stage);

  const createProjectNodeFiles = useCreateProjectNodeFiles();
  const createProjectNodeFolder = useCreateProjectNodeFolder();
  const createProjectFileAndFolderNodes = useCallback(async () => {
    const root = uploadSession.fileTree.getNode('/');
    if (projectId && root && root.type === 'folder') {
      try {
        await createProjectNodesFromFileTreeNode(
          uploadSession.fileTree,
          root,
          projectId,
          projectParentNodeId,
          createProjectNodeFolder,
          createProjectNodeFiles,
        );
      } catch (e) {
        setProjectError(e);
      }
    }
  }, [
    createProjectNodeFiles,
    createProjectNodeFolder,
    projectId,
    projectParentNodeId,
    uploadSession.fileTree,
  ]);

  const handleClose = useCallback(() => {
    beforeClose?.();
    setIsOpen(false);
    resetUploadStore();
    resetBeforeClose();
  }, [beforeClose]);

  const handleCancel = useCallback(() => {
    handleClose();
    uploadSession.abort();
    createNewUploadSession();
  }, [createNewUploadSession, handleClose, uploadSession]);

  const handleUpload = useCallback(() => {
    uploadSession.continueToUploadFiles();
  }, [uploadSession]);

  const handleRetry = useCallback(() => {
    if (projectError) {
      setProjectError(null);
      createProjectFileAndFolderNodes();
    } else if (uploadSessionError) {
      uploadSession.retry();
    }
  }, [createProjectFileAndFolderNodes, projectError, uploadSession, uploadSessionError]);

  useEffect(() => {
    const onAddFiles = () => {
      uploadSession.start({
        dependants: [],
        featureType: 'FILE_SYSTEM',
        parentSystemFolderNodeId: targetNodeId,
        persistType: 'IMMEDIATE',
        priority: 'IMMEDIATE',
      });
    };

    const onSuccess = () => {
      createProjectFileAndFolderNodes();
      queryClient.invalidateQueries(['LIST_SYSTEM_NODES_BY_PARENT_NODE_ID']);
    };

    const unsubscribeFileTreeChange = uploadSession.subscribeFileTreeChange(onAddFiles);
    const unsubscribeSuccess = uploadSession.subscribeSuccess(onSuccess);

    return () => {
      unsubscribeFileTreeChange();
      unsubscribeSuccess();
    };
  }, [createProjectFileAndFolderNodes, targetNodeId, uploadSession]);

  return (
    <AlertDialog.Root onOpenChange={setIsOpen} open={isOpen}>
      <AlertDialog.Portal>
        <AlertDialog.Overlay className="fixed inset-0 z-900" />
        <AlertDialog.Content
          className={cn(
            'b-1 b-neutral-600 b-solid',
            'bg-neutral-100',
            'fixed',
            'flex-col',
            'flex',
            'inset-t-50% inset-l-50%',
            'p-6',
            'rounded-2',
            'shadow-[0px_2px_2px_0px_rgba(0,0,0,0.15)]',
            'transform-translate--50%',
            'w-700px',
            'mt-auto mb-auto',
            'max-h-90vh',
            'overflow-auto',
            '[&_p]:mt-unset [&_p]:mb-unset [&_input]:transition-unset',
            'z-900',
          )}
          onEscapeKeyDown={e => e.preventDefault()}
        >
          <AlertDialog.Title className="color-neutral-800 typo-text-l">
            Upload new files
          </AlertDialog.Title>

          <TargetSection />
          <FileSection uploadSession={uploadSession} />
          <OptionSection
            chunkSizeOption={chunkSizeOption}
            isDisabled={!canChangeOptions}
            setChunkSizeOption={setChunkSizeOption}
          />

          <div className="mt-3">
            {error && (
              <p className="text-right color-alert-400 typo-text-s">
                Something went wrong during uploading. Please retry. <br />
                {displayErrors(error)}
              </p>
            )}
          </div>

          <div className="mt-3 flex flex-none justify-end gap-3">
            {canCancel && (
              <AlertDialog.Cancel asChild>
                <Button className="flex-1 cursor-pointer" onClick={handleCancel} size="s">
                  Cancel
                </Button>
              </AlertDialog.Cancel>
            )}

            {!error && !canSave && (
              <Button
                className="flex-1 cursor-pointer"
                disabled={!canUpload}
                filled
                onClick={handleUpload}
                primary
                size="s"
              >
                Upload files
              </Button>
            )}

            {!error && canSave && (
              <Button
                className="flex-1 cursor-pointer"
                filled
                onClick={handleCancel}
                primary
                size="s"
              >
                Save and close
              </Button>
            )}

            {error && (
              <Button
                className="flex-1 cursor-pointer"
                filled
                onClick={handleRetry}
                primary
                size="s"
              >
                Retry
              </Button>
            )}
          </div>
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  );
};
