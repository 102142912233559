import { PanoGroupIcon, PhotoGroupIcon } from '@/components/IconButton';
import { useShareLink } from '@/stores/shareLink';
import { PhotoGroup } from '@/stores/viewer';
import { cn } from '@/utils/classname';

export interface PhotoGroupNodeProps {
  group: PhotoGroup;
}
export const PhotoGroupNode = ({ group }: PhotoGroupNodeProps) => {
  const isPhoto2D = group.photos.length === 0 || group.photos[0].type === 'photo2D';
  const visiblePhotoGroupIds = useShareLink(state => state.visiblePhotoGroupIds);
  const isVisible = visiblePhotoGroupIds.has(group.id);

  const handleToggle = () => {
    if (visiblePhotoGroupIds.has(group.id)) {
      useShareLink.setState(prev => {
        const visiblePhotoGroupIds = new Set(prev.visiblePhotoGroupIds);
        visiblePhotoGroupIds.delete(group.id);
        return { visiblePhotoGroupIds };
      });
    } else {
      useShareLink.setState(prev => {
        const visiblePhotoGroupIds = new Set(prev.visiblePhotoGroupIds);
        visiblePhotoGroupIds.add(group.id);
        return { visiblePhotoGroupIds };
      });
    }
  };

  return (
    <div className={cn('flex justify-between items-center py-1')}>
      {isPhoto2D ? (
        <PhotoGroupIcon className="mr-2 text-3 color-neutral-600" />
      ) : (
        <PanoGroupIcon className="mr-2 text-3 color-neutral-600" />
      )}
      <div className={cn('flex items-center gap-2 text-neutral-600')}>
        <p className={cn('typo-text-small text-neutral-800 whitespace-nowrap')}>{group.name}</p>
        <div
          className="fixed right-0 h-32px flex flex-none items-center gap-2 bg-neutral-100 pl-2"
          style={{ boxShadow: '-8px 0px 8px -2px rgba(255,255,255,1)' }}
        >
          <div className={cn(isVisible ? 'i-skand-show' : 'i-skand-hide')} onClick={handleToggle} />
        </div>
      </div>
    </div>
  );
};
