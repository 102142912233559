import { Menu, MenuItem } from '@skand/ui';
import { SortKey } from './FilesTab';

export const SortByMenu = ({
  setSortKey,
  setIsSortByMenuOpen,
}: {
  setSortKey: (key: SortKey) => void;
  setIsSortByMenuOpen: (isOpen: boolean) => void;
}) => {
  return (
    <Menu className="absolute right-0 top-5 z1 w-28">
      <MenuItem
        className="cursor-pointer"
        onClick={() => {
          setSortKey('name');
          setIsSortByMenuOpen(false);
        }}
        size="s"
      >
        NAME
      </MenuItem>
      <MenuItem
        className="cursor-pointer"
        onClick={() => {
          setSortKey('createdAt');
          setIsSortByMenuOpen(false);
        }}
        size="s"
      >
        CREATED DATE
      </MenuItem>
      <MenuItem
        className="cursor-pointer"
        onClick={() => {
          setSortKey('updatedAt');
          setIsSortByMenuOpen(false);
        }}
        size="s"
      >
        LAST UPDATED
      </MenuItem>
    </Menu>
  );
};
