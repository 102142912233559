export const ANALYTICS_EVENT_OBJECT = {
  INITIATE_PROJECT_CREATION: 'initiate-project-creation',
  CREATE_PROJECT_NAME: 'create-project-name',
  CREATE_PROJECT_NEXT_STEP: 'create-project-next-step',
  FILE_SELECTION_TO_CREATE_PROJECT: 'file-selection-to-create-project',
  CREATE_PROJECT: 'create-project',
  LAYER_TREE: 'layer-tree',
  LAYER_TAB: 'layer-tab',
  FILE_TAB: 'file-tab',
  TEMPLATE_TAB: 'template-tab',
  SHARE_LINK_TAB: 'share-link-tab',
  PROCESS_TAB: 'process-tab',
  CREATE_SHARE_LINK: 'create-share-link',
  SHARE_LINK_CONTENT: 'share-link-content',
  SAVE_SHARE_LINK: 'save-share-link',
  CLOSE_PROJECT: 'close-project',
  IMAGE_VIEWER: 'image-viewer',
  IMAGE_VIEWER_2D_IMAGE: 'image-viewer-2d-image',
  IMAGE_VIEWER_PANORAMA: 'image-viewer-panorama',
  IMAGE_VIEWER_FILTER: 'image-viewer-filter',
  IMAGE_VIEWER_SETTINGS: 'image-viewer-settings',
  DRAW_TOOL_3D: 'draw-tool-3d',
  DRAW_TOOL_2D: 'draw-tool-2d',
  EDIT_ANNOTATION_2D: 'edit-annotation-2d',
  EDIT_ANNOTATION_3D: 'edit-annotation-3d',
  SHARE_ANNOTATION: 'share-annotation',
  DELETE_ANNOTATION: 'delete-annotation',
  FLY_TO_ANNOTATION: 'fly-to-annotation',
  DEPROJECTION_TOOL: 'deprojection-tool',
  ORTHOGRAPHIC_VIEW: 'orthographic-view',
  PERSPECTIVE_VIEW: 'perspective-view',
  GLOBE_CONTROL_MENU: 'globe-control-menu',
  VIEWER_SETTINGS: 'viewer-settings',
  ORBIT_MODE: 'orbit-mode',
  SUPERHERO_MODE: 'superhero-mode',
  VIEW_3D: 'view-3d',
  VIEW_PANORAMA: 'view-panorama',
  CLIPPING_TOOL: 'clipping-tool',
  LAYERING_TOOL: 'layering-tool',
  IFC_TOOL: 'ifc-tool',
  POINT_CLOUD_TOOL: 'point-cloud-tool',
  FLY_TO_LAYER: 'fly-to-layer',
};
export type AnalyticsEventObject = keyof typeof ANALYTICS_EVENT_OBJECT;
