import skand from '@/assets/skand.png';
import { cn } from '@skand/ui';

interface SplashProps {
  onFinish: () => void;
}

export const Splash = ({ onFinish }: SplashProps) => {
  const animationKeyframes = `
    @keyframes logoFade {
      0%   { opacity:0; }
      25%  { opacity:1; }
      75%  { opacity:1; }
      100% { opacity:0; }
    }
  `;

  return (
    <div
      className={cn(
        'h-full',
        'w-full',
        'left-0',
        'top-0',
        'absolute',
        'z-2',
        'pointer-events-none',
      )}
    >
      <style>{animationKeyframes}</style>
      <div
        className={cn(
          'relative',
          'display-flex',
          'top-50%',
          'transform-translate-y--50%',
          'justify-center',
          'animate-[logoFade]',
          'animate-forwards',
          'animate-duration-5s',
          'animate-ease-in-out',
        )}
        onAnimationEnd={onFinish}
      >
        <img src={skand} />
      </div>
    </div>
  );
};
