import { updatePhotoUrls, useViewer } from '@/stores/viewer';
import { cn } from '@/utils/classname';
import { Editor, Viewer2DAPI } from '@/utils/Viewer2D';
import { Progress, toast } from '@skand/ui';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Vector2 } from 'three';
import { Overlay } from './Overlay';

const Viewer2DCore = (_: unknown, ref: React.Ref<Viewer2DAPI | null>) => {
  const targetPhoto = useViewer(state => state.targetPhoto);
  const enabled2D = useViewer(state => state.enabled2D);
  const targetAnnotation2D = useViewer(state => state.targetAnnotation2D);
  const annotationGroups = useViewer(state => state.annotationGroups);
  const visibleAnnotations = useViewer(state => state.visibleAnnotations);
  const [element, setElement] = useState<HTMLCanvasElement | null>(null);
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [targetReady, setTargetReady] = useState(false);

  const [api2D, setAPI2D] = useState<Viewer2DAPI | null>(null);

  // Initialize 2D editor
  useEffect(() => {
    if (element === null) return;
    const editor = new Editor(element);

    const resizeObserver = new ResizeObserver(() => {
      const size = new Vector2(element.offsetWidth, element.offsetHeight);
      editor.setSize(size);
    });
    resizeObserver.observe(element);

    // Main loop
    let frame = 0;
    const update = () => {
      frame = requestAnimationFrame(update);
      editor.update();
    };
    update();

    setAPI2D({ editor });

    return () => {
      cancelAnimationFrame(frame);
    };
  }, [element]);

  // Expose Viewer2D API object via ref
  useImperativeHandle(ref, () => api2D, [api2D]);

  // Listen for changes in the target photo
  useEffect(() => {
    if (api2D === null || targetPhoto === null || !enabled2D) return;

    // Render target photo onto canvas
    const showTarget = async () => {
      api2D.editor.clear();

      // Show photo overlay
      setTargetReady(false);
      try {
        await updatePhotoUrls(targetPhoto);
        if (targetPhoto.thumbnailUrl && targetPhoto.tileset) {
          await api2D.editor.setPhoto(
            {
              thumbnailUrl: targetPhoto.thumbnailUrl,
              rows: targetPhoto.tileset.rows,
              cols: targetPhoto.tileset.cols,
              getTileURL: (row: number, col: number) =>
                targetPhoto.tileset?.tileURLs.get(`${row} ${col}`) ?? '',
            },
            setDownloadProgress,
          );
        } else if (targetPhoto.url) {
          await api2D.editor.setPhoto(targetPhoto.url, setDownloadProgress);
        }
      } catch (error) {
        toast({ type: 'warn', message: error as string, lifespan: 10000 });
      }
      setTargetReady(true);

      // Render annotations
      for (const group of annotationGroups) {
        for (const annotation of group.annotations) {
          if (annotation.sketch2D) {
            if (
              annotation.photo === targetPhoto &&
              visibleAnnotations.has(annotation.id) &&
              targetAnnotation2D !== annotation
            ) {
              api2D.editor.show(annotation.sketch2D);
            } else {
              api2D.editor.hide(annotation.sketch2D);
            }
          }
        }
      }
    };
    showTarget();
  }, [annotationGroups, api2D, targetPhoto, visibleAnnotations, targetAnnotation2D, enabled2D]);

  return (
    <div className={cn('h-full', 'w-full', 'flex', 'justify-center', 'items-center')}>
      <Overlay />
      {!targetReady && (
        <div
          className={cn(
            'h-full',
            'w-full',
            'flex',
            'flex-col',
            'items-center',
            'justify-center',
            'z-1',
            'pointer-events-none',
          )}
        >
          <p className={cn('color-neutral-200', 'typo-text-small')}>Select an image to view here</p>

          {downloadProgress > 0 && downloadProgress < 1 && (
            <div className="p-t-4">
              <Progress className="w-153px" progress={downloadProgress * 100} />
            </div>
          )}
        </div>
      )}
      <canvas className={cn('absolute', 'h-full', 'w-full', 'bg-neutral-800')} ref={setElement} />
    </div>
  );
};

export const Viewer2D = forwardRef(Viewer2DCore);
