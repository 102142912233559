import { cancelDownload, DownloadItem, retryDownload } from '@/stores/download';
import { cn, Progress } from '@skand/ui';

export interface DownloadItemRowProps {
  downloadItem: DownloadItem;
}

export const DownloadItemRow = ({ downloadItem }: DownloadItemRowProps) => {
  const { progress, name, error, url, linkNode } = downloadItem;
  const percent = progress * 100;

  return (
    <div className="w-full">
      <div
        className={cn(
          'w-full px-3 h-8 mb-3 bg-info-100 border border-info-400 border-solid	rounded flex flex-row',
        )}
      >
        <div className="flex items-center gap-[14px]">
          <div className="i-skand-info text-[12px] color-info-400" />
          <div>
            <p className="color-neutral-800 typo-text-s">
              {linkNode
                ? 'This will download the original file, not just a shortcut or link.'
                : "You'll receive an email once download has completed."}
            </p>
          </div>
        </div>
      </div>

      <div className="w-full flex flex-row">
        <p
          className="flex-1 overflow-hidden text-ellipsis whitespace-nowrap color-neutral-800 typo-text-s"
          title={name}
        >
          {name}
        </p>

        <div className="flex flex-row items-center gap-2">
          <Progress className="w-[162px]" progress={progress} />

          <div className="col-span-1 flex items-center justify-end justify-self-end gap-2">
            {!!error && (
              <div
                className="i-skand-undo cursor-pointer text-3 color-neutral-500 hover:color-neutral-800"
                onClick={() => retryDownload(url)}
              />
            )}

            <div
              className="i-skand-closesmall cursor-pointer text-3 color-neutral-500 hover:color-neutral-800"
              onClick={() => cancelDownload(url)}
            />

            <p className="w-8 color-neutral-500 typo-text-s">{Math.trunc(percent)}%</p>
          </div>
        </div>
      </div>
    </div>
  );
};
