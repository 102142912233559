import { ImageViewerSettings, Photo, Photo2D, PhotoGroup, useViewer } from '@/stores/viewer';
import { ViewerAPI } from '@skand/viewer-component-v2';
import { Quaternion, Vector3 } from 'three';
import { Process } from './process';

/**
 * Approximate a maximum subsample of a given photo set such that the distances between photos satisfy a minimum bound.
 *
 * @param points
 * @param minimumDistance
 * @returns
 */
const spatialSubsample = (objects: Photo[], minimumDistance: number) => {
  if (objects.length === 0) return [];

  const samples: Photo[] = [objects[0]]; // Seed
  for (const a of objects) {
    let inBounds = true;
    const aPosition = a.widget?.getPosition();

    // Photos with no 3D data always get included
    if (!aPosition) {
      samples.push(a);
      continue;
    }

    for (const b of samples) {
      const bPosition = b.widget?.getPosition();
      if (bPosition && aPosition.distanceTo(bPosition) < minimumDistance) {
        inBounds = false;
        break;
      }
    }
    if (inBounds) {
      samples.push(a);
    }
  }
  return samples;
};

/**
 * Compute photo priority key.
 *
 * @param photo
 * @param api3D
 * @param position
 * @returns
 */
const computePriorityKey = (photo: Photo2D, api3D: ViewerAPI, position: Vector3) => {
  if (photo.widget && photo.cameraModelId !== undefined) {
    const cameraModel = photo.group.cameraModels.get(photo.cameraModelId);
    if (cameraModel) {
      const loaderPhoto = {
        fileName: photo.name,
        position: photo.widget.getPosition(),
        rotation: photo.widget.getRotation(),
        cameraId: photo.cameraModelId,
      };

      // Compute FOV
      const fov = Math.atan(cameraModel.sensorSize / (2 * cameraModel.focalLength));

      // Check if point is in FOV range
      const expectedDirection = new Vector3(0, 0, -1)
        .applyQuaternion(loaderPhoto.rotation)
        .normalize();
      const receivedDirection = new Vector3()
        .subVectors(position, loaderPhoto.position)
        .normalize();
      const cosineAngle = expectedDirection.dot(receivedDirection);
      const inDirection = cosineAngle > Math.cos(fov);

      // Check if the projected point is in the bounds of the image
      const projected = api3D.deproject.project(position, loaderPhoto, cameraModel);
      const inBoundsX = projected.x >= 0 && projected.x <= cameraModel.imageSize.x;
      const inBoundsY = projected.y >= 0 && projected.y <= cameraModel.imageSize.y;

      if (inBoundsX && inBoundsY && inDirection) {
        const cosineDistance = 1 - cosineAngle;
        const cartesianDistance = 1 - 1 / position.distanceTo(loaderPhoto.position);
        return 0.7 * cosineDistance + 0.3 * cartesianDistance;
      }
    }
  }
  return Infinity;
};

/**
 * Sort photos based on projection point.
 *
 * @param position
 * @param photos
 * @returns
 */
export const sortByProjection = (api3D: ViewerAPI, position: Vector3, photos: Photo2D[]) => {
  // 2-phase sort keys
  const priority: Record<string, number> = {};
  const occlusion: Record<string, number> = {};

  let maxOcclusions = 0;
  for (const photo of photos) {
    const key = computePriorityKey(photo, api3D, position);
    if (key < Infinity) {
      maxOcclusions++;
    }

    priority[photo.id] = key;
    occlusion[photo.id] = Infinity;
  }

  // Presort images by priority
  const presort = photos.sort((a, b) => priority[a.id] - priority[b.id]);

  // Sample N high-priority photos and compute occlusion
  let N = 25;
  let i = 0;
  while (N > 0 && i < maxOcclusions) {
    const photo = presort[i];
    if (photo.widget && photo.cameraModelId !== undefined) {
      const loaderPhoto = {
        fileName: photo.name,
        position: photo.widget.getPosition(),
        rotation: photo.widget.getRotation(),
        cameraId: photo.cameraModelId,
      };
      if (!api3D.deproject.isPhotoOccluded(loaderPhoto, position)) {
        occlusion[photo.id] = priority[photo.id];
      }
      i += Math.floor((maxOcclusions - i) / N--);
    } else {
      i++;
    }
  }

  // Resort by occlusion
  return photos.sort((a, b) => occlusion[a.id] - occlusion[b.id]);
};

/**
 * Sort by distance to the user's camera.
 *
 * @param api3D
 * @param photos
 */
export const sortByNearby = (api3D: ViewerAPI, photos: Photo[]) => {
  const cameraPosition = api3D.navigation.getPosition();
  const distance: Record<string, number> = {};
  for (const photo of photos) {
    if (photo.widget) {
      const position = photo.widget.getPosition();
      distance[photo.id] = position.distanceTo(cameraPosition);
    } else {
      distance[photo.id] = 0;
    }
  }

  return photos.sort((a, b) => distance[a.id] - distance[b.id]);
};

/**
 * Filter photos.
 *
 * @param photoGroups
 * @param visiblePhotoGroups
 * @param filteredPhotos
 * @param settings
 * @param process
 * @returns
 */
export const filterPhotos = (
  photoGroups: PhotoGroup[],
  visiblePhotoGroups: Set<PhotoGroup['id']>,
  filteredPhotos: Set<Photo['id']>,
  process: Process | null,
  gapSize: number,
  showProcessDone: boolean,
  showProcessInProgress: boolean,
) => {
  let photos: Photo[] = [];
  for (const group of photoGroups) {
    if (visiblePhotoGroups.has(group.id)) {
      for (const photo of group.photos) {
        // Scene tree filter
        if (filteredPhotos.has(photo.id)) {
          continue;
        }
        // Filter photos by process, if available
        if (process && !process.images.has(photo.id)) {
          continue;
        }
        photos.push(photo);
      }
    }
  }

  // Filter by process status
  photos = photos.filter(photo => {
    const processImage = process?.images.get(photo.id);
    if (!processImage) return true;
    else {
      return (
        (showProcessDone && processImage.status === 'checked') ||
        (showProcessDone && processImage.status === 'ignored') ||
        (showProcessInProgress && processImage.status === 'created')
      );
    }
  });

  // Apply distance minimum gap distance
  if (gapSize) {
    photos = spatialSubsample(photos, gapSize);
  }
  return photos;
};

/**
 * Find the photo closest to current camera position.
 *
 * @param photoGroups
 * @param cameraPosition
 * @returns
 */
export const getClosestTarget = (photoGroups: PhotoGroup[], cameraPosition: Vector3) => {
  let minDistance = Infinity;
  let closestTarget: Photo | null = null;

  for (const group of photoGroups) {
    for (const target of group.photos) {
      if (target.widget) {
        const targetPosition = target.widget.getPosition();
        const distance = targetPosition.distanceTo(cameraPosition);

        if (distance < minDistance) {
          minDistance = distance;
          closestTarget = target;
        }
      }
    }
  }

  return closestTarget;
};

/** --- LEGACY ALGORITHMS --- */

/**
 * Sort photos by filename.
 *
 * @param photos
 * @returns
 */
const sortByName = (photos: Photo[]) => {
  return photos.sort((a, b) =>
    a.name.localeCompare(b.name, undefined, {
      numeric: true,
    }),
  );
};

/**
 * Sort photos based on the distance between its projection point to
 * the given position.
 *
 * @param position
 * @param photos
 * @returns
 */
const sortPhoto2DsByProjection = (position: Vector3, photos: Photo2D[]) => {
  const target = new Vector3(position.x, position.y, position.z);
  return photos
    .map(photo => {
      const distance = photo.projectedPosition?.distanceTo(target) ?? 0;
      return { photo, distance };
    })
    .sort((a, b) => a.distance - b.distance)
    .map(data => data.photo);
};

/**
 * Sort photos based on distance to point.
 *
 * @param position
 * @param photos
 * @param alpha
 * @returns
 */
const sortByDisplacement = (position: Vector3, photos: Photo[], alpha: number) => {
  const { api3D } = useViewer.getState();
  if (!api3D) return photos;

  const cameraDirection = new Vector3(0, 0, -1).applyQuaternion(api3D.navigation.getRotation());
  let maxDistance = 0;
  return photos
    .map(photo => {
      // Forward direction of the photo's camera
      const photoDirection = new Vector3(0, 0, -1)
        .applyQuaternion(photo.widget?.getRotation() ?? new Quaternion())
        .normalize();

      const cosine = photoDirection.dot(cameraDirection);
      const distance = photo.widget?.getPosition().distanceTo(position) ?? 0;
      maxDistance = Math.max(maxDistance, distance);

      return { photo, cosine, distance };
    })
    .map(({ photo, cosine, distance }) => {
      const normCosine = 1 - (cosine + 1) / 2;
      const normDistance = maxDistance === 0 ? 1 : distance / maxDistance;
      const metric = normCosine * alpha + normDistance * (1 - alpha);
      return { photo, metric };
    })
    .sort((a, b) => a.metric - b.metric)
    .map(data => data.photo);
};

/**
 * Sort photos by their similarity to the user camera.
 *
 * @param photos
 */
const sortBySimilarityToCamera = (photos: Photo[]) => {
  const { api3D } = useViewer.getState();
  if (!api3D) return photos;

  const cameraDirection = new Vector3(0, 0, -1).applyQuaternion(api3D.navigation.getRotation());
  const cameraPosition = api3D.navigation.getPosition();

  return photos
    .map(photo => {
      // Forward direction of the photo's camera
      const photoDirection = new Vector3(0, 0, -1)
        .applyQuaternion(photo.widget?.getRotation() ?? new Quaternion())
        .normalize();

      const cosine = photoDirection.dot(cameraDirection);
      const distance = photo.widget?.getPosition().distanceTo(cameraPosition) ?? 0;
      return { photo, cosine, distance };
    })
    .sort((a, b) => {
      const cosineDiff = -(a.cosine - b.cosine);
      const distanceDiff = a.distance - b.distance;

      if (Math.abs(cosineDiff) < 0.005) {
        return distanceDiff;
      } else {
        return cosineDiff;
      }
    })
    .map(data => data.photo);
};

/**
 * Sort photos by their similarity to the user camera using a weighted average
 * between euclidean distance and cosine distance (angular displacement).
 *
 * @param photos
 * @param alpha
 * @returns
 */
const sortByWeightedCameraSimilarity = (photos: Photo[], alpha: number) => {
  const { api3D } = useViewer.getState();
  if (!api3D) return photos;

  const cameraDirection = new Vector3(0, 0, -1).applyQuaternion(api3D.navigation.getRotation());
  const cameraPosition = api3D.navigation.getPosition();

  let maxDistance = 0;
  return photos
    .map(photo => {
      // Forward direction of the photo's camera
      const photoDirection = new Vector3(0, 0, -1)
        .applyQuaternion(photo.widget?.getRotation() ?? new Quaternion())
        .normalize();

      const cosine = photoDirection.dot(cameraDirection);
      const distance = photo.widget?.getPosition().distanceTo(cameraPosition) ?? 0;
      maxDistance = Math.max(maxDistance, distance);

      return { photo, cosine, distance };
    })
    .map(({ photo, cosine, distance }) => {
      const normCosine = 1 - (cosine + 1) / 2;
      const normDistance = maxDistance === 0 ? 1 : distance / maxDistance;
      const metric = normCosine * alpha + normDistance * (1 - alpha);
      return { photo, metric };
    })
    .sort((a, b) => a.metric - b.metric)
    .map(data => data.photo);
};

/**
 * Apply the filters and sort settings to a list of photos.
 *
 * @param photos
 * @param settings
 * @param canShow
 * @returns
 */
export const sortAndFilterRibbonPhotos = async (
  photoGroups: PhotoGroup[],
  visiblePhotoGroups: Set<PhotoGroup['id']>,
  filteredPhotos: Set<Photo['id']>,
  settings: ImageViewerSettings,
  canShow: boolean,
  process: Process | null,
) => {
  let ribbonPhotos: Photo[] = [];
  for (const group of photoGroups) {
    for (const photo of group.photos) {
      await photo.widget?.hide();
    }
    if (visiblePhotoGroups.has(group.id)) {
      for (const photo of group.photos) {
        // Scene tree filter
        if (filteredPhotos.has(photo.id)) {
          continue;
        }
        // Filter photos by process, if available
        if (process && !process.images.has(photo.id)) {
          continue;
        }
        ribbonPhotos.push(photo);
      }
    }
  }
  const totalPhotos = ribbonPhotos.length;

  // Filter by process status
  ribbonPhotos = ribbonPhotos.filter(photo => {
    const processImage = process?.images.get(photo.id);
    if (!processImage) return true;
    else {
      return (
        (settings.showProcessDone && processImage.status === 'checked') ||
        (settings.showProcessDone && processImage.status === 'ignored') ||
        (settings.showProcessInProgress && processImage.status === 'created')
      );
    }
  });

  // Apply distance minimum gap distance
  if (settings.gapSize) {
    ribbonPhotos = spatialSubsample(ribbonPhotos, settings.gapSize);
  }

  // Sort photos
  switch (settings.sortMode) {
    case 'name':
      ribbonPhotos = sortByName(ribbonPhotos);
      break;
    case 'distance to camera': {
      const { api3D } = useViewer.getState();
      if (api3D) {
        ribbonPhotos = sortByDisplacement(api3D.navigation.getPosition(), ribbonPhotos, 0);
      }
      break;
    }
    case 'distance to clicked point':
      ribbonPhotos = sortByDisplacement(settings.sortPoint, ribbonPhotos, 0);
      break;
    case 'relevance to point':
      ribbonPhotos = sortByDisplacement(settings.sortPoint, ribbonPhotos, settings.sortWeight);
      break;
    case 'similarity to camera':
      ribbonPhotos = sortBySimilarityToCamera(ribbonPhotos);
      break;
    case 'weighted similarity to camera':
      ribbonPhotos = sortByWeightedCameraSimilarity(ribbonPhotos, settings.sortWeight);
      break;
    case 'projected point':
      ribbonPhotos = sortPhoto2DsByProjection(settings.sortPoint, ribbonPhotos as Photo2D[]);
      break;
  }

  // Filter first count photos
  ribbonPhotos = ribbonPhotos.slice(0, settings.showAll ? totalPhotos : settings.count);

  // Show camera widgets
  if (canShow) {
    for (const photo of ribbonPhotos) {
      await photo.widget?.show();
    }
  }

  return ribbonPhotos;
};
