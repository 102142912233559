import { UploadSession, useUploadSession } from '@skand/uploader';
import { FAILURE_STAGES, PREPARED_STAGES, PREPARING_STAGES } from '../../Upload/constants';
import { ProgressView } from './ProgressView';
import { FileTreeNodeWithDepth } from './UploadDetails';

export interface UploadStatusProps {
  nodeWithDepth: FileTreeNodeWithDepth;
  uploadSession: UploadSession;
}

export const UploadStatus = ({ nodeWithDepth, uploadSession }: UploadStatusProps) => {
  const { stage } = useUploadSession(uploadSession);

  if (nodeWithDepth.node.type === 'folder') return null;

  if (FAILURE_STAGES.includes(stage))
    return <span className="color-alert-400 typo-text-s">Upload failed</span>;

  if (PREPARING_STAGES.includes(stage))
    return <span className="color-neutral-500 typo-text-s">Preparing...</span>;

  if (PREPARED_STAGES.includes(stage))
    return <span className="color-success-400 typo-text-s-em">Ready for upload</span>;

  if (nodeWithDepth.node.uploader === null) return null;

  return <ProgressView stage={stage} uploader={nodeWithDepth.node.uploader} />;
};
