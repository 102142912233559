import { ActionType } from '@/constants/policy';
import { convertToTitleCase } from '@/utils/types';
import { Dropdown, DropdownItem } from '@skand/ui';
import { useRef } from 'react';
export interface PermissionsSelectProps {
  subjectId: string;
  actionType: ActionType;
  validActionTypes: ActionType[];
  onChangePermission: (subjectId: string, newPermission: ActionType) => void;
}

const PermissionsSelect = ({
  subjectId,
  actionType,
  validActionTypes,
  onChangePermission,
}: PermissionsSelectProps) => {
  const dropdownRef = useRef<HTMLLabelElement>(null);
  const handleClick = (permission: ActionType) => {
    onChangePermission(subjectId, permission);
    dropdownRef.current?.focus();
    dropdownRef.current?.blur();
  };

  return (
    <Dropdown
      className="w-120px"
      label={convertToTitleCase(actionType.replace(/_/g, ' '))}
      ref={dropdownRef}
      width="full"
    >
      {validActionTypes.map((permission, index) => (
        <DropdownItem key={index} onClick={() => handleClick(permission)}>
          {convertToTitleCase(permission.replace(/_/g, ' '))}
        </DropdownItem>
      ))}
    </Dropdown>
  );
};

export default PermissionsSelect;
