import { LAYER_FORMAT_TYPE, LayerFormatType, LayerStatus } from '@/constants/layer';
import { queryClient } from '@/graphql/client';
import {
  AnnotationField,
  Annotation as AnnotationQuery,
  AnnotationTemplate,
  ImageField,
  ImageFile,
  PanoramicImageField,
} from '@/graphql/codegen/graphql';
import { TRANSFORM_SCENE_ENTITY } from '@/graphql/mutations';
import {
  GET_IMAGE_THUMBNAIL_URLS_BY_FILE_IDS,
  GET_IMAGE_URLS_BY_FILE_ID,
  LIST_IMAGE_TILES_BY_FILE_IDS,
} from '@/graphql/queries';
import { request } from '@/graphql/request';
import { useFetchImageTiles } from '@/hooks/useFetchImageTiles';
import { useFetchImageUrls } from '@/hooks/useFetchImageUrls';
import { useFetchSceneEntities } from '@/hooks/useFetchSceneEntities';
import { persist } from '@/utils/Persist';
import { Sketch2, Viewer2DAPI } from '@/utils/Viewer2D';
import { modelCache } from '@/utils/modelCache';
import { Process } from '@/utils/process';
import { getShareLinkToken } from '@/utils/shareLink';
import { CameraModel, Photo as LoaderPhoto } from '@skand/data-3d-loader';
import { toast } from '@skand/ui';
import {
  CameraMotionCallback,
  ClippingBoxTool,
  CursorListener,
  Geometry,
  GlobeMode,
  Imagery,
  ImageryBaseMap,
  Model3D,
  ModelConfiguration,
  ModelNode,
  NavigationMode,
  OnTransform,
  Panorama as PanoramaModel,
  ProjectionMode,
  SceneNode,
  Sketch,
  Terrain,
  TiledTextureSource,
  Tileset,
  TilesetStyle,
  ViewerAPI,
  toCartographic,
} from '@skand/viewer-component-v2';
import { Color, Quaternion, Vector2, Vector3 } from 'three';
import { create } from 'zustand';
import { LayerFilterKey, TemplateFilterKey } from '../components/SceneEntityTree';
import { AnnotationGeometry, startCreateAnnotation, useExplore } from './explore';
import { useLayout } from './layout';

type LoadState = 'pending' | 'fetching' | 'ready';

export type EventStatus = 'SUCCESS' | 'IN_PROGRESS' | 'FAILED' | 'NO_EVENT';

export interface LayerGroup {
  /**
   * Layer group type.
   */
  type: 'layerGroup';

  /**
   * Unique ID of the group.
   */
  id: string;

  /**
   * Name of the layer group.
   */
  name: string;

  /**
   * ID of the scene entity.
   */
  sceneEntityId: string;

  /**
   * 3D scene node associated with the group.
   */
  sceneNode: SceneNode;

  /**
   * Parent node.
   */
  parent?: Layer | LayerGroup;
}

/**
 * Project Layer.
 */
export interface Layer {
  /**
   * Layer type.
   */
  type: 'layer';

  /**
   * Unique ID of the layer.
   */
  id: string;

  /**
   * ID of the scene entity.
   */
  sceneEntityId: string;

  /**
   * Name of the layer.
   */
  name: string;

  /**
   * Capture date of the layer.
   */
  captureDate: Date;

  /**
   * Viewer Model configuration.
   */
  config: ModelConfiguration;

  /**
   * Viewer Model to be rendered.
   *
   * If the model has not been loaded yet, it is a SceneNode.
   */
  sceneNode: SceneNode | ModelNode | Imagery | Terrain;

  /**
   * Parent node.
   */
  parent?: Layer | LayerGroup;

  /**
   * Layer format type.
   */
  formatType: LayerFormatType;

  /**
   * Layer status.
   */
  status: LayerStatus;
}

/**
 * Annotation group.
 */
export interface AnnotationGroup {
  /**
   * Annotation group type.
   */
  type: 'annotationGroup';

  /**
   * Unique ID of the group.
   */
  id: string;

  /**
   * Name of the annotation group.
   */
  name: string;

  /**
   * ID of the scene entity.
   */
  sceneEntityId: string;

  /**
   * 3D scene node associated with the group.
   */
  sceneNode: SceneNode;

  /**
   * Parent node.
   */
  parent?: Layer | LayerGroup;

  /**
   * Lazy load status.
   */
  loadState: LoadState;

  /**
   * Annotations in the group.
   */
  annotations: Annotation[];
}

/**
 * Annotation.
 */
export interface Annotation {
  /**
   * Annotation type.
   */
  type: 'annotation';

  /**
   * Unique ID of the annotation.
   */
  id: string;

  /**
   * Version ID of the annotation.
   */
  versionId: string;

  /**
   * Name of the annotation.
   */
  name: string;

  /**
   * Group the annotation belongs to.
   */
  group: AnnotationGroup;

  /**
   * Associated photo ID.
   */
  photoId?: string;

  /**
   * Photo associated with the annotation (2D).
   */
  photo?: Photo;

  /**
   * 2D sketch
   */
  sketch2D?: Sketch2;

  /**
   * 3D sketch (scene hierarchy node).
   */
  sketch3D?: ModelNode;

  /**
   * Annotation template.
   */
  template: AnnotationTemplate;

  /**
   * Fields associated with the annotation.
   */
  fields: AnnotationField[];

  /**
   * Annotation query object (TODO: Use Fields instead)
   */
  metadata: AnnotationQuery;

  /**
   * Annotation updated date
   */
  updatedAt: Date;

  /**
   * Annotation updated user
   */
  updatedBy: string | null;
}

export interface PhotoGroup {
  /**
   * Photo group type.
   */
  type: 'photoGroup';

  /**
   * Unique ID of the group.
   */
  id: string;

  /**
   * Name of the photo group.
   */
  name: string;

  /**
   * ID of the scene entity.
   */
  sceneEntityId: string;

  /**
   * 3D scene node associated with the group.
   */
  sceneNode: SceneNode;

  /**
   * Layer associated with the photo group.
   */
  parent?: Layer | LayerGroup;

  /**
   * Lazy load status.
   */
  loadState: LoadState;

  /**
   * Render object ID.
   */
  renderObjectId: string;

  /**
   * Process ID.
   */
  processId: string;

  /**
   * Camera models associated with this group.
   */
  cameraModels: Map<CameraModel['id'], CameraModel>;

  /**
   * Group color.
   */
  color: Color;

  /**
   * Photos in the group.
   */
  photos: Photo[];
}

export interface ImageTileset {
  /**
   * Number of tile rows.
   */
  rows: number;

  /**
   * Number of tile columns.
   */
  cols: number;

  /**
   * Array of URLs to the tile images.
   *
   * Indexed by '${row} ${col}'
   */
  tileURLs: Map<string, string>;
}

/**
 * Photo base interface.
 */
export interface BasePhoto {
  /**
   * Unique ID of the photo.
   */
  id: string;

  /**
   * URL of the photo.
   */
  url?: string;

  /**
   * URL of the photo thumbnail.
   */
  thumbnailUrl?: string;

  /**
   * Filename of the photo.
   */
  name: string;

  /**
   * Group the photo belongs to.
   */
  group: PhotoGroup;

  /**
   * Camera widget model.
   */
  widget?: ModelNode;

  /**
   * Image tiles.
   */
  tileset?: ImageTileset;

  /**
   * Tileset event status
   */
  tilesetEventStatus: EventStatus;

  /**
   * Thumbnail event status
   */
  thumbnailEventStatus: EventStatus;

  /**
   * Camera widget color.
   */
  color?: Color;
}

/**
 * 2D Photo.
 *
 * This can be converted to a `@skand/data-3d-loader` Photo for de-projection purposes.
 */
export interface Photo2D extends BasePhoto {
  /**
   * Photo2D type.
   */
  type: 'photo2D';

  /**
   * Camera model ID.
   */
  cameraModelId?: number;

  /**
   * Projected position.
   */
  projectedPosition?: Vector3;
}

/**
 * Panorama photo.
 */
export interface Panorama extends BasePhoto {
  /**
   * Panorama type.
   */
  type: 'panorama';
}

/**
 * Viewer Photo.
 */
export type Photo = Photo2D | Panorama;

/**
 * Measurement sketch in 3D space (a temporary annotation).
 *
 * These are only available in a share link.
 */
export interface Measurement {
  type: 'measurement';

  /**
   * Unique identifier of the measurement.
   */
  id: string;

  /**
   * 3D sketch.
   */
  sketch: Sketch;
}

/**
 * LEGACY - Photo sorting mode.
 */
export type PhotoSortMode =
  | 'name'
  | 'relevance to point'
  | 'similarity to camera'
  | 'weighted similarity to camera'
  | 'distance to clicked point'
  | 'distance to camera'
  | 'projected point';

/**
 * Image Viewer settings.
 */
export interface ImageViewerSettings {
  /**
   * Number of images shown.
   */
  count: number;

  /**
   * Show process done images.
   */
  showProcessDone: boolean;

  /**
   * Show process pending images.
   */
  showProcessInProgress: boolean;

  /**
   * Show all images.
   */
  showAll: boolean;

  /**
   * Lock the sorting (for dynamic sort modes).
   */
  lockSorting: boolean;

  /**
   * LEGACY - Sort mode.
   */
  sortMode: PhotoSortMode;

  /**
   * LEGACY - Value for the weighted sort mode.
   */
  sortWeight: number;

  /**
   * Clicked point or camera position for sort mode.
   */
  sortPoint: Vector3;

  /**
   * Minimum gap distance between images.
   */
  gapSize: number;

  /**
   * Size of the 3D panoramic spheres in meters.
   */
  panoramaIconSize: number;

  /**
   * Height of the panorama camera off the ground.
   */
  panoramaCameraHeight: number;

  /**
   * Show the sort point.
   */
  showSortPoint: boolean;
}

/**
 * 3D Viewer settings.
 */
export interface Viewer3DSettings {
  /**
   * Determines the number of tiles to keep in memory at a time.
   */
  tileMemoryBudget: number;

  /**
   * Sort the tileset network requests.
   */
  networkRequestSorting: boolean;

  /**
   * Maximum number of network requests to be made at a time while streaming a tileset.
   */
  maxNetworkRequests: number;

  /**
   * Cancel enqueued network requests for tiles out of view.
   */
  tileRequestCancelling: boolean;

  /**
   * File caching using indexedDB.
   */
  localCacheEnabled: boolean;

  /**
   * Annotation name label visibility.
   */
  annotationNameVisibility: boolean;

  /**
   * Annotation measurement label visibility.
   */
  annotationMeasurementVisibility: boolean;

  /**
   * Background color of the 3D viewer.
   */
  backgroundColor: Color;

  /**
   * Eye dome lighting toggle.
   */
  eyeDomeLighting: boolean;

  /**
   * Toggle scaling point sizes by distance to camera.
   */
  pointSizeAttenuation: boolean;

  /**
   * Near plane clipping for the orthographic camera.
   */
  orthoNearPlaneClipping: boolean;

  /**
   * Globe clipping models underground.
   */
  globeClipping: boolean;

  /**
   * Opacity of the 3D overlay (e.g., panoramics, 2D image)
   */
  overlayOpacity: number;

  /**
   * Base point cloud MSSE value for performance tuning.
   */
  basePointCloudMSSE: number;

  /**
   * Maximum number of points that can be rendered per point cloud.
   */
  pointBudget: number;

  /**
   * Enable statistics menu.
   */
  enabledStatistics: boolean;

  /**
   * Show the tile request priority volumes.
   */
  showRequestPriorities: boolean;

  /**
   * EASTER EGG: Enable the FPS networked game mode.
   */
  enabledGameMode: boolean;
}

/**
 * Tileset settings.
 */
export interface TilesetSettings {
  /**
   * Maximum screen space error. Higher values will render faster but at lower quality.
   */
  msse: number;

  /**
   * Style settings, primarily for point clouds.
   */
  style: TilesetStyle;
}

/**
 * Global Viewer 2D and 3D state.
 */
export interface ViewerState {
  /**
   * Service API for the Viewer3D.
   */
  api3D: ViewerAPI | null;

  /**
   * Service API for the Viewer2D.
   */
  api2D: Viewer2DAPI | null;

  /**
   * Processes.
   */
  processes: Process[];

  /**
   * Layer groups.
   */
  layerGroups: LayerGroup[];

  /**
   * Layer models.
   */
  layers: Layer[];

  /**
   * Annotation groups.
   */
  annotationGroups: AnnotationGroup[];

  /**
   * 2D photo groups.
   */
  photo2DGroups: PhotoGroup[];

  /**
   * Panorama groups.
   */
  panoramaGroups: PhotoGroup[];

  /**
   * Measurements.
   */
  measurements: Measurement[];

  /**
   * Target measurement.
   */
  targetMeasurement: Measurement | null;

  /**
   * Context menu target.
   */
  contextTarget: Photo | null;

  /**
   * Target photo for Viewer2D.
   */
  targetPhoto: Photo | null;

  /**
   * Target annotation for Viewer3D.
   */
  targetAnnotation3D: Annotation | null;

  /**
   * Target annotation for Viewer2D.
   */
  targetAnnotation2D: Annotation | null;

  /**
   * Target process.
   */
  targetProcess: Process | null;

  /**
   * Globe mode.
   */
  globeMode: GlobeMode;

  /**
   * Navigation mode.
   */
  navigationMode: NavigationMode;

  /**
   * Projection mode.
   */
  projectionMode: ProjectionMode;

  /**
   * Imagery base map type.
   */
  baseMapType: ImageryBaseMap;

  /**
   * Enable 2D Image Viewer (no 3D data associated with image).
   */
  enabled2D: boolean;

  /**
   * Enable Image Viewer control menu.
   */
  enabledImageRibbon: boolean;

  /**
   * Enable UI to update the globe (i.e., imagery and terrain layers, globe settings).
   */
  enableGlobeUI: boolean;

  /**
   * Enable 3D drawing.
   */
  enabledDraw3D: boolean;

  /**
   * Enable 2D drawing.
   */
  enabledDraw2D: boolean;

  /**
   * Enable 2D image overlay tool.
   */
  enabledPhotoOverlay: boolean;

  /**
   * Enable panorama walkthrough tool.
   */
  enabledPanoramaWalkthrough: boolean;

  /**
   * Enable screenshot tool.
   */
  enabledScreenshot: boolean;

  /**
   * Flag to prevent race condition when loading images for panorama walkthrough or image overlay.
   */
  loadingMutex: boolean;

  /**
   * Screenshot callback.
   *
   * @param image
   * @returns
   */
  screenshotCallback: (image: string) => void;

  /**
   * Event listeners.
   */
  listeners: {
    onCursorHover: CursorListener[];
    onCursorTap: CursorListener[];
    onSketch2DTap: ((sketch: Sketch2) => void)[];
    onCameraMotion: CameraMotionCallback[];
    onCameraMotionFinished: CameraMotionCallback[];
  };

  /**
   * 3D viewer settings.
   */
  viewer3DSettings: Viewer3DSettings;

  /**
   * 2D viewer settings.
   */
  viewer2DSettings: ImageViewerSettings;

  /**
   * Tileset settings.
   */
  tilesetSettings: Map<Layer['id'], TilesetSettings>;

  /**
   * Clipping box tools.
   */
  clippingBoxTools: Map<Layer['id'], ClippingBoxTool>;

  /**
   * Visible layers.
   */
  visibleLayers: Set<Layer['id'] | LayerGroup['id']>;

  /**
   * Visible photo groups.
   */
  visiblePhotoGroups: Set<PhotoGroup['id']>;

  /**
   * Visible annotations.
   */
  visibleAnnotations: Set<Annotation['id']>;

  /**
   * Filtered layers.
   */
  filteredLayers: Set<Layer['id']>;

  /**
   * Filtered annotations.
   */
  filteredAnnotations: Set<Annotation['id']>;

  /**
   * Filtered photos.
   */
  filteredPhotos: Set<Photo['id']>;

  /**
   * Photos displayed on the ribbon list.
   */
  ribbonPhotos: Photo[];

  /**
   * Popup photos.
   */
  popupPhotos: Set<Photo>;

  /**
   * Position cache.
   */
  positionCache: Map<Layer['id'], [Vector3, Quaternion]>;

  /**
   * Filtered templates in scene tab.
   */
  filteredTemplateKeys: Set<TemplateFilterKey>;

  /**
   * Filtered layers in scene tab.
   */
  filteredLayerKeys: Set<LayerFilterKey>;

  /**
   * Enable data attribution modal.
   */
  enabledDataAttribution: boolean;

  /**
   * Store default transforms from model source file.
   */
  defaultTransformCache: Map<Layer['id'], [Vector3, Quaternion]>;

  /**
   * Store pinned annotations
   */
  pinnedAnnotations: Set<Annotation['id']>;

  /**
   * Store pinned layers
   */
  pinnedLayers: Set<Layer['id']>;

  /**
   * Enable select mode in scene tree.
   */
  enabledSelectMode: boolean;
}

/**
 * Initialize ViewerState.
 */
export const useViewer = create<ViewerState>()(() => ({
  api3D: null,
  api2D: null,
  processes: [],
  layerGroups: [],
  layers: [],
  annotationGroups: [],
  photo2DGroups: [],
  panoramaGroups: [],
  cameraModels: new Map(),
  measurements: [],
  contextTarget: null,
  targetPhoto: null,
  targetMeasurement: null,
  targetAnnotation3D: null,
  targetAnnotation2D: null,
  globeMode: persist.get('globe') ?? 'default',
  baseMapType: persist.get('imageryBase') ?? 'satellite',
  navigationMode: persist.get('navigation') ?? 'orbit',
  projectionMode: persist.get('projection') ?? 'perspective',
  enabled2D: false,
  enabledImageRibbon: persist.get('enabledImageRibbon') ?? false,
  enableGlobeUI: true,
  enabledDraw2D: false,
  enabledDraw3D: false,
  enabledPhotoOverlay: false,
  enabledPanoramaWalkthrough: false,
  enabledScreenshot: false,
  loadingMutex: false,
  screenshotCallback: () => {},
  listeners: {
    onDraw3DSubmit: [],
    onDraw2DSubmit: [],
    onCursorHover: [],
    onCursorTap: [],
    onSketch2DTap: [],
    onCameraMotion: [],
    onCameraMotionFinished: [],
  },
  viewer3DSettings: {
    tileMemoryBudget: persist.get('tileMemoryBudget') ?? 1024,
    pointBudget: persist.get('pointBudget') ?? 2000000,
    networkRequestSorting: persist.get('networkRequestSorting') ?? true,
    maxNetworkRequests: persist.get('maxNetworkRequests') ?? 16,
    tileRequestCancelling: persist.get('tileRequestCancelling') ?? false,
    localCacheEnabled: persist.get('localCacheEnabled') ?? true,
    annotationNameVisibility: persist.get('showLabels') ?? false,
    annotationMeasurementVisibility: persist.get('showMeasurements') ?? false,
    backgroundColor: persist.get('backgroundColor') ?? new Color(0, 0, 0),
    eyeDomeLighting: persist.get('edl') ?? false,
    pointSizeAttenuation: persist.get('pointSizeAttenuation') ?? false,
    orthoNearPlaneClipping: persist.get('orthoNearPlaneClipping') ?? true,
    globeClipping: persist.get('globeClipping') ?? true,
    overlayOpacity: 1.0,
    basePointCloudMSSE: 16,
    enabledStatistics: false,
    showRequestPriorities: false,

    // Enable by default on mobile
    enabledGameMode:
      /mobile/i.test(navigator.userAgent) && !/ipad|tablet/i.test(navigator.userAgent),
  },
  viewer2DSettings: persist.get('viewer2DSettings')
    ? {
        ...(persist.get('viewer2DSettings') as ImageViewerSettings),
        panoramaIconSize: persist.get('panoramaIconSize') ?? 0.5,
        panoramaCameraHeight: persist.get('panoramaCameraHeight') ?? 1.2,
      }
    : {
        count: 10,
        showAll: true,
        lockSorting: false,
        showProcessDone: true,
        showProcessInProgress: true,
        sortMode: 'distance to camera',
        sortWeight: 0.5,
        sortPoint: new Vector3(),
        gapSize: 3,
        panoramaIconSize: persist.get('panoramaIconSize') ?? 0.5,
        panoramaCameraHeight: persist.get('panoramaCameraHeight') ?? 1.2,
        showSortPoint: true,
      },
  tilesetSettings: new Map(Object.entries(persist.get('tilesetSettings') ?? {})),
  clippingBoxTools: new Map(),
  visibleLayers: new Set(persist.get('layers')),
  visiblePhotoGroups: new Set(persist.get('photoGroups')),
  visibleAnnotations: new Set(
    persist.get('annotation') ? [persist.get('annotation') as string] : [],
  ),
  filteredLayers: new Set(),
  filteredAnnotations: new Set(),
  filteredPhotos: new Set(),
  popupPhotos: new Set(),
  ribbonPhotos: [],
  targetProcess: null,
  positionCache: new Map(),
  filteredTemplateKeys: new Set(),
  filteredLayerKeys: new Set(),
  enabledDataAttribution: false,
  defaultTransformCache: new Map(),
  pinnedAnnotations: new Set(),
  pinnedLayers: new Set(),
  enabledSelectMode: false,
}));

/**
 * Load a layer model and return if the initial position is likely to be invalid.
 *
 * @param layer
 * @returns
 */
export const loadLayerModel = async (layer: Layer) => {
  const { api3D, enabledPanoramaWalkthrough, tilesetSettings, viewer3DSettings, globeMode } =
    useViewer.getState();
  let invalidPosition = false;
  if (modelCache.isLoading(layer.id) || modelCache.get(layer.id) || !api3D) return invalidPosition;

  const infoToast = toast({ type: 'info', message: `Loading ${layer.name} (${layer.id}).` });
  try {
    const model = await modelCache.load(layer.id, api3D, layer.config);

    // Apply tileset settings if available
    if (model instanceof Tileset) {
      const settings = tilesetSettings.get(layer.id);
      if (layer.formatType === LAYER_FORMAT_TYPE.MESH_3D) {
        model.setMSSE(settings?.msse ?? 2);
      } else if (settings) {
        settings.style.pointSizeAttenuation = viewer3DSettings.pointSizeAttenuation;
        model.setStyle(settings.style);
        model.setMSSE(settings.msse);
      } else {
        model.setMSSE(viewer3DSettings.basePointCloudMSSE + 17);
      }
    }

    // Set clipping volume if available
    if (model instanceof Model3D) {
      const persistClippingBox = persist.get('clippingBoxes')?.[layer.id];
      if (persistClippingBox) {
        useViewer.setState(prev => {
          const clippingBoxTools = new Map(prev.clippingBoxTools);
          const clippingBox = api3D.clipping.createBoxTool();
          clippingBox.setOBB(persistClippingBox);
          clippingBox.addTarget(model);
          clippingBoxTools.set(layer.id, clippingBox);
          return { clippingBoxTools };
        });
      }
    }

    // Resolve the position of the model based on the scene entity
    if (model instanceof Terrain || model instanceof Imagery) {
      layer.sceneNode = model;
      if (globeMode === 'google') {
        toast({
          type: 'info',
          message: 'Ortho & terrain layers do not show in Google Earth view.',
          lifespan: 10000,
        });
      }
    } else if (model instanceof Model3D && layer.sceneNode instanceof SceneNode) {
      const nodePosition = layer.sceneNode.getPosition();

      const position = model.getPosition();
      const rotation = model.getRotation();

      // Store model source file transforms
      useViewer.setState(state => ({
        defaultTransformCache: new Map(
          state.defaultTransformCache.set(layer.id, [position, rotation]),
        ),
      }));

      if (nodePosition.x === 0 && nodePosition.y === 0 && nodePosition.z === 0) {
        // If the scene entity position is 0,0,0, save model-calculated position
        const projectId = useExplore.getState().projectId as string;

        await request(TRANSFORM_SCENE_ENTITY, {
          projectId,
          sceneEntityId: layer.sceneEntityId,
          position: { x: position.x, y: position.y, z: position.z },
          rotation: { x: rotation.x, y: rotation.y, z: rotation.z, w: rotation.w },
        });
        await queryClient.invalidateQueries(
          useFetchSceneEntities.getSceneEntityQueryKey(projectId),
        );
      } else {
        // Otherwise, overwrite with the scene entity position
        model.setPosition(layer.sceneNode.getPosition());
        model.setRotation(layer.sceneNode.getRotation());
        model.setScale(layer.sceneNode.getScale());
      }

      // Replace scene node with scene tree
      const parent = layer.sceneNode.getParent();
      const children = layer.sceneNode.getChildren();
      layer.sceneNode = new ModelNode(model);
      parent?.add(layer.sceneNode);
      for (const child of children) {
        layer.sceneNode.add(child);
      }

      // Check if the final model position is too far from earth surface
      const threshold = 10000;
      const distanceToCenter = model.getPosition().length();
      const cartographic = toCartographic(model.getPosition());
      invalidPosition =
        distanceToCenter < threshold ||
        cartographic.height < -threshold ||
        cartographic.height > threshold;
    }

    // Update layer array to signal re-render
    useViewer.setState(prev => ({ layers: [...prev.layers] }));

    // Fly to the model if there are no camera overrides
    if (
      !persist.get('annotation') &&
      !persist.get('cameraPosition') &&
      !persist.get('cameraRotation') &&
      !enabledPanoramaWalkthrough &&
      !invalidPosition
    ) {
      api3D?.navigation.lookAt(model, false);
    }
    toast({
      type: 'success',
      message: `Successfully loaded ${layer.name} (${layer.id}).`,
      lifespan: 5000,
    });
  } catch (error) {
    toast({
      type: 'warn',
      message: `${error} - ${layer.name} (${layer.id})`,
      lifespan: 10000,
    });
  } finally {
    infoToast.dismiss();
  }
  return invalidPosition;
};

/**
 * Viewer3D component ref listener to set the ViewerAPI in the store.
 *
 * @param api3D
 */
export const setViewerAPI3D = (api3D: ViewerAPI | null) => {
  useViewer.setState({ api3D });
};

/**
 * Viewer2D component ref listener to set the Viewer2DAPI in the store.
 *
 * @param api2D
 */
export const setViewerAPI2D = (api2D: Viewer2DAPI | null) => {
  useViewer.setState({ api2D });
};

/**
 * Enable Image Ribbon.
 */
export const enableImageRibbon = (targetPhoto: Photo | null) => {
  useViewer.setState({ targetPhoto, enabledImageRibbon: true });
};

/**
 * Disable Image Ribbon.
 */
export const disableImageRibbon = () => {
  useViewer.setState({ enabledImageRibbon: false });
};

/**
 * Enable UI that updates the globe.
 */
export const enableViewerGlobeUI = () => {
  useViewer.setState({ enableGlobeUI: true });
};

/**
 * Disable UI that updates the globe.
 */
export const disableViewerGlobeUI = () => {
  useViewer.setState({ enableGlobeUI: false });
};

/**
 * Set the target measurement.
 *
 * @param targetMeasurement
 */
export const setTargetMeasurement = (targetMeasurement: Measurement | null) => {
  useViewer.setState({ targetMeasurement });
  useLayout.getState().showRightSideBar();
};

/**
 * Set the target annotation for Viewer3D.
 *
 * @param targetAnnotation3D
 */
export const setTargetAnnotation3D = (targetAnnotation3D: Annotation | null) => {
  useViewer.setState({ targetAnnotation3D });
};

/**
 * Set the target annotation for Viewer2D.
 *
 * @param targetAnnotation2D
 */
export const setTargetAnnotation2D = (targetAnnotation2D: Annotation | null) => {
  useViewer.setState({ targetAnnotation2D });
};

/**
 * Create a new measurement.
 *
 * @param vertices
 * @param closed
 * @param color
 */
export const createMeasurement = async (vertices: Vector3[], closed: boolean, color: Color) => {
  const { api3D, measurements } = useViewer.getState();
  if (api3D) {
    const id = crypto.randomUUID();
    let geometry: Geometry;
    if (vertices.length === 1) {
      geometry = 'point';
    } else if (closed) {
      geometry = 'polygon';
    } else {
      geometry = 'lines';
    }
    const sketch = (await api3D.model.create({
      type: 'sketch',
      name: id,
      color,
      points: vertices,
      geometry,
    })) as Sketch;
    await sketch.hide();
    const measurement: Measurement = {
      type: 'measurement',
      id,
      sketch,
    };
    useViewer.setState({ measurements: [...measurements, measurement] });
    setTargetMeasurement(measurement);
    stopDraw3D();
  }
};

/**
 * Start 3D drawing.
 *
 * @param points
 * @param closed
 * @param edit
 */
export const startDraw3D = async (
  points?: Vector3[],
  closed?: boolean,
  color?: Color,
  edit = false,
) => {
  const { api3D, targetPhoto, enabledPhotoOverlay } = useViewer.getState();
  if (api3D) {
    await api3D.draw.start(points, closed);
    api3D.draw.setColor(color ?? new Color(1, 1, 1));
    api3D.draw.setSubmitCallback((vertices, closed, color) => {
      const hasShareLinkToken = getShareLinkToken();
      if (hasShareLinkToken) {
        createMeasurement(vertices, closed, color);
      } else {
        // TODO: Refactor this once we fix data-3d-loader
        let sketch2D: AnnotationGeometry['sketch2D'] = undefined;
        if (
          targetPhoto &&
          targetPhoto.type === 'photo2D' &&
          targetPhoto.widget &&
          targetPhoto.cameraModelId !== undefined &&
          enabledPhotoOverlay
        ) {
          const photo: LoaderPhoto = {
            fileName: targetPhoto.name,
            position: targetPhoto.widget.getPosition(),
            rotation: targetPhoto.widget.getRotation(),
            cameraId: targetPhoto.cameraModelId,
          };

          const camera = targetPhoto.group.cameraModels.get(targetPhoto.cameraModelId);
          if (camera) {
            sketch2D = {
              vertices: vertices.map(vertex => api3D.deproject.project(vertex, photo, camera)),
              photoId: targetPhoto.id,
              closed,
              color,
            };
          }
        }
        startCreateAnnotation(
          {
            sketch3D: {
              vertices,
              closed,
              color,
            },
            sketch2D,
          },
          edit,
        );
      }
    });
    api3D.panorama.getWalkthrough().disableTransitions();
    useViewer.setState({ enabledDraw3D: true });
  }
};

/**
 * Stop 3D drawing.
 */
export const stopDraw3D = () => {
  const { api3D } = useViewer.getState();
  if (api3D) {
    api3D.draw.stop();
    api3D.draw.setColor(new Color(1, 1, 1));
    api3D.panorama.getWalkthrough().enableTransitions();
    useViewer.setState({ enabledDraw3D: false });
  }
};

/**
 * Start 2D drawing.
 *
 * @param points
 * @param closed
 * @param edit
 */
export const startDraw2D = (points?: Vector2[], closed?: boolean, color?: Color, edit = false) => {
  const { api2D, targetPhoto } = useViewer.getState();
  if (api2D) {
    const drawController = api2D.editor.getDrawController();
    drawController.start(points, closed);
    drawController.setColor(color ?? new Color(1, 1, 1));
    drawController.setSubmitCallback((vertices, closed, color) => {
      if (targetPhoto) {
        startCreateAnnotation(
          {
            sketch2D: {
              vertices,
              closed,
              photoId: targetPhoto.id,
              color,
            },
          },
          edit,
        );
      }
    });
    useViewer.setState({ enabledDraw2D: true });
  }
};

/**
 * Stop 2D drawing.
 */
export const stopDraw2D = () => {
  const { api2D } = useViewer.getState();
  if (api2D) {
    const drawController = api2D.editor.getDrawController();
    drawController.stop();
    drawController.setColor(new Color(1, 1, 1));
    useViewer.setState({ enabledDraw2D: false });
  }
};

/**
 * Update the image url fields when entering image overlay or panorama walkthrough.
 *
 * @param panoramas
 */
export const updatePhotoUrls = async (photo: Photo) => {
  // Load the main image url if not available
  if (!photo.url) {
    const urlResult = await queryClient.fetchQuery({
      queryFn: () => request(GET_IMAGE_URLS_BY_FILE_ID, { fileIds: [photo.id] }),
      queryKey: useFetchImageUrls.getQueryKey([photo.id], false),
    });
    const urlMap = new Map<string, string>();
    useFetchImageUrls.transformUrls(urlMap, urlResult);

    photo.url = urlMap.get(photo.id);
    const model = photo.widget?.getModel();
    if (photo.url && !photo.tileset && model instanceof PanoramaModel) {
      model.setUrl(photo.url);
    }
  }

  // Load the thumbnail url if not available
  if (!photo.thumbnailUrl) {
    const thumbnailUrlResult = await queryClient.fetchQuery({
      queryFn: () => request(GET_IMAGE_THUMBNAIL_URLS_BY_FILE_IDS, { fileIds: [photo.id] }),
      queryKey: useFetchImageUrls.getQueryKey([photo.id], true),
    });
    const thumbnailUrlMap = new Map<string, string>();
    useFetchImageUrls.transformThumbnailUrls(thumbnailUrlMap, thumbnailUrlResult);

    if (thumbnailUrlMap.get(photo.id)) {
      photo.thumbnailUrl = thumbnailUrlMap.get(photo.id);
    } else {
      photo.thumbnailUrl = photo.url;
    }
    const model = photo.widget?.getModel();
    if (photo.thumbnailUrl && model instanceof PanoramaModel) {
      model.setThumbnailUrl(photo.thumbnailUrl);
    }
  }

  // Load the tile urls of the photos if not available
  if (photo.tileset && !photo.tileset.tileURLs.size) {
    const tileUrlResult = await queryClient.fetchQuery({
      queryFn: () => request(LIST_IMAGE_TILES_BY_FILE_IDS, { fileIds: [photo.id] }),
      queryKey: useFetchImageTiles.getQueryKey([photo.id]),
    });
    const tileMap = new Map<string, Map<string, string>>();
    useFetchImageTiles.transform(tileMap, tileUrlResult);

    const tiles = tileMap.get(photo.id);
    if (tiles) {
      photo.tileset.tileURLs = tiles;
      const model = photo.widget?.getModel();
      if (model instanceof PanoramaModel) {
        model.setUrl((row, col) => tiles.get(`${row} ${col}`) ?? '');
      }
    }
  }
};

/**
 * Update the image url fields when entering image overlay or panorama walkthrough.
 */
export const updatePhotoUrlFromRenderObjectImage = async (
  photo: Photo,
  renderObjectImage: ImageField | PanoramicImageField,
) => {
  const originalUrl = renderObjectImage.file?.signedGetObjectUrl;
  const thumbnailUrl = (renderObjectImage.file as ImageFile)?.thumbnailUrl;

  // Load the main image url if not available
  if (!photo.url && originalUrl) {
    photo.url = originalUrl;
    const model = photo.widget?.getModel();
    if (photo.url && !photo.tileset && model instanceof PanoramaModel) {
      model.setUrl(photo.url);
    }
  }

  // Load the thumbnail url if not available
  if (!photo.thumbnailUrl) {
    photo.thumbnailUrl = thumbnailUrl ?? photo.url;
    const model = photo.widget?.getModel();
    if (photo.thumbnailUrl && model instanceof PanoramaModel) {
      model.setThumbnailUrl(photo.thumbnailUrl);
    }
  }

  // Load the tile urls of the photos if not available
  if (photo.tileset && !photo.tileset.tileURLs.size) {
    const tileUrlResult = await queryClient.fetchQuery({
      queryFn: () => request(LIST_IMAGE_TILES_BY_FILE_IDS, { fileIds: [photo.id] }),
      queryKey: useFetchImageTiles.getQueryKey([photo.id]),
    });
    const tileMap = new Map<string, Map<string, string>>();
    useFetchImageTiles.transform(tileMap, tileUrlResult);
    const tiles = tileMap.get(photo.id);
    if (tiles) {
      photo.tileset.tileURLs = tiles;
      const model = photo.widget?.getModel();
      if (model instanceof PanoramaModel) {
        model.setUrl((row, col) => tiles.get(`${row} ${col}`) ?? '');
      }
    }
  }
};

/**
 * Start the image overlay mode.
 *
 * @param target
 * @param fullScreen
 * @param preserveView
 */
export const startPhotoOverlay = async (
  target: Photo2D,
  fullScreen = false,
  preserveView = false,
  hideLeftSideBar = true,
) => {
  const { api3D, viewer3DSettings } = useViewer.getState();
  const { hideLeftSideBarI } = useLayout.getState();
  persist.set('image', target.id);
  persist.url.refreshURL();
  enableImageRibbon(target);

  // Hide the left side bar to enter immersive mode
  if (hideLeftSideBar) {
    hideLeftSideBarI();
  }

  // If no 3D data available or fullscreen mode is enabled, open 2D viewer
  if (!target.widget || fullScreen) {
    useViewer.setState({ enabled2D: true, enabledPhotoOverlay: true });
  } else {
    if (!api3D || !target.widget || !target.widget || target.cameraModelId === undefined) return;

    const camera = target.group.cameraModels.get(target.cameraModelId);
    if (!camera) return;

    useViewer.setState({
      enabled2D: false,
      enabledPhotoOverlay: true,
      loadingMutex: true,
      contextTarget: null,
    });
    const photo: LoaderPhoto = {
      fileName: target.name,
      position: target.widget.getPosition(),
      rotation: target.widget.getRotation(),
      cameraId: target.cameraModelId,
    };

    const toastHandle = toast({
      type: 'info',
      message: `Loading overlay image ${photo.fileName}.`,
    });
    try {
      await updatePhotoUrls(target);
      if (target.tileset && target.thumbnailUrl) {
        const source: TiledTextureSource = {
          thumbnailUrl: target.thumbnailUrl,
          rows: target.tileset.rows,
          cols: target.tileset.cols,
          getTileURL: (row, col) => target.tileset?.tileURLs.get(`${row} ${col}`) ?? '',
        };
        await api3D.deproject.lock(source, photo, camera, preserveView);
        api3D.deproject.setOpacity(viewer3DSettings.overlayOpacity);
        api3D.deproject.setColor(target.color ?? new Color(0x0040ff));
      } else if (target.url) {
        await api3D.deproject.lock(target.url, photo, camera, preserveView);
        api3D.deproject.setOpacity(viewer3DSettings.overlayOpacity);
        api3D.deproject.setColor(target.color ?? new Color(0x0040ff));
      }
    } catch (error) {
      toast({
        type: 'warn',
        message: `${error}`,
      });
    } finally {
      toastHandle.dismiss();
      useViewer.setState({ loadingMutex: false });
    }
  }
};

/**
 * Stop the image overlay mode.
 */
export const stopPhotoOverlay = () => {
  const { api3D, enabled2D } = useViewer.getState();
  disableImageRibbon();
  if (enabled2D) {
    useViewer.setState({ enabled2D: false, enabledPhotoOverlay: false, targetPhoto: null });
  } else {
    useViewer.setState({ enabledPhotoOverlay: false });
    api3D?.deproject.unlock();
  }

  // Update URL
  persist.clear('image');
  persist.url.refreshURL();
};

/**
 * Start a panorama walkthrough.
 *
 * @param target
 */
export const startPanoramaWalkthrough = async (target: Panorama) => {
  const { api3D, ribbonPhotos } = useViewer.getState();
  const { hideLeftSideBarI } = useLayout.getState();
  disableImageRibbon();
  persist.set('image', target.id);
  persist.url.refreshURL();
  hideLeftSideBarI();

  if (!target.widget) return;
  useViewer.setState({
    enabled2D: false,
    enabledPanoramaWalkthrough: true,
    loadingMutex: true,
    contextTarget: null,
  });

  const panoramaModels: PanoramaModel[] = [];
  for (const photo of ribbonPhotos) {
    if (photo.type === 'panorama' && photo.widget) {
      const model = photo.widget.getModel() as PanoramaModel;
      await model.show();
      panoramaModels.push(model);
    }
  }
  // Update panorama walkthrough set
  api3D?.panorama.getWalkthrough().setPanoramas(panoramaModels);

  let toastHandle: ReturnType<typeof toast> | null = null;
  api3D?.panorama.getWalkthrough().stop();
  try {
    await updatePhotoUrls(target);
    await api3D?.panorama.getWalkthrough().start(
      panoramaModels,
      target.widget.getModel() as PanoramaModel,
      async panorama => {
        toastHandle = toast({
          type: 'info',
          message: `Loading panoramic image ${panorama.getName()}.`,
        });
        const photo = useViewer
          .getState()
          .ribbonPhotos.find(photo => photo.widget?.getModel() === panorama);
        if (photo) {
          persist.set('image', photo.id);
          persist.url.refreshURL();
          await updatePhotoUrls(photo);
        }
      },
      async () => toastHandle?.dismiss(),
      async error => {
        toastHandle?.dismiss();
        toast({
          type: 'warn',
          message: `${error}`,
        });
      },
    );
  } finally {
    useViewer.setState({ loadingMutex: false });
  }
};

/**
 * Start the panorama edit mode.
 *
 * @param target
 * @param onEdit
 * @returns
 */
export const startPanoramaEdit = async (target: Panorama, onEdit: OnTransform) => {
  const { api3D } = useViewer.getState();
  if (!target.widget) return;
  useViewer.setState({ enabled2D: false, enabledPanoramaWalkthrough: true });

  let toastHandle: ReturnType<typeof toast> | null = null;
  await target.widget.show();
  api3D?.panorama.getEditor().start(
    target.widget.getModel() as PanoramaModel,
    onEdit,
    async panorama => {
      toastHandle = toast({
        type: 'info',
        message: `Loading panoramic image ${panorama.getName()}.`,
      });
      const photo = useViewer
        .getState()
        .ribbonPhotos.find(photo => photo.widget?.getModel() === panorama);
      if (photo) {
        persist.set('image', photo.id);
        persist.url.refreshURL();
        await updatePhotoUrls(photo);
      }
    },
    async () => toastHandle?.dismiss(),
    async error => {
      toastHandle?.dismiss();
      toast({
        type: 'warn',
        message: `${error}`,
      });
    },
  );
};

/**
 * Stop the current panorama walkthrough.
 */
export const stopPanoramaView = async () => {
  const { api3D } = useViewer.getState();
  disableImageRibbon();
  useViewer.setState({ enabledPanoramaWalkthrough: false, targetPhoto: null });
  api3D?.panorama.getWalkthrough().stop();
  api3D?.panorama.getEditor().stop();

  // Update URL
  persist.clear('image');
  persist.url.refreshURL();
};

/**
 * Start a screenshot event.
 *
 * @param callback
 */
export const startViewerScreenshot = (callback: ViewerState['screenshotCallback']) => {
  useViewer.setState({
    enabledScreenshot: true,
    screenshotCallback: callback,
  });
};

/**
 * Stop the current screenshot event.
 */
export const stopViewerScreenshot = () => {
  useViewer.setState({ enabledScreenshot: false });
};

/**
 * Add an event listener for the Viewer.
 *
 * @param event
 * @param callback
 */
export function addViewerEventListener<Event extends keyof ViewerState['listeners']>(
  event: Event,
  callback: ViewerState['listeners'][Event][number],
) {
  useViewer.setState(state => ({
    listeners: {
      ...state.listeners,
      [event]: [...state.listeners[event], callback],
    },
  }));
}

/**
 * Remove an event listener for the Viewer.
 *
 * @param event
 * @param callback
 */
export function removeViewerEventListener<Event extends keyof ViewerState['listeners']>(
  event: Event,
  callback: ViewerState['listeners'][Event][number],
) {
  useViewer.setState(state => ({
    listeners: {
      ...state.listeners,
      [event]: [...state.listeners[event]].filter(cb => cb !== callback),
    },
  }));
}

/**
 * Set the image viewer settings.
 *
 * @param settings
 * @param currentTab
 */
export const setViewer3DSettings = (
  settings: Partial<Viewer3DSettings> | ((settings: Viewer3DSettings) => Partial<Viewer3DSettings>),
) => {
  const { viewer3DSettings } = useViewer.getState();
  const newSettings = settings instanceof Function ? settings(viewer3DSettings) : settings;
  useViewer.setState({
    viewer3DSettings: {
      ...viewer3DSettings,
      ...newSettings,
    },
  });
};

/**
 * Set the image viewer settings.
 *
 * @param settings
 * @param currentTab
 */
export const setViewer2DSettings = (
  settings:
    | Partial<ImageViewerSettings>
    | ((settings: ImageViewerSettings) => Partial<ImageViewerSettings>),
) => {
  const { viewer2DSettings } = useViewer.getState();
  const newSettings = settings instanceof Function ? settings(viewer2DSettings) : settings;
  useViewer.setState({
    viewer2DSettings: {
      ...viewer2DSettings,
      ...newSettings,
    },
  });
};

// Resolve single fetched annotation group
export const resolveAnnotationGroup = (newGroup: AnnotationGroup) => {
  const { api2D } = useViewer.getState();

  useViewer.setState(state => {
    // Cleanup and track previous annotations
    const prevAnnotations = new Set<Annotation['id']>();
    for (const group of state.annotationGroups) {
      if (!(group.id === newGroup.id)) continue;

      for (const annotation of group.annotations) {
        prevAnnotations.add(annotation.id);
        annotation.sketch3D?.destroy();
        if (annotation.sketch2D) {
          api2D?.editor.hide(annotation.sketch2D);
        }
      }
    }

    // Only show annotations that have not been fetched before
    let targetAnnotation2D = state.targetAnnotation2D;
    let targetAnnotation3D = state.targetAnnotation3D;
    const visibleAnnotations = new Set(state.visibleAnnotations);

    for (const annotation of newGroup.annotations) {
      if (!prevAnnotations.has(annotation.id)) {
        // Get owned layer
        let layerOwner = annotation.group.parent;
        while (layerOwner && layerOwner.type !== 'layer') {
          layerOwner = layerOwner.parent;
        }
        if (!layerOwner || state.visibleLayers.has(layerOwner.id)) {
          visibleAnnotations.add(annotation.id);
        }
      }

      // Update target annotation
      if (annotation.id === state.targetAnnotation3D?.id) {
        targetAnnotation3D = annotation;
      }
      if (annotation.id === state.targetAnnotation2D?.id) {
        targetAnnotation2D = annotation;
      }
    }

    const newAnnotationGroups = state.annotationGroups.map(group => {
      if (group.id === newGroup.id) group.annotations = newGroup.annotations;
      return group;
    });

    return {
      annotationGroups: newAnnotationGroups,
      visibleAnnotations,
      targetAnnotation2D,
      targetAnnotation3D,
    };
  });
};

// Resolve single fetched photo group
export const resolvePhotoGroup = (newGroup: PhotoGroup) => {
  useViewer.setState(state => {
    for (const group of [...state.panoramaGroups, ...state.photo2DGroups]) {
      if (!(group.id === newGroup.id)) continue;
      for (const photo of group.photos) photo.widget?.destroy();
    }

    const newPanaoramaGroups = state.panoramaGroups.map(group => {
      if (group.id === newGroup.id) {
        group.photos = newGroup.photos;
        group.cameraModels = newGroup.cameraModels;
      }
      return group;
    });

    const newPhoto2DGroups = state.photo2DGroups.map(group => {
      if (group.id === newGroup.id) {
        group.photos = newGroup.photos;
        group.cameraModels = newGroup.cameraModels;
      }
      return group;
    });

    let targetPhoto = state.targetPhoto;
    for (const photo of newGroup.photos) {
      if (photo.id === state.targetPhoto?.id) {
        targetPhoto = photo;
      }
    }

    const photoMap = new Map<string, Photo>();
    for (const photo of newGroup.photos) {
      photoMap.set(photo.id, photo);
    }
    for (const group of state.annotationGroups) {
      for (const annotation of group.annotations) {
        if (annotation.photoId) {
          const photo = photoMap.get(annotation.photoId);
          if (photo) {
            annotation.photo = photo;
          }
        }
      }
    }

    // Get owned layer and update group visibility
    let layerOwner = newGroup.parent;
    while (layerOwner && layerOwner.type !== 'layer') {
      layerOwner = layerOwner.parent;
    }

    return {
      panoramaGroups: newPanaoramaGroups,
      photo2DGroups: newPhoto2DGroups,
      targetPhoto,
    };
  });
};

/**
 * Store template keys.
 */
export const setFilteredTemplateKeys = (keys: Set<TemplateFilterKey>) => {
  useViewer.setState({ filteredTemplateKeys: keys });
};

/**
 * Store layer keys.
 */
export const setFilteredLayerKeys = (keys: Set<LayerFilterKey>) => {
  useViewer.setState({ filteredLayerKeys: keys });
};

/**
 * Toggle the data attribution menu.
 */
export const setDataAttribution = (enabled: boolean) => {
  useViewer.setState({ enabledDataAttribution: enabled });
};
