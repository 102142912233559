import { SystemNodeType } from '@/stores/systemNodes';

export const getFileExtension = (fileName: string, type: SystemNodeType): string | null => {
  if (!fileName) return null;
  if (type === 'folder') return 'folder';

  const match = fileName.match(/\.([^.]+)$/);
  return match ? match[1] : null;
};

export const removeExtension = (filename: string): string => {
  const dotIndex = filename.lastIndexOf('.');
  return dotIndex !== -1 ? filename.slice(0, dotIndex) : filename;
};
