import { PanoGroupIcon, PhotoGroupIcon } from '@/components/IconButton';
import { MoreMenu } from '@/components/MoreMenu';
import { canPolicyActionEdit } from '@/constants/policy';
import { queryClient } from '@/graphql/client';
import { RenameSceneEntityMutationVariables } from '@/graphql/codegen/graphql';
import { RENAME_SCENE_ENTITY } from '@/graphql/mutations';
import { request } from '@/graphql/request';
import { useFetchSceneEntities } from '@/hooks/useFetchSceneEntities';
import { useFetchSceneEntityPermissions } from '@/hooks/useFetchSceneEntityPermissions';
import { useExplore } from '@/stores/explore';
import { PhotoGroup, useViewer } from '@/stores/viewer';
import { cn } from '@/utils/classname';
import { Button, Menu, MenuItem, toast } from '@skand/ui';
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { ListItemButton } from './ListItemButton';

export interface PhotoGroupNodeProps {
  group: PhotoGroup;
  setSettingsNodeTarget: (group: PhotoGroup | null) => void;
  setLinkNodeTarget: (group: PhotoGroup | null) => void;
  settingsNodeTarget: PhotoGroup | null;
  setEditNodeTarget: (node: PhotoGroup | null) => void;
  editNodeTarget: PhotoGroup | null;
}

export const PhotoGroupNode = ({
  group,
  setSettingsNodeTarget,
  setLinkNodeTarget,
  settingsNodeTarget,
  setEditNodeTarget,
  editNodeTarget,
}: PhotoGroupNodeProps) => {
  const projectId = useExplore(state => state.projectId);
  const enabledSelectMode = useViewer(state => state.enabledSelectMode);
  const visiblePhotoGroups = useViewer(state => state.visiblePhotoGroups);

  const isPhoto2D = group.photos.length === 0 || group.photos[0].type === 'photo2D';
  const isVisible = visiblePhotoGroups.has(group.id);

  const [editInput, setEditInput] = useState(group.name);

  const handleToggle = () => {
    if (visiblePhotoGroups.has(group.id)) {
      useViewer.setState(prev => {
        const visiblePhotoGroups = new Set(prev.visiblePhotoGroups);
        visiblePhotoGroups.delete(group.id);
        return { visiblePhotoGroups };
      });
    } else {
      useViewer.setState(prev => {
        const visiblePhotoGroups = new Set(prev.visiblePhotoGroups);
        visiblePhotoGroups.add(group.id);
        return { visiblePhotoGroups };
      });
    }
  };

  const { getSceneEntityPermission } = useFetchSceneEntityPermissions();
  const permission = getSceneEntityPermission(group.sceneEntityId);
  const canEdit = canPolicyActionEdit(permission);
  const updatePhotoGroup = useMutation({
    mutationFn: (variables: RenameSceneEntityMutationVariables) =>
      request(RENAME_SCENE_ENTITY, variables),
    onSuccess: () => {
      queryClient.invalidateQueries(useFetchSceneEntities.getSceneEntityQueryKey(projectId));
      toast({
        type: 'success',
        message: 'Successfully updated photo group node.',
        lifespan: 5000,
      });
    },
  });

  // Handle keypress events
  const handleInputKeypress = (key: string) => {
    if (key === 'Enter') {
      handleSubmitName();
    }
    if (key === 'Escape') {
      setEditNodeTarget(null);
    }
  };

  // Handle submitting the edited name
  const handleSubmitName = async () => {
    if (!projectId) return;

    await updatePhotoGroup.mutateAsync({
      sceneEntityId: group.sceneEntityId,
      projectId,
      name: editInput,
    });
    setEditNodeTarget(null);
  };

  return (
    <>
      {editNodeTarget === group ? (
        <div className="flex flex-1">
          <input
            className={cn(
              'px-1',
              'color-neutral-800',
              'typo-text-s-em',
              'rounded',
              'border-1',
              'border-solid',
              'border-primary-400',
              'outline-none',
              'w-full',
            )}
            onChange={e => setEditInput(e.target.value)}
            onKeyDown={e => handleInputKeypress(e.key)}
            value={editInput}
          />
          <Button className="ml-1" filled onClick={handleSubmitName} primary size="xs">
            Save
          </Button>
        </div>
      ) : (
        <>
          {isPhoto2D ? (
            <PhotoGroupIcon className="w-5 text-3 color-neutral-600" />
          ) : (
            <PanoGroupIcon className="w-5 text-3 color-neutral-600" />
          )}
          <p
            className={cn(
              'group',
              'cursor-pointer',
              'typo-text-small',
              'text-neutral-800',
              'flex-1 whitespace-nowrap',
            )}
            onDoubleClick={() => {
              if (canEdit) {
                setEditNodeTarget(group);
                setEditInput(group.name);
              }
            }}
            title={group.name}
          >
            {group.name}
          </p>
          <div
            className={cn(
              'bg-neutral-200 py-[1px] px-1 rounded-[50px] min-w-5 h-[13px] justify-center flex items-center ',
            )}
          >
            <p className={cn('typo-text-xs-em text-neutral-500 whitespace-nowrap pt-[1px]')}>
              {group.photos.length}
            </p>
          </div>

          <div className="w-48px" />
          <div
            className={cn(
              'fixed right-0 h-32px flex flex-none items-center gap-2 pl-2',
              !enabledSelectMode && 'bg-neutral-100',
            )}
            style={{
              boxShadow: !enabledSelectMode ? '-8px 0px 8px -2px rgba(255,255,255,1)' : 'none',
            }}
          >
            {!enabledSelectMode && (
              <ListItemButton
                active={group === settingsNodeTarget}
                icon={<div className="i-skand-gear" />}
                onClick={() => setSettingsNodeTarget(group === settingsNodeTarget ? null : group)}
              />
            )}
            <ListItemButton
              icon={
                <div className={cn('i-skand-eye', isVisible ? 'i-skand-show' : 'i-skand-hide')} />
              }
              onClick={handleToggle}
            />

            <MoreMenu
              className={cn(
                canPolicyActionEdit(permission) ? 'cursor-pointer' : 'opacity-0',
                enabledSelectMode && 'hidden',
              )}
            >
              {canPolicyActionEdit(permission) && (
                <Menu className="z-2">
                  <MenuItem className="cursor-pointer" onClick={() => setLinkNodeTarget(group)}>
                    Link to layer
                  </MenuItem>
                </Menu>
              )}
            </MoreMenu>
          </div>
        </>
      )}
    </>
  );
};
