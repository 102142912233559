import { imageLoadingOverlayStore } from '@/stores/imageLoadingOverlay';
import { Progress } from '@skand/ui';
import { useAtomValue } from 'jotai';

export const ImageLoadingOverlay = () => {
  const isOpen = useAtomValue(imageLoadingOverlayStore.isOpen);
  const { message, proportion } = useAtomValue(imageLoadingOverlayStore.currentStep) ?? {};

  if (!isOpen) return null;
  if (message === undefined || proportion === undefined) return null;

  return (
    <div className="absolute h-full w-full flex flex-col items-center justify-center gap-6 bg-neutral-100">
      <span className="color-neutral-600 typo-text-l">{message}</span>
      <div className="flex items-center gap-3">
        <span className="color-neutral-400 typo-text-s">Loading</span>
        <Progress animated className="w-60" progress={proportion} />
        <span className="color-neutral-400 typo-text-s">{proportion * 100}%</span>
      </div>
    </div>
  );
};
