import { cn } from '@skand/ui';
import { UploadSession, useUploadSession } from '@skand/uploader';
import { ChangeEventHandler, useCallback, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import { UploadDetails } from './UploadDetails';

export interface FileSectionProps {
  uploadSession: UploadSession;
}

export const FileSection = ({ uploadSession }: FileSectionProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const { getRootProps, getInputProps } = useDropzone({ onDrop: uploadSession.addFiles });

  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    e => {
      e.preventDefault();
      const files = [...(e.target.files ?? [])];
      uploadSession.addFiles(files);
      e.target.value = '';
    },
    [uploadSession],
  );

  const { fileTreeRef } = useUploadSession(uploadSession);
  const numberOfFiles = fileTreeRef.current.numberOfFiles;
  const canAddFiles = numberOfFiles === 0;

  return (
    <div
      className={cn(
        'mt-6 b-1 b-neutral-400 rounded-2 b-solid px-3 flex flex-col flex-none overflow-hidden',
        'h-186px',
      )}
    >
      <UploadDetails uploadSession={uploadSession} />

      {canAddFiles && (
        <div
          className="mt-6 h-100px flex items-center justify-center b-1 b-neutral-400 rounded-2 b-dashed"
          {...getRootProps()}
        >
          <span className="color-neutral-500 typo-text-s">Drag and drop files or&nbsp;</span>
          <label className="color-neutral-800 underline typo-link-s">
            browse files from your computer
            <input
              className="hidden"
              multiple
              onChange={handleChange}
              ref={inputRef}
              type="file"
              {...getInputProps()}
            />
          </label>
        </div>
      )}
    </div>
  );
};
