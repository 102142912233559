import { useModels } from '@/hooks/useModels';
import { useTrainings } from '@/hooks/useTrainings';
import { cn } from '@/utils/classname';
import { Modal } from '@skand/ui';
import { useState } from 'react';

interface Model {
  id: string;
  name: string;
}

interface Training {
  id: string;
  startTime: string;
  status: string;
}

export const ModelList = ({
  active,
  setActive,
}: {
  active: boolean;
  setActive: (value: boolean) => void;
}) => {
  const [selectedModelId, setSelectedModelId] = useState<string | null>(null);

  const { models, isLoading: isLoadingModels } = useModels();
  const { trainings, isLoading } = useTrainings(selectedModelId);

  if (!active) return null;

  const getStatusStyles = (status: string) => {
    const baseStyles = 'px-2 py-1 rd-md typo-text-small';
    const statusStyles = {
      deployed: 'bg-green-100 text-green-800',
      training: 'bg-yellow-100 text-yellow-800',
      failed: 'bg-red-100 text-red-800',
    };
    return `${baseStyles} ${statusStyles[status as keyof typeof statusStyles]}`;
  };

  return (
    <Modal>
      <div className="mb-4 flex items-center justify-between">
        <p className="color-neutral-800 typo-text-l">Model List</p>
        <div
          className={cn(
            'text-12px i-skand-close transform-rotate-90 text-neutral-400 hover:text-neutral-600 cursor-pointer',
          )}
          onClick={() => {
            setSelectedModelId(null);
            setActive(false);
          }}
        />
      </div>

      <div className="h-[444px] flex gap-4">
        <div className="w-1/2 flex flex-col">
          <div className="mb-2 text-neutral-800 typo-text-m">Models</div>
          <div
            className={cn(
              'flex-1 flex flex-col rounded-md gap-[10px] py-3 border-[1px] border-solid border-neutral-400 overflow-y-auto',
            )}
          >
            {isLoadingModels ? (
              <div className="py-2 text-center text-neutral-600">Loading models...</div>
            ) : models?.length > 0 ? (
              models.map((model: Model) => (
                <div
                  className={cn(
                    'text-left py-2 px-3 hover:bg-[#F5F3F6] cursor-pointer typo-text-small text-neutral-800',
                    selectedModelId === model.id && 'bg-[#F5F3F6]',
                  )}
                  key={model.id}
                  onClick={() => setSelectedModelId(model.id)}
                >
                  {model.name}
                </div>
              ))
            ) : (
              <p className="py-2 text-center text-neutral-600">No models found</p>
            )}
          </div>
        </div>

        <div className="w-1/2 flex flex-col">
          <div className="mb-2 text-neutral-800 typo-text-m">Trainings</div>
          <div
            className={cn(
              'flex-1 flex flex-col rounded-md gap-[10px] py-3 border-[1px] border-solid border-neutral-400 overflow-y-auto',
            )}
          >
            {selectedModelId ? (
              isLoading ? (
                <div className="py-2 text-center text-neutral-600">Loading trainings...</div>
              ) : trainings.length > 0 ? (
                [...trainings]
                  .sort((a, b) => new Date(b.startTime).getTime() - new Date(a.startTime).getTime())
                  .map((training: Training) => (
                    <div className={cn('px-3 py-2 text-left hover:bg-[#F5F3F6]')} key={training.id}>
                      <div className="flex items-center justify-between">
                        <div className="flex flex-col">
                          <span className="text-neutral-900 typo-text-medium">{training.id}</span>
                          <div className="text-neutral-500 typo-text-small">
                            {training.startTime}
                          </div>
                        </div>
                        <span className={cn(getStatusStyles(training.status))}>
                          {training.status}
                        </span>
                      </div>
                    </div>
                  ))
              ) : (
                <p className="py-2 text-center text-neutral-600">No trainings found</p>
              )
            ) : (
              <p className="py-2 text-center text-neutral-600">Select a model to view trainings</p>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};
