import { COUNT_ANNOTATIONS_BY_TEMPLATE_ID } from '@/graphql/queries';
import { request } from '@/graphql/request';
import { useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';

const getQueryKey = (id: string, filterDeleted?: boolean) => [
  'COUNT_ANNOTATIONS_BY_TEMPLATE_ID',
  id,
  filterDeleted,
];

export const useFetchCountTemplateAnnotations = (id: string, filterDeleted?: boolean) => {
  const result = useQuery({
    enabled: !!id,
    queryFn: () =>
      request(COUNT_ANNOTATIONS_BY_TEMPLATE_ID, {
        templateId: id as string,
        filterDeleted: filterDeleted ?? true,
      }),
    queryKey: getQueryKey(id, filterDeleted),
  });

  return useCallback(() => {
    return result.data?.countActiveAnnotationsByTemplateId ?? 0;
  }, [result.data?.countActiveAnnotationsByTemplateId]);
};

useFetchCountTemplateAnnotations.getQueryKey = getQueryKey;
