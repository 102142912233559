import { ColorPickerMenu } from '@/components/ColorPickerMenu';
import { PhotoGroupIcon } from '@/components/IconButton';
import {
  Panorama,
  Photo,
  Photo2D,
  startPanoramaWalkthrough,
  startPhotoOverlay,
  stopPanoramaView,
  stopPhotoOverlay,
  updatePhotoUrls,
  useViewer,
} from '@/stores/viewer';
import { IMAGE_VIEWER_SORT } from '@/utils/split';
import { Editor, Viewer2DAPI } from '@/utils/Viewer2D';
import { cn, Progress, Rnd, Slider, toast } from '@skand/ui';
import { PhotoCamera } from '@skand/viewer-component-v2';
import { useTreatments } from '@splitsoftware/splitio-react';
import { useEffect, useState } from 'react';
import { Color, Vector2 } from 'three';

interface PhotoPopupProps {
  photo: Photo;
  handleClose: () => void;
  handleFocus: () => void;
  focused: boolean;
}

export const PhotoPopup = ({ photo, handleClose, handleFocus, focused }: PhotoPopupProps) => {
  const treatment = useTreatments([IMAGE_VIEWER_SORT]);
  const imageViewerSortFlag = treatment[IMAGE_VIEWER_SORT].treatment === 'on';

  const api3D = useViewer(state => state.api3D);
  const annotationGroups = useViewer(state => state.annotationGroups);
  const visibleAnnotations = useViewer(state => state.visibleAnnotations);
  const listeners = useViewer(state => state.listeners);
  const loadingMutex = useViewer(state => state.loadingMutex);

  const [api2D, setAPI2D] = useState<Viewer2DAPI | null>(null);
  const [element, setElement] = useState<HTMLCanvasElement | null>(null);
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [targetReady, setTargetReady] = useState(false);
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [cameraWidgetColor, setCameraWidgetColor] = useState(new Color(photo.group.color));

  const [isView3DTooltip, setIsView3DTooltip] = useState(false);
  const [isResetTooltip, setIsResetTooltip] = useState(false);
  const [isDownloadTooltip, setIsDownloadTooltip] = useState(false);
  const [isCloseTooltip, setIsCloseTooltip] = useState(false);

  const [zoom, setZoom] = useState(0);
  const [minZoom, setMinZoom] = useState(0);
  const maxZoom = 5;
  const zoomStep = 0.05;

  // Initialize 2D editor
  useEffect(() => {
    if (element === null) return;
    const editor = new Editor(element);

    const resizeObserver = new ResizeObserver(() => {
      const size = new Vector2(element.offsetWidth, element.offsetHeight);
      editor.setSize(size);
    });
    resizeObserver.observe(element);

    // Main loop
    let frame = 0;
    const update = () => {
      frame = requestAnimationFrame(update);
      editor.update();
    };
    update();

    setAPI2D({ editor });

    // Listen for changes in zoom
    editor.setOnTransform(() => {
      setZoom(editor.getZoom());
      setMinZoom(editor.getMinZoom());
    });

    return () => {
      cancelAnimationFrame(frame);
    };
  }, [element]);

  // Listen for changes in the target photo
  useEffect(() => {
    if (api2D === null) return;

    // Render target photo onto canvas
    const showTarget = async () => {
      api2D.editor.clear();

      // Show photo overlay
      setTargetReady(false);
      try {
        await updatePhotoUrls(photo);
        if (photo.thumbnailUrl && photo.tileset) {
          await api2D.editor.setPhoto(
            {
              thumbnailUrl: photo.thumbnailUrl,
              rows: photo.tileset.rows,
              cols: photo.tileset.cols,
              getTileURL: (row: number, col: number) =>
                photo.tileset?.tileURLs.get(`${row} ${col}`) ?? '',
            },
            setDownloadProgress,
          );
        } else if (photo.url) {
          await api2D.editor.setPhoto(photo.url, setDownloadProgress);
        }
      } catch (error) {
        toast({ type: 'warn', message: error as string, lifespan: 10000 });
      }
      setTargetReady(true);

      // Update zoom levels
      setZoom(api2D.editor.getZoom());
      setMinZoom(api2D.editor.getMinZoom());

      // Render annotations
      for (const group of annotationGroups) {
        for (const annotation of group.annotations) {
          if (annotation.sketch2D) {
            if (annotation.photo === photo && visibleAnnotations.has(annotation.id)) {
              api2D.editor.show(annotation.sketch2D);
            } else {
              api2D.editor.hide(annotation.sketch2D);
            }
          }
        }
      }
    };
    showTarget();
  }, [api2D, photo, annotationGroups, visibleAnnotations]);

  // Attach on-click listeners
  useEffect(() => {
    api2D?.editor.setListener(sketch => {
      for (const handler of listeners.onSketch2DTap) {
        handler(sketch);
      }
    });
  }, [api2D, listeners]);

  // Handle zoom setting
  const handleZoom = (zoom: number) => {
    const clampedZoom = Math.max(minZoom, Math.min(maxZoom, zoom));
    api2D?.editor.setZoom(clampedZoom);
    setZoom(clampedZoom);
  };

  // Handle resetting the view
  const handleReset = () => {
    api2D?.editor.resetView();
  };

  // Handle downloading the photo
  const handleDownload = () => {
    if (photo.url) {
      window.open(photo.url);
    }
  };

  // Close when pressing escape key
  useEffect(() => {
    const keyHandler = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && focused) {
        handleClose();
      }
    };
    document.addEventListener('keydown', keyHandler);
    return () => {
      document.removeEventListener('keydown', keyHandler);
    };
  });

  const handleView3D = async () => {
    if (!loadingMutex && photo) {
      if (photo.type === 'photo2D') {
        stopPhotoOverlay();
        stopPanoramaView();
        await startPhotoOverlay(photo as Photo2D);
      } else if (photo.type === 'panorama') {
        stopPhotoOverlay();
        stopPanoramaView();
        await startPanoramaWalkthrough(photo as Panorama);
      }
    }
  };

  const handleCameraWidgetColor = (color: Color) => {
    setCameraWidgetColor(color);
    if (photo.widget) {
      const camera = photo.widget.getModel() as PhotoCamera;
      camera.setColor(color);
      api3D?.deproject.setColor(color);

      useViewer.setState(prev => {
        const popupPhotos = new Set(prev.popupPhotos);
        for (const el of popupPhotos) {
          if (el.id === photo.id) {
            el.color = new Color(color);
          }
        }
        return { popupPhotos };
      });
    }
  };

  return (
    <Rnd
      className={cn(
        'pointer-events-auto',
        'fixed',
        focused && 'z-1',
        'border-2',
        'border-neutral-100',
        'border-rounded-1',
        'border-solid',
        'pointer-events-auto',
      )}
      focused={focused}
      height={480}
      minHeight={120}
      minWidth={350}
      resizeThreshold={10}
      width={640}
    >
      <div
        className={cn(
          'absolute',
          'top-0',
          'h-9',
          'w-full',
          'z-1',
          'flex',
          'flex-row',
          'justify-between',
          'bg-neutral-100',
          'px-3',
          'py-2',
          'skand-rnd-enable',
        )}
        onClick={handleFocus}
      >
        <div className="flex flex-row items-center gap-2">
          <span
            className={cn(
              'overflow-hidden',
              focused ? 'color-neutral-500' : 'color-neutral-300',
              'typo-text-s',
            )}
          >
            {photo.name}
          </span>
          {imageViewerSortFlag && (
            <button
              className={cn(
                'bg-contain',
                'h-16px w-16px',
                'rounded-1',
                'b-1px b-solid b-neutral-400',
                'rounded-xl',
                'hover:cursor-pointer',
              )}
              onClick={() => setShowColorPicker(!showColorPicker)}
              style={{ backgroundColor: `#${cameraWidgetColor.getHexString()}` }}
            />
          )}
          {showColorPicker && (
            <ColorPickerMenu
              closeMenu={() => setShowColorPicker(false)}
              color={cameraWidgetColor}
              selectColor={handleCameraWidgetColor}
              x={100}
              y={200}
            />
          )}
        </div>

        <div className={'flex flex-row gap-2 items-center'}>
          <div
            className={cn(
              'flex',
              'flex-row',
              'gap-1',
              'flex',
              'flex-row',
              'items-center',
              'justify-between',
              'mr-6',
            )}
          >
            <div
              className={cn(
                'h-5',
                'w-5',
                'flex',
                'items-center',
                'justify-center',
                'border-1',
                'border-neutral-500',
                'rounded-1',
                'border-solid',
                'bg-neutral-100',
                'color-neutral-600',
                'hover:color-primary-400',
              )}
              onClick={() => handleZoom(zoom - zoomStep)}
            >
              <div className={cn('i-skand-subtract', 'cursor-pointer', 'text-12px')} />
            </div>
            <div className="w-163px">
              <Slider
                max={maxZoom}
                min={minZoom}
                onValueChange={value => handleZoom(value[0])}
                step={zoomStep}
                value={[zoom]}
              />
            </div>
            <div
              className={cn(
                'h-5',
                'w-5',
                'flex',
                'items-center',
                'justify-center',
                'border-1',
                'border-neutral-500',
                'rounded-1',
                'border-solid',
                'bg-neutral-100',
                'color-neutral-600',
                'hover:color-primary-400',
              )}
              onClick={() => handleZoom(zoom + zoomStep)}
            >
              <div className={cn('i-skand-add', 'cursor-pointer', 'text-12px')} />
            </div>
          </div>

          {imageViewerSortFlag && (
            <div>
              <button
                className={cn(
                  'cursor-pointer',
                  'bg-transparent',
                  'b-none',
                  focused ? 'color-neutral-500' : 'color-neutral-300',
                )}
                onClick={handleView3D}
                onMouseEnter={() => setIsView3DTooltip(true)}
                onMouseLeave={() => setIsView3DTooltip(false)}
              >
                <PhotoGroupIcon className={cn('mt-1 h-[18px]', 'w-[18px]')} />
              </button>
              {isView3DTooltip && (
                <span
                  className={cn(
                    'absolute',
                    'translate-x--15px',
                    'translate-y--110%',
                    'w-auto',
                    'bg-neutral-800',
                    'text-neutral-100',
                    'typo-text-small',
                    'px-2',
                    'py-1',
                    'rounded-1',
                    'gap-10px',
                    'whitespace-nowrap',
                  )}
                >
                  Open in 3D Image Viewer
                </span>
              )}
            </div>
          )}
          <div>
            <button
              className={cn(
                'cursor-pointer',
                'bg-transparent',
                'b-none',
                focused ? 'color-neutral-500' : 'color-neutral-300',
              )}
              onClick={handleReset}
              onMouseEnter={() => setIsResetTooltip(true)}
              onMouseLeave={() => setIsResetTooltip(false)}
            >
              <div className={cn('text-15px', 'text-right', 'i-skand-undo')} />
            </button>
            {isResetTooltip && (
              <span
                className={cn(
                  'absolute',
                  'translate-x--15px',
                  'translate-y--120%',
                  'w-auto',
                  'bg-neutral-800',
                  'text-neutral-100',
                  'typo-text-small',
                  'px-2',
                  'py-1',
                  'rounded-1',
                  'gap-10px',
                  'whitespace-nowrap',
                )}
              >
                Reset zoom
              </span>
            )}
          </div>
          <div>
            <button
              className={cn(
                'cursor-pointer',
                'bg-transparent',
                'b-none',
                focused ? 'color-neutral-500' : 'color-neutral-300',
              )}
              onClick={handleDownload}
              onMouseEnter={() => setIsDownloadTooltip(true)}
              onMouseLeave={() => setIsDownloadTooltip(false)}
            >
              <div className={cn('text-15px', 'text-right', 'i-skand-download')} />
            </button>
            {isDownloadTooltip && (
              <span
                className={cn(
                  'absolute',
                  'translate-x--15px',
                  'translate-y--120%',
                  'w-auto',
                  'bg-neutral-800',
                  'text-neutral-100',
                  'typo-text-small',
                  'px-2',
                  'py-1',
                  'rounded-1',
                  'gap-10px',
                  'whitespace-nowrap',
                )}
              >
                Download image
              </span>
            )}
          </div>
          <div>
            <button
              className={cn(
                'cursor-pointer',
                'bg-transparent',
                'b-none',
                focused ? 'color-neutral-500' : 'color-neutral-300',
              )}
              onClick={handleClose}
              onMouseEnter={() => setIsCloseTooltip(true)}
              onMouseLeave={() => setIsCloseTooltip(false)}
            >
              <div className={cn('text-12px', 'text-right', 'i-skand-close')} />
            </button>
            {isCloseTooltip && (
              <span
                className={cn(
                  'absolute',
                  'translate-x--15px',
                  'translate-y--120%',
                  'w-auto',
                  'bg-neutral-800',
                  'text-neutral-100',
                  'typo-text-small',
                  'px-2',
                  'py-1',
                  'rounded-1',
                  'gap-10px',
                  'whitespace-nowrap',
                )}
              >
                Close
              </span>
            )}
          </div>
        </div>
      </div>
      <div
        className={cn(
          'h-full',
          'w-full',
          'flex',
          'justify-center',
          'items-center',
          'skand-rnd-disable',
        )}
        onClick={handleFocus}
      >
        {!targetReady && (
          <div
            className={cn(
              'h-full',
              'w-full',
              'flex',
              'flex-col',
              'items-center',
              'justify-center',
              'z-1',
              'pointer-events-none',
            )}
          >
            <p className={cn('color-neutral-200', 'typo-text-small')}>
              Select an image to view here
            </p>

            {downloadProgress > 0 && downloadProgress < 1 && (
              <div className="p-t-4">
                <Progress className="w-153px" progress={downloadProgress * 100} />
              </div>
            )}
          </div>
        )}
        <canvas className={cn('absolute', 'h-full', 'w-full', 'bg-neutral-800')} ref={setElement} />
      </div>
    </Rnd>
  );
};
