import { cn, toast } from '@skand/ui';
import { ViewerAPI } from '@skand/viewer-component-v2';
import { useEffect, useMemo, useState } from 'react';
import { DeathCard } from './DeathCard';
import { HealthCard } from './HealthCard';
import { InfoCard, PlayerData } from './InfoCard';

interface GameMenuProps {
  api3D: ViewerAPI;
}

export const GameMenu = ({ api3D }: GameMenuProps) => {
  const gameId = api3D.game.getGameId() ?? null;

  const [dead, setDead] = useState<boolean>(false);
  const [showInfo, setShowInfo] = useState<boolean>(false);
  const [players, setPlayers] = useState<PlayerData[]>([...api3D.game.getActors().values()]);

  // Attach keyboard shortcut listeners
  useEffect(() => {
    const keydownCb = (ev: KeyboardEvent) => {
      if (ev.key.toLowerCase() === 'q') {
        setShowInfo(true);
      }
    };
    const keyupCb = (ev: KeyboardEvent) => {
      if (ev.key.toLowerCase() === 'q') {
        setShowInfo(false);
      }
    };
    document.addEventListener('keydown', keydownCb);
    document.addEventListener('keyup', keyupCb);
    return () => {
      document.removeEventListener('keydown', keydownCb);
      document.removeEventListener('keyup', keyupCb);
    };
  }, []);

  // Attach listeners to game service
  useEffect(() => {
    api3D.game.setKillHandler((player, target) => {
      toast({
        type: 'info',
        message: `${player.name} killed ${target.name}`,
        lifespan: 5000,
      });
      if (target.id === api3D.game.getPlayerId()) {
        setDead(true);
      }
    });
    api3D.game.setRespawnHandler(() => setDead(false));
    api3D.game.setScoreUpdateHandler(() => {
      setPlayers([...api3D.game.getActors().values()]);
    });
  }, [api3D]);

  // Grab the current player health
  const health = useMemo(() => {
    const playerData = players.find(data => data.id === api3D.game.getPlayerId());
    return playerData?.lives ?? 10;
  }, [api3D, players]);

  return (
    <>
      <div
        className={cn(
          'i-skand-gear',
          'text-32px',
          'absolute',
          'left-10',
          'top-10',
          'hover:text-neutral-4',
          'text-neutral-1',
          'hover:cursor-pointer',
          'pointer-events-auto',
        )}
        onClick={() => setShowInfo(true)}
      />
      {dead && <DeathCard />}
      {showInfo && gameId && (
        <InfoCard
          gameId={gameId}
          onClose={() => setShowInfo(false)}
          onLeave={() => api3D.game.leave()}
          players={players}
        />
      )}
      <HealthCard health={health} />
    </>
  );
};
