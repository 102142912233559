export const FileGroupIcon = () => {
  return (
    <svg fill="none" height="42" viewBox="0 0 44 42" width="44" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M39.4072 7.20068H5.43871C4.98043 7.20097 4.54101 7.38314 4.21696 7.70719C3.89291 8.03124 3.71073 8.47066 3.71045 8.92894V40.0954C3.71073 40.5537 3.89291 40.9931 4.21696 41.3171C4.54101 41.6412 4.98043 41.8234 5.43871 41.8236H39.4072C39.8655 41.8234 40.305 41.6412 40.6291 41.3172C40.9532 40.9931 41.1354 40.5537 41.1357 40.0954V8.92894C41.1354 8.47063 40.9532 8.03118 40.6291 7.70713C40.305 7.38307 39.8655 7.20092 39.4072 7.20068Z"
        fill="#D8D6D9"
      />
      <path
        d="M37.1709 30.9937H7.67503C6.7206 30.9937 5.94678 30.2199 5.94678 29.2654V1.72826C5.94678 0.773825 6.7206 0 7.67503 0H37.1709C38.1253 0 38.8991 0.773825 38.8991 1.72826V29.2654C38.8991 30.2199 38.1253 30.9937 37.1709 30.9937Z"
        fill="#F5F3F6"
      />
      <path
        d="M6.49395 29.8124V2.27543C6.49395 1.321 7.26778 0.547174 8.22221 0.547174H37.718C38.0552 0.547174 38.3685 0.645152 38.6343 0.812016C38.3288 0.324807 37.7884 0 37.1709 0H7.67503C6.7206 0 5.94678 0.773825 5.94678 1.72826V29.2654C5.94678 29.8831 6.27158 30.4233 6.75879 30.729C6.59193 30.4631 6.49395 30.1497 6.49395 29.8126V29.8124Z"
        fill="white"
      />
      <path
        d="M33.542 5.81836H11.3047V7.08564H33.542V5.81836ZM33.542 10.6573H11.3047V11.9245H33.542V10.6573ZM33.542 15.4972H11.3047V16.7645H33.542V15.4972ZM33.542 20.3361H11.3047V21.6036H33.542V20.3361Z"
        fill="white"
      />
      <path d="M13.9546 16.2451L19.5425 21.8332H13.9546V16.2451Z" fill="#F5F3F6" />
      <path d="M41.1358 22.2944V9.43721L38.8994 7.20068V22.2942H41.1358V22.2944Z" fill="#D8D6D9" />
      <path
        d="M43.7552 23.4576L41.3458 40.5138C41.2253 41.3665 40.4956 42.0003 39.6345 42.0003H5.21174C4.35065 42.0003 3.62091 41.3665 3.50044 40.5138L0.244345 17.4667C0.097468 16.4262 0.904844 15.4966 1.95565 15.4966H12.5861C13.4472 15.4966 14.1769 16.1303 14.2974 16.9828L14.7241 20.001C14.8446 20.8537 15.5743 21.4875 16.4354 21.4875H42.0443C43.0947 21.4875 43.9023 22.4171 43.7554 23.4576H43.7552Z"
        fill="#D8D6D9"
      />
      <path
        d="M35.2181 34.3925H9.62821C9.48794 34.3924 9.35251 34.3412 9.24728 34.2485C9.14206 34.1557 9.07426 34.0278 9.05658 33.8887L8.73642 31.3538C8.72624 31.2727 8.73343 31.1903 8.7575 31.1122C8.78158 31.0341 8.82198 30.962 8.87604 30.9007C8.93009 30.8394 8.99657 30.7903 9.07105 30.7567C9.14553 30.723 9.22631 30.7056 9.30804 30.7056H35.5383C35.62 30.7056 35.7008 30.723 35.7753 30.7567C35.8498 30.7903 35.9163 30.8394 35.9703 30.9007C36.0244 30.962 36.0648 31.0341 36.0889 31.1122C36.1129 31.1903 36.1201 31.2727 36.1099 31.3538L35.7898 33.8887C35.7721 34.0278 35.7043 34.1557 35.5991 34.2485C35.4938 34.3412 35.3584 34.3924 35.2181 34.3925Z"
        fill="white"
      />
    </svg>
  );
};
