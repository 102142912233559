import { Layer } from '@/stores/viewer';
import { cn } from '@/utils/classname';
import { getLayerIcon } from '@/utils/nodeIcons';
export interface LayerNodeProps {
  layer: Layer;
  setTargetLayer: (layer: Layer) => void;
}

export const LayerNode = ({ layer, setTargetLayer }: LayerNodeProps) => {
  return (
    <div
      className={cn('flex justify-between items-center py-1')}
      onClick={() => setTargetLayer(layer)}
    >
      <div className={cn('flex items-center gap-2 text-neutral-600')}>
        <div
          className={cn('w-5 color-neutral-600 text-3 flex-none', getLayerIcon(layer.formatType))}
        />
        <p className={cn('typo-text-small text-neutral-800 whitespace-nowrap')}>{layer.name}</p>
      </div>
    </div>
  );
};
