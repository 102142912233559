import { queryClient } from '@/graphql/client';
import { SystemNodeDependantDomain } from '@/graphql/codegen/graphql';
import { useExplore } from '@/stores/explore';
import { useFiles } from '@/stores/files';
import { resetBeforeClose, resetUploadStore, setIsOpen, useUploadStore } from '@/stores/upload';
import { Button, Modal, toast } from '@skand/ui';
import {
  AwsMultipartUploaderFactory,
  useCreateNewUploadSession,
  useUploadSession,
} from '@skand/uploader';
import { useCallback, useEffect } from 'react';
import {
  CHUNK_SIZE_OPTION,
  FAILURE_STAGES,
  PREPARED_STAGES,
  PREPARING_STAGES,
  UPLOADING_STAGES,
} from '../Upload/constants';
import { awsMultipartRequestService, uploadSessionRequestService } from '../Upload/requestServices';
import { displayErrors } from '../Upload/utils';
import '../UploadLegacy/globals';
import { FileSection } from './FileSection/FileSection';

export const UploadUserFiles = () => {
  const { uploadSession, createNewUploadSession } = useCreateNewUploadSession(
    new AwsMultipartUploaderFactory(CHUNK_SIZE_OPTION['500M'], awsMultipartRequestService),
    uploadSessionRequestService,
    {
      pauseBeforeUploadFiles: false,
      rateLimiterOptions: { maxConcurrency: 5 },
    },
  );

  const { enabledUploader, project } = useFiles(state => state);
  const projectId = useExplore(state => state.projectId);

  const targetNodeId = useUploadStore(state => state.targetNodeId);
  const beforeClose = useUploadStore(state => state.beforeClose);

  const { error: uploadSessionError, stage, uploadProgress } = useUploadSession(uploadSession);
  const error = uploadSessionError as null | Error;

  const canCancel = [
    ...PREPARING_STAGES,
    ...PREPARED_STAGES,
    ...UPLOADING_STAGES,
    ...FAILURE_STAGES,
  ].includes(stage);

  const handleClose = useCallback(() => {
    beforeClose?.();
    setIsOpen(false);
    resetUploadStore();
    resetBeforeClose();
    useFiles.setState({ enabledUploader: false });
  }, [beforeClose]);

  const handleCancel = useCallback(() => {
    handleClose();
    uploadSession.abort();
    createNewUploadSession();
  }, [createNewUploadSession, handleClose, uploadSession]);

  useEffect(() => {
    if (!project || !project.userFilesRootFolderNodeId) return;
    const onAddFiles = async () => {
      await uploadSession.start({
        dependants: [
          {
            domainName: SystemNodeDependantDomain.Project,
            entityId: projectId as string,
          },
        ],
        featureType: 'FILE_SYSTEM',
        parentSystemFolderNodeId: project.userFilesRootFolderNodeId,
        persistType: 'IMMEDIATE',
        priority: 'IMMEDIATE',
      });
    };

    const onSuccess = () => {
      queryClient.invalidateQueries(['LIST_SYSTEM_NODES_BY_PARENT_NODE_ID']);
      toast({
        type: 'success',
        message: `Successfully added file${uploadProgress.numberOfFilesUploaded > 1 ? 's' : ''}!`,
        lifespan: 5000,
      });
    };

    const unsubscribeFileTreeChange = uploadSession.subscribeFileTreeChange(onAddFiles);
    const unsubscribeSuccess = uploadSession.subscribeSuccess(onSuccess);

    return () => {
      unsubscribeFileTreeChange();
      unsubscribeSuccess();
    };
  }, [targetNodeId, projectId, uploadSession, project, uploadProgress.numberOfFilesUploaded]);

  if (enabledUploader)
    return (
      <Modal className="w-[682px] flex px-6 pb-6 pt-7">
        <></>
        <p className="pb-3 color-neutral-800 typo-text-m">Upload new files</p>
        <p className="color-neutral-500 typo-text-s">
          Drag and drop files to upload in this project. Files will be automatically synced with the
          Project folder.
        </p>
        <div>
          <FileSection uploadSession={uploadSession} />

          <div className="mt-3">
            {error && (
              <p className="text-right color-alert-400 typo-text-s">
                Something went wrong during uploading. Please retry. <br />
                {displayErrors(error)}
              </p>
            )}
          </div>

          <div className="mt-3 flex flex-none justify-end gap-3">
            <Button
              className="flex-1 cursor-pointer"
              filled={!canCancel}
              onClick={handleCancel}
              primary={!canCancel}
              size="s"
            >
              {canCancel ? 'Cancel' : 'Close'}
            </Button>
          </div>
        </div>
      </Modal>
    );
};
