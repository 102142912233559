import { SPLIT_API_KEY, USER_EMAIL_NAME } from '@/constants/env';
import Cookies from 'js-cookie';

// Make sure user key isn't undefined when user doesn't have the cookie
// Otherwise, it will return `control` treatment
const USER_EMAIL = (USER_EMAIL_NAME && Cookies.get(USER_EMAIL_NAME)) || 'user';

export const config = {
  core: {
    authorizationKey: SPLIT_API_KEY,
    key: USER_EMAIL,
  },
};

export const PANORAMA_PROCESS_FEATURE_FLAG = 'explore-panorama-process';
export const MANUAL_ANNOTATION_PROCESS_FEATURE_FLAG = 'explore-manual-annotation-process';
export const RENDERER_STATISTICS_FLAG = 'explore-renderer-statistics';
export const AI_PREDICTOR = 'explore-ai-predictor';
export const GOOGLE_EARTH_USER = 'google-earth-globe';
export const GOOGLE_EARTH_ACCOUNT = 'explore-google-earth-account';
export const ANNOTATION_CSV = 'annotation-csv';
export const MULTITHREADING_OBJ = 'explore-multithreading-obj';
export const ESRI_CSV_FLAG = 'explore-esri-csv';
export const TERRA_GEO_JSON_FLAG = 'explore-terraexplorer-geojson';
export const ADVANCED_UPLOADER = 'advanced-uploader';
export const MODEL_TRAINING = 'explore-model-training';
export const EXPLORE_SCENE_TREE_ENHANCE = 'explore-scene-tree-enhance';
export const EXPLORE_FILES_TAB = 'explore-files-tab';
export const DATASETS_ADVANCED_DOWNLOADER = 'datasets-advanced-downloader';
export const ANNOTATION_ORDERED_NAME = 'explore-annotation-ordered-name';
export const GAME_MODE = 'explore-game';
export const IMAGE_VIEWER_SORT = 'explore-image-viewer-sort';
