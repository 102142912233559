import { cn, toast } from '@skand/ui';
import { ViewerAPI } from '@skand/viewer-component-v2';
import { useEffect, useState } from 'react';
import { GameMenu } from './GameMenu';
import { MainMenu } from './MainMenu';

interface OverlayProps {
  api3D: ViewerAPI;
}

export const Overlay = ({ api3D }: OverlayProps) => {
  const [gameId, setGameId] = useState<string | null>(null);

  // Listen for changes in the connection change handler
  useEffect(() => {
    api3D.game.setConnectionChangeHandler(nextGameId => {
      setGameId(prevGameId => {
        if (!prevGameId && !nextGameId) {
          toast({
            type: 'warn',
            message: 'Could not connect to host.',
            lifespan: 5000,
          });
        } else if (prevGameId && !nextGameId) {
          toast({
            type: 'info',
            message: 'Disconnected from host.',
            lifespan: 5000,
          });
        } else {
          toast({
            type: 'info',
            message: 'Connected to host.',
            lifespan: 5000,
          });
        }
        return nextGameId;
      });
    });
  }, [api3D]);

  return (
    <div
      className={cn(
        'h-full',
        'w-full',
        'left-0',
        'top-0',
        'absolute',
        'z-2',
        'pointer-events-none',
      )}
    >
      {gameId === null ? <MainMenu api3D={api3D} /> : <GameMenu api3D={api3D} />}
    </div>
  );
};
