import { useShareLink } from '@/stores/shareLink';
import { AnnotationGroup, Layer, LayerGroup, PhotoGroup, useViewer } from '@/stores/viewer';
import { cn } from '@/utils/classname';
import { getLayerIcon } from '@/utils/nodeIcons';
import { Graph } from '@skand/math';
import { useMemo } from 'react';

export interface LayerNodeProps {
  layer: Layer;
}

export const LayerNode = ({ layer }: LayerNodeProps) => {
  const layers = useViewer(state => state.layers);
  const layerGroups = useViewer(state => state.layerGroups);
  const annotationGroups = useViewer(state => state.annotationGroups);
  const photo2DGroups = useViewer(state => state.photo2DGroups);
  const panoramaGroups = useViewer(state => state.panoramaGroups);

  const visibleLayerIds = useShareLink(state => state.visibleLayerIds);
  const visiblePhotoGroupIds = useShareLink(state => state.visiblePhotoGroupIds);
  const selectedSceneEntityIds = useShareLink(state => state.selectedSceneEntityIds);
  const isEnabled = selectedSceneEntityIds.includes(layer.sceneEntityId);

  const [members, photoGroups] = useMemo(() => {
    const entities = [
      ...layerGroups,
      ...layers,
      ...annotationGroups,
      ...photo2DGroups,
      ...panoramaGroups,
    ];
    const graph = new Graph<Layer | LayerGroup | AnnotationGroup | PhotoGroup>();
    for (const entity of entities) {
      graph.addNode(entity);
      if (entity.parent) {
        graph.addEdge(entity.parent, entity);
      }
    }

    const members: Layer[] = [];
    const photoGroups: PhotoGroup[] = [];

    graph.bfs(layer, node => {
      switch (node.type) {
        case 'layer':
          members.push(node);
          break;
        case 'photoGroup':
          photoGroups.push(node);
          break;
      }
    });

    return [members, photoGroups];
  }, [layerGroups, layers, annotationGroups, photo2DGroups, panoramaGroups, layer]);

  const isVisible =
    members.some(layer => visibleLayerIds.has(layer.id)) ||
    photoGroups.some(group => visiblePhotoGroupIds.has(group.id));

  const handleToggle = () => {
    if (isVisible) {
      useShareLink.setState(prev => {
        const visibleLayerIds = new Set(prev.visibleLayerIds);
        for (const layer of members) {
          visibleLayerIds.delete(layer.id);
        }
        for (const group of photoGroups) {
          visiblePhotoGroupIds.delete(group.id);
        }
        return { visibleLayerIds, visiblePhotoGroupIds };
      });
    } else {
      useShareLink.setState(prev => {
        const visibleLayerIds = new Set(prev.visibleLayerIds);
        for (const layer of members) {
          visibleLayerIds.add(layer.id);
        }
        return { visibleLayerIds };
      });
    }
  };

  return (
    <div className={cn('flex justify-between items-center py-1')}>
      <div className={cn('flex items-center gap-2 text-neutral-600')}>
        <div
          className={cn('w-5 color-neutral-600 text-3 flex-none', getLayerIcon(layer.formatType))}
        />
        <p className={cn('typo-text-small text-neutral-800 whitespace-nowrap')}>{layer.name}</p>
        <div
          className="fixed right-0 h-32px flex flex-none items-center gap-2 bg-neutral-100 pl-2"
          style={{ boxShadow: '-8px 0px 8px -2px rgba(255,255,255,1)' }}
        >
          {isEnabled && (
            <div
              className={cn(isVisible ? 'i-skand-show' : 'i-skand-hide')}
              onClick={handleToggle}
            />
          )}
        </div>
      </div>
    </div>
  );
};
