import { cn } from '@skand/ui';
import { useState } from 'react';

interface Tab {
  label: string;
  content: React.ReactNode;
}

interface TabViewProps {
  tabs: Tab[];
}

export const TabView = ({ tabs }: TabViewProps) => {
  const [activeTab, setActiveTab] = useState<number>(0);

  const handleTabClick = (index: number) => {
    setActiveTab(index);
  };

  return (
    <div
      className={cn(
        'border-neutral-400',
        'border-1',
        'rounded-6px',
        'border-solid',
        'w-full',
        'flex',
        'flex-col',
      )}
    >
      <div className={cn('w-full flex justify-between px-3 py-3')}>
        {tabs.map((tab, index) => (
          <div
            className={`cursor-pointer flex items-center justify-center border-b-solid border-neutral-300 border-b-1  pb-3 w-full  ${
              activeTab === index
                ? 'border-b-2 border-primary-400 font-semibold border-b-solid color-primary-400'
                : 'text-gray-600 hover:text-gray-900'
            }`}
            key={index}
            onClick={() => handleTabClick(index)}
          >
            <p
              className={cn(
                activeTab === index
                  ? 'typo-text-s-em	color-primary-400'
                  : 'typo-text-s color-neutral-500',
              )}
            >
              {tab.label}
            </p>
          </div>
        ))}
      </div>

      <div className="border border-gray-300 rounded-md px-4 py-2">{tabs[activeTab].content}</div>
    </div>
  );
};
