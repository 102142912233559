import { downloadBlob, downloadImage } from '@/utils/download';
import {
  Draw2D,
  Drawable,
  GestureControl,
  ImageDrawable,
  TiledTextureSource,
} from '@skand/viewer-component-v2';
import { Vector2 } from 'three';
import { DrawController, NavigationController } from './Controllers';
import { Sketch2 } from './Sketch2';
import { Transform2 } from './Transform2';

/**
 * API for rendering and editing 2D sketches on an image.
 */
export class Editor {
  private canvas: HTMLCanvasElement;
  private draw2D: Draw2D;
  private gestures: GestureControl;

  private transform: Transform2;
  private navigationController: NavigationController;
  private drawController: DrawController;

  private dimensions: Vector2;
  private current: ImageDrawable;

  private sketches: Map<Sketch2, Drawable[]>;

  private loading: boolean;

  private targetTransform: Transform2 | null;

  private abortController: AbortController | undefined;

  private deferZoomCluster: Vector2[] | null;

  private listener: (sketch: Sketch2) => void;

  constructor(canvas: HTMLCanvasElement) {
    this.canvas = canvas;
    this.draw2D = new Draw2D(canvas);
    this.gestures = new GestureControl(canvas);

    this.transform = new Transform2();
    this.navigationController = new NavigationController(this.canvas, this.transform);
    this.drawController = new DrawController(
      this.draw2D,
      this.transform,
      this.navigationController,
    );

    this.dimensions = new Vector2();
    this.current = {
      type: 'image',
      src: {
        imageType: 'element',
        blob: new Image(),
      },
      position: new Vector2(),
      opacity: 1.0,
      destinationSize: new Vector2(),
      zIndex: -1,
    };
    this.sketches = new Map();

    this.loading = false;
    this.targetTransform = null;
    this.abortController = undefined;
    this.listener = () => {};

    this.deferZoomCluster = null;

    this.initializeControls();
  }

  /**
   * Apply translation and scaling to the image and the sketches.
   */
  private applyTransform() {
    this.current.destinationSize = this.transform.scaleDimensions(this.dimensions);
    this.current.position = this.transform.getTranslation();

    for (const [sketch, drawables] of this.sketches) {
      sketch.transformDrawables(drawables, this.transform);
    }
    this.drawController.applyTransform();
  }

  /**
   * Refresh the editor with the latest updates.
   */
  private refresh() {
    // Refresh the drawn image
    this.draw2D.removeDrawable(this.current);
    this.draw2D.addDrawable(this.current);

    // Check if we have an enqueued zoom target
    if (this.deferZoomCluster) {
      this.zoomToCluster(this.deferZoomCluster);
      this.deferZoomCluster = null;
    }
  }

  /**
   * Load full resolution photo.
   *
   * @param url
   * @param onProgress
   */
  private loadFullPhoto(url: string, onProgress: (percent: number) => void = () => {}) {
    return new Promise<HTMLImageElement>((resolve, reject) => {
      const img = new Image();
      downloadBlob(url, onProgress, this.abortController)
        .then(blob => (img.src = window.URL.createObjectURL(blob)))
        .catch(error => error !== 'abort' && reject(error));

      img.onload = () => {
        this.abortController = undefined;
        this.dimensions.x = img.width;
        this.dimensions.y = img.height;
        this.loading = false;
        this.refresh();
        this.resetView();
        resolve(img);
      };
      img.onerror = () => {
        reject(new Error(`Could not load image resource \`${url}\``));
      };
    });
  }

  /**
   * Load photo tiles.
   *
   * @param src
   * @param onProgress
   */
  private async loadTiledPhoto(
    src: TiledTextureSource,
    onProgress: (percent: number) => void = () => {},
  ) {
    const tile0 = await downloadImage(src.getTileURL(0, 0));
    return new Promise<HTMLCanvasElement>((resolve, reject) => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      if (!ctx) {
        reject(new Error('Could not create canvas context'));
        return;
      }

      const thumbnail = new Image();
      downloadBlob(src.thumbnailUrl, onProgress, this.abortController)
        .then(blob => (thumbnail.src = window.URL.createObjectURL(blob)))
        .catch(error => error !== 'abort' && reject(error));

      thumbnail.onload = () => {
        this.abortController = undefined;
        this.dimensions.x = src.cols * tile0.width;
        this.dimensions.y = src.rows * tile0.height;

        // Progressively load the tiles
        canvas.width = this.dimensions.x;
        canvas.height = this.dimensions.y;
        ctx.drawImage(thumbnail, 0, 0, canvas.width, canvas.height);
        for (let r = 0; r < src.rows; r++) {
          for (let c = 0; c < src.cols; c++) {
            downloadImage(src.getTileURL(r, c))
              .then(tile => {
                ctx.drawImage(tile, c * tile.width, r * tile.height);
                this.refresh();
              })
              .catch(error => {
                reject(error);
              });
          }
        }

        this.loading = false;
        this.refresh();
        this.resetView();
        resolve(canvas);
      };

      thumbnail.onerror = () => {
        reject(new Error(`Could not load image thumbnail resource \`${src.thumbnailUrl}\``));
      };
    });
  }

  /**
   * Initialize gesture handlers for controlling the camera.
   */
  private initializeControls() {
    this.navigationController.start();
    this.gestures.register('tap', gesture => {
      const { position } = gesture;

      // Sketch clicking
      for (const [sketch] of this.sketches) {
        if (sketch.isColliding(position, this.transform) && !this.drawController.isActive()) {
          this.listener(sketch);
          return;
        }
      }
    });
  }

  /**
   * Cleanup and destroy the editor instance.
   */
  public destroy() {
    this.drawController.stop();
    this.gestures.unregisterAll();
  }

  /**
   * Zoom in to a cluster of points on the image.
   *
   * This will defer the zoom until the canvas is in a valid state.
   *
   * @param vertices
   */
  public zoomToCluster(vertices: Vector2[]) {
    // Defer the zoom
    if (!this.canvas.width || !this.canvas.height) {
      this.deferZoomCluster = vertices;
      return;
    }

    // Calculate centroid
    const n = vertices.length;
    const centroid = new Vector2();
    for (const vertex of vertices) {
      centroid.add(vertex);
    }
    centroid.divideScalar(n);

    // Calculate zoom level
    let span = 0;
    for (let i = 0; i < n - 1; i++) {
      for (let j = i + 1; j < n; j++) {
        const dist = vertices[i].distanceTo(vertices[j]);
        span = Math.max(span, dist);
      }
    }
    const zoom = Math.min((this.canvas.width * 0.75) / span, (this.canvas.height * 0.75) / span);

    // Set target transform
    this.targetTransform = new Transform2();
    this.targetTransform.setZoom(zoom);
    this.targetTransform.setTranslation(centroid.x, centroid.y);
  }

  /**
   * Zoom in to a polygon on the image.
   *
   * This will defer the zoom until the canvas is in a valid state.
   *
   * @param vertices
   */
  public lookAt(sketch: Sketch2) {
    this.zoomToCluster(sketch.getVertices());
  }

  /**
   * Reset translation and zoom so the image fills the width of the canvas.
   */
  public resetView() {
    const zoom = this.canvas.width / this.dimensions.x;
    this.transform.setZoom(zoom);
    this.transform.setTranslation(0, (this.canvas.height - zoom * this.dimensions.y) / 2);
  }

  /**
   * Set the current photo.
   *
   * @param source
   * @param onProgress
   * @returns
   */
  public async setPhoto(
    source: string | TiledTextureSource,
    onProgress: (percent: number) => void = () => {},
  ) {
    // Abort previous requests
    this.abortController?.abort();
    this.abortController = new AbortController();

    // Hide current target
    this.draw2D.removeDrawable(this.current);
    this.loading = true;

    // Load image metadata
    let img: HTMLImageElement | HTMLCanvasElement;
    if (typeof source === 'string') {
      img = await this.loadFullPhoto(source, onProgress);
    } else {
      img = await this.loadTiledPhoto(source, onProgress);
    }

    // Update image drawable
    this.current.src = {
      imageType: 'element',
      blob: img as HTMLImageElement,
    };

    return this;
  }

  /**
   * Set the size of the editor.
   *
   * @param size
   */
  public setSize(size: Vector2) {
    this.draw2D.setSize(size);
  }

  /**
   * Set the zoom (center of canvas).
   *
   * @param zoom
   */
  public setZoom(zoom: number) {
    const canvasCenter = new Vector2(this.canvas.width / 2, this.canvas.height / 2);
    const imagePoint = this.transform.canvasToImageSpace(canvasCenter);
    const translation = imagePoint.multiplyScalar(-zoom).add(canvasCenter);
    this.transform.setZoom(zoom);
    this.transform.setTranslation(translation.x, translation.y);
  }

  /**
   * Get the current zoom.
   *
   * @returns
   */
  public getZoom() {
    return this.transform.getZoom();
  }

  /**
   * Calculate the minimum zoom level.
   */
  public getMinZoom() {
    return (this.canvas.width * 0.8) / this.dimensions.x;
  }

  /**
   * Set the navigation change callback.
   *
   * @param cb
   */
  public setOnTransform(cb: (transform: Transform2) => void) {
    this.transform.setListener(cb);
  }

  /**
   * Show a sketch.
   *
   * @param sketch
   */
  public show(sketch: Sketch2) {
    const drawables = sketch.buildDrawables();
    for (const drawable of drawables) {
      this.draw2D.addDrawable(drawable);
    }
    this.sketches.set(sketch, drawables);
  }

  /**
   * Hide a sketch.
   *
   * @param sketch
   */
  public hide(sketch: Sketch2) {
    const drawables = this.sketches.get(sketch);
    if (drawables) {
      for (const drawable of drawables) {
        this.draw2D.removeDrawable(drawable);
      }
    }
    this.sketches.delete(sketch);
  }

  /**
   * Clear all sketches.
   */
  public clear() {
    for (const [, drawables] of this.sketches) {
      for (const drawable of drawables) {
        this.draw2D.removeDrawable(drawable);
      }
    }
    this.sketches.clear();
  }

  /**
   * Register an on-click listener for sketches.
   *
   * @param onClick
   */
  public setListener(onClick: (sketch: Sketch2) => void) {
    this.listener = onClick;
  }

  /**
   * Detach an existing on-click listener.
   */
  public removeListener() {
    this.listener = () => {};
  }

  /**
   * Get the draw controller.
   *
   * @returns
   */
  public getDrawController() {
    return this.drawController;
  }

  /**
   * Update the current transform to fly towards target transform.
   */
  private updateFlyTo() {
    if (this.targetTransform !== null && !this.loading) {
      const imagePoint = this.targetTransform.getTranslation();
      const targetZoom = this.targetTransform.getZoom();
      const canvasCenter = new Vector2(this.canvas.width / 2, this.canvas.height / 2);
      const targetTranslation = imagePoint.clone().multiplyScalar(-targetZoom).add(canvasCenter);

      const currentTranslation = this.transform.getTranslation();
      const currentZoom = this.transform.getZoom();
      const deltaZoom = targetZoom - currentZoom;
      const deltaTranslate = targetTranslation.clone().sub(currentTranslation);
      if (
        Math.abs(deltaZoom) >= 0.01 ||
        Math.abs(deltaTranslate.x) >= 3 ||
        Math.abs(deltaTranslate.y) >= 3
      ) {
        this.transform.setZoom(deltaZoom * 0.1 + this.transform.getZoom());
        this.transform.setTranslation(
          deltaTranslate.x * 0.1 + currentTranslation.x,
          deltaTranslate.y * 0.1 + currentTranslation.y,
        );
      } else {
        this.transform.setTranslation(targetTranslation.x, targetTranslation.y);
        this.transform.setZoom(targetZoom);
        this.targetTransform = null;
      }
    }
  }

  /**
   * Update the drawable colors based on current sketch color.
   */
  private updateColors() {
    for (const [sketch, drawables] of this.sketches) {
      for (const drawable of drawables) {
        if (drawable.type === 'circle' || drawable.type === 'polygon' || drawable.type === 'line') {
          drawable.color = sketch.getColor();
        }
      }
    }
  }

  /**
   * Main update loop.
   */
  public update() {
    this.draw2D.render();
    this.navigationController.setMinZoom(this.getMinZoom());
    this.navigationController.update();
    this.updateFlyTo();
    this.updateColors();
    this.applyTransform();
  }
}
