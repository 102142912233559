import { ImageViewerSettings, Photo, Photo2D, PhotoGroup, useViewer } from '@/stores/viewer';
import { Quaternion, Vector3 } from 'three';
import { Process } from './process';
import { search } from './search';

/**
 * Approximate a maximum subsample of a given photo set such that the distances between photos satisfy a minimum bound.
 *
 * @param points
 * @param minimumDistance
 * @returns
 */
const spatialSubsample = (objects: Photo[], minimumDistance: number) => {
  if (objects.length === 0) return [];

  const samples: Photo[] = [objects[0]]; // Seed
  for (const a of objects) {
    let inBounds = true;
    const aPosition = a.widget?.getPosition();
    if (!aPosition) continue;

    for (const b of samples) {
      const bPosition = b.widget?.getPosition();
      if (!bPosition) continue;

      if (aPosition.distanceTo(bPosition) < minimumDistance) {
        inBounds = false;
        break;
      }
    }
    if (inBounds) {
      samples.push(a);
    }
  }
  return samples;
};

/**
 * Sort photos by filename.
 *
 * @param photos
 * @returns
 */
const sortByName = (photos: Photo[]) => {
  return photos.sort((a, b) =>
    a.name.localeCompare(b.name, undefined, {
      numeric: true,
    }),
  );
};

/**
 * Sort photos based on the distance between its projection point to
 * the given position.
 *
 * @param position
 * @param photos
 * @returns
 */
const sortPhoto2DsByProjection = (position: Vector3, photos: Photo2D[]) => {
  const target = new Vector3(position.x, position.y, position.z);
  return photos
    .map(photo => {
      const distance = photo.projectedPosition?.distanceTo(target) ?? 0;
      return { photo, distance };
    })
    .sort((a, b) => a.distance - b.distance)
    .map(data => data.photo);
};

/**
 * Sort photos based on distance to point.
 *
 * @param position
 * @param photos
 * @param alpha
 * @returns
 */
const sortByDisplacement = (position: Vector3, photos: Photo[], alpha: number) => {
  const { api3D } = useViewer.getState();
  if (!api3D) return photos;

  const cameraDirection = new Vector3(0, 0, -1).applyQuaternion(api3D.navigation.getRotation());
  let maxDistance = 0;
  return photos
    .map(photo => {
      // Forward direction of the photo's camera
      const photoDirection = new Vector3(0, 0, -1)
        .applyQuaternion(photo.widget?.getRotation() ?? new Quaternion())
        .normalize();

      const cosine = photoDirection.dot(cameraDirection);
      const distance = photo.widget?.getPosition().distanceTo(position) ?? 0;
      maxDistance = Math.max(maxDistance, distance);

      return { photo, cosine, distance };
    })
    .map(({ photo, cosine, distance }) => {
      const normCosine = 1 - (cosine + 1) / 2;
      const normDistance = maxDistance === 0 ? 1 : distance / maxDistance;
      const metric = normCosine * alpha + normDistance * (1 - alpha);
      return { photo, metric };
    })
    .sort((a, b) => a.metric - b.metric)
    .map(data => data.photo);
};

/**
 * Sort photos by their similarity to the user camera.
 *
 * @param photos
 */
const sortBySimilarityToCamera = (photos: Photo[]) => {
  const { api3D } = useViewer.getState();
  if (!api3D) return photos;

  const cameraDirection = new Vector3(0, 0, -1).applyQuaternion(api3D.navigation.getRotation());
  const cameraPosition = api3D.navigation.getPosition();

  return photos
    .map(photo => {
      // Forward direction of the photo's camera
      const photoDirection = new Vector3(0, 0, -1)
        .applyQuaternion(photo.widget?.getRotation() ?? new Quaternion())
        .normalize();

      const cosine = photoDirection.dot(cameraDirection);
      const distance = photo.widget?.getPosition().distanceTo(cameraPosition) ?? 0;
      return { photo, cosine, distance };
    })
    .sort((a, b) => {
      const cosineDiff = -(a.cosine - b.cosine);
      const distanceDiff = a.distance - b.distance;

      if (Math.abs(cosineDiff) < 0.005) {
        return distanceDiff;
      } else {
        return cosineDiff;
      }
    })
    .map(data => data.photo);
};

/**
 * Sort photos by their similarity to the user camera using a weighted average
 * between euclidean distance and cosine distance (angular displacement).
 *
 * @param photos
 * @param alpha
 * @returns
 */
const sortByWeightedCameraSimilarity = (photos: Photo[], alpha: number) => {
  const { api3D } = useViewer.getState();
  if (!api3D) return photos;

  const cameraDirection = new Vector3(0, 0, -1).applyQuaternion(api3D.navigation.getRotation());
  const cameraPosition = api3D.navigation.getPosition();

  let maxDistance = 0;
  return photos
    .map(photo => {
      // Forward direction of the photo's camera
      const photoDirection = new Vector3(0, 0, -1)
        .applyQuaternion(photo.widget?.getRotation() ?? new Quaternion())
        .normalize();

      const cosine = photoDirection.dot(cameraDirection);
      const distance = photo.widget?.getPosition().distanceTo(cameraPosition) ?? 0;
      maxDistance = Math.max(maxDistance, distance);

      return { photo, cosine, distance };
    })
    .map(({ photo, cosine, distance }) => {
      const normCosine = 1 - (cosine + 1) / 2;
      const normDistance = maxDistance === 0 ? 1 : distance / maxDistance;
      const metric = normCosine * alpha + normDistance * (1 - alpha);
      return { photo, metric };
    })
    .sort((a, b) => a.metric - b.metric)
    .map(data => data.photo);
};

/**
 * Apply the filters and sort settings to a list of photos.
 *
 * @param photos
 * @param settings
 * @param canShow
 * @returns
 */
export const sortAndFilterPhotos = async (
  photoGroups: PhotoGroup[],
  settings: ImageViewerSettings,
  canShow: boolean,
  process: Process | null,
) => {
  let filtered: Photo[] = [];
  for (const group of photoGroups) {
    for (const photo of group.photos) {
      await photo.widget?.hide();

      // Filter photos by process, if available
      if (!process || process.images.has(photo.id)) {
        filtered.push(photo);
      }
    }
  }
  const totalPhotos = filtered.length;

  // Filter photos by search key
  filtered = filtered.filter(photo => search(photo.name, settings.searchKey));

  // Filter by image groups
  filtered = filtered.filter(photo => settings.photoGroupIds.includes(photo.group.id));

  // Filter by process status
  filtered = filtered.filter(photo => {
    const processImage = process?.images.get(photo.id);
    if (!processImage) return true;
    else {
      return (
        (settings.showProcessDone && processImage.status === 'checked') ||
        (settings.showProcessDone && processImage.status === 'ignored') ||
        (settings.showProcessInProgress && processImage.status === 'created')
      );
    }
  });

  // Apply distance minimum gap distance
  if (settings.gapSize) {
    filtered = spatialSubsample(filtered, settings.gapSize);
  }

  // Sort photos
  switch (settings.sortMode) {
    case 'name':
      filtered = sortByName(filtered);
      break;
    case 'distance to camera':
    case 'distance to clicked point':
      filtered = sortByDisplacement(settings.sortPoint, filtered, 0);
      break;
    case 'relevance to point':
      filtered = sortByDisplacement(settings.sortPoint, filtered, settings.sortWeight);
      break;
    case 'similarity to camera':
      filtered = sortBySimilarityToCamera(filtered);
      break;
    case 'weighted similarity to camera':
      filtered = sortByWeightedCameraSimilarity(filtered, settings.sortWeight);
      break;
    case 'projected point':
      filtered = sortPhoto2DsByProjection(settings.sortPoint, filtered as Photo2D[]);
      break;
  }

  // Filter first count photos
  filtered = filtered.slice(0, settings.showAll ? totalPhotos : settings.count);

  // Show camera widgets
  if (settings.showCameras && canShow) {
    for (const photo of filtered) {
      await photo.widget?.show();
    }
  }

  return filtered;
};
