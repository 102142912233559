import { Label } from '@/components/Label';
import { PopupMenu } from '@/components/PopupMenu';
import { SystemNodeDependantDomain } from '@/graphql/codegen/graphql';
import { useFetchFileUrls } from '@/hooks/useFetchFileUrls';
import { useFetchSystemNodeChainV2 } from '@/hooks/useFetchSystemNodeChainV2';
import { useFiles } from '@/stores/files';
import { SystemNode } from '@/stores/systemNodes';
import { cn } from '@/utils/classname';
import dayjs from 'dayjs';
import { useMemo, useState } from 'react';
import { formatBytes } from '../../../Upload/utils';
import { ItemRow } from './ItemRow';

interface LinkLayerMenuProps {
  closeMenu: () => void;
  selectedFile: SystemNode;
}

export const ViewDetailsMenu = ({ closeMenu, selectedFile }: LinkLayerMenuProps) => {
  const filesWithUrls = useFetchFileUrls(selectedFile.fileId ? [selectedFile.fileId] : []);
  const parentNodesChain = useFetchSystemNodeChainV2(selectedFile.sourceNode?.parentNodeId, null);
  const { project } = useFiles(state => state);

  const [enabledOriginalPathTooltip, setEnabledOriginalPathTooltip] = useState(false);
  const [enabledFileTypeTooltip, setEnabledFileTypeTooltip] = useState(false);
  const [enabledFileNameTooltip, setEnabledFileNameTooltip] = useState(false);
  const isSystemManagedNode = [
    project?.rootFolderNodeId,
    project?.renderObjectsRootFolderNodeId,
    project?.annotationsRootFolderNodeId,
    project?.userFilesRootFolderNodeId,
  ].includes(selectedFile.id);

  const isAnnotationNode = selectedFile.dependants.includes(
    SystemNodeDependantDomain['Annotation'],
  );
  const isLayerNode = selectedFile.dependants.includes(SystemNodeDependantDomain['RenderObject']);

  const sizeMeta = formatBytes(filesWithUrls[0]?.size, 1);
  const size = `${sizeMeta.amount} ${sizeMeta.unit}`;
  const isLinkedNode = selectedFile.kind === 'LinkNode' || isSystemManagedNode;
  const isFile = selectedFile.type === 'file';

  const getFileTypeName = () => {
    if (isLayerNode || isAnnotationNode) {
      return 'System-created';
    } else if (isSystemManagedNode) {
      return 'System-managed';
    } else if (isLinkedNode) {
      return 'Linked';
    } else {
      return 'Default';
    }
  };

  const getFileType = () => (
    <div className="flex flex-row items-center justify-center gap-2">
      <p className="color-neutral-600 typo-text-s">{getFileTypeName()}</p>
      <span
        className={cn('i-skand-info', 'color-neutral-500', 'text-3.5', 'cursor-pointer')}
        onMouseEnter={() => setEnabledFileTypeTooltip(true)}
        onMouseLeave={() => setEnabledFileTypeTooltip(false)}
      />
    </div>
  );

  const filePath = useMemo(() => {
    if (!selectedFile.sourceNode?.parentNodeId) {
      return [selectedFile.name];
    } else if (!parentNodesChain || !selectedFile.parentId) return [];
    const folderNodes = structuredClone(parentNodesChain).reverse() as SystemNode[];
    const map = new Map<string, SystemNode>();
    let currentNode: SystemNode | undefined = undefined;

    for (const node of folderNodes) {
      map.set(node.id, node);
      if (!node.parentId) {
        currentNode = node;
      }
    }

    // Traverse from root to leaf
    const pathSegments: string[] = [];

    while (currentNode) {
      pathSegments.push(currentNode.name);
      currentNode = currentNode.id
        ? Array.from(map.values()).find(node => node.parentId === currentNode?.id)
        : undefined;
    }

    // Add file name at the end
    pathSegments.push(selectedFile.name);
    return pathSegments.join('/');
  }, [
    parentNodesChain,
    selectedFile.name,
    selectedFile.parentId,
    selectedFile.sourceNode?.parentNodeId,
  ]);

  const getFileTypeTooltip = () => {
    if (isAnnotationNode) {
      return `${
        isFile ? 'File' : 'Folder'
      } is auto-created when an annotation is added to this project.`;
    } else if (isLayerNode) {
      return `${
        isFile ? 'File' : 'Folder'
      } is auto-created when a layer is added to this project. `;
    } else if (isSystemManagedNode) {
      return `${isFile ? 'File' : 'Folder'} is auto-created for the project and is read-only.`;
    } else if (isLinkedNode) {
      return `${isFile ? 'File' : 'Folder'} is a shortcut to existing ${
        isFile ? 'file' : 'folder'
      } in Data Management.`;
    } else {
      return `${isFile ? 'File' : 'Folder'} is uploaded directly to this project.`;
    }
  };

  return (
    <PopupMenu
      closeMenu={closeMenu}
      containerStyles={cn(
        'w-[320px]',
        'flex',
        'bg-neutral-100',
        'rounded-2',
        'border-1px',
        'border-solid',
        'border-neutral-400',
        'p-3',
      )}
    >
      <div className="w-[320px] flex flex-col gap-2">
        <p className={cn('color-neutral-700 typo-text-m')}>{isFile ? 'File' : 'Folder'} details</p>
        <div className="relative">
          <ItemRow
            label="File name"
            onMouseEnter={() => setEnabledFileNameTooltip(selectedFile.name.length > 20)}
            onMouseLeave={() => setEnabledFileNameTooltip(false)}
            value={
              selectedFile.name.length >= 30
                ? selectedFile.name.slice(0, 30) + '...'
                : selectedFile.name
            }
          />
          {enabledFileNameTooltip && (
            <div className={cn('absolute', 'z-1', 'top-[20px]', 'left-[300px]')}>
              <Label
                css="bg-neutral-100 py-2 text-nowrap"
                ellipsis={false}
                labelTitle={selectedFile.name}
                labelType="default"
                textLength={selectedFile.name.length}
              />
            </div>
          )}
        </div>
        <div className="relative">
          <ItemRow label={`${isFile ? 'File' : 'Folder'} type`} value={getFileType()} />
          {enabledFileTypeTooltip && (
            <div className={cn('absolute', 'z-1', 'top-[20px]', 'left-[300px]')}>
              <Label
                css="bg-neutral-100 py-1 text-nowrap typo-text-s"
                ellipsis={false}
                labelTitle={getFileTypeTooltip()}
                labelType="default"
                textLength={400}
              />
            </div>
          )}
        </div>
        {isLinkedNode ? (
          <div className="relative">
            <ItemRow
              label="Original path"
              onMouseEnter={() => setEnabledOriginalPathTooltip(filePath.length > 37)}
              onMouseLeave={() => setEnabledOriginalPathTooltip(false)}
              value={filePath.length >= 30 ? filePath.slice(0, 30) + '...' : filePath}
            />
            {enabledOriginalPathTooltip && (
              <div className={cn('absolute', 'z-1', 'top-[20px]', 'left-[300px]')}>
                <Label
                  css="bg-neutral-100 py-2 text-nowrap"
                  ellipsis={false}
                  labelTitle={filePath as string}
                  labelType="default"
                  textLength={filePath.length}
                />
              </div>
            )}
          </div>
        ) : (
          <>
            <ItemRow
              label="Created date"
              value={
                selectedFile.createdAt
                  ? dayjs(selectedFile.createdAt).format('DD/MM/YYYY HH:mm:ss a')
                  : 'N/A'
              }
            />
            <ItemRow label="Created by" value={selectedFile.createdBy?.displayName || 'N/A'} />
            {selectedFile.type === 'file' && <ItemRow label="Size" value={size || 'N/A'} />}
          </>
        )}

        <ItemRow
          label="Last downloaded at"
          value={
            selectedFile.lastDownloadedAt
              ? dayjs(selectedFile.lastDownloadedAt).format('DD/MM/YYYY HH:mm:ss a')
              : 'N/A'
          }
        />
        <ItemRow
          label="Last downloaded by"
          value={selectedFile.lastDownloadedBy?.displayName || 'N/A'}
        />
      </div>
    </PopupMenu>
  );
};
