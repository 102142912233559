import { useFetchSystemNodeChain } from '@/hooks/useFetchSystemNodeChain';
import { cn } from '@/utils/classname';

export interface BreadcrumbProps {
  onChangeParentNodeId: (parentNodeId: null | string) => void;
  parentNodeId: null | string;
}

export const Breadcrumb = ({ onChangeParentNodeId, parentNodeId }: BreadcrumbProps) => {
  const chain = useFetchSystemNodeChain(parentNodeId ?? undefined);
  const reversed = parentNodeId ? (chain ? [...chain].reverse() : []) : [];

  return (
    <div className={cn('mt-3 flex items-center')}>
      {reversed?.map((item, index) => {
        return (
          <span
            className={cn(
              'cursor-pointer typo-text-s',
              index === reversed.length - 1 ? 'color-neutral-500' : 'color-neutral-400 underline',
            )}
            key={item?.id}
            onClick={() => onChangeParentNodeId(item?.id as string)}
          >
            {index === 0 ? (
              <span className="hover:underline">All folders</span>
            ) : (
              <div className={cn('flex items-center')}>
                <div
                  className={cn(
                    'i-skand-dropdownarrow',
                    'text-neutral-400',
                    'text-2',
                    'rotate-270',
                    'mx-1',
                  )}
                />

                <span className="hover:underline">{item?.name}</span>
              </div>
            )}
          </span>
        );
      })}
    </div>
  );
};
