import { Checkbox } from '@/components/Checkbox';
import { Label } from '@/components/Label';
import { NODES_TABLE_DEFAULT_PAGE_INDEX } from '@/constants/node';
import { SystemNodeDependantDomain } from '@/graphql/codegen/graphql';
import { useFiles } from '@/stores/files';
import { SystemNode } from '@/stores/systemNodes';
import { cn } from '@/utils/classname';
import { getElementWidth } from '@/utils/elementWidth';
import { getNodeIcon, getSystemCreatedNodeIcon } from '@/utils/nodeIcons';
import { useEffect, useRef, useState } from 'react';

interface FileRowProps {
  node: SystemNode;
  onClick: (value: string) => void;
  setPageIndex: (node: number) => void;
  setSearchKey: (value: string) => void;
  shareFilesTab: boolean;
  setBreadCrumbParentNodeId: (value: string) => void;
}

export const FileRow = ({
  node,
  shareFilesTab,
  onClick,
  setPageIndex,
  setSearchKey,
  setBreadCrumbParentNodeId,
}: FileRowProps) => {
  const { project } = useFiles(state => state);

  const fileNameRef = useRef(null);
  const [fileNameWidth, setFilenNameWidth] = useState(0);
  const [enabledFileNameTooltip, setEnabledFileNameTooltip] = useState(false);

  const isSystemManagedNode = [
    project?.rootFolderNodeId,
    project?.renderObjectsRootFolderNodeId,
    project?.annotationsRootFolderNodeId,
    project?.userFilesRootFolderNodeId,
  ].includes(node.id);

  const isSystemCreatedNode = node.dependants.some(
    dep =>
      dep === SystemNodeDependantDomain['RenderObject'] ||
      dep === SystemNodeDependantDomain['Annotation'],
  );

  useEffect(() => {
    setFilenNameWidth(getElementWidth(fileNameRef));
  }, []);

  const handleClick = () => {
    if (node.type === 'file') return;
    if (node.type === 'folder' && node.sourceNodeId) {
      onClick(node.sourceNodeId);
    } else if (node.type === 'folder') {
      onClick(node.id);
    }
    setBreadCrumbParentNodeId(node.id);
    setPageIndex(NODES_TABLE_DEFAULT_PAGE_INDEX);
    setSearchKey('');
  };

  const renderNodeIcon = () => {
    if (isSystemManagedNode) {
      return 'i-fs-folder-lock';
    } else if (isSystemCreatedNode) {
      return getSystemCreatedNodeIcon(node.extension, node.kind);
    } else {
      return getNodeIcon(node.extension, node.kind);
    }
  };

  return (
    <div className={cn('w-full', 'my-3', 'flex', 'flex-row', 'justify-between', 'cursor-pointer')}>
      <div className={cn('flex felx-row gap-3 items-center mr-3')}>
        <Checkbox checked={shareFilesTab} disabled placeHolderView small />
        <div className={cn('flex flex-row  gap-3')} onClick={handleClick}>
          <div className={cn(`${renderNodeIcon()} text-5 text-neutral-600 cursor-pointer`)} />
          <div className="relative">
            <p
              className={cn(
                'w-auto max-w-[280px] text-neutral-800 typo-text-s text-nowrap overflow-hidden',
              )}
              onMouseEnter={() => setEnabledFileNameTooltip(true)}
              onMouseLeave={() => setEnabledFileNameTooltip(false)}
              ref={fileNameRef}
            >
              {node.name}
            </p>
            {fileNameWidth > 279 && enabledFileNameTooltip && (
              <div className={cn('absolute', 'z-1', 'top-5', 'left-[-56px]')}>
                <Label
                  css="bg-neutral-100 py-1"
                  ellipsis={false}
                  labelTitle={node.name}
                  labelType="default"
                  textLength={node.name.length}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
