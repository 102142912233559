import { gqlQueryFactory } from '@/graphql/operations';
import { DownloadContext, DownloadStore } from '@skand/downloader';
import { toast } from '@skand/ui';
import { jotaiStore } from './jotaiStore';

export const downloadContext = new DownloadContext({
  gqlQueryFactory,
  jotaiStore,
  toastFn: toast,
});

export const downloadStore = new DownloadStore({
  downloadContext,
  maxTotalSize: Infinity,
});
