import {
  AnnotationTemplate,
  AnnotationTemplateSelectField,
  AnnotationTemplateSelectOption,
  TriggerPredictionV2MutationVariables,
} from '@/graphql/codegen/graphql';
import { START_PREDICTION } from '@/graphql/mutations';
import { request } from '@/graphql/request';
import { useAnnotationTemplates } from '@/hooks/useAnnotationTemplates';
import { useFetchImageUrls } from '@/hooks/useFetchImageUrls';
import { useModels } from '@/hooks/useModels';
import { useTrainings } from '@/hooks/useTrainings';
import { cancelEditAnnotation, setCurrentAnnotation } from '@/stores/explore';
import { Photo, startDraw2D, useViewer } from '@/stores/viewer';
import { Button, Draggable, Select, cn } from '@skand/ui';
import { useMutation } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import { Color, Vector2 } from 'three';
import { Prediction, PredictionRow } from './PredictionRow';

export interface PredictorProps {
  photo: Photo;
  handleClose: () => void;
}

export const Predictor = ({ photo, handleClose }: PredictorProps) => {
  const api2D = useViewer(state => state.api2D);

  const [photoUrl, setPhotoUrl] = useState('');
  const [template, setTemplate] = useState<AnnotationTemplate | null>(null);
  const [field, setField] = useState<AnnotationTemplateSelectField | null>(null);
  const [submitted, setSubmitted] = useState(false);
  const [predictions, setPredictions] = useState<Prediction[] | null>(null);
  const [visible, setVisible] = useState<Set<Prediction>>(new Set());
  const [selected, setSelected] = useState<Prediction | null>(null);
  const [modelId, setModelId] = useState<string | null>(null);
  const [selectedTraining, setSelectedTraining] = useState<string | null>(null);

  const { trainings, isLoading: isLoadingTrainings } = useTrainings(modelId);

  const trainingOptions = useMemo(
    () =>
      trainings
        .filter(training => training.status === 'deployed')
        .map(training => ({
          key: training.id,
          name: `${new Date(training.startTime).toLocaleString()}`,
        })),
    [trainings],
  );

  const startPrediction = useMutation({
    mutationFn: (variables: TriggerPredictionV2MutationVariables) =>
      request(START_PREDICTION, variables),
    onSuccess: data => {
      if (!data.triggerPredictionV2?.[0]) {
        return;
      }

      if (!api2D || !field) {
        return;
      }

      const prediction = data.triggerPredictionV2[0];

      // Map the content to the prediction interface
      const options = (field?.options as AnnotationTemplateSelectOption[]) ?? [];
      const count: number = data.triggerPredictionV2[0].predicted_boxes.length;
      const result = [];
      for (let i = 0; i < count; i++) {
        const [minX, minY, maxX, maxY] = prediction.predicted_boxes[i] as number[];
        const optionIndex = Math.min(options.length - 1, prediction.predicted_labels[i] as number);
        const option = options[optionIndex];
        const points = [
          new Vector2(minX, minY),
          new Vector2(maxX, minY),
          new Vector2(maxX, maxY),
          new Vector2(minX, maxY),
        ];
        const color = new Color(option?.color as string);
        const sketch = api2D.editor.createSketch('polygon', points, color);
        result.push({ id: i, option, sketch });
        sketch.show();
      }

      setPredictions(result);
      setVisible(new Set(result));
    },
  });

  const fetchUrls = useFetchImageUrls([photo.id], false);

  // Update the url
  useEffect(() => {
    fetchUrls().then(map => setPhotoUrl(map.get(photo.id) as string));
  }, [fetchUrls, photo.id]);

  // Update the visibility of the predictions
  useEffect(() => {
    if (predictions) {
      for (const prediction of predictions) {
        if (visible.has(prediction) && prediction !== selected) {
          prediction.sketch.show();
        } else {
          prediction.sketch.hide();
        }
      }
    }
  }, [visible, selected, predictions]);

  // Clean up component on unmount
  useEffect(() => {
    return () => {
      if (predictions) {
        for (const prediction of predictions) {
          prediction.sketch.destroy();
        }
      }
    };
  }, [predictions]);

  // Enumerate templates
  const { templates } = useAnnotationTemplates();
  const templateOptions = useMemo(
    () =>
      templates.map(template => ({
        key: template.id ?? '',
        name: template.name ?? '',
      })),
    [templates],
  );

  // Enumerate models
  const { models } = useModels();
  const modelOptions = useMemo(
    () =>
      models.map(model => ({
        key: model.id,
        name: model.name,
      })),
    [models],
  );

  // Enumerate fields of the template
  const fieldOptions = useMemo(() => {
    const fields = [];
    for (const field of template?.fields ?? []) {
      if (field?.type === 'SELECT') {
        fields.push({
          key: field.id ?? '',
          name: field.name ?? '',
        });
      }
    }
    return fields;
  }, [template?.fields]);

  // Handle selecting a template
  const handleSelectTemplate = (id: string) => {
    const query = templates.find(template => template.id === id);
    if (query) {
      setTemplate(query);
    }
  };

  // Handle selecting a template field
  const handleSelectField = (id: string) => {
    if (template) {
      const query = template.fields?.find(field => field?.id === id);
      if (query) {
        setField(query as AnnotationTemplateSelectField);
      }
    }
  };

  // Handle submitting the payload
  const handleSubmit = async () => {
    setSubmitted(true);

    await startPrediction.mutate({
      request: {
        imageUrls: [photoUrl],
        deploymentId: selectedTraining,
      },
    });
  };

  // Create a 2D annotation from a prediction
  const createAnnotation = (prediction: Prediction) => {
    setSelected(prediction);
    if (api2D && template && field && prediction.option.color) {
      setCurrentAnnotation(prediction.option.color, template, undefined, [
        {
          type: 'SELECT',
          fieldId: field.id,
          optionId: prediction.option.id,
          name: field.name,
          value: prediction.option.value,
        },
      ]);
      startDraw2D(prediction.sketch.getVertices(), true, new Color(prediction.option.color));
      api2D.editor.lookAt(prediction.sketch);
      api2D.editor.getDrawController().submit();
    }
  };

  // Toggle a prediction
  const togglePrediction = (prediction: Prediction) => {
    const newVisible = new Set(visible);
    if (newVisible.has(prediction)) {
      newVisible.delete(prediction);
    } else {
      newVisible.add(prediction);
    }
    setVisible(newVisible);
  };

  // Delete a prediction
  const deletePrediction = (prediction: Prediction) => {
    if (predictions) {
      prediction.sketch.destroy();
      const index = predictions.findIndex(v => v === prediction);
      if (index > -1) {
        predictions.splice(index, 1);
      }
      setPredictions([...predictions]);
      if (selected === prediction) {
        setSelected(null);
        cancelEditAnnotation();
      }
    }
  };

  return (
    <Draggable x={100} y={100}>
      <div
        className={cn(
          'w-60',
          'gap-2',
          'flex',
          'flex-col',
          'bg-white',
          'p-3',
          'b-rounded',
          'pointer-events-auto',
        )}
      >
        {predictions ? (
          <>
            <div className={cn('max-h-40', 'overflow-y-scroll')}>
              {predictions.map((prediction, index) => (
                <PredictionRow
                  handleCreate={() => createAnnotation(prediction)}
                  handleDelete={() => deletePrediction(prediction)}
                  handleToggle={() => togglePrediction(prediction)}
                  key={index}
                  prediction={prediction}
                  selected={selected === prediction}
                  visible={visible.has(prediction)}
                />
              ))}
            </div>
            <Button className={cn('w-full')} onClick={handleClose}>
              Done
            </Button>
          </>
        ) : (
          <>
            <div className="cursor-grab color-neutral-800 typo-text-m"> Prediction</div>
            <Select
              label="Model"
              onChange={id => setModelId(id)}
              options={modelOptions}
              style={{ display: 'block' }}
              width="full"
            />

            {modelId && (
              <Select
                label={
                  isLoadingTrainings
                    ? 'Loading...'
                    : trainingOptions.length === 0
                    ? 'No trainings found'
                    : 'Select a training'
                }
                onChange={id => setSelectedTraining(id)}
                options={trainingOptions}
                style={{ display: 'block' }}
                value={selectedTraining}
                width="full"
              />
            )}

            {selectedTraining && (
              <Select
                label="Template"
                onChange={handleSelectTemplate}
                options={templateOptions}
                style={{ display: 'block' }}
                value={template?.id}
                width="full"
              />
            )}

            {template && (
              <Select
                label="Dropdown field"
                onChange={handleSelectField}
                options={fieldOptions}
                style={{ display: 'block' }}
                value={field?.id}
                width="full"
              />
            )}

            <Button
              disabled={submitted}
              onClick={submitted ? handleClose : handleSubmit}
              primary
              size="s"
            >
              {submitted ? 'Close' : 'Submit'}
            </Button>
          </>
        )}
      </div>
    </Draggable>
  );
};
