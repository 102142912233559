import { Layer } from '@/stores/viewer';
import { cn } from '@/utils/classname';
import { getLayerIcon } from '@/utils/nodeIcons';

export interface LayerNodeProps {
  layer: Layer;
  setTargetLayer: (layer: Layer) => void;
  childCount: number;
}

export const LayerNode = ({ layer, setTargetLayer, childCount }: LayerNodeProps) => {
  return (
    <div
      className={cn('flex justify-between items-center py-1')}
      onClick={() => setTargetLayer(layer)}
    >
      <div className={cn('flex items-center gap-2 text-neutral-600')}>
        <div
          className={cn('w-5 color-neutral-600 text-3 flex-none', getLayerIcon(layer.formatType))}
        />
        <p className={cn('typo-text-small text-neutral-800 whitespace-nowrap')}>{layer.name}</p>
        <div
          className={cn(
            'bg-neutral-200 py-[1px] px-1 rounded-[50px] min-w-5 h-[13px] justify-center flex items-center ',
          )}
        >
          <p className={cn('typo-text-xs-em text-neutral-500 whitespace-nowrap pt-[1px]')}>
            {childCount}
          </p>
        </div>
      </div>
    </div>
  );
};
