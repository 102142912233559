import { CESIUM_API_KEY, GOOGLE_MAP_API_KEY } from '@/constants/env';
import { cn } from '@skand/ui';
import {
  Cartographic,
  toCartesian,
  toRadians,
  Viewer,
  ViewerAPI,
} from '@skand/viewer-component-v2';
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { Overlay } from './Overlay';
import { Splash } from './Splash';

const DEFAULT_PLAYER_POSITION = toCartesian(
  new Cartographic(toRadians(144.925696), toRadians(-37.823541), 100),
);

export const GameScene = () => {
  const [splash, setSplash] = useState<boolean>(true);
  const [api3D, setAPI3D] = useState<ViewerAPI | null>(null);

  // Enable the Google Earth globe and move to default player position
  useEffect(() => {
    if (api3D) {
      api3D.globe.setGlobeMode('terrain');
      api3D.navigation.moveTo(DEFAULT_PLAYER_POSITION);
    }
  }, [api3D]);

  // Render on top of everything
  return createPortal(
    <div className={cn('absolute', 'h-full', 'w-full', 'top-0', 'left-0', 'z-1000')}>
      {splash || !api3D ? <Splash onFinish={() => setSplash(false)} /> : <Overlay api3D={api3D} />}
      <Viewer
        cesiumToken={CESIUM_API_KEY}
        gameServerUrl="https://explore-game-server.onrender.com"
        googleToken={GOOGLE_MAP_API_KEY}
        ref={setAPI3D}
      />
    </div>,
    document.getElementById('root') as HTMLElement,
  );
};
