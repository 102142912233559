import { Downloader } from '@/components/Downloader';
import { NODES_TABLE_DEFAULT_PAGE_INDEX } from '@/constants/node';
import { SystemNodeKindInput } from '@/graphql/codegen/graphql';
import { useFetchProject } from '@/hooks/useFetchProject';
import { useSystemNodes } from '@/hooks/useSystemNodes';
import { useFiles } from '@/stores/files';
import { SystemNode } from '@/stores/systemNodes';
import { cn } from '@/utils/classname';
import { debounce } from '@/utils/debounce';
import { search } from '@/utils/search';
import { useOnClickOutside } from '@/utils/useOnClickOutside';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { Button, Input, Menu, MenuItem, Skeleton } from '@skand/ui';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { EmptyResourceMessage } from '../EmptyResourceMessage';
import { FindIcon } from '../IconButton';
import { LazyLoader } from '../LazyLoader';
import { Breadcrumb } from './Breadcrumb';
import { FileRow } from './FileRow';
import { AddUserOrUserGroupDialog } from './Menus/AddUserOrUserGroupDialog';
import { DataManagementFilesMenu } from './Menus/DataManagementFilesMenu';
import { EditAccessMenu } from './Menus/EditAccessMenu';
import { ViewDetailsMenu } from './Menus/ViewDetailsMenu';
import { SortByMenu } from './SortByMenu';

export type SortKey = 'name' | 'createdAt' | 'updatedAt';

export const FilesTab = () => {
  const [isAddDropdownOpen, setIsAddDropdownOpen] = useState(false);
  const [searchKey, setSearchKey] = useState('');
  const [isSortByMenuOpen, setIsSortByMenuOpen] = useState(false);
  const [currentSort, setCurrentSort] = useState<SortKey>('name');
  const [parentNodeId, setParentNodeId] = useState<string | null>(null);
  const [selectedFile, setSelectedFile] = useState<SystemNode | null>(null);
  const [enabledDataManagementFilesMenu, setEnabledDataManagementFilesMenu] = useState(false);
  const [fileTypeTooltip, setFileTypeTooltip] = useState(false);
  const [pageIndex, setPageIndex] = useState(NODES_TABLE_DEFAULT_PAGE_INDEX);
  const [editNodeTarget, setEditNodeTarget] = useState<SystemNode | null>(null);
  const [editingSystemNodeId, setEditingSystemNodeId] = useState<SystemNode['id'] | null>(null);
  const [enableEditAccessMenu, setEnableEditAccessMenu] = useState(false);
  const [enableUserGroupMenu, setEnableUserGroupMenu] = useState(false);

  const [systemNodes, setSystemNodes] = useState<SystemNode[]>([]);

  const fileTypeTooltipRef = useRef<HTMLInputElement>(null);

  const enabledOutsideClick = useOnClickOutside(fileTypeTooltipRef);

  const { fetch: fetchProject, isLoading } = useFetchProject();

  useEffect(() => {
    fetchProject().then(project => {
      if (project && project.rootFolderNodeId) {
        setParentNodeId(project.rootFolderNodeId);
        useFiles.setState({ project });
      }
    });
  }, [fetchProject]);

  useEffect(() => {
    if (enabledOutsideClick) {
      setFileTypeTooltip(false);
    }
  }, [enabledOutsideClick]);

  const sortFiles = useCallback(
    (a: SystemNode, b: SystemNode) => {
      if (currentSort === 'name') {
        return (a.name ?? '').localeCompare(b.name ?? '');
      } else if (currentSort === 'createdAt') {
        return a.createdAt.getTime() - b.createdAt.getTime();
      } else if (currentSort === 'updatedAt') {
        return a.updatedAt.getTime() - b.updatedAt.getTime();
      }
      return 0;
    },
    [currentSort],
  );

  const sortedSystemNodes = systemNodes.sort(sortFiles);

  const {
    systemNodes: fetchedNodes,
    response: { isFetching },
  } = useSystemNodes({
    parentNodeId,
    pageIndex,
    pageSize: 50,
    searchTerm: searchKey || undefined,
    nodeKinds: [
      SystemNodeKindInput.FileNode,
      SystemNodeKindInput.FolderNode,
      SystemNodeKindInput.LinkNode,
    ],
    includeMigratedData: true,
  });

  useEffect(() => {
    if (fetchedNodes) {
      setSystemNodes(prev => {
        const allNodes = [...prev, ...fetchedNodes];

        // Create a Map where the key is the node.id to ensure uniqueness
        const uniqueNodesMap = new Map(allNodes.map(node => [node.id, node]));

        // Filter by parentId and searchKey
        const uniqueNodes = Array.from(uniqueNodesMap.values())
          .filter(node => node.parentId === parentNodeId)
          .filter(node => search(node.name, searchKey));

        return uniqueNodes;
      });
    }
  }, [fetchedNodes, parentNodeId, searchKey]);

  const hasMore = useMemo(() => fetchedNodes && fetchedNodes.length > 0, [fetchedNodes]);

  // Debounced search handler
  const handleSearch = useMemo(() => {
    return debounce((value: string) => {
      setSearchKey(value);
      // Reset page index when search is done
      setPageIndex(0);
    }, 300);
  }, [setSearchKey, setPageIndex]);

  const handleSortChange = (key: SortKey) => {
    setCurrentSort(key);
  };
  const getCurrentSortLabel = () => {
    switch (currentSort) {
      case 'name':
        return 'NAME';
      case 'createdAt':
        return 'CREATED DATE';
      case 'updatedAt':
        return 'LAST UPDATED';
      default:
        return 'SORT BY';
    }
  };

  const closeEditAccessMenu = () => {
    setEditingSystemNodeId(null);
    setEnableEditAccessMenu(false);
  };

  const closeUserGroupMenu = () => {
    setEnableUserGroupMenu(false);
    setEnableEditAccessMenu(true);
  };

  const handleEditAccessMenu = (id: string | null) => {
    setEditingSystemNodeId(id);
    setEnableEditAccessMenu(true);
  };

  return (
    <div className={cn('flex flex-col px-1 gap-3 h-full w-full relative')}>
      <div
        className={cn(
          'border-neutral-300',
          'border-1',
          'border-b-solid',
          'pb-3',
          'flex',
          'flex-row',
          'items-center',
          'justify-between',
          'relative',
        )}
      >
        <p className={cn('typo-heading-4 color-neutral-800', 'text')}>Files</p>
        <span
          className={cn('i-skand-info', 'color-neutral-500', 'ml-3', 'text-3.5', 'cursor-pointer')}
          onClick={() => setFileTypeTooltip(!fileTypeTooltip)}
        />
        {fileTypeTooltip && (
          <div
            className={cn(
              'h-[178px] top-7 left-89 w-[683px] gap-3 absolute bg-info-100 px-3 py-2  z-1  border-info-400 rounded border-[1px] b-solid flex flex-row items-start',
            )}
            ref={fileTypeTooltipRef}
          >
            <div className={cn('i-skand-info', 'color-info-400', 'text-3', 'mt-1')} />
            <div className={cn('flex flex-col gap2')}>
              <p className="text-neutral-800 typo-text-s-em">What are these new folder types?</p>
              <div className={cn('flex flex-row items-center')}>
                <div className="i-fs-folder color-neutral-600" />
                <p className={cn('text-neutral-800 typo-text-s-em ml-3 mr-2')}>Default</p>
                <p className={cn('text-neutral-800 typo-text-s')}>
                  – folders uploaded directly to this project.
                </p>
              </div>
              <div className={cn('flex flex-row items-center')}>
                <div className="i-fs-folder-link color-neutral-600" />
                <p className={cn('text-neutral-800 typo-text-s-em ml-3 mr-2')}>Linked</p>
                <p className={cn('text-neutral-800 typo-text-s')}>
                  – shortcuts to existing folders in Data Management.
                </p>
              </div>
              <div className={cn('flex flex-row items-center')}>
                <div className="i-fs-folder-lock color-neutral-600" />
                <p className={cn('text-neutral-800 typo-text-s-em ml-3 mr-2')}>System managed</p>
                <p className={cn('text-neutral-800 typo-text-s')}>
                  – auto-created root project folders that cannot be deleted (e.g. Annotations,
                  Layers, User files).
                </p>
              </div>
              <div className={cn('flex flex-row items-center')}>
                <div className="i-fs-folder-sync color-neutral-600" />
                <p className={cn('text-neutral-800 typo-text-s-em ml-3 mr-2')}>System created</p>
                <p className={cn('text-neutral-800 typo-text-s')}>
                  – auto-created project folders that can be edited (e.g. individual annotations or
                  layers).
                </p>
              </div>
              <p className={cn('text-primary-400 typo-text-s-em underline cursor-pointer')}>
                Learn more about these features
              </p>
            </div>
          </div>
        )}
      </div>

      <div className={cn('flex gap-2 relative')}>
        <div className="w-full">
          <Input
            data-testid="input"
            label="Search"
            onChange={handleSearch}
            tail={<FindIcon />}
            value={searchKey}
          />
        </div>
        <Button className="px-5" onClick={() => {}} size="s">
          Filter
        </Button>
      </div>

      <DropdownMenu.Root onOpenChange={setIsAddDropdownOpen} open={isAddDropdownOpen}>
        <DropdownMenu.Trigger asChild>
          <Button
            active={isAddDropdownOpen}
            className={cn('relative cursor-pointer')}
            filled
            primary
            size="s"
          >
            Add
            <div className="i-skand-dropdown absolute right-2 top-2" />
          </Button>
        </DropdownMenu.Trigger>

        <DropdownMenu.Portal>
          <DropdownMenu.Content asChild>
            <Menu className="mt-0 w-368px">
              <DropdownMenu.Item asChild>
                <MenuItem
                  className="cursor-pointer text-center outline-none"
                  onClick={() => setEnabledDataManagementFilesMenu(true)}
                >
                  Link files from Data Management
                </MenuItem>
              </DropdownMenu.Item>
              <DropdownMenu.Item asChild>
                <MenuItem
                  className="cursor-pointer text-center outline-none"
                  onClick={() => {
                    useFiles.setState({ enabledUploader: true });
                  }}
                >
                  Upload new files
                </MenuItem>
              </DropdownMenu.Item>
            </Menu>
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>
      <div className="flex-1 overflow-hidden">
        <div className="mb-3 flex">
          <div className="flex-1" />
          <div className="relative cursor-pointer">
            <div
              className="flex items-center justify-between gap-2"
              onClick={() => setIsSortByMenuOpen(!isSortByMenuOpen)}
            >
              <p className="color-neutral-800 typo-text-xs-em">SORT BY: {getCurrentSortLabel()}</p>
              <div
                className={cn(
                  'i-skand-dropdown text-14px color-neutral-800',
                  isSortByMenuOpen && 'rotate-180',
                )}
              />
            </div>
            {isSortByMenuOpen && (
              <SortByMenu setIsSortByMenuOpen={setIsSortByMenuOpen} setSortKey={handleSortChange} />
            )}
          </div>
        </div>
        <Breadcrumb
          onChangeParentNodeId={id => {
            setParentNodeId(id);
            setPageIndex(NODES_TABLE_DEFAULT_PAGE_INDEX);
          }}
          parentNodeId={parentNodeId}
        />
        {isFetching || isLoading ? (
          <Skeleton className="mb-3 h-full w-full" />
        ) : !systemNodes.length ? (
          <EmptyResourceMessage
            emptyResourceContent={{
              emptyDesc:
                'Link existing files from Data Management or upload new files using the Add button above.',
              emptyTitle: 'No files added',
              emptyDescStyles: 'text-center',
            }}
          />
        ) : (
          <LazyLoader
            className="h-11/12 overflow-x-hidden"
            hasMore={hasMore}
            isLoading={isFetching}
            items={sortedSystemNodes}
            onLoadMore={() => setPageIndex(prev => prev + 1)}
          >
            {files =>
              files.map(file => (
                <FileRow
                  editNodeTarget={editNodeTarget}
                  handleEditAccessMenu={handleEditAccessMenu}
                  key={file.id}
                  node={file}
                  onClick={setParentNodeId}
                  onSelectFile={setSelectedFile}
                  pageIndex={pageIndex}
                  setEditNodeTarget={setEditNodeTarget}
                  setPageIndex={setPageIndex}
                  setSearchKey={setSearchKey}
                  setSystemNodes={setSystemNodes}
                />
              ))
            }
          </LazyLoader>
        )}
      </div>
      {enabledDataManagementFilesMenu && (
        <DataManagementFilesMenu
          closeMenu={() => setEnabledDataManagementFilesMenu(false)}
          setPageIndex={setPageIndex}
        />
      )}
      {selectedFile && (
        <ViewDetailsMenu closeMenu={() => setSelectedFile(null)} selectedFile={selectedFile} />
      )}

      {editingSystemNodeId && enableEditAccessMenu && (
        <EditAccessMenu
          closeMenu={closeEditAccessMenu}
          editingSystemNodeId={editingSystemNodeId}
          setEnableUserGroupMenu={setEnableUserGroupMenu}
        />
      )}
      {editingSystemNodeId && enableUserGroupMenu && (
        <AddUserOrUserGroupDialog
          closeMenu={closeUserGroupMenu}
          editingSystemNodeId={editingSystemNodeId}
        />
      )}
      <Downloader />
    </div>
  );
};
