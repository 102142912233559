import { useFetchSystemNodeChain } from '@/hooks/useFetchSystemNodeChain';
import { SystemNode } from '@/stores/systemNodes';
import { cn } from '@/utils/classname';
import { ReactNode, useMemo } from 'react';

export interface BreadcrumbProps {
  parentNodeId: string | null;
  onNavigate: (folderNodeId: string | null) => void;
  size?: 'small' | 'medium';
  divider?: ReactNode | string;
  initialRoot?: string;
  previewMode?: boolean;
}

export const Breadcrumb = ({
  size = 'medium',
  divider = '/',
  initialRoot = 'All folders',
  parentNodeId,
  onNavigate,
  previewMode = false,
}: BreadcrumbProps) => {
  const parentNodesChain = useFetchSystemNodeChain(parentNodeId as string);

  const folderNodes = useMemo(() => {
    if (!parentNodesChain || !parentNodeId) return [];
    return structuredClone(parentNodesChain).reverse() as SystemNode[];
  }, [parentNodeId, parentNodesChain]);

  return (
    <div>
      <nav className="flex">
        <ol
          className={cn(
            'inline-flex list-none items-center',
            size === 'medium' ? 'typo-text-s' : 'typo-text-xs',
          )}
        >
          <li className="inline-flex items-center">
            <div
              className={cn(
                'color-neutral-400',
                parentNodeId && !previewMode
                  ? 'hover:cursor-pointer underline'
                  : 'no-underline hover:cursor-default color-neutral-500',
              )}
              onClick={() => onNavigate(null)}
            >
              {initialRoot}
            </div>
          </li>
          {folderNodes.map(folderNode => (
            <li key={folderNode.id}>
              <div className="flex items-center">
                <span className="color-neutral-400">{divider}</span>
                <div
                  className={cn(
                    'color-neutral-400',
                    parentNodeId !== folderNode.id && !previewMode
                      ? 'hover:cursor-pointer underline'
                      : 'no-underline hover:cursor-default color-neutral-500',
                  )}
                  onClick={() => onNavigate(folderNode.id)}
                >
                  {folderNode.name}
                </div>
              </div>
            </li>
          ))}
        </ol>
      </nav>
    </div>
  );
};
