import { gql } from './codegen';

// Login
export const LOGIN = gql(`
  mutation Login($email: String, $password: String) {
    login(email: $email, password: $password) {
      bearerToken
      email
      accountId
    }
  }
`);

export const CREATE_ANNOTATION = gql(`
  mutation CreateAnnotation($annotation: CreateAnnotationInput!) {
    createAnnotation(request: $annotation) {
      annotationId
      name
    }
  }
`);

export const CREATE_MANY_ANNOTATIONS = gql(`
  mutation CreateManyAnnotations($annotations: [CreateAnnotationInput]!) {
    createManyAnnotations(requests: $annotations) 
  }
`);

export const CREATE_ACCOUNT_ANNOTATION_TEMPLATE = gql(`
  mutation CreateAccountAnnotationTemplate($template: CreateAccountAnnotationTemplateInput!) {
    createAccountAnnotationTemplate(request: $template) {
      id
      name
    }
  }
`);

export const CREATE_PROJECT_ANNOTATION_TEMPLATE = gql(`
  mutation CreateProjectAnnotationTemplate($template: CreateProjectAnnotationTemplateInput!) {
    createProjectAnnotationTemplate(request: $template) {
      id
      name
    }
  }
`);

export const UPDATE_ANNOTATION_TEMPLATE = gql(`
  mutation UpdateAnnotationTemplate($template: UpdateAnnotationTemplateInput!) {
    updateAnnotationTemplate(request: $template) {
      id
      name
    }
  }
`);

export const CREATE_ANNOTATION_GROUP = gql(`
  mutation CreateAnnotationGroup($annotationGroup: CreateAnnotationGroupInput!) {
    createAnnotationGroup(request: $annotationGroup) {
      id
      name
    }
  }
`);

export const RENAME_SCENE_ENTITY = gql(`
  mutation RenameSceneEntity($sceneEntityId: ID!, $projectId: ID!, $name: String!) {
    updateSceneEntityName(sceneEntityId: $sceneEntityId, projectId: $projectId, name: $name) {
      id
      name
    }
  }
`);

export const DELETE_ANNOTATION_GROUP = gql(`
  mutation DeleteAnnotationGroup($annotationGroupId: ID!) {
    deleteAnnotationGroup(annotationGroupSceneEntityId: $annotationGroupId)
  }
`);

export const CREATE_PROJECT_GROUP = gql(`
  mutation CreateProjectGroup($accountId: ID!, $description: String!, $name: String!, $projectIds: [ID]) {
    createProjectGroup(accountId: $accountId, description: $description, name: $name, projectIds: $projectIds) {
      id
      name
    }
  }
`);

export const UPDATE_PROJECT_GROUP = gql(`
  mutation UpdateProjectGroup($projectGroupId: ID!, $name: String!, $description: String!) {
    updateProjectGroupNameAndDescription(projectGroupId: $projectGroupId, name: $name, description: $description,) {
      id
      name
    }
  }
`);

export const MOVE_ANNOTATIONS_TO_GROUP = gql(`
  mutation MoveAnnotationsToGroup($annotationIdsWithPositionAndRotation: [AnnotationIdWithPositionAndRotation]!, $groupId: String!) {
    moveAnnotationsToGroup(annotationIdsWithPositionAndRotation: $annotationIdsWithPositionAndRotation, groupId: $groupId) {
      id
      name
    }
  }
`);

export const UPDATE_PROJECT = gql(`
  mutation UpdateProject($projectId: ID!, $name: String, $address: String, $description: String, $geoid: String, $projectGroupId: ID, $longitude: Float, $latitude: Float) {
    updateProject(projectId: $projectId, name: $name, address: $address, description: $description, geoid: $geoid, projectGroupId: $projectGroupId,longitude: $longitude, latitude: $latitude ) {
      id
    }
  }
`);

export const DELETE_PROJECT_GROUP = gql(`
  mutation DeleteProjectGroup($projectGroupId: ID!) {
    deleteProjectGroup(projectGroupId: $projectGroupId)
  }
`);

export const MOVE_PROJECTS_TO_GROUP = gql(`
  mutation MoveProjectsToGroup($groupId: ID!, $projectIds: [ID!]!) {
    moveProjectsToGroup(groupId: $groupId, projectIds: $projectIds)
  }
`);

export const UPDATE_ANNOTATION = gql(`
  mutation UpdateAnnotation($annotation: UpdateAnnotationInput!) {
    updateAnnotation(request: $annotation) {
      annotationId
      name
      color
    }
  }
`);

export const DELETE_ANNOTATION = gql(`
  mutation DeleteAnnotation($annotationId: String!) {
    deleteAnnotation(annotationId: $annotationId) {
      id
      name
      groupId
    }
  }
`);

export const DELETE_ANNOTATIONS = gql(`
  mutation DeleteAnnotations($annotationIds: [String]!) {
    deleteAnnotations(annotationIds: $annotationIds) {
      id
      name
      groupId
    }
  }
`);

export const SET_PARENT_SCENE_ENTITY = gql(`
  mutation SetParentSceneEntity(
    $sceneEntityId: ID!,
    $parentSceneEntityId: ID!,
    $position: SceneEntityPositionInput!,
    $rotation: SceneEntityRotationInput!
    $projectId: ID!
  ) {
    AddParentToSceneEntity(
      sceneEntityId: $sceneEntityId,
      parentSceneEntityId: $parentSceneEntityId,
      position: $position,
      rotation: $rotation,
      projectId: $projectId
    ) {
      id
    }
  }
`);

export const REMOVE_PARENT_SCENE_ENTITY = gql(`
  mutation RemoveParentSceneEntity(
    $sceneEntityId: ID!
    $position: SceneEntityPositionInput!,
    $rotation: SceneEntityRotationInput!,
    $projectId: ID!
  ) {
    RemoveParentFromSceneEntity(
      sceneEntityId: $sceneEntityId,
      position: $position,
      rotation: $rotation,
      projectId: $projectId
    ) {
      id
    }
  }
`);

export const CREATE_SHARE_LINK = gql(`
  mutation CreateShareLink($projectId: ID!, $name: String!, $permissions: [PermissionPolicyWithOutSubjectInput]) {
    createShareLink(projectId: $projectId, name: $name, permissions: $permissions) {
      id
      shareToken
    }
  }
`);

export const UPDATE_SHARE_LINK = gql(`
  mutation UpdateShareLink($shareLinkId: ID!, $isActivated: Boolean,$name: String, $permissions: [PermissionPolicyInput]) {
    updateShareLink(shareLinkId: $shareLinkId, isActivated: $isActivated, name: $name, permissions: $permissions) {
      id
    }
  }
`);

export const DELETE_SHARE_LINK = gql(`
  mutation DeleteShareLink($shareLinkId: ID!) {
    deleteShareLink(shareLinkId: $shareLinkId)
  }
`);

export const CREATE_PROJECT = gql(`
  mutation CreateProject($name: String!, $address: String!, $description: String!,$geoid: String!, $projectGroupId: ID, $longitude: Float!, $latitude: Float!) {
    createProject(name: $name, address: $address, description: $description, geoid: $geoid, projectGroupId: $projectGroupId, longitude: $longitude, latitude: $latitude) {
      id
    }
  }
`);

export const CREATE_PROJECT_FILE_NODES_WITH_NODE_TREE = gql(`
  mutation CreateProjectFileNodesWithNodeTree($nodeIds: [ID], $siteId: ID!, $parentNodeId: ID) {
    createProjectFileNodesWithNodeTree(nodeIds: $nodeIds, siteId: $siteId, parentNodeId: $parentNodeId) {
      id
    }
  }
`);

export const DELETE_PROJECT = gql(`
  mutation DeleteProject($projectId: ID!) {
    deleteProject(projectId: $projectId)
  }
`);

export const TRANSFORM_SCENE_ENTITY = gql(`
  mutation TransformSceneEntity(
    $sceneEntityId: ID!,
    $projectId: ID!,
    $position: SceneEntityPositionInput!,
    $rotation: SceneEntityRotationInput!
  ) {
    updateSceneEntityPositionAndRotation(
      sceneEntityId: $sceneEntityId,
      projectId: $projectId,
      position: $position,
      rotation: $rotation
    ) {
      id
    }
  }
`);

export const CREATE_SCENE_ENTITY_GROUP = gql(`
  mutation CreateSceneEntityGroup(
    $accountId: ID!,
    $projectId: ID!,
    $name: String!, 
    $parentSceneEntityId: ID
    ) {
    CreateSceneEntityGroup(
      accountId: $accountId,
      projectId: $projectId,
      name: $name, 
      parentSceneEntityId: $parentSceneEntityId
      ) {
      id
    }
  }
`);

export const DELETE_SCENE_ENTITY_GROUP = gql(`
  mutation DeleteSceneEntityGroup($sceneEntityId: ID!, $projectId: ID!) {
    DeleteSceneEntityGroup(sceneEntityId: $sceneEntityId, projectId: $projectId)
  }
`);

export const CREATE_PROJECT_FILE_NODES_FROM_FILES = gql(`
  mutation CreateProjectFileNodesFromFiles($projectId: ID!, $parentNodeId: ID, $nodeIds: [ID!]!) {
    createProjectFileNodesWithNodeTree(nodeIds: $nodeIds, siteId: $projectId, parentNodeId: $parentNodeId) {
      id
      kind
      name
    }
  }
`);

export const CREATE_PROJECT_FOLDER_NODE = gql(`
  mutation CreateProjectFolderNode($projectId: ID!, $parentNodeId: ID, $name: String!) {
    createProjectFolderNode(parentNodeId: $parentNodeId, siteId: $projectId, name: $name) {
      id
      kind
      name
    }
  }
`);

export const CREATE_SYSTEM_FOLDER_NODE = gql(`
  mutation CreateSystemFolderNode($input: CreateSystemFolderNodeInput!) {
    createSystemFolderNode(input: $input) {
      id
      kind
      parentNodeId
      name
      createdAt
      createdUser {
        id
        displayName
        firstName
        lastName
      }
      lastDownloadedAt
      lastDownloadedUser {
        id
        displayName
        firstName
        lastName
      }
    }
  }
`);

export const UPDATE_PROCESS_STEP = gql(`
  mutation UpdateProcessStep($processId: ID!, $stepName: String!) {
    addProcessStep(processId: $processId, stepName: $stepName) {
      ... on AnnotationProcess {
        id
      }
    }
  }
`);

export const UPDATE_PROCESS_IMAGES_STATUS = gql(`
  mutation UpdateProcessImagesStatus($annotationProcessImageStatusIds: [ID!]!, $status: String!) {
    submitAnnotationProcessImagesStatus(annotationProcessImageStatusIds: $annotationProcessImageStatusIds, status: $status)
  }
`);

export const UPDATE_PANORAMIC_IMAGE_TRANSFORM = gql(`
  mutation UpdatePanoramicImageTransform($renderObjectId: ID!, $fileId: ID!, $name: String!, $position: [Float]!, $rotation: [Float]!) {
    updatePanoramicRenderObjectImagePosition(renderObjectId: $renderObjectId, fileId: $fileId, name: $name, relativePosition: $position, relativeRotation: $rotation) {
      ... on PanoramicRenderObject {
        id
      }
    }
  }
`);

export const UPDATE_PANORAMIC_PROCESS_IMAGE_STATUS = gql(`
  mutation UpdatePanoramicImagesStatus($panoramicProcessImageStatusIds: [ID]!, $status: String!) {
    submitPanoramicProcessImagesStatus(panoramicProcessImageStatusIds: $panoramicProcessImageStatusIds, status: $status)
  }
`);

export const DEBUG_CREATE_IMAGE_TILES_BY_FILE_IDS = gql(`
  mutation DebugCreateImageTilesByFileIds($fileIds: [ID]!) {
    createImageTilesForPersistedImagesByIds(fileIds: $fileIds)
  }
`);

export const ABORT_MULTIPART_UPLOAD = gql(`
  mutation AbortMultiPartUpload(
    $bucket: String!
    $key: String!
    $uploadId: String!
  ) {
    abortMultipartUpload(
      bucket: $bucket
      key: $key
      uploadId: $uploadId
    )
  }
`);

export const CREATE_MULTIPART_UPLOAD_FOR_FILE_IMPORT_REQUEST = gql(`
  mutation CreateMultipartUploadForFileImportRequest($dataImportRequestId: ID!, $fileImportRequestId: ID!) {
    createMultipartUploadForFileImportRequest(dataImportRequestId: $dataImportRequestId, fileImportRequestId: $fileImportRequestId) {
      bucket
      key
      uploadId
    }
  }
`);

export const CREATE_PRESIGNED_UPLOAD_PART_URL = gql(`
  mutation CreatePresignedUploadPartUrl(
    $bucket: String!
    $key: String!
    $partNumber: Int!
    $uploadId: String!
  ) {
    createPresignedUploadPartUrl(
      bucket: $bucket
      key: $key
      partNumber: $partNumber
      uploadId: $uploadId
    )
  }
`);

export const COMPLETE_MULTIPART_UPLOAD = gql(`
  mutation CompleteMultipartUpload(
    $bucket: String!
    $key: String!
    $parts: [MultiPartInput!]!
    $uploadId: String!
  ) {
    completeMultipartUpload(
      bucket: $bucket
      key: $key
      parts: $parts
      uploadId: $uploadId
    )
  }
`);

export const CREATE_DATA_IMPORT_REQUEST = gql(`
  mutation CreateDataImportRequest(
    $featureType: String!
    $name: String!
    $persistType: String!
    $priority: String!
    $rootFolderIdempotencyKeyAndName: FolderIdempotencyKeyAndNameInput!
    $systemFolderNodeId: ID
  ) {
    createDataImportRequest(
      featureType: $featureType
      name: $name
      persistType: $persistType
      priority: $priority
      rootFolderIdempotencyKeyAndName: $rootFolderIdempotencyKeyAndName
      systemFolderNodeId: $systemFolderNodeId
    )
  }
`);

export const UPDATE_STATUS_FOR_DATA_IMPORT_REQUEST = gql(`
  mutation UpdateStatusForDataImportRequest($status: String!, $dataImportRequestId: ID!) {
    updateStatusForDataImportRequest(status: $status, dataImportRequestId: $dataImportRequestId)
  }
`);

export const CREATE_FOLDER_IMPORT_REQUEST_FOR_DATA_IMPORT_REQUEST = gql(`
  mutation CreateFolderImportRequestForDataImportRequest(
    $dataImportRequestId: ID!
    $parentFolderNodeIdempotencyKey: String!
    $childFolderNodeIdempotencyKeyAndNames: [FolderIdempotencyKeyAndNameInput!]!
  ) {
    createFolderImportRequestForDataImportRequest(
      dataImportRequestId: $dataImportRequestId
      parentFolderNodeIdempotencyKey: $parentFolderNodeIdempotencyKey
      childFolderNodeIdempotencyKeyAndNames: $childFolderNodeIdempotencyKeyAndNames
    )
  }
`);

export const UPDATE_STATUS_FOR_FOLDER_IMPORT_REQUEST = gql(`
  mutation UpdateStatusForFolderImportRequest($input: UpdateStatusForFolderImportRequestInput!) {
    updateStatusForFolderImportRequest(input: $input)
  }
`);

export const CREATE_FILE_NODE_FOR_FOLDER_IMPORT_REQUEST = gql(`
  mutation CreateFileNodeForFolderImportRequest(
    $dataImportRequestId: ID!,
    $fileIdempotencyKeyStorageAndMetadata: [FileIdempotencyKeyStorageAndMetadataInput!]!
    $folderImportRequestId: ID!,
  ) {
    createFileNodeForFolderImportRequest(
      dataImportRequestId: $dataImportRequestId
      fileIdempotencyKeyStorageAndMetadata: $fileIdempotencyKeyStorageAndMetadata
      folderImportRequestId: $folderImportRequestId
    )
  }
`);

export const CREATE_FOLDER_NODE_FOR_FOLDER_IMPORT_REQUEST = gql(`
  mutation CreateFolderNodeForFolderImportRequest(
    $childFolderImportRequestIds: [ID]!
    $dataImportRequestId: ID!
    $parentFolderImportRequestId: ID
  ) {
    createFolderNodeForFolderImportRequest(
      childFolderImportRequestIds: $childFolderImportRequestIds
      dataImportRequestId: $dataImportRequestId
      parentFolderImportRequestId: $parentFolderImportRequestId
    )
  }
`);

export const TRACK_FILES_FOR_FOLDER_IMPORT_REQUEST = gql(`
  mutation TrackFilesForFolderImportRequest(
    $dataImportRequestId: ID!
    $folderImportRequestId: ID!
    $files: [FileItemInput!]!
  ) {
    trackFilesForFolderImportRequest(
      dataImportRequestId: $dataImportRequestId
      folderImportRequestId: $folderImportRequestId
      files: $files
    )
  }
`);

export const CREATE_MULTIPART_UPLOAD = gql(`
  mutation CreateMultipartUpload($fileName: String!) {
    createMultipartUpload(fileName: $fileName) {
      bucket
      fileId
      key
      uploadId
    }
  }
`);

export const CREATE_FILE = gql(`
  mutation CreateFile(
    $accountId: ID!
    $bucket: String!
    $fileId: ID!
    $fileKind: FileKindInput!
    $key: String!
    $name: String!
    $size: Float!
    $type: String!
  ) {
    createFile(
      accountId: $accountId
      bucket: $bucket
      fileId: $fileId
      fileKind: $fileKind
      key: $key
      name: $name
      size: $size
      type: $type
    ) {
      __typename
      ... on AssetFile { id }
      ... on ImageFile { id }
      ... on ObjFile { id }
      ... on Ortho2dFile { id }
      ... on Tileset3dFile { id }
    }
  }
`);

export const CREATE_IMAGE_THUMBNAILS_FOR_PERSISTED_IMAGES_BY_IDS = gql(`
  mutation CreateImageThumbnailsForPersistedImagesByIds($fileIds: [ID]!) {
    createImageThumbnailsForPersistedImagesByIds(fileIds: $fileIds)
  }
`);

export const CREATE_MODEL = gql(`
  mutation CreateModel($modelName: String!) {
    createModel(modelName: $modelName)
  }
`);

export const START_MODEL_TRAINING = gql(`
  mutation TriggerTrainV2($request: TriggerTrainV2Input!) {
    triggerTrainV2(request: $request)
  }
`);

export const START_PREDICTION = gql(`
  mutation TriggerPredictionV2($request: TriggerPredictionV2Input!) {
    triggerPredictionV2(request: $request) {
      predicted_boxes
      predicted_labels
    }
  }
`);
