import { ReactNode } from 'react';

interface ItemProps {
  label: string;
  value: string | string[] | ReactNode;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

export const ItemRow = ({ label, value, onMouseEnter, onMouseLeave }: ItemProps) => (
  <div
    className="flex flex-row cursor-default items-center justify-between"
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
  >
    <p className="color-neutral-800 typo-text-s">{label}</p>
    <p className="color-neutral-600 typo-text-s">{value}</p>
  </div>
);
