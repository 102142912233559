import { useUploadStore } from '@/stores/upload';
import { cn } from '@skand/ui';
import { UploadSession, useUploadSession } from '@skand/uploader';
import { useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import { UploadDetails } from './UploadDetails';
import { UploadOverview } from './UploadOverview';

export interface FileSectionProps {
  uploadSession: UploadSession;
}

export const FileSection = ({ uploadSession }: FileSectionProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const { getRootProps, getInputProps, open } = useDropzone({
    multiple: true,
    noClick: true,
    noKeyboard: true,
    onDrop: uploadSession.addFiles,
  });

  const showUploadDetails = useUploadStore(state => state.showUploadDetails);

  const { fileTreeRef } = useUploadSession(uploadSession);
  const numberOfFiles = fileTreeRef.current.numberOfFiles;
  const canAddFiles = numberOfFiles === 0;

  return (
    <div
      className={cn(
        'mt-3 b-1 b-neutral-400 rounded-2 b-solid px-3 flex flex-col flex-none overflow-auto',
        showUploadDetails && 'h-200px',
      )}
    >
      {numberOfFiles > 0 && <UploadOverview uploadSession={uploadSession} />}
      {numberOfFiles > 0 && showUploadDetails && <UploadDetails uploadSession={uploadSession} />}
      {canAddFiles && (
        <div
          className="my-3 h-80px flex items-center justify-center b-1 b-neutral-400 rounded-2 b-dashed"
          {...getRootProps()}
        >
          <input ref={inputRef} {...getInputProps()} />
          <span className="color-neutral-500 typo-text-s">Drag and drop files or&nbsp;</span>
          <span className="cursor-pointer color-neutral-800 underline typo-link-s" onClick={open}>
            browse files from your computer
          </span>
        </div>
      )}
    </div>
  );
};
