import { cn } from '@/utils/classname';

interface PaginationButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  isActive?: boolean;
}
export const PaginationButton = (props: PaginationButtonProps) => {
  const { children, isActive, disabled, ...otherProps } = props;
  return (
    <button
      className={cn(
        'w-5 b-none bg-none bg-transparent',
        'flex items-center justify-center',
        isActive ? 'color-neutral-800 typo-text-xs-em' : 'color-neutral-600 typo-text-xs',
        { 'hover:cursor-pointer': !disabled },
        { '!color-neutral-300': disabled },
      )}
      disabled={disabled}
      {...otherProps}
    >
      {children}
    </button>
  );
};
