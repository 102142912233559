import { NODES_TABLE_DEFAULT_PAGE_INDEX } from '@/constants/node';
import { queryClient } from '@/graphql/client';
import {
  DownloadSystemNodesMutationVariables,
  File,
  UpdateSystemNodeNameMutationVariables,
} from '@/graphql/codegen/graphql';
import { DOWNLOAD_SYSTEM_NODES, UPDATE_SYSTEM_NODE_NAME } from '@/graphql/mutations';
import { request } from '@/graphql/request';
import { useGetSignedObjectDownloadUrl } from '@/hooks/useGetSignedObjectDownloadUrl';
import { useSystemNodesUserActionsPermissionsBySystemNodeIds } from '@/hooks/useSystemNodesUserActionsPermissionsBySystemNodeIds';
import { downloadFiles, setIsOpenDownloadDialog, setIsRequestingDownload } from '@/stores/download';
import { useFiles } from '@/stores/files';
import { SystemNode } from '@/stores/systemNodes';
import { cn } from '@/utils/classname';
import { getElementWidth } from '@/utils/elementWidth';
import { removeExtension } from '@/utils/fileExtension';
import { getNodeIcon } from '@/utils/nodeIcons';
import { DATASETS_ADVANCED_DOWNLOADER } from '@/utils/split';
import { Button, CheckBox, Menu, MenuItem } from '@skand/ui';
import { useTreatments } from '@splitsoftware/splitio-react';
import { useMutation } from '@tanstack/react-query';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Label } from '../Label';
import { MoreMenu } from '../MoreMenu';

interface FileRowProps {
  node: SystemNode;
  pageIndex: number;
  onClick: (value: string) => void;
  onSelectFile: (node: SystemNode) => void;
  setPageIndex: (node: number) => void;
  setSearchKey: (value: string) => void;
  setEditNodeTarget: (node: SystemNode | null) => void;
  editNodeTarget: SystemNode | null;
  handleEditAccessMenu: (id: SystemNode['id'] | null) => void;
  setSystemNodes: (value: []) => void;
}

export const FileRow = ({
  node,
  pageIndex,
  onClick,
  onSelectFile,
  setPageIndex,
  setSearchKey,
  setEditNodeTarget,
  editNodeTarget,
  handleEditAccessMenu,
  setSystemNodes,
}: FileRowProps) => {
  const treatments = useTreatments([DATASETS_ADVANCED_DOWNLOADER]);
  const isAdvancedDownloaderEnabled = treatments[DATASETS_ADVANCED_DOWNLOADER].treatment === 'on';

  const { project } = useFiles(state => state);

  const fileNameRef = useRef(null);
  const [fileNameWidth, setFilenNameWidth] = useState(0);
  const [enabledFileNameTooltip, setEnabledFileNameTooltip] = useState(false);
  const [editInput, setEditInput] = useState(node.name);

  const locallySignedUrl = useGetSignedObjectDownloadUrl(node.fileId ? [node.fileId] : []);
  const { userHasAdminPermission } = useSystemNodesUserActionsPermissionsBySystemNodeIds({
    systemNodeIds: node.id ? [node.id] : [],
  });

  const isSystemManagedNode = [
    project?.rootFolderNodeId,
    project?.renderObjectsRootFolderNodeId,
    project?.annotationsRootFolderNodeId,
    project?.userFilesRootFolderNodeId,
  ].includes(node.id);

  useEffect(() => {
    setFilenNameWidth(getElementWidth(fileNameRef));
  }, []);

  const handleClick = () => {
    if (editNodeTarget || node.kind === 'LinkNode') return;
    if (node.type === 'folder' && node.sourceNodeId) {
      onClick(node.sourceNodeId);
      setPageIndex(NODES_TABLE_DEFAULT_PAGE_INDEX);
    } else if (node.type === 'folder') {
      setPageIndex(NODES_TABLE_DEFAULT_PAGE_INDEX);
      onClick(node.id);
    }
    setSearchKey('');
  };

  const renderNodeIcon = () => {
    if (!isSystemManagedNode) {
      return getNodeIcon(node.extension, node.kind);
    } else {
      return 'i-fs-folder-lock';
    }
  };

  const updateSystemNodeName = useMutation({
    mutationFn: (variables: UpdateSystemNodeNameMutationVariables) =>
      request(UPDATE_SYSTEM_NODE_NAME, variables),
    onSuccess: () => {
      if (pageIndex === 0) {
        queryClient.invalidateQueries(['LIST_SYSTEM_NODES_BY_PARENT_NODE_ID']);
      } else {
        // LIST_SYSTEM_NODES_BY_PARENT_NODE_ID will be triggered when pageIndex is changed
        setPageIndex(NODES_TABLE_DEFAULT_PAGE_INDEX);
      }
      setSystemNodes([]);
      setEditNodeTarget(null);
    },
  });

  const handleSubmit = async () => {
    let nodeName = editInput;
    if (node.type === 'file') {
      nodeName = `${editInput}.${node.extension}`;
    }
    await updateSystemNodeName.mutateAsync({
      systemNodeId: node.id,
      name: nodeName,
    });
  };

  const downloadSystemNodes = useMutation({
    mutationFn: (variables: DownloadSystemNodesMutationVariables) => {
      return request(DOWNLOAD_SYSTEM_NODES, variables);
    },
  });

  const handleDownloadFile = useCallback(async () => {
    if (node.fileId && locallySignedUrl) {
      const isLinkedNode = node.kind === 'LinkNode';
      downloadFiles(locallySignedUrl as File[], isAdvancedDownloaderEnabled, isLinkedNode);
    } else {
      downloadSystemNodes.mutate({
        nodeIds: [node.kind === 'LinkNode' ? node.sourceNodeId : node.id],
      });
      setIsRequestingDownload(true);
    }

    setIsOpenDownloadDialog(true);
  }, [
    node.fileId,
    node.kind,
    node.sourceNodeId,
    node.id,
    locallySignedUrl,
    isAdvancedDownloaderEnabled,
    downloadSystemNodes,
  ]);

  return (
    <div className={cn('w-full', 'my-3', 'flex', 'flex-row', 'justify-between', 'cursor-pointer')}>
      <div className={cn('flex felx-row gap-3 items-center mr-3')}>
        <CheckBox />
        <div className={cn('flex flex-row  gap-3')} onClick={handleClick}>
          <div className={cn(`${renderNodeIcon()} text-5 text-neutral-600 cursor-pointer`)} />
          {editNodeTarget === node ? (
            <div className="flex flex-1">
              <input
                className={cn(
                  'px-1',
                  'color-neutral-800',
                  'typo-text-s-em',
                  'rounded',
                  'border-1',
                  'border-solid',
                  'border-primary-400',
                  'outline-none',
                  'w-full',
                )}
                onChange={e => setEditInput(e.target.value)}
                value={editInput}
              />
              <Button className="ml-1" filled onClick={handleSubmit} primary size="xs">
                Save
              </Button>
              <Button className="ml-1" onClick={() => setEditNodeTarget(null)} size="xs">
                Cancel
              </Button>
            </div>
          ) : (
            <div className="relative">
              <p
                className={cn(
                  'w-auto max-w-[280px] text-neutral-800 typo-text-s text-nowrap overflow-hidden',
                )}
                onMouseEnter={() => setEnabledFileNameTooltip(true)}
                onMouseLeave={() => setEnabledFileNameTooltip(false)}
                ref={fileNameRef}
              >
                {node.name}
              </p>
              {fileNameWidth > 279 && enabledFileNameTooltip && (
                <div className={cn('absolute', 'z-1', 'top-5', 'left-[-56px]')}>
                  <Label
                    css="bg-neutral-100 py-1"
                    ellipsis={false}
                    labelTitle={node.name}
                    labelType="default"
                    textLength={node.name.length}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      <MoreMenu className={cn('cursor-pointer')}>
        <Menu className="z-2">
          <MenuItem disabled={isSystemManagedNode} onClick={() => handleDownloadFile()}>
            Download
          </MenuItem>
          <MenuItem
            className="cursor-pointer"
            onClick={() => {
              onSelectFile(node);
            }}
          >
            View details
          </MenuItem>
          <MenuItem
            disabled={!userHasAdminPermission || node.type === 'file' || node.kind === 'LinkNode'}
            onClick={() => handleEditAccessMenu(node.id)}
          >
            Edit access
          </MenuItem>

          <MenuItem
            className="cursor-pointer"
            disabled={isSystemManagedNode}
            onClick={() => {
              setEditInput(removeExtension(node.name));
              setEditNodeTarget(node);
            }}
          >
            Rename
          </MenuItem>
          {/* <MenuItem className="cursor-pointer" onClick={() => {}}>
            Copy share link
          </MenuItem>
          <MenuItem className="cursor-pointer" disabled={isSystemManagedNode} onClick={() => {}}>
            Delete
          </MenuItem> */}
        </Menu>
      </MoreMenu>
    </div>
  );
};
