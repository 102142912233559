import { Label } from '@/components/Label';
import { NODES_TABLE_DEFAULT_PAGE_INDEX } from '@/constants/node';
import { SystemNodeKindInput } from '@/graphql/codegen/graphql';
import { useFetchProject } from '@/hooks/useFetchProject';
import { useSystemNodes } from '@/hooks/useSystemNodes';
import { useFiles } from '@/stores/files';
import { SystemNode } from '@/stores/systemNodes';
import { cn } from '@/utils/classname';
import { search } from '@/utils/search';
import { Switch } from '@skand/ui';
import { useEffect, useMemo, useState } from 'react';
import { EmptyResourceMessage } from '../../../EmptyResourceMessage';
import { LazyLoader } from '../../../LazyLoader';
import { Breadcrumb } from './Breadcrumb';
import { FileRow } from './FileRow';

interface FilesTabProps {
  setShareFilesTab: (value: boolean) => void;
  shareFilesTab: boolean;
}

export const FilesTab = ({ setShareFilesTab, shareFilesTab }: FilesTabProps) => {
  const [searchKey, setSearchKey] = useState('');
  const [parentNodeId, setParentNodeId] = useState<string | null>(null);

  const [pageIndex, setPageIndex] = useState(NODES_TABLE_DEFAULT_PAGE_INDEX);
  const [fileTypeTooltip, setFileTypeTooltip] = useState(false);
  const [systemNodes, setSystemNodes] = useState<SystemNode[]>([]);
  const [breadCrumbParentNodeId, setBreadCrumbParentNodeId] = useState<string | null>(null);

  const { fetch: fetchProject, isLoading: isLoadingProject } = useFetchProject();
  const { project } = useFiles(state => state);

  useEffect(() => {
    fetchProject().then(project => {
      if (project && project.rootFolderNodeId) {
        setParentNodeId(project.rootFolderNodeId);
        setBreadCrumbParentNodeId(project.rootFolderNodeId);
        useFiles.setState({ project });
      }
    });
  }, [fetchProject]);

  const {
    systemNodes: fetchedNodes,
    response: { isFetching },
  } = useSystemNodes({
    parentNodeId,
    pageIndex,
    pageSize: 50,
    searchTerm: searchKey || undefined,
    nodeKinds: [
      SystemNodeKindInput.FileNode,
      SystemNodeKindInput.FolderNode,
      SystemNodeKindInput.LinkNode,
    ],
    includeMigratedData: true,
  });

  useEffect(() => {
    if (fetchedNodes) {
      setSystemNodes(prev => {
        const allNodes = [...prev, ...fetchedNodes];

        // Create a Map where the key is the node.id to ensure uniqueness
        const uniqueNodesMap = new Map(allNodes.map(node => [node.id, node]));

        // Filter by parentId and searchKey
        const uniqueNodes = Array.from(uniqueNodesMap.values())
          .filter(node => node.parentId === parentNodeId)
          .filter(node => search(node.name, searchKey));

        return uniqueNodes;
      });
    }
  }, [fetchedNodes, parentNodeId, searchKey]);

  const hasMore = useMemo(() => fetchedNodes && fetchedNodes.length > 0, [fetchedNodes]);

  const [emptyResourceTitle, emptyResourceDesc] = useMemo(() => {
    let emptyResourceDesc =
      'Link existing files from Data Management or upload new files using the Add button above.';
    let emptyResourceTitle = 'No files added';

    if (project?.annotationsRootFolderNodeId === parentNodeId) {
      emptyResourceTitle = 'No annotations added yet';
      emptyResourceDesc =
        'Folders will be created automatically when you add annotations. Any attached images or files related to annotations will be stored here.';
    } else if (project?.renderObjectsRootFolderNodeId === parentNodeId) {
      emptyResourceTitle = 'No layers added yet';
      emptyResourceDesc =
        'Folders will appear here when you create a layer. Any files used to create layers, such as ZIP, E57, or image files, will be stored in their respective folders.';
    }

    return [emptyResourceTitle, emptyResourceDesc];
  }, [parentNodeId, project?.annotationsRootFolderNodeId, project?.renderObjectsRootFolderNodeId]);

  return (
    <div className={cn('flex flex-col gap-3 h-[516px] w-full relative')}>
      <div className={cn('flex', 'flex-row', 'justify-between', 'items-center')}>
        <div className={cn('flex', 'flex-row', 'justify-between', 'items-center')}>
          <p className={cn('typo-text-small-em', 'color-neutral-800')}>Share all files</p>

          <span
            className={cn(
              'i-skand-info',
              'color-neutral-500',
              'ml-3',
              'text-3.5',
              'cursor-pointer',
            )}
            onMouseEnter={() => setFileTypeTooltip(true)}
            onMouseLeave={() => setFileTypeTooltip(false)}
          />

          <div className={cn(fileTypeTooltip ? 'flex' : 'hidden', 'absolute left-0 z-100 mt-18')}>
            <Label
              css="py-3"
              ellipsis={false}
              labelTitle="Toggle to share or restrict access to all files, this includes the root folders (Layers, Annotations, and User files)."
              labelType="info"
              textLength={120}
            />
          </div>
        </div>

        <Switch checked={shareFilesTab} onChange={() => setShareFilesTab(!shareFilesTab)} />
      </div>

      <div className="flex-1 overflow-hidden">
        <Breadcrumb
          onChangeParentNodeId={id => {
            setParentNodeId(id);
            setPageIndex(NODES_TABLE_DEFAULT_PAGE_INDEX);
          }}
          parentNodeId={breadCrumbParentNodeId}
          setParentNodeId={setBreadCrumbParentNodeId}
        />
        {!systemNodes.length && !isFetching ? (
          <EmptyResourceMessage
            emptyResourceContent={{
              emptyDesc: emptyResourceDesc,
              emptyTitle: emptyResourceTitle,
              emptyDescStyles: 'text-center',
            }}
          />
        ) : (
          <LazyLoader
            className="h-11/12 overflow-x-hidden"
            hasMore={hasMore}
            isLoading={isFetching || isLoadingProject}
            items={systemNodes}
            onLoadMore={() => setPageIndex(prev => prev + 1)}
          >
            {files =>
              files.map(file => (
                <FileRow
                  key={file.id}
                  node={file}
                  onClick={setParentNodeId}
                  setBreadCrumbParentNodeId={setBreadCrumbParentNodeId}
                  setPageIndex={setPageIndex}
                  setSearchKey={setSearchKey}
                  shareFilesTab={shareFilesTab}
                />
              ))
            }
          </LazyLoader>
        )}
      </div>
    </div>
  );
};
