import { FindIcon } from '@/components/IconButton';
import { Layer, addViewerEventListener, removeViewerEventListener } from '@/stores/viewer';
import { cn } from '@/utils/classname';
import { findElementById } from '@/utils/ifc';
import { getAllGuids } from '@/utils/ifcTree';
import { search } from '@/utils/search';
import { Input, Tree, TreeNodeProps } from '@skand/ui';
import { CursorListener, IFC, IfcElementNode, ModelNode } from '@skand/viewer-component-v2';
import { useCallback, useEffect, useMemo, useState } from 'react';

export interface IFCTabProps {
  layer: Layer;
  setSelectedIfcElement: (node: IfcElementNode | null) => void;
  selectedIfcElement: IfcElementNode | null;
}

export const IFCTab = ({ layer, selectedIfcElement, setSelectedIfcElement }: IFCTabProps) => {
  const model = (layer.sceneNode as ModelNode).getModel() as IFC;
  const elementTree = useMemo(() => [model.getElementTree()], [model]);

  const [searchKey, setSearchKey] = useState('');
  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
  const [hidden, setHidden] = useState<Set<IfcElementNode>>(new Set());

  useEffect(() => {
    const cb: CursorListener = ({ userData }) => {
      if (userData) {
        const onMouseselectedElement = findElementById(
          userData,
          elementTree,
        ) as IfcElementNode | null;
        setSelectedIfcElement(onMouseselectedElement);
      }
    };
    addViewerEventListener('onCursorTap', cb);
    return () => removeViewerEventListener('onCursorTap', cb);
  }, [elementTree, setSelectedIfcElement]);

  // Reset IFC style on dismount
  useEffect(() => {
    return () => {
      model.setStyle([], []);
    };
  }, [model]);

  // Update IFC styling based on hidden and selected elements
  useEffect(() => {
    const hiddenIds = new Set<number>();
    const selectedIds = new Set<number>();
    if (selectedIfcElement) {
      const physicalIds = model.getPhysicalDescendants(selectedIfcElement.id, []);
      for (const id of physicalIds) {
        selectedIds.add(id);
      }
    }
    for (const element of hidden) {
      if (element.isPhysicalMesh) {
        hiddenIds.add(element.id);
      }
    }

    model.setStyle([...hiddenIds.values()], [...selectedIds.values()]);
  }, [hidden, model, selectedIfcElement]);

  // Compute memoized search results
  const searchResult = useMemo(() => {
    const results = [];
    const queue = [...elementTree];

    while (queue.length && searchKey.length) {
      const node = queue.shift();
      if (node) {
        queue.push(...node.children);
        if (search(node.name, searchKey) || search(node.type, searchKey)) {
          results.push(node);
        }
      }
    }
    return results;
  }, [elementTree, searchKey]);

  // Handle toggling the selected element
  const toggleSelected = useCallback(
    (element: IfcElementNode) => {
      if (selectedIfcElement === element) {
        setSelectedIfcElement(null);
      } else {
        setSelectedIfcElement(element);
      }
    },
    [selectedIfcElement, setSelectedIfcElement],
  );

  // Handle toggling visibility of element
  const toggleView = useCallback(
    (element: IfcElementNode) => {
      const set = new Set(hidden);
      const queue: IfcElementNode[] = [element];
      const descendants: IfcElementNode[] = [];
      while (queue.length) {
        const current = queue.shift();
        if (current) {
          descendants.push(current);
          queue.push(...current.children);
        }
      }
      if (set.has(element)) {
        for (const descendant of descendants) {
          set.delete(descendant);
        }
      } else {
        for (const descendant of descendants) {
          set.add(descendant);
        }
      }
      setHidden(set);
    },
    [hidden],
  );

  // Define Tree node component
  const TreeNode = useCallback(
    ({ data, isLeaf, depth, setOpen, isOpen }: TreeNodeProps<IfcElementNode>) => (
      <div className={cn('flex', 'flex-row', 'items-center')}>
        <div
          className={cn('flex', 'flex-row', 'items-center', 'flex-1', 'mr-2')}
          style={{ marginLeft: `${depth}rem` }}
        >
          <div
            className={cn(
              !isLeaf ? 'i-skand-dropdownarrow cursor-pointer' : 'w-1em h-1em',
              'text-neutral-400',
              'text-3',
              !isOpen && 'rotate-270',
            )}
            onClick={() => setOpen(!isOpen)}
          />
          <span
            className={cn(
              'color-neutral-800',
              'typo-text-small',
              'ml-2',
              'flex-1',
              'cursor-pointer',
              'whitespace-nowrap',
            )}
            onClick={() => toggleSelected(data)}
          >
            {data.name.length ? data.name : data.type}
          </span>
        </div>
        <div
          className={cn(
            'bg-white',
            'text-neutral-600',
            'cursor-pointer',
            'fixed',
            'right-0',
            'pl-1',
          )}
          onClick={() => toggleView(data)}
        >
          <div className={cn(hidden.has(data) ? 'i-skand-hide' : 'i-skand-show')} />
        </div>
      </div>
    ),
    [hidden, toggleSelected, toggleView],
  );

  return (
    <div className={cn('flex flex-col mt-4')}>
      <Input label="Search" onChange={setSearchKey} tail={<FindIcon />} value={searchKey} />
      <div className={cn('flex flex-row justify-between items-center mt-3 mb-2')}>
        <p
          className={cn('color-neutral-800', 'typo-text-xs-em', 'cursor-pointer')}
          onClick={() => setExpandedKeys([])}
        >
          COLLAPSE ALL
        </p>
        <p
          className={cn('color-neutral-800', 'typo-text-xs-em', 'cursor-pointer')}
          onClick={() => setExpandedKeys(getAllGuids(elementTree))}
        >
          EXPAND ALL
        </p>
      </div>
      <div className={cn('h-100 overflow-auto')}>
        <Tree
          expandedKeys={expandedKeys}
          getKey={node => node.guid}
          roots={searchResult.length ? searchResult : elementTree}
          sortCmp={(a, b) => a.children.length - b.children.length}
          walker={node => node.children}
        >
          {TreeNode}
        </Tree>
      </div>
    </div>
  );
};
