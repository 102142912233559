export const LAYER_STATUS = {
  PROCESSING: 'PROCESSING',
  UNPUBLISHED: 'UNPUBLISHED',
  PUBLISHED: 'PUBLISHED',
} as const;
export type LayerStatus = keyof typeof LAYER_STATUS;

export const LAYER_FORMAT_TYPE = {
  IMAGES: 'IMAGES',
  MESH_3D: 'MESH_3D',
  POINT_CLOUD: 'POINT_CLOUD',
  OBJ: 'OBJ',
  ORTHO_2D: 'ORTHO_2D',
  GAUSSIAN_SPLAT: 'GAUSSIAN_SPLAT',
  BIM_CAD_MODEL: 'BIM_CAD_MODEL',
  DOCUMENT: 'DOCUMENT',
  TERRAIN: 'TERRAIN',
  IFC: 'IFC',
  DXF: 'DXF',
} as const;
export type LayerFormatType = keyof typeof LAYER_FORMAT_TYPE;
