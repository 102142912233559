import { SPLIT_API_KEY, USER_EMAIL_NAME } from '@/LegacyExplore/constants/env';
import Cookies from 'js-cookie';

// Make sure user key isn't undefined when user doesn't have the cookie
// Otherwise, it will return `control` treatment
const USER_EMAIL = (USER_EMAIL_NAME && Cookies.get(USER_EMAIL_NAME)) || 'user';

export const config = {
  core: {
    authorizationKey: SPLIT_API_KEY,
    key: USER_EMAIL,
  },
};

export const DISABLE_DOWNLOAD_FLAG = 'datasets-disable-download';
export const OPERATION_FEATURE_FLAG = 'explore-operations';
export const PANORAMA_PROCESS_FEATURE_FLAG = 'explore-panorama-process';
export const REMOVE_LAYER_POSITION = 'explore-remove-layer-position';
export const SHARE_IN_DEMO_FEATURE_FLAG = 'explore-share-in-demo';
export const MAX_NETWORK_REQUEST_FLAG = 'explore-max-network-request';
export const NETWORK_REQUEST_SORTING = 'explore-network-request-sorting';
export const PHOTO_ANNOTATION_FEATURE_FLAG = 'explore-2d-annotation';
export const EXPLORE_MAP_FLAG = 'explore-dashboard-map';
export const HIDE_LEFT_SIDEBAR_BY_DEFAULT = 'explore-hide-left-sidebar-by-default';
export const MANUAL_ANNOTATION_PROCESS_FEATURE_FLAG = 'explore-manual-annotation-process';
export const RENDERER_STATISTICS_FLAG = 'explore-renderer-statistics';
export const AI_PREDICTOR = 'explore-ai-predictor';
export const GOOGLE_EARTH_USER = 'google-earth-globe';
export const GOOGLE_EARTH_ACCOUNT = 'explore-google-earth-account';
export const ANNOTATION_CSV = 'annotation-csv';
export const MULTITHREADING_OBJ = 'explore-multithreading-obj';
export const ESRI_CSV_FLAG = 'explore-esri-csv';
export const TERRA_GEO_JSON_FLAG = 'explore-terraexplorer-geojson';
export const ADVANCED_UPLOADER = 'advanced-uploader';
export const MODEL_TRAINING = 'explore-model-training';
export const ANNOTATION_ORDERED_NAME = 'explore-annotation-ordered-name';
