export const NODES_TABLE_DEFAULT_PAGE_INDEX = 0;
export const NODES_TABLE_DEFAULT_PAGE_SIZE = 20;
export const NODES_TABLE_PAGE_SIZE_10 = 10;
export const NODES_TABLE_PAGE_SIZE_12 = 12;

export const NODE_KIND = {
  FILE_NODE: 'FileNode',
  FOLDER_NODE: 'FolderNode',
};

export type NodeKind = keyof typeof NODE_KIND;
