import { Loading } from '@/components/Loading';
import { ActionType, OBJECT_TYPE, ObjectType, SUBJECT_TYPE } from '@/constants/policy';
import { queryClient } from '@/graphql/client';
import { PermissionPolicy, User, UserGroup, UserV2 } from '@/graphql/codegen/graphql';
import { useFetchUsers } from '@/hooks/useFetchUsers';
import { useListActionTypesByObjectTypeQuery } from '@/hooks/useListActionTypesByObjectTypeQuery';
import { useListPermissionPoliciesWithAccountContextQuery } from '@/hooks/useListPermissionPoliciesWithAccountContextQuery';
import { usePermissionPolicyMutation } from '@/hooks/usePermissionPolicyMutation';
import { useUserGroupsQuery } from '@/hooks/useUserGroupsQuery';
import {
  removeEditingSubjectsWithPermissionPolicyById,
  setEditingSubjectsWithPermissionPolicy,
  setUsersAndGroups,
  SubjectWithPolicy,
  useFiles,
  UserOrGroup,
} from '@/stores/files';
import { displayName } from '@/utils/user';
import { Button, cn, Modal } from '@skand/ui';
import { useEffect, useState } from 'react';
import PermissionsSelect from './PermissionsSelect';

interface EditAccessMenuProps {
  editingSystemNodeId: string;
  closeMenu: () => void;
  setEnableUserGroupMenu: (value: boolean) => void;
}

export const EditAccessMenu = ({
  editingSystemNodeId,
  closeMenu,
  setEnableUserGroupMenu,
}: EditAccessMenuProps) => {
  const fetchUsers = useFetchUsers();
  const editingSubjectsWithPermissionPolicy = useFiles(
    state => state.editingSubjectsWithPermissionPolicy,
  );
  const usersAndGroups = useFiles(state => state.usersAndGroups);
  const [users, setUsers] = useState<User[]>([]);
  const [actionTypes, setActionTypes] = useState<ActionType[]>([]);

  useEffect(() => {
    fetchUsers().then(setUsers);
  }, [fetchUsers]);
  const { groups } = useUserGroupsQuery();

  const fetchActionTypesByObjectType = useListActionTypesByObjectTypeQuery(OBJECT_TYPE.SYSTEM_NODE);

  useEffect(() => {
    const fetchActionTypes = async () => {
      const actionTypes = await fetchActionTypesByObjectType();
      setActionTypes(
        actionTypes.filter((actionType): actionType is ActionType => actionType !== null),
      );
    };

    fetchActionTypes();
  }, [fetchActionTypesByObjectType]);

  useEffect(() => {
    setUsersAndGroups([
      ...(users.map(user => ({ ...user, type: SUBJECT_TYPE.USER })) as UserOrGroup[]),
      ...(groups.map(user => ({ ...user, type: SUBJECT_TYPE.GROUP })) as UserOrGroup[]),
    ]);
  }, [users, groups]);

  const {
    permissionPolicies,
    response: { isFetching: isFetchingPermissionPolicies },
  } = useListPermissionPoliciesWithAccountContextQuery({
    objectId: editingSystemNodeId,
    objectType: OBJECT_TYPE.SYSTEM_NODE as ObjectType,
    subjectId: null,
    subjectType: null,
    actionType: null,
  });

  const handleUpsertOrDeletePermissionSuccess = () => {
    queryClient.invalidateQueries(
      useListPermissionPoliciesWithAccountContextQuery.getQueryKey({
        objectId: editingSystemNodeId,
        objectType: OBJECT_TYPE.SYSTEM_NODE as ObjectType,
        subjectId: null,
        subjectType: null,
        actionType: null,
      }),
    );
  };

  const { upsertPermission, deletePermission } = usePermissionPolicyMutation({
    onUpsertPermissionPoliciesSuccess: handleUpsertOrDeletePermissionSuccess,
    onDeletePermissionPoliciesSuccess: handleUpsertOrDeletePermissionSuccess,
  });

  useEffect(() => {
    const isSubjectsWithPermissionInitialized = editingSubjectsWithPermissionPolicy.length > 0;

    if (!usersAndGroups.length || !permissionPolicies.length || isSubjectsWithPermissionInitialized)
      return;

    const subjectsWithPolicy = permissionPolicies
      .map(policy => {
        const userOrGroup = usersAndGroups.find(
          userOrGroup => userOrGroup.id === policy?.subjectId,
        );

        // Ensure the userOrGroup exists before continuing
        if (!userOrGroup) {
          return null;
        }
        return {
          ...userOrGroup,
          policy,
        };
      })
      .filter(subjectWithPolicy => subjectWithPolicy !== null) as SubjectWithPolicy[];

    setEditingSubjectsWithPermissionPolicy([...subjectsWithPolicy]);

    // Don't depend on editingSubjectsWithPermissionPolicy changes to avoid infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersAndGroups, permissionPolicies]);

  const handleChangePermission = (subjectId: string, newActionType: ActionType) => {
    useFiles.setState(state => {
      const editingSubjectsWithPermissionPolicy = [...state.editingSubjectsWithPermissionPolicy];

      const updateIndex = editingSubjectsWithPermissionPolicy.findIndex(
        subject => subject.id === subjectId,
      );
      if (updateIndex !== -1) {
        const tempRecord = { ...editingSubjectsWithPermissionPolicy[updateIndex] };

        editingSubjectsWithPermissionPolicy[updateIndex] = {
          ...tempRecord,
          policy: {
            ...tempRecord.policy,
            actionType: newActionType,
          },
          isUpdated: true,
        };
      }

      return {
        editingSubjectsWithPermissionPolicy,
      };
    });
  };

  const handleDelete = (subjectId: string) => {
    removeEditingSubjectsWithPermissionPolicyById(subjectId);
  };

  const handleCloseDialog = () => {
    closeMenu();
    setEditingSubjectsWithPermissionPolicy([]);
  };

  const handleSaveChanges = async () => {
    const { toUpdatePermissionPolicies, toAddPermissionPolicies } =
      editingSubjectsWithPermissionPolicy.reduce(
        (acc, currSubjectWithPolicy) => {
          const policy = currSubjectWithPolicy.policy;
          delete policy.createdAt;
          delete policy.updatedAt;

          if (currSubjectWithPolicy.isUpdated) {
            return {
              ...acc,
              toUpdatePermissionPolicies: [...acc.toUpdatePermissionPolicies, policy],
            };
          }

          if (currSubjectWithPolicy.isNew) {
            return {
              ...acc,
              toAddPermissionPolicies: [...acc.toAddPermissionPolicies, policy],
            };
          }

          return acc;
        },
        {
          toUpdatePermissionPolicies: [] as PermissionPolicy[],
          toAddPermissionPolicies: [] as PermissionPolicy[],
        },
      );

    const toDeletePermissionPolicies = permissionPolicies.reduce((acc, currPolicy) => {
      const exist = editingSubjectsWithPermissionPolicy.some(
        subject => subject.id === currPolicy?.subjectId,
      );

      if (exist) return acc;

      const policy = { ...currPolicy };
      delete policy.createdAt;
      delete policy.updatedAt;

      return [...acc, policy];
    }, [] as PermissionPolicy[]);

    const toUpsertPermissionPolicies = [...toUpdatePermissionPolicies, ...toAddPermissionPolicies];
    if (toUpsertPermissionPolicies.length > 0) {
      await upsertPermission.mutateAsync(toUpsertPermissionPolicies);
    }

    if (toDeletePermissionPolicies.length > 0) {
      // Reset any changes in actionType back to its original value before performing delete
      const toDeletePermissions = toDeletePermissionPolicies.map(policy => ({
        ...policy,
        actionType: permissionPolicies?.find(
          p => p?.objectId === policy.objectId && p?.subjectId === policy.subjectId,
        )?.actionType,
      }));

      await deletePermission.mutateAsync(toDeletePermissions);
    }
    queryClient.invalidateQueries([
      'LIST_SUBJECT_TO_OBJECTS_PERMISSION_POLICIES_WITH_EXPANDED_SUBJECT_IDS',
    ]);
    queryClient.invalidateQueries([
      'FIND_SYSTEM_NODE_MOST_PERMISSIVE_ACTION_TYPE_FROM_PARENT_NODE_CHAIN_WITH_SYSTEM_NODE_ID',
    ]);
    handleCloseDialog();
  };

  return (
    <Modal className="w-[640px] flex">
      <></>

      <div className={cn('bg-neutral-100', 'flex-col', 'flex', 'p-6', 'rounded-2', 'w-640px')}>
        <div className="flex items-center justify-between">
          <h1 className="color-neutral-800 typo-text-l">Edit access</h1>
          <Button
            className={cn('hover:cursor-pointer', 'w-134px')}
            disabled={isFetchingPermissionPolicies}
            onClick={() => setEnableUserGroupMenu(true)}
            primary
            size="s"
          >
            Add user / group
          </Button>
        </div>

        <div className="mt-2 color-neutral-800 typo-text-s">
          Add or edit user and group permissions for this folder.
        </div>

        <div className="flex-1">
          <table className="mt-3 w-full border-collapse table-auto">
            <thead>
              <tr
                className={cn(
                  'b-t-1 b-t-neutral-500 b-t-solid',
                  'b-b-1 b-b-neutral-500 b-b-solid',
                  'text-left',
                  'grid grid-cols-3',
                  'p-r-6',
                )}
              >
                <th className="w-2/5 py-3 text-left uppercase color-neutral-800 typo-button-xs">
                  Users/Groups
                </th>
                <th className="py-3 text-left uppercase color-neutral-800 typo-button-xs">
                  Permissions
                </th>
              </tr>
            </thead>

            <tbody
              className={cn(
                'grid h-[calc(70vh-220px)] overflow-y-auto auto-rows-max m-y-1 p-y-1',
                'scrollbar scrollbar-rounded',
              )}
              style={{ scrollbarGutter: 'stable' }}
            >
              {isFetchingPermissionPolicies ? (
                <Loading />
              ) : (
                editingSubjectsWithPermissionPolicy.map(subjectWithPolicy => (
                  <tr
                    className={cn('grid grid-cols-3', 'items-center p-r-5')}
                    key={subjectWithPolicy.id}
                  >
                    <td className="pt-2 color-neutral-800 typo-text-s">
                      {subjectWithPolicy.type === SUBJECT_TYPE.USER
                        ? displayName(subjectWithPolicy as UserV2)
                        : (subjectWithPolicy as UserGroup).name}
                    </td>
                    <td className="pt-2">
                      <PermissionsSelect
                        actionType={subjectWithPolicy.policy.actionType as ActionType}
                        onChangePermission={handleChangePermission}
                        subjectId={subjectWithPolicy.id as string}
                        validActionTypes={actionTypes}
                      />
                    </td>
                    <td className="pt-2">
                      <div className="flex justify-end">
                        <div
                          className={cn(
                            'i-skand-close text-3 color-neutral-400',
                            'hover:cursor-pointer',
                          )}
                          onClick={() => handleDelete(subjectWithPolicy.id as string)}
                        />
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>

        <div className="flex justify-between gap-3">
          <Button
            className={cn('hover:cursor-pointer ', 'flex-1')}
            onClick={() => handleCloseDialog()}
            size="s"
          >
            Cancel
          </Button>

          <Button
            className={cn('hover:cursor-pointer', 'flex-1')}
            filled
            onClick={() => handleSaveChanges()}
            primary
            size="s"
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
};
