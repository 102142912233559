import { SystemKindNodeType } from '@/stores/systemNodes';

export const getNodeIcon = (ext: string | null, kind?: SystemKindNodeType) => {
  if (!ext) {
    return kind === 'LinkNode' ? 'i-fs-file-link' : 'i-fs-file';
  }

  if (kind === 'LinkNode') {
    switch (ext.toLowerCase()) {
      case 'folder':
        return 'i-fs-folder-link';
      case 'zip':
        return 'i-fs-zip-link';
      case 'png':
        return 'i-fs-png-link';
      case 'jpg':
      case 'jpeg':
        return 'i-fs-image-link';
      case 'xslx':
        return 'i-fs-xlsx-link';
      case 'docx':
        return 'i-fs-docx-link';
      case 'pptx':
        return 'i-fs-pptx-link';
      case 'dwg':
        return 'i-fs-dwg-link';
      case 'e57':
        return 'i-fs-e57-link';
      case 'pdf':
        return 'i-fs-pdf-link';
      default:
        return 'i-fs-file-link';
    }
  } else {
    switch (ext.toLowerCase()) {
      case 'folder':
        return 'i-fs-folder';
      case 'zip':
        return 'i-fs-zip';
      case 'png':
        return 'i-fs-png';
      case 'jpg':
      case 'jpeg':
        return 'i-fs-image';
      case 'xslx':
        return 'i-fs-xlsx';
      case 'docx':
        return 'i-fs-docx';
      case 'pptx':
        return 'i-fs-pptx';
      case 'dwg':
        return 'i-fs-dwg';
      case 'e57':
        return 'i-fs-e57';
      case 'pdf':
        return 'i-fs-pdf';
      default:
        return 'i-fs-file';
    }
  }
};
