import { persist } from '@/utils/Persist';
import { atom } from 'jotai';
import { jotaiStore } from './jotaiStore';

interface ImageLoadingOverlayItem {
  proportion: number;
  message: string;
}

const STEPS: ImageLoadingOverlayItem[] = [
  { proportion: 0.3, message: 'Don’t rush me, I’m trying.' },
  { proportion: 0.6, message: 'Where are we going?' },
  { proportion: 0.9, message: 'I’ve got it! Hang on a tiny bit longer.' },
];

class ImageLoadingOverlayStore {
  enabled = atom(persist.get('image') !== null);
  currentStepNumber = atom(0);
  currentStep = atom(get => {
    const stepNumber = get(this.currentStepNumber);
    if (stepNumber >= STEPS.length) return null;
    return STEPS[stepNumber] ?? null;
  });
  isOpen = atom(get => {
    return get(this.enabled) && !!get(this.currentStep);
  });

  next() {
    jotaiStore.set(this.currentStepNumber, prev => prev + 1);
  }

  hide() {
    jotaiStore.set(this.enabled, false);
  }
}

export const imageLoadingOverlayStore = new ImageLoadingOverlayStore();
