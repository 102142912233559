// @unocss-include
import { SubjectType } from '@/constants/policy';
import { PermissionPolicy, UserGroup, UserV2 } from '@/graphql/codegen/graphql';
import { Project } from '@/utils/project';
import { create } from 'zustand';
import { SystemNode } from './systemNodes';

export type UserWithType = UserV2 & { type: SubjectType };

export type UserGroupWithType = UserGroup & { type: SubjectType };

export type UserOrGroup = UserWithType | UserGroupWithType;

export type SubjectWithPolicy = UserOrGroup & {
  policy: PermissionPolicy;
  isUpdated?: boolean;
  isNew?: boolean;
};

interface FilesState {
  selectedFiles: SystemNode[];
  project: Project | null;
  parentNodeId: string | null;
  enabledUploader: boolean;
  editingSubjectsWithPermissionPolicy: SubjectWithPolicy[];
  usersAndGroups: UserOrGroup[];
}

export const useFiles = create<FilesState>()(() => ({
  selectedFiles: [],
  project: null,
  parentNodeId: null,
  enabledUploader: false,
  editingSubjectsWithPermissionPolicy: [],
  usersAndGroups: [],
}));

export const setSelectedFiles = (files: SystemNode[], ids: string[]) => {
  useFiles.setState(state => {
    const selectedNodeIds = new Set(ids);

    // Store both the newly selected files and the existing files, since systemNodes only provides the current page nodes.
    const newSelectedFiles = [
      ...state.selectedFiles.filter(file => selectedNodeIds.has(file.id)),
      ...files.filter(file => selectedNodeIds.has(file.id)),
    ];

    const uniqueFiles = Array.from(new Map(newSelectedFiles.map(file => [file.id, file])).values());

    return {
      selectedFiles: uniqueFiles,
    };
  });
};

export const setEditingSubjectsWithPermissionPolicy = (
  editingSubjectsWithPermissionPolicy: SubjectWithPolicy[],
) =>
  useFiles.setState(() => ({
    editingSubjectsWithPermissionPolicy,
  }));

export const setUsersAndGroups = (usersAndGroups: UserOrGroup[]) =>
  useFiles.setState(() => ({
    usersAndGroups,
  }));

export const removeEditingSubjectsWithPermissionPolicyById = (subjectId: string) =>
  useFiles.setState(state => {
    const editingSubjectsWithPermissionPolicy = [...state.editingSubjectsWithPermissionPolicy];

    const removeIndex = editingSubjectsWithPermissionPolicy.findIndex(
      subject => subject.id === subjectId,
    );
    if (removeIndex !== -1) {
      editingSubjectsWithPermissionPolicy.splice(removeIndex, 1);
    }

    return {
      editingSubjectsWithPermissionPolicy,
    };
  });
