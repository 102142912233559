import { PaginationButton } from './PaginationButton';

interface PaginationProps {
  maxVisiblePages?: number;
  currentPage: number;
  totalPages: number;
  isNextPageDisabled: boolean;
  isPreviewPageDisabled: boolean;
  onPageChange: (newPage: number) => void;
}

export const Pagination = ({
  maxVisiblePages = 6,
  currentPage,
  totalPages,
  isNextPageDisabled,
  isPreviewPageDisabled,
  onPageChange,
}: PaginationProps) => {
  const FIRST_PAGE = 1;
  const LAST_PAGE = totalPages;
  const hasNoAvailablePages = totalPages === -1;

  const generatePaginationLinks = () => {
    const paginationLinks = [];
    const halfVisiblePages = Math.floor(maxVisiblePages / 2);

    if (totalPages <= maxVisiblePages) {
      // Display all pages if total pages are less than or equal to maxVisiblePages
      for (let i = 1; i <= totalPages; i++) {
        paginationLinks.push(
          <PaginationButton
            isActive={i === currentPage}
            key={i}
            onClick={() => onPageChange(i - 1)}
          >
            {i}
          </PaginationButton>,
        );
      }
    } else {
      let startPage;
      let endPage;

      if (currentPage <= halfVisiblePages) {
        startPage = 1;
        endPage = maxVisiblePages;
      } else if (currentPage >= totalPages - halfVisiblePages) {
        startPage = totalPages - maxVisiblePages + 1;
        endPage = totalPages;
      } else {
        startPage = currentPage - halfVisiblePages;
        endPage = currentPage + halfVisiblePages - 1;
      }

      if (startPage !== 1) {
        paginationLinks.push(
          <PaginationButton
            isActive={FIRST_PAGE === currentPage}
            key={FIRST_PAGE}
            onClick={() => onPageChange(FIRST_PAGE)}
          >
            {FIRST_PAGE}
          </PaginationButton>,
        );

        if (startPage !== 2) {
          paginationLinks.push(
            <span className="color-neutral-600 typo-text-xs" key="start-ellipses">
              ...
            </span>,
          );
        }
      }

      // Add the page numbers within the visible range
      for (let i = startPage; i <= endPage; i++) {
        paginationLinks.push(
          <PaginationButton isActive={i === currentPage} key={i} onClick={() => onPageChange(i)}>
            {i}
          </PaginationButton>,
        );
      }

      if (endPage !== LAST_PAGE) {
        if (endPage !== LAST_PAGE - 1) {
          paginationLinks.push(
            <span className="color-neutral-600 typo-text-xs" key="end-ellipses">
              ...
            </span>,
          );
        }

        paginationLinks.push(
          <PaginationButton
            isActive={LAST_PAGE === currentPage}
            key={LAST_PAGE}
            onClick={() => onPageChange(LAST_PAGE)}
          >
            {LAST_PAGE}
          </PaginationButton>,
        );
      }
    }

    // Add [maxVisiblePages] dummy page numbers
    if (!paginationLinks.length) {
      for (let i = 1; i <= maxVisiblePages; i++) {
        paginationLinks.push(
          <PaginationButton disabled key={i}>
            {i}
          </PaginationButton>,
        );
      }
    }

    return paginationLinks;
  };

  return (
    <nav className="flex gap-1">
      <PaginationButton
        disabled={isPreviewPageDisabled || hasNoAvailablePages}
        onClick={() => onPageChange(currentPage - 1)}
      >
        <div className="i-skand-dropdown rotate-90 text-3" />
      </PaginationButton>
      {generatePaginationLinks()}
      <PaginationButton
        disabled={isNextPageDisabled || hasNoAvailablePages}
        onClick={() => onPageChange(currentPage + 1)}
      >
        <div className="i-skand-dropdown rotate-270 text-3" />
      </PaginationButton>
    </nav>
  );
};
