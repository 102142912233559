export function abbreviate(input: string) {
  // Clean and split the input
  const words = input
    .toLowerCase()
    .trim()
    .split(/[\s-_]+/);

  // Handle multi-word phrases
  if (words.length >= 3) {
    // For three or more words: use first letter of first three words
    return words
      .slice(0, 3)
      .map(w => w[0])
      .join('')
      .toUpperCase();
  }

  if (words.length === 2) {
    // For two words:

    // Option 1: First syllable of each word
    const firstWordSyl = getSyllables(words[0])[0][0];
    const secondWordSyl = getSyllables(words[1])[0];
    if (secondWordSyl.length >= 2) {
      return (firstWordSyl + secondWordSyl.slice(0, 2)).toUpperCase();
    }

    // Option 2: If first word is shorter, use more of it
    if (words[0].length <= 2) {
      return (words[0] + words[1][0]).toUpperCase();
    }

    // Option 3: If second word is shorter, use more of it
    if (words[1].length <= 2) {
      return (words[0][0] + words[1]).toUpperCase();
    }

    // Default: First letter + first two letters of second word
    return (words[0][0] + words[1].slice(0, 2)).toUpperCase();
  }

  // Helper function to get syllables
  function getSyllables(word: string) {
    const vowels = 'aeiou';
    const syllables = [];
    let currentSyllable = '';
    let hasVowel = false;

    for (let i = 0; i < word.length; i++) {
      currentSyllable += word[i];
      if (vowels.includes(word[i])) hasVowel = true;

      if (
        hasVowel &&
        (i === word.length - 1 ||
          (!vowels.includes(word[i]) && i < word.length - 1 && !vowels.includes(word[i + 1])))
      ) {
        syllables.push(currentSyllable);
        currentSyllable = '';
        hasVowel = false;
      }
    }
    if (currentSyllable) syllables.push(currentSyllable);
    return syllables;
  }

  // For single word, we need more sophisticated rules
  const word = words[0];

  // If word is 3 letters or less, just return it padded if necessary
  if (word.length <= 3) {
    return word.padEnd(3, 'x');
  }

  // Find vowels and consonants
  const vowels = 'aeiou';
  const isVowel = (char: string) => vowels.includes(char);

  // Strategy 1: First, last, and middle strong consonant
  const consonants = Array.from(word).filter(c => !isVowel(c));
  if (consonants.length >= 3) {
    const midIndex = Math.floor(consonants.length / 2);
    return consonants[0] + consonants[midIndex] + consonants[consonants.length - 1];
  }

  // Strategy 2: First letter, first letter after first vowel, last letter
  let secondLetter = '';
  for (let i = 1; i < word.length; i++) {
    if (isVowel(word[i - 1]) && !isVowel(word[i])) {
      secondLetter = word[i];
      break;
    }
  }
  if (!secondLetter) secondLetter = word[Math.floor(word.length / 2)];

  return word[0] + secondLetter + word[word.length - 1];
}
