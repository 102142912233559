import { SystemNode } from '@/stores/systemNodes';
import React, { useCallback, useRef } from 'react';

export interface LazyLoaderProps {
  hasMore: boolean;
  isLoading: boolean;
  onLoadMore: () => void;
  children: (items: SystemNode[]) => React.ReactNode;
  items: SystemNode[];
  className?: string;
}

export const LazyLoader = ({
  hasMore,
  isLoading,
  onLoadMore,
  children,
  items,
  className,
}: LazyLoaderProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const threshold = 50;

  const handleScroll = useCallback(() => {
    const container = containerRef.current;
    if (container) {
      const { scrollTop, scrollHeight, clientHeight } = container;
      // Trigger onLoadMore when scrolled near the bottom
      if (scrollTop + clientHeight >= scrollHeight - threshold && hasMore && !isLoading) {
        onLoadMore();
      }
    }
  }, [hasMore, isLoading, onLoadMore]);

  return (
    <div className={className || 'overflow-auto h-full'} onScroll={handleScroll} ref={containerRef}>
      {children(items)}
    </div>
  );
};
