import { EmptyResourceMessage } from '@/components/EmptyResourceMessage';
import { useFetchImageUrls } from '@/hooks/useFetchImageUrls';
import {
  Photo,
  startPanoramaWalkthrough,
  startPhotoOverlay,
  stopPanoramaView,
  stopPhotoOverlay,
  useViewer,
} from '@/stores/viewer';
import { cn } from '@/utils/classname';
import { EmptyDesc, QuickList, QuickListAPI, QuickListItemProps } from '@skand/ui';
import { Panorama } from '@skand/viewer-component-v2';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Thumbnail } from './Thumbnail';

export const ImageRibbon = () => {
  const panoramaGroups = useViewer(state => state.panoramaGroups);
  const photo2DGroups = useViewer(state => state.photo2DGroups);

  const photos = useViewer(state => state.ribbonPhotos);
  const targetPhoto = useViewer(state => state.targetPhoto);
  const loadingMutex = useViewer(state => state.loadingMutex);

  const [, setReady] = useState(false);
  const [quickListAPI, setQuickListAPI] = useState<QuickListAPI | null>(null);

  // List of visible photos in the ribbon
  const visiblePhotos = useMemo(() => {
    const result: Photo[] = [];

    if (!quickListAPI) return result;
    for (let i = quickListAPI.start; i < quickListAPI.stop && i < photos.length; i++) {
      result.push(photos[i]);
    }
    return result;
  }, [photos, quickListAPI]);

  const noUrlPhotos = useMemo(
    () => visiblePhotos.filter(photo => !photo.url).map(photo => photo.id),
    [visiblePhotos],
  );
  const fetchUrls = useFetchImageUrls(noUrlPhotos, false);

  const noThumbnailPhotos = useMemo(
    () => visiblePhotos.filter(photo => !photo.thumbnailUrl).map(photo => photo.id),
    [visiblePhotos],
  );
  const fetchThumbnailUrls = useFetchImageUrls(noThumbnailPhotos, true);

  // Handle selecting an image
  const handleSelect = async (photo: Photo) => {
    const { enabled2D } = useViewer.getState();
    if (!loadingMutex) {
      stopPhotoOverlay();
      stopPanoramaView();

      if (photo.type === 'photo2D') {
        await startPhotoOverlay(photo, false, !enabled2D, false);
      } else {
        await startPanoramaWalkthrough(photo);
      }
    }
  };

  // Count the total number of images in the project
  const countImages = () => {
    const photo2DCount = photo2DGroups
      .map(group => group.photos.length)
      .reduce((sum, a) => sum + a, 0);
    const panoramaCount = panoramaGroups
      .map(group => group.photos.length)
      .reduce((sum, a) => sum + a, 0);
    return photo2DCount + panoramaCount;
  };

  // Fetch urls in bulk for photos in the sublist
  useEffect(() => {
    const fetchPhotoUrls = async () => {
      setReady(false);
      const urls: Map<string, string> = noUrlPhotos.length ? await fetchUrls() : new Map();
      const thumbnailUrls: Map<string, string> = noThumbnailPhotos.length
        ? await fetchThumbnailUrls()
        : new Map();

      for (const photo of photos) {
        const url = urls.get(photo.id);
        const thumbnailUrl = thumbnailUrls.get(photo.id);

        const widget = photo.widget?.getModel();
        if (url) {
          photo.url = url;
          if (widget instanceof Panorama && !photo.tileset) {
            widget.setUrl(url);
          }
        }
        if (thumbnailUrl) {
          photo.thumbnailUrl = thumbnailUrl;
          if (widget instanceof Panorama) {
            widget.setThumbnailUrl(thumbnailUrl);
          }
        }
      }
      setReady(true);
    };
    fetchPhotoUrls();
  }, [fetchThumbnailUrls, fetchUrls, noThumbnailPhotos, noUrlPhotos, photos]);

  // Scroll to selected photo
  useEffect(() => {
    const index = photos.findIndex(photo => photo === targetPhoto);
    if (quickListAPI) {
      quickListAPI.scrollTo(index * quickListAPI.itemSize);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [photos, targetPhoto]);

  // Handler for keyboard input
  const onKeyDown = (event: React.KeyboardEvent) => {
    event.preventDefault();
    const { key } = event;
    const index = photos.findIndex(photo => photo === targetPhoto);
    if (index < 0) {
      return;
    } else if (key === 'ArrowUp' && index > 0) {
      handleSelect(photos[index - 1]);
    } else if (key === 'ArrowDown' && index < photos.length - 1) {
      handleSelect(photos[index + 1]);
    }
  };

  // List item component wrapper
  const ListItem = useCallback(
    ({ index }: QuickListItemProps) => {
      const photo = photos[index];
      return (
        <div key={`photo-${index}`}>
          <Thumbnail photo={photo} />
        </div>
      );
    },
    [photos],
  );

  return (
    <div className="h-full min-w-400px flex flex-col bg-neutral-100 pl-18px pr-18px">
      <div className="mt-30px flex justify-between border-1 b-neutral-300 border-b-solid pb-8px">
        <p className={cn('typo-heading-4 color-neutral-800')}>Image viewer</p>
      </div>
      <div className={cn('h-full overflow-scroll w-full mt-2')} onKeyDown={onKeyDown} tabIndex={0}>
        {photos.length ? (
          <QuickList itemCount={photos.length} ref={setQuickListAPI}>
            {ListItem}
          </QuickList>
        ) : (
          <EmptyResourceMessage
            emptyResourceContent={{
              emptyTitle: 'No image groups enabled',
              emptyTitleClassName: '!color-gray-800',
              emptyDescNode: (
                <div>
                  <EmptyDesc asChild>
                    {
                      <span className="!typo-text-m">
                        There are {countImages()} images available in this project.{' '}
                      </span>
                    }
                  </EmptyDesc>
                  <EmptyDesc asChild>
                    <div
                      className={cn(
                        'flex',
                        'flex-row',
                        'items-center',
                        'justify-center',
                        'mt-12px',
                      )}
                    >
                      <div
                        className={cn('i-skand-show', 'cursor-pointer', 'text-16px', 'mr-8px')}
                      />
                      <span>Make sure the correct layer group is turned on.</span>
                    </div>
                  </EmptyDesc>
                </div>
              ),
            }}
          />
        )}
      </div>
    </div>
  );
};
