export const epsgCodeList = [
  { value: '3819', label: 'HD1909 - EPSG:3819' },
  { value: '3821', label: 'TWD67 - EPSG:3821' },
  { value: '3824', label: 'TWD97 - EPSG:3824' },
  { value: '3889', label: 'IGRS - EPSG:3889' },
  { value: '3906', label: 'MGI 1901 - EPSG:3906' },
  { value: '4023', label: 'MOLDREF99 - EPSG:4023' },
  { value: '4046', label: 'RGRDC 2005 - EPSG:4046' },
  { value: '4055', label: 'Popular Visualisation CRS - EPSG:4055' },
  { value: '4075', label: 'SREF98 - EPSG:4075' },
  { value: '4081', label: 'REGCAN95 - EPSG:4081' },
  { value: '4120', label: 'Greek - EPSG:4120' },
  { value: '4121', label: 'GGRS87 - EPSG:4121' },
  { value: '4122', label: 'ATS77 - EPSG:4122' },
  { value: '4123', label: 'KKJ - EPSG:4123' },
  { value: '4124', label: 'RT90 - EPSG:4124' },
  { value: '4125', label: 'Samboja - EPSG:4125' },
  { value: '4126', label: 'LKS94 (ETRS89) - EPSG:4126' },
  { value: '4127', label: 'Tete - EPSG:4127' },
  { value: '4128', label: 'Madzansua - EPSG:4128' },
  { value: '4129', label: 'Observatario - EPSG:4129' },
  { value: '4130', label: 'Moznet - EPSG:4130' },
  { value: '4131', label: 'Indian 1960 - EPSG:4131' },
  { value: '4132', label: 'FD58 - EPSG:4132' },
  { value: '4133', label: 'EST92 - EPSG:4133' },
  { value: '4134', label: 'PSD93 - EPSG:4134' },
  { value: '4135', label: 'Old Hawaiian - EPSG:4135' },
  { value: '4136', label: 'St. Lawrence Island - EPSG:4136' },
  { value: '4137', label: 'St. Paul Island - EPSG:4137' },
  { value: '4138', label: 'St. George Island - EPSG:4138' },
  { value: '4139', label: 'Puerto Rico - EPSG:4139' },
  { value: '4140', label: 'NAD83(CSRS98) - EPSG:4140' },
  { value: '4141', label: 'Israel 1993 - EPSG:4141' },
  { value: '4142', label: 'Locodjo 1965 - EPSG:4142' },
  { value: '4143', label: 'Abidjan 1987 - EPSG:4143' },
  { value: '4144', label: 'Kalianpur 1937 - EPSG:4144' },
  { value: '4145', label: 'Kalianpur 1962 - EPSG:4145' },
  { value: '4146', label: 'Kalianpur 1975 - EPSG:4146' },
  { value: '4147', label: 'Hanoi 1972 - EPSG:4147' },
  { value: '4148', label: 'Hartebeesthoek94 - EPSG:4148' },
  { value: '4149', label: 'CH1903 - EPSG:4149' },
  { value: '4150', label: 'CH1903+ - EPSG:4150' },
  { value: '4151', label: 'CHTRF95 - EPSG:4151' },
  { value: '4152', label: 'NAD83(HARN) - EPSG:4152' },
  { value: '4153', label: 'Rassadiran - EPSG:4153' },
  { value: '4154', label: 'ED50(ED77) - EPSG:4154' },
  { value: '4155', label: 'Dabola 1981 - EPSG:4155' },
  { value: '4156', label: 'S-JTSK - EPSG:4156' },
  { value: '4157', label: 'Mount Dillon - EPSG:4157' },
  { value: '4158', label: 'Naparima 1955 - EPSG:4158' },
  { value: '4159', label: 'ELD79 - EPSG:4159' },
  { value: '4160', label: 'Chos Malal 1914 - EPSG:4160' },
  { value: '4161', label: 'Pampa del Castillo - EPSG:4161' },
  { value: '4162', label: 'Korean 1985 - EPSG:4162' },
  { value: '4163', label: 'Yemen NGN96 - EPSG:4163' },
  { value: '4164', label: 'South Yemen - EPSG:4164' },
  { value: '4165', label: 'Bissau - EPSG:4165' },
  { value: '4166', label: 'Korean 1995 - EPSG:4166' },
  { value: '4167', label: 'NZGD2000 - EPSG:4167' },
  { value: '4168', label: 'Accra - EPSG:4168' },
  { value: '4169', label: 'American Samoa 1962 - EPSG:4169' },
  { value: '4170', label: 'SIRGAS 1995 - EPSG:4170' },
  { value: '4171', label: 'RGF93 - EPSG:4171' },
  { value: '4172', label: 'POSGAR - EPSG:4172' },
  { value: '4173', label: 'IRENET95 - EPSG:4173' },
  { value: '4174', label: 'Sierra Leone 1924 - EPSG:4174' },
  { value: '4175', label: 'Sierra Leone 1968 - EPSG:4175' },
  { value: '4176', label: 'Australian Antarctic - EPSG:4176' },
  { value: '4178', label: 'Pulkovo 1942(83) - EPSG:4178' },
  { value: '4179', label: 'Pulkovo 1942(58) - EPSG:4179' },
  { value: '4180', label: 'EST97 - EPSG:4180' },
  { value: '4181', label: 'Luxembourg 1930 - EPSG:4181' },
  { value: '4182', label: 'Azores Occidental 1939 - EPSG:4182' },
  { value: '4183', label: 'Azores Central 1948 - EPSG:4183' },
  { value: '4184', label: 'Azores Oriental 1940 - EPSG:4184' },
  { value: '4185', label: 'Madeira 1936 - EPSG:4185' },
  { value: '4188', label: 'OSNI 1952 - EPSG:4188' },
  { value: '4189', label: 'REGVEN - EPSG:4189' },
  { value: '4190', label: 'POSGAR 98 - EPSG:4190' },
  { value: '4191', label: 'Albanian 1987 - EPSG:4191' },
  { value: '4192', label: 'Douala 1948 - EPSG:4192' },
  { value: '4193', label: 'Manoca 1962 - EPSG:4193' },
  { value: '4194', label: 'Qornoq 1927 - EPSG:4194' },
  { value: '4195', label: 'Scoresbysund 1952 - EPSG:4195' },
  { value: '4196', label: 'Ammassalik 1958 - EPSG:4196' },
  { value: '4197', label: 'Garoua - EPSG:4197' },
  { value: '4198', label: 'Kousseri - EPSG:4198' },
  { value: '4199', label: 'Egypt 1930 - EPSG:4199' },
  { value: '4200', label: 'Pulkovo 1995 - EPSG:4200' },
  { value: '4201', label: 'Adindan - EPSG:4201' },
  { value: '4202', label: 'AGD66 - EPSG:4202' },
  { value: '4203', label: 'AGD84 - EPSG:4203' },
  { value: '4204', label: 'Ain el Abd - EPSG:4204' },
  { value: '4205', label: 'Afgooye - EPSG:4205' },
  { value: '4206', label: 'Agadez - EPSG:4206' },
  { value: '4207', label: 'Lisbon - EPSG:4207' },
  { value: '4208', label: 'Aratu - EPSG:4208' },
  { value: '4209', label: 'Arc 1950 - EPSG:4209' },
  { value: '4210', label: 'Arc 1960 - EPSG:4210' },
  { value: '4211', label: 'Batavia - EPSG:4211' },
  { value: '4212', label: 'Barbados 1938 - EPSG:4212' },
  { value: '4213', label: 'Beduaram - EPSG:4213' },
  { value: '4214', label: 'Beijing 1954 - EPSG:4214' },
  { value: '4215', label: 'Belge 1950 - EPSG:4215' },
  { value: '4216', label: 'Bermuda 1957 - EPSG:4216' },
  { value: '4218', label: 'Bogota 1975 - EPSG:4218' },
  { value: '4219', label: 'Bukit Rimpah - EPSG:4219' },
  { value: '4220', label: 'Camacupa - EPSG:4220' },
  { value: '4221', label: 'Campo Inchauspe - EPSG:4221' },
  { value: '4222', label: 'Cape - EPSG:4222' },
  { value: '4223', label: 'Carthage - EPSG:4223' },
  { value: '4224', label: 'Chua - EPSG:4224' },
  { value: '4225', label: 'Corrego Alegre 1970-72 - EPSG:4225' },
  { value: '4226', label: "Cote d'Ivoire - EPSG:4226" },
  { value: '4227', label: 'Deir ez Zor - EPSG:4227' },
  { value: '4228', label: 'Douala - EPSG:4228' },
  { value: '4229', label: 'Egypt 1907 - EPSG:4229' },
  { value: '4230', label: 'ED50 - EPSG:4230' },
  { value: '4231', label: 'ED87 - EPSG:4231' },
  { value: '4232', label: 'Fahud - EPSG:4232' },
  { value: '4233', label: 'Gandajika 1970 - EPSG:4233' },
  { value: '4234', label: 'Garoua - EPSG:4234' },
  { value: '4235', label: 'Guyane Francaise - EPSG:4235' },
  { value: '4236', label: 'Hu Tzu Shan 1950 - EPSG:4236' },
  { value: '4237', label: 'HD72 - EPSG:4237' },
  { value: '4238', label: 'ID74 - EPSG:4238' },
  { value: '4239', label: 'Indian 1954 - EPSG:4239' },
  { value: '4240', label: 'Indian 1975 - EPSG:4240' },
  { value: '4241', label: 'Jamaica 1875 - EPSG:4241' },
  { value: '4242', label: 'JAD69 - EPSG:4242' },
  { value: '4243', label: 'Kalianpur 1880 - EPSG:4243' },
  { value: '4244', label: 'Kandawala - EPSG:4244' },
  { value: '4245', label: 'Kertau 1968 - EPSG:4245' },
  { value: '4246', label: 'KOC - EPSG:4246' },
  { value: '4247', label: 'La Canoa - EPSG:4247' },
  { value: '4248', label: 'PSAD56 - EPSG:4248' },
  { value: '4249', label: 'Lake - EPSG:4249' },
  { value: '4250', label: 'Leigon - EPSG:4250' },
  { value: '4251', label: 'Liberia 1964 - EPSG:4251' },
  { value: '4252', label: 'Lome - EPSG:4252' },
  { value: '4253', label: 'Luzon 1911 - EPSG:4253' },
  { value: '4254', label: 'Hito XVIII 1963 - EPSG:4254' },
  { value: '4255', label: 'Herat North - EPSG:4255' },
  { value: '4256', label: 'Mahe 1971 - EPSG:4256' },
  { value: '4257', label: 'Makassar - EPSG:4257' },
  { value: '4258', label: 'ETRS89 - EPSG:4258' },
  { value: '4259', label: 'Malongo 1987 - EPSG:4259' },
  { value: '4260', label: 'Manoca - EPSG:4260' },
  { value: '4261', label: 'Merchich - EPSG:4261' },
  { value: '4262', label: 'Massawa - EPSG:4262' },
  { value: '4263', label: 'Minna - EPSG:4263' },
  { value: '4264', label: 'Mhast - EPSG:4264' },
  { value: '4265', label: 'Monte Mario - EPSG:4265' },
  { value: '4266', label: "M'poraloko - EPSG:4266" },
  { value: '4267', label: 'NAD27 - EPSG:4267' },
  { value: '4268', label: 'NAD27 Michigan - EPSG:4268' },
  { value: '4269', label: 'NAD83 - EPSG:4269' },
  { value: '4270', label: 'Nahrwan 1967 - EPSG:4270' },
  { value: '4271', label: 'Naparima 1972 - EPSG:4271' },
  { value: '4272', label: 'NZGD49 - EPSG:4272' },
  { value: '4273', label: 'NGO 1948 - EPSG:4273' },
  { value: '4274', label: 'Datum 73 - EPSG:4274' },
  { value: '4275', label: 'NTF - EPSG:4275' },
  { value: '4276', label: 'NSWC 9Z-2 - EPSG:4276' },
  { value: '4277', label: 'OSGB 1936 - EPSG:4277' },
  { value: '4278', label: 'OSGB70 - EPSG:4278' },
  { value: '4279', label: 'OS(SN)80 - EPSG:4279' },
  { value: '4280', label: 'Padang - EPSG:4280' },
  { value: '4281', label: 'Palestine 1923 - EPSG:4281' },
  { value: '4282', label: 'Pointe Noire - EPSG:4282' },
  { value: '4283', label: 'GDA94 - EPSG:4283' },
  { value: '4284', label: 'Pulkovo 1942 - EPSG:4284' },
  { value: '4285', label: 'Qatar 1974 - EPSG:4285' },
  { value: '4286', label: 'Qatar 1948 - EPSG:4286' },
  { value: '4287', label: 'Qornoq - EPSG:4287' },
  { value: '4288', label: 'Loma Quintana - EPSG:4288' },
  { value: '4289', label: 'Amersfoort - EPSG:4289' },
  { value: '4291', label: 'SAD69 - EPSG:4291' },
  { value: '4292', label: 'Sapper Hill 1943 - EPSG:4292' },
  { value: '4293', label: 'Schwarzeck - EPSG:4293' },
  { value: '4294', label: 'Segora - EPSG:4294' },
  { value: '4295', label: 'Serindung - EPSG:4295' },
  { value: '4296', label: 'Sudan - EPSG:4296' },
  { value: '4297', label: 'Tananarive - EPSG:4297' },
  { value: '4298', label: 'Timbalai 1948 - EPSG:4298' },
  { value: '4299', label: 'TM65 - EPSG:4299' },
  { value: '4300', label: 'TM75 - EPSG:4300' },
  { value: '4301', label: 'Tokyo - EPSG:4301' },
  { value: '4302', label: 'Trinidad 1903 - EPSG:4302' },
  { value: '4303', label: 'TC(1948) - EPSG:4303' },
  { value: '4304', label: 'Voirol 1875 - EPSG:4304' },
  { value: '4306', label: 'Bern 1938 - EPSG:4306' },
  { value: '4307', label: 'Nord Sahara 1959 - EPSG:4307' },
  { value: '4308', label: 'RT38 - EPSG:4308' },
  { value: '4309', label: 'Yacare - EPSG:4309' },
  { value: '4310', label: 'Yoff - EPSG:4310' },
  { value: '4311', label: 'Zanderij - EPSG:4311' },
  { value: '4312', label: 'MGI - EPSG:4312' },
  { value: '4313', label: 'Belge 1972 - EPSG:4313' },
  { value: '4314', label: 'DHDN - EPSG:4314' },
  { value: '4315', label: 'Conakry 1905 - EPSG:4315' },
  { value: '4316', label: 'Dealul Piscului 1930 - EPSG:4316' },
  { value: '4317', label: 'Dealul Piscului 1970 - EPSG:4317' },
  { value: '4318', label: 'NGN - EPSG:4318' },
  { value: '4319', label: 'KUDAMS - EPSG:4319' },
  { value: '4322', label: 'WGS 72 - EPSG:4322' },
  { value: '4324', label: 'WGS 72BE - EPSG:4324' },
  { value: '4326', label: 'WGS 84 - EPSG:4326' },
  { value: '4463', label: 'RGSPM06 - EPSG:4463' },
  { value: '4470', label: 'RGM04 - EPSG:4470' },
  { value: '4475', label: 'Cadastre 1997 - EPSG:4475' },
  { value: '4483', label: 'Mexico ITRF92 - EPSG:4483' },
  { value: '4490', label: 'China Geodetic Coordinate System 2000 - EPSG:4490' },
  { value: '4555', label: 'New Beijing - EPSG:4555' },
  { value: '4558', label: 'RRAF 1991 - EPSG:4558' },
  { value: '4600', label: 'Anguilla 1957 - EPSG:4600' },
  { value: '4601', label: 'Antigua 1943 - EPSG:4601' },
  { value: '4602', label: 'Dominica 1945 - EPSG:4602' },
  { value: '4603', label: 'Grenada 1953 - EPSG:4603' },
  { value: '4604', label: 'Montserrat 1958 - EPSG:4604' },
  { value: '4605', label: 'St. Kitts 1955 - EPSG:4605' },
  { value: '4606', label: 'St. Lucia 1955 - EPSG:4606' },
  { value: '4607', label: 'St. Vincent 1945 - EPSG:4607' },
  { value: '4608', label: 'NAD27(76) - EPSG:4608' },
  { value: '4609', label: 'NAD27(CGQ77) - EPSG:4609' },
  { value: '4610', label: 'Xian 1980 - EPSG:4610' },
  { value: '4611', label: 'Hong Kong 1980 - EPSG:4611' },
  { value: '4612', label: 'JGD2000 - EPSG:4612' },
  { value: '4613', label: 'Segara - EPSG:4613' },
  { value: '4614', label: 'QND95 - EPSG:4614' },
  { value: '4615', label: 'Porto Santo - EPSG:4615' },
  { value: '4616', label: 'Selvagem Grande - EPSG:4616' },
  { value: '4617', label: 'NAD83(CSRS) - EPSG:4617' },
  { value: '4618', label: 'SAD69 - EPSG:4618' },
  { value: '4619', label: 'SWEREF99 - EPSG:4619' },
  { value: '4620', label: 'Point 58 - EPSG:4620' },
  { value: '4621', label: 'Fort Marigot - EPSG:4621' },
  { value: '4622', label: 'Guadeloupe 1948 - EPSG:4622' },
  { value: '4623', label: 'CSG67 - EPSG:4623' },
  { value: '4624', label: 'RGFG95 - EPSG:4624' },
  { value: '4625', label: 'Martinique 1938 - EPSG:4625' },
  { value: '4626', label: 'Reunion 1947 - EPSG:4626' },
  { value: '4627', label: 'RGR92 - EPSG:4627' },
  { value: '4628', label: 'Tahiti 52 - EPSG:4628' },
  { value: '4629', label: 'Tahaa 54 - EPSG:4629' },
  { value: '4630', label: 'IGN72 Nuku Hiva - EPSG:4630' },
  { value: '4631', label: 'K0 1949 - EPSG:4631' },
  { value: '4632', label: 'Combani 1950 - EPSG:4632' },
  { value: '4633', label: 'IGN56 Lifou - EPSG:4633' },
  { value: '4634', label: 'IGN72 Grand Terre - EPSG:4634' },
  { value: '4635', label: 'ST87 Ouvea - EPSG:4635' },
  { value: '4636', label: 'Petrels 1972 - EPSG:4636' },
  { value: '4637', label: 'Perroud 1950 - EPSG:4637' },
  { value: '4638', label: 'Saint Pierre et Miquelon 1950 - EPSG:4638' },
  { value: '4639', label: 'MOP78 - EPSG:4639' },
  { value: '4640', label: 'RRAF 1991 - EPSG:4640' },
  { value: '4641', label: 'IGN53 Mare - EPSG:4641' },
  { value: '4642', label: 'ST84 Ile des Pins - EPSG:4642' },
  { value: '4643', label: 'ST71 Belep - EPSG:4643' },
  { value: '4644', label: 'NEA74 Noumea - EPSG:4644' },
  { value: '4645', label: 'RGNC 1991 - EPSG:4645' },
  { value: '4646', label: 'Grand Comoros - EPSG:4646' },
  { value: '4657', label: 'Reykjavik 1900 - EPSG:4657' },
  { value: '4658', label: 'Hjorsey 1955 - EPSG:4658' },
  { value: '4659', label: 'ISN93 - EPSG:4659' },
  { value: '4660', label: 'Helle 1954 - EPSG:4660' },
  { value: '4661', label: 'LKS92 - EPSG:4661' },
  { value: '4662', label: 'IGN72 Grande Terre - EPSG:4662' },
  { value: '4663', label: 'Porto Santo 1995 - EPSG:4663' },
  { value: '4664', label: 'Azores Oriental 1995 - EPSG:4664' },
  { value: '4665', label: 'Azores Central 1995 - EPSG:4665' },
  { value: '4666', label: 'Lisbon 1890 - EPSG:4666' },
  { value: '4667', label: 'IKBD-92 - EPSG:4667' },
  { value: '4668', label: 'ED79 - EPSG:4668' },
  { value: '4669', label: 'LKS94 - EPSG:4669' },
  { value: '4670', label: 'IGM95 - EPSG:4670' },
  { value: '4671', label: 'Voirol 1879 - EPSG:4671' },
  { value: '4672', label: 'Chatham Islands 1971 - EPSG:4672' },
  { value: '4673', label: 'Chatham Islands 1979 - EPSG:4673' },
  { value: '4674', label: 'SIRGAS 2000 - EPSG:4674' },
  { value: '4675', label: 'Guam 1963 - EPSG:4675' },
  { value: '4676', label: 'Vientiane 1982 - EPSG:4676' },
  { value: '4677', label: 'Lao 1993 - EPSG:4677' },
  { value: '4678', label: 'Lao 1997 - EPSG:4678' },
  { value: '4679', label: 'Jouik 1961 - EPSG:4679' },
  { value: '4680', label: 'Nouakchott 1965 - EPSG:4680' },
  { value: '4681', label: 'Mauritania 1999 - EPSG:4681' },
  { value: '4682', label: 'Gulshan 303 - EPSG:4682' },
  { value: '4683', label: 'PRS92 - EPSG:4683' },
  { value: '4684', label: 'Gan 1970 - EPSG:4684' },
  { value: '4685', label: 'Gandajika - EPSG:4685' },
  { value: '4686', label: 'MAGNA-SIRGAS - EPSG:4686' },
  { value: '4687', label: 'RGPF - EPSG:4687' },
  { value: '4688', label: 'Fatu Iva 72 - EPSG:4688' },
  { value: '4689', label: 'IGN63 Hiva Oa - EPSG:4689' },
  { value: '4690', label: 'Tahiti 79 - EPSG:4690' },
  { value: '4691', label: 'Moorea 87 - EPSG:4691' },
  { value: '4692', label: 'Maupiti 83 - EPSG:4692' },
  { value: '4693', label: 'Nakhl-e Ghanem - EPSG:4693' },
  { value: '4694', label: 'POSGAR 94 - EPSG:4694' },
  { value: '4695', label: 'Katanga 1955 - EPSG:4695' },
  { value: '4696', label: 'Kasai 1953 - EPSG:4696' },
  { value: '4697', label: 'IGC 1962 6th Parallel South - EPSG:4697' },
  { value: '4698', label: 'IGN 1962 Kerguelen - EPSG:4698' },
  { value: '4699', label: 'Le Pouce 1934 - EPSG:4699' },
  { value: '4700', label: 'IGN Astro 1960 - EPSG:4700' },
  { value: '4701', label: 'IGCB 1955 - EPSG:4701' },
  { value: '4702', label: 'Mauritania 1999 - EPSG:4702' },
  { value: '4703', label: 'Mhast 1951 - EPSG:4703' },
  { value: '4704', label: 'Mhast (onshore) - EPSG:4704' },
  { value: '4705', label: 'Mhast (offshore) - EPSG:4705' },
  { value: '4706', label: 'Egypt Gulf of Suez S-650 TL - EPSG:4706' },
  { value: '4707', label: 'Tern Island 1961 - EPSG:4707' },
  { value: '4708', label: 'Cocos Islands 1965 - EPSG:4708' },
  { value: '4709', label: 'Iwo Jima 1945 - EPSG:4709' },
  { value: '4710', label: 'Astro DOS 71 - EPSG:4710' },
  { value: '4711', label: 'Marcus Island 1952 - EPSG:4711' },
  { value: '4712', label: 'Ascension Island 1958 - EPSG:4712' },
  { value: '4713', label: 'Ayabelle Lighthouse - EPSG:4713' },
  { value: '4714', label: 'Bellevue - EPSG:4714' },
  { value: '4715', label: 'Camp Area Astro - EPSG:4715' },
  { value: '4716', label: 'Phoenix Islands 1966 - EPSG:4716' },
  { value: '4717', label: 'Cape Canaveral - EPSG:4717' },
  { value: '4718', label: 'Solomon 1968 - EPSG:4718' },
  { value: '4719', label: 'Easter Island 1967 - EPSG:4719' },
  { value: '4720', label: 'Fiji 1986 - EPSG:4720' },
  { value: '4721', label: 'Fiji 1956 - EPSG:4721' },
  { value: '4722', label: 'South Georgia 1968 - EPSG:4722' },
  { value: '4723', label: 'GCGD59 - EPSG:4723' },
  { value: '4724', label: 'Diego Garcia 1969 - EPSG:4724' },
  { value: '4725', label: 'Johnston Island 1961 - EPSG:4725' },
  { value: '4726', label: 'SIGD61 - EPSG:4726' },
  { value: '4727', label: 'Midway 1961 - EPSG:4727' },
  { value: '4728', label: 'Pico de las Nieves 1984 - EPSG:4728' },
  { value: '4729', label: 'Pitcairn 1967 - EPSG:4729' },
  { value: '4730', label: 'Santo 1965 - EPSG:4730' },
  { value: '4731', label: 'Viti Levu 1916 - EPSG:4731' },
  { value: '4732', label: 'Marshall Islands 1960 - EPSG:4732' },
  { value: '4733', label: 'Wake Island 1952 - EPSG:4733' },
  { value: '4734', label: 'Tristan 1968 - EPSG:4734' },
  { value: '4735', label: 'Kusaie 1951 - EPSG:4735' },
  { value: '4736', label: 'Deception Island - EPSG:4736' },
  { value: '4737', label: 'Korea 2000 - EPSG:4737' },
  { value: '4738', label: 'Hong Kong 1963 - EPSG:4738' },
  { value: '4739', label: 'Hong Kong 1963(67) - EPSG:4739' },
  { value: '4740', label: 'PZ-90 - EPSG:4740' },
  { value: '4741', label: 'FD54 - EPSG:4741' },
  { value: '4742', label: 'GDM2000 - EPSG:4742' },
  { value: '4743', label: 'Karbala 1979 - EPSG:4743' },
  { value: '4744', label: 'Nahrwan 1934 - EPSG:4744' },
  { value: '4745', label: 'RD/83 - EPSG:4745' },
  { value: '4746', label: 'PD/83 - EPSG:4746' },
  { value: '4747', label: 'GR96 - EPSG:4747' },
  { value: '4748', label: 'Vanua Levu 1915 - EPSG:4748' },
  { value: '4749', label: 'RGNC91-93 - EPSG:4749' },
  { value: '4750', label: 'ST87 Ouvea - EPSG:4750' },
  { value: '4751', label: 'Kertau (RSO) - EPSG:4751' },
  { value: '4752', label: 'Viti Levu 1912 - EPSG:4752' },
  { value: '4753', label: 'fk89 - EPSG:4753' },
  { value: '4754', label: 'LGD2006 - EPSG:4754' },
  { value: '4755', label: 'DGN95 - EPSG:4755' },
  { value: '4756', label: 'VN-2000 - EPSG:4756' },
  { value: '4757', label: 'SVY21 - EPSG:4757' },
  { value: '4758', label: 'JAD2001 - EPSG:4758' },
  { value: '4759', label: 'NAD83(NSRS2007) - EPSG:4759' },
  { value: '4760', label: 'WGS 66 - EPSG:4760' },
  { value: '4761', label: 'HTRS96 - EPSG:4761' },
  { value: '4762', label: 'BDA2000 - EPSG:4762' },
  { value: '4763', label: 'Pitcairn 2006 - EPSG:4763' },
  { value: '4764', label: 'RSRGD2000 - EPSG:4764' },
  { value: '4765', label: 'Slovenia 1996 - EPSG:4765' },
  { value: '4801', label: 'Bern 1898 (Bern) - EPSG:4801' },
  { value: '4802', label: 'Bogota 1975 (Bogota) - EPSG:4802' },
  { value: '4803', label: 'Lisbon (Lisbon) - EPSG:4803' },
  { value: '4804', label: 'Makassar (Jakarta) - EPSG:4804' },
  { value: '4805', label: 'MGI (Ferro) - EPSG:4805' },
  { value: '4806', label: 'Monte Mario (Rome) - EPSG:4806' },
  { value: '4807', label: 'NTF (Paris) - EPSG:4807' },
  { value: '4808', label: 'Padang (Jakarta) - EPSG:4808' },
  { value: '4809', label: 'Belge 1950 (Brussels) - EPSG:4809' },
  { value: '4810', label: 'Tananarive (Paris) - EPSG:4810' },
  { value: '4811', label: 'Voirol 1875 (Paris) - EPSG:4811' },
  { value: '4813', label: 'Batavia (Jakarta) - EPSG:4813' },
  { value: '4814', label: 'RT38 (Stockholm) - EPSG:4814' },
  { value: '4815', label: 'Greek (Athens) - EPSG:4815' },
  { value: '4816', label: 'Carthage (Paris) - EPSG:4816' },
  { value: '4817', label: 'NGO 1948 (Oslo) - EPSG:4817' },
  { value: '4818', label: 'S-JTSK (Ferro) - EPSG:4818' },
  { value: '4819', label: 'Nord Sahara 1959 (Paris) - EPSG:4819' },
  { value: '4820', label: 'Segara (Jakarta) - EPSG:4820' },
  { value: '4821', label: 'Voirol 1879 (Paris) - EPSG:4821' },
  { value: '4823', label: 'Sao Tome - EPSG:4823' },
  { value: '4824', label: 'Principe - EPSG:4824' },
  { value: '4901', label: 'ATF (Paris) - EPSG:4901' },
  { value: '4902', label: 'NDG (Paris) - EPSG:4902' },
  { value: '4903', label: 'Madrid 1870 (Madrid) - EPSG:4903' },
  { value: '4904', label: 'Lisbon 1890 (Lisbon) - EPSG:4904' },
  { value: '5013', label: 'PTRA08 - EPSG:5013' },
  { value: '5132', label: 'Tokyo 1892 - EPSG:5132' },
  { value: '5228', label: 'S-JTSK/05 - EPSG:5228' },
  { value: '5229', label: 'S-JTSK/05 (Ferro) - EPSG:5229' },
  { value: '5233', label: 'SLD99 - EPSG:5233' },
  { value: '5246', label: 'GDBD2009 - EPSG:5246' },
  { value: '5252', label: 'TUREF - EPSG:5252' },
  { value: '5264', label: 'DRUKREF 03 - EPSG:5264' },
  { value: '5324', label: 'ISN2004 - EPSG:5324' },
  { value: '5340', label: 'POSGAR 2007 - EPSG:5340' },
  { value: '5354', label: 'MARGEN - EPSG:5354' },
  { value: '5360', label: 'SIRGAS-Chile - EPSG:5360' },
  { value: '5365', label: 'CR05 - EPSG:5365' },
  { value: '5371', label: 'MACARIO SOLIS - EPSG:5371' },
  { value: '5373', label: 'Peru96 - EPSG:5373' },
  { value: '5381', label: 'SIRGAS-ROU98 - EPSG:5381' },
  { value: '5393', label: 'SIRGAS_ES2007.8 - EPSG:5393' },
  { value: '5451', label: 'Ocotepeque 1935 - EPSG:5451' },
  { value: '5464', label: 'Sibun Gorge 1922 - EPSG:5464' },
  { value: '5467', label: 'Panama-Colon 1911 - EPSG:5467' },
  { value: '5489', label: 'RGAF09 - EPSG:5489' },
  { value: '5524', label: 'Corrego Alegre 1961 - EPSG:5524' },
  { value: '5527', label: 'SAD69(96) - EPSG:5527' },
  { value: '5546', label: 'PNG94 - EPSG:5546' },
  { value: '5561', label: 'UCS-2000 - EPSG:5561' },
  { value: '5593', label: 'FEH2010 - EPSG:5593' },
  { value: '5681', label: 'DB_REF - EPSG:5681' },
  { value: '5886', label: 'TGD2005 - EPSG:5886' },
  { value: '6135', label: 'CIGD11 - EPSG:6135' },
  { value: '6207', label: 'Nepal 1981 - EPSG:6207' },
  { value: '6311', label: 'CGRS93 - EPSG:6311' },
  { value: '6318', label: 'NAD83(2011) - EPSG:6318' },
  { value: '6322', label: 'NAD83(PA11) - EPSG:6322' },
  { value: '6325', label: 'NAD83(MA11) - EPSG:6325' },
  { value: '6365', label: 'Mexico ITRF2008 - EPSG:6365' },
  { value: '6668', label: 'JGD2011 - EPSG:6668' },
  { value: '6706', label: 'RDN2008 - EPSG:6706' },
  { value: '6783', label: 'NAD83(CORS96) - EPSG:6783' },
  { value: '6881', label: 'Aden 1925 - EPSG:6881' },
  { value: '6882', label: 'Bekaa Valley 1920 - EPSG:6882' },
  { value: '6883', label: 'Bioko - EPSG:6883' },
  { value: '6892', label: 'South East Island 1943 - EPSG:6892' },
  { value: '6894', label: 'Gambia - EPSG:6894' },
  { value: '6980', label: 'IGD05 - EPSG:6980' },
  { value: '6983', label: 'IG05 Intermediate CRS - EPSG:6983' },
  { value: '6987', label: 'IGD05/12 - EPSG:6987' },
  { value: '6990', label: 'IG05/12 Intermediate CRS - EPSG:6990' },
  { value: '7035', label: 'RGSPM06 (lon-lat) - EPSG:7035' },
  { value: '7037', label: 'RGR92 (lon-lat) - EPSG:7037' },
  { value: '7039', label: 'RGM04 (lon-lat) - EPSG:7039' },
  { value: '7041', label: 'RGFG95 (lon-lat) - EPSG:7041' },
  { value: '7073', label: 'RGTAAF07 - EPSG:7073' },
  { value: '7084', label: 'RGF93 (lon-lat) - EPSG:7084' },
  { value: '7086', label: 'RGAF09 (lon-lat) - EPSG:7086' },
  { value: '7088', label: 'RGTAAF07 (lon-lat) - EPSG:7088' },
  { value: '7133', label: 'RGTAAF07 (lon-lat) - EPSG:7133' },
  { value: '7136', label: 'IGD05 - EPSG:7136' },
  { value: '7139', label: 'IGD05/12 - EPSG:7139' },
  { value: '7373', label: 'ONGD14 - EPSG:7373' },
  { value: '7683', label: 'GSK-2011 - EPSG:7683' },
  { value: '7686', label: 'Kyrg-06 - EPSG:7686' },
  { value: '7798', label: 'BGS2005 - EPSG:7798' },
  { value: '7844', label: 'GDA2020 - EPSG:7844' },
  { value: '7881', label: 'St. Helena Tritan - EPSG:7881' },
  { value: '7886', label: 'SHGD2015 - EPSG:7886' },
  { value: '8042', label: 'Gusterberg (Ferro) - EPSG:8042' },
  { value: '8043', label: 'St. Stephen (Ferro) - EPSG:8043' },
  { value: '8086', label: 'ISN2016 - EPSG:8086' },
  { value: '8232', label: 'NAD83(CSRS96) - EPSG:8232' },
  { value: '8237', label: 'NAD83(CSRS)v2 - EPSG:8237' },
  { value: '8240', label: 'NAD83(CSRS)v3 - EPSG:8240' },
  { value: '8246', label: 'NAD83(CSRS)v4 - EPSG:8246' },
  { value: '8249', label: 'NAD83(CSRS)v5 - EPSG:8249' },
  { value: '8252', label: 'NAD83(CSRS)v6 - EPSG:8252' },
  { value: '8255', label: 'NAD83(CSRS)v7 - EPSG:8255' },
  {
    value: '2000',
    label: 'Anguilla 1957 / British West Indies Grid - EPSG:2000',
  },
  {
    value: '2001',
    label: 'Antigua 1943 / British West Indies Grid - EPSG:2001',
  },
  {
    value: '2002',
    label: 'Dominica 1945 / British West Indies Grid - EPSG:2002',
  },
  {
    value: '2003',
    label: 'Grenada 1953 / British West Indies Grid - EPSG:2003',
  },
  {
    value: '2004',
    label: 'Montserrat 1958 / British West Indies Grid - EPSG:2004',
  },
  {
    value: '2005',
    label: 'St. Kitts 1955 / British West Indies Grid - EPSG:2005',
  },
  {
    value: '2006',
    label: 'St. Lucia 1955 / British West Indies Grid - EPSG:2006',
  },
  {
    value: '2007',
    label: 'St. Vincent 45 / British West Indies Grid - EPSG:2007',
  },
  { value: '2009', label: 'NAD27(CGQ77) / SCoPQ zone 3 - EPSG:2009' },
  { value: '2010', label: 'NAD27(CGQ77) / SCoPQ zone 4 - EPSG:2010' },
  { value: '2011', label: 'NAD27(CGQ77) / SCoPQ zone 5 - EPSG:2011' },
  { value: '2012', label: 'NAD27(CGQ77) / SCoPQ zone 6 - EPSG:2012' },
  { value: '2013', label: 'NAD27(CGQ77) / SCoPQ zone 7 - EPSG:2013' },
  { value: '2014', label: 'NAD27(CGQ77) / SCoPQ zone 8 - EPSG:2014' },
  { value: '2015', label: 'NAD27(CGQ77) / SCoPQ zone 9 - EPSG:2015' },
  { value: '2016', label: 'NAD27(CGQ77) / SCoPQ zone 10 - EPSG:2016' },
  { value: '2017', label: 'NAD27(76) / MTM zone 8 - EPSG:2017' },
  { value: '2018', label: 'NAD27(76) / MTM zone 9 - EPSG:2018' },
  { value: '2019', label: 'NAD27(76) / MTM zone 10 - EPSG:2019' },
  { value: '2020', label: 'NAD27(76) / MTM zone 11 - EPSG:2020' },
  { value: '2021', label: 'NAD27(76) / MTM zone 12 - EPSG:2021' },
  { value: '2022', label: 'NAD27(76) / MTM zone 13 - EPSG:2022' },
  { value: '2023', label: 'NAD27(76) / MTM zone 14 - EPSG:2023' },
  { value: '2024', label: 'NAD27(76) / MTM zone 15 - EPSG:2024' },
  { value: '2025', label: 'NAD27(76) / MTM zone 16 - EPSG:2025' },
  { value: '2026', label: 'NAD27(76) / MTM zone 17 - EPSG:2026' },
  { value: '2027', label: 'NAD27(76) / UTM zone 15N - EPSG:2027' },
  { value: '2028', label: 'NAD27(76) / UTM zone 16N - EPSG:2028' },
  { value: '2029', label: 'NAD27(76) / UTM zone 17N - EPSG:2029' },
  { value: '2030', label: 'NAD27(76) / UTM zone 18N - EPSG:2030' },
  { value: '2031', label: 'NAD27(CGQ77) / UTM zone 17N - EPSG:2031' },
  { value: '2032', label: 'NAD27(CGQ77) / UTM zone 18N - EPSG:2032' },
  { value: '2033', label: 'NAD27(CGQ77) / UTM zone 19N - EPSG:2033' },
  { value: '2034', label: 'NAD27(CGQ77) / UTM zone 20N - EPSG:2034' },
  { value: '2035', label: 'NAD27(CGQ77) / UTM zone 21N - EPSG:2035' },
  { value: '2039', label: 'Israel 1993 / Israeli TM Grid - EPSG:2039' },
  { value: '2040', label: 'Locodjo 1965 / UTM zone 30N - EPSG:2040' },
  { value: '2041', label: 'Abidjan 1987 / UTM zone 30N - EPSG:2041' },
  { value: '2042', label: 'Locodjo 1965 / UTM zone 29N - EPSG:2042' },
  { value: '2043', label: 'Abidjan 1987 / UTM zone 29N - EPSG:2043' },
  { value: '2044', label: 'Hanoi 1972 / Gauss-Kruger zone 18 - EPSG:2044' },
  { value: '2045', label: 'Hanoi 1972 / Gauss-Kruger zone 19 - EPSG:2045' },
  { value: '2046', label: 'Hartebeesthoek94 / Lo15 - EPSG:2046' },
  { value: '2047', label: 'Hartebeesthoek94 / Lo17 - EPSG:2047' },
  { value: '2048', label: 'Hartebeesthoek94 / Lo19 - EPSG:2048' },
  { value: '2049', label: 'Hartebeesthoek94 / Lo21 - EPSG:2049' },
  { value: '2050', label: 'Hartebeesthoek94 / Lo23 - EPSG:2050' },
  { value: '2051', label: 'Hartebeesthoek94 / Lo25 - EPSG:2051' },
  { value: '2052', label: 'Hartebeesthoek94 / Lo27 - EPSG:2052' },
  { value: '2053', label: 'Hartebeesthoek94 / Lo29 - EPSG:2053' },
  { value: '2054', label: 'Hartebeesthoek94 / Lo31 - EPSG:2054' },
  { value: '2055', label: 'Hartebeesthoek94 / Lo33 - EPSG:2055' },
  { value: '2056', label: 'CH1903+ / LV95 - EPSG:2056' },
  { value: '2057', label: 'Rassadiran / Nakhl e Taqi - EPSG:2057' },
  { value: '2058', label: 'ED50(ED77) / UTM zone 38N - EPSG:2058' },
  { value: '2059', label: 'ED50(ED77) / UTM zone 39N - EPSG:2059' },
  { value: '2060', label: 'ED50(ED77) / UTM zone 40N - EPSG:2060' },
  { value: '2061', label: 'ED50(ED77) / UTM zone 41N - EPSG:2061' },
  { value: '2062', label: 'Madrid 1870 (Madrid) / Spain - EPSG:2062' },
  { value: '2065', label: 'S-JTSK (Ferro) / Krovak - EPSG:2065' },
  { value: '2066', label: 'Mount Dillon / Tobago Grid - EPSG:2066' },
  { value: '2067', label: 'Naparima 1955 / UTM zone 20N - EPSG:2067' },
  { value: '2068', label: 'ELD79 / Libya zone 5 - EPSG:2068' },
  { value: '2069', label: 'ELD79 / Libya zone 6 - EPSG:2069' },
  { value: '2070', label: 'ELD79 / Libya zone 7 - EPSG:2070' },
  { value: '2071', label: 'ELD79 / Libya zone 8 - EPSG:2071' },
  { value: '2072', label: 'ELD79 / Libya zone 9 - EPSG:2072' },
  { value: '2073', label: 'ELD79 / Libya zone 10 - EPSG:2073' },
  { value: '2074', label: 'ELD79 / Libya zone 11 - EPSG:2074' },
  { value: '2075', label: 'ELD79 / Libya zone 12 - EPSG:2075' },
  { value: '2076', label: 'ELD79 / Libya zone 13 - EPSG:2076' },
  { value: '2077', label: 'ELD79 / UTM zone 32N - EPSG:2077' },
  { value: '2078', label: 'ELD79 / UTM zone 33N - EPSG:2078' },
  { value: '2079', label: 'ELD79 / UTM zone 34N - EPSG:2079' },
  { value: '2080', label: 'ELD79 / UTM zone 35N - EPSG:2080' },
  { value: '2081', label: 'Chos Malal 1914 / Argentina 2 - EPSG:2081' },
  { value: '2082', label: 'Pampa del Castillo / Argentina 2 - EPSG:2082' },
  { value: '2083', label: 'Hito XVIII 1963 / Argentina 2 - EPSG:2083' },
  { value: '2084', label: 'Hito XVIII 1963 / UTM zone 19S - EPSG:2084' },
  { value: '2087', label: 'ELD79 / TM 12 NE - EPSG:2087' },
  { value: '2088', label: 'Carthage / TM 11 NE - EPSG:2088' },
  { value: '2089', label: 'Yemen NGN96 / UTM zone 38N - EPSG:2089' },
  { value: '2090', label: 'Yemen NGN96 / UTM zone 39N - EPSG:2090' },
  { value: '2093', label: 'Hanoi 1972 / GK 106 NE - EPSG:2093' },
  { value: '2094', label: 'WGS 72BE / TM 106 NE - EPSG:2094' },
  { value: '2095', label: 'Bissau / UTM zone 28N - EPSG:2095' },
  { value: '2096', label: 'Korean 1985 / East Belt - EPSG:2096' },
  { value: '2097', label: 'Korean 1985 / Central Belt - EPSG:2097' },
  { value: '2098', label: 'Korean 1985 / West Belt - EPSG:2098' },
  { value: '2099', label: 'Qatar 1948 / Qatar Grid - EPSG:2099' },
  { value: '2100', label: 'GGRS87 / Greek Grid - EPSG:2100' },
  { value: '2101', label: 'Lake / Maracaibo Grid M1 - EPSG:2101' },
  { value: '2102', label: 'Lake / Maracaibo Grid - EPSG:2102' },
  { value: '2103', label: 'Lake / Maracaibo Grid M3 - EPSG:2103' },
  { value: '2104', label: 'Lake / Maracaibo La Rosa Grid - EPSG:2104' },
  { value: '2105', label: 'NZGD2000 / Mount Eden 2000 - EPSG:2105' },
  { value: '2106', label: 'NZGD2000 / Bay of Plenty 2000 - EPSG:2106' },
  { value: '2107', label: 'NZGD2000 / Poverty Bay 2000 - EPSG:2107' },
  { value: '2108', label: 'NZGD2000 / Hawkes Bay 2000 - EPSG:2108' },
  { value: '2109', label: 'NZGD2000 / Taranaki 2000 - EPSG:2109' },
  { value: '2110', label: 'NZGD2000 / Tuhirangi 2000 - EPSG:2110' },
  { value: '2111', label: 'NZGD2000 / Wanganui 2000 - EPSG:2111' },
  { value: '2112', label: 'NZGD2000 / Wairarapa 2000 - EPSG:2112' },
  { value: '2113', label: 'NZGD2000 / Wellington 2000 - EPSG:2113' },
  { value: '2114', label: 'NZGD2000 / Collingwood 2000 - EPSG:2114' },
  { value: '2115', label: 'NZGD2000 / Nelson 2000 - EPSG:2115' },
  { value: '2116', label: 'NZGD2000 / Karamea 2000 - EPSG:2116' },
  { value: '2117', label: 'NZGD2000 / Buller 2000 - EPSG:2117' },
  { value: '2118', label: 'NZGD2000 / Grey 2000 - EPSG:2118' },
  { value: '2119', label: 'NZGD2000 / Amuri 2000 - EPSG:2119' },
  { value: '2120', label: 'NZGD2000 / Marlborough 2000 - EPSG:2120' },
  { value: '2121', label: 'NZGD2000 / Hokitika 2000 - EPSG:2121' },
  { value: '2122', label: 'NZGD2000 / Okarito 2000 - EPSG:2122' },
  { value: '2123', label: 'NZGD2000 / Jacksons Bay 2000 - EPSG:2123' },
  { value: '2124', label: 'NZGD2000 / Mount Pleasant 2000 - EPSG:2124' },
  { value: '2125', label: 'NZGD2000 / Gawler 2000 - EPSG:2125' },
  { value: '2126', label: 'NZGD2000 / Timaru 2000 - EPSG:2126' },
  { value: '2127', label: 'NZGD2000 / Lindis Peak 2000 - EPSG:2127' },
  { value: '2128', label: 'NZGD2000 / Mount Nicholas 2000 - EPSG:2128' },
  { value: '2129', label: 'NZGD2000 / Mount York 2000 - EPSG:2129' },
  { value: '2130', label: 'NZGD2000 / Observation Point 2000 - EPSG:2130' },
  { value: '2131', label: 'NZGD2000 / North Taieri 2000 - EPSG:2131' },
  { value: '2132', label: 'NZGD2000 / Bluff 2000 - EPSG:2132' },
  { value: '2133', label: 'NZGD2000 / UTM zone 58S - EPSG:2133' },
  { value: '2134', label: 'NZGD2000 / UTM zone 59S - EPSG:2134' },
  { value: '2135', label: 'NZGD2000 / UTM zone 60S - EPSG:2135' },
  { value: '2136', label: 'Accra / Ghana National Grid - EPSG:2136' },
  { value: '2137', label: 'Accra / TM 1 NW - EPSG:2137' },
  { value: '2138', label: 'NAD27(CGQ77) / Quebec Lambert - EPSG:2138' },
  { value: '2154', label: 'RGF93 / Lambert-93 - EPSG:2154' },
  { value: '2157', label: 'IRENET95 / Irish Transverse Mercator - EPSG:2157' },
  { value: '2158', label: 'IRENET95 / UTM zone 29N - EPSG:2158' },
  { value: '2159', label: 'Sierra Leone 1924 / New Colony Grid - EPSG:2159' },
  {
    value: '2160',
    label: 'Sierra Leone 1924 / New War Office Grid - EPSG:2160',
  },
  { value: '2161', label: 'Sierra Leone 1968 / UTM zone 28N - EPSG:2161' },
  { value: '2162', label: 'Sierra Leone 1968 / UTM zone 29N - EPSG:2162' },
  { value: '2163', label: 'US National Atlas Equal Area - EPSG:2163' },
  { value: '2164', label: 'Locodjo 1965 / TM 5 NW - EPSG:2164' },
  { value: '2165', label: 'Abidjan 1987 / TM 5 NW - EPSG:2165' },
  { value: '2169', label: 'Luxembourg 1930 / Gauss - EPSG:2169' },
  { value: '2172', label: 'Pulkovo 1942(58) / Poland zone II - EPSG:2172' },
  { value: '2173', label: 'Pulkovo 1942(58) / Poland zone III - EPSG:2173' },
  { value: '2174', label: 'Pulkovo 1942(58) / Poland zone IV - EPSG:2174' },
  { value: '2175', label: 'Pulkovo 1942(58) / Poland zone V - EPSG:2175' },
  { value: '2176', label: 'ETRS89 / Poland CS2000 zone 5 - EPSG:2176' },
  { value: '2177', label: 'ETRS89 / Poland CS2000 zone 6 - EPSG:2177' },
  { value: '2178', label: 'ETRS89 / Poland CS2000 zone 7 - EPSG:2178' },
  { value: '2179', label: 'ETRS89 / Poland CS2000 zone 8 - EPSG:2179' },
  { value: '2180', label: 'ETRS89 / Poland CS92 - EPSG:2180' },
  { value: '2188', label: 'Azores Occidental 1939 / UTM zone 25N - EPSG:2188' },
  { value: '2189', label: 'Azores Central 1948 / UTM zone 26N - EPSG:2189' },
  { value: '2190', label: 'Azores Oriental 1940 / UTM zone 26N - EPSG:2190' },
  {
    value: '2193',
    label: 'NZGD2000 / New Zealand Transverse Mercator 2000 - EPSG:2193',
  },
  { value: '2195', label: 'NAD83(HARN) / UTM zone 2S - EPSG:2195' },
  { value: '2196', label: 'ETRS89 / Kp2000 Jutland - EPSG:2196' },
  { value: '2197', label: 'ETRS89 / Kp2000 Zealand - EPSG:2197' },
  { value: '2198', label: 'ETRS89 / Kp2000 Bornholm - EPSG:2198' },
  {
    value: '2200',
    label: 'ATS77 / New Brunswick Stereographic (ATS77) - EPSG:2200',
  },
  { value: '2201', label: 'REGVEN / UTM zone 18N - EPSG:2201' },
  { value: '2202', label: 'REGVEN / UTM zone 19N - EPSG:2202' },
  { value: '2203', label: 'REGVEN / UTM zone 20N - EPSG:2203' },
  { value: '2204', label: 'NAD27 / Tennessee - EPSG:2204' },
  { value: '2205', label: 'NAD83 / Kentucky North - EPSG:2205' },
  { value: '2206', label: 'ED50 / 3-degree Gauss-Kruger zone 9 - EPSG:2206' },
  { value: '2207', label: 'ED50 / 3-degree Gauss-Kruger zone 10 - EPSG:2207' },
  { value: '2208', label: 'ED50 / 3-degree Gauss-Kruger zone 11 - EPSG:2208' },
  { value: '2209', label: 'ED50 / 3-degree Gauss-Kruger zone 12 - EPSG:2209' },
  { value: '2210', label: 'ED50 / 3-degree Gauss-Kruger zone 13 - EPSG:2210' },
  { value: '2211', label: 'ED50 / 3-degree Gauss-Kruger zone 14 - EPSG:2211' },
  { value: '2212', label: 'ED50 / 3-degree Gauss-Kruger zone 15 - EPSG:2212' },
  { value: '2213', label: 'ETRS89 / TM 30 NE - EPSG:2213' },
  { value: '2215', label: 'Manoca 1962 / UTM zone 32N - EPSG:2215' },
  { value: '2216', label: 'Qornoq 1927 / UTM zone 22N - EPSG:2216' },
  { value: '2217', label: 'Qornoq 1927 / UTM zone 23N - EPSG:2217' },
  {
    value: '2218',
    label: 'Scoresbysund 1952 / Greenland zone 5 east - EPSG:2218',
  },
  { value: '2219', label: 'ATS77 / UTM zone 19N - EPSG:2219' },
  { value: '2220', label: 'ATS77 / UTM zone 20N - EPSG:2220' },
  {
    value: '2221',
    label: 'Scoresbysund 1952 / Greenland zone 6 east - EPSG:2221',
  },
  { value: '2222', label: 'NAD83 / Arizona East (ft) - EPSG:2222' },
  { value: '2223', label: 'NAD83 / Arizona Central (ft) - EPSG:2223' },
  { value: '2224', label: 'NAD83 / Arizona West (ft) - EPSG:2224' },
  { value: '2225', label: 'NAD83 / California zone 1 (ftUS) - EPSG:2225' },
  { value: '2226', label: 'NAD83 / California zone 2 (ftUS) - EPSG:2226' },
  { value: '2227', label: 'NAD83 / California zone 3 (ftUS) - EPSG:2227' },
  { value: '2228', label: 'NAD83 / California zone 4 (ftUS) - EPSG:2228' },
  { value: '2229', label: 'NAD83 / California zone 5 (ftUS) - EPSG:2229' },
  { value: '2230', label: 'NAD83 / California zone 6 (ftUS) - EPSG:2230' },
  { value: '2231', label: 'NAD83 / Colorado North (ftUS) - EPSG:2231' },
  { value: '2232', label: 'NAD83 / Colorado Central (ftUS) - EPSG:2232' },
  { value: '2233', label: 'NAD83 / Colorado South (ftUS) - EPSG:2233' },
  { value: '2234', label: 'NAD83 / Connecticut (ftUS) - EPSG:2234' },
  { value: '2235', label: 'NAD83 / Delaware (ftUS) - EPSG:2235' },
  { value: '2236', label: 'NAD83 / Florida East (ftUS) - EPSG:2236' },
  { value: '2237', label: 'NAD83 / Florida West (ftUS) - EPSG:2237' },
  { value: '2238', label: 'NAD83 / Florida North (ftUS) - EPSG:2238' },
  { value: '2239', label: 'NAD83 / Georgia East (ftUS) - EPSG:2239' },
  { value: '2240', label: 'NAD83 / Georgia West (ftUS) - EPSG:2240' },
  { value: '2241', label: 'NAD83 / Idaho East (ftUS) - EPSG:2241' },
  { value: '2242', label: 'NAD83 / Idaho Central (ftUS) - EPSG:2242' },
  { value: '2243', label: 'NAD83 / Idaho West (ftUS) - EPSG:2243' },
  { value: '2246', label: 'NAD83 / Kentucky North (ftUS) - EPSG:2246' },
  { value: '2247', label: 'NAD83 / Kentucky South (ftUS) - EPSG:2247' },
  { value: '2248', label: 'NAD83 / Maryland (ftUS) - EPSG:2248' },
  { value: '2249', label: 'NAD83 / Massachusetts Mainland (ftUS) - EPSG:2249' },
  { value: '2250', label: 'NAD83 / Massachusetts Island (ftUS) - EPSG:2250' },
  { value: '2251', label: 'NAD83 / Michigan North (ft) - EPSG:2251' },
  { value: '2252', label: 'NAD83 / Michigan Central (ft) - EPSG:2252' },
  { value: '2253', label: 'NAD83 / Michigan South (ft) - EPSG:2253' },
  { value: '2254', label: 'NAD83 / Mississippi East (ftUS) - EPSG:2254' },
  { value: '2255', label: 'NAD83 / Mississippi West (ftUS) - EPSG:2255' },
  { value: '2256', label: 'NAD83 / Montana (ft) - EPSG:2256' },
  { value: '2257', label: 'NAD83 / New Mexico East (ftUS) - EPSG:2257' },
  { value: '2258', label: 'NAD83 / New Mexico Central (ftUS) - EPSG:2258' },
  { value: '2259', label: 'NAD83 / New Mexico West (ftUS) - EPSG:2259' },
  { value: '2260', label: 'NAD83 / New York East (ftUS) - EPSG:2260' },
  { value: '2261', label: 'NAD83 / New York Central (ftUS) - EPSG:2261' },
  { value: '2262', label: 'NAD83 / New York West (ftUS) - EPSG:2262' },
  { value: '2263', label: 'NAD83 / New York Long Island (ftUS) - EPSG:2263' },
  { value: '2264', label: 'NAD83 / North Carolina (ftUS) - EPSG:2264' },
  { value: '2265', label: 'NAD83 / North Dakota North (ft) - EPSG:2265' },
  { value: '2266', label: 'NAD83 / North Dakota South (ft) - EPSG:2266' },
  { value: '2267', label: 'NAD83 / Oklahoma North (ftUS) - EPSG:2267' },
  { value: '2268', label: 'NAD83 / Oklahoma South (ftUS) - EPSG:2268' },
  { value: '2269', label: 'NAD83 / Oregon North (ft) - EPSG:2269' },
  { value: '2270', label: 'NAD83 / Oregon South (ft) - EPSG:2270' },
  { value: '2271', label: 'NAD83 / Pennsylvania North (ftUS) - EPSG:2271' },
  { value: '2272', label: 'NAD83 / Pennsylvania South (ftUS) - EPSG:2272' },
  { value: '2273', label: 'NAD83 / South Carolina (ft) - EPSG:2273' },
  { value: '2274', label: 'NAD83 / Tennessee (ftUS) - EPSG:2274' },
  { value: '2275', label: 'NAD83 / Texas North (ftUS) - EPSG:2275' },
  { value: '2276', label: 'NAD83 / Texas North Central (ftUS) - EPSG:2276' },
  { value: '2277', label: 'NAD83 / Texas Central (ftUS) - EPSG:2277' },
  { value: '2278', label: 'NAD83 / Texas South Central (ftUS) - EPSG:2278' },
  { value: '2279', label: 'NAD83 / Texas South (ftUS) - EPSG:2279' },
  { value: '2280', label: 'NAD83 / Utah North (ft) - EPSG:2280' },
  { value: '2281', label: 'NAD83 / Utah Central (ft) - EPSG:2281' },
  { value: '2282', label: 'NAD83 / Utah South (ft) - EPSG:2282' },
  { value: '2283', label: 'NAD83 / Virginia North (ftUS) - EPSG:2283' },
  { value: '2284', label: 'NAD83 / Virginia South (ftUS) - EPSG:2284' },
  { value: '2285', label: 'NAD83 / Washington North (ftUS) - EPSG:2285' },
  { value: '2286', label: 'NAD83 / Washington South (ftUS) - EPSG:2286' },
  { value: '2287', label: 'NAD83 / Wisconsin North (ftUS) - EPSG:2287' },
  { value: '2288', label: 'NAD83 / Wisconsin Central (ftUS) - EPSG:2288' },
  { value: '2289', label: 'NAD83 / Wisconsin South (ftUS) - EPSG:2289' },
  {
    value: '2290',
    label: 'ATS77 / Prince Edward Isl. Stereographic (ATS77) - EPSG:2290',
  },
  { value: '2294', label: 'ATS77 / MTM Nova Scotia zone 4 - EPSG:2294' },
  { value: '2295', label: 'ATS77 / MTM Nova Scotia zone 5 - EPSG:2295' },
  {
    value: '2296',
    label: 'Ammassalik 1958 / Greenland zone 7 east - EPSG:2296',
  },
  { value: '2299', label: 'Qornoq 1927 / Greenland zone 2 west - EPSG:2299' },
  { value: '2301', label: 'Qornoq 1927 / Greenland zone 3 west - EPSG:2301' },
  { value: '2303', label: 'Qornoq 1927 / Greenland zone 4 west - EPSG:2303' },
  { value: '2304', label: 'Qornoq 1927 / Greenland zone 5 west - EPSG:2304' },
  { value: '2305', label: 'Qornoq 1927 / Greenland zone 6 west - EPSG:2305' },
  { value: '2306', label: 'Qornoq 1927 / Greenland zone 7 west - EPSG:2306' },
  { value: '2307', label: 'Qornoq 1927 / Greenland zone 8 east - EPSG:2307' },
  { value: '2308', label: 'Batavia / TM 109 SE - EPSG:2308' },
  { value: '2309', label: 'WGS 84 / TM 116 SE - EPSG:2309' },
  { value: '2310', label: 'WGS 84 / TM 132 SE - EPSG:2310' },
  { value: '2311', label: 'WGS 84 / TM 6 NE - EPSG:2311' },
  { value: '2312', label: 'Garoua / UTM zone 33N - EPSG:2312' },
  { value: '2313', label: 'Kousseri / UTM zone 33N - EPSG:2313' },
  { value: '2314', label: 'Trinidad 1903 / Trinidad Grid (ftCla) - EPSG:2314' },
  { value: '2315', label: 'Campo Inchauspe / UTM zone 19S - EPSG:2315' },
  { value: '2316', label: 'Campo Inchauspe / UTM zone 20S - EPSG:2316' },
  { value: '2317', label: 'PSAD56 / ICN Regional - EPSG:2317' },
  { value: '2318', label: 'Ain el Abd / Aramco Lambert - EPSG:2318' },
  { value: '2319', label: 'ED50 / TM27 - EPSG:2319' },
  { value: '2320', label: 'ED50 / TM30 - EPSG:2320' },
  { value: '2321', label: 'ED50 / TM33 - EPSG:2321' },
  { value: '2322', label: 'ED50 / TM36 - EPSG:2322' },
  { value: '2323', label: 'ED50 / TM39 - EPSG:2323' },
  { value: '2324', label: 'ED50 / TM42 - EPSG:2324' },
  { value: '2325', label: 'ED50 / TM45 - EPSG:2325' },
  { value: '2326', label: 'Hong Kong 1980 Grid System - EPSG:2326' },
  { value: '2327', label: 'Xian 1980 / Gauss-Kruger zone 13 - EPSG:2327' },
  { value: '2328', label: 'Xian 1980 / Gauss-Kruger zone 14 - EPSG:2328' },
  { value: '2329', label: 'Xian 1980 / Gauss-Kruger zone 15 - EPSG:2329' },
  { value: '2330', label: 'Xian 1980 / Gauss-Kruger zone 16 - EPSG:2330' },
  { value: '2331', label: 'Xian 1980 / Gauss-Kruger zone 17 - EPSG:2331' },
  { value: '2332', label: 'Xian 1980 / Gauss-Kruger zone 18 - EPSG:2332' },
  { value: '2333', label: 'Xian 1980 / Gauss-Kruger zone 19 - EPSG:2333' },
  { value: '2334', label: 'Xian 1980 / Gauss-Kruger zone 20 - EPSG:2334' },
  { value: '2335', label: 'Xian 1980 / Gauss-Kruger zone 21 - EPSG:2335' },
  { value: '2336', label: 'Xian 1980 / Gauss-Kruger zone 22 - EPSG:2336' },
  { value: '2337', label: 'Xian 1980 / Gauss-Kruger zone 23 - EPSG:2337' },
  { value: '2338', label: 'Xian 1980 / Gauss-Kruger CM 75E - EPSG:2338' },
  { value: '2339', label: 'Xian 1980 / Gauss-Kruger CM 81E - EPSG:2339' },
  { value: '2340', label: 'Xian 1980 / Gauss-Kruger CM 87E - EPSG:2340' },
  { value: '2341', label: 'Xian 1980 / Gauss-Kruger CM 93E - EPSG:2341' },
  { value: '2342', label: 'Xian 1980 / Gauss-Kruger CM 99E - EPSG:2342' },
  { value: '2343', label: 'Xian 1980 / Gauss-Kruger CM 105E - EPSG:2343' },
  { value: '2344', label: 'Xian 1980 / Gauss-Kruger CM 111E - EPSG:2344' },
  { value: '2345', label: 'Xian 1980 / Gauss-Kruger CM 117E - EPSG:2345' },
  { value: '2346', label: 'Xian 1980 / Gauss-Kruger CM 123E - EPSG:2346' },
  { value: '2347', label: 'Xian 1980 / Gauss-Kruger CM 129E - EPSG:2347' },
  { value: '2348', label: 'Xian 1980 / Gauss-Kruger CM 135E - EPSG:2348' },
  {
    value: '2349',
    label: 'Xian 1980 / 3-degree Gauss-Kruger zone 25 - EPSG:2349',
  },
  {
    value: '2350',
    label: 'Xian 1980 / 3-degree Gauss-Kruger zone 26 - EPSG:2350',
  },
  {
    value: '2351',
    label: 'Xian 1980 / 3-degree Gauss-Kruger zone 27 - EPSG:2351',
  },
  {
    value: '2352',
    label: 'Xian 1980 / 3-degree Gauss-Kruger zone 28 - EPSG:2352',
  },
  {
    value: '2353',
    label: 'Xian 1980 / 3-degree Gauss-Kruger zone 29 - EPSG:2353',
  },
  {
    value: '2354',
    label: 'Xian 1980 / 3-degree Gauss-Kruger zone 30 - EPSG:2354',
  },
  {
    value: '2355',
    label: 'Xian 1980 / 3-degree Gauss-Kruger zone 31 - EPSG:2355',
  },
  {
    value: '2356',
    label: 'Xian 1980 / 3-degree Gauss-Kruger zone 32 - EPSG:2356',
  },
  {
    value: '2357',
    label: 'Xian 1980 / 3-degree Gauss-Kruger zone 33 - EPSG:2357',
  },
  {
    value: '2358',
    label: 'Xian 1980 / 3-degree Gauss-Kruger zone 34 - EPSG:2358',
  },
  {
    value: '2359',
    label: 'Xian 1980 / 3-degree Gauss-Kruger zone 35 - EPSG:2359',
  },
  {
    value: '2360',
    label: 'Xian 1980 / 3-degree Gauss-Kruger zone 36 - EPSG:2360',
  },
  {
    value: '2361',
    label: 'Xian 1980 / 3-degree Gauss-Kruger zone 37 - EPSG:2361',
  },
  {
    value: '2362',
    label: 'Xian 1980 / 3-degree Gauss-Kruger zone 38 - EPSG:2362',
  },
  {
    value: '2363',
    label: 'Xian 1980 / 3-degree Gauss-Kruger zone 39 - EPSG:2363',
  },
  {
    value: '2364',
    label: 'Xian 1980 / 3-degree Gauss-Kruger zone 40 - EPSG:2364',
  },
  {
    value: '2365',
    label: 'Xian 1980 / 3-degree Gauss-Kruger zone 41 - EPSG:2365',
  },
  {
    value: '2366',
    label: 'Xian 1980 / 3-degree Gauss-Kruger zone 42 - EPSG:2366',
  },
  {
    value: '2367',
    label: 'Xian 1980 / 3-degree Gauss-Kruger zone 43 - EPSG:2367',
  },
  {
    value: '2368',
    label: 'Xian 1980 / 3-degree Gauss-Kruger zone 44 - EPSG:2368',
  },
  {
    value: '2369',
    label: 'Xian 1980 / 3-degree Gauss-Kruger zone 45 - EPSG:2369',
  },
  {
    value: '2370',
    label: 'Xian 1980 / 3-degree Gauss-Kruger CM 75E - EPSG:2370',
  },
  {
    value: '2371',
    label: 'Xian 1980 / 3-degree Gauss-Kruger CM 78E - EPSG:2371',
  },
  {
    value: '2372',
    label: 'Xian 1980 / 3-degree Gauss-Kruger CM 81E - EPSG:2372',
  },
  {
    value: '2373',
    label: 'Xian 1980 / 3-degree Gauss-Kruger CM 84E - EPSG:2373',
  },
  {
    value: '2374',
    label: 'Xian 1980 / 3-degree Gauss-Kruger CM 87E - EPSG:2374',
  },
  {
    value: '2375',
    label: 'Xian 1980 / 3-degree Gauss-Kruger CM 90E - EPSG:2375',
  },
  {
    value: '2376',
    label: 'Xian 1980 / 3-degree Gauss-Kruger CM 93E - EPSG:2376',
  },
  {
    value: '2377',
    label: 'Xian 1980 / 3-degree Gauss-Kruger CM 96E - EPSG:2377',
  },
  {
    value: '2378',
    label: 'Xian 1980 / 3-degree Gauss-Kruger CM 99E - EPSG:2378',
  },
  {
    value: '2379',
    label: 'Xian 1980 / 3-degree Gauss-Kruger CM 102E - EPSG:2379',
  },
  {
    value: '2380',
    label: 'Xian 1980 / 3-degree Gauss-Kruger CM 105E - EPSG:2380',
  },
  {
    value: '2381',
    label: 'Xian 1980 / 3-degree Gauss-Kruger CM 108E - EPSG:2381',
  },
  {
    value: '2382',
    label: 'Xian 1980 / 3-degree Gauss-Kruger CM 111E - EPSG:2382',
  },
  {
    value: '2383',
    label: 'Xian 1980 / 3-degree Gauss-Kruger CM 114E - EPSG:2383',
  },
  {
    value: '2384',
    label: 'Xian 1980 / 3-degree Gauss-Kruger CM 117E - EPSG:2384',
  },
  {
    value: '2385',
    label: 'Xian 1980 / 3-degree Gauss-Kruger CM 120E - EPSG:2385',
  },
  {
    value: '2386',
    label: 'Xian 1980 / 3-degree Gauss-Kruger CM 123E - EPSG:2386',
  },
  {
    value: '2387',
    label: 'Xian 1980 / 3-degree Gauss-Kruger CM 126E - EPSG:2387',
  },
  {
    value: '2388',
    label: 'Xian 1980 / 3-degree Gauss-Kruger CM 129E - EPSG:2388',
  },
  {
    value: '2389',
    label: 'Xian 1980 / 3-degree Gauss-Kruger CM 132E - EPSG:2389',
  },
  {
    value: '2390',
    label: 'Xian 1980 / 3-degree Gauss-Kruger CM 135E - EPSG:2390',
  },
  { value: '2391', label: 'KKJ / Finland zone 1 - EPSG:2391' },
  { value: '2392', label: 'KKJ / Finland zone 2 - EPSG:2392' },
  {
    value: '2393',
    label: 'KKJ / Finland Uniform Coordinate System - EPSG:2393',
  },
  { value: '2394', label: 'KKJ / Finland zone 4 - EPSG:2394' },
  { value: '2395', label: 'South Yemen / Gauss-Kruger zone 8 - EPSG:2395' },
  { value: '2396', label: 'South Yemen / Gauss-Kruger zone 9 - EPSG:2396' },
  {
    value: '2397',
    label: 'Pulkovo 1942(83) / 3-degree Gauss-Kruger zone 3 - EPSG:2397',
  },
  {
    value: '2398',
    label: 'Pulkovo 1942(83) / 3-degree Gauss-Kruger zone 4 - EPSG:2398',
  },
  {
    value: '2399',
    label: 'Pulkovo 1942(83) / 3-degree Gauss-Kruger zone 5 - EPSG:2399',
  },
  {
    value: '2401',
    label: 'Beijing 1954 / 3-degree Gauss-Kruger zone 25 - EPSG:2401',
  },
  {
    value: '2402',
    label: 'Beijing 1954 / 3-degree Gauss-Kruger zone 26 - EPSG:2402',
  },
  {
    value: '2403',
    label: 'Beijing 1954 / 3-degree Gauss-Kruger zone 27 - EPSG:2403',
  },
  {
    value: '2404',
    label: 'Beijing 1954 / 3-degree Gauss-Kruger zone 28 - EPSG:2404',
  },
  {
    value: '2405',
    label: 'Beijing 1954 / 3-degree Gauss-Kruger zone 29 - EPSG:2405',
  },
  {
    value: '2406',
    label: 'Beijing 1954 / 3-degree Gauss-Kruger zone 30 - EPSG:2406',
  },
  {
    value: '2407',
    label: 'Beijing 1954 / 3-degree Gauss-Kruger zone 31 - EPSG:2407',
  },
  {
    value: '2408',
    label: 'Beijing 1954 / 3-degree Gauss-Kruger zone 32 - EPSG:2408',
  },
  {
    value: '2409',
    label: 'Beijing 1954 / 3-degree Gauss-Kruger zone 33 - EPSG:2409',
  },
  {
    value: '2410',
    label: 'Beijing 1954 / 3-degree Gauss-Kruger zone 34 - EPSG:2410',
  },
  {
    value: '2411',
    label: 'Beijing 1954 / 3-degree Gauss-Kruger zone 35 - EPSG:2411',
  },
  {
    value: '2412',
    label: 'Beijing 1954 / 3-degree Gauss-Kruger zone 36 - EPSG:2412',
  },
  {
    value: '2413',
    label: 'Beijing 1954 / 3-degree Gauss-Kruger zone 37 - EPSG:2413',
  },
  {
    value: '2414',
    label: 'Beijing 1954 / 3-degree Gauss-Kruger zone 38 - EPSG:2414',
  },
  {
    value: '2415',
    label: 'Beijing 1954 / 3-degree Gauss-Kruger zone 39 - EPSG:2415',
  },
  {
    value: '2416',
    label: 'Beijing 1954 / 3-degree Gauss-Kruger zone 40 - EPSG:2416',
  },
  {
    value: '2417',
    label: 'Beijing 1954 / 3-degree Gauss-Kruger zone 41 - EPSG:2417',
  },
  {
    value: '2418',
    label: 'Beijing 1954 / 3-degree Gauss-Kruger zone 42 - EPSG:2418',
  },
  {
    value: '2419',
    label: 'Beijing 1954 / 3-degree Gauss-Kruger zone 43 - EPSG:2419',
  },
  {
    value: '2420',
    label: 'Beijing 1954 / 3-degree Gauss-Kruger zone 44 - EPSG:2420',
  },
  {
    value: '2421',
    label: 'Beijing 1954 / 3-degree Gauss-Kruger zone 45 - EPSG:2421',
  },
  {
    value: '2422',
    label: 'Beijing 1954 / 3-degree Gauss-Kruger CM 75E - EPSG:2422',
  },
  {
    value: '2423',
    label: 'Beijing 1954 / 3-degree Gauss-Kruger CM 78E - EPSG:2423',
  },
  {
    value: '2424',
    label: 'Beijing 1954 / 3-degree Gauss-Kruger CM 81E - EPSG:2424',
  },
  {
    value: '2425',
    label: 'Beijing 1954 / 3-degree Gauss-Kruger CM 84E - EPSG:2425',
  },
  {
    value: '2426',
    label: 'Beijing 1954 / 3-degree Gauss-Kruger CM 87E - EPSG:2426',
  },
  {
    value: '2427',
    label: 'Beijing 1954 / 3-degree Gauss-Kruger CM 90E - EPSG:2427',
  },
  {
    value: '2428',
    label: 'Beijing 1954 / 3-degree Gauss-Kruger CM 93E - EPSG:2428',
  },
  {
    value: '2429',
    label: 'Beijing 1954 / 3-degree Gauss-Kruger CM 96E - EPSG:2429',
  },
  {
    value: '2430',
    label: 'Beijing 1954 / 3-degree Gauss-Kruger CM 99E - EPSG:2430',
  },
  {
    value: '2431',
    label: 'Beijing 1954 / 3-degree Gauss-Kruger CM 102E - EPSG:2431',
  },
  {
    value: '2432',
    label: 'Beijing 1954 / 3-degree Gauss-Kruger CM 105E - EPSG:2432',
  },
  {
    value: '2433',
    label: 'Beijing 1954 / 3-degree Gauss-Kruger CM 108E - EPSG:2433',
  },
  {
    value: '2434',
    label: 'Beijing 1954 / 3-degree Gauss-Kruger CM 111E - EPSG:2434',
  },
  {
    value: '2435',
    label: 'Beijing 1954 / 3-degree Gauss-Kruger CM 114E - EPSG:2435',
  },
  {
    value: '2436',
    label: 'Beijing 1954 / 3-degree Gauss-Kruger CM 117E - EPSG:2436',
  },
  {
    value: '2437',
    label: 'Beijing 1954 / 3-degree Gauss-Kruger CM 120E - EPSG:2437',
  },
  {
    value: '2438',
    label: 'Beijing 1954 / 3-degree Gauss-Kruger CM 123E - EPSG:2438',
  },
  {
    value: '2439',
    label: 'Beijing 1954 / 3-degree Gauss-Kruger CM 126E - EPSG:2439',
  },
  {
    value: '2440',
    label: 'Beijing 1954 / 3-degree Gauss-Kruger CM 129E - EPSG:2440',
  },
  {
    value: '2441',
    label: 'Beijing 1954 / 3-degree Gauss-Kruger CM 132E - EPSG:2441',
  },
  {
    value: '2442',
    label: 'Beijing 1954 / 3-degree Gauss-Kruger CM 135E - EPSG:2442',
  },
  {
    value: '2443',
    label: 'JGD2000 / Japan Plane Rectangular CS I - EPSG:2443',
  },
  {
    value: '2444',
    label: 'JGD2000 / Japan Plane Rectangular CS II - EPSG:2444',
  },
  {
    value: '2445',
    label: 'JGD2000 / Japan Plane Rectangular CS III - EPSG:2445',
  },
  {
    value: '2446',
    label: 'JGD2000 / Japan Plane Rectangular CS IV - EPSG:2446',
  },
  {
    value: '2447',
    label: 'JGD2000 / Japan Plane Rectangular CS V - EPSG:2447',
  },
  {
    value: '2448',
    label: 'JGD2000 / Japan Plane Rectangular CS VI - EPSG:2448',
  },
  {
    value: '2449',
    label: 'JGD2000 / Japan Plane Rectangular CS VII - EPSG:2449',
  },
  {
    value: '2450',
    label: 'JGD2000 / Japan Plane Rectangular CS VIII - EPSG:2450',
  },
  {
    value: '2451',
    label: 'JGD2000 / Japan Plane Rectangular CS IX - EPSG:2451',
  },
  {
    value: '2452',
    label: 'JGD2000 / Japan Plane Rectangular CS X - EPSG:2452',
  },
  {
    value: '2453',
    label: 'JGD2000 / Japan Plane Rectangular CS XI - EPSG:2453',
  },
  {
    value: '2454',
    label: 'JGD2000 / Japan Plane Rectangular CS XII - EPSG:2454',
  },
  {
    value: '2455',
    label: 'JGD2000 / Japan Plane Rectangular CS XIII - EPSG:2455',
  },
  {
    value: '2456',
    label: 'JGD2000 / Japan Plane Rectangular CS XIV - EPSG:2456',
  },
  {
    value: '2457',
    label: 'JGD2000 / Japan Plane Rectangular CS XV - EPSG:2457',
  },
  {
    value: '2458',
    label: 'JGD2000 / Japan Plane Rectangular CS XVI - EPSG:2458',
  },
  {
    value: '2459',
    label: 'JGD2000 / Japan Plane Rectangular CS XVII - EPSG:2459',
  },
  {
    value: '2460',
    label: 'JGD2000 / Japan Plane Rectangular CS XVIII - EPSG:2460',
  },
  {
    value: '2461',
    label: 'JGD2000 / Japan Plane Rectangular CS XIX - EPSG:2461',
  },
  { value: '2462', label: 'Albanian 1987 / Gauss-Kruger zone 4 - EPSG:2462' },
  { value: '2463', label: 'Pulkovo 1995 / Gauss-Kruger CM 21E - EPSG:2463' },
  { value: '2464', label: 'Pulkovo 1995 / Gauss-Kruger CM 27E - EPSG:2464' },
  { value: '2465', label: 'Pulkovo 1995 / Gauss-Kruger CM 33E - EPSG:2465' },
  { value: '2466', label: 'Pulkovo 1995 / Gauss-Kruger CM 39E - EPSG:2466' },
  { value: '2467', label: 'Pulkovo 1995 / Gauss-Kruger CM 45E - EPSG:2467' },
  { value: '2468', label: 'Pulkovo 1995 / Gauss-Kruger CM 51E - EPSG:2468' },
  { value: '2469', label: 'Pulkovo 1995 / Gauss-Kruger CM 57E - EPSG:2469' },
  { value: '2470', label: 'Pulkovo 1995 / Gauss-Kruger CM 63E - EPSG:2470' },
  { value: '2471', label: 'Pulkovo 1995 / Gauss-Kruger CM 69E - EPSG:2471' },
  { value: '2472', label: 'Pulkovo 1995 / Gauss-Kruger CM 75E - EPSG:2472' },
  { value: '2473', label: 'Pulkovo 1995 / Gauss-Kruger CM 81E - EPSG:2473' },
  { value: '2474', label: 'Pulkovo 1995 / Gauss-Kruger CM 87E - EPSG:2474' },
  { value: '2475', label: 'Pulkovo 1995 / Gauss-Kruger CM 93E - EPSG:2475' },
  { value: '2476', label: 'Pulkovo 1995 / Gauss-Kruger CM 99E - EPSG:2476' },
  { value: '2477', label: 'Pulkovo 1995 / Gauss-Kruger CM 105E - EPSG:2477' },
  { value: '2478', label: 'Pulkovo 1995 / Gauss-Kruger CM 111E - EPSG:2478' },
  { value: '2479', label: 'Pulkovo 1995 / Gauss-Kruger CM 117E - EPSG:2479' },
  { value: '2480', label: 'Pulkovo 1995 / Gauss-Kruger CM 123E - EPSG:2480' },
  { value: '2481', label: 'Pulkovo 1995 / Gauss-Kruger CM 129E - EPSG:2481' },
  { value: '2482', label: 'Pulkovo 1995 / Gauss-Kruger CM 135E - EPSG:2482' },
  { value: '2483', label: 'Pulkovo 1995 / Gauss-Kruger CM 141E - EPSG:2483' },
  { value: '2484', label: 'Pulkovo 1995 / Gauss-Kruger CM 147E - EPSG:2484' },
  { value: '2485', label: 'Pulkovo 1995 / Gauss-Kruger CM 153E - EPSG:2485' },
  { value: '2486', label: 'Pulkovo 1995 / Gauss-Kruger CM 159E - EPSG:2486' },
  { value: '2487', label: 'Pulkovo 1995 / Gauss-Kruger CM 165E - EPSG:2487' },
  { value: '2488', label: 'Pulkovo 1995 / Gauss-Kruger CM 171E - EPSG:2488' },
  { value: '2489', label: 'Pulkovo 1995 / Gauss-Kruger CM 177E - EPSG:2489' },
  { value: '2490', label: 'Pulkovo 1995 / Gauss-Kruger CM 177W - EPSG:2490' },
  { value: '2491', label: 'Pulkovo 1995 / Gauss-Kruger CM 171W - EPSG:2491' },
  { value: '2494', label: 'Pulkovo 1942 / Gauss-Kruger CM 21E - EPSG:2494' },
  { value: '2495', label: 'Pulkovo 1942 / Gauss-Kruger CM 27E - EPSG:2495' },
  { value: '2496', label: 'Pulkovo 1942 / Gauss-Kruger CM 33E - EPSG:2496' },
  { value: '2497', label: 'Pulkovo 1942 / Gauss-Kruger CM 39E - EPSG:2497' },
  { value: '2498', label: 'Pulkovo 1942 / Gauss-Kruger CM 45E - EPSG:2498' },
  { value: '2499', label: 'Pulkovo 1942 / Gauss-Kruger CM 51E - EPSG:2499' },
  { value: '2500', label: 'Pulkovo 1942 / Gauss-Kruger CM 57E - EPSG:2500' },
  { value: '2501', label: 'Pulkovo 1942 / Gauss-Kruger CM 63E - EPSG:2501' },
  { value: '2502', label: 'Pulkovo 1942 / Gauss-Kruger CM 69E - EPSG:2502' },
  { value: '2503', label: 'Pulkovo 1942 / Gauss-Kruger CM 75E - EPSG:2503' },
  { value: '2504', label: 'Pulkovo 1942 / Gauss-Kruger CM 81E - EPSG:2504' },
  { value: '2505', label: 'Pulkovo 1942 / Gauss-Kruger CM 87E - EPSG:2505' },
  { value: '2506', label: 'Pulkovo 1942 / Gauss-Kruger CM 93E - EPSG:2506' },
  { value: '2507', label: 'Pulkovo 1942 / Gauss-Kruger CM 99E - EPSG:2507' },
  { value: '2508', label: 'Pulkovo 1942 / Gauss-Kruger CM 105E - EPSG:2508' },
  { value: '2509', label: 'Pulkovo 1942 / Gauss-Kruger CM 111E - EPSG:2509' },
  { value: '2510', label: 'Pulkovo 1942 / Gauss-Kruger CM 117E - EPSG:2510' },
  { value: '2511', label: 'Pulkovo 1942 / Gauss-Kruger CM 123E - EPSG:2511' },
  { value: '2512', label: 'Pulkovo 1942 / Gauss-Kruger CM 129E - EPSG:2512' },
  { value: '2513', label: 'Pulkovo 1942 / Gauss-Kruger CM 135E - EPSG:2513' },
  { value: '2514', label: 'Pulkovo 1942 / Gauss-Kruger CM 141E - EPSG:2514' },
  { value: '2515', label: 'Pulkovo 1942 / Gauss-Kruger CM 147E - EPSG:2515' },
  { value: '2516', label: 'Pulkovo 1942 / Gauss-Kruger CM 153E - EPSG:2516' },
  { value: '2517', label: 'Pulkovo 1942 / Gauss-Kruger CM 159E - EPSG:2517' },
  { value: '2518', label: 'Pulkovo 1942 / Gauss-Kruger CM 165E - EPSG:2518' },
  { value: '2519', label: 'Pulkovo 1942 / Gauss-Kruger CM 171E - EPSG:2519' },
  { value: '2520', label: 'Pulkovo 1942 / Gauss-Kruger CM 177E - EPSG:2520' },
  { value: '2521', label: 'Pulkovo 1942 / Gauss-Kruger CM 177W - EPSG:2521' },
  { value: '2522', label: 'Pulkovo 1942 / Gauss-Kruger CM 171W - EPSG:2522' },
  {
    value: '2523',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger zone 7 - EPSG:2523',
  },
  {
    value: '2524',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger zone 8 - EPSG:2524',
  },
  {
    value: '2525',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger zone 9 - EPSG:2525',
  },
  {
    value: '2526',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger zone 10 - EPSG:2526',
  },
  {
    value: '2527',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger zone 11 - EPSG:2527',
  },
  {
    value: '2528',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger zone 12 - EPSG:2528',
  },
  {
    value: '2529',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger zone 13 - EPSG:2529',
  },
  {
    value: '2530',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger zone 14 - EPSG:2530',
  },
  {
    value: '2531',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger zone 15 - EPSG:2531',
  },
  {
    value: '2532',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger zone 16 - EPSG:2532',
  },
  {
    value: '2533',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger zone 17 - EPSG:2533',
  },
  {
    value: '2534',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger zone 18 - EPSG:2534',
  },
  {
    value: '2535',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger zone 19 - EPSG:2535',
  },
  {
    value: '2536',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger zone 20 - EPSG:2536',
  },
  {
    value: '2537',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger zone 21 - EPSG:2537',
  },
  {
    value: '2538',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger zone 22 - EPSG:2538',
  },
  {
    value: '2539',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger zone 23 - EPSG:2539',
  },
  {
    value: '2540',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger zone 24 - EPSG:2540',
  },
  {
    value: '2541',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger zone 25 - EPSG:2541',
  },
  {
    value: '2542',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger zone 26 - EPSG:2542',
  },
  {
    value: '2543',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger zone 27 - EPSG:2543',
  },
  {
    value: '2544',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger zone 28 - EPSG:2544',
  },
  {
    value: '2545',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger zone 29 - EPSG:2545',
  },
  {
    value: '2546',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger zone 30 - EPSG:2546',
  },
  {
    value: '2547',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger zone 31 - EPSG:2547',
  },
  {
    value: '2548',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger zone 32 - EPSG:2548',
  },
  {
    value: '2549',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger zone 33 - EPSG:2549',
  },
  {
    value: '2551',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger zone 34 - EPSG:2551',
  },
  {
    value: '2552',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger zone 35 - EPSG:2552',
  },
  {
    value: '2553',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger zone 36 - EPSG:2553',
  },
  {
    value: '2554',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger zone 37 - EPSG:2554',
  },
  {
    value: '2555',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger zone 38 - EPSG:2555',
  },
  {
    value: '2556',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger zone 39 - EPSG:2556',
  },
  {
    value: '2557',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger zone 40 - EPSG:2557',
  },
  {
    value: '2558',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger zone 41 - EPSG:2558',
  },
  {
    value: '2559',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger zone 42 - EPSG:2559',
  },
  {
    value: '2560',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger zone 43 - EPSG:2560',
  },
  {
    value: '2561',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger zone 44 - EPSG:2561',
  },
  {
    value: '2562',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger zone 45 - EPSG:2562',
  },
  {
    value: '2563',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger zone 46 - EPSG:2563',
  },
  {
    value: '2564',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger zone 47 - EPSG:2564',
  },
  {
    value: '2565',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger zone 48 - EPSG:2565',
  },
  {
    value: '2566',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger zone 49 - EPSG:2566',
  },
  {
    value: '2567',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger zone 50 - EPSG:2567',
  },
  {
    value: '2568',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger zone 51 - EPSG:2568',
  },
  {
    value: '2569',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger zone 52 - EPSG:2569',
  },
  {
    value: '2570',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger zone 53 - EPSG:2570',
  },
  {
    value: '2571',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger zone 54 - EPSG:2571',
  },
  {
    value: '2572',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger zone 55 - EPSG:2572',
  },
  {
    value: '2573',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger zone 56 - EPSG:2573',
  },
  {
    value: '2574',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger zone 57 - EPSG:2574',
  },
  {
    value: '2575',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger zone 58 - EPSG:2575',
  },
  {
    value: '2576',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger zone 59 - EPSG:2576',
  },
  {
    value: '2578',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger zone 61 - EPSG:2578',
  },
  {
    value: '2579',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger zone 62 - EPSG:2579',
  },
  {
    value: '2580',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger zone 63 - EPSG:2580',
  },
  {
    value: '2581',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger zone 64 - EPSG:2581',
  },
  {
    value: '2582',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger CM 21E - EPSG:2582',
  },
  {
    value: '2583',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger CM 24E - EPSG:2583',
  },
  {
    value: '2584',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger CM 27E - EPSG:2584',
  },
  {
    value: '2585',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger CM 30E - EPSG:2585',
  },
  {
    value: '2586',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger CM 33E - EPSG:2586',
  },
  {
    value: '2587',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger CM 36E - EPSG:2587',
  },
  {
    value: '2588',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger CM 39E - EPSG:2588',
  },
  {
    value: '2589',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger CM 42E - EPSG:2589',
  },
  {
    value: '2590',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger CM 45E - EPSG:2590',
  },
  {
    value: '2591',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger CM 48E - EPSG:2591',
  },
  {
    value: '2592',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger CM 51E - EPSG:2592',
  },
  {
    value: '2593',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger CM 54E - EPSG:2593',
  },
  {
    value: '2594',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger CM 57E - EPSG:2594',
  },
  {
    value: '2595',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger CM 60E - EPSG:2595',
  },
  {
    value: '2596',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger CM 63E - EPSG:2596',
  },
  {
    value: '2597',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger CM 66E - EPSG:2597',
  },
  {
    value: '2598',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger CM 69E - EPSG:2598',
  },
  {
    value: '2599',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger CM 72E - EPSG:2599',
  },
  {
    value: '2601',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger CM 75E - EPSG:2601',
  },
  {
    value: '2602',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger CM 78E - EPSG:2602',
  },
  {
    value: '2603',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger CM 81E - EPSG:2603',
  },
  {
    value: '2604',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger CM 84E - EPSG:2604',
  },
  {
    value: '2605',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger CM 87E - EPSG:2605',
  },
  {
    value: '2606',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger CM 90E - EPSG:2606',
  },
  {
    value: '2607',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger CM 93E - EPSG:2607',
  },
  {
    value: '2608',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger CM 96E - EPSG:2608',
  },
  {
    value: '2609',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger CM 99E - EPSG:2609',
  },
  {
    value: '2610',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger CM 102E - EPSG:2610',
  },
  {
    value: '2611',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger CM 105E - EPSG:2611',
  },
  {
    value: '2612',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger CM 108E - EPSG:2612',
  },
  {
    value: '2613',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger CM 111E - EPSG:2613',
  },
  {
    value: '2614',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger CM 114E - EPSG:2614',
  },
  {
    value: '2615',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger CM 117E - EPSG:2615',
  },
  {
    value: '2616',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger CM 120E - EPSG:2616',
  },
  {
    value: '2617',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger CM 123E - EPSG:2617',
  },
  {
    value: '2618',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger CM 126E - EPSG:2618',
  },
  {
    value: '2619',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger CM 129E - EPSG:2619',
  },
  {
    value: '2620',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger CM 132E - EPSG:2620',
  },
  {
    value: '2621',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger CM 135E - EPSG:2621',
  },
  {
    value: '2622',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger CM 138E - EPSG:2622',
  },
  {
    value: '2623',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger CM 141E - EPSG:2623',
  },
  {
    value: '2624',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger CM 144E - EPSG:2624',
  },
  {
    value: '2625',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger CM 147E - EPSG:2625',
  },
  {
    value: '2626',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger CM 150E - EPSG:2626',
  },
  {
    value: '2627',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger CM 153E - EPSG:2627',
  },
  {
    value: '2628',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger CM 156E - EPSG:2628',
  },
  {
    value: '2629',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger CM 159E - EPSG:2629',
  },
  {
    value: '2630',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger CM 162E - EPSG:2630',
  },
  {
    value: '2631',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger CM 165E - EPSG:2631',
  },
  {
    value: '2632',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger CM 168E - EPSG:2632',
  },
  {
    value: '2633',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger CM 171E - EPSG:2633',
  },
  {
    value: '2634',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger CM 174E - EPSG:2634',
  },
  {
    value: '2635',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger CM 177E - EPSG:2635',
  },
  {
    value: '2636',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger CM 180E - EPSG:2636',
  },
  {
    value: '2637',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger CM 177W - EPSG:2637',
  },
  {
    value: '2638',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger CM 174W - EPSG:2638',
  },
  {
    value: '2639',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger CM 171W - EPSG:2639',
  },
  {
    value: '2640',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger CM 168W - EPSG:2640',
  },
  {
    value: '2641',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger zone 7 - EPSG:2641',
  },
  {
    value: '2642',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger zone 8 - EPSG:2642',
  },
  {
    value: '2643',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger zone 9 - EPSG:2643',
  },
  {
    value: '2644',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger zone 10 - EPSG:2644',
  },
  {
    value: '2645',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger zone 11 - EPSG:2645',
  },
  {
    value: '2646',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger zone 12 - EPSG:2646',
  },
  {
    value: '2647',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger zone 13 - EPSG:2647',
  },
  {
    value: '2648',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger zone 14 - EPSG:2648',
  },
  {
    value: '2649',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger zone 15 - EPSG:2649',
  },
  {
    value: '2650',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger zone 16 - EPSG:2650',
  },
  {
    value: '2651',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger zone 17 - EPSG:2651',
  },
  {
    value: '2652',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger zone 18 - EPSG:2652',
  },
  {
    value: '2653',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger zone 19 - EPSG:2653',
  },
  {
    value: '2654',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger zone 20 - EPSG:2654',
  },
  {
    value: '2655',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger zone 21 - EPSG:2655',
  },
  {
    value: '2656',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger zone 22 - EPSG:2656',
  },
  {
    value: '2657',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger zone 23 - EPSG:2657',
  },
  {
    value: '2658',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger zone 24 - EPSG:2658',
  },
  {
    value: '2659',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger zone 25 - EPSG:2659',
  },
  {
    value: '2660',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger zone 26 - EPSG:2660',
  },
  {
    value: '2661',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger zone 27 - EPSG:2661',
  },
  {
    value: '2662',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger zone 28 - EPSG:2662',
  },
  {
    value: '2663',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger zone 29 - EPSG:2663',
  },
  {
    value: '2664',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger zone 30 - EPSG:2664',
  },
  {
    value: '2665',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger zone 31 - EPSG:2665',
  },
  {
    value: '2666',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger zone 32 - EPSG:2666',
  },
  {
    value: '2667',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger zone 33 - EPSG:2667',
  },
  {
    value: '2668',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger zone 34 - EPSG:2668',
  },
  {
    value: '2669',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger zone 35 - EPSG:2669',
  },
  {
    value: '2670',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger zone 36 - EPSG:2670',
  },
  {
    value: '2671',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger zone 37 - EPSG:2671',
  },
  {
    value: '2672',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger zone 38 - EPSG:2672',
  },
  {
    value: '2673',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger zone 39 - EPSG:2673',
  },
  {
    value: '2674',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger zone 40 - EPSG:2674',
  },
  {
    value: '2675',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger zone 41 - EPSG:2675',
  },
  {
    value: '2676',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger zone 42 - EPSG:2676',
  },
  {
    value: '2677',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger zone 43 - EPSG:2677',
  },
  {
    value: '2678',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger zone 44 - EPSG:2678',
  },
  {
    value: '2679',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger zone 45 - EPSG:2679',
  },
  {
    value: '2680',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger zone 46 - EPSG:2680',
  },
  {
    value: '2681',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger zone 47 - EPSG:2681',
  },
  {
    value: '2682',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger zone 48 - EPSG:2682',
  },
  {
    value: '2683',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger zone 49 - EPSG:2683',
  },
  {
    value: '2684',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger zone 50 - EPSG:2684',
  },
  {
    value: '2685',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger zone 51 - EPSG:2685',
  },
  {
    value: '2686',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger zone 52 - EPSG:2686',
  },
  {
    value: '2687',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger zone 53 - EPSG:2687',
  },
  {
    value: '2688',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger zone 54 - EPSG:2688',
  },
  {
    value: '2689',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger zone 55 - EPSG:2689',
  },
  {
    value: '2690',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger zone 56 - EPSG:2690',
  },
  {
    value: '2691',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger zone 57 - EPSG:2691',
  },
  {
    value: '2692',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger zone 58 - EPSG:2692',
  },
  {
    value: '2693',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger zone 59 - EPSG:2693',
  },
  {
    value: '2695',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger zone 61 - EPSG:2695',
  },
  {
    value: '2696',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger zone 62 - EPSG:2696',
  },
  {
    value: '2697',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger zone 63 - EPSG:2697',
  },
  {
    value: '2698',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger zone 64 - EPSG:2698',
  },
  {
    value: '2699',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger CM 21E - EPSG:2699',
  },
  {
    value: '2700',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger CM 24E - EPSG:2700',
  },
  {
    value: '2701',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger CM 27E - EPSG:2701',
  },
  {
    value: '2702',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger CM 30E - EPSG:2702',
  },
  {
    value: '2703',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger CM 33E - EPSG:2703',
  },
  {
    value: '2704',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger CM 36E - EPSG:2704',
  },
  {
    value: '2705',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger CM 39E - EPSG:2705',
  },
  {
    value: '2706',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger CM 42E - EPSG:2706',
  },
  {
    value: '2707',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger CM 45E - EPSG:2707',
  },
  {
    value: '2708',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger CM 48E - EPSG:2708',
  },
  {
    value: '2709',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger CM 51E - EPSG:2709',
  },
  {
    value: '2710',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger CM 54E - EPSG:2710',
  },
  {
    value: '2711',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger CM 57E - EPSG:2711',
  },
  {
    value: '2712',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger CM 60E - EPSG:2712',
  },
  {
    value: '2713',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger CM 63E - EPSG:2713',
  },
  {
    value: '2714',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger CM 66E - EPSG:2714',
  },
  {
    value: '2715',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger CM 69E - EPSG:2715',
  },
  {
    value: '2716',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger CM 72E - EPSG:2716',
  },
  {
    value: '2717',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger CM 75E - EPSG:2717',
  },
  {
    value: '2718',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger CM 78E - EPSG:2718',
  },
  {
    value: '2719',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger CM 81E - EPSG:2719',
  },
  {
    value: '2720',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger CM 84E - EPSG:2720',
  },
  {
    value: '2721',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger CM 87E - EPSG:2721',
  },
  {
    value: '2722',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger CM 90E - EPSG:2722',
  },
  {
    value: '2723',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger CM 93E - EPSG:2723',
  },
  {
    value: '2724',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger CM 96E - EPSG:2724',
  },
  {
    value: '2725',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger CM 99E - EPSG:2725',
  },
  {
    value: '2726',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger CM 102E - EPSG:2726',
  },
  {
    value: '2727',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger CM 105E - EPSG:2727',
  },
  {
    value: '2728',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger CM 108E - EPSG:2728',
  },
  {
    value: '2729',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger CM 111E - EPSG:2729',
  },
  {
    value: '2730',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger CM 114E - EPSG:2730',
  },
  {
    value: '2731',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger CM 117E - EPSG:2731',
  },
  {
    value: '2732',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger CM 120E - EPSG:2732',
  },
  {
    value: '2733',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger CM 123E - EPSG:2733',
  },
  {
    value: '2734',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger CM 126E - EPSG:2734',
  },
  {
    value: '2735',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger CM 129E - EPSG:2735',
  },
  { value: '2736', label: 'Tete / UTM zone 36S - EPSG:2736' },
  { value: '2737', label: 'Tete / UTM zone 37S - EPSG:2737' },
  {
    value: '2738',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger CM 132E - EPSG:2738',
  },
  {
    value: '2739',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger CM 135E - EPSG:2739',
  },
  {
    value: '2740',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger CM 138E - EPSG:2740',
  },
  {
    value: '2741',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger CM 141E - EPSG:2741',
  },
  {
    value: '2742',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger CM 144E - EPSG:2742',
  },
  {
    value: '2743',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger CM 147E - EPSG:2743',
  },
  {
    value: '2744',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger CM 150E - EPSG:2744',
  },
  {
    value: '2745',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger CM 153E - EPSG:2745',
  },
  {
    value: '2746',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger CM 156E - EPSG:2746',
  },
  {
    value: '2747',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger CM 159E - EPSG:2747',
  },
  {
    value: '2748',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger CM 162E - EPSG:2748',
  },
  {
    value: '2749',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger CM 165E - EPSG:2749',
  },
  {
    value: '2750',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger CM 168E - EPSG:2750',
  },
  {
    value: '2751',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger CM 171E - EPSG:2751',
  },
  {
    value: '2752',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger CM 174E - EPSG:2752',
  },
  {
    value: '2753',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger CM 177E - EPSG:2753',
  },
  {
    value: '2754',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger CM 180E - EPSG:2754',
  },
  {
    value: '2755',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger CM 177W - EPSG:2755',
  },
  {
    value: '2756',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger CM 174W - EPSG:2756',
  },
  {
    value: '2757',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger CM 171W - EPSG:2757',
  },
  {
    value: '2758',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger CM 168W - EPSG:2758',
  },
  { value: '2759', label: 'NAD83(HARN) / Alabama East - EPSG:2759' },
  { value: '2760', label: 'NAD83(HARN) / Alabama West - EPSG:2760' },
  { value: '2761', label: 'NAD83(HARN) / Arizona East - EPSG:2761' },
  { value: '2762', label: 'NAD83(HARN) / Arizona Central - EPSG:2762' },
  { value: '2763', label: 'NAD83(HARN) / Arizona West - EPSG:2763' },
  { value: '2764', label: 'NAD83(HARN) / Arkansas North - EPSG:2764' },
  { value: '2765', label: 'NAD83(HARN) / Arkansas South - EPSG:2765' },
  { value: '2766', label: 'NAD83(HARN) / California zone 1 - EPSG:2766' },
  { value: '2767', label: 'NAD83(HARN) / California zone 2 - EPSG:2767' },
  { value: '2768', label: 'NAD83(HARN) / California zone 3 - EPSG:2768' },
  { value: '2769', label: 'NAD83(HARN) / California zone 4 - EPSG:2769' },
  { value: '2770', label: 'NAD83(HARN) / California zone 5 - EPSG:2770' },
  { value: '2771', label: 'NAD83(HARN) / California zone 6 - EPSG:2771' },
  { value: '2772', label: 'NAD83(HARN) / Colorado North - EPSG:2772' },
  { value: '2773', label: 'NAD83(HARN) / Colorado Central - EPSG:2773' },
  { value: '2774', label: 'NAD83(HARN) / Colorado South - EPSG:2774' },
  { value: '2775', label: 'NAD83(HARN) / Connecticut - EPSG:2775' },
  { value: '2776', label: 'NAD83(HARN) / Delaware - EPSG:2776' },
  { value: '2777', label: 'NAD83(HARN) / Florida East - EPSG:2777' },
  { value: '2778', label: 'NAD83(HARN) / Florida West - EPSG:2778' },
  { value: '2779', label: 'NAD83(HARN) / Florida North - EPSG:2779' },
  { value: '2780', label: 'NAD83(HARN) / Georgia East - EPSG:2780' },
  { value: '2781', label: 'NAD83(HARN) / Georgia West - EPSG:2781' },
  { value: '2782', label: 'NAD83(HARN) / Hawaii zone 1 - EPSG:2782' },
  { value: '2783', label: 'NAD83(HARN) / Hawaii zone 2 - EPSG:2783' },
  { value: '2784', label: 'NAD83(HARN) / Hawaii zone 3 - EPSG:2784' },
  { value: '2785', label: 'NAD83(HARN) / Hawaii zone 4 - EPSG:2785' },
  { value: '2786', label: 'NAD83(HARN) / Hawaii zone 5 - EPSG:2786' },
  { value: '2787', label: 'NAD83(HARN) / Idaho East - EPSG:2787' },
  { value: '2788', label: 'NAD83(HARN) / Idaho Central - EPSG:2788' },
  { value: '2789', label: 'NAD83(HARN) / Idaho West - EPSG:2789' },
  { value: '2790', label: 'NAD83(HARN) / Illinois East - EPSG:2790' },
  { value: '2791', label: 'NAD83(HARN) / Illinois West - EPSG:2791' },
  { value: '2792', label: 'NAD83(HARN) / Indiana East - EPSG:2792' },
  { value: '2793', label: 'NAD83(HARN) / Indiana West - EPSG:2793' },
  { value: '2794', label: 'NAD83(HARN) / Iowa North - EPSG:2794' },
  { value: '2795', label: 'NAD83(HARN) / Iowa South - EPSG:2795' },
  { value: '2796', label: 'NAD83(HARN) / Kansas North - EPSG:2796' },
  { value: '2797', label: 'NAD83(HARN) / Kansas South - EPSG:2797' },
  { value: '2798', label: 'NAD83(HARN) / Kentucky North - EPSG:2798' },
  { value: '2799', label: 'NAD83(HARN) / Kentucky South - EPSG:2799' },
  { value: '2800', label: 'NAD83(HARN) / Louisiana North - EPSG:2800' },
  { value: '2801', label: 'NAD83(HARN) / Louisiana South - EPSG:2801' },
  { value: '2802', label: 'NAD83(HARN) / Maine East - EPSG:2802' },
  { value: '2803', label: 'NAD83(HARN) / Maine West - EPSG:2803' },
  { value: '2804', label: 'NAD83(HARN) / Maryland - EPSG:2804' },
  { value: '2805', label: 'NAD83(HARN) / Massachusetts Mainland - EPSG:2805' },
  { value: '2806', label: 'NAD83(HARN) / Massachusetts Island - EPSG:2806' },
  { value: '2807', label: 'NAD83(HARN) / Michigan North - EPSG:2807' },
  { value: '2808', label: 'NAD83(HARN) / Michigan Central - EPSG:2808' },
  { value: '2809', label: 'NAD83(HARN) / Michigan South - EPSG:2809' },
  { value: '2810', label: 'NAD83(HARN) / Minnesota North - EPSG:2810' },
  { value: '2811', label: 'NAD83(HARN) / Minnesota Central - EPSG:2811' },
  { value: '2812', label: 'NAD83(HARN) / Minnesota South - EPSG:2812' },
  { value: '2813', label: 'NAD83(HARN) / Mississippi East - EPSG:2813' },
  { value: '2814', label: 'NAD83(HARN) / Mississippi West - EPSG:2814' },
  { value: '2815', label: 'NAD83(HARN) / Missouri East - EPSG:2815' },
  { value: '2816', label: 'NAD83(HARN) / Missouri Central - EPSG:2816' },
  { value: '2817', label: 'NAD83(HARN) / Missouri West - EPSG:2817' },
  { value: '2818', label: 'NAD83(HARN) / Montana - EPSG:2818' },
  { value: '2819', label: 'NAD83(HARN) / Nebraska - EPSG:2819' },
  { value: '2820', label: 'NAD83(HARN) / Nevada East - EPSG:2820' },
  { value: '2821', label: 'NAD83(HARN) / Nevada Central - EPSG:2821' },
  { value: '2822', label: 'NAD83(HARN) / Nevada West - EPSG:2822' },
  { value: '2823', label: 'NAD83(HARN) / New Hampshire - EPSG:2823' },
  { value: '2824', label: 'NAD83(HARN) / New Jersey - EPSG:2824' },
  { value: '2825', label: 'NAD83(HARN) / New Mexico East - EPSG:2825' },
  { value: '2826', label: 'NAD83(HARN) / New Mexico Central - EPSG:2826' },
  { value: '2827', label: 'NAD83(HARN) / New Mexico West - EPSG:2827' },
  { value: '2828', label: 'NAD83(HARN) / New York East - EPSG:2828' },
  { value: '2829', label: 'NAD83(HARN) / New York Central - EPSG:2829' },
  { value: '2830', label: 'NAD83(HARN) / New York West - EPSG:2830' },
  { value: '2831', label: 'NAD83(HARN) / New York Long Island - EPSG:2831' },
  { value: '2832', label: 'NAD83(HARN) / North Dakota North - EPSG:2832' },
  { value: '2833', label: 'NAD83(HARN) / North Dakota South - EPSG:2833' },
  { value: '2834', label: 'NAD83(HARN) / Ohio North - EPSG:2834' },
  { value: '2835', label: 'NAD83(HARN) / Ohio South - EPSG:2835' },
  { value: '2836', label: 'NAD83(HARN) / Oklahoma North - EPSG:2836' },
  { value: '2837', label: 'NAD83(HARN) / Oklahoma South - EPSG:2837' },
  { value: '2838', label: 'NAD83(HARN) / Oregon North - EPSG:2838' },
  { value: '2839', label: 'NAD83(HARN) / Oregon South - EPSG:2839' },
  { value: '2840', label: 'NAD83(HARN) / Rhode Island - EPSG:2840' },
  { value: '2841', label: 'NAD83(HARN) / South Dakota North - EPSG:2841' },
  { value: '2842', label: 'NAD83(HARN) / South Dakota South - EPSG:2842' },
  { value: '2843', label: 'NAD83(HARN) / Tennessee - EPSG:2843' },
  { value: '2844', label: 'NAD83(HARN) / Texas North - EPSG:2844' },
  { value: '2845', label: 'NAD83(HARN) / Texas North Central - EPSG:2845' },
  { value: '2846', label: 'NAD83(HARN) / Texas Central - EPSG:2846' },
  { value: '2847', label: 'NAD83(HARN) / Texas South Central - EPSG:2847' },
  { value: '2848', label: 'NAD83(HARN) / Texas South - EPSG:2848' },
  { value: '2849', label: 'NAD83(HARN) / Utah North - EPSG:2849' },
  { value: '2850', label: 'NAD83(HARN) / Utah Central - EPSG:2850' },
  { value: '2851', label: 'NAD83(HARN) / Utah South - EPSG:2851' },
  { value: '2852', label: 'NAD83(HARN) / Vermont - EPSG:2852' },
  { value: '2853', label: 'NAD83(HARN) / Virginia North - EPSG:2853' },
  { value: '2854', label: 'NAD83(HARN) / Virginia South - EPSG:2854' },
  { value: '2855', label: 'NAD83(HARN) / Washington North - EPSG:2855' },
  { value: '2856', label: 'NAD83(HARN) / Washington South - EPSG:2856' },
  { value: '2857', label: 'NAD83(HARN) / West Virginia North - EPSG:2857' },
  { value: '2858', label: 'NAD83(HARN) / West Virginia South - EPSG:2858' },
  { value: '2859', label: 'NAD83(HARN) / Wisconsin North - EPSG:2859' },
  { value: '2860', label: 'NAD83(HARN) / Wisconsin Central - EPSG:2860' },
  { value: '2861', label: 'NAD83(HARN) / Wisconsin South - EPSG:2861' },
  { value: '2862', label: 'NAD83(HARN) / Wyoming East - EPSG:2862' },
  { value: '2863', label: 'NAD83(HARN) / Wyoming East Central - EPSG:2863' },
  { value: '2864', label: 'NAD83(HARN) / Wyoming West Central - EPSG:2864' },
  { value: '2865', label: 'NAD83(HARN) / Wyoming West - EPSG:2865' },
  {
    value: '2866',
    label: 'NAD83(HARN) / Puerto Rico and Virgin Is. - EPSG:2866',
  },
  { value: '2867', label: 'NAD83(HARN) / Arizona East (ft) - EPSG:2867' },
  { value: '2868', label: 'NAD83(HARN) / Arizona Central (ft) - EPSG:2868' },
  { value: '2869', label: 'NAD83(HARN) / Arizona West (ft) - EPSG:2869' },
  {
    value: '2870',
    label: 'NAD83(HARN) / California zone 1 (ftUS) - EPSG:2870',
  },
  {
    value: '2871',
    label: 'NAD83(HARN) / California zone 2 (ftUS) - EPSG:2871',
  },
  {
    value: '2872',
    label: 'NAD83(HARN) / California zone 3 (ftUS) - EPSG:2872',
  },
  {
    value: '2873',
    label: 'NAD83(HARN) / California zone 4 (ftUS) - EPSG:2873',
  },
  {
    value: '2874',
    label: 'NAD83(HARN) / California zone 5 (ftUS) - EPSG:2874',
  },
  {
    value: '2875',
    label: 'NAD83(HARN) / California zone 6 (ftUS) - EPSG:2875',
  },
  { value: '2876', label: 'NAD83(HARN) / Colorado North (ftUS) - EPSG:2876' },
  { value: '2877', label: 'NAD83(HARN) / Colorado Central (ftUS) - EPSG:2877' },
  { value: '2878', label: 'NAD83(HARN) / Colorado South (ftUS) - EPSG:2878' },
  { value: '2879', label: 'NAD83(HARN) / Connecticut (ftUS) - EPSG:2879' },
  { value: '2880', label: 'NAD83(HARN) / Delaware (ftUS) - EPSG:2880' },
  { value: '2881', label: 'NAD83(HARN) / Florida East (ftUS) - EPSG:2881' },
  { value: '2882', label: 'NAD83(HARN) / Florida West (ftUS) - EPSG:2882' },
  { value: '2883', label: 'NAD83(HARN) / Florida North (ftUS) - EPSG:2883' },
  { value: '2884', label: 'NAD83(HARN) / Georgia East (ftUS) - EPSG:2884' },
  { value: '2885', label: 'NAD83(HARN) / Georgia West (ftUS) - EPSG:2885' },
  { value: '2886', label: 'NAD83(HARN) / Idaho East (ftUS) - EPSG:2886' },
  { value: '2887', label: 'NAD83(HARN) / Idaho Central (ftUS) - EPSG:2887' },
  { value: '2888', label: 'NAD83(HARN) / Idaho West (ftUS) - EPSG:2888' },
  { value: '2891', label: 'NAD83(HARN) / Kentucky North (ftUS) - EPSG:2891' },
  { value: '2892', label: 'NAD83(HARN) / Kentucky South (ftUS) - EPSG:2892' },
  { value: '2893', label: 'NAD83(HARN) / Maryland (ftUS) - EPSG:2893' },
  {
    value: '2894',
    label: 'NAD83(HARN) / Massachusetts Mainland (ftUS) - EPSG:2894',
  },
  {
    value: '2895',
    label: 'NAD83(HARN) / Massachusetts Island (ftUS) - EPSG:2895',
  },
  { value: '2896', label: 'NAD83(HARN) / Michigan North (ft) - EPSG:2896' },
  { value: '2897', label: 'NAD83(HARN) / Michigan Central (ft) - EPSG:2897' },
  { value: '2898', label: 'NAD83(HARN) / Michigan South (ft) - EPSG:2898' },
  { value: '2899', label: 'NAD83(HARN) / Mississippi East (ftUS) - EPSG:2899' },
  { value: '2900', label: 'NAD83(HARN) / Mississippi West (ftUS) - EPSG:2900' },
  { value: '2901', label: 'NAD83(HARN) / Montana (ft) - EPSG:2901' },
  { value: '2902', label: 'NAD83(HARN) / New Mexico East (ftUS) - EPSG:2902' },
  {
    value: '2903',
    label: 'NAD83(HARN) / New Mexico Central (ftUS) - EPSG:2903',
  },
  { value: '2904', label: 'NAD83(HARN) / New Mexico West (ftUS) - EPSG:2904' },
  { value: '2905', label: 'NAD83(HARN) / New York East (ftUS) - EPSG:2905' },
  { value: '2906', label: 'NAD83(HARN) / New York Central (ftUS) - EPSG:2906' },
  { value: '2907', label: 'NAD83(HARN) / New York West (ftUS) - EPSG:2907' },
  {
    value: '2908',
    label: 'NAD83(HARN) / New York Long Island (ftUS) - EPSG:2908',
  },
  { value: '2909', label: 'NAD83(HARN) / North Dakota North (ft) - EPSG:2909' },
  { value: '2910', label: 'NAD83(HARN) / North Dakota South (ft) - EPSG:2910' },
  { value: '2911', label: 'NAD83(HARN) / Oklahoma North (ftUS) - EPSG:2911' },
  { value: '2912', label: 'NAD83(HARN) / Oklahoma South (ftUS) - EPSG:2912' },
  { value: '2913', label: 'NAD83(HARN) / Oregon North (ft) - EPSG:2913' },
  { value: '2914', label: 'NAD83(HARN) / Oregon South (ft) - EPSG:2914' },
  { value: '2915', label: 'NAD83(HARN) / Tennessee (ftUS) - EPSG:2915' },
  { value: '2916', label: 'NAD83(HARN) / Texas North (ftUS) - EPSG:2916' },
  {
    value: '2917',
    label: 'NAD83(HARN) / Texas North Central (ftUS) - EPSG:2917',
  },
  { value: '2918', label: 'NAD83(HARN) / Texas Central (ftUS) - EPSG:2918' },
  {
    value: '2919',
    label: 'NAD83(HARN) / Texas South Central (ftUS) - EPSG:2919',
  },
  { value: '2920', label: 'NAD83(HARN) / Texas South (ftUS) - EPSG:2920' },
  { value: '2921', label: 'NAD83(HARN) / Utah North (ft) - EPSG:2921' },
  { value: '2922', label: 'NAD83(HARN) / Utah Central (ft) - EPSG:2922' },
  { value: '2923', label: 'NAD83(HARN) / Utah South (ft) - EPSG:2923' },
  { value: '2924', label: 'NAD83(HARN) / Virginia North (ftUS) - EPSG:2924' },
  { value: '2925', label: 'NAD83(HARN) / Virginia South (ftUS) - EPSG:2925' },
  { value: '2926', label: 'NAD83(HARN) / Washington North (ftUS) - EPSG:2926' },
  { value: '2927', label: 'NAD83(HARN) / Washington South (ftUS) - EPSG:2927' },
  { value: '2928', label: 'NAD83(HARN) / Wisconsin North (ftUS) - EPSG:2928' },
  {
    value: '2929',
    label: 'NAD83(HARN) / Wisconsin Central (ftUS) - EPSG:2929',
  },
  { value: '2930', label: 'NAD83(HARN) / Wisconsin South (ftUS) - EPSG:2930' },
  { value: '2931', label: 'Beduaram / TM 13 NE - EPSG:2931' },
  { value: '2932', label: 'QND95 / Qatar National Grid - EPSG:2932' },
  { value: '2933', label: 'Segara / UTM zone 50S - EPSG:2933' },
  { value: '2935', label: 'Pulkovo 1942 / CS63 zone A1 - EPSG:2935' },
  { value: '2936', label: 'Pulkovo 1942 / CS63 zone A2 - EPSG:2936' },
  { value: '2937', label: 'Pulkovo 1942 / CS63 zone A3 - EPSG:2937' },
  { value: '2938', label: 'Pulkovo 1942 / CS63 zone A4 - EPSG:2938' },
  { value: '2939', label: 'Pulkovo 1942 / CS63 zone K2 - EPSG:2939' },
  { value: '2940', label: 'Pulkovo 1942 / CS63 zone K3 - EPSG:2940' },
  { value: '2941', label: 'Pulkovo 1942 / CS63 zone K4 - EPSG:2941' },
  { value: '2942', label: 'Porto Santo / UTM zone 28N - EPSG:2942' },
  { value: '2943', label: 'Selvagem Grande / UTM zone 28N - EPSG:2943' },
  { value: '2945', label: 'NAD83(CSRS) / MTM zone 3 - EPSG:2945' },
  { value: '2946', label: 'NAD83(CSRS) / MTM zone 4 - EPSG:2946' },
  { value: '2947', label: 'NAD83(CSRS) / MTM zone 5 - EPSG:2947' },
  { value: '2948', label: 'NAD83(CSRS) / MTM zone 6 - EPSG:2948' },
  { value: '2949', label: 'NAD83(CSRS) / MTM zone 7 - EPSG:2949' },
  { value: '2950', label: 'NAD83(CSRS) / MTM zone 8 - EPSG:2950' },
  { value: '2951', label: 'NAD83(CSRS) / MTM zone 9 - EPSG:2951' },
  { value: '2952', label: 'NAD83(CSRS) / MTM zone 10 - EPSG:2952' },
  {
    value: '2953',
    label: 'NAD83(CSRS) / New Brunswick Stereographic - EPSG:2953',
  },
  {
    value: '2954',
    label: 'NAD83(CSRS) / Prince Edward Isl. Stereographic (NAD83) - EPSG:2954',
  },
  { value: '2955', label: 'NAD83(CSRS) / UTM zone 11N - EPSG:2955' },
  { value: '2956', label: 'NAD83(CSRS) / UTM zone 12N - EPSG:2956' },
  { value: '2957', label: 'NAD83(CSRS) / UTM zone 13N - EPSG:2957' },
  { value: '2958', label: 'NAD83(CSRS) / UTM zone 17N - EPSG:2958' },
  { value: '2959', label: 'NAD83(CSRS) / UTM zone 18N - EPSG:2959' },
  { value: '2960', label: 'NAD83(CSRS) / UTM zone 19N - EPSG:2960' },
  { value: '2961', label: 'NAD83(CSRS) / UTM zone 20N - EPSG:2961' },
  { value: '2962', label: 'NAD83(CSRS) / UTM zone 21N - EPSG:2962' },
  { value: '2963', label: 'Lisbon 1890 (Lisbon) / Portugal Bonne - EPSG:2963' },
  { value: '2964', label: 'NAD27 / Alaska Albers - EPSG:2964' },
  { value: '2965', label: 'NAD83 / Indiana East (ftUS) - EPSG:2965' },
  { value: '2966', label: 'NAD83 / Indiana West (ftUS) - EPSG:2966' },
  { value: '2967', label: 'NAD83(HARN) / Indiana East (ftUS) - EPSG:2967' },
  { value: '2968', label: 'NAD83(HARN) / Indiana West (ftUS) - EPSG:2968' },
  { value: '2969', label: 'Fort Marigot / UTM zone 20N - EPSG:2969' },
  { value: '2970', label: 'Guadeloupe 1948 / UTM zone 20N - EPSG:2970' },
  { value: '2971', label: 'CSG67 / UTM zone 22N - EPSG:2971' },
  { value: '2972', label: 'RGFG95 / UTM zone 22N - EPSG:2972' },
  { value: '2973', label: 'Martinique 1938 / UTM zone 20N - EPSG:2973' },
  { value: '2975', label: 'RGR92 / UTM zone 40S - EPSG:2975' },
  { value: '2976', label: 'Tahiti 52 / UTM zone 6S - EPSG:2976' },
  { value: '2977', label: 'Tahaa 54 / UTM zone 5S - EPSG:2977' },
  { value: '2978', label: 'IGN72 Nuku Hiva / UTM zone 7S - EPSG:2978' },
  { value: '2980', label: 'Combani 1950 / UTM zone 38S - EPSG:2980' },
  { value: '2981', label: 'IGN56 Lifou / UTM zone 58S - EPSG:2981' },
  {
    value: '2985',
    label: 'Petrels 1972 / Terre Adelie Polar Stereographic - EPSG:2985',
  },
  {
    value: '2986',
    label: 'Perroud 1950 / Terre Adelie Polar Stereographic - EPSG:2986',
  },
  {
    value: '2987',
    label: 'Saint Pierre et Miquelon 1950 / UTM zone 21N - EPSG:2987',
  },
  { value: '2988', label: 'MOP78 / UTM zone 1S - EPSG:2988' },
  { value: '2991', label: 'NAD83 / Oregon LCC (m) - EPSG:2991' },
  { value: '2992', label: 'NAD83 / Oregon GIC Lambert (ft) - EPSG:2992' },
  { value: '2993', label: 'NAD83(HARN) / Oregon LCC (m) - EPSG:2993' },
  { value: '2994', label: 'NAD83(HARN) / Oregon GIC Lambert (ft) - EPSG:2994' },
  { value: '2995', label: 'IGN53 Mare / UTM zone 58S - EPSG:2995' },
  { value: '2996', label: 'ST84 Ile des Pins / UTM zone 58S - EPSG:2996' },
  { value: '2997', label: 'ST71 Belep / UTM zone 58S - EPSG:2997' },
  { value: '2998', label: 'NEA74 Noumea / UTM zone 58S - EPSG:2998' },
  { value: '2999', label: 'Grand Comoros / UTM zone 38S - EPSG:2999' },
  { value: '3000', label: 'Segara / NEIEZ - EPSG:3000' },
  { value: '3001', label: 'Batavia / NEIEZ - EPSG:3001' },
  { value: '3002', label: 'Makassar / NEIEZ - EPSG:3002' },
  { value: '3003', label: 'Monte Mario / Italy zone 1 - EPSG:3003' },
  { value: '3004', label: 'Monte Mario / Italy zone 2 - EPSG:3004' },
  { value: '3005', label: 'NAD83 / BC Albers - EPSG:3005' },
  { value: '3006', label: 'SWEREF99 TM - EPSG:3006' },
  { value: '3007', label: 'SWEREF99 12 00 - EPSG:3007' },
  { value: '3008', label: 'SWEREF99 13 30 - EPSG:3008' },
  { value: '3009', label: 'SWEREF99 15 00 - EPSG:3009' },
  { value: '3010', label: 'SWEREF99 16 30 - EPSG:3010' },
  { value: '3011', label: 'SWEREF99 18 00 - EPSG:3011' },
  { value: '3012', label: 'SWEREF99 14 15 - EPSG:3012' },
  { value: '3013', label: 'SWEREF99 15 45 - EPSG:3013' },
  { value: '3014', label: 'SWEREF99 17 15 - EPSG:3014' },
  { value: '3015', label: 'SWEREF99 18 45 - EPSG:3015' },
  { value: '3016', label: 'SWEREF99 20 15 - EPSG:3016' },
  { value: '3017', label: 'SWEREF99 21 45 - EPSG:3017' },
  { value: '3018', label: 'SWEREF99 23 15 - EPSG:3018' },
  { value: '3019', label: 'RT90 7.5 gon V - EPSG:3019' },
  { value: '3020', label: 'RT90 5 gon V - EPSG:3020' },
  { value: '3021', label: 'RT90 2.5 gon V - EPSG:3021' },
  { value: '3022', label: 'RT90 0 gon - EPSG:3022' },
  { value: '3023', label: 'RT90 2.5 gon O - EPSG:3023' },
  { value: '3024', label: 'RT90 5 gon O - EPSG:3024' },
  { value: '3025', label: 'RT38 7.5 gon V - EPSG:3025' },
  { value: '3026', label: 'RT38 5 gon V - EPSG:3026' },
  { value: '3027', label: 'RT38 2.5 gon V - EPSG:3027' },
  { value: '3028', label: 'RT38 0 gon - EPSG:3028' },
  { value: '3029', label: 'RT38 2.5 gon O - EPSG:3029' },
  { value: '3030', label: 'RT38 5 gon O - EPSG:3030' },
  {
    value: '3031',
    label: 'WGS 84 / Antarctic Polar Stereographic - EPSG:3031',
  },
  {
    value: '3032',
    label: 'WGS 84 / Australian Antarctic Polar Stereographic - EPSG:3032',
  },
  { value: '3033', label: 'WGS 84 / Australian Antarctic Lambert - EPSG:3033' },
  { value: '3034', label: 'ETRS89 / LCC Europe - EPSG:3034' },
  { value: '3035', label: 'ETRS89 / LAEA Europe - EPSG:3035' },
  { value: '3036', label: 'Moznet / UTM zone 36S - EPSG:3036' },
  { value: '3037', label: 'Moznet / UTM zone 37S - EPSG:3037' },
  { value: '3040', label: 'ETRS89 / UTM zone 28N (N-E) - EPSG:3040' },
  { value: '3041', label: 'ETRS89 / UTM zone 29N (N-E) - EPSG:3041' },
  { value: '3042', label: 'ETRS89 / UTM zone 30N (N-E) - EPSG:3042' },
  { value: '3043', label: 'ETRS89 / UTM zone 31N (N-E) - EPSG:3043' },
  { value: '3044', label: 'ETRS89 / UTM zone 32N (N-E) - EPSG:3044' },
  { value: '3045', label: 'ETRS89 / UTM zone 33N (N-E) - EPSG:3045' },
  { value: '3046', label: 'ETRS89 / UTM zone 34N (N-E) - EPSG:3046' },
  { value: '3047', label: 'ETRS89 / UTM zone 35N (N-E) - EPSG:3047' },
  { value: '3048', label: 'ETRS89 / UTM zone 36N (N-E) - EPSG:3048' },
  { value: '3049', label: 'ETRS89 / UTM zone 37N (N-E) - EPSG:3049' },
  { value: '3052', label: 'Reykjavik 1900 / Lambert 1900 - EPSG:3052' },
  { value: '3053', label: 'Hjorsey 1955 / Lambert 1955 - EPSG:3053' },
  { value: '3054', label: 'Hjorsey 1955 / UTM zone 26N - EPSG:3054' },
  { value: '3055', label: 'Hjorsey 1955 / UTM zone 27N - EPSG:3055' },
  { value: '3056', label: 'Hjorsey 1955 / UTM zone 28N - EPSG:3056' },
  { value: '3057', label: 'ISN93 / Lambert 1993 - EPSG:3057' },
  { value: '3058', label: 'Helle 1954 / Jan Mayen Grid - EPSG:3058' },
  { value: '3059', label: 'LKS92 / Latvia TM - EPSG:3059' },
  { value: '3060', label: 'IGN72 Grande Terre / UTM zone 58S - EPSG:3060' },
  { value: '3061', label: 'Porto Santo 1995 / UTM zone 28N - EPSG:3061' },
  { value: '3062', label: 'Azores Oriental 1995 / UTM zone 26N - EPSG:3062' },
  { value: '3063', label: 'Azores Central 1995 / UTM zone 26N - EPSG:3063' },
  { value: '3064', label: 'IGM95 / UTM zone 32N - EPSG:3064' },
  { value: '3065', label: 'IGM95 / UTM zone 33N - EPSG:3065' },
  { value: '3066', label: 'ED50 / Jordan TM - EPSG:3066' },
  { value: '3067', label: 'ETRS89 / TM35FIN(E,N) - EPSG:3067' },
  { value: '3068', label: 'DHDN / Soldner Berlin - EPSG:3068' },
  { value: '3069', label: 'NAD27 / Wisconsin Transverse Mercator - EPSG:3069' },
  { value: '3070', label: 'NAD83 / Wisconsin Transverse Mercator - EPSG:3070' },
  {
    value: '3071',
    label: 'NAD83(HARN) / Wisconsin Transverse Mercator - EPSG:3071',
  },
  { value: '3072', label: 'NAD83 / Maine CS2000 East - EPSG:3072' },
  { value: '3074', label: 'NAD83 / Maine CS2000 West - EPSG:3074' },
  { value: '3075', label: 'NAD83(HARN) / Maine CS2000 East - EPSG:3075' },
  { value: '3077', label: 'NAD83(HARN) / Maine CS2000 West - EPSG:3077' },
  { value: '3078', label: 'NAD83 / Michigan Oblique Mercator - EPSG:3078' },
  {
    value: '3079',
    label: 'NAD83(HARN) / Michigan Oblique Mercator - EPSG:3079',
  },
  { value: '3080', label: 'NAD27 / Shackleford - EPSG:3080' },
  { value: '3081', label: 'NAD83 / Texas State Mapping System - EPSG:3081' },
  {
    value: '3082',
    label: 'NAD83 / Texas Centric Lambert Conformal - EPSG:3082',
  },
  {
    value: '3083',
    label: 'NAD83 / Texas Centric Albers Equal Area - EPSG:3083',
  },
  {
    value: '3084',
    label: 'NAD83(HARN) / Texas Centric Lambert Conformal - EPSG:3084',
  },
  {
    value: '3085',
    label: 'NAD83(HARN) / Texas Centric Albers Equal Area - EPSG:3085',
  },
  { value: '3086', label: 'NAD83 / Florida GDL Albers - EPSG:3086' },
  { value: '3087', label: 'NAD83(HARN) / Florida GDL Albers - EPSG:3087' },
  { value: '3088', label: 'NAD83 / Kentucky Single Zone - EPSG:3088' },
  { value: '3089', label: 'NAD83 / Kentucky Single Zone (ftUS) - EPSG:3089' },
  { value: '3090', label: 'NAD83(HARN) / Kentucky Single Zone - EPSG:3090' },
  {
    value: '3091',
    label: 'NAD83(HARN) / Kentucky Single Zone (ftUS) - EPSG:3091',
  },
  { value: '3092', label: 'Tokyo / UTM zone 51N - EPSG:3092' },
  { value: '3093', label: 'Tokyo / UTM zone 52N - EPSG:3093' },
  { value: '3094', label: 'Tokyo / UTM zone 53N - EPSG:3094' },
  { value: '3095', label: 'Tokyo / UTM zone 54N - EPSG:3095' },
  { value: '3096', label: 'Tokyo / UTM zone 55N - EPSG:3096' },
  { value: '3097', label: 'JGD2000 / UTM zone 51N - EPSG:3097' },
  { value: '3098', label: 'JGD2000 / UTM zone 52N - EPSG:3098' },
  { value: '3099', label: 'JGD2000 / UTM zone 53N - EPSG:3099' },
  { value: '3100', label: 'JGD2000 / UTM zone 54N - EPSG:3100' },
  { value: '3101', label: 'JGD2000 / UTM zone 55N - EPSG:3101' },
  {
    value: '3102',
    label: 'American Samoa 1962 / American Samoa Lambert - EPSG:3102',
  },
  {
    value: '3106',
    label: 'Gulshan 303 / Bangladesh Transverse Mercator - EPSG:3106',
  },
  { value: '3107', label: 'GDA94 / SA Lambert - EPSG:3107' },
  { value: '3108', label: 'ETRS89 / Guernsey Grid - EPSG:3108' },
  { value: '3109', label: 'ETRS89 / Jersey Transverse Mercator - EPSG:3109' },
  { value: '3110', label: 'AGD66 / Vicgrid66 - EPSG:3110' },
  { value: '3111', label: 'GDA94 / Vicgrid - EPSG:3111' },
  { value: '3112', label: 'GDA94 / Geoscience Australia Lambert - EPSG:3112' },
  { value: '3113', label: 'GDA94 / BCSG02 - EPSG:3113' },
  { value: '3114', label: 'MAGNA-SIRGAS / Colombia Far West zone - EPSG:3114' },
  { value: '3115', label: 'MAGNA-SIRGAS / Colombia West zone - EPSG:3115' },
  { value: '3116', label: 'MAGNA-SIRGAS / Colombia Bogota zone - EPSG:3116' },
  {
    value: '3117',
    label: 'MAGNA-SIRGAS / Colombia East Central zone - EPSG:3117',
  },
  { value: '3118', label: 'MAGNA-SIRGAS / Colombia East zone - EPSG:3118' },
  { value: '3119', label: 'Douala 1948 / AEF west - EPSG:3119' },
  { value: '3120', label: 'Pulkovo 1942(58) / Poland zone I - EPSG:3120' },
  { value: '3121', label: 'PRS92 / Philippines zone 1 - EPSG:3121' },
  { value: '3122', label: 'PRS92 / Philippines zone 2 - EPSG:3122' },
  { value: '3123', label: 'PRS92 / Philippines zone 3 - EPSG:3123' },
  { value: '3124', label: 'PRS92 / Philippines zone 4 - EPSG:3124' },
  { value: '3125', label: 'PRS92 / Philippines zone 5 - EPSG:3125' },
  { value: '3126', label: 'ETRS89 / ETRS-GK19FIN - EPSG:3126' },
  { value: '3127', label: 'ETRS89 / ETRS-GK20FIN - EPSG:3127' },
  { value: '3128', label: 'ETRS89 / ETRS-GK21FIN - EPSG:3128' },
  { value: '3129', label: 'ETRS89 / ETRS-GK22FIN - EPSG:3129' },
  { value: '3130', label: 'ETRS89 / ETRS-GK23FIN - EPSG:3130' },
  { value: '3131', label: 'ETRS89 / ETRS-GK24FIN - EPSG:3131' },
  { value: '3132', label: 'ETRS89 / ETRS-GK25FIN - EPSG:3132' },
  { value: '3133', label: 'ETRS89 / ETRS-GK26FIN - EPSG:3133' },
  { value: '3134', label: 'ETRS89 / ETRS-GK27FIN - EPSG:3134' },
  { value: '3135', label: 'ETRS89 / ETRS-GK28FIN - EPSG:3135' },
  { value: '3136', label: 'ETRS89 / ETRS-GK29FIN - EPSG:3136' },
  { value: '3137', label: 'ETRS89 / ETRS-GK30FIN - EPSG:3137' },
  { value: '3138', label: 'ETRS89 / ETRS-GK31FIN - EPSG:3138' },
  { value: '3139', label: 'Vanua Levu 1915 / Vanua Levu Grid - EPSG:3139' },
  { value: '3140', label: 'Viti Levu 1912 / Viti Levu Grid - EPSG:3140' },
  { value: '3141', label: 'Fiji 1956 / UTM zone 60S - EPSG:3141' },
  { value: '3142', label: 'Fiji 1956 / UTM zone 1S - EPSG:3142' },
  { value: '3144', label: 'FD54 / Faroe Lambert - EPSG:3144' },
  { value: '3145', label: 'ETRS89 / Faroe Lambert - EPSG:3145' },
  { value: '3148', label: 'Indian 1960 / UTM zone 48N - EPSG:3148' },
  { value: '3149', label: 'Indian 1960 / UTM zone 49N - EPSG:3149' },
  { value: '3152', label: 'ST74 - EPSG:3152' },
  { value: '3153', label: 'NAD83(CSRS) / BC Albers - EPSG:3153' },
  { value: '3154', label: 'NAD83(CSRS) / UTM zone 7N - EPSG:3154' },
  { value: '3155', label: 'NAD83(CSRS) / UTM zone 8N - EPSG:3155' },
  { value: '3156', label: 'NAD83(CSRS) / UTM zone 9N - EPSG:3156' },
  { value: '3157', label: 'NAD83(CSRS) / UTM zone 10N - EPSG:3157' },
  { value: '3158', label: 'NAD83(CSRS) / UTM zone 14N - EPSG:3158' },
  { value: '3159', label: 'NAD83(CSRS) / UTM zone 15N - EPSG:3159' },
  { value: '3160', label: 'NAD83(CSRS) / UTM zone 16N - EPSG:3160' },
  { value: '3161', label: 'NAD83 / Ontario MNR Lambert - EPSG:3161' },
  { value: '3162', label: 'NAD83(CSRS) / Ontario MNR Lambert - EPSG:3162' },
  { value: '3163', label: 'RGNC91-93 / Lambert New Caledonia - EPSG:3163' },
  { value: '3164', label: 'ST87 Ouvea / UTM zone 58S - EPSG:3164' },
  { value: '3165', label: 'NEA74 Noumea / Noumea Lambert - EPSG:3165' },
  { value: '3166', label: 'NEA74 Noumea / Noumea Lambert 2 - EPSG:3166' },
  { value: '3167', label: 'Kertau (RSO) / RSO Malaya (ch) - EPSG:3167' },
  { value: '3168', label: 'Kertau (RSO) / RSO Malaya (m) - EPSG:3168' },
  { value: '3169', label: 'RGNC91-93 / UTM zone 57S - EPSG:3169' },
  { value: '3170', label: 'RGNC91-93 / UTM zone 58S - EPSG:3170' },
  { value: '3171', label: 'RGNC91-93 / UTM zone 59S - EPSG:3171' },
  { value: '3172', label: 'IGN53 Mare / UTM zone 59S - EPSG:3172' },
  { value: '3173', label: 'fk89 / Faroe Lambert FK89 - EPSG:3173' },
  { value: '3174', label: 'NAD83 / Great Lakes Albers - EPSG:3174' },
  {
    value: '3175',
    label: 'NAD83 / Great Lakes and St Lawrence Albers - EPSG:3175',
  },
  { value: '3176', label: 'Indian 1960 / TM 106 NE - EPSG:3176' },
  { value: '3177', label: 'LGD2006 / Libya TM - EPSG:3177' },
  { value: '3178', label: 'GR96 / UTM zone 18N - EPSG:3178' },
  { value: '3179', label: 'GR96 / UTM zone 19N - EPSG:3179' },
  { value: '3180', label: 'GR96 / UTM zone 20N - EPSG:3180' },
  { value: '3181', label: 'GR96 / UTM zone 21N - EPSG:3181' },
  { value: '3182', label: 'GR96 / UTM zone 22N - EPSG:3182' },
  { value: '3183', label: 'GR96 / UTM zone 23N - EPSG:3183' },
  { value: '3184', label: 'GR96 / UTM zone 24N - EPSG:3184' },
  { value: '3185', label: 'GR96 / UTM zone 25N - EPSG:3185' },
  { value: '3186', label: 'GR96 / UTM zone 26N - EPSG:3186' },
  { value: '3187', label: 'GR96 / UTM zone 27N - EPSG:3187' },
  { value: '3188', label: 'GR96 / UTM zone 28N - EPSG:3188' },
  { value: '3189', label: 'GR96 / UTM zone 29N - EPSG:3189' },
  { value: '3190', label: 'LGD2006 / Libya TM zone 5 - EPSG:3190' },
  { value: '3191', label: 'LGD2006 / Libya TM zone 6 - EPSG:3191' },
  { value: '3192', label: 'LGD2006 / Libya TM zone 7 - EPSG:3192' },
  { value: '3193', label: 'LGD2006 / Libya TM zone 8 - EPSG:3193' },
  { value: '3194', label: 'LGD2006 / Libya TM zone 9 - EPSG:3194' },
  { value: '3195', label: 'LGD2006 / Libya TM zone 10 - EPSG:3195' },
  { value: '3196', label: 'LGD2006 / Libya TM zone 11 - EPSG:3196' },
  { value: '3197', label: 'LGD2006 / Libya TM zone 12 - EPSG:3197' },
  { value: '3198', label: 'LGD2006 / Libya TM zone 13 - EPSG:3198' },
  { value: '3199', label: 'LGD2006 / UTM zone 32N - EPSG:3199' },
  { value: '3200', label: 'FD58 / Iraq zone - EPSG:3200' },
  { value: '3201', label: 'LGD2006 / UTM zone 33N - EPSG:3201' },
  { value: '3202', label: 'LGD2006 / UTM zone 34N - EPSG:3202' },
  { value: '3203', label: 'LGD2006 / UTM zone 35N - EPSG:3203' },
  { value: '3204', label: 'WGS 84 / SCAR IMW SP19-20 - EPSG:3204' },
  { value: '3205', label: 'WGS 84 / SCAR IMW SP21-22 - EPSG:3205' },
  { value: '3206', label: 'WGS 84 / SCAR IMW SP23-24 - EPSG:3206' },
  { value: '3207', label: 'WGS 84 / SCAR IMW SQ01-02 - EPSG:3207' },
  { value: '3208', label: 'WGS 84 / SCAR IMW SQ19-20 - EPSG:3208' },
  { value: '3209', label: 'WGS 84 / SCAR IMW SQ21-22 - EPSG:3209' },
  { value: '3210', label: 'WGS 84 / SCAR IMW SQ37-38 - EPSG:3210' },
  { value: '3211', label: 'WGS 84 / SCAR IMW SQ39-40 - EPSG:3211' },
  { value: '3212', label: 'WGS 84 / SCAR IMW SQ41-42 - EPSG:3212' },
  { value: '3213', label: 'WGS 84 / SCAR IMW SQ43-44 - EPSG:3213' },
  { value: '3214', label: 'WGS 84 / SCAR IMW SQ45-46 - EPSG:3214' },
  { value: '3215', label: 'WGS 84 / SCAR IMW SQ47-48 - EPSG:3215' },
  { value: '3216', label: 'WGS 84 / SCAR IMW SQ49-50 - EPSG:3216' },
  { value: '3217', label: 'WGS 84 / SCAR IMW SQ51-52 - EPSG:3217' },
  { value: '3218', label: 'WGS 84 / SCAR IMW SQ53-54 - EPSG:3218' },
  { value: '3219', label: 'WGS 84 / SCAR IMW SQ55-56 - EPSG:3219' },
  { value: '3220', label: 'WGS 84 / SCAR IMW SQ57-58 - EPSG:3220' },
  { value: '3221', label: 'WGS 84 / SCAR IMW SR13-14 - EPSG:3221' },
  { value: '3222', label: 'WGS 84 / SCAR IMW SR15-16 - EPSG:3222' },
  { value: '3223', label: 'WGS 84 / SCAR IMW SR17-18 - EPSG:3223' },
  { value: '3224', label: 'WGS 84 / SCAR IMW SR19-20 - EPSG:3224' },
  { value: '3225', label: 'WGS 84 / SCAR IMW SR27-28 - EPSG:3225' },
  { value: '3226', label: 'WGS 84 / SCAR IMW SR29-30 - EPSG:3226' },
  { value: '3227', label: 'WGS 84 / SCAR IMW SR31-32 - EPSG:3227' },
  { value: '3228', label: 'WGS 84 / SCAR IMW SR33-34 - EPSG:3228' },
  { value: '3229', label: 'WGS 84 / SCAR IMW SR35-36 - EPSG:3229' },
  { value: '3230', label: 'WGS 84 / SCAR IMW SR37-38 - EPSG:3230' },
  { value: '3231', label: 'WGS 84 / SCAR IMW SR39-40 - EPSG:3231' },
  { value: '3232', label: 'WGS 84 / SCAR IMW SR41-42 - EPSG:3232' },
  { value: '3233', label: 'WGS 84 / SCAR IMW SR43-44 - EPSG:3233' },
  { value: '3234', label: 'WGS 84 / SCAR IMW SR45-46 - EPSG:3234' },
  { value: '3235', label: 'WGS 84 / SCAR IMW SR47-48 - EPSG:3235' },
  { value: '3236', label: 'WGS 84 / SCAR IMW SR49-50 - EPSG:3236' },
  { value: '3237', label: 'WGS 84 / SCAR IMW SR51-52 - EPSG:3237' },
  { value: '3238', label: 'WGS 84 / SCAR IMW SR53-54 - EPSG:3238' },
  { value: '3239', label: 'WGS 84 / SCAR IMW SR55-56 - EPSG:3239' },
  { value: '3240', label: 'WGS 84 / SCAR IMW SR57-58 - EPSG:3240' },
  { value: '3241', label: 'WGS 84 / SCAR IMW SR59-60 - EPSG:3241' },
  { value: '3242', label: 'WGS 84 / SCAR IMW SS04-06 - EPSG:3242' },
  { value: '3243', label: 'WGS 84 / SCAR IMW SS07-09 - EPSG:3243' },
  { value: '3244', label: 'WGS 84 / SCAR IMW SS10-12 - EPSG:3244' },
  { value: '3245', label: 'WGS 84 / SCAR IMW SS13-15 - EPSG:3245' },
  { value: '3246', label: 'WGS 84 / SCAR IMW SS16-18 - EPSG:3246' },
  { value: '3247', label: 'WGS 84 / SCAR IMW SS19-21 - EPSG:3247' },
  { value: '3248', label: 'WGS 84 / SCAR IMW SS25-27 - EPSG:3248' },
  { value: '3249', label: 'WGS 84 / SCAR IMW SS28-30 - EPSG:3249' },
  { value: '3250', label: 'WGS 84 / SCAR IMW SS31-33 - EPSG:3250' },
  { value: '3251', label: 'WGS 84 / SCAR IMW SS34-36 - EPSG:3251' },
  { value: '3252', label: 'WGS 84 / SCAR IMW SS37-39 - EPSG:3252' },
  { value: '3253', label: 'WGS 84 / SCAR IMW SS40-42 - EPSG:3253' },
  { value: '3254', label: 'WGS 84 / SCAR IMW SS43-45 - EPSG:3254' },
  { value: '3255', label: 'WGS 84 / SCAR IMW SS46-48 - EPSG:3255' },
  { value: '3256', label: 'WGS 84 / SCAR IMW SS49-51 - EPSG:3256' },
  { value: '3257', label: 'WGS 84 / SCAR IMW SS52-54 - EPSG:3257' },
  { value: '3258', label: 'WGS 84 / SCAR IMW SS55-57 - EPSG:3258' },
  { value: '3259', label: 'WGS 84 / SCAR IMW SS58-60 - EPSG:3259' },
  { value: '3260', label: 'WGS 84 / SCAR IMW ST01-04 - EPSG:3260' },
  { value: '3261', label: 'WGS 84 / SCAR IMW ST05-08 - EPSG:3261' },
  { value: '3262', label: 'WGS 84 / SCAR IMW ST09-12 - EPSG:3262' },
  { value: '3263', label: 'WGS 84 / SCAR IMW ST13-16 - EPSG:3263' },
  { value: '3264', label: 'WGS 84 / SCAR IMW ST17-20 - EPSG:3264' },
  { value: '3265', label: 'WGS 84 / SCAR IMW ST21-24 - EPSG:3265' },
  { value: '3266', label: 'WGS 84 / SCAR IMW ST25-28 - EPSG:3266' },
  { value: '3267', label: 'WGS 84 / SCAR IMW ST29-32 - EPSG:3267' },
  { value: '3268', label: 'WGS 84 / SCAR IMW ST33-36 - EPSG:3268' },
  { value: '3269', label: 'WGS 84 / SCAR IMW ST37-40 - EPSG:3269' },
  { value: '3270', label: 'WGS 84 / SCAR IMW ST41-44 - EPSG:3270' },
  { value: '3271', label: 'WGS 84 / SCAR IMW ST45-48 - EPSG:3271' },
  { value: '3272', label: 'WGS 84 / SCAR IMW ST49-52 - EPSG:3272' },
  { value: '3273', label: 'WGS 84 / SCAR IMW ST53-56 - EPSG:3273' },
  { value: '3274', label: 'WGS 84 / SCAR IMW ST57-60 - EPSG:3274' },
  { value: '3275', label: 'WGS 84 / SCAR IMW SU01-05 - EPSG:3275' },
  { value: '3276', label: 'WGS 84 / SCAR IMW SU06-10 - EPSG:3276' },
  { value: '3277', label: 'WGS 84 / SCAR IMW SU11-15 - EPSG:3277' },
  { value: '3278', label: 'WGS 84 / SCAR IMW SU16-20 - EPSG:3278' },
  { value: '3279', label: 'WGS 84 / SCAR IMW SU21-25 - EPSG:3279' },
  { value: '3280', label: 'WGS 84 / SCAR IMW SU26-30 - EPSG:3280' },
  { value: '3281', label: 'WGS 84 / SCAR IMW SU31-35 - EPSG:3281' },
  { value: '3282', label: 'WGS 84 / SCAR IMW SU36-40 - EPSG:3282' },
  { value: '3283', label: 'WGS 84 / SCAR IMW SU41-45 - EPSG:3283' },
  { value: '3284', label: 'WGS 84 / SCAR IMW SU46-50 - EPSG:3284' },
  { value: '3285', label: 'WGS 84 / SCAR IMW SU51-55 - EPSG:3285' },
  { value: '3286', label: 'WGS 84 / SCAR IMW SU56-60 - EPSG:3286' },
  { value: '3287', label: 'WGS 84 / SCAR IMW SV01-10 - EPSG:3287' },
  { value: '3288', label: 'WGS 84 / SCAR IMW SV11-20 - EPSG:3288' },
  { value: '3289', label: 'WGS 84 / SCAR IMW SV21-30 - EPSG:3289' },
  { value: '3290', label: 'WGS 84 / SCAR IMW SV31-40 - EPSG:3290' },
  { value: '3291', label: 'WGS 84 / SCAR IMW SV41-50 - EPSG:3291' },
  { value: '3292', label: 'WGS 84 / SCAR IMW SV51-60 - EPSG:3292' },
  { value: '3293', label: 'WGS 84 / SCAR IMW SW01-60 - EPSG:3293' },
  {
    value: '3294',
    label: 'WGS 84 / USGS Transantarctic Mountains - EPSG:3294',
  },
  { value: '3295', label: 'Guam 1963 / Yap Islands - EPSG:3295' },
  { value: '3296', label: 'RGPF / UTM zone 5S - EPSG:3296' },
  { value: '3297', label: 'RGPF / UTM zone 6S - EPSG:3297' },
  { value: '3298', label: 'RGPF / UTM zone 7S - EPSG:3298' },
  { value: '3299', label: 'RGPF / UTM zone 8S - EPSG:3299' },
  { value: '3300', label: 'Estonian Coordinate System of 1992 - EPSG:3300' },
  { value: '3301', label: 'Estonian Coordinate System of 1997 - EPSG:3301' },
  { value: '3302', label: 'IGN63 Hiva Oa / UTM zone 7S - EPSG:3302' },
  { value: '3303', label: 'Fatu Iva 72 / UTM zone 7S - EPSG:3303' },
  { value: '3304', label: 'Tahiti 79 / UTM zone 6S - EPSG:3304' },
  { value: '3305', label: 'Moorea 87 / UTM zone 6S - EPSG:3305' },
  { value: '3306', label: 'Maupiti 83 / UTM zone 5S - EPSG:3306' },
  { value: '3307', label: 'Nakhl-e Ghanem / UTM zone 39N - EPSG:3307' },
  { value: '3308', label: 'GDA94 / NSW Lambert - EPSG:3308' },
  { value: '3309', label: 'NAD27 / California Albers - EPSG:3309' },
  { value: '3310', label: 'NAD83 / California Albers - EPSG:3310' },
  { value: '3311', label: 'NAD83(HARN) / California Albers - EPSG:3311' },
  { value: '3312', label: 'CSG67 / UTM zone 21N - EPSG:3312' },
  { value: '3313', label: 'RGFG95 / UTM zone 21N - EPSG:3313' },
  { value: '3316', label: 'Kasai 1953 / Congo TM zone 22 - EPSG:3316' },
  { value: '3317', label: 'Kasai 1953 / Congo TM zone 24 - EPSG:3317' },
  { value: '3318', label: 'IGC 1962 / Congo TM zone 12 - EPSG:3318' },
  { value: '3319', label: 'IGC 1962 / Congo TM zone 14 - EPSG:3319' },
  { value: '3320', label: 'IGC 1962 / Congo TM zone 16 - EPSG:3320' },
  { value: '3321', label: 'IGC 1962 / Congo TM zone 18 - EPSG:3321' },
  { value: '3322', label: 'IGC 1962 / Congo TM zone 20 - EPSG:3322' },
  { value: '3323', label: 'IGC 1962 / Congo TM zone 22 - EPSG:3323' },
  { value: '3324', label: 'IGC 1962 / Congo TM zone 24 - EPSG:3324' },
  { value: '3325', label: 'IGC 1962 / Congo TM zone 26 - EPSG:3325' },
  { value: '3326', label: 'IGC 1962 / Congo TM zone 28 - EPSG:3326' },
  { value: '3327', label: 'IGC 1962 / Congo TM zone 30 - EPSG:3327' },
  { value: '3328', label: 'Pulkovo 1942(58) / GUGiK-80 - EPSG:3328' },
  {
    value: '3329',
    label: 'Pulkovo 1942(58) / 3-degree Gauss-Kruger zone 5 - EPSG:3329',
  },
  {
    value: '3330',
    label: 'Pulkovo 1942(58) / 3-degree Gauss-Kruger zone 6 - EPSG:3330',
  },
  {
    value: '3331',
    label: 'Pulkovo 1942(58) / 3-degree Gauss-Kruger zone 7 - EPSG:3331',
  },
  {
    value: '3332',
    label: 'Pulkovo 1942(58) / 3-degree Gauss-Kruger zone 8 - EPSG:3332',
  },
  {
    value: '3333',
    label: 'Pulkovo 1942(58) / Gauss-Kruger zone 3 - EPSG:3333',
  },
  {
    value: '3334',
    label: 'Pulkovo 1942(58) / Gauss-Kruger zone 4 - EPSG:3334',
  },
  {
    value: '3335',
    label: 'Pulkovo 1942(58) / Gauss-Kruger zone 5 - EPSG:3335',
  },
  { value: '3336', label: 'IGN 1962 Kerguelen / UTM zone 42S - EPSG:3336' },
  { value: '3337', label: 'Le Pouce 1934 / Mauritius Grid - EPSG:3337' },
  { value: '3338', label: 'NAD83 / Alaska Albers - EPSG:3338' },
  { value: '3339', label: 'IGCB 1955 / Congo TM zone 12 - EPSG:3339' },
  { value: '3340', label: 'IGCB 1955 / Congo TM zone 14 - EPSG:3340' },
  { value: '3341', label: 'IGCB 1955 / Congo TM zone 16 - EPSG:3341' },
  { value: '3342', label: 'IGCB 1955 / UTM zone 33S - EPSG:3342' },
  { value: '3343', label: 'Mauritania 1999 / UTM zone 28N - EPSG:3343' },
  { value: '3344', label: 'Mauritania 1999 / UTM zone 29N - EPSG:3344' },
  { value: '3345', label: 'Mauritania 1999 / UTM zone 30N - EPSG:3345' },
  { value: '3346', label: 'LKS94 / Lithuania TM - EPSG:3346' },
  { value: '3347', label: 'NAD83 / Statistics Canada Lambert - EPSG:3347' },
  {
    value: '3348',
    label: 'NAD83(CSRS) / Statistics Canada Lambert - EPSG:3348',
  },
  { value: '3350', label: 'Pulkovo 1942 / CS63 zone C0 - EPSG:3350' },
  { value: '3351', label: 'Pulkovo 1942 / CS63 zone C1 - EPSG:3351' },
  { value: '3352', label: 'Pulkovo 1942 / CS63 zone C2 - EPSG:3352' },
  { value: '3353', label: 'Mhast (onshore) / UTM zone 32S - EPSG:3353' },
  { value: '3354', label: 'Mhast (offshore) / UTM zone 32S - EPSG:3354' },
  {
    value: '3355',
    label: 'Egypt Gulf of Suez S-650 TL / Red Belt - EPSG:3355',
  },
  { value: '3358', label: 'NAD83(HARN) / North Carolina - EPSG:3358' },
  { value: '3360', label: 'NAD83(HARN) / South Carolina - EPSG:3360' },
  { value: '3361', label: 'NAD83(HARN) / South Carolina (ft) - EPSG:3361' },
  { value: '3362', label: 'NAD83(HARN) / Pennsylvania North - EPSG:3362' },
  {
    value: '3363',
    label: 'NAD83(HARN) / Pennsylvania North (ftUS) - EPSG:3363',
  },
  { value: '3364', label: 'NAD83(HARN) / Pennsylvania South - EPSG:3364' },
  {
    value: '3365',
    label: 'NAD83(HARN) / Pennsylvania South (ftUS) - EPSG:3365',
  },
  { value: '3367', label: 'IGN Astro 1960 / UTM zone 28N - EPSG:3367' },
  { value: '3368', label: 'IGN Astro 1960 / UTM zone 29N - EPSG:3368' },
  { value: '3369', label: 'IGN Astro 1960 / UTM zone 30N - EPSG:3369' },
  { value: '3370', label: 'NAD27 / UTM zone 59N - EPSG:3370' },
  { value: '3371', label: 'NAD27 / UTM zone 60N - EPSG:3371' },
  { value: '3372', label: 'NAD83 / UTM zone 59N - EPSG:3372' },
  { value: '3373', label: 'NAD83 / UTM zone 60N - EPSG:3373' },
  { value: '3374', label: 'FD54 / UTM zone 29N - EPSG:3374' },
  { value: '3375', label: 'GDM2000 / Peninsula RSO - EPSG:3375' },
  { value: '3376', label: 'GDM2000 / East Malaysia BRSO - EPSG:3376' },
  { value: '3377', label: 'GDM2000 / Johor Grid - EPSG:3377' },
  { value: '3378', label: 'GDM2000 / Sembilan and Melaka Grid - EPSG:3378' },
  { value: '3379', label: 'GDM2000 / Pahang Grid - EPSG:3379' },
  { value: '3380', label: 'GDM2000 / Selangor Grid - EPSG:3380' },
  { value: '3381', label: 'GDM2000 / Terengganu Grid - EPSG:3381' },
  { value: '3382', label: 'GDM2000 / Pinang Grid - EPSG:3382' },
  { value: '3383', label: 'GDM2000 / Kedah and Perlis Grid - EPSG:3383' },
  { value: '3384', label: 'GDM2000 / Perak Grid - EPSG:3384' },
  { value: '3385', label: 'GDM2000 / Kelantan Grid - EPSG:3385' },
  { value: '3386', label: 'KKJ / Finland zone 0 - EPSG:3386' },
  { value: '3387', label: 'KKJ / Finland zone 5 - EPSG:3387' },
  { value: '3388', label: 'Pulkovo 1942 / Caspian Sea Mercator - EPSG:3388' },
  {
    value: '3389',
    label: 'Pulkovo 1942 / 3-degree Gauss-Kruger zone 60 - EPSG:3389',
  },
  {
    value: '3390',
    label: 'Pulkovo 1995 / 3-degree Gauss-Kruger zone 60 - EPSG:3390',
  },
  { value: '3391', label: 'Karbala 1979 / UTM zone 37N - EPSG:3391' },
  { value: '3392', label: 'Karbala 1979 / UTM zone 38N - EPSG:3392' },
  { value: '3393', label: 'Karbala 1979 / UTM zone 39N - EPSG:3393' },
  { value: '3394', label: 'Nahrwan 1934 / Iraq zone - EPSG:3394' },
  { value: '3395', label: 'WGS 84 / World Mercator - EPSG:3395' },
  { value: '3396', label: 'PD/83 / 3-degree Gauss-Kruger zone 3 - EPSG:3396' },
  { value: '3397', label: 'PD/83 / 3-degree Gauss-Kruger zone 4 - EPSG:3397' },
  { value: '3398', label: 'RD/83 / 3-degree Gauss-Kruger zone 4 - EPSG:3398' },
  { value: '3399', label: 'RD/83 / 3-degree Gauss-Kruger zone 5 - EPSG:3399' },
  { value: '3400', label: 'NAD83 / Alberta 10-TM (Forest) - EPSG:3400' },
  { value: '3401', label: 'NAD83 / Alberta 10-TM (Resource) - EPSG:3401' },
  { value: '3402', label: 'NAD83(CSRS) / Alberta 10-TM (Forest) - EPSG:3402' },
  {
    value: '3403',
    label: 'NAD83(CSRS) / Alberta 10-TM (Resource) - EPSG:3403',
  },
  { value: '3404', label: 'NAD83(HARN) / North Carolina (ftUS) - EPSG:3404' },
  { value: '3405', label: 'VN-2000 / UTM zone 48N - EPSG:3405' },
  { value: '3406', label: 'VN-2000 / UTM zone 49N - EPSG:3406' },
  { value: '3407', label: 'Hong Kong 1963 Grid System - EPSG:3407' },
  { value: '3408', label: 'NSIDC EASE-Grid North - EPSG:3408' },
  { value: '3409', label: 'NSIDC EASE-Grid South - EPSG:3409' },
  { value: '3410', label: 'NSIDC EASE-Grid Global - EPSG:3410' },
  {
    value: '3411',
    label: 'NSIDC Sea Ice Polar Stereographic North - EPSG:3411',
  },
  {
    value: '3412',
    label: 'NSIDC Sea Ice Polar Stereographic South - EPSG:3412',
  },
  {
    value: '3413',
    label: 'WGS 84 / NSIDC Sea Ice Polar Stereographic North - EPSG:3413',
  },
  { value: '3414', label: 'SVY21 / Singapore TM - EPSG:3414' },
  { value: '3415', label: 'WGS 72BE / South China Sea Lambert - EPSG:3415' },
  { value: '3416', label: 'ETRS89 / Austria Lambert - EPSG:3416' },
  { value: '3417', label: 'NAD83 / Iowa North (ftUS) - EPSG:3417' },
  { value: '3418', label: 'NAD83 / Iowa South (ftUS) - EPSG:3418' },
  { value: '3419', label: 'NAD83 / Kansas North (ftUS) - EPSG:3419' },
  { value: '3420', label: 'NAD83 / Kansas South (ftUS) - EPSG:3420' },
  { value: '3421', label: 'NAD83 / Nevada East (ftUS) - EPSG:3421' },
  { value: '3422', label: 'NAD83 / Nevada Central (ftUS) - EPSG:3422' },
  { value: '3423', label: 'NAD83 / Nevada West (ftUS) - EPSG:3423' },
  { value: '3424', label: 'NAD83 / New Jersey (ftUS) - EPSG:3424' },
  { value: '3425', label: 'NAD83(HARN) / Iowa North (ftUS) - EPSG:3425' },
  { value: '3426', label: 'NAD83(HARN) / Iowa South (ftUS) - EPSG:3426' },
  { value: '3427', label: 'NAD83(HARN) / Kansas North (ftUS) - EPSG:3427' },
  { value: '3428', label: 'NAD83(HARN) / Kansas South (ftUS) - EPSG:3428' },
  { value: '3429', label: 'NAD83(HARN) / Nevada East (ftUS) - EPSG:3429' },
  { value: '3430', label: 'NAD83(HARN) / Nevada Central (ftUS) - EPSG:3430' },
  { value: '3431', label: 'NAD83(HARN) / Nevada West (ftUS) - EPSG:3431' },
  { value: '3432', label: 'NAD83(HARN) / New Jersey (ftUS) - EPSG:3432' },
  { value: '3433', label: 'NAD83 / Arkansas North (ftUS) - EPSG:3433' },
  { value: '3434', label: 'NAD83 / Arkansas South (ftUS) - EPSG:3434' },
  { value: '3435', label: 'NAD83 / Illinois East (ftUS) - EPSG:3435' },
  { value: '3436', label: 'NAD83 / Illinois West (ftUS) - EPSG:3436' },
  { value: '3437', label: 'NAD83 / New Hampshire (ftUS) - EPSG:3437' },
  { value: '3438', label: 'NAD83 / Rhode Island (ftUS) - EPSG:3438' },
  { value: '3439', label: 'PSD93 / UTM zone 39N - EPSG:3439' },
  { value: '3440', label: 'PSD93 / UTM zone 40N - EPSG:3440' },
  { value: '3441', label: 'NAD83(HARN) / Arkansas North (ftUS) - EPSG:3441' },
  { value: '3442', label: 'NAD83(HARN) / Arkansas South (ftUS) - EPSG:3442' },
  { value: '3443', label: 'NAD83(HARN) / Illinois East (ftUS) - EPSG:3443' },
  { value: '3444', label: 'NAD83(HARN) / Illinois West (ftUS) - EPSG:3444' },
  { value: '3445', label: 'NAD83(HARN) / New Hampshire (ftUS) - EPSG:3445' },
  { value: '3446', label: 'NAD83(HARN) / Rhode Island (ftUS) - EPSG:3446' },
  { value: '3447', label: 'ETRS89 / Belgian Lambert 2005 - EPSG:3447' },
  { value: '3448', label: 'JAD2001 / Jamaica Metric Grid - EPSG:3448' },
  { value: '3449', label: 'JAD2001 / UTM zone 17N - EPSG:3449' },
  { value: '3450', label: 'JAD2001 / UTM zone 18N - EPSG:3450' },
  { value: '3451', label: 'NAD83 / Louisiana North (ftUS) - EPSG:3451' },
  { value: '3452', label: 'NAD83 / Louisiana South (ftUS) - EPSG:3452' },
  { value: '3453', label: 'NAD83 / Louisiana Offshore (ftUS) - EPSG:3453' },
  { value: '3455', label: 'NAD83 / South Dakota South (ftUS) - EPSG:3455' },
  { value: '3456', label: 'NAD83(HARN) / Louisiana North (ftUS) - EPSG:3456' },
  { value: '3457', label: 'NAD83(HARN) / Louisiana South (ftUS) - EPSG:3457' },
  {
    value: '3458',
    label: 'NAD83(HARN) / South Dakota North (ftUS) - EPSG:3458',
  },
  {
    value: '3459',
    label: 'NAD83(HARN) / South Dakota South (ftUS) - EPSG:3459',
  },
  { value: '3460', label: 'Fiji 1986 / Fiji Map Grid - EPSG:3460' },
  { value: '3461', label: 'Dabola 1981 / UTM zone 28N - EPSG:3461' },
  { value: '3462', label: 'Dabola 1981 / UTM zone 29N - EPSG:3462' },
  { value: '3463', label: 'NAD83 / Maine CS2000 Central - EPSG:3463' },
  { value: '3464', label: 'NAD83(HARN) / Maine CS2000 Central - EPSG:3464' },
  { value: '3465', label: 'NAD83(NSRS2007) / Alabama East - EPSG:3465' },
  { value: '3466', label: 'NAD83(NSRS2007) / Alabama West - EPSG:3466' },
  { value: '3467', label: 'NAD83(NSRS2007) / Alaska Albers - EPSG:3467' },
  { value: '3468', label: 'NAD83(NSRS2007) / Alaska zone 1 - EPSG:3468' },
  { value: '3469', label: 'NAD83(NSRS2007) / Alaska zone 2 - EPSG:3469' },
  { value: '3470', label: 'NAD83(NSRS2007) / Alaska zone 3 - EPSG:3470' },
  { value: '3471', label: 'NAD83(NSRS2007) / Alaska zone 4 - EPSG:3471' },
  { value: '3472', label: 'NAD83(NSRS2007) / Alaska zone 5 - EPSG:3472' },
  { value: '3473', label: 'NAD83(NSRS2007) / Alaska zone 6 - EPSG:3473' },
  { value: '3474', label: 'NAD83(NSRS2007) / Alaska zone 7 - EPSG:3474' },
  { value: '3475', label: 'NAD83(NSRS2007) / Alaska zone 8 - EPSG:3475' },
  { value: '3476', label: 'NAD83(NSRS2007) / Alaska zone 9 - EPSG:3476' },
  { value: '3477', label: 'NAD83(NSRS2007) / Alaska zone 10 - EPSG:3477' },
  { value: '3478', label: 'NAD83(NSRS2007) / Arizona Central - EPSG:3478' },
  {
    value: '3479',
    label: 'NAD83(NSRS2007) / Arizona Central (ft) - EPSG:3479',
  },
  { value: '3480', label: 'NAD83(NSRS2007) / Arizona East - EPSG:3480' },
  { value: '3481', label: 'NAD83(NSRS2007) / Arizona East (ft) - EPSG:3481' },
  { value: '3482', label: 'NAD83(NSRS2007) / Arizona West - EPSG:3482' },
  { value: '3483', label: 'NAD83(NSRS2007) / Arizona West (ft) - EPSG:3483' },
  { value: '3484', label: 'NAD83(NSRS2007) / Arkansas North - EPSG:3484' },
  {
    value: '3485',
    label: 'NAD83(NSRS2007) / Arkansas North (ftUS) - EPSG:3485',
  },
  { value: '3486', label: 'NAD83(NSRS2007) / Arkansas South - EPSG:3486' },
  {
    value: '3487',
    label: 'NAD83(NSRS2007) / Arkansas South (ftUS) - EPSG:3487',
  },
  { value: '3488', label: 'NAD83(NSRS2007) / California Albers - EPSG:3488' },
  { value: '3489', label: 'NAD83(NSRS2007) / California zone 1 - EPSG:3489' },
  {
    value: '3490',
    label: 'NAD83(NSRS2007) / California zone 1 (ftUS) - EPSG:3490',
  },
  { value: '3491', label: 'NAD83(NSRS2007) / California zone 2 - EPSG:3491' },
  {
    value: '3492',
    label: 'NAD83(NSRS2007) / California zone 2 (ftUS) - EPSG:3492',
  },
  { value: '3493', label: 'NAD83(NSRS2007) / California zone 3 - EPSG:3493' },
  {
    value: '3494',
    label: 'NAD83(NSRS2007) / California zone 3 (ftUS) - EPSG:3494',
  },
  { value: '3495', label: 'NAD83(NSRS2007) / California zone 4 - EPSG:3495' },
  {
    value: '3496',
    label: 'NAD83(NSRS2007) / California zone 4 (ftUS) - EPSG:3496',
  },
  { value: '3497', label: 'NAD83(NSRS2007) / California zone 5 - EPSG:3497' },
  {
    value: '3498',
    label: 'NAD83(NSRS2007) / California zone 5 (ftUS) - EPSG:3498',
  },
  { value: '3499', label: 'NAD83(NSRS2007) / California zone 6 - EPSG:3499' },
  {
    value: '3500',
    label: 'NAD83(NSRS2007) / California zone 6 (ftUS) - EPSG:3500',
  },
  { value: '3501', label: 'NAD83(NSRS2007) / Colorado Central - EPSG:3501' },
  {
    value: '3502',
    label: 'NAD83(NSRS2007) / Colorado Central (ftUS) - EPSG:3502',
  },
  { value: '3503', label: 'NAD83(NSRS2007) / Colorado North - EPSG:3503' },
  {
    value: '3504',
    label: 'NAD83(NSRS2007) / Colorado North (ftUS) - EPSG:3504',
  },
  { value: '3505', label: 'NAD83(NSRS2007) / Colorado South - EPSG:3505' },
  {
    value: '3506',
    label: 'NAD83(NSRS2007) / Colorado South (ftUS) - EPSG:3506',
  },
  { value: '3507', label: 'NAD83(NSRS2007) / Connecticut - EPSG:3507' },
  { value: '3508', label: 'NAD83(NSRS2007) / Connecticut (ftUS) - EPSG:3508' },
  { value: '3509', label: 'NAD83(NSRS2007) / Delaware - EPSG:3509' },
  { value: '3510', label: 'NAD83(NSRS2007) / Delaware (ftUS) - EPSG:3510' },
  { value: '3511', label: 'NAD83(NSRS2007) / Florida East - EPSG:3511' },
  { value: '3512', label: 'NAD83(NSRS2007) / Florida East (ftUS) - EPSG:3512' },
  { value: '3513', label: 'NAD83(NSRS2007) / Florida GDL Albers - EPSG:3513' },
  { value: '3514', label: 'NAD83(NSRS2007) / Florida North - EPSG:3514' },
  {
    value: '3515',
    label: 'NAD83(NSRS2007) / Florida North (ftUS) - EPSG:3515',
  },
  { value: '3516', label: 'NAD83(NSRS2007) / Florida West - EPSG:3516' },
  { value: '3517', label: 'NAD83(NSRS2007) / Florida West (ftUS) - EPSG:3517' },
  { value: '3518', label: 'NAD83(NSRS2007) / Georgia East - EPSG:3518' },
  { value: '3519', label: 'NAD83(NSRS2007) / Georgia East (ftUS) - EPSG:3519' },
  { value: '3520', label: 'NAD83(NSRS2007) / Georgia West - EPSG:3520' },
  { value: '3521', label: 'NAD83(NSRS2007) / Georgia West (ftUS) - EPSG:3521' },
  { value: '3522', label: 'NAD83(NSRS2007) / Idaho Central - EPSG:3522' },
  {
    value: '3523',
    label: 'NAD83(NSRS2007) / Idaho Central (ftUS) - EPSG:3523',
  },
  { value: '3524', label: 'NAD83(NSRS2007) / Idaho East - EPSG:3524' },
  { value: '3525', label: 'NAD83(NSRS2007) / Idaho East (ftUS) - EPSG:3525' },
  { value: '3526', label: 'NAD83(NSRS2007) / Idaho West - EPSG:3526' },
  { value: '3527', label: 'NAD83(NSRS2007) / Idaho West (ftUS) - EPSG:3527' },
  { value: '3528', label: 'NAD83(NSRS2007) / Illinois East - EPSG:3528' },
  {
    value: '3529',
    label: 'NAD83(NSRS2007) / Illinois East (ftUS) - EPSG:3529',
  },
  { value: '3530', label: 'NAD83(NSRS2007) / Illinois West - EPSG:3530' },
  {
    value: '3531',
    label: 'NAD83(NSRS2007) / Illinois West (ftUS) - EPSG:3531',
  },
  { value: '3532', label: 'NAD83(NSRS2007) / Indiana East - EPSG:3532' },
  { value: '3533', label: 'NAD83(NSRS2007) / Indiana East (ftUS) - EPSG:3533' },
  { value: '3534', label: 'NAD83(NSRS2007) / Indiana West - EPSG:3534' },
  { value: '3535', label: 'NAD83(NSRS2007) / Indiana West (ftUS) - EPSG:3535' },
  { value: '3536', label: 'NAD83(NSRS2007) / Iowa North - EPSG:3536' },
  { value: '3537', label: 'NAD83(NSRS2007) / Iowa North (ftUS) - EPSG:3537' },
  { value: '3538', label: 'NAD83(NSRS2007) / Iowa South - EPSG:3538' },
  { value: '3539', label: 'NAD83(NSRS2007) / Iowa South (ftUS) - EPSG:3539' },
  { value: '3540', label: 'NAD83(NSRS2007) / Kansas North - EPSG:3540' },
  { value: '3541', label: 'NAD83(NSRS2007) / Kansas North (ftUS) - EPSG:3541' },
  { value: '3542', label: 'NAD83(NSRS2007) / Kansas South - EPSG:3542' },
  { value: '3543', label: 'NAD83(NSRS2007) / Kansas South (ftUS) - EPSG:3543' },
  { value: '3544', label: 'NAD83(NSRS2007) / Kentucky North - EPSG:3544' },
  {
    value: '3545',
    label: 'NAD83(NSRS2007) / Kentucky North (ftUS) - EPSG:3545',
  },
  {
    value: '3546',
    label: 'NAD83(NSRS2007) / Kentucky Single Zone - EPSG:3546',
  },
  {
    value: '3547',
    label: 'NAD83(NSRS2007) / Kentucky Single Zone (ftUS) - EPSG:3547',
  },
  { value: '3548', label: 'NAD83(NSRS2007) / Kentucky South - EPSG:3548' },
  {
    value: '3549',
    label: 'NAD83(NSRS2007) / Kentucky South (ftUS) - EPSG:3549',
  },
  { value: '3550', label: 'NAD83(NSRS2007) / Louisiana North - EPSG:3550' },
  {
    value: '3551',
    label: 'NAD83(NSRS2007) / Louisiana North (ftUS) - EPSG:3551',
  },
  { value: '3552', label: 'NAD83(NSRS2007) / Louisiana South - EPSG:3552' },
  {
    value: '3553',
    label: 'NAD83(NSRS2007) / Louisiana South (ftUS) - EPSG:3553',
  },
  {
    value: '3554',
    label: 'NAD83(NSRS2007) / Maine CS2000 Central - EPSG:3554',
  },
  { value: '3555', label: 'NAD83(NSRS2007) / Maine CS2000 East - EPSG:3555' },
  { value: '3556', label: 'NAD83(NSRS2007) / Maine CS2000 West - EPSG:3556' },
  { value: '3557', label: 'NAD83(NSRS2007) / Maine East - EPSG:3557' },
  { value: '3558', label: 'NAD83(NSRS2007) / Maine West - EPSG:3558' },
  { value: '3559', label: 'NAD83(NSRS2007) / Maryland - EPSG:3559' },
  { value: '3560', label: 'NAD83 / Utah North (ftUS) - EPSG:3560' },
  { value: '3561', label: 'Old Hawaiian / Hawaii zone 1 - EPSG:3561' },
  { value: '3562', label: 'Old Hawaiian / Hawaii zone 2 - EPSG:3562' },
  { value: '3563', label: 'Old Hawaiian / Hawaii zone 3 - EPSG:3563' },
  { value: '3564', label: 'Old Hawaiian / Hawaii zone 4 - EPSG:3564' },
  { value: '3565', label: 'Old Hawaiian / Hawaii zone 5 - EPSG:3565' },
  { value: '3566', label: 'NAD83 / Utah Central (ftUS) - EPSG:3566' },
  { value: '3567', label: 'NAD83 / Utah South (ftUS) - EPSG:3567' },
  { value: '3568', label: 'NAD83(HARN) / Utah North (ftUS) - EPSG:3568' },
  { value: '3569', label: 'NAD83(HARN) / Utah Central (ftUS) - EPSG:3569' },
  { value: '3570', label: 'NAD83(HARN) / Utah South (ftUS) - EPSG:3570' },
  { value: '3571', label: 'WGS 84 / North Pole LAEA Bering Sea - EPSG:3571' },
  { value: '3572', label: 'WGS 84 / North Pole LAEA Alaska - EPSG:3572' },
  { value: '3573', label: 'WGS 84 / North Pole LAEA Canada - EPSG:3573' },
  { value: '3574', label: 'WGS 84 / North Pole LAEA Atlantic - EPSG:3574' },
  { value: '3575', label: 'WGS 84 / North Pole LAEA Europe - EPSG:3575' },
  { value: '3576', label: 'WGS 84 / North Pole LAEA Russia - EPSG:3576' },
  { value: '3577', label: 'GDA94 / Australian Albers - EPSG:3577' },
  { value: '3578', label: 'NAD83 / Yukon Albers - EPSG:3578' },
  { value: '3579', label: 'NAD83(CSRS) / Yukon Albers - EPSG:3579' },
  { value: '3580', label: 'NAD83 / NWT Lambert - EPSG:3580' },
  { value: '3581', label: 'NAD83(CSRS) / NWT Lambert - EPSG:3581' },
  { value: '3582', label: 'NAD83(NSRS2007) / Maryland (ftUS) - EPSG:3582' },
  {
    value: '3583',
    label: 'NAD83(NSRS2007) / Massachusetts Island - EPSG:3583',
  },
  {
    value: '3584',
    label: 'NAD83(NSRS2007) / Massachusetts Island (ftUS) - EPSG:3584',
  },
  {
    value: '3585',
    label: 'NAD83(NSRS2007) / Massachusetts Mainland - EPSG:3585',
  },
  {
    value: '3586',
    label: 'NAD83(NSRS2007) / Massachusetts Mainland (ftUS) - EPSG:3586',
  },
  { value: '3587', label: 'NAD83(NSRS2007) / Michigan Central - EPSG:3587' },
  {
    value: '3588',
    label: 'NAD83(NSRS2007) / Michigan Central (ft) - EPSG:3588',
  },
  { value: '3589', label: 'NAD83(NSRS2007) / Michigan North - EPSG:3589' },
  { value: '3590', label: 'NAD83(NSRS2007) / Michigan North (ft) - EPSG:3590' },
  {
    value: '3591',
    label: 'NAD83(NSRS2007) / Michigan Oblique Mercator - EPSG:3591',
  },
  { value: '3592', label: 'NAD83(NSRS2007) / Michigan South - EPSG:3592' },
  { value: '3593', label: 'NAD83(NSRS2007) / Michigan South (ft) - EPSG:3593' },
  { value: '3594', label: 'NAD83(NSRS2007) / Minnesota Central - EPSG:3594' },
  { value: '3595', label: 'NAD83(NSRS2007) / Minnesota North - EPSG:3595' },
  { value: '3596', label: 'NAD83(NSRS2007) / Minnesota South - EPSG:3596' },
  { value: '3597', label: 'NAD83(NSRS2007) / Mississippi East - EPSG:3597' },
  {
    value: '3598',
    label: 'NAD83(NSRS2007) / Mississippi East (ftUS) - EPSG:3598',
  },
  { value: '3599', label: 'NAD83(NSRS2007) / Mississippi West - EPSG:3599' },
  {
    value: '3600',
    label: 'NAD83(NSRS2007) / Mississippi West (ftUS) - EPSG:3600',
  },
  { value: '3601', label: 'NAD83(NSRS2007) / Missouri Central - EPSG:3601' },
  { value: '3602', label: 'NAD83(NSRS2007) / Missouri East - EPSG:3602' },
  { value: '3603', label: 'NAD83(NSRS2007) / Missouri West - EPSG:3603' },
  { value: '3604', label: 'NAD83(NSRS2007) / Montana - EPSG:3604' },
  { value: '3605', label: 'NAD83(NSRS2007) / Montana (ft) - EPSG:3605' },
  { value: '3606', label: 'NAD83(NSRS2007) / Nebraska - EPSG:3606' },
  { value: '3607', label: 'NAD83(NSRS2007) / Nevada Central - EPSG:3607' },
  {
    value: '3608',
    label: 'NAD83(NSRS2007) / Nevada Central (ftUS) - EPSG:3608',
  },
  { value: '3609', label: 'NAD83(NSRS2007) / Nevada East - EPSG:3609' },
  { value: '3610', label: 'NAD83(NSRS2007) / Nevada East (ftUS) - EPSG:3610' },
  { value: '3611', label: 'NAD83(NSRS2007) / Nevada West - EPSG:3611' },
  { value: '3612', label: 'NAD83(NSRS2007) / Nevada West (ftUS) - EPSG:3612' },
  { value: '3613', label: 'NAD83(NSRS2007) / New Hampshire - EPSG:3613' },
  {
    value: '3614',
    label: 'NAD83(NSRS2007) / New Hampshire (ftUS) - EPSG:3614',
  },
  { value: '3615', label: 'NAD83(NSRS2007) / New Jersey - EPSG:3615' },
  { value: '3616', label: 'NAD83(NSRS2007) / New Jersey (ftUS) - EPSG:3616' },
  { value: '3617', label: 'NAD83(NSRS2007) / New Mexico Central - EPSG:3617' },
  {
    value: '3618',
    label: 'NAD83(NSRS2007) / New Mexico Central (ftUS) - EPSG:3618',
  },
  { value: '3619', label: 'NAD83(NSRS2007) / New Mexico East - EPSG:3619' },
  {
    value: '3620',
    label: 'NAD83(NSRS2007) / New Mexico East (ftUS) - EPSG:3620',
  },
  { value: '3621', label: 'NAD83(NSRS2007) / New Mexico West - EPSG:3621' },
  {
    value: '3622',
    label: 'NAD83(NSRS2007) / New Mexico West (ftUS) - EPSG:3622',
  },
  { value: '3623', label: 'NAD83(NSRS2007) / New York Central - EPSG:3623' },
  {
    value: '3624',
    label: 'NAD83(NSRS2007) / New York Central (ftUS) - EPSG:3624',
  },
  { value: '3625', label: 'NAD83(NSRS2007) / New York East - EPSG:3625' },
  {
    value: '3626',
    label: 'NAD83(NSRS2007) / New York East (ftUS) - EPSG:3626',
  },
  {
    value: '3627',
    label: 'NAD83(NSRS2007) / New York Long Island - EPSG:3627',
  },
  {
    value: '3628',
    label: 'NAD83(NSRS2007) / New York Long Island (ftUS) - EPSG:3628',
  },
  { value: '3629', label: 'NAD83(NSRS2007) / New York West - EPSG:3629' },
  {
    value: '3630',
    label: 'NAD83(NSRS2007) / New York West (ftUS) - EPSG:3630',
  },
  { value: '3631', label: 'NAD83(NSRS2007) / North Carolina - EPSG:3631' },
  {
    value: '3632',
    label: 'NAD83(NSRS2007) / North Carolina (ftUS) - EPSG:3632',
  },
  { value: '3633', label: 'NAD83(NSRS2007) / North Dakota North - EPSG:3633' },
  {
    value: '3634',
    label: 'NAD83(NSRS2007) / North Dakota North (ft) - EPSG:3634',
  },
  { value: '3635', label: 'NAD83(NSRS2007) / North Dakota South - EPSG:3635' },
  {
    value: '3636',
    label: 'NAD83(NSRS2007) / North Dakota South (ft) - EPSG:3636',
  },
  { value: '3637', label: 'NAD83(NSRS2007) / Ohio North - EPSG:3637' },
  { value: '3638', label: 'NAD83(NSRS2007) / Ohio South - EPSG:3638' },
  { value: '3639', label: 'NAD83(NSRS2007) / Oklahoma North - EPSG:3639' },
  {
    value: '3640',
    label: 'NAD83(NSRS2007) / Oklahoma North (ftUS) - EPSG:3640',
  },
  { value: '3641', label: 'NAD83(NSRS2007) / Oklahoma South - EPSG:3641' },
  {
    value: '3642',
    label: 'NAD83(NSRS2007) / Oklahoma South (ftUS) - EPSG:3642',
  },
  { value: '3643', label: 'NAD83(NSRS2007) / Oregon LCC (m) - EPSG:3643' },
  {
    value: '3644',
    label: 'NAD83(NSRS2007) / Oregon GIC Lambert (ft) - EPSG:3644',
  },
  { value: '3645', label: 'NAD83(NSRS2007) / Oregon North - EPSG:3645' },
  { value: '3646', label: 'NAD83(NSRS2007) / Oregon North (ft) - EPSG:3646' },
  { value: '3647', label: 'NAD83(NSRS2007) / Oregon South - EPSG:3647' },
  { value: '3648', label: 'NAD83(NSRS2007) / Oregon South (ft) - EPSG:3648' },
  { value: '3649', label: 'NAD83(NSRS2007) / Pennsylvania North - EPSG:3649' },
  {
    value: '3650',
    label: 'NAD83(NSRS2007) / Pennsylvania North (ftUS) - EPSG:3650',
  },
  { value: '3651', label: 'NAD83(NSRS2007) / Pennsylvania South - EPSG:3651' },
  {
    value: '3652',
    label: 'NAD83(NSRS2007) / Pennsylvania South (ftUS) - EPSG:3652',
  },
  { value: '3653', label: 'NAD83(NSRS2007) / Rhode Island - EPSG:3653' },
  { value: '3654', label: 'NAD83(NSRS2007) / Rhode Island (ftUS) - EPSG:3654' },
  { value: '3655', label: 'NAD83(NSRS2007) / South Carolina - EPSG:3655' },
  { value: '3656', label: 'NAD83(NSRS2007) / South Carolina (ft) - EPSG:3656' },
  { value: '3657', label: 'NAD83(NSRS2007) / South Dakota North - EPSG:3657' },
  {
    value: '3658',
    label: 'NAD83(NSRS2007) / South Dakota North (ftUS) - EPSG:3658',
  },
  { value: '3659', label: 'NAD83(NSRS2007) / South Dakota South - EPSG:3659' },
  {
    value: '3660',
    label: 'NAD83(NSRS2007) / South Dakota South (ftUS) - EPSG:3660',
  },
  { value: '3661', label: 'NAD83(NSRS2007) / Tennessee - EPSG:3661' },
  { value: '3662', label: 'NAD83(NSRS2007) / Tennessee (ftUS) - EPSG:3662' },
  { value: '3663', label: 'NAD83(NSRS2007) / Texas Central - EPSG:3663' },
  {
    value: '3664',
    label: 'NAD83(NSRS2007) / Texas Central (ftUS) - EPSG:3664',
  },
  {
    value: '3665',
    label: 'NAD83(NSRS2007) / Texas Centric Albers Equal Area - EPSG:3665',
  },
  {
    value: '3666',
    label: 'NAD83(NSRS2007) / Texas Centric Lambert Conformal - EPSG:3666',
  },
  { value: '3667', label: 'NAD83(NSRS2007) / Texas North - EPSG:3667' },
  { value: '3668', label: 'NAD83(NSRS2007) / Texas North (ftUS) - EPSG:3668' },
  { value: '3669', label: 'NAD83(NSRS2007) / Texas North Central - EPSG:3669' },
  {
    value: '3670',
    label: 'NAD83(NSRS2007) / Texas North Central (ftUS) - EPSG:3670',
  },
  { value: '3671', label: 'NAD83(NSRS2007) / Texas South - EPSG:3671' },
  { value: '3672', label: 'NAD83(NSRS2007) / Texas South (ftUS) - EPSG:3672' },
  { value: '3673', label: 'NAD83(NSRS2007) / Texas South Central - EPSG:3673' },
  {
    value: '3674',
    label: 'NAD83(NSRS2007) / Texas South Central (ftUS) - EPSG:3674',
  },
  { value: '3675', label: 'NAD83(NSRS2007) / Utah Central - EPSG:3675' },
  { value: '3676', label: 'NAD83(NSRS2007) / Utah Central (ft) - EPSG:3676' },
  { value: '3677', label: 'NAD83(NSRS2007) / Utah Central (ftUS) - EPSG:3677' },
  { value: '3678', label: 'NAD83(NSRS2007) / Utah North - EPSG:3678' },
  { value: '3679', label: 'NAD83(NSRS2007) / Utah North (ft) - EPSG:3679' },
  { value: '3680', label: 'NAD83(NSRS2007) / Utah North (ftUS) - EPSG:3680' },
  { value: '3681', label: 'NAD83(NSRS2007) / Utah South - EPSG:3681' },
  { value: '3682', label: 'NAD83(NSRS2007) / Utah South (ft) - EPSG:3682' },
  { value: '3683', label: 'NAD83(NSRS2007) / Utah South (ftUS) - EPSG:3683' },
  { value: '3684', label: 'NAD83(NSRS2007) / Vermont - EPSG:3684' },
  { value: '3685', label: 'NAD83(NSRS2007) / Virginia North - EPSG:3685' },
  {
    value: '3686',
    label: 'NAD83(NSRS2007) / Virginia North (ftUS) - EPSG:3686',
  },
  { value: '3687', label: 'NAD83(NSRS2007) / Virginia South - EPSG:3687' },
  {
    value: '3688',
    label: 'NAD83(NSRS2007) / Virginia South (ftUS) - EPSG:3688',
  },
  { value: '3689', label: 'NAD83(NSRS2007) / Washington North - EPSG:3689' },
  {
    value: '3690',
    label: 'NAD83(NSRS2007) / Washington North (ftUS) - EPSG:3690',
  },
  { value: '3691', label: 'NAD83(NSRS2007) / Washington South - EPSG:3691' },
  {
    value: '3692',
    label: 'NAD83(NSRS2007) / Washington South (ftUS) - EPSG:3692',
  },
  { value: '3693', label: 'NAD83(NSRS2007) / West Virginia North - EPSG:3693' },
  { value: '3694', label: 'NAD83(NSRS2007) / West Virginia South - EPSG:3694' },
  { value: '3695', label: 'NAD83(NSRS2007) / Wisconsin Central - EPSG:3695' },
  {
    value: '3696',
    label: 'NAD83(NSRS2007) / Wisconsin Central (ftUS) - EPSG:3696',
  },
  { value: '3697', label: 'NAD83(NSRS2007) / Wisconsin North - EPSG:3697' },
  {
    value: '3698',
    label: 'NAD83(NSRS2007) / Wisconsin North (ftUS) - EPSG:3698',
  },
  { value: '3699', label: 'NAD83(NSRS2007) / Wisconsin South - EPSG:3699' },
  {
    value: '3700',
    label: 'NAD83(NSRS2007) / Wisconsin South (ftUS) - EPSG:3700',
  },
  {
    value: '3701',
    label: 'NAD83(NSRS2007) / Wisconsin Transverse Mercator - EPSG:3701',
  },
  { value: '3702', label: 'NAD83(NSRS2007) / Wyoming East - EPSG:3702' },
  {
    value: '3703',
    label: 'NAD83(NSRS2007) / Wyoming East Central - EPSG:3703',
  },
  {
    value: '3704',
    label: 'NAD83(NSRS2007) / Wyoming West Central - EPSG:3704',
  },
  { value: '3705', label: 'NAD83(NSRS2007) / Wyoming West - EPSG:3705' },
  { value: '3706', label: 'NAD83(NSRS2007) / UTM zone 59N - EPSG:3706' },
  { value: '3707', label: 'NAD83(NSRS2007) / UTM zone 60N - EPSG:3707' },
  { value: '3708', label: 'NAD83(NSRS2007) / UTM zone 1N - EPSG:3708' },
  { value: '3709', label: 'NAD83(NSRS2007) / UTM zone 2N - EPSG:3709' },
  { value: '3710', label: 'NAD83(NSRS2007) / UTM zone 3N - EPSG:3710' },
  { value: '3711', label: 'NAD83(NSRS2007) / UTM zone 4N - EPSG:3711' },
  { value: '3712', label: 'NAD83(NSRS2007) / UTM zone 5N - EPSG:3712' },
  { value: '3713', label: 'NAD83(NSRS2007) / UTM zone 6N - EPSG:3713' },
  { value: '3714', label: 'NAD83(NSRS2007) / UTM zone 7N - EPSG:3714' },
  { value: '3715', label: 'NAD83(NSRS2007) / UTM zone 8N - EPSG:3715' },
  { value: '3716', label: 'NAD83(NSRS2007) / UTM zone 9N - EPSG:3716' },
  { value: '3717', label: 'NAD83(NSRS2007) / UTM zone 10N - EPSG:3717' },
  { value: '3718', label: 'NAD83(NSRS2007) / UTM zone 11N - EPSG:3718' },
  { value: '3719', label: 'NAD83(NSRS2007) / UTM zone 12N - EPSG:3719' },
  { value: '3720', label: 'NAD83(NSRS2007) / UTM zone 13N - EPSG:3720' },
  { value: '3721', label: 'NAD83(NSRS2007) / UTM zone 14N - EPSG:3721' },
  { value: '3722', label: 'NAD83(NSRS2007) / UTM zone 15N - EPSG:3722' },
  { value: '3723', label: 'NAD83(NSRS2007) / UTM zone 16N - EPSG:3723' },
  { value: '3724', label: 'NAD83(NSRS2007) / UTM zone 17N - EPSG:3724' },
  { value: '3725', label: 'NAD83(NSRS2007) / UTM zone 18N - EPSG:3725' },
  { value: '3726', label: 'NAD83(NSRS2007) / UTM zone 19N - EPSG:3726' },
  { value: '3727', label: 'Reunion 1947 / TM Reunion - EPSG:3727' },
  { value: '3728', label: 'NAD83(NSRS2007) / Ohio North (ftUS) - EPSG:3728' },
  { value: '3729', label: 'NAD83(NSRS2007) / Ohio South (ftUS) - EPSG:3729' },
  { value: '3730', label: 'NAD83(NSRS2007) / Wyoming East (ftUS) - EPSG:3730' },
  {
    value: '3731',
    label: 'NAD83(NSRS2007) / Wyoming East Central (ftUS) - EPSG:3731',
  },
  {
    value: '3732',
    label: 'NAD83(NSRS2007) / Wyoming West Central (ftUS) - EPSG:3732',
  },
  { value: '3733', label: 'NAD83(NSRS2007) / Wyoming West (ftUS) - EPSG:3733' },
  { value: '3734', label: 'NAD83 / Ohio North (ftUS) - EPSG:3734' },
  { value: '3735', label: 'NAD83 / Ohio South (ftUS) - EPSG:3735' },
  { value: '3736', label: 'NAD83 / Wyoming East (ftUS) - EPSG:3736' },
  { value: '3737', label: 'NAD83 / Wyoming East Central (ftUS) - EPSG:3737' },
  { value: '3738', label: 'NAD83 / Wyoming West Central (ftUS) - EPSG:3738' },
  { value: '3739', label: 'NAD83 / Wyoming West (ftUS) - EPSG:3739' },
  { value: '3740', label: 'NAD83(HARN) / UTM zone 10N - EPSG:3740' },
  { value: '3741', label: 'NAD83(HARN) / UTM zone 11N - EPSG:3741' },
  { value: '3742', label: 'NAD83(HARN) / UTM zone 12N - EPSG:3742' },
  { value: '3743', label: 'NAD83(HARN) / UTM zone 13N - EPSG:3743' },
  { value: '3744', label: 'NAD83(HARN) / UTM zone 14N - EPSG:3744' },
  { value: '3745', label: 'NAD83(HARN) / UTM zone 15N - EPSG:3745' },
  { value: '3746', label: 'NAD83(HARN) / UTM zone 16N - EPSG:3746' },
  { value: '3747', label: 'NAD83(HARN) / UTM zone 17N - EPSG:3747' },
  { value: '3748', label: 'NAD83(HARN) / UTM zone 18N - EPSG:3748' },
  { value: '3749', label: 'NAD83(HARN) / UTM zone 19N - EPSG:3749' },
  { value: '3750', label: 'NAD83(HARN) / UTM zone 4N - EPSG:3750' },
  { value: '3751', label: 'NAD83(HARN) / UTM zone 5N - EPSG:3751' },
  { value: '3753', label: 'NAD83(HARN) / Ohio North (ftUS) - EPSG:3753' },
  { value: '3754', label: 'NAD83(HARN) / Ohio South (ftUS) - EPSG:3754' },
  { value: '3755', label: 'NAD83(HARN) / Wyoming East (ftUS) - EPSG:3755' },
  {
    value: '3756',
    label: 'NAD83(HARN) / Wyoming East Central (ftUS) - EPSG:3756',
  },
  {
    value: '3757',
    label: 'NAD83(HARN) / Wyoming West Central (ftUS) - EPSG:3757',
  },
  { value: '3758', label: 'NAD83(HARN) / Wyoming West (ftUS) - EPSG:3758' },
  { value: '3759', label: 'NAD83 / Hawaii zone 3 (ftUS) - EPSG:3759' },
  { value: '3760', label: 'NAD83(HARN) / Hawaii zone 3 (ftUS) - EPSG:3760' },
  { value: '3761', label: 'NAD83(CSRS) / UTM zone 22N - EPSG:3761' },
  { value: '3762', label: 'WGS 84 / South Georgia Lambert - EPSG:3762' },
  { value: '3763', label: 'ETRS89 / Portugal TM06 - EPSG:3763' },
  {
    value: '3764',
    label: 'NZGD2000 / Chatham Island Circuit 2000 - EPSG:3764',
  },
  { value: '3765', label: 'HTRS96 / Croatia TM - EPSG:3765' },
  { value: '3766', label: 'HTRS96 / Croatia LCC - EPSG:3766' },
  { value: '3767', label: 'HTRS96 / UTM zone 33N - EPSG:3767' },
  { value: '3768', label: 'HTRS96 / UTM zone 34N - EPSG:3768' },
  { value: '3769', label: 'Bermuda 1957 / UTM zone 20N - EPSG:3769' },
  { value: '3770', label: 'BDA2000 / Bermuda 2000 National Grid - EPSG:3770' },
  { value: '3771', label: 'NAD27 / Alberta 3TM ref merid 111 W - EPSG:3771' },
  { value: '3772', label: 'NAD27 / Alberta 3TM ref merid 114 W - EPSG:3772' },
  { value: '3773', label: 'NAD27 / Alberta 3TM ref merid 117 W - EPSG:3773' },
  { value: '3775', label: 'NAD83 / Alberta 3TM ref merid 111 W - EPSG:3775' },
  { value: '3776', label: 'NAD83 / Alberta 3TM ref merid 114 W - EPSG:3776' },
  { value: '3777', label: 'NAD83 / Alberta 3TM ref merid 117 W - EPSG:3777' },
  {
    value: '3779',
    label: 'NAD83(CSRS) / Alberta 3TM ref merid 111 W - EPSG:3779',
  },
  {
    value: '3780',
    label: 'NAD83(CSRS) / Alberta 3TM ref merid 114 W - EPSG:3780',
  },
  {
    value: '3781',
    label: 'NAD83(CSRS) / Alberta 3TM ref merid 117 W - EPSG:3781',
  },
  { value: '3783', label: 'Pitcairn 2006 / Pitcairn TM 2006 - EPSG:3783' },
  { value: '3784', label: 'Pitcairn 1967 / UTM zone 9S - EPSG:3784' },
  { value: '3788', label: 'NZGD2000 / Auckland Islands TM 2000 - EPSG:3788' },
  { value: '3789', label: 'NZGD2000 / Campbell Island TM 2000 - EPSG:3789' },
  { value: '3790', label: 'NZGD2000 / Antipodes Islands TM 2000 - EPSG:3790' },
  { value: '3791', label: 'NZGD2000 / Raoul Island TM 2000 - EPSG:3791' },
  { value: '3793', label: 'NZGD2000 / Chatham Islands TM 2000 - EPSG:3793' },
  { value: '3794', label: 'Slovenia 1996 / Slovene National Grid - EPSG:3794' },
  { value: '3795', label: 'NAD27 / Cuba Norte - EPSG:3795' },
  { value: '3796', label: 'NAD27 / Cuba Sur - EPSG:3796' },
  { value: '3797', label: 'NAD27 / MTQ Lambert - EPSG:3797' },
  { value: '3798', label: 'NAD83 / MTQ Lambert - EPSG:3798' },
  { value: '3799', label: 'NAD83(CSRS) / MTQ Lambert - EPSG:3799' },
  { value: '3800', label: 'NAD27 / Alberta 3TM ref merid 120 W - EPSG:3800' },
  { value: '3801', label: 'NAD83 / Alberta 3TM ref merid 120 W - EPSG:3801' },
  {
    value: '3802',
    label: 'NAD83(CSRS) / Alberta 3TM ref merid 120 W - EPSG:3802',
  },
  { value: '3812', label: 'ETRS89 / Belgian Lambert 2008 - EPSG:3812' },
  { value: '3814', label: 'NAD83 / Mississippi TM - EPSG:3814' },
  { value: '3815', label: 'NAD83(HARN) / Mississippi TM - EPSG:3815' },
  { value: '3816', label: 'NAD83(NSRS2007) / Mississippi TM - EPSG:3816' },
  { value: '3825', label: 'TWD97 / TM2 zone 119 - EPSG:3825' },
  { value: '3826', label: 'TWD97 / TM2 zone 121 - EPSG:3826' },
  { value: '3827', label: 'TWD67 / TM2 zone 119 - EPSG:3827' },
  { value: '3828', label: 'TWD67 / TM2 zone 121 - EPSG:3828' },
  { value: '3829', label: 'Hu Tzu Shan 1950 / UTM zone 51N - EPSG:3829' },
  { value: '3832', label: 'WGS 84 / PDC Mercator - EPSG:3832' },
  {
    value: '3833',
    label: 'Pulkovo 1942(58) / Gauss-Kruger zone 2 - EPSG:3833',
  },
  {
    value: '3834',
    label: 'Pulkovo 1942(83) / Gauss-Kruger zone 2 - EPSG:3834',
  },
  {
    value: '3835',
    label: 'Pulkovo 1942(83) / Gauss-Kruger zone 3 - EPSG:3835',
  },
  {
    value: '3836',
    label: 'Pulkovo 1942(83) / Gauss-Kruger zone 4 - EPSG:3836',
  },
  {
    value: '3837',
    label: 'Pulkovo 1942(58) / 3-degree Gauss-Kruger zone 3 - EPSG:3837',
  },
  {
    value: '3838',
    label: 'Pulkovo 1942(58) / 3-degree Gauss-Kruger zone 4 - EPSG:3838',
  },
  {
    value: '3839',
    label: 'Pulkovo 1942(58) / 3-degree Gauss-Kruger zone 9 - EPSG:3839',
  },
  {
    value: '3840',
    label: 'Pulkovo 1942(58) / 3-degree Gauss-Kruger zone 10 - EPSG:3840',
  },
  {
    value: '3841',
    label: 'Pulkovo 1942(83) / 3-degree Gauss-Kruger zone 6 - EPSG:3841',
  },
  { value: '3844', label: 'Pulkovo 1942(58) / Stereo70 - EPSG:3844' },
  { value: '3845', label: 'SWEREF99 / RT90 7.5 gon V emulation - EPSG:3845' },
  { value: '3846', label: 'SWEREF99 / RT90 5 gon V emulation - EPSG:3846' },
  { value: '3847', label: 'SWEREF99 / RT90 2.5 gon V emulation - EPSG:3847' },
  { value: '3848', label: 'SWEREF99 / RT90 0 gon emulation - EPSG:3848' },
  { value: '3849', label: 'SWEREF99 / RT90 2.5 gon O emulation - EPSG:3849' },
  { value: '3850', label: 'SWEREF99 / RT90 5 gon O emulation - EPSG:3850' },
  { value: '3851', label: 'NZGD2000 / NZCS2000 - EPSG:3851' },
  { value: '3852', label: 'RSRGD2000 / DGLC2000 - EPSG:3852' },
  { value: '3854', label: 'County ST74 - EPSG:3854' },
  { value: '3857', label: 'WGS 84 / Pseudo-Mercator - EPSG:3857' },
  { value: '3873', label: 'ETRS89 / GK19FIN - EPSG:3873' },
  { value: '3874', label: 'ETRS89 / GK20FIN - EPSG:3874' },
  { value: '3875', label: 'ETRS89 / GK21FIN - EPSG:3875' },
  { value: '3876', label: 'ETRS89 / GK22FIN - EPSG:3876' },
  { value: '3877', label: 'ETRS89 / GK23FIN - EPSG:3877' },
  { value: '3878', label: 'ETRS89 / GK24FIN - EPSG:3878' },
  { value: '3879', label: 'ETRS89 / GK25FIN - EPSG:3879' },
  { value: '3880', label: 'ETRS89 / GK26FIN - EPSG:3880' },
  { value: '3881', label: 'ETRS89 / GK27FIN - EPSG:3881' },
  { value: '3882', label: 'ETRS89 / GK28FIN - EPSG:3882' },
  { value: '3883', label: 'ETRS89 / GK29FIN - EPSG:3883' },
  { value: '3884', label: 'ETRS89 / GK30FIN - EPSG:3884' },
  { value: '3885', label: 'ETRS89 / GK31FIN - EPSG:3885' },
  { value: '3890', label: 'IGRS / UTM zone 37N - EPSG:3890' },
  { value: '3891', label: 'IGRS / UTM zone 38N - EPSG:3891' },
  { value: '3892', label: 'IGRS / UTM zone 39N - EPSG:3892' },
  { value: '3893', label: 'ED50 / Iraq National Grid - EPSG:3893' },
  { value: '3907', label: 'MGI 1901 / Balkans zone 5 - EPSG:3907' },
  { value: '3908', label: 'MGI 1901 / Balkans zone 6 - EPSG:3908' },
  { value: '3909', label: 'MGI 1901 / Balkans zone 7 - EPSG:3909' },
  { value: '3910', label: 'MGI 1901 / Balkans zone 8 - EPSG:3910' },
  { value: '3911', label: 'MGI 1901 / Slovenia Grid - EPSG:3911' },
  { value: '3912', label: 'MGI 1901 / Slovene National Grid - EPSG:3912' },
  { value: '3920', label: 'Puerto Rico / UTM zone 20N - EPSG:3920' },
  { value: '3942', label: 'RGF93 / CC42 - EPSG:3942' },
  { value: '3943', label: 'RGF93 / CC43 - EPSG:3943' },
  { value: '3944', label: 'RGF93 / CC44 - EPSG:3944' },
  { value: '3945', label: 'RGF93 / CC45 - EPSG:3945' },
  { value: '3946', label: 'RGF93 / CC46 - EPSG:3946' },
  { value: '3947', label: 'RGF93 / CC47 - EPSG:3947' },
  { value: '3948', label: 'RGF93 / CC48 - EPSG:3948' },
  { value: '3949', label: 'RGF93 / CC49 - EPSG:3949' },
  { value: '3950', label: 'RGF93 / CC50 - EPSG:3950' },
  { value: '3968', label: 'NAD83 / Virginia Lambert - EPSG:3968' },
  { value: '3969', label: 'NAD83(HARN) / Virginia Lambert - EPSG:3969' },
  { value: '3970', label: 'NAD83(NSRS2007) / Virginia Lambert - EPSG:3970' },
  {
    value: '3976',
    label: 'WGS 84 / NSIDC Sea Ice Polar Stereographic South - EPSG:3976',
  },
  { value: '3978', label: 'NAD83 / Canada Atlas Lambert - EPSG:3978' },
  { value: '3979', label: 'NAD83(CSRS) / Canada Atlas Lambert - EPSG:3979' },
  { value: '3986', label: 'Katanga 1955 / Katanga Gauss zone A - EPSG:3986' },
  { value: '3987', label: 'Katanga 1955 / Katanga Gauss zone B - EPSG:3987' },
  { value: '3988', label: 'Katanga 1955 / Katanga Gauss zone C - EPSG:3988' },
  { value: '3989', label: 'Katanga 1955 / Katanga Gauss zone D - EPSG:3989' },
  { value: '3991', label: 'Puerto Rico State Plane CS of 1927 - EPSG:3991' },
  { value: '3992', label: 'Puerto Rico / St. Croix - EPSG:3992' },
  { value: '3993', label: 'Guam 1963 / Guam SPCS - EPSG:3993' },
  { value: '3994', label: 'WGS 84 / Mercator 41 - EPSG:3994' },
  { value: '3995', label: 'WGS 84 / Arctic Polar Stereographic - EPSG:3995' },
  { value: '3996', label: 'WGS 84 / IBCAO Polar Stereographic - EPSG:3996' },
  { value: '3997', label: 'WGS 84 / Dubai Local TM - EPSG:3997' },
  { value: '4026', label: 'MOLDREF99 / Moldova TM - EPSG:4026' },
  { value: '4037', label: 'WGS 84 / TMzn35N - EPSG:4037' },
  { value: '4038', label: 'WGS 84 / TMzn36N - EPSG:4038' },
  { value: '4048', label: 'RGRDC 2005 / Congo TM zone 12 - EPSG:4048' },
  { value: '4049', label: 'RGRDC 2005 / Congo TM zone 14 - EPSG:4049' },
  { value: '4050', label: 'RGRDC 2005 / Congo TM zone 16 - EPSG:4050' },
  { value: '4051', label: 'RGRDC 2005 / Congo TM zone 18 - EPSG:4051' },
  { value: '4056', label: 'RGRDC 2005 / Congo TM zone 20 - EPSG:4056' },
  { value: '4057', label: 'RGRDC 2005 / Congo TM zone 22 - EPSG:4057' },
  { value: '4058', label: 'RGRDC 2005 / Congo TM zone 24 - EPSG:4058' },
  { value: '4059', label: 'RGRDC 2005 / Congo TM zone 26 - EPSG:4059' },
  { value: '4060', label: 'RGRDC 2005 / Congo TM zone 28 - EPSG:4060' },
  { value: '4061', label: 'RGRDC 2005 / UTM zone 33S - EPSG:4061' },
  { value: '4062', label: 'RGRDC 2005 / UTM zone 34S - EPSG:4062' },
  { value: '4063', label: 'RGRDC 2005 / UTM zone 35S - EPSG:4063' },
  { value: '4071', label: 'Chua / UTM zone 23S - EPSG:4071' },
  { value: '4082', label: 'REGCAN95 / UTM zone 27N - EPSG:4082' },
  { value: '4083', label: 'REGCAN95 / UTM zone 28N - EPSG:4083' },
  {
    value: '4087',
    label: 'WGS 84 / World Equidistant Cylindrical - EPSG:4087',
  },
  {
    value: '4088',
    label: 'World Equidistant Cylindrical (Sphere) - EPSG:4088',
  },
  { value: '4093', label: 'ETRS89 / DKTM1 - EPSG:4093' },
  { value: '4094', label: 'ETRS89 / DKTM2 - EPSG:4094' },
  { value: '4095', label: 'ETRS89 / DKTM3 - EPSG:4095' },
  { value: '4096', label: 'ETRS89 / DKTM4 - EPSG:4096' },
  { value: '4217', label: 'NAD83 / BLM 59N (ftUS) - EPSG:4217' },
  { value: '4390', label: 'Kertau 1968 / Johor Grid - EPSG:4390' },
  {
    value: '4391',
    label: 'Kertau 1968 / Sembilan and Melaka Grid - EPSG:4391',
  },
  { value: '4392', label: 'Kertau 1968 / Pahang Grid - EPSG:4392' },
  { value: '4393', label: 'Kertau 1968 / Selangor Grid - EPSG:4393' },
  { value: '4394', label: 'Kertau 1968 / Terengganu Grid - EPSG:4394' },
  { value: '4395', label: 'Kertau 1968 / Pinang Grid - EPSG:4395' },
  { value: '4396', label: 'Kertau 1968 / Kedah and Perlis Grid - EPSG:4396' },
  { value: '4397', label: 'Kertau 1968 / Perak Revised Grid - EPSG:4397' },
  { value: '4398', label: 'Kertau 1968 / Kelantan Grid - EPSG:4398' },
  { value: '4399', label: 'NAD27 / BLM 59N (ftUS) - EPSG:4399' },
  { value: '4400', label: 'NAD27 / BLM 60N (ftUS) - EPSG:4400' },
  { value: '4401', label: 'NAD27 / BLM 1N (ftUS) - EPSG:4401' },
  { value: '4402', label: 'NAD27 / BLM 2N (ftUS) - EPSG:4402' },
  { value: '4403', label: 'NAD27 / BLM 3N (ftUS) - EPSG:4403' },
  { value: '4404', label: 'NAD27 / BLM 4N (ftUS) - EPSG:4404' },
  { value: '4405', label: 'NAD27 / BLM 5N (ftUS) - EPSG:4405' },
  { value: '4406', label: 'NAD27 / BLM 6N (ftUS) - EPSG:4406' },
  { value: '4407', label: 'NAD27 / BLM 7N (ftUS) - EPSG:4407' },
  { value: '4408', label: 'NAD27 / BLM 8N (ftUS) - EPSG:4408' },
  { value: '4409', label: 'NAD27 / BLM 9N (ftUS) - EPSG:4409' },
  { value: '4410', label: 'NAD27 / BLM 10N (ftUS) - EPSG:4410' },
  { value: '4411', label: 'NAD27 / BLM 11N (ftUS) - EPSG:4411' },
  { value: '4412', label: 'NAD27 / BLM 12N (ftUS) - EPSG:4412' },
  { value: '4413', label: 'NAD27 / BLM 13N (ftUS) - EPSG:4413' },
  { value: '4414', label: 'NAD83(HARN) / Guam Map Grid - EPSG:4414' },
  { value: '4415', label: 'Katanga 1955 / Katanga Lambert - EPSG:4415' },
  {
    value: '4417',
    label: 'Pulkovo 1942(83) / 3-degree Gauss-Kruger zone 7 - EPSG:4417',
  },
  { value: '4418', label: 'NAD27 / BLM 18N (ftUS) - EPSG:4418' },
  { value: '4419', label: 'NAD27 / BLM 19N (ftUS) - EPSG:4419' },
  { value: '4420', label: 'NAD83 / BLM 60N (ftUS) - EPSG:4420' },
  { value: '4421', label: 'NAD83 / BLM 1N (ftUS) - EPSG:4421' },
  { value: '4422', label: 'NAD83 / BLM 2N (ftUS) - EPSG:4422' },
  { value: '4423', label: 'NAD83 / BLM 3N (ftUS) - EPSG:4423' },
  { value: '4424', label: 'NAD83 / BLM 4N (ftUS) - EPSG:4424' },
  { value: '4425', label: 'NAD83 / BLM 5N (ftUS) - EPSG:4425' },
  { value: '4426', label: 'NAD83 / BLM 6N (ftUS) - EPSG:4426' },
  { value: '4427', label: 'NAD83 / BLM 7N (ftUS) - EPSG:4427' },
  { value: '4428', label: 'NAD83 / BLM 8N (ftUS) - EPSG:4428' },
  { value: '4429', label: 'NAD83 / BLM 9N (ftUS) - EPSG:4429' },
  { value: '4430', label: 'NAD83 / BLM 10N (ftUS) - EPSG:4430' },
  { value: '4431', label: 'NAD83 / BLM 11N (ftUS) - EPSG:4431' },
  { value: '4432', label: 'NAD83 / BLM 12N (ftUS) - EPSG:4432' },
  { value: '4433', label: 'NAD83 / BLM 13N (ftUS) - EPSG:4433' },
  {
    value: '4434',
    label: 'Pulkovo 1942(83) / 3-degree Gauss-Kruger zone 8 - EPSG:4434',
  },
  {
    value: '4437',
    label: 'NAD83(NSRS2007) / Puerto Rico and Virgin Is. - EPSG:4437',
  },
  { value: '4438', label: 'NAD83 / BLM 18N (ftUS) - EPSG:4438' },
  { value: '4439', label: 'NAD83 / BLM 19N (ftUS) - EPSG:4439' },
  { value: '4455', label: 'NAD27 / Pennsylvania South - EPSG:4455' },
  { value: '4456', label: 'NAD27 / New York Long Island - EPSG:4456' },
  { value: '4457', label: 'NAD83 / South Dakota North (ftUS) - EPSG:4457' },
  {
    value: '4462',
    label: 'WGS 84 / Australian Centre for Remote Sensing Lambert - EPSG:4462',
  },
  { value: '4467', label: 'RGSPM06 / UTM zone 21N - EPSG:4467' },
  { value: '4471', label: 'RGM04 / UTM zone 38S - EPSG:4471' },
  { value: '4484', label: 'Mexico ITRF92 / UTM zone 11N - EPSG:4484' },
  { value: '4485', label: 'Mexico ITRF92 / UTM zone 12N - EPSG:4485' },
  { value: '4486', label: 'Mexico ITRF92 / UTM zone 13N - EPSG:4486' },
  { value: '4487', label: 'Mexico ITRF92 / UTM zone 14N - EPSG:4487' },
  { value: '4488', label: 'Mexico ITRF92 / UTM zone 15N - EPSG:4488' },
  { value: '4489', label: 'Mexico ITRF92 / UTM zone 16N - EPSG:4489' },
  { value: '4491', label: 'CGCS2000 / Gauss-Kruger zone 13 - EPSG:4491' },
  { value: '4492', label: 'CGCS2000 / Gauss-Kruger zone 14 - EPSG:4492' },
  { value: '4493', label: 'CGCS2000 / Gauss-Kruger zone 15 - EPSG:4493' },
  { value: '4494', label: 'CGCS2000 / Gauss-Kruger zone 16 - EPSG:4494' },
  { value: '4495', label: 'CGCS2000 / Gauss-Kruger zone 17 - EPSG:4495' },
  { value: '4496', label: 'CGCS2000 / Gauss-Kruger zone 18 - EPSG:4496' },
  { value: '4497', label: 'CGCS2000 / Gauss-Kruger zone 19 - EPSG:4497' },
  { value: '4498', label: 'CGCS2000 / Gauss-Kruger zone 20 - EPSG:4498' },
  { value: '4499', label: 'CGCS2000 / Gauss-Kruger zone 21 - EPSG:4499' },
  { value: '4500', label: 'CGCS2000 / Gauss-Kruger zone 22 - EPSG:4500' },
  { value: '4501', label: 'CGCS2000 / Gauss-Kruger zone 23 - EPSG:4501' },
  { value: '4502', label: 'CGCS2000 / Gauss-Kruger CM 75E - EPSG:4502' },
  { value: '4503', label: 'CGCS2000 / Gauss-Kruger CM 81E - EPSG:4503' },
  { value: '4504', label: 'CGCS2000 / Gauss-Kruger CM 87E - EPSG:4504' },
  { value: '4505', label: 'CGCS2000 / Gauss-Kruger CM 93E - EPSG:4505' },
  { value: '4506', label: 'CGCS2000 / Gauss-Kruger CM 99E - EPSG:4506' },
  { value: '4507', label: 'CGCS2000 / Gauss-Kruger CM 105E - EPSG:4507' },
  { value: '4508', label: 'CGCS2000 / Gauss-Kruger CM 111E - EPSG:4508' },
  { value: '4509', label: 'CGCS2000 / Gauss-Kruger CM 117E - EPSG:4509' },
  { value: '4510', label: 'CGCS2000 / Gauss-Kruger CM 123E - EPSG:4510' },
  { value: '4511', label: 'CGCS2000 / Gauss-Kruger CM 129E - EPSG:4511' },
  { value: '4512', label: 'CGCS2000 / Gauss-Kruger CM 135E - EPSG:4512' },
  {
    value: '4513',
    label: 'CGCS2000 / 3-degree Gauss-Kruger zone 25 - EPSG:4513',
  },
  {
    value: '4514',
    label: 'CGCS2000 / 3-degree Gauss-Kruger zone 26 - EPSG:4514',
  },
  {
    value: '4515',
    label: 'CGCS2000 / 3-degree Gauss-Kruger zone 27 - EPSG:4515',
  },
  {
    value: '4516',
    label: 'CGCS2000 / 3-degree Gauss-Kruger zone 28 - EPSG:4516',
  },
  {
    value: '4517',
    label: 'CGCS2000 / 3-degree Gauss-Kruger zone 29 - EPSG:4517',
  },
  {
    value: '4518',
    label: 'CGCS2000 / 3-degree Gauss-Kruger zone 30 - EPSG:4518',
  },
  {
    value: '4519',
    label: 'CGCS2000 / 3-degree Gauss-Kruger zone 31 - EPSG:4519',
  },
  {
    value: '4520',
    label: 'CGCS2000 / 3-degree Gauss-Kruger zone 32 - EPSG:4520',
  },
  {
    value: '4521',
    label: 'CGCS2000 / 3-degree Gauss-Kruger zone 33 - EPSG:4521',
  },
  {
    value: '4522',
    label: 'CGCS2000 / 3-degree Gauss-Kruger zone 34 - EPSG:4522',
  },
  {
    value: '4523',
    label: 'CGCS2000 / 3-degree Gauss-Kruger zone 35 - EPSG:4523',
  },
  {
    value: '4524',
    label: 'CGCS2000 / 3-degree Gauss-Kruger zone 36 - EPSG:4524',
  },
  {
    value: '4525',
    label: 'CGCS2000 / 3-degree Gauss-Kruger zone 37 - EPSG:4525',
  },
  {
    value: '4526',
    label: 'CGCS2000 / 3-degree Gauss-Kruger zone 38 - EPSG:4526',
  },
  {
    value: '4527',
    label: 'CGCS2000 / 3-degree Gauss-Kruger zone 39 - EPSG:4527',
  },
  {
    value: '4528',
    label: 'CGCS2000 / 3-degree Gauss-Kruger zone 40 - EPSG:4528',
  },
  {
    value: '4529',
    label: 'CGCS2000 / 3-degree Gauss-Kruger zone 41 - EPSG:4529',
  },
  {
    value: '4530',
    label: 'CGCS2000 / 3-degree Gauss-Kruger zone 42 - EPSG:4530',
  },
  {
    value: '4531',
    label: 'CGCS2000 / 3-degree Gauss-Kruger zone 43 - EPSG:4531',
  },
  {
    value: '4532',
    label: 'CGCS2000 / 3-degree Gauss-Kruger zone 44 - EPSG:4532',
  },
  {
    value: '4533',
    label: 'CGCS2000 / 3-degree Gauss-Kruger zone 45 - EPSG:4533',
  },
  {
    value: '4534',
    label: 'CGCS2000 / 3-degree Gauss-Kruger CM 75E - EPSG:4534',
  },
  {
    value: '4535',
    label: 'CGCS2000 / 3-degree Gauss-Kruger CM 78E - EPSG:4535',
  },
  {
    value: '4536',
    label: 'CGCS2000 / 3-degree Gauss-Kruger CM 81E - EPSG:4536',
  },
  {
    value: '4537',
    label: 'CGCS2000 / 3-degree Gauss-Kruger CM 84E - EPSG:4537',
  },
  {
    value: '4538',
    label: 'CGCS2000 / 3-degree Gauss-Kruger CM 87E - EPSG:4538',
  },
  {
    value: '4539',
    label: 'CGCS2000 / 3-degree Gauss-Kruger CM 90E - EPSG:4539',
  },
  {
    value: '4540',
    label: 'CGCS2000 / 3-degree Gauss-Kruger CM 93E - EPSG:4540',
  },
  {
    value: '4541',
    label: 'CGCS2000 / 3-degree Gauss-Kruger CM 96E - EPSG:4541',
  },
  {
    value: '4542',
    label: 'CGCS2000 / 3-degree Gauss-Kruger CM 99E - EPSG:4542',
  },
  {
    value: '4543',
    label: 'CGCS2000 / 3-degree Gauss-Kruger CM 102E - EPSG:4543',
  },
  {
    value: '4544',
    label: 'CGCS2000 / 3-degree Gauss-Kruger CM 105E - EPSG:4544',
  },
  {
    value: '4545',
    label: 'CGCS2000 / 3-degree Gauss-Kruger CM 108E - EPSG:4545',
  },
  {
    value: '4546',
    label: 'CGCS2000 / 3-degree Gauss-Kruger CM 111E - EPSG:4546',
  },
  {
    value: '4547',
    label: 'CGCS2000 / 3-degree Gauss-Kruger CM 114E - EPSG:4547',
  },
  {
    value: '4548',
    label: 'CGCS2000 / 3-degree Gauss-Kruger CM 117E - EPSG:4548',
  },
  {
    value: '4549',
    label: 'CGCS2000 / 3-degree Gauss-Kruger CM 120E - EPSG:4549',
  },
  {
    value: '4550',
    label: 'CGCS2000 / 3-degree Gauss-Kruger CM 123E - EPSG:4550',
  },
  {
    value: '4551',
    label: 'CGCS2000 / 3-degree Gauss-Kruger CM 126E - EPSG:4551',
  },
  {
    value: '4552',
    label: 'CGCS2000 / 3-degree Gauss-Kruger CM 129E - EPSG:4552',
  },
  {
    value: '4553',
    label: 'CGCS2000 / 3-degree Gauss-Kruger CM 132E - EPSG:4553',
  },
  {
    value: '4554',
    label: 'CGCS2000 / 3-degree Gauss-Kruger CM 135E - EPSG:4554',
  },
  { value: '4559', label: 'RRAF 1991 / UTM zone 20N - EPSG:4559' },
  { value: '4568', label: 'New Beijing / Gauss-Kruger zone 13 - EPSG:4568' },
  { value: '4569', label: 'New Beijing / Gauss-Kruger zone 14 - EPSG:4569' },
  { value: '4570', label: 'New Beijing / Gauss-Kruger zone 15 - EPSG:4570' },
  { value: '4571', label: 'New Beijing / Gauss-Kruger zone 16 - EPSG:4571' },
  { value: '4572', label: 'New Beijing / Gauss-Kruger zone 17 - EPSG:4572' },
  { value: '4573', label: 'New Beijing / Gauss-Kruger zone 18 - EPSG:4573' },
  { value: '4574', label: 'New Beijing / Gauss-Kruger zone 19 - EPSG:4574' },
  { value: '4575', label: 'New Beijing / Gauss-Kruger zone 20 - EPSG:4575' },
  { value: '4576', label: 'New Beijing / Gauss-Kruger zone 21 - EPSG:4576' },
  { value: '4577', label: 'New Beijing / Gauss-Kruger zone 22 - EPSG:4577' },
  { value: '4578', label: 'New Beijing / Gauss-Kruger zone 23 - EPSG:4578' },
  { value: '4579', label: 'New Beijing / Gauss-Kruger CM 75E - EPSG:4579' },
  { value: '4580', label: 'New Beijing / Gauss-Kruger CM 81E - EPSG:4580' },
  { value: '4581', label: 'New Beijing / Gauss-Kruger CM 87E - EPSG:4581' },
  { value: '4582', label: 'New Beijing / Gauss-Kruger CM 93E - EPSG:4582' },
  { value: '4583', label: 'New Beijing / Gauss-Kruger CM 99E - EPSG:4583' },
  { value: '4584', label: 'New Beijing / Gauss-Kruger CM 105E - EPSG:4584' },
  { value: '4585', label: 'New Beijing / Gauss-Kruger CM 111E - EPSG:4585' },
  { value: '4586', label: 'New Beijing / Gauss-Kruger CM 117E - EPSG:4586' },
  { value: '4587', label: 'New Beijing / Gauss-Kruger CM 123E - EPSG:4587' },
  { value: '4588', label: 'New Beijing / Gauss-Kruger CM 129E - EPSG:4588' },
  { value: '4589', label: 'New Beijing / Gauss-Kruger CM 135E - EPSG:4589' },
  { value: '4647', label: 'ETRS89 / UTM zone 32N (zE-N) - EPSG:4647' },
  {
    value: '4652',
    label: 'New Beijing / 3-degree Gauss-Kruger zone 25 - EPSG:4652',
  },
  {
    value: '4653',
    label: 'New Beijing / 3-degree Gauss-Kruger zone 26 - EPSG:4653',
  },
  {
    value: '4654',
    label: 'New Beijing / 3-degree Gauss-Kruger zone 27 - EPSG:4654',
  },
  {
    value: '4655',
    label: 'New Beijing / 3-degree Gauss-Kruger zone 28 - EPSG:4655',
  },
  {
    value: '4656',
    label: 'New Beijing / 3-degree Gauss-Kruger zone 29 - EPSG:4656',
  },
  {
    value: '4766',
    label: 'New Beijing / 3-degree Gauss-Kruger zone 30 - EPSG:4766',
  },
  {
    value: '4767',
    label: 'New Beijing / 3-degree Gauss-Kruger zone 31 - EPSG:4767',
  },
  {
    value: '4768',
    label: 'New Beijing / 3-degree Gauss-Kruger zone 32 - EPSG:4768',
  },
  {
    value: '4769',
    label: 'New Beijing / 3-degree Gauss-Kruger zone 33 - EPSG:4769',
  },
  {
    value: '4770',
    label: 'New Beijing / 3-degree Gauss-Kruger zone 34 - EPSG:4770',
  },
  {
    value: '4771',
    label: 'New Beijing / 3-degree Gauss-Kruger zone 35 - EPSG:4771',
  },
  {
    value: '4772',
    label: 'New Beijing / 3-degree Gauss-Kruger zone 36 - EPSG:4772',
  },
  {
    value: '4773',
    label: 'New Beijing / 3-degree Gauss-Kruger zone 37 - EPSG:4773',
  },
  {
    value: '4774',
    label: 'New Beijing / 3-degree Gauss-Kruger zone 38 - EPSG:4774',
  },
  {
    value: '4775',
    label: 'New Beijing / 3-degree Gauss-Kruger zone 39 - EPSG:4775',
  },
  {
    value: '4776',
    label: 'New Beijing / 3-degree Gauss-Kruger zone 40 - EPSG:4776',
  },
  {
    value: '4777',
    label: 'New Beijing / 3-degree Gauss-Kruger zone 41 - EPSG:4777',
  },
  {
    value: '4778',
    label: 'New Beijing / 3-degree Gauss-Kruger zone 42 - EPSG:4778',
  },
  {
    value: '4779',
    label: 'New Beijing / 3-degree Gauss-Kruger zone 43 - EPSG:4779',
  },
  {
    value: '4780',
    label: 'New Beijing / 3-degree Gauss-Kruger zone 44 - EPSG:4780',
  },
  {
    value: '4781',
    label: 'New Beijing / 3-degree Gauss-Kruger zone 45 - EPSG:4781',
  },
  {
    value: '4782',
    label: 'New Beijing / 3-degree Gauss-Kruger CM 75E - EPSG:4782',
  },
  {
    value: '4783',
    label: 'New Beijing / 3-degree Gauss-Kruger CM 78E - EPSG:4783',
  },
  {
    value: '4784',
    label: 'New Beijing / 3-degree Gauss-Kruger CM 81E - EPSG:4784',
  },
  {
    value: '4785',
    label: 'New Beijing / 3-degree Gauss-Kruger CM 84E - EPSG:4785',
  },
  {
    value: '4786',
    label: 'New Beijing / 3-degree Gauss-Kruger CM 87E - EPSG:4786',
  },
  {
    value: '4787',
    label: 'New Beijing / 3-degree Gauss-Kruger CM 90E - EPSG:4787',
  },
  {
    value: '4788',
    label: 'New Beijing / 3-degree Gauss-Kruger CM 93E - EPSG:4788',
  },
  {
    value: '4789',
    label: 'New Beijing / 3-degree Gauss-Kruger CM 96E - EPSG:4789',
  },
  {
    value: '4790',
    label: 'New Beijing / 3-degree Gauss-Kruger CM 99E - EPSG:4790',
  },
  {
    value: '4791',
    label: 'New Beijing / 3-degree Gauss-Kruger CM 102E - EPSG:4791',
  },
  {
    value: '4792',
    label: 'New Beijing / 3-degree Gauss-Kruger CM 105E - EPSG:4792',
  },
  {
    value: '4793',
    label: 'New Beijing / 3-degree Gauss-Kruger CM 108E - EPSG:4793',
  },
  {
    value: '4794',
    label: 'New Beijing / 3-degree Gauss-Kruger CM 111E - EPSG:4794',
  },
  {
    value: '4795',
    label: 'New Beijing / 3-degree Gauss-Kruger CM 114E - EPSG:4795',
  },
  {
    value: '4796',
    label: 'New Beijing / 3-degree Gauss-Kruger CM 117E - EPSG:4796',
  },
  {
    value: '4797',
    label: 'New Beijing / 3-degree Gauss-Kruger CM 120E - EPSG:4797',
  },
  {
    value: '4798',
    label: 'New Beijing / 3-degree Gauss-Kruger CM 123E - EPSG:4798',
  },
  {
    value: '4799',
    label: 'New Beijing / 3-degree Gauss-Kruger CM 126E - EPSG:4799',
  },
  {
    value: '4800',
    label: 'New Beijing / 3-degree Gauss-Kruger CM 129E - EPSG:4800',
  },
  {
    value: '4812',
    label: 'New Beijing / 3-degree Gauss-Kruger CM 132E - EPSG:4812',
  },
  {
    value: '4822',
    label: 'New Beijing / 3-degree Gauss-Kruger CM 135E - EPSG:4822',
  },
  { value: '4826', label: 'WGS 84 / Cape Verde National - EPSG:4826' },
  { value: '4839', label: 'ETRS89 / LCC Germany (N-E) - EPSG:4839' },
  { value: '5014', label: 'PTRA08 / UTM zone 25N - EPSG:5014' },
  { value: '5015', label: 'PTRA08 / UTM zone 26N - EPSG:5015' },
  { value: '5016', label: 'PTRA08 / UTM zone 28N - EPSG:5016' },
  { value: '5017', label: 'Lisbon 1890 / Portugal Bonne New - EPSG:5017' },
  { value: '5018', label: 'Lisbon / Portuguese Grid New - EPSG:5018' },
  { value: '5041', label: 'WGS 84 / UPS North (E,N) - EPSG:5041' },
  { value: '5042', label: 'WGS 84 / UPS South (E,N) - EPSG:5042' },
  { value: '5048', label: 'ETRS89 / TM35FIN(N,E) - EPSG:5048' },
  { value: '5069', label: 'NAD27 / Conus Albers - EPSG:5069' },
  { value: '5070', label: 'NAD83 / Conus Albers - EPSG:5070' },
  { value: '5071', label: 'NAD83(HARN) / Conus Albers - EPSG:5071' },
  { value: '5072', label: 'NAD83(NSRS2007) / Conus Albers - EPSG:5072' },
  { value: '5105', label: 'ETRS89 / NTM zone 5 - EPSG:5105' },
  { value: '5106', label: 'ETRS89 / NTM zone 6 - EPSG:5106' },
  { value: '5107', label: 'ETRS89 / NTM zone 7 - EPSG:5107' },
  { value: '5108', label: 'ETRS89 / NTM zone 8 - EPSG:5108' },
  { value: '5109', label: 'ETRS89 / NTM zone 9 - EPSG:5109' },
  { value: '5110', label: 'ETRS89 / NTM zone 10 - EPSG:5110' },
  { value: '5111', label: 'ETRS89 / NTM zone 11 - EPSG:5111' },
  { value: '5112', label: 'ETRS89 / NTM zone 12 - EPSG:5112' },
  { value: '5113', label: 'ETRS89 / NTM zone 13 - EPSG:5113' },
  { value: '5114', label: 'ETRS89 / NTM zone 14 - EPSG:5114' },
  { value: '5115', label: 'ETRS89 / NTM zone 15 - EPSG:5115' },
  { value: '5116', label: 'ETRS89 / NTM zone 16 - EPSG:5116' },
  { value: '5117', label: 'ETRS89 / NTM zone 17 - EPSG:5117' },
  { value: '5118', label: 'ETRS89 / NTM zone 18 - EPSG:5118' },
  { value: '5119', label: 'ETRS89 / NTM zone 19 - EPSG:5119' },
  { value: '5120', label: 'ETRS89 / NTM zone 20 - EPSG:5120' },
  { value: '5121', label: 'ETRS89 / NTM zone 21 - EPSG:5121' },
  { value: '5122', label: 'ETRS89 / NTM zone 22 - EPSG:5122' },
  { value: '5123', label: 'ETRS89 / NTM zone 23 - EPSG:5123' },
  { value: '5124', label: 'ETRS89 / NTM zone 24 - EPSG:5124' },
  { value: '5125', label: 'ETRS89 / NTM zone 25 - EPSG:5125' },
  { value: '5126', label: 'ETRS89 / NTM zone 26 - EPSG:5126' },
  { value: '5127', label: 'ETRS89 / NTM zone 27 - EPSG:5127' },
  { value: '5128', label: 'ETRS89 / NTM zone 28 - EPSG:5128' },
  { value: '5129', label: 'ETRS89 / NTM zone 29 - EPSG:5129' },
  { value: '5130', label: 'ETRS89 / NTM zone 30 - EPSG:5130' },
  { value: '5167', label: 'Korean 1985 / East Sea Belt - EPSG:5167' },
  { value: '5168', label: 'Korean 1985 / Central Belt Jeju - EPSG:5168' },
  { value: '5169', label: 'Tokyo 1892 / Korea West Belt - EPSG:5169' },
  { value: '5170', label: 'Tokyo 1892 / Korea Central Belt - EPSG:5170' },
  { value: '5171', label: 'Tokyo 1892 / Korea East Belt - EPSG:5171' },
  { value: '5172', label: 'Tokyo 1892 / Korea East Sea Belt - EPSG:5172' },
  { value: '5173', label: 'Korean 1985 / Modified West Belt - EPSG:5173' },
  { value: '5174', label: 'Korean 1985 / Modified Central Belt - EPSG:5174' },
  {
    value: '5175',
    label: 'Korean 1985 / Modified Central Belt Jeju - EPSG:5175',
  },
  { value: '5176', label: 'Korean 1985 / Modified East Belt - EPSG:5176' },
  { value: '5177', label: 'Korean 1985 / Modified East Sea Belt - EPSG:5177' },
  { value: '5178', label: 'Korean 1985 / Unified CS - EPSG:5178' },
  { value: '5179', label: 'Korea 2000 / Unified CS - EPSG:5179' },
  { value: '5180', label: 'Korea 2000 / West Belt - EPSG:5180' },
  { value: '5181', label: 'Korea 2000 / Central Belt - EPSG:5181' },
  { value: '5182', label: 'Korea 2000 / Central Belt Jeju - EPSG:5182' },
  { value: '5183', label: 'Korea 2000 / East Belt - EPSG:5183' },
  { value: '5184', label: 'Korea 2000 / East Sea Belt - EPSG:5184' },
  { value: '5185', label: 'Korea 2000 / West Belt 2010 - EPSG:5185' },
  { value: '5186', label: 'Korea 2000 / Central Belt 2010 - EPSG:5186' },
  { value: '5187', label: 'Korea 2000 / East Belt 2010 - EPSG:5187' },
  { value: '5188', label: 'Korea 2000 / East Sea Belt 2010 - EPSG:5188' },
  { value: '5221', label: 'S-JTSK (Ferro) / Krovak East North - EPSG:5221' },
  { value: '5223', label: 'WGS 84 / Gabon TM - EPSG:5223' },
  { value: '5224', label: 'S-JTSK/05 (Ferro) / Modified Krovak - EPSG:5224' },
  {
    value: '5225',
    label: 'S-JTSK/05 (Ferro) / Modified Krovak East North - EPSG:5225',
  },
  { value: '5234', label: 'Kandawala / Sri Lanka Grid - EPSG:5234' },
  { value: '5235', label: 'SLD99 / Sri Lanka Grid 1999 - EPSG:5235' },
  { value: '5243', label: 'ETRS89 / LCC Germany (E-N) - EPSG:5243' },
  { value: '5247', label: 'GDBD2009 / Brunei BRSO - EPSG:5247' },
  { value: '5253', label: 'TUREF / TM27 - EPSG:5253' },
  { value: '5254', label: 'TUREF / TM30 - EPSG:5254' },
  { value: '5255', label: 'TUREF / TM33 - EPSG:5255' },
  { value: '5256', label: 'TUREF / TM36 - EPSG:5256' },
  { value: '5257', label: 'TUREF / TM39 - EPSG:5257' },
  { value: '5258', label: 'TUREF / TM42 - EPSG:5258' },
  { value: '5259', label: 'TUREF / TM45 - EPSG:5259' },
  { value: '5266', label: 'DRUKREF 03 / Bhutan National Grid - EPSG:5266' },
  { value: '5269', label: 'TUREF / 3-degree Gauss-Kruger zone 9 - EPSG:5269' },
  { value: '5270', label: 'TUREF / 3-degree Gauss-Kruger zone 10 - EPSG:5270' },
  { value: '5271', label: 'TUREF / 3-degree Gauss-Kruger zone 11 - EPSG:5271' },
  { value: '5272', label: 'TUREF / 3-degree Gauss-Kruger zone 12 - EPSG:5272' },
  { value: '5273', label: 'TUREF / 3-degree Gauss-Kruger zone 13 - EPSG:5273' },
  { value: '5274', label: 'TUREF / 3-degree Gauss-Kruger zone 14 - EPSG:5274' },
  { value: '5275', label: 'TUREF / 3-degree Gauss-Kruger zone 15 - EPSG:5275' },
  { value: '5292', label: 'DRUKREF 03 / Bumthang TM - EPSG:5292' },
  { value: '5293', label: 'DRUKREF 03 / Chhukha TM - EPSG:5293' },
  { value: '5294', label: 'DRUKREF 03 / Dagana TM - EPSG:5294' },
  { value: '5295', label: 'DRUKREF 03 / Gasa TM - EPSG:5295' },
  { value: '5296', label: 'DRUKREF 03 / Ha TM - EPSG:5296' },
  { value: '5297', label: 'DRUKREF 03 / Lhuentse TM - EPSG:5297' },
  { value: '5298', label: 'DRUKREF 03 / Mongar TM - EPSG:5298' },
  { value: '5299', label: 'DRUKREF 03 / Paro TM - EPSG:5299' },
  { value: '5300', label: 'DRUKREF 03 / Pemagatshel TM - EPSG:5300' },
  { value: '5301', label: 'DRUKREF 03 / Punakha TM - EPSG:5301' },
  { value: '5302', label: 'DRUKREF 03 / Samdrup Jongkhar TM - EPSG:5302' },
  { value: '5303', label: 'DRUKREF 03 / Samtse TM - EPSG:5303' },
  { value: '5304', label: 'DRUKREF 03 / Sarpang TM - EPSG:5304' },
  { value: '5305', label: 'DRUKREF 03 / Thimphu TM - EPSG:5305' },
  { value: '5306', label: 'DRUKREF 03 / Trashigang TM - EPSG:5306' },
  { value: '5307', label: 'DRUKREF 03 / Trongsa TM - EPSG:5307' },
  { value: '5308', label: 'DRUKREF 03 / Tsirang TM - EPSG:5308' },
  { value: '5309', label: 'DRUKREF 03 / Wangdue Phodrang TM - EPSG:5309' },
  { value: '5310', label: 'DRUKREF 03 / Yangtse TM - EPSG:5310' },
  { value: '5311', label: 'DRUKREF 03 / Zhemgang TM - EPSG:5311' },
  { value: '5316', label: 'ETRS89 / Faroe TM - EPSG:5316' },
  { value: '5320', label: 'NAD83 / Teranet Ontario Lambert - EPSG:5320' },
  { value: '5321', label: 'NAD83(CSRS) / Teranet Ontario Lambert - EPSG:5321' },
  { value: '5325', label: 'ISN2004 / Lambert 2004 - EPSG:5325' },
  { value: '5329', label: 'Segara (Jakarta) / NEIEZ - EPSG:5329' },
  { value: '5330', label: 'Batavia (Jakarta) / NEIEZ - EPSG:5330' },
  { value: '5331', label: 'Makassar (Jakarta) / NEIEZ - EPSG:5331' },
  { value: '5337', label: 'Aratu / UTM zone 25S - EPSG:5337' },
  { value: '5343', label: 'POSGAR 2007 / Argentina 1 - EPSG:5343' },
  { value: '5344', label: 'POSGAR 2007 / Argentina 2 - EPSG:5344' },
  { value: '5345', label: 'POSGAR 2007 / Argentina 3 - EPSG:5345' },
  { value: '5346', label: 'POSGAR 2007 / Argentina 4 - EPSG:5346' },
  { value: '5347', label: 'POSGAR 2007 / Argentina 5 - EPSG:5347' },
  { value: '5348', label: 'POSGAR 2007 / Argentina 6 - EPSG:5348' },
  { value: '5349', label: 'POSGAR 2007 / Argentina 7 - EPSG:5349' },
  { value: '5355', label: 'MARGEN / UTM zone 20S - EPSG:5355' },
  { value: '5356', label: 'MARGEN / UTM zone 19S - EPSG:5356' },
  { value: '5357', label: 'MARGEN / UTM zone 21S - EPSG:5357' },
  { value: '5361', label: 'SIRGAS-Chile / UTM zone 19S - EPSG:5361' },
  { value: '5362', label: 'SIRGAS-Chile / UTM zone 18S - EPSG:5362' },
  { value: '5367', label: 'CR05 / CRTM05 - EPSG:5367' },
  { value: '5382', label: 'SIRGAS-ROU98 / UTM zone 21S - EPSG:5382' },
  { value: '5383', label: 'SIRGAS-ROU98 / UTM zone 22S - EPSG:5383' },
  { value: '5387', label: 'Peru96 / UTM zone 18S - EPSG:5387' },
  { value: '5389', label: 'Peru96 / UTM zone 19S - EPSG:5389' },
  { value: '5396', label: 'SIRGAS 2000 / UTM zone 26S - EPSG:5396' },
  { value: '5456', label: 'Ocotepeque 1935 / Costa Rica Norte - EPSG:5456' },
  { value: '5457', label: 'Ocotepeque 1935 / Costa Rica Sur - EPSG:5457' },
  { value: '5459', label: 'Ocotepeque 1935 / Guatemala Sur - EPSG:5459' },
  { value: '5460', label: 'Ocotepeque 1935 / El Salvador Lambert - EPSG:5460' },
  { value: '5461', label: 'Ocotepeque 1935 / Nicaragua Norte - EPSG:5461' },
  { value: '5462', label: 'Ocotepeque 1935 / Nicaragua Sur - EPSG:5462' },
  { value: '5463', label: 'SAD69 / UTM zone 17N - EPSG:5463' },
  { value: '5469', label: 'Panama-Colon 1911 / Panama Lambert - EPSG:5469' },
  { value: '5472', label: 'Panama-Colon 1911 / Panama Polyconic - EPSG:5472' },
  { value: '5479', label: 'RSRGD2000 / MSLC2000 - EPSG:5479' },
  { value: '5480', label: 'RSRGD2000 / BCLC2000 - EPSG:5480' },
  { value: '5481', label: 'RSRGD2000 / PCLC2000 - EPSG:5481' },
  { value: '5482', label: 'RSRGD2000 / RSPS2000 - EPSG:5482' },
  { value: '5490', label: 'RGAF09 / UTM zone 20N - EPSG:5490' },
  { value: '5513', label: 'S-JTSK / Krovak - EPSG:5513' },
  { value: '5514', label: 'S-JTSK / Krovak East North - EPSG:5514' },
  { value: '5515', label: 'S-JTSK/05 / Modified Krovak - EPSG:5515' },
  {
    value: '5516',
    label: 'S-JTSK/05 / Modified Krovak East North - EPSG:5516',
  },
  { value: '5518', label: 'CI1971 / Chatham Islands Map Grid - EPSG:5518' },
  { value: '5519', label: 'CI1979 / Chatham Islands Map Grid - EPSG:5519' },
  { value: '5520', label: 'DHDN / 3-degree Gauss-Kruger zone 1 - EPSG:5520' },
  { value: '5523', label: 'WGS 84 / Gabon TM 2011 - EPSG:5523' },
  { value: '5530', label: 'SAD69(96) / Brazil Polyconic - EPSG:5530' },
  { value: '5531', label: 'SAD69(96) / UTM zone 21S - EPSG:5531' },
  { value: '5533', label: 'SAD69(96) / UTM zone 23S - EPSG:5533' },
  { value: '5534', label: 'SAD69(96) / UTM zone 24S - EPSG:5534' },
  { value: '5535', label: 'SAD69(96) / UTM zone 25S - EPSG:5535' },
  { value: '5536', label: 'Corrego Alegre 1961 / UTM zone 21S - EPSG:5536' },
  { value: '5537', label: 'Corrego Alegre 1961 / UTM zone 22S - EPSG:5537' },
  { value: '5538', label: 'Corrego Alegre 1961 / UTM zone 23S - EPSG:5538' },
  { value: '5539', label: 'Corrego Alegre 1961 / UTM zone 24S - EPSG:5539' },
  { value: '5550', label: 'PNG94 / PNGMG94 zone 54 - EPSG:5550' },
  { value: '5551', label: 'PNG94 / PNGMG94 zone 55 - EPSG:5551' },
  { value: '5552', label: 'PNG94 / PNGMG94 zone 56 - EPSG:5552' },
  { value: '5559', label: 'Ocotepeque 1935 / Guatemala Norte - EPSG:5559' },
  { value: '5562', label: 'UCS-2000 / Gauss-Kruger zone 4 - EPSG:5562' },
  { value: '5563', label: 'UCS-2000 / Gauss-Kruger zone 5 - EPSG:5563' },
  { value: '5564', label: 'UCS-2000 / Gauss-Kruger zone 6 - EPSG:5564' },
  { value: '5565', label: 'UCS-2000 / Gauss-Kruger zone 7 - EPSG:5565' },
  { value: '5566', label: 'UCS-2000 / Gauss-Kruger CM 21E - EPSG:5566' },
  { value: '5567', label: 'UCS-2000 / Gauss-Kruger CM 27E - EPSG:5567' },
  { value: '5568', label: 'UCS-2000 / Gauss-Kruger CM 33E - EPSG:5568' },
  { value: '5569', label: 'UCS-2000 / Gauss-Kruger CM 39E - EPSG:5569' },
  {
    value: '5588',
    label: 'NAD27 / New Brunswick Stereographic (NAD27) - EPSG:5588',
  },
  { value: '5589', label: 'Sibun Gorge 1922 / Colony Grid - EPSG:5589' },
  { value: '5596', label: 'FEH2010 / Fehmarnbelt TM - EPSG:5596' },
  { value: '5623', label: 'NAD27 / Michigan East - EPSG:5623' },
  { value: '5624', label: 'NAD27 / Michigan Old Central - EPSG:5624' },
  { value: '5625', label: 'NAD27 / Michigan West - EPSG:5625' },
  { value: '5627', label: 'ED50 / TM 6 NE - EPSG:5627' },
  { value: '5629', label: 'Moznet / UTM zone 38S - EPSG:5629' },
  {
    value: '5631',
    label: 'Pulkovo 1942(58) / Gauss-Kruger zone 2 (E-N) - EPSG:5631',
  },
  { value: '5632', label: 'PTRA08 / LCC Europe - EPSG:5632' },
  { value: '5633', label: 'PTRA08 / LAEA Europe - EPSG:5633' },
  { value: '5634', label: 'REGCAN95 / LCC Europe - EPSG:5634' },
  { value: '5635', label: 'REGCAN95 / LAEA Europe - EPSG:5635' },
  { value: '5636', label: 'TUREF / LAEA Europe - EPSG:5636' },
  { value: '5637', label: 'TUREF / LCC Europe - EPSG:5637' },
  { value: '5638', label: 'ISN2004 / LAEA Europe - EPSG:5638' },
  { value: '5639', label: 'ISN2004 / LCC Europe - EPSG:5639' },
  { value: '5641', label: 'SIRGAS 2000 / Brazil Mercator - EPSG:5641' },
  { value: '5643', label: 'ED50 / SPBA LCC - EPSG:5643' },
  { value: '5644', label: 'RGR92 / UTM zone 39S - EPSG:5644' },
  { value: '5646', label: 'NAD83 / Vermont (ftUS) - EPSG:5646' },
  { value: '5649', label: 'ETRS89 / UTM zone 31N (zE-N) - EPSG:5649' },
  { value: '5650', label: 'ETRS89 / UTM zone 33N (zE-N) - EPSG:5650' },
  { value: '5651', label: 'ETRS89 / UTM zone 31N (N-zE) - EPSG:5651' },
  { value: '5652', label: 'ETRS89 / UTM zone 32N (N-zE) - EPSG:5652' },
  { value: '5653', label: 'ETRS89 / UTM zone 33N (N-zE) - EPSG:5653' },
  { value: '5654', label: 'NAD83(HARN) / Vermont (ftUS) - EPSG:5654' },
  { value: '5655', label: 'NAD83(NSRS2007) / Vermont (ftUS) - EPSG:5655' },
  { value: '5659', label: 'Monte Mario / TM Emilia-Romagna - EPSG:5659' },
  {
    value: '5663',
    label: 'Pulkovo 1942(58) / Gauss-Kruger zone 3 (E-N) - EPSG:5663',
  },
  {
    value: '5664',
    label: 'Pulkovo 1942(83) / Gauss-Kruger zone 2 (E-N) - EPSG:5664',
  },
  {
    value: '5665',
    label: 'Pulkovo 1942(83) / Gauss-Kruger zone 3 (E-N) - EPSG:5665',
  },
  {
    value: '5666',
    label: 'PD/83 / 3-degree Gauss-Kruger zone 3 (E-N) - EPSG:5666',
  },
  {
    value: '5667',
    label: 'PD/83 / 3-degree Gauss-Kruger zone 4 (E-N) - EPSG:5667',
  },
  {
    value: '5668',
    label: 'RD/83 / 3-degree Gauss-Kruger zone 4 (E-N) - EPSG:5668',
  },
  {
    value: '5669',
    label: 'RD/83 / 3-degree Gauss-Kruger zone 5 (E-N) - EPSG:5669',
  },
  {
    value: '5670',
    label: 'Pulkovo 1942(58) / 3-degree Gauss-Kruger zone 3 (E-N) - EPSG:5670',
  },
  {
    value: '5671',
    label: 'Pulkovo 1942(58) / 3-degree Gauss-Kruger zone 4 (E-N) - EPSG:5671',
  },
  {
    value: '5672',
    label: 'Pulkovo 1942(58) / 3-degree Gauss-Kruger zone 5 (E-N) - EPSG:5672',
  },
  {
    value: '5673',
    label: 'Pulkovo 1942(83) / 3-degree Gauss-Kruger zone 3 (E-N) - EPSG:5673',
  },
  {
    value: '5674',
    label: 'Pulkovo 1942(83) / 3-degree Gauss-Kruger zone 4 (E-N) - EPSG:5674',
  },
  {
    value: '5675',
    label: 'Pulkovo 1942(83) / 3-degree Gauss-Kruger zone 5 (E-N) - EPSG:5675',
  },
  {
    value: '5676',
    label: 'DHDN / 3-degree Gauss-Kruger zone 2 (E-N) - EPSG:5676',
  },
  {
    value: '5677',
    label: 'DHDN / 3-degree Gauss-Kruger zone 3 (E-N) - EPSG:5677',
  },
  {
    value: '5678',
    label: 'DHDN / 3-degree Gauss-Kruger zone 4 (E-N) - EPSG:5678',
  },
  {
    value: '5679',
    label: 'DHDN / 3-degree Gauss-Kruger zone 5 (E-N) - EPSG:5679',
  },
  {
    value: '5680',
    label: 'DHDN / 3-degree Gauss-Kruger zone 1 (E-N) - EPSG:5680',
  },
  {
    value: '5682',
    label: 'DB_REF / 3-degree Gauss-Kruger zone 2 (E-N) - EPSG:5682',
  },
  {
    value: '5683',
    label: 'DB_REF / 3-degree Gauss-Kruger zone 3 (E-N) - EPSG:5683',
  },
  {
    value: '5684',
    label: 'DB_REF / 3-degree Gauss-Kruger zone 4 (E-N) - EPSG:5684',
  },
  {
    value: '5685',
    label: 'DB_REF / 3-degree Gauss-Kruger zone 5 (E-N) - EPSG:5685',
  },
  { value: '5700', label: 'NZGD2000 / UTM zone 1S - EPSG:5700' },
  { value: '5819', label: 'EPSG topocentric example A - EPSG:5819' },
  { value: '5820', label: 'EPSG topocentric example B - EPSG:5820' },
  { value: '5821', label: 'EPSG vertical perspective example - EPSG:5821' },
  { value: '5825', label: 'AGD66 / ACT Standard Grid - EPSG:5825' },
  { value: '5836', label: 'Yemen NGN96 / UTM zone 37N - EPSG:5836' },
  { value: '5837', label: 'Yemen NGN96 / UTM zone 40N - EPSG:5837' },
  { value: '5839', label: 'Peru96 / UTM zone 17S - EPSG:5839' },
  { value: '5842', label: 'WGS 84 / TM 12 SE - EPSG:5842' },
  { value: '5844', label: 'RGRDC 2005 / Congo TM zone 30 - EPSG:5844' },
  { value: '5858', label: 'SAD69(96) / UTM zone 22S - EPSG:5858' },
  { value: '5875', label: 'SAD69(96) / UTM zone 18S - EPSG:5875' },
  { value: '5876', label: 'SAD69(96) / UTM zone 19S - EPSG:5876' },
  { value: '5877', label: 'SAD69(96) / UTM zone 20S - EPSG:5877' },
  { value: '5879', label: 'Cadastre 1997 / UTM zone 38S - EPSG:5879' },
  { value: '5880', label: 'SIRGAS 2000 / Brazil Polyconic - EPSG:5880' },
  { value: '5887', label: 'TGD2005 / Tonga Map Grid - EPSG:5887' },
  {
    value: '5890',
    label: 'JAXA Snow Depth Polar Stereographic North - EPSG:5890',
  },
  { value: '5921', label: 'WGS 84 / EPSG Arctic Regional zone A1 - EPSG:5921' },
  { value: '5922', label: 'WGS 84 / EPSG Arctic Regional zone A2 - EPSG:5922' },
  { value: '5923', label: 'WGS 84 / EPSG Arctic Regional zone A3 - EPSG:5923' },
  { value: '5924', label: 'WGS 84 / EPSG Arctic Regional zone A4 - EPSG:5924' },
  { value: '5925', label: 'WGS 84 / EPSG Arctic Regional zone A5 - EPSG:5925' },
  { value: '5926', label: 'WGS 84 / EPSG Arctic Regional zone B1 - EPSG:5926' },
  { value: '5927', label: 'WGS 84 / EPSG Arctic Regional zone B2 - EPSG:5927' },
  { value: '5928', label: 'WGS 84 / EPSG Arctic Regional zone B3 - EPSG:5928' },
  { value: '5929', label: 'WGS 84 / EPSG Arctic Regional zone B4 - EPSG:5929' },
  { value: '5930', label: 'WGS 84 / EPSG Arctic Regional zone B5 - EPSG:5930' },
  { value: '5931', label: 'WGS 84 / EPSG Arctic Regional zone C1 - EPSG:5931' },
  { value: '5932', label: 'WGS 84 / EPSG Arctic Regional zone C2 - EPSG:5932' },
  { value: '5933', label: 'WGS 84 / EPSG Arctic Regional zone C3 - EPSG:5933' },
  { value: '5934', label: 'WGS 84 / EPSG Arctic Regional zone C4 - EPSG:5934' },
  { value: '5935', label: 'WGS 84 / EPSG Arctic Regional zone C5 - EPSG:5935' },
  {
    value: '5936',
    label: 'WGS 84 / EPSG Alaska Polar Stereographic - EPSG:5936',
  },
  {
    value: '5937',
    label: 'WGS 84 / EPSG Canada Polar Stereographic - EPSG:5937',
  },
  {
    value: '5938',
    label: 'WGS 84 / EPSG Greenland Polar Stereographic - EPSG:5938',
  },
  {
    value: '5939',
    label: 'WGS 84 / EPSG Norway Polar Stereographic - EPSG:5939',
  },
  {
    value: '5940',
    label: 'WGS 84 / EPSG Russia Polar Stereographic - EPSG:5940',
  },
  { value: '6050', label: 'GR96 / EPSG Arctic zone 1-25 - EPSG:6050' },
  { value: '6051', label: 'GR96 / EPSG Arctic zone 2-18 - EPSG:6051' },
  { value: '6052', label: 'GR96 / EPSG Arctic zone 2-20 - EPSG:6052' },
  { value: '6053', label: 'GR96 / EPSG Arctic zone 3-29 - EPSG:6053' },
  { value: '6054', label: 'GR96 / EPSG Arctic zone 3-31 - EPSG:6054' },
  { value: '6055', label: 'GR96 / EPSG Arctic zone 3-33 - EPSG:6055' },
  { value: '6056', label: 'GR96 / EPSG Arctic zone 4-20 - EPSG:6056' },
  { value: '6057', label: 'GR96 / EPSG Arctic zone 4-22 - EPSG:6057' },
  { value: '6058', label: 'GR96 / EPSG Arctic zone 4-24 - EPSG:6058' },
  { value: '6059', label: 'GR96 / EPSG Arctic zone 5-41 - EPSG:6059' },
  { value: '6060', label: 'GR96 / EPSG Arctic zone 5-43 - EPSG:6060' },
  { value: '6061', label: 'GR96 / EPSG Arctic zone 5-45 - EPSG:6061' },
  { value: '6062', label: 'GR96 / EPSG Arctic zone 6-26 - EPSG:6062' },
  { value: '6063', label: 'GR96 / EPSG Arctic zone 6-28 - EPSG:6063' },
  { value: '6064', label: 'GR96 / EPSG Arctic zone 6-30 - EPSG:6064' },
  { value: '6065', label: 'GR96 / EPSG Arctic zone 7-11 - EPSG:6065' },
  { value: '6066', label: 'GR96 / EPSG Arctic zone 7-13 - EPSG:6066' },
  { value: '6067', label: 'GR96 / EPSG Arctic zone 8-20 - EPSG:6067' },
  { value: '6068', label: 'GR96 / EPSG Arctic zone 8-22 - EPSG:6068' },
  { value: '6069', label: 'ETRS89 / EPSG Arctic zone 2-22 - EPSG:6069' },
  { value: '6070', label: 'ETRS89 / EPSG Arctic zone 3-11 - EPSG:6070' },
  { value: '6071', label: 'ETRS89 / EPSG Arctic zone 4-26 - EPSG:6071' },
  { value: '6072', label: 'ETRS89 / EPSG Arctic zone 4-28 - EPSG:6072' },
  { value: '6073', label: 'ETRS89 / EPSG Arctic zone 5-11 - EPSG:6073' },
  { value: '6074', label: 'ETRS89 / EPSG Arctic zone 5-13 - EPSG:6074' },
  { value: '6075', label: 'WGS 84 / EPSG Arctic zone 2-24 - EPSG:6075' },
  { value: '6076', label: 'WGS 84 / EPSG Arctic zone 2-26 - EPSG:6076' },
  { value: '6077', label: 'WGS 84 / EPSG Arctic zone 3-13 - EPSG:6077' },
  { value: '6078', label: 'WGS 84 / EPSG Arctic zone 3-15 - EPSG:6078' },
  { value: '6079', label: 'WGS 84 / EPSG Arctic zone 3-17 - EPSG:6079' },
  { value: '6080', label: 'WGS 84 / EPSG Arctic zone 3-19 - EPSG:6080' },
  { value: '6081', label: 'WGS 84 / EPSG Arctic zone 4-30 - EPSG:6081' },
  { value: '6082', label: 'WGS 84 / EPSG Arctic zone 4-32 - EPSG:6082' },
  { value: '6083', label: 'WGS 84 / EPSG Arctic zone 4-34 - EPSG:6083' },
  { value: '6084', label: 'WGS 84 / EPSG Arctic zone 4-36 - EPSG:6084' },
  { value: '6085', label: 'WGS 84 / EPSG Arctic zone 4-38 - EPSG:6085' },
  { value: '6086', label: 'WGS 84 / EPSG Arctic zone 4-40 - EPSG:6086' },
  { value: '6087', label: 'WGS 84 / EPSG Arctic zone 5-15 - EPSG:6087' },
  { value: '6088', label: 'WGS 84 / EPSG Arctic zone 5-17 - EPSG:6088' },
  { value: '6089', label: 'WGS 84 / EPSG Arctic zone 5-19 - EPSG:6089' },
  { value: '6090', label: 'WGS 84 / EPSG Arctic zone 5-21 - EPSG:6090' },
  { value: '6091', label: 'WGS 84 / EPSG Arctic zone 5-23 - EPSG:6091' },
  { value: '6092', label: 'WGS 84 / EPSG Arctic zone 5-25 - EPSG:6092' },
  { value: '6093', label: 'WGS 84 / EPSG Arctic zone 5-27 - EPSG:6093' },
  {
    value: '6094',
    label: 'NAD83(NSRS2007) / EPSG Arctic zone 5-29 - EPSG:6094',
  },
  {
    value: '6095',
    label: 'NAD83(NSRS2007) / EPSG Arctic zone 5-31 - EPSG:6095',
  },
  {
    value: '6096',
    label: 'NAD83(NSRS2007) / EPSG Arctic zone 6-14 - EPSG:6096',
  },
  {
    value: '6097',
    label: 'NAD83(NSRS2007) / EPSG Arctic zone 6-16 - EPSG:6097',
  },
  { value: '6098', label: 'NAD83(CSRS) / EPSG Arctic zone 1-23 - EPSG:6098' },
  { value: '6099', label: 'NAD83(CSRS) / EPSG Arctic zone 2-14 - EPSG:6099' },
  { value: '6100', label: 'NAD83(CSRS) / EPSG Arctic zone 2-16 - EPSG:6100' },
  { value: '6101', label: 'NAD83(CSRS) / EPSG Arctic zone 3-25 - EPSG:6101' },
  { value: '6102', label: 'NAD83(CSRS) / EPSG Arctic zone 3-27 - EPSG:6102' },
  { value: '6103', label: 'NAD83(CSRS) / EPSG Arctic zone 3-29 - EPSG:6103' },
  { value: '6104', label: 'NAD83(CSRS) / EPSG Arctic zone 4-14 - EPSG:6104' },
  { value: '6105', label: 'NAD83(CSRS) / EPSG Arctic zone 4-16 - EPSG:6105' },
  { value: '6106', label: 'NAD83(CSRS) / EPSG Arctic zone 4-18 - EPSG:6106' },
  { value: '6107', label: 'NAD83(CSRS) / EPSG Arctic zone 5-33 - EPSG:6107' },
  { value: '6108', label: 'NAD83(CSRS) / EPSG Arctic zone 5-35 - EPSG:6108' },
  { value: '6109', label: 'NAD83(CSRS) / EPSG Arctic zone 5-37 - EPSG:6109' },
  { value: '6110', label: 'NAD83(CSRS) / EPSG Arctic zone 5-39 - EPSG:6110' },
  { value: '6111', label: 'NAD83(CSRS) / EPSG Arctic zone 6-18 - EPSG:6111' },
  { value: '6112', label: 'NAD83(CSRS) / EPSG Arctic zone 6-20 - EPSG:6112' },
  { value: '6113', label: 'NAD83(CSRS) / EPSG Arctic zone 6-22 - EPSG:6113' },
  { value: '6114', label: 'NAD83(CSRS) / EPSG Arctic zone 6-24 - EPSG:6114' },
  { value: '6115', label: 'WGS 84 / EPSG Arctic zone 1-27 - EPSG:6115' },
  { value: '6116', label: 'WGS 84 / EPSG Arctic zone 1-29 - EPSG:6116' },
  { value: '6117', label: 'WGS 84 / EPSG Arctic zone 1-31 - EPSG:6117' },
  { value: '6118', label: 'WGS 84 / EPSG Arctic zone 1-21 - EPSG:6118' },
  { value: '6119', label: 'WGS 84 / EPSG Arctic zone 2-28 - EPSG:6119' },
  { value: '6120', label: 'WGS 84 / EPSG Arctic zone 2-10 - EPSG:6120' },
  { value: '6121', label: 'WGS 84 / EPSG Arctic zone 2-12 - EPSG:6121' },
  { value: '6122', label: 'WGS 84 / EPSG Arctic zone 3-21 - EPSG:6122' },
  { value: '6123', label: 'WGS 84 / EPSG Arctic zone 3-23 - EPSG:6123' },
  { value: '6124', label: 'WGS 84 / EPSG Arctic zone 4-12 - EPSG:6124' },
  { value: '6125', label: 'ETRS89 / EPSG Arctic zone 5-47 - EPSG:6125' },
  { value: '6128', label: 'Grand Cayman National Grid 1959 - EPSG:6128' },
  { value: '6129', label: 'Sister Islands National Grid 1961 - EPSG:6129' },
  { value: '6201', label: 'NAD27 / Michigan Central - EPSG:6201' },
  { value: '6202', label: 'NAD27 / Michigan South - EPSG:6202' },
  { value: '6204', label: 'Macedonia State Coordinate System - EPSG:6204' },
  { value: '6210', label: 'SIRGAS 2000 / UTM zone 23N - EPSG:6210' },
  { value: '6211', label: 'SIRGAS 2000 / UTM zone 24N - EPSG:6211' },
  { value: '6244', label: 'MAGNA-SIRGAS / Arauca urban grid - EPSG:6244' },
  { value: '6245', label: 'MAGNA-SIRGAS / Armenia urban grid - EPSG:6245' },
  {
    value: '6246',
    label: 'MAGNA-SIRGAS / Barranquilla urban grid - EPSG:6246',
  },
  { value: '6247', label: 'MAGNA-SIRGAS / Bogota urban grid - EPSG:6247' },
  { value: '6248', label: 'MAGNA-SIRGAS / Bucaramanga urban grid - EPSG:6248' },
  { value: '6249', label: 'MAGNA-SIRGAS / Cali urban grid - EPSG:6249' },
  { value: '6250', label: 'MAGNA-SIRGAS / Cartagena urban grid - EPSG:6250' },
  { value: '6251', label: 'MAGNA-SIRGAS / Cucuta urban grid - EPSG:6251' },
  { value: '6252', label: 'MAGNA-SIRGAS / Florencia urban grid - EPSG:6252' },
  { value: '6253', label: 'MAGNA-SIRGAS / Ibague urban grid - EPSG:6253' },
  { value: '6254', label: 'MAGNA-SIRGAS / Inirida urban grid - EPSG:6254' },
  { value: '6255', label: 'MAGNA-SIRGAS / Leticia urban grid - EPSG:6255' },
  { value: '6256', label: 'MAGNA-SIRGAS / Manizales urban grid - EPSG:6256' },
  { value: '6257', label: 'MAGNA-SIRGAS / Medellin urban grid - EPSG:6257' },
  { value: '6258', label: 'MAGNA-SIRGAS / Mitu urban grid - EPSG:6258' },
  { value: '6259', label: 'MAGNA-SIRGAS / Mocoa urban grid - EPSG:6259' },
  { value: '6260', label: 'MAGNA-SIRGAS / Monteria urban grid - EPSG:6260' },
  { value: '6261', label: 'MAGNA-SIRGAS / Neiva urban grid - EPSG:6261' },
  { value: '6262', label: 'MAGNA-SIRGAS / Pasto urban grid - EPSG:6262' },
  { value: '6263', label: 'MAGNA-SIRGAS / Pereira urban grid - EPSG:6263' },
  { value: '6264', label: 'MAGNA-SIRGAS / Popayan urban grid - EPSG:6264' },
  {
    value: '6265',
    label: 'MAGNA-SIRGAS / Puerto Carreno urban grid - EPSG:6265',
  },
  { value: '6266', label: 'MAGNA-SIRGAS / Quibdo urban grid - EPSG:6266' },
  { value: '6267', label: 'MAGNA-SIRGAS / Riohacha urban grid - EPSG:6267' },
  { value: '6268', label: 'MAGNA-SIRGAS / San Andres urban grid - EPSG:6268' },
  {
    value: '6269',
    label: 'MAGNA-SIRGAS / San Jose del Guaviare urban grid - EPSG:6269',
  },
  { value: '6270', label: 'MAGNA-SIRGAS / Santa Marta urban grid - EPSG:6270' },
  { value: '6271', label: 'MAGNA-SIRGAS / Sucre urban grid - EPSG:6271' },
  { value: '6272', label: 'MAGNA-SIRGAS / Tunja urban grid - EPSG:6272' },
  { value: '6273', label: 'MAGNA-SIRGAS / Valledupar urban grid - EPSG:6273' },
  {
    value: '6274',
    label: 'MAGNA-SIRGAS / Villavicencio urban grid - EPSG:6274',
  },
  { value: '6275', label: 'MAGNA-SIRGAS / Yopal urban grid - EPSG:6275' },
  {
    value: '6307',
    label: 'NAD83(CORS96) / Puerto Rico and Virgin Is. - EPSG:6307',
  },
  {
    value: '6312',
    label: 'CGRS93 / Cyprus Local Transverse Mercator - EPSG:6312',
  },
  {
    value: '6316',
    label: 'Macedonia State Coordinate System zone 7 - EPSG:6316',
  },
  { value: '6328', label: 'NAD83(2011) / UTM zone 59N - EPSG:6328' },
  { value: '6329', label: 'NAD83(2011) / UTM zone 60N - EPSG:6329' },
  { value: '6330', label: 'NAD83(2011) / UTM zone 1N - EPSG:6330' },
  { value: '6331', label: 'NAD83(2011) / UTM zone 2N - EPSG:6331' },
  { value: '6332', label: 'NAD83(2011) / UTM zone 3N - EPSG:6332' },
  { value: '6333', label: 'NAD83(2011) / UTM zone 4N - EPSG:6333' },
  { value: '6334', label: 'NAD83(2011) / UTM zone 5N - EPSG:6334' },
  { value: '6335', label: 'NAD83(2011) / UTM zone 6N - EPSG:6335' },
  { value: '6336', label: 'NAD83(2011) / UTM zone 7N - EPSG:6336' },
  { value: '6337', label: 'NAD83(2011) / UTM zone 8N - EPSG:6337' },
  { value: '6338', label: 'NAD83(2011) / UTM zone 9N - EPSG:6338' },
  { value: '6339', label: 'NAD83(2011) / UTM zone 10N - EPSG:6339' },
  { value: '6340', label: 'NAD83(2011) / UTM zone 11N - EPSG:6340' },
  { value: '6341', label: 'NAD83(2011) / UTM zone 12N - EPSG:6341' },
  { value: '6342', label: 'NAD83(2011) / UTM zone 13N - EPSG:6342' },
  { value: '6343', label: 'NAD83(2011) / UTM zone 14N - EPSG:6343' },
  { value: '6344', label: 'NAD83(2011) / UTM zone 15N - EPSG:6344' },
  { value: '6345', label: 'NAD83(2011) / UTM zone 16N - EPSG:6345' },
  { value: '6346', label: 'NAD83(2011) / UTM zone 17N - EPSG:6346' },
  { value: '6347', label: 'NAD83(2011) / UTM zone 18N - EPSG:6347' },
  { value: '6348', label: 'NAD83(2011) / UTM zone 19N - EPSG:6348' },
  { value: '6350', label: 'NAD83(2011) / Conus Albers - EPSG:6350' },
  { value: '6351', label: 'NAD83(2011) / EPSG Arctic zone 5-29 - EPSG:6351' },
  { value: '6352', label: 'NAD83(2011) / EPSG Arctic zone 5-31 - EPSG:6352' },
  { value: '6353', label: 'NAD83(2011) / EPSG Arctic zone 6-14 - EPSG:6353' },
  { value: '6354', label: 'NAD83(2011) / EPSG Arctic zone 6-16 - EPSG:6354' },
  { value: '6355', label: 'NAD83(2011) / Alabama East - EPSG:6355' },
  { value: '6356', label: 'NAD83(2011) / Alabama West - EPSG:6356' },
  { value: '6362', label: 'Mexico ITRF92 / LCC - EPSG:6362' },
  { value: '6366', label: 'Mexico ITRF2008 / UTM zone 11N - EPSG:6366' },
  { value: '6367', label: 'Mexico ITRF2008 / UTM zone 12N - EPSG:6367' },
  { value: '6368', label: 'Mexico ITRF2008 / UTM zone 13N - EPSG:6368' },
  { value: '6369', label: 'Mexico ITRF2008 / UTM zone 14N - EPSG:6369' },
  { value: '6370', label: 'Mexico ITRF2008 / UTM zone 15N - EPSG:6370' },
  { value: '6371', label: 'Mexico ITRF2008 / UTM zone 16N - EPSG:6371' },
  { value: '6372', label: 'Mexico ITRF2008 / LCC - EPSG:6372' },
  { value: '6381', label: 'UCS-2000 / Ukraine TM zone 7 - EPSG:6381' },
  { value: '6382', label: 'UCS-2000 / Ukraine TM zone 8 - EPSG:6382' },
  { value: '6383', label: 'UCS-2000 / Ukraine TM zone 9 - EPSG:6383' },
  { value: '6384', label: 'UCS-2000 / Ukraine TM zone 10 - EPSG:6384' },
  { value: '6385', label: 'UCS-2000 / Ukraine TM zone 11 - EPSG:6385' },
  { value: '6386', label: 'UCS-2000 / Ukraine TM zone 12 - EPSG:6386' },
  { value: '6387', label: 'UCS-2000 / Ukraine TM zone 13 - EPSG:6387' },
  { value: '6391', label: 'Cayman Islands National Grid 2011 - EPSG:6391' },
  { value: '6393', label: 'NAD83(2011) / Alaska Albers - EPSG:6393' },
  { value: '6394', label: 'NAD83(2011) / Alaska zone 1 - EPSG:6394' },
  { value: '6395', label: 'NAD83(2011) / Alaska zone 2 - EPSG:6395' },
  { value: '6396', label: 'NAD83(2011) / Alaska zone 3 - EPSG:6396' },
  { value: '6397', label: 'NAD83(2011) / Alaska zone 4 - EPSG:6397' },
  { value: '6398', label: 'NAD83(2011) / Alaska zone 5 - EPSG:6398' },
  { value: '6399', label: 'NAD83(2011) / Alaska zone 6 - EPSG:6399' },
  { value: '6400', label: 'NAD83(2011) / Alaska zone 7 - EPSG:6400' },
  { value: '6401', label: 'NAD83(2011) / Alaska zone 8 - EPSG:6401' },
  { value: '6402', label: 'NAD83(2011) / Alaska zone 9 - EPSG:6402' },
  { value: '6403', label: 'NAD83(2011) / Alaska zone 10 - EPSG:6403' },
  { value: '6404', label: 'NAD83(2011) / Arizona Central - EPSG:6404' },
  { value: '6405', label: 'NAD83(2011) / Arizona Central (ft) - EPSG:6405' },
  { value: '6406', label: 'NAD83(2011) / Arizona East - EPSG:6406' },
  { value: '6407', label: 'NAD83(2011) / Arizona East (ft) - EPSG:6407' },
  { value: '6408', label: 'NAD83(2011) / Arizona West - EPSG:6408' },
  { value: '6409', label: 'NAD83(2011) / Arizona West (ft) - EPSG:6409' },
  { value: '6410', label: 'NAD83(2011) / Arkansas North - EPSG:6410' },
  { value: '6411', label: 'NAD83(2011) / Arkansas North (ftUS) - EPSG:6411' },
  { value: '6412', label: 'NAD83(2011) / Arkansas South - EPSG:6412' },
  { value: '6413', label: 'NAD83(2011) / Arkansas South (ftUS) - EPSG:6413' },
  { value: '6414', label: 'NAD83(2011) / California Albers - EPSG:6414' },
  { value: '6415', label: 'NAD83(2011) / California zone 1 - EPSG:6415' },
  {
    value: '6416',
    label: 'NAD83(2011) / California zone 1 (ftUS) - EPSG:6416',
  },
  { value: '6417', label: 'NAD83(2011) / California zone 2 - EPSG:6417' },
  {
    value: '6418',
    label: 'NAD83(2011) / California zone 2 (ftUS) - EPSG:6418',
  },
  { value: '6419', label: 'NAD83(2011) / California zone 3 - EPSG:6419' },
  {
    value: '6420',
    label: 'NAD83(2011) / California zone 3 (ftUS) - EPSG:6420',
  },
  { value: '6421', label: 'NAD83(2011) / California zone 4 - EPSG:6421' },
  {
    value: '6422',
    label: 'NAD83(2011) / California zone 4 (ftUS) - EPSG:6422',
  },
  { value: '6423', label: 'NAD83(2011) / California zone 5 - EPSG:6423' },
  {
    value: '6424',
    label: 'NAD83(2011) / California zone 5 (ftUS) - EPSG:6424',
  },
  { value: '6425', label: 'NAD83(2011) / California zone 6 - EPSG:6425' },
  {
    value: '6426',
    label: 'NAD83(2011) / California zone 6 (ftUS) - EPSG:6426',
  },
  { value: '6427', label: 'NAD83(2011) / Colorado Central - EPSG:6427' },
  { value: '6428', label: 'NAD83(2011) / Colorado Central (ftUS) - EPSG:6428' },
  { value: '6429', label: 'NAD83(2011) / Colorado North - EPSG:6429' },
  { value: '6430', label: 'NAD83(2011) / Colorado North (ftUS) - EPSG:6430' },
  { value: '6431', label: 'NAD83(2011) / Colorado South - EPSG:6431' },
  { value: '6432', label: 'NAD83(2011) / Colorado South (ftUS) - EPSG:6432' },
  { value: '6433', label: 'NAD83(2011) / Connecticut - EPSG:6433' },
  { value: '6434', label: 'NAD83(2011) / Connecticut (ftUS) - EPSG:6434' },
  { value: '6435', label: 'NAD83(2011) / Delaware - EPSG:6435' },
  { value: '6436', label: 'NAD83(2011) / Delaware (ftUS) - EPSG:6436' },
  { value: '6437', label: 'NAD83(2011) / Florida East - EPSG:6437' },
  { value: '6438', label: 'NAD83(2011) / Florida East (ftUS) - EPSG:6438' },
  { value: '6439', label: 'NAD83(2011) / Florida GDL Albers - EPSG:6439' },
  { value: '6440', label: 'NAD83(2011) / Florida North - EPSG:6440' },
  { value: '6441', label: 'NAD83(2011) / Florida North (ftUS) - EPSG:6441' },
  { value: '6442', label: 'NAD83(2011) / Florida West - EPSG:6442' },
  { value: '6443', label: 'NAD83(2011) / Florida West (ftUS) - EPSG:6443' },
  { value: '6444', label: 'NAD83(2011) / Georgia East - EPSG:6444' },
  { value: '6445', label: 'NAD83(2011) / Georgia East (ftUS) - EPSG:6445' },
  { value: '6446', label: 'NAD83(2011) / Georgia West - EPSG:6446' },
  { value: '6447', label: 'NAD83(2011) / Georgia West (ftUS) - EPSG:6447' },
  { value: '6448', label: 'NAD83(2011) / Idaho Central - EPSG:6448' },
  { value: '6449', label: 'NAD83(2011) / Idaho Central (ftUS) - EPSG:6449' },
  { value: '6450', label: 'NAD83(2011) / Idaho East - EPSG:6450' },
  { value: '6451', label: 'NAD83(2011) / Idaho East (ftUS) - EPSG:6451' },
  { value: '6452', label: 'NAD83(2011) / Idaho West - EPSG:6452' },
  { value: '6453', label: 'NAD83(2011) / Idaho West (ftUS) - EPSG:6453' },
  { value: '6454', label: 'NAD83(2011) / Illinois East - EPSG:6454' },
  { value: '6455', label: 'NAD83(2011) / Illinois East (ftUS) - EPSG:6455' },
  { value: '6456', label: 'NAD83(2011) / Illinois West - EPSG:6456' },
  { value: '6457', label: 'NAD83(2011) / Illinois West (ftUS) - EPSG:6457' },
  { value: '6458', label: 'NAD83(2011) / Indiana East - EPSG:6458' },
  { value: '6459', label: 'NAD83(2011) / Indiana East (ftUS) - EPSG:6459' },
  { value: '6460', label: 'NAD83(2011) / Indiana West - EPSG:6460' },
  { value: '6461', label: 'NAD83(2011) / Indiana West (ftUS) - EPSG:6461' },
  { value: '6462', label: 'NAD83(2011) / Iowa North - EPSG:6462' },
  { value: '6463', label: 'NAD83(2011) / Iowa North (ftUS) - EPSG:6463' },
  { value: '6464', label: 'NAD83(2011) / Iowa South - EPSG:6464' },
  { value: '6465', label: 'NAD83(2011) / Iowa South (ftUS) - EPSG:6465' },
  { value: '6466', label: 'NAD83(2011) / Kansas North - EPSG:6466' },
  { value: '6467', label: 'NAD83(2011) / Kansas North (ftUS) - EPSG:6467' },
  { value: '6468', label: 'NAD83(2011) / Kansas South - EPSG:6468' },
  { value: '6469', label: 'NAD83(2011) / Kansas South (ftUS) - EPSG:6469' },
  { value: '6470', label: 'NAD83(2011) / Kentucky North - EPSG:6470' },
  { value: '6471', label: 'NAD83(2011) / Kentucky North (ftUS) - EPSG:6471' },
  { value: '6472', label: 'NAD83(2011) / Kentucky Single Zone - EPSG:6472' },
  {
    value: '6473',
    label: 'NAD83(2011) / Kentucky Single Zone (ftUS) - EPSG:6473',
  },
  { value: '6474', label: 'NAD83(2011) / Kentucky South - EPSG:6474' },
  { value: '6475', label: 'NAD83(2011) / Kentucky South (ftUS) - EPSG:6475' },
  { value: '6476', label: 'NAD83(2011) / Louisiana North - EPSG:6476' },
  { value: '6477', label: 'NAD83(2011) / Louisiana North (ftUS) - EPSG:6477' },
  { value: '6478', label: 'NAD83(2011) / Louisiana South - EPSG:6478' },
  { value: '6479', label: 'NAD83(2011) / Louisiana South (ftUS) - EPSG:6479' },
  { value: '6480', label: 'NAD83(2011) / Maine CS2000 Central - EPSG:6480' },
  { value: '6481', label: 'NAD83(2011) / Maine CS2000 East - EPSG:6481' },
  { value: '6482', label: 'NAD83(2011) / Maine CS2000 West - EPSG:6482' },
  { value: '6483', label: 'NAD83(2011) / Maine East - EPSG:6483' },
  { value: '6484', label: 'NAD83(2011) / Maine East (ftUS) - EPSG:6484' },
  { value: '6485', label: 'NAD83(2011) / Maine West - EPSG:6485' },
  { value: '6486', label: 'NAD83(2011) / Maine West (ftUS) - EPSG:6486' },
  { value: '6487', label: 'NAD83(2011) / Maryland - EPSG:6487' },
  { value: '6488', label: 'NAD83(2011) / Maryland (ftUS) - EPSG:6488' },
  { value: '6489', label: 'NAD83(2011) / Massachusetts Island - EPSG:6489' },
  {
    value: '6490',
    label: 'NAD83(2011) / Massachusetts Island (ftUS) - EPSG:6490',
  },
  { value: '6491', label: 'NAD83(2011) / Massachusetts Mainland - EPSG:6491' },
  {
    value: '6492',
    label: 'NAD83(2011) / Massachusetts Mainland (ftUS) - EPSG:6492',
  },
  { value: '6493', label: 'NAD83(2011) / Michigan Central - EPSG:6493' },
  { value: '6494', label: 'NAD83(2011) / Michigan Central (ft) - EPSG:6494' },
  { value: '6495', label: 'NAD83(2011) / Michigan North - EPSG:6495' },
  { value: '6496', label: 'NAD83(2011) / Michigan North (ft) - EPSG:6496' },
  {
    value: '6497',
    label: 'NAD83(2011) / Michigan Oblique Mercator - EPSG:6497',
  },
  { value: '6498', label: 'NAD83(2011) / Michigan South - EPSG:6498' },
  { value: '6499', label: 'NAD83(2011) / Michigan South (ft) - EPSG:6499' },
  { value: '6500', label: 'NAD83(2011) / Minnesota Central - EPSG:6500' },
  {
    value: '6501',
    label: 'NAD83(2011) / Minnesota Central (ftUS) - EPSG:6501',
  },
  { value: '6502', label: 'NAD83(2011) / Minnesota North - EPSG:6502' },
  { value: '6503', label: 'NAD83(2011) / Minnesota North (ftUS) - EPSG:6503' },
  { value: '6504', label: 'NAD83(2011) / Minnesota South - EPSG:6504' },
  { value: '6505', label: 'NAD83(2011) / Minnesota South (ftUS) - EPSG:6505' },
  { value: '6506', label: 'NAD83(2011) / Mississippi East - EPSG:6506' },
  { value: '6507', label: 'NAD83(2011) / Mississippi East (ftUS) - EPSG:6507' },
  { value: '6508', label: 'NAD83(2011) / Mississippi TM - EPSG:6508' },
  { value: '6509', label: 'NAD83(2011) / Mississippi West - EPSG:6509' },
  { value: '6510', label: 'NAD83(2011) / Mississippi West (ftUS) - EPSG:6510' },
  { value: '6511', label: 'NAD83(2011) / Missouri Central - EPSG:6511' },
  { value: '6512', label: 'NAD83(2011) / Missouri East - EPSG:6512' },
  { value: '6513', label: 'NAD83(2011) / Missouri West - EPSG:6513' },
  { value: '6514', label: 'NAD83(2011) / Montana - EPSG:6514' },
  { value: '6515', label: 'NAD83(2011) / Montana (ft) - EPSG:6515' },
  { value: '6516', label: 'NAD83(2011) / Nebraska - EPSG:6516' },
  { value: '6518', label: 'NAD83(2011) / Nevada Central - EPSG:6518' },
  { value: '6519', label: 'NAD83(2011) / Nevada Central (ftUS) - EPSG:6519' },
  { value: '6520', label: 'NAD83(2011) / Nevada East - EPSG:6520' },
  { value: '6521', label: 'NAD83(2011) / Nevada East (ftUS) - EPSG:6521' },
  { value: '6522', label: 'NAD83(2011) / Nevada West - EPSG:6522' },
  { value: '6523', label: 'NAD83(2011) / Nevada West (ftUS) - EPSG:6523' },
  { value: '6524', label: 'NAD83(2011) / New Hampshire - EPSG:6524' },
  { value: '6525', label: 'NAD83(2011) / New Hampshire (ftUS) - EPSG:6525' },
  { value: '6526', label: 'NAD83(2011) / New Jersey - EPSG:6526' },
  { value: '6527', label: 'NAD83(2011) / New Jersey (ftUS) - EPSG:6527' },
  { value: '6528', label: 'NAD83(2011) / New Mexico Central - EPSG:6528' },
  {
    value: '6529',
    label: 'NAD83(2011) / New Mexico Central (ftUS) - EPSG:6529',
  },
  { value: '6530', label: 'NAD83(2011) / New Mexico East - EPSG:6530' },
  { value: '6531', label: 'NAD83(2011) / New Mexico East (ftUS) - EPSG:6531' },
  { value: '6532', label: 'NAD83(2011) / New Mexico West - EPSG:6532' },
  { value: '6533', label: 'NAD83(2011) / New Mexico West (ftUS) - EPSG:6533' },
  { value: '6534', label: 'NAD83(2011) / New York Central - EPSG:6534' },
  { value: '6535', label: 'NAD83(2011) / New York Central (ftUS) - EPSG:6535' },
  { value: '6536', label: 'NAD83(2011) / New York East - EPSG:6536' },
  { value: '6537', label: 'NAD83(2011) / New York East (ftUS) - EPSG:6537' },
  { value: '6538', label: 'NAD83(2011) / New York Long Island - EPSG:6538' },
  {
    value: '6539',
    label: 'NAD83(2011) / New York Long Island (ftUS) - EPSG:6539',
  },
  { value: '6540', label: 'NAD83(2011) / New York West - EPSG:6540' },
  { value: '6541', label: 'NAD83(2011) / New York West (ftUS) - EPSG:6541' },
  { value: '6542', label: 'NAD83(2011) / North Carolina - EPSG:6542' },
  { value: '6543', label: 'NAD83(2011) / North Carolina (ftUS) - EPSG:6543' },
  { value: '6544', label: 'NAD83(2011) / North Dakota North - EPSG:6544' },
  { value: '6545', label: 'NAD83(2011) / North Dakota North (ft) - EPSG:6545' },
  { value: '6546', label: 'NAD83(2011) / North Dakota South - EPSG:6546' },
  { value: '6547', label: 'NAD83(2011) / North Dakota South (ft) - EPSG:6547' },
  { value: '6548', label: 'NAD83(2011) / Ohio North - EPSG:6548' },
  { value: '6549', label: 'NAD83(2011) / Ohio North (ftUS) - EPSG:6549' },
  { value: '6550', label: 'NAD83(2011) / Ohio South - EPSG:6550' },
  { value: '6551', label: 'NAD83(2011) / Ohio South (ftUS) - EPSG:6551' },
  { value: '6552', label: 'NAD83(2011) / Oklahoma North - EPSG:6552' },
  { value: '6553', label: 'NAD83(2011) / Oklahoma North (ftUS) - EPSG:6553' },
  { value: '6554', label: 'NAD83(2011) / Oklahoma South - EPSG:6554' },
  { value: '6555', label: 'NAD83(2011) / Oklahoma South (ftUS) - EPSG:6555' },
  { value: '6556', label: 'NAD83(2011) / Oregon LCC (m) - EPSG:6556' },
  { value: '6557', label: 'NAD83(2011) / Oregon GIC Lambert (ft) - EPSG:6557' },
  { value: '6558', label: 'NAD83(2011) / Oregon North - EPSG:6558' },
  { value: '6559', label: 'NAD83(2011) / Oregon North (ft) - EPSG:6559' },
  { value: '6560', label: 'NAD83(2011) / Oregon South - EPSG:6560' },
  { value: '6561', label: 'NAD83(2011) / Oregon South (ft) - EPSG:6561' },
  { value: '6562', label: 'NAD83(2011) / Pennsylvania North - EPSG:6562' },
  {
    value: '6563',
    label: 'NAD83(2011) / Pennsylvania North (ftUS) - EPSG:6563',
  },
  { value: '6564', label: 'NAD83(2011) / Pennsylvania South - EPSG:6564' },
  {
    value: '6565',
    label: 'NAD83(2011) / Pennsylvania South (ftUS) - EPSG:6565',
  },
  {
    value: '6566',
    label: 'NAD83(2011) / Puerto Rico and Virgin Is. - EPSG:6566',
  },
  { value: '6567', label: 'NAD83(2011) / Rhode Island - EPSG:6567' },
  { value: '6568', label: 'NAD83(2011) / Rhode Island (ftUS) - EPSG:6568' },
  { value: '6569', label: 'NAD83(2011) / South Carolina - EPSG:6569' },
  { value: '6570', label: 'NAD83(2011) / South Carolina (ft) - EPSG:6570' },
  { value: '6571', label: 'NAD83(2011) / South Dakota North - EPSG:6571' },
  {
    value: '6572',
    label: 'NAD83(2011) / South Dakota North (ftUS) - EPSG:6572',
  },
  { value: '6573', label: 'NAD83(2011) / South Dakota South - EPSG:6573' },
  {
    value: '6574',
    label: 'NAD83(2011) / South Dakota South (ftUS) - EPSG:6574',
  },
  { value: '6575', label: 'NAD83(2011) / Tennessee - EPSG:6575' },
  { value: '6576', label: 'NAD83(2011) / Tennessee (ftUS) - EPSG:6576' },
  { value: '6577', label: 'NAD83(2011) / Texas Central - EPSG:6577' },
  { value: '6578', label: 'NAD83(2011) / Texas Central (ftUS) - EPSG:6578' },
  {
    value: '6579',
    label: 'NAD83(2011) / Texas Centric Albers Equal Area - EPSG:6579',
  },
  {
    value: '6580',
    label: 'NAD83(2011) / Texas Centric Lambert Conformal - EPSG:6580',
  },
  { value: '6581', label: 'NAD83(2011) / Texas North - EPSG:6581' },
  { value: '6582', label: 'NAD83(2011) / Texas North (ftUS) - EPSG:6582' },
  { value: '6583', label: 'NAD83(2011) / Texas North Central - EPSG:6583' },
  {
    value: '6584',
    label: 'NAD83(2011) / Texas North Central (ftUS) - EPSG:6584',
  },
  { value: '6585', label: 'NAD83(2011) / Texas South - EPSG:6585' },
  { value: '6586', label: 'NAD83(2011) / Texas South (ftUS) - EPSG:6586' },
  { value: '6587', label: 'NAD83(2011) / Texas South Central - EPSG:6587' },
  {
    value: '6588',
    label: 'NAD83(2011) / Texas South Central (ftUS) - EPSG:6588',
  },
  { value: '6589', label: 'NAD83(2011) / Vermont - EPSG:6589' },
  { value: '6590', label: 'NAD83(2011) / Vermont (ftUS) - EPSG:6590' },
  { value: '6591', label: 'NAD83(2011) / Virginia Lambert - EPSG:6591' },
  { value: '6592', label: 'NAD83(2011) / Virginia North - EPSG:6592' },
  { value: '6593', label: 'NAD83(2011) / Virginia North (ftUS) - EPSG:6593' },
  { value: '6594', label: 'NAD83(2011) / Virginia South - EPSG:6594' },
  { value: '6595', label: 'NAD83(2011) / Virginia South (ftUS) - EPSG:6595' },
  { value: '6596', label: 'NAD83(2011) / Washington North - EPSG:6596' },
  { value: '6597', label: 'NAD83(2011) / Washington North (ftUS) - EPSG:6597' },
  { value: '6598', label: 'NAD83(2011) / Washington South - EPSG:6598' },
  { value: '6599', label: 'NAD83(2011) / Washington South (ftUS) - EPSG:6599' },
  { value: '6600', label: 'NAD83(2011) / West Virginia North - EPSG:6600' },
  {
    value: '6601',
    label: 'NAD83(2011) / West Virginia North (ftUS) - EPSG:6601',
  },
  { value: '6602', label: 'NAD83(2011) / West Virginia South - EPSG:6602' },
  {
    value: '6603',
    label: 'NAD83(2011) / West Virginia South (ftUS) - EPSG:6603',
  },
  {
    value: '6605',
    label: 'NAD83(2011) / Wisconsin Central (ftUS) - EPSG:6605',
  },
  { value: '6606', label: 'NAD83(2011) / Wisconsin North - EPSG:6606' },
  { value: '6607', label: 'NAD83(2011) / Wisconsin North (ftUS) - EPSG:6607' },
  { value: '6608', label: 'NAD83(2011) / Wisconsin South - EPSG:6608' },
  { value: '6609', label: 'NAD83(2011) / Wisconsin South (ftUS) - EPSG:6609' },
  {
    value: '6610',
    label: 'NAD83(2011) / Wisconsin Transverse Mercator - EPSG:6610',
  },
  { value: '6611', label: 'NAD83(2011) / Wyoming East - EPSG:6611' },
  { value: '6612', label: 'NAD83(2011) / Wyoming East (ftUS) - EPSG:6612' },
  { value: '6613', label: 'NAD83(2011) / Wyoming East Central - EPSG:6613' },
  {
    value: '6614',
    label: 'NAD83(2011) / Wyoming East Central (ftUS) - EPSG:6614',
  },
  { value: '6615', label: 'NAD83(2011) / Wyoming West - EPSG:6615' },
  { value: '6616', label: 'NAD83(2011) / Wyoming West (ftUS) - EPSG:6616' },
  { value: '6617', label: 'NAD83(2011) / Wyoming West Central - EPSG:6617' },
  {
    value: '6618',
    label: 'NAD83(2011) / Wyoming West Central (ftUS) - EPSG:6618',
  },
  { value: '6619', label: 'NAD83(2011) / Utah Central - EPSG:6619' },
  { value: '6620', label: 'NAD83(2011) / Utah North - EPSG:6620' },
  { value: '6621', label: 'NAD83(2011) / Utah South - EPSG:6621' },
  { value: '6622', label: 'NAD83(CSRS) / Quebec Lambert - EPSG:6622' },
  { value: '6623', label: 'NAD83 / Quebec Albers - EPSG:6623' },
  { value: '6624', label: 'NAD83(CSRS) / Quebec Albers - EPSG:6624' },
  { value: '6625', label: 'NAD83(2011) / Utah Central (ftUS) - EPSG:6625' },
  { value: '6626', label: 'NAD83(2011) / Utah North (ftUS) - EPSG:6626' },
  { value: '6627', label: 'NAD83(2011) / Utah South (ftUS) - EPSG:6627' },
  { value: '6628', label: 'NAD83(PA11) / Hawaii zone 1 - EPSG:6628' },
  { value: '6629', label: 'NAD83(PA11) / Hawaii zone 2 - EPSG:6629' },
  { value: '6630', label: 'NAD83(PA11) / Hawaii zone 3 - EPSG:6630' },
  { value: '6631', label: 'NAD83(PA11) / Hawaii zone 4 - EPSG:6631' },
  { value: '6632', label: 'NAD83(PA11) / Hawaii zone 5 - EPSG:6632' },
  { value: '6633', label: 'NAD83(PA11) / Hawaii zone 3 (ftUS) - EPSG:6633' },
  { value: '6634', label: 'NAD83(PA11) / UTM zone 4N - EPSG:6634' },
  { value: '6635', label: 'NAD83(PA11) / UTM zone 5N - EPSG:6635' },
  { value: '6636', label: 'NAD83(PA11) / UTM zone 2S - EPSG:6636' },
  { value: '6637', label: 'NAD83(MA11) / Guam Map Grid - EPSG:6637' },
  { value: '6646', label: 'Karbala 1979 / Iraq National Grid - EPSG:6646' },
  {
    value: '6669',
    label: 'JGD2011 / Japan Plane Rectangular CS I - EPSG:6669',
  },
  {
    value: '6670',
    label: 'JGD2011 / Japan Plane Rectangular CS II - EPSG:6670',
  },
  {
    value: '6671',
    label: 'JGD2011 / Japan Plane Rectangular CS III - EPSG:6671',
  },
  {
    value: '6672',
    label: 'JGD2011 / Japan Plane Rectangular CS IV - EPSG:6672',
  },
  {
    value: '6673',
    label: 'JGD2011 / Japan Plane Rectangular CS V - EPSG:6673',
  },
  {
    value: '6674',
    label: 'JGD2011 / Japan Plane Rectangular CS VI - EPSG:6674',
  },
  {
    value: '6675',
    label: 'JGD2011 / Japan Plane Rectangular CS VII - EPSG:6675',
  },
  {
    value: '6676',
    label: 'JGD2011 / Japan Plane Rectangular CS VIII - EPSG:6676',
  },
  {
    value: '6677',
    label: 'JGD2011 / Japan Plane Rectangular CS IX - EPSG:6677',
  },
  {
    value: '6678',
    label: 'JGD2011 / Japan Plane Rectangular CS X - EPSG:6678',
  },
  {
    value: '6679',
    label: 'JGD2011 / Japan Plane Rectangular CS XI - EPSG:6679',
  },
  {
    value: '6680',
    label: 'JGD2011 / Japan Plane Rectangular CS XII - EPSG:6680',
  },
  {
    value: '6681',
    label: 'JGD2011 / Japan Plane Rectangular CS XIII - EPSG:6681',
  },
  {
    value: '6682',
    label: 'JGD2011 / Japan Plane Rectangular CS XIV - EPSG:6682',
  },
  {
    value: '6683',
    label: 'JGD2011 / Japan Plane Rectangular CS XV - EPSG:6683',
  },
  {
    value: '6684',
    label: 'JGD2011 / Japan Plane Rectangular CS XVI - EPSG:6684',
  },
  {
    value: '6685',
    label: 'JGD2011 / Japan Plane Rectangular CS XVII - EPSG:6685',
  },
  {
    value: '6686',
    label: 'JGD2011 / Japan Plane Rectangular CS XVIII - EPSG:6686',
  },
  {
    value: '6687',
    label: 'JGD2011 / Japan Plane Rectangular CS XIX - EPSG:6687',
  },
  { value: '6688', label: 'JGD2011 / UTM zone 51N - EPSG:6688' },
  { value: '6689', label: 'JGD2011 / UTM zone 52N - EPSG:6689' },
  { value: '6690', label: 'JGD2011 / UTM zone 53N - EPSG:6690' },
  { value: '6691', label: 'JGD2011 / UTM zone 54N - EPSG:6691' },
  { value: '6692', label: 'JGD2011 / UTM zone 55N - EPSG:6692' },
  { value: '6703', label: 'WGS 84 / TM 60 SW - EPSG:6703' },
  { value: '6707', label: 'RDN2008 / UTM zone 32N (N-E) - EPSG:6707' },
  { value: '6708', label: 'RDN2008 / UTM zone 33N (N-E) - EPSG:6708' },
  { value: '6709', label: 'RDN2008 / UTM zone 34N (N-E) - EPSG:6709' },
  { value: '6720', label: 'WGS 84 / CIG92 - EPSG:6720' },
  { value: '6721', label: 'GDA94 / CIG94 - EPSG:6721' },
  { value: '6722', label: 'WGS 84 / CKIG92 - EPSG:6722' },
  { value: '6723', label: 'GDA94 / CKIG94 - EPSG:6723' },
  { value: '6736', label: 'GDA94 / MGA zone 46 - EPSG:6736' },
  { value: '6737', label: 'GDA94 / MGA zone 47 - EPSG:6737' },
  { value: '6738', label: 'GDA94 / MGA zone 59 - EPSG:6738' },
  { value: '6784', label: 'NAD83(CORS96) / Oregon Baker zone (m) - EPSG:6784' },
  {
    value: '6785',
    label: 'NAD83(CORS96) / Oregon Baker zone (ft) - EPSG:6785',
  },
  { value: '6786', label: 'NAD83(2011) / Oregon Baker zone (m) - EPSG:6786' },
  { value: '6787', label: 'NAD83(2011) / Oregon Baker zone (ft) - EPSG:6787' },
  {
    value: '6788',
    label: 'NAD83(CORS96) / Oregon Bend-Klamath Falls zone (m) - EPSG:6788',
  },
  {
    value: '6789',
    label: 'NAD83(CORS96) / Oregon Bend-Klamath Falls zone (ft) - EPSG:6789',
  },
  {
    value: '6790',
    label: 'NAD83(2011) / Oregon Bend-Klamath Falls zone (m) - EPSG:6790',
  },
  {
    value: '6791',
    label: 'NAD83(2011) / Oregon Bend-Klamath Falls zone (ft) - EPSG:6791',
  },
  {
    value: '6792',
    label: 'NAD83(CORS96) / Oregon Bend-Redmond-Prineville zone (m) - EPSG:6792',
  },
  {
    value: '6793',
    label: 'NAD83(CORS96) / Oregon Bend-Redmond-Prineville zone (ft) - EPSG:6793',
  },
  {
    value: '6794',
    label: 'NAD83(2011) / Oregon Bend-Redmond-Prineville zone (m) - EPSG:6794',
  },
  {
    value: '6795',
    label: 'NAD83(2011) / Oregon Bend-Redmond-Prineville zone (ft) - EPSG:6795',
  },
  {
    value: '6796',
    label: 'NAD83(CORS96) / Oregon Bend-Burns zone (m) - EPSG:6796',
  },
  {
    value: '6797',
    label: 'NAD83(CORS96) / Oregon Bend-Burns zone (ft) - EPSG:6797',
  },
  {
    value: '6798',
    label: 'NAD83(2011) / Oregon Bend-Burns zone (m) - EPSG:6798',
  },
  {
    value: '6799',
    label: 'NAD83(2011) / Oregon Bend-Burns zone (ft) - EPSG:6799',
  },
  {
    value: '6800',
    label: 'NAD83(CORS96) / Oregon Canyonville-Grants Pass zone (m) - EPSG:6800',
  },
  {
    value: '6801',
    label: 'NAD83(CORS96) / Oregon Canyonville-Grants Pass zone (ft) - EPSG:6801',
  },
  {
    value: '6802',
    label: 'NAD83(2011) / Oregon Canyonville-Grants Pass zone (m) - EPSG:6802',
  },
  {
    value: '6803',
    label: 'NAD83(2011) / Oregon Canyonville-Grants Pass zone (ft) - EPSG:6803',
  },
  {
    value: '6804',
    label: 'NAD83(CORS96) / Oregon Columbia River East zone (m) - EPSG:6804',
  },
  {
    value: '6805',
    label: 'NAD83(CORS96) / Oregon Columbia River East zone (ft) - EPSG:6805',
  },
  {
    value: '6806',
    label: 'NAD83(2011) / Oregon Columbia River East zone (m) - EPSG:6806',
  },
  {
    value: '6807',
    label: 'NAD83(2011) / Oregon Columbia River East zone (ft) - EPSG:6807',
  },
  {
    value: '6808',
    label: 'NAD83(CORS96) / Oregon Columbia River West zone (m) - EPSG:6808',
  },
  {
    value: '6809',
    label: 'NAD83(CORS96) / Oregon Columbia River West zone (ft) - EPSG:6809',
  },
  {
    value: '6810',
    label: 'NAD83(2011) / Oregon Columbia River West zone (m) - EPSG:6810',
  },
  {
    value: '6811',
    label: 'NAD83(2011) / Oregon Columbia River West zone (ft) - EPSG:6811',
  },
  {
    value: '6812',
    label: 'NAD83(CORS96) / Oregon Cottage Grove-Canyonville zone (m) - EPSG:6812',
  },
  {
    value: '6813',
    label: 'NAD83(CORS96) / Oregon Cottage Grove-Canyonville zone (ft) - EPSG:6813',
  },
  {
    value: '6814',
    label: 'NAD83(2011) / Oregon Cottage Grove-Canyonville zone (m) - EPSG:6814',
  },
  {
    value: '6815',
    label: 'NAD83(2011) / Oregon Cottage Grove-Canyonville zone (ft) - EPSG:6815',
  },
  {
    value: '6816',
    label: 'NAD83(CORS96) / Oregon Dufur-Madras zone (m) - EPSG:6816',
  },
  {
    value: '6817',
    label: 'NAD83(CORS96) / Oregon Dufur-Madras zone (ft) - EPSG:6817',
  },
  {
    value: '6818',
    label: 'NAD83(2011) / Oregon Dufur-Madras zone (m) - EPSG:6818',
  },
  {
    value: '6819',
    label: 'NAD83(2011) / Oregon Dufur-Madras zone (ft) - EPSG:6819',
  },
  {
    value: '6820',
    label: 'NAD83(CORS96) / Oregon Eugene zone (m) - EPSG:6820',
  },
  {
    value: '6821',
    label: 'NAD83(CORS96) / Oregon Eugene zone (ft) - EPSG:6821',
  },
  { value: '6822', label: 'NAD83(2011) / Oregon Eugene zone (m) - EPSG:6822' },
  { value: '6823', label: 'NAD83(2011) / Oregon Eugene zone (ft) - EPSG:6823' },
  {
    value: '6824',
    label: 'NAD83(CORS96) / Oregon Grants Pass-Ashland zone (m) - EPSG:6824',
  },
  {
    value: '6825',
    label: 'NAD83(CORS96) / Oregon Grants Pass-Ashland zone (ft) - EPSG:6825',
  },
  {
    value: '6826',
    label: 'NAD83(2011) / Oregon Grants Pass-Ashland zone (m) - EPSG:6826',
  },
  {
    value: '6827',
    label: 'NAD83(2011) / Oregon Grants Pass-Ashland zone (ft) - EPSG:6827',
  },
  {
    value: '6828',
    label: 'NAD83(CORS96) / Oregon Gresham-Warm Springs zone (m) - EPSG:6828',
  },
  {
    value: '6829',
    label: 'NAD83(CORS96) / Oregon Gresham-Warm Springs zone (ft) - EPSG:6829',
  },
  {
    value: '6830',
    label: 'NAD83(2011) / Oregon Gresham-Warm Springs zone (m) - EPSG:6830',
  },
  {
    value: '6831',
    label: 'NAD83(2011) / Oregon Gresham-Warm Springs zone (ft) - EPSG:6831',
  },
  {
    value: '6832',
    label: 'NAD83(CORS96) / Oregon La Grande zone (m) - EPSG:6832',
  },
  {
    value: '6833',
    label: 'NAD83(CORS96) / Oregon La Grande zone (ft) - EPSG:6833',
  },
  {
    value: '6834',
    label: 'NAD83(2011) / Oregon La Grande zone (m) - EPSG:6834',
  },
  {
    value: '6835',
    label: 'NAD83(2011) / Oregon La Grande zone (ft) - EPSG:6835',
  },
  {
    value: '6836',
    label: 'NAD83(CORS96) / Oregon Ontario zone (m) - EPSG:6836',
  },
  {
    value: '6837',
    label: 'NAD83(CORS96) / Oregon Ontario zone (ft) - EPSG:6837',
  },
  { value: '6838', label: 'NAD83(2011) / Oregon Ontario zone (m) - EPSG:6838' },
  {
    value: '6839',
    label: 'NAD83(2011) / Oregon Ontario zone (ft) - EPSG:6839',
  },
  { value: '6840', label: 'NAD83(CORS96) / Oregon Coast zone (m) - EPSG:6840' },
  {
    value: '6841',
    label: 'NAD83(CORS96) / Oregon Coast zone (ft) - EPSG:6841',
  },
  { value: '6842', label: 'NAD83(2011) / Oregon Coast zone (m) - EPSG:6842' },
  { value: '6843', label: 'NAD83(2011) / Oregon Coast zone (ft) - EPSG:6843' },
  {
    value: '6844',
    label: 'NAD83(CORS96) / Oregon Pendleton zone (m) - EPSG:6844',
  },
  {
    value: '6845',
    label: 'NAD83(CORS96) / Oregon Pendleton zone (ft) - EPSG:6845',
  },
  {
    value: '6846',
    label: 'NAD83(2011) / Oregon Pendleton zone (m) - EPSG:6846',
  },
  {
    value: '6847',
    label: 'NAD83(2011) / Oregon Pendleton zone (ft) - EPSG:6847',
  },
  {
    value: '6848',
    label: 'NAD83(CORS96) / Oregon Pendleton-La Grande zone (m) - EPSG:6848',
  },
  {
    value: '6849',
    label: 'NAD83(CORS96) / Oregon Pendleton-La Grande zone (ft) - EPSG:6849',
  },
  {
    value: '6850',
    label: 'NAD83(2011) / Oregon Pendleton-La Grande zone (m) - EPSG:6850',
  },
  {
    value: '6851',
    label: 'NAD83(2011) / Oregon Pendleton-La Grande zone (ft) - EPSG:6851',
  },
  {
    value: '6852',
    label: 'NAD83(CORS96) / Oregon Portland zone (m) - EPSG:6852',
  },
  {
    value: '6853',
    label: 'NAD83(CORS96) / Oregon Portland zone (ft) - EPSG:6853',
  },
  {
    value: '6854',
    label: 'NAD83(2011) / Oregon Portland zone (m) - EPSG:6854',
  },
  {
    value: '6855',
    label: 'NAD83(2011) / Oregon Portland zone (ft) - EPSG:6855',
  },
  { value: '6856', label: 'NAD83(CORS96) / Oregon Salem zone (m) - EPSG:6856' },
  {
    value: '6857',
    label: 'NAD83(CORS96) / Oregon Salem zone (ft) - EPSG:6857',
  },
  { value: '6858', label: 'NAD83(2011) / Oregon Salem zone (m) - EPSG:6858' },
  { value: '6859', label: 'NAD83(2011) / Oregon Salem zone (ft) - EPSG:6859' },
  {
    value: '6860',
    label: 'NAD83(CORS96) / Oregon Santiam Pass zone (m) - EPSG:6860',
  },
  {
    value: '6861',
    label: 'NAD83(CORS96) / Oregon Santiam Pass zone (ft) - EPSG:6861',
  },
  {
    value: '6862',
    label: 'NAD83(2011) / Oregon Santiam Pass zone (m) - EPSG:6862',
  },
  {
    value: '6863',
    label: 'NAD83(2011) / Oregon Santiam Pass zone (ft) - EPSG:6863',
  },
  { value: '6867', label: 'NAD83(CORS96) / Oregon LCC (m) - EPSG:6867' },
  {
    value: '6868',
    label: 'NAD83(CORS96) / Oregon GIC Lambert (ft) - EPSG:6868',
  },
  { value: '6870', label: 'ETRS89 / Albania TM 2010 - EPSG:6870' },
  { value: '6875', label: 'RDN2008 / Italy zone (N-E) - EPSG:6875' },
  { value: '6876', label: 'RDN2008 / Zone 12 (N-E) - EPSG:6876' },
  { value: '6879', label: 'NAD83(2011) / Wisconsin Central - EPSG:6879' },
  { value: '6880', label: 'NAD83(2011) / Nebraska (ftUS) - EPSG:6880' },
  { value: '6884', label: 'NAD83(CORS96) / Oregon North - EPSG:6884' },
  { value: '6885', label: 'NAD83(CORS96) / Oregon North (ft) - EPSG:6885' },
  { value: '6886', label: 'NAD83(CORS96) / Oregon South - EPSG:6886' },
  { value: '6887', label: 'NAD83(CORS96) / Oregon South (ft) - EPSG:6887' },
  { value: '6915', label: 'South East Island 1943 / UTM zone 40N - EPSG:6915' },
  { value: '6922', label: 'NAD83 / Kansas LCC - EPSG:6922' },
  { value: '6923', label: 'NAD83 / Kansas LCC (ftUS) - EPSG:6923' },
  { value: '6924', label: 'NAD83(2011) / Kansas LCC - EPSG:6924' },
  { value: '6925', label: 'NAD83(2011) / Kansas LCC (ftUS) - EPSG:6925' },
  { value: '6931', label: 'WGS 84 / NSIDC EASE-Grid 2.0 North - EPSG:6931' },
  { value: '6932', label: 'WGS 84 / NSIDC EASE-Grid 2.0 South - EPSG:6932' },
  { value: '6933', label: 'WGS 84 / NSIDC EASE-Grid 2.0 Global - EPSG:6933' },
  { value: '6956', label: 'VN-2000 / TM-3 zone 481 - EPSG:6956' },
  { value: '6957', label: 'VN-2000 / TM-3 zone 482 - EPSG:6957' },
  { value: '6958', label: 'VN-2000 / TM-3 zone 491 - EPSG:6958' },
  { value: '6959', label: 'VN-2000 / TM-3 Da Nang zone - EPSG:6959' },
  { value: '6962', label: 'ETRS89 / Albania LCC 2010 - EPSG:6962' },
  { value: '6966', label: 'NAD27 / Michigan North - EPSG:6966' },
  { value: '6984', label: 'Israeli Grid 05 - EPSG:6984' },
  { value: '6991', label: 'Israeli Grid 05/12 - EPSG:6991' },
  { value: '7005', label: 'Nahrwan 1934 / UTM zone 37N - EPSG:7005' },
  { value: '7006', label: 'Nahrwan 1934 / UTM zone 38N - EPSG:7006' },
  { value: '7007', label: 'Nahrwan 1934 / UTM zone 39N - EPSG:7007' },
  { value: '7057', label: 'NAD83(2011) / IaRCS zone 1 - EPSG:7057' },
  { value: '7058', label: 'NAD83(2011) / IaRCS zone 2 - EPSG:7058' },
  { value: '7059', label: 'NAD83(2011) / IaRCS zone 3 - EPSG:7059' },
  { value: '7060', label: 'NAD83(2011) / IaRCS zone 4 - EPSG:7060' },
  { value: '7061', label: 'NAD83(2011) / IaRCS zone 5 - EPSG:7061' },
  { value: '7062', label: 'NAD83(2011) / IaRCS zone 6 - EPSG:7062' },
  { value: '7063', label: 'NAD83(2011) / IaRCS zone 7 - EPSG:7063' },
  { value: '7064', label: 'NAD83(2011) / IaRCS zone 8 - EPSG:7064' },
  { value: '7065', label: 'NAD83(2011) / IaRCS zone 9 - EPSG:7065' },
  { value: '7066', label: 'NAD83(2011) / IaRCS zone 10 - EPSG:7066' },
  { value: '7067', label: 'NAD83(2011) / IaRCS zone 11 - EPSG:7067' },
  { value: '7068', label: 'NAD83(2011) / IaRCS zone 12 - EPSG:7068' },
  { value: '7069', label: 'NAD83(2011) / IaRCS zone 13 - EPSG:7069' },
  { value: '7070', label: 'NAD83(2011) / IaRCS zone 14 - EPSG:7070' },
  { value: '7074', label: 'RGTAAF07 / UTM zone 37S - EPSG:7074' },
  { value: '7075', label: 'RGTAAF07 / UTM zone 38S - EPSG:7075' },
  { value: '7076', label: 'RGTAAF07 / UTM zone 39S - EPSG:7076' },
  { value: '7077', label: 'RGTAAF07 / UTM zone 40S - EPSG:7077' },
  { value: '7078', label: 'RGTAAF07 / UTM zone 41S - EPSG:7078' },
  { value: '7079', label: 'RGTAAF07 / UTM zone 42S - EPSG:7079' },
  { value: '7080', label: 'RGTAAF07 / UTM zone 43S - EPSG:7080' },
  { value: '7081', label: 'RGTAAF07 / UTM zone 44S - EPSG:7081' },
  {
    value: '7082',
    label: 'RGTAAF07 / Terre Adelie Polar Stereographic - EPSG:7082',
  },
  { value: '7109', label: 'NAD83(2011) / RMTCRS St Mary (m) - EPSG:7109' },
  { value: '7110', label: 'NAD83(2011) / RMTCRS Blackfeet (m) - EPSG:7110' },
  { value: '7111', label: 'NAD83(2011) / RMTCRS Milk River (m) - EPSG:7111' },
  { value: '7112', label: 'NAD83(2011) / RMTCRS Fort Belknap (m) - EPSG:7112' },
  {
    value: '7113',
    label: 'NAD83(2011) / RMTCRS Fort Peck Assiniboine (m) - EPSG:7113',
  },
  {
    value: '7114',
    label: 'NAD83(2011) / RMTCRS Fort Peck Sioux (m) - EPSG:7114',
  },
  { value: '7115', label: 'NAD83(2011) / RMTCRS Crow (m) - EPSG:7115' },
  { value: '7116', label: 'NAD83(2011) / RMTCRS Bobcat (m) - EPSG:7116' },
  { value: '7117', label: 'NAD83(2011) / RMTCRS Billings (m) - EPSG:7117' },
  { value: '7118', label: 'NAD83(2011) / RMTCRS Wind River (m) - EPSG:7118' },
  { value: '7119', label: 'NAD83(2011) / RMTCRS St Mary (ft) - EPSG:7119' },
  { value: '7120', label: 'NAD83(2011) / RMTCRS Blackfeet (ft) - EPSG:7120' },
  { value: '7121', label: 'NAD83(2011) / RMTCRS Milk River (ft) - EPSG:7121' },
  {
    value: '7122',
    label: 'NAD83(2011) / RMTCRS Fort Belknap (ft) - EPSG:7122',
  },
  {
    value: '7123',
    label: 'NAD83(2011) / RMTCRS Fort Peck Assiniboine (ft) - EPSG:7123',
  },
  {
    value: '7124',
    label: 'NAD83(2011) / RMTCRS Fort Peck Sioux (ft) - EPSG:7124',
  },
  { value: '7125', label: 'NAD83(2011) / RMTCRS Crow (ft) - EPSG:7125' },
  { value: '7126', label: 'NAD83(2011) / RMTCRS Bobcat (ft) - EPSG:7126' },
  { value: '7127', label: 'NAD83(2011) / RMTCRS Billings (ft) - EPSG:7127' },
  {
    value: '7128',
    label: 'NAD83(2011) / RMTCRS Wind River (ftUS) - EPSG:7128',
  },
  { value: '7131', label: 'NAD83(2011) / San Francisco CS13 - EPSG:7131' },
  {
    value: '7132',
    label: 'NAD83(2011) / San Francisco CS13 (ftUS) - EPSG:7132',
  },
  {
    value: '7142',
    label: 'Palestine 1923 / Palestine Grid modified - EPSG:7142',
  },
  { value: '7257', label: 'NAD83(2011) / InGCS Adams (m) - EPSG:7257' },
  { value: '7258', label: 'NAD83(2011) / InGCS Adams (ftUS) - EPSG:7258' },
  { value: '7259', label: 'NAD83(2011) / InGCS Allen (m) - EPSG:7259' },
  { value: '7260', label: 'NAD83(2011) / InGCS Allen (ftUS) - EPSG:7260' },
  { value: '7261', label: 'NAD83(2011) / InGCS Bartholomew (m) - EPSG:7261' },
  {
    value: '7262',
    label: 'NAD83(2011) / InGCS Bartholomew (ftUS) - EPSG:7262',
  },
  { value: '7263', label: 'NAD83(2011) / InGCS Benton (m) - EPSG:7263' },
  { value: '7264', label: 'NAD83(2011) / InGCS Benton (ftUS) - EPSG:7264' },
  {
    value: '7265',
    label: 'NAD83(2011) / InGCS Blackford-Delaware (m) - EPSG:7265',
  },
  {
    value: '7266',
    label: 'NAD83(2011) / InGCS Blackford-Delaware (ftUS) - EPSG:7266',
  },
  {
    value: '7267',
    label: 'NAD83(2011) / InGCS Boone-Hendricks (m) - EPSG:7267',
  },
  {
    value: '7268',
    label: 'NAD83(2011) / InGCS Boone-Hendricks (ftUS) - EPSG:7268',
  },
  { value: '7269', label: 'NAD83(2011) / InGCS Brown (m) - EPSG:7269' },
  { value: '7270', label: 'NAD83(2011) / InGCS Brown (ftUS) - EPSG:7270' },
  { value: '7271', label: 'NAD83(2011) / InGCS Carroll (m) - EPSG:7271' },
  { value: '7272', label: 'NAD83(2011) / InGCS Carroll (ftUS) - EPSG:7272' },
  { value: '7273', label: 'NAD83(2011) / InGCS Cass (m) - EPSG:7273' },
  { value: '7274', label: 'NAD83(2011) / InGCS Cass (ftUS) - EPSG:7274' },
  {
    value: '7275',
    label: 'NAD83(2011) / InGCS Clark-Floyd-Scott (m) - EPSG:7275',
  },
  {
    value: '7276',
    label: 'NAD83(2011) / InGCS Clark-Floyd-Scott (ftUS) - EPSG:7276',
  },
  { value: '7277', label: 'NAD83(2011) / InGCS Clay (m) - EPSG:7277' },
  { value: '7278', label: 'NAD83(2011) / InGCS Clay (ftUS) - EPSG:7278' },
  { value: '7279', label: 'NAD83(2011) / InGCS Clinton (m) - EPSG:7279' },
  { value: '7280', label: 'NAD83(2011) / InGCS Clinton (ftUS) - EPSG:7280' },
  {
    value: '7281',
    label: 'NAD83(2011) / InGCS Crawford-Lawrence-Orange (m) - EPSG:7281',
  },
  {
    value: '7282',
    label: 'NAD83(2011) / InGCS Crawford-Lawrence-Orange (ftUS) - EPSG:7282',
  },
  {
    value: '7283',
    label: 'NAD83(2011) / InGCS Daviess-Greene (m) - EPSG:7283',
  },
  {
    value: '7284',
    label: 'NAD83(2011) / InGCS Daviess-Greene (ftUS) - EPSG:7284',
  },
  {
    value: '7285',
    label: 'NAD83(2011) / InGCS Dearborn-Ohio-Switzerland (m) - EPSG:7285',
  },
  {
    value: '7286',
    label: 'NAD83(2011) / InGCS Dearborn-Ohio-Switzerland (ftUS) - EPSG:7286',
  },
  { value: '7287', label: 'NAD83(2011) / InGCS Decatur-Rush (m) - EPSG:7287' },
  {
    value: '7288',
    label: 'NAD83(2011) / InGCS Decatur-Rush (ftUS) - EPSG:7288',
  },
  { value: '7289', label: 'NAD83(2011) / InGCS DeKalb (m) - EPSG:7289' },
  { value: '7290', label: 'NAD83(2011) / InGCS DeKalb (ftUS) - EPSG:7290' },
  { value: '7291', label: 'NAD83(2011) / InGCS Dubois-Martin (m) - EPSG:7291' },
  {
    value: '7292',
    label: 'NAD83(2011) / InGCS Dubois-Martin (ftUS) - EPSG:7292',
  },
  {
    value: '7293',
    label: 'NAD83(2011) / InGCS Elkhart-Kosciusko-Wabash (m) - EPSG:7293',
  },
  {
    value: '7294',
    label: 'NAD83(2011) / InGCS Elkhart-Kosciusko-Wabash (ftUS) - EPSG:7294',
  },
  {
    value: '7295',
    label: 'NAD83(2011) / InGCS Fayette-Franklin-Union (m) - EPSG:7295',
  },
  {
    value: '7296',
    label: 'NAD83(2011) / InGCS Fayette-Franklin-Union (ftUS) - EPSG:7296',
  },
  {
    value: '7297',
    label: 'NAD83(2011) / InGCS Fountain-Warren (m) - EPSG:7297',
  },
  {
    value: '7298',
    label: 'NAD83(2011) / InGCS Fountain-Warren (ftUS) - EPSG:7298',
  },
  {
    value: '7299',
    label: 'NAD83(2011) / InGCS Fulton-Marshall-St. Joseph (m) - EPSG:7299',
  },
  {
    value: '7300',
    label: 'NAD83(2011) / InGCS Fulton-Marshall-St. Joseph (ftUS) - EPSG:7300',
  },
  { value: '7301', label: 'NAD83(2011) / InGCS Gibson (m) - EPSG:7301' },
  { value: '7302', label: 'NAD83(2011) / InGCS Gibson (ftUS) - EPSG:7302' },
  { value: '7303', label: 'NAD83(2011) / InGCS Grant (m) - EPSG:7303' },
  { value: '7304', label: 'NAD83(2011) / InGCS Grant (ftUS) - EPSG:7304' },
  {
    value: '7305',
    label: 'NAD83(2011) / InGCS Hamilton-Tipton (m) - EPSG:7305',
  },
  {
    value: '7306',
    label: 'NAD83(2011) / InGCS Hamilton-Tipton (ftUS) - EPSG:7306',
  },
  {
    value: '7307',
    label: 'NAD83(2011) / InGCS Hancock-Madison (m) - EPSG:7307',
  },
  {
    value: '7308',
    label: 'NAD83(2011) / InGCS Hancock-Madison (ftUS) - EPSG:7308',
  },
  {
    value: '7309',
    label: 'NAD83(2011) / InGCS Harrison-Washington (m) - EPSG:7309',
  },
  {
    value: '7310',
    label: 'NAD83(2011) / InGCS Harrison-Washington (ftUS) - EPSG:7310',
  },
  { value: '7311', label: 'NAD83(2011) / InGCS Henry (m) - EPSG:7311' },
  { value: '7312', label: 'NAD83(2011) / InGCS Henry (ftUS) - EPSG:7312' },
  { value: '7313', label: 'NAD83(2011) / InGCS Howard-Miami (m) - EPSG:7313' },
  {
    value: '7314',
    label: 'NAD83(2011) / InGCS Howard-Miami (ftUS) - EPSG:7314',
  },
  {
    value: '7315',
    label: 'NAD83(2011) / InGCS Huntington-Whitley (m) - EPSG:7315',
  },
  {
    value: '7316',
    label: 'NAD83(2011) / InGCS Huntington-Whitley (ftUS) - EPSG:7316',
  },
  { value: '7317', label: 'NAD83(2011) / InGCS Jackson (m) - EPSG:7317' },
  { value: '7318', label: 'NAD83(2011) / InGCS Jackson (ftUS) - EPSG:7318' },
  { value: '7319', label: 'NAD83(2011) / InGCS Jasper-Porter (m) - EPSG:7319' },
  {
    value: '7320',
    label: 'NAD83(2011) / InGCS Jasper-Porter (ftUS) - EPSG:7320',
  },
  { value: '7321', label: 'NAD83(2011) / InGCS Jay (m) - EPSG:7321' },
  { value: '7322', label: 'NAD83(2011) / InGCS Jay (ftUS) - EPSG:7322' },
  { value: '7323', label: 'NAD83(2011) / InGCS Jefferson (m) - EPSG:7323' },
  { value: '7324', label: 'NAD83(2011) / InGCS Jefferson (ftUS) - EPSG:7324' },
  { value: '7325', label: 'NAD83(2011) / InGCS Jennings (m) - EPSG:7325' },
  { value: '7326', label: 'NAD83(2011) / InGCS Jennings (ftUS) - EPSG:7326' },
  {
    value: '7327',
    label: 'NAD83(2011) / InGCS Johnson-Marion (m) - EPSG:7327',
  },
  {
    value: '7328',
    label: 'NAD83(2011) / InGCS Johnson-Marion (ftUS) - EPSG:7328',
  },
  { value: '7329', label: 'NAD83(2011) / InGCS Knox (m) - EPSG:7329' },
  { value: '7330', label: 'NAD83(2011) / InGCS Knox (ftUS) - EPSG:7330' },
  {
    value: '7331',
    label: 'NAD83(2011) / InGCS LaGrange-Noble (m) - EPSG:7331',
  },
  {
    value: '7332',
    label: 'NAD83(2011) / InGCS LaGrange-Noble (ftUS) - EPSG:7332',
  },
  { value: '7333', label: 'NAD83(2011) / InGCS Lake-Newton (m) - EPSG:7333' },
  {
    value: '7334',
    label: 'NAD83(2011) / InGCS Lake-Newton (ftUS) - EPSG:7334',
  },
  {
    value: '7335',
    label: 'NAD83(2011) / InGCS LaPorte-Pulaski-Starke (m) - EPSG:7335',
  },
  {
    value: '7336',
    label: 'NAD83(2011) / InGCS LaPorte-Pulaski-Starke (ftUS) - EPSG:7336',
  },
  { value: '7337', label: 'NAD83(2011) / InGCS Monroe-Morgan (m) - EPSG:7337' },
  {
    value: '7338',
    label: 'NAD83(2011) / InGCS Monroe-Morgan (ftUS) - EPSG:7338',
  },
  {
    value: '7339',
    label: 'NAD83(2011) / InGCS Montgomery-Putnam (m) - EPSG:7339',
  },
  {
    value: '7340',
    label: 'NAD83(2011) / InGCS Montgomery-Putnam (ftUS) - EPSG:7340',
  },
  { value: '7341', label: 'NAD83(2011) / InGCS Owen (m) - EPSG:7341' },
  { value: '7342', label: 'NAD83(2011) / InGCS Owen (ftUS) - EPSG:7342' },
  {
    value: '7343',
    label: 'NAD83(2011) / InGCS Parke-Vermillion (m) - EPSG:7343',
  },
  {
    value: '7344',
    label: 'NAD83(2011) / InGCS Parke-Vermillion (ftUS) - EPSG:7344',
  },
  { value: '7345', label: 'NAD83(2011) / InGCS Perry (m) - EPSG:7345' },
  { value: '7346', label: 'NAD83(2011) / InGCS Perry (ftUS) - EPSG:7346' },
  { value: '7347', label: 'NAD83(2011) / InGCS Pike-Warrick (m) - EPSG:7347' },
  {
    value: '7348',
    label: 'NAD83(2011) / InGCS Pike-Warrick (ftUS) - EPSG:7348',
  },
  { value: '7349', label: 'NAD83(2011) / InGCS Posey (m) - EPSG:7349' },
  { value: '7350', label: 'NAD83(2011) / InGCS Posey (ftUS) - EPSG:7350' },
  {
    value: '7351',
    label: 'NAD83(2011) / InGCS Randolph-Wayne (m) - EPSG:7351',
  },
  {
    value: '7352',
    label: 'NAD83(2011) / InGCS Randolph-Wayne (ftUS) - EPSG:7352',
  },
  { value: '7353', label: 'NAD83(2011) / InGCS Ripley (m) - EPSG:7353' },
  { value: '7354', label: 'NAD83(2011) / InGCS Ripley (ftUS) - EPSG:7354' },
  { value: '7355', label: 'NAD83(2011) / InGCS Shelby (m) - EPSG:7355' },
  { value: '7356', label: 'NAD83(2011) / InGCS Shelby (ftUS) - EPSG:7356' },
  { value: '7357', label: 'NAD83(2011) / InGCS Spencer (m) - EPSG:7357' },
  { value: '7358', label: 'NAD83(2011) / InGCS Spencer (ftUS) - EPSG:7358' },
  { value: '7359', label: 'NAD83(2011) / InGCS Steuben (m) - EPSG:7359' },
  { value: '7360', label: 'NAD83(2011) / InGCS Steuben (ftUS) - EPSG:7360' },
  { value: '7361', label: 'NAD83(2011) / InGCS Sullivan (m) - EPSG:7361' },
  { value: '7362', label: 'NAD83(2011) / InGCS Sullivan (ftUS) - EPSG:7362' },
  {
    value: '7363',
    label: 'NAD83(2011) / InGCS Tippecanoe-White (m) - EPSG:7363',
  },
  {
    value: '7364',
    label: 'NAD83(2011) / InGCS Tippecanoe-White (ftUS) - EPSG:7364',
  },
  { value: '7365', label: 'NAD83(2011) / InGCS Vanderburgh (m) - EPSG:7365' },
  {
    value: '7366',
    label: 'NAD83(2011) / InGCS Vanderburgh (ftUS) - EPSG:7366',
  },
  { value: '7367', label: 'NAD83(2011) / InGCS Vigo (m) - EPSG:7367' },
  { value: '7368', label: 'NAD83(2011) / InGCS Vigo (ftUS) - EPSG:7368' },
  { value: '7369', label: 'NAD83(2011) / InGCS Wells (m) - EPSG:7369' },
  { value: '7370', label: 'NAD83(2011) / InGCS Wells (ftUS) - EPSG:7370' },
  { value: '7374', label: 'ONGD14 / UTM zone 39N - EPSG:7374' },
  { value: '7375', label: 'ONGD14 / UTM zone 40N - EPSG:7375' },
  { value: '7376', label: 'ONGD14 / UTM zone 41N - EPSG:7376' },
  {
    value: '7528',
    label: 'NAD83(2011) / WISCRS Adams and Juneau (m) - EPSG:7528',
  },
  { value: '7529', label: 'NAD83(2011) / WISCRS Ashland (m) - EPSG:7529' },
  { value: '7530', label: 'NAD83(2011) / WISCRS Barron (m) - EPSG:7530' },
  { value: '7531', label: 'NAD83(2011) / WISCRS Bayfield (m) - EPSG:7531' },
  { value: '7532', label: 'NAD83(2011) / WISCRS Brown (m) - EPSG:7532' },
  { value: '7533', label: 'NAD83(2011) / WISCRS Buffalo (m) - EPSG:7533' },
  { value: '7534', label: 'NAD83(2011) / WISCRS Burnett (m) - EPSG:7534' },
  {
    value: '7535',
    label: 'NAD83(2011) / WISCRS Calumet, Fond du Lac, Outagamie and Winnebago (m) - EPSG:7535',
  },
  { value: '7536', label: 'NAD83(2011) / WISCRS Chippewa (m) - EPSG:7536' },
  { value: '7537', label: 'NAD83(2011) / WISCRS Clark (m) - EPSG:7537' },
  { value: '7538', label: 'NAD83(2011) / WISCRS Columbia (m) - EPSG:7538' },
  { value: '7539', label: 'NAD83(2011) / WISCRS Crawford (m) - EPSG:7539' },
  { value: '7540', label: 'NAD83(2011) / WISCRS Dane (m) - EPSG:7540' },
  {
    value: '7541',
    label: 'NAD83(2011) / WISCRS Dodge and Jefferson (m) - EPSG:7541',
  },
  { value: '7542', label: 'NAD83(2011) / WISCRS Door (m) - EPSG:7542' },
  { value: '7543', label: 'NAD83(2011) / WISCRS Douglas (m) - EPSG:7543' },
  { value: '7544', label: 'NAD83(2011) / WISCRS Dunn (m) - EPSG:7544' },
  { value: '7545', label: 'NAD83(2011) / WISCRS Eau Claire (m) - EPSG:7545' },
  { value: '7546', label: 'NAD83(2011) / WISCRS Florence (m) - EPSG:7546' },
  { value: '7547', label: 'NAD83(2011) / WISCRS Forest (m) - EPSG:7547' },
  { value: '7548', label: 'NAD83(2011) / WISCRS Grant (m) - EPSG:7548' },
  {
    value: '7549',
    label: 'NAD83(2011) / WISCRS Green and Lafayette (m) - EPSG:7549',
  },
  {
    value: '7550',
    label: 'NAD83(2011) / WISCRS Green Lake and Marquette (m) - EPSG:7550',
  },
  { value: '7551', label: 'NAD83(2011) / WISCRS Iowa (m) - EPSG:7551' },
  { value: '7552', label: 'NAD83(2011) / WISCRS Iron (m) - EPSG:7552' },
  { value: '7553', label: 'NAD83(2011) / WISCRS Jackson (m) - EPSG:7553' },
  {
    value: '7554',
    label: 'NAD83(2011) / WISCRS Kenosha, Milwaukee, Ozaukee and Racine (m) - EPSG:7554',
  },
  {
    value: '7555',
    label: 'NAD83(2011) / WISCRS Kewaunee, Manitowoc and Sheboygan (m) - EPSG:7555',
  },
  { value: '7556', label: 'NAD83(2011) / WISCRS La Crosse (m) - EPSG:7556' },
  { value: '7557', label: 'NAD83(2011) / WISCRS Langlade (m) - EPSG:7557' },
  { value: '7558', label: 'NAD83(2011) / WISCRS Lincoln (m) - EPSG:7558' },
  { value: '7559', label: 'NAD83(2011) / WISCRS Marathon (m) - EPSG:7559' },
  { value: '7560', label: 'NAD83(2011) / WISCRS Marinette (m) - EPSG:7560' },
  { value: '7561', label: 'NAD83(2011) / WISCRS Menominee (m) - EPSG:7561' },
  { value: '7562', label: 'NAD83(2011) / WISCRS Monroe (m) - EPSG:7562' },
  { value: '7563', label: 'NAD83(2011) / WISCRS Oconto (m) - EPSG:7563' },
  { value: '7564', label: 'NAD83(2011) / WISCRS Oneida (m) - EPSG:7564' },
  {
    value: '7565',
    label: 'NAD83(2011) / WISCRS Pepin and Pierce (m) - EPSG:7565',
  },
  { value: '7566', label: 'NAD83(2011) / WISCRS Polk (m) - EPSG:7566' },
  { value: '7567', label: 'NAD83(2011) / WISCRS Portage (m) - EPSG:7567' },
  { value: '7568', label: 'NAD83(2011) / WISCRS Price (m) - EPSG:7568' },
  { value: '7569', label: 'NAD83(2011) / WISCRS Richland (m) - EPSG:7569' },
  { value: '7570', label: 'NAD83(2011) / WISCRS Rock (m) - EPSG:7570' },
  { value: '7571', label: 'NAD83(2011) / WISCRS Rusk (m) - EPSG:7571' },
  { value: '7572', label: 'NAD83(2011) / WISCRS Sauk (m) - EPSG:7572' },
  { value: '7573', label: 'NAD83(2011) / WISCRS Sawyer (m) - EPSG:7573' },
  { value: '7574', label: 'NAD83(2011) / WISCRS Shawano (m) - EPSG:7574' },
  { value: '7575', label: 'NAD83(2011) / WISCRS St. Croix (m) - EPSG:7575' },
  { value: '7576', label: 'NAD83(2011) / WISCRS Taylor (m) - EPSG:7576' },
  { value: '7577', label: 'NAD83(2011) / WISCRS Trempealeau (m) - EPSG:7577' },
  { value: '7578', label: 'NAD83(2011) / WISCRS Vernon (m) - EPSG:7578' },
  { value: '7579', label: 'NAD83(2011) / WISCRS Vilas (m) - EPSG:7579' },
  { value: '7580', label: 'NAD83(2011) / WISCRS Walworth (m) - EPSG:7580' },
  { value: '7581', label: 'NAD83(2011) / WISCRS Washburn (m) - EPSG:7581' },
  { value: '7582', label: 'NAD83(2011) / WISCRS Washington (m) - EPSG:7582' },
  { value: '7583', label: 'NAD83(2011) / WISCRS Waukesha (m) - EPSG:7583' },
  { value: '7584', label: 'NAD83(2011) / WISCRS Waupaca (m) - EPSG:7584' },
  { value: '7585', label: 'NAD83(2011) / WISCRS Waushara (m) - EPSG:7585' },
  { value: '7586', label: 'NAD83(2011) / WISCRS Wood (m) - EPSG:7586' },
  {
    value: '7587',
    label: 'NAD83(2011) / WISCRS Adams and Juneau (ftUS) - EPSG:7587',
  },
  { value: '7588', label: 'NAD83(2011) / WISCRS Ashland (ftUS) - EPSG:7588' },
  { value: '7589', label: 'NAD83(2011) / WISCRS Barron (ftUS) - EPSG:7589' },
  { value: '7590', label: 'NAD83(2011) / WISCRS Bayfield (ftUS) - EPSG:7590' },
  { value: '7591', label: 'NAD83(2011) / WISCRS Brown (ftUS) - EPSG:7591' },
  { value: '7592', label: 'NAD83(2011) / WISCRS Buffalo (ftUS) - EPSG:7592' },
  { value: '7593', label: 'NAD83(2011) / WISCRS Burnett (ftUS) - EPSG:7593' },
  {
    value: '7594',
    label: 'NAD83(2011) / WISCRS Calumet, Fond du Lac, Outagamie and Winnebago (ftUS) - EPSG:7594',
  },
  { value: '7595', label: 'NAD83(2011) / WISCRS Chippewa (ftUS) - EPSG:7595' },
  { value: '7596', label: 'NAD83(2011) / WISCRS Clark (ftUS) - EPSG:7596' },
  { value: '7597', label: 'NAD83(2011) / WISCRS Columbia (ftUS) - EPSG:7597' },
  { value: '7598', label: 'NAD83(2011) / WISCRS Crawford (ftUS) - EPSG:7598' },
  { value: '7599', label: 'NAD83(2011) / WISCRS Dane (ftUS) - EPSG:7599' },
  {
    value: '7600',
    label: 'NAD83(2011) / WISCRS Dodge and Jefferson (ftUS) - EPSG:7600',
  },
  { value: '7601', label: 'NAD83(2011) / WISCRS Door (ftUS) - EPSG:7601' },
  { value: '7602', label: 'NAD83(2011) / WISCRS Douglas (ftUS) - EPSG:7602' },
  { value: '7603', label: 'NAD83(2011) / WISCRS Dunn (ftUS) - EPSG:7603' },
  {
    value: '7604',
    label: 'NAD83(2011) / WISCRS Eau Claire (ftUS) - EPSG:7604',
  },
  { value: '7605', label: 'NAD83(2011) / WISCRS Florence (ftUS) - EPSG:7605' },
  { value: '7606', label: 'NAD83(2011) / WISCRS Forest (ftUS) - EPSG:7606' },
  { value: '7607', label: 'NAD83(2011) / WISCRS Grant (ftUS) - EPSG:7607' },
  {
    value: '7608',
    label: 'NAD83(2011) / WISCRS Green and Lafayette (ftUS) - EPSG:7608',
  },
  {
    value: '7609',
    label: 'NAD83(2011) / WISCRS Green Lake and Marquette (ftUS) - EPSG:7609',
  },
  { value: '7610', label: 'NAD83(2011) / WISCRS Iowa (ftUS) - EPSG:7610' },
  { value: '7611', label: 'NAD83(2011) / WISCRS Iron (ftUS) - EPSG:7611' },
  { value: '7612', label: 'NAD83(2011) / WISCRS Jackson (ftUS) - EPSG:7612' },
  {
    value: '7613',
    label: 'NAD83(2011) / WISCRS Kenosha, Milwaukee, Ozaukee and Racine (ftUS) - EPSG:7613',
  },
  {
    value: '7614',
    label: 'NAD83(2011) / WISCRS Kewaunee, Manitowoc and Sheboygan (ftUS) - EPSG:7614',
  },
  { value: '7615', label: 'NAD83(2011) / WISCRS La Crosse (ftUS) - EPSG:7615' },
  { value: '7616', label: 'NAD83(2011) / WISCRS Langlade (ftUS) - EPSG:7616' },
  { value: '7617', label: 'NAD83(2011) / WISCRS Lincoln (ftUS) - EPSG:7617' },
  { value: '7618', label: 'NAD83(2011) / WISCRS Marathon (ftUS) - EPSG:7618' },
  { value: '7619', label: 'NAD83(2011) / WISCRS Marinette (ftUS) - EPSG:7619' },
  { value: '7620', label: 'NAD83(2011) / WISCRS Menominee (ftUS) - EPSG:7620' },
  { value: '7621', label: 'NAD83(2011) / WISCRS Monroe (ftUS) - EPSG:7621' },
  { value: '7622', label: 'NAD83(2011) / WISCRS Oconto (ftUS) - EPSG:7622' },
  { value: '7623', label: 'NAD83(2011) / WISCRS Oneida (ftUS) - EPSG:7623' },
  {
    value: '7624',
    label: 'NAD83(2011) / WISCRS Pepin and Pierce (ftUS) - EPSG:7624',
  },
  { value: '7625', label: 'NAD83(2011) / WISCRS Polk (ftUS) - EPSG:7625' },
  { value: '7626', label: 'NAD83(2011) / WISCRS Portage (ftUS) - EPSG:7626' },
  { value: '7627', label: 'NAD83(2011) / WISCRS Price (ftUS) - EPSG:7627' },
  { value: '7628', label: 'NAD83(2011) / WISCRS Richland (ftUS) - EPSG:7628' },
  { value: '7629', label: 'NAD83(2011) / WISCRS Rock (ftUS) - EPSG:7629' },
  { value: '7630', label: 'NAD83(2011) / WISCRS Rusk (ftUS) - EPSG:7630' },
  { value: '7631', label: 'NAD83(2011) / WISCRS Sauk (ftUS) - EPSG:7631' },
  { value: '7632', label: 'NAD83(2011) / WISCRS Sawyer (ftUS) - EPSG:7632' },
  { value: '7633', label: 'NAD83(2011) / WISCRS Shawano (ftUS) - EPSG:7633' },
  { value: '7634', label: 'NAD83(2011) / WISCRS St. Croix (ftUS) - EPSG:7634' },
  { value: '7635', label: 'NAD83(2011) / WISCRS Taylor (ftUS) - EPSG:7635' },
  {
    value: '7636',
    label: 'NAD83(2011) / WISCRS Trempealeau (ftUS) - EPSG:7636',
  },
  { value: '7637', label: 'NAD83(2011) / WISCRS Vernon (ftUS) - EPSG:7637' },
  { value: '7638', label: 'NAD83(2011) / WISCRS Vilas (ftUS) - EPSG:7638' },
  { value: '7639', label: 'NAD83(2011) / WISCRS Walworth (ftUS) - EPSG:7639' },
  { value: '7640', label: 'NAD83(2011) / WISCRS Washburn (ftUS) - EPSG:7640' },
  {
    value: '7641',
    label: 'NAD83(2011) / WISCRS Washington (ftUS) - EPSG:7641',
  },
  { value: '7642', label: 'NAD83(2011) / WISCRS Waukesha (ftUS) - EPSG:7642' },
  { value: '7643', label: 'NAD83(2011) / WISCRS Waupaca (ftUS) - EPSG:7643' },
  { value: '7644', label: 'NAD83(2011) / WISCRS Waushara (ftUS) - EPSG:7644' },
  { value: '7645', label: 'NAD83(2011) / WISCRS Wood (ftUS) - EPSG:7645' },
  { value: '7692', label: 'Kyrg-06 / zone 1 - EPSG:7692' },
  { value: '7693', label: 'Kyrg-06 / zone 2 - EPSG:7693' },
  { value: '7694', label: 'Kyrg-06 / zone 3 - EPSG:7694' },
  { value: '7695', label: 'Kyrg-06 / zone 4 - EPSG:7695' },
  { value: '7696', label: 'Kyrg-06 / zone 5 - EPSG:7696' },
  { value: '7755', label: 'WGS 84 / India NSF LCC - EPSG:7755' },
  { value: '7756', label: 'WGS 84 / Andhra Pradesh - EPSG:7756' },
  { value: '7757', label: 'WGS 84 / Arunachal Pradesh - EPSG:7757' },
  { value: '7758', label: 'WGS 84 / Assam - EPSG:7758' },
  { value: '7759', label: 'WGS 84 / Bihar - EPSG:7759' },
  { value: '7760', label: 'WGS 84 / Delhi - EPSG:7760' },
  { value: '7761', label: 'WGS 84 / Gujarat - EPSG:7761' },
  { value: '7762', label: 'WGS 84 / Haryana - EPSG:7762' },
  { value: '7763', label: 'WGS 84 / Himachal Pradesh - EPSG:7763' },
  { value: '7764', label: 'WGS 84 / Jammu and Kashmir - EPSG:7764' },
  { value: '7765', label: 'WGS 84 / Jharkhand - EPSG:7765' },
  { value: '7766', label: 'WGS 84 / Madhya Pradesh - EPSG:7766' },
  { value: '7767', label: 'WGS 84 / Maharashtra - EPSG:7767' },
  { value: '7768', label: 'WGS 84 / Manipur - EPSG:7768' },
  { value: '7769', label: 'WGS 84 / Meghalaya - EPSG:7769' },
  { value: '7770', label: 'WGS 84 / Nagaland - EPSG:7770' },
  { value: '7771', label: 'WGS 84 / India Northeast - EPSG:7771' },
  { value: '7772', label: 'WGS 84 / Orissa - EPSG:7772' },
  { value: '7773', label: 'WGS 84 / Punjab - EPSG:7773' },
  { value: '7774', label: 'WGS 84 / Rajasthan - EPSG:7774' },
  { value: '7775', label: 'WGS 84 / Uttar Pradesh - EPSG:7775' },
  { value: '7776', label: 'WGS 84 / Uttaranchal - EPSG:7776' },
  { value: '7777', label: 'WGS 84 / Andaman and Nicobar - EPSG:7777' },
  { value: '7778', label: 'WGS 84 / Chhattisgarh - EPSG:7778' },
  { value: '7779', label: 'WGS 84 / Goa - EPSG:7779' },
  { value: '7780', label: 'WGS 84 / Karnataka - EPSG:7780' },
  { value: '7781', label: 'WGS 84 / Kerala - EPSG:7781' },
  { value: '7782', label: 'WGS 84 / Lakshadweep - EPSG:7782' },
  { value: '7783', label: 'WGS 84 / Mizoram - EPSG:7783' },
  { value: '7784', label: 'WGS 84 / Sikkim - EPSG:7784' },
  { value: '7785', label: 'WGS 84 / Tamil Nadu - EPSG:7785' },
  { value: '7786', label: 'WGS 84 / Tripura - EPSG:7786' },
  { value: '7787', label: 'WGS 84 / West Bengal - EPSG:7787' },
  { value: '7791', label: 'RDN2008 / UTM zone 32N - EPSG:7791' },
  { value: '7792', label: 'RDN2008 / UTM zone 33N - EPSG:7792' },
  { value: '7793', label: 'RDN2008 / UTM zone 34N - EPSG:7793' },
  { value: '7794', label: 'RDN2008 / Italy zone (E-N) - EPSG:7794' },
  { value: '7795', label: 'RDN2008 / Zone 12 (E-N) - EPSG:7795' },
  { value: '7799', label: 'BGS2005 / UTM zone 34N (N-E) - EPSG:7799' },
  { value: '7800', label: 'BGS2005 / UTM zone 35N (N-E) - EPSG:7800' },
  { value: '7801', label: 'BGS2005 / CCS2005 - EPSG:7801' },
  { value: '7803', label: 'BGS2005 / UTM zone 34N - EPSG:7803' },
  { value: '7804', label: 'BGS2005 / UTM zone 35N - EPSG:7804' },
  { value: '7805', label: 'BGS2005 / UTM zone 36N - EPSG:7805' },
  { value: '7825', label: 'Pulkovo 1942 / CS63 zone X1 - EPSG:7825' },
  { value: '7826', label: 'Pulkovo 1942 / CS63 zone X2 - EPSG:7826' },
  { value: '7827', label: 'Pulkovo 1942 / CS63 zone X3 - EPSG:7827' },
  { value: '7828', label: 'Pulkovo 1942 / CS63 zone X4 - EPSG:7828' },
  { value: '7829', label: 'Pulkovo 1942 / CS63 zone X5 - EPSG:7829' },
  { value: '7830', label: 'Pulkovo 1942 / CS63 zone X6 - EPSG:7830' },
  { value: '7831', label: 'Pulkovo 1942 / CS63 zone X7 - EPSG:7831' },
  { value: '7845', label: 'GDA2020 / GA LCC - EPSG:7845' },
  { value: '7846', label: 'GDA2020 / MGA zone 46 - EPSG:7846' },
  { value: '7847', label: 'GDA2020 / MGA zone 47 - EPSG:7847' },
  { value: '7848', label: 'GDA2020 / MGA zone 48 - EPSG:7848' },
  { value: '7849', label: 'GDA2020 / MGA zone 49 - EPSG:7849' },
  { value: '7850', label: 'GDA2020 / MGA zone 50 - EPSG:7850' },
  { value: '7851', label: 'GDA2020 / MGA zone 51 - EPSG:7851' },
  { value: '7852', label: 'GDA2020 / MGA zone 52 - EPSG:7852' },
  { value: '7853', label: 'GDA2020 / MGA zone 53 - EPSG:7853' },
  { value: '7854', label: 'GDA2020 / MGA zone 54 - EPSG:7854' },
  { value: '7855', label: 'GDA2020 / MGA zone 55 - EPSG:7855' },
  { value: '7856', label: 'GDA2020 / MGA zone 56 - EPSG:7856' },
  { value: '7857', label: 'GDA2020 / MGA zone 57 - EPSG:7857' },
  { value: '7858', label: 'GDA2020 / MGA zone 58 - EPSG:7858' },
  { value: '7859', label: 'GDA2020 / MGA zone 59 - EPSG:7859' },
  { value: '7877', label: 'Astro DOS 71 / SHLG71 - EPSG:7877' },
  { value: '7878', label: 'Astro DOS 71 / UTM zone 30S - EPSG:7878' },
  { value: '7882', label: 'St. Helena Tritan / SHLG(Tritan) - EPSG:7882' },
  { value: '7883', label: 'St. Helena Tritan / UTM zone 30S - EPSG:7883' },
  { value: '7887', label: 'SHMG2015 - EPSG:7887' },
  { value: '7899', label: 'GDA2020 / Vicgrid - EPSG:7899' },
  { value: '7991', label: 'NAD27 / MTM zone 10 - EPSG:7991' },
  { value: '7992', label: 'Malongo 1987 / UTM zone 33S - EPSG:7992' },
  { value: '8013', label: 'GDA2020 / ALB2020 - EPSG:8013' },
  { value: '8014', label: 'GDA2020 / BIO2020 - EPSG:8014' },
  { value: '8015', label: 'GDA2020 / BRO2020 - EPSG:8015' },
  { value: '8016', label: 'GDA2020 / BCG2020 - EPSG:8016' },
  { value: '8017', label: 'GDA2020 / CARN2020 - EPSG:8017' },
  { value: '8018', label: 'GDA2020 / CIG2020 - EPSG:8018' },
  { value: '8019', label: 'GDA2020 / CKIG2020 - EPSG:8019' },
  { value: '8020', label: 'GDA2020 / COL2020 - EPSG:8020' },
  { value: '8021', label: 'GDA2020 / ESP2020 - EPSG:8021' },
  { value: '8022', label: 'GDA2020 / EXM2020 - EPSG:8022' },
  { value: '8023', label: 'GDA2020 / GCG2020 - EPSG:8023' },
  { value: '8024', label: 'GDA2020 / GOLD2020 - EPSG:8024' },
  { value: '8025', label: 'GDA2020 / JCG2020 - EPSG:8025' },
  { value: '8026', label: 'GDA2020 / KALB2020 - EPSG:8026' },
  { value: '8027', label: 'GDA2020 / KAR2020 - EPSG:8027' },
  { value: '8028', label: 'GDA2020 / KUN2020 - EPSG:8028' },
  { value: '8029', label: 'GDA2020 / LCG2020 - EPSG:8029' },
  { value: '8030', label: 'GDA2020 / MRCG2020 - EPSG:8030' },
  { value: '8031', label: 'GDA2020 / PCG2020 - EPSG:8031' },
  { value: '8032', label: 'GDA2020 / PHG2020 - EPSG:8032' },
  { value: '8035', label: 'WGS 84 / TM Zone 20N (ftUS) - EPSG:8035' },
  { value: '8036', label: 'WGS 84 / TM Zone 21N (ftUS) - EPSG:8036' },
  { value: '8044', label: 'Gusterberg Grid (Ferro) - EPSG:8044' },
  { value: '8045', label: 'St. Stephen Grid (Ferro) - EPSG:8045' },
  { value: '8058', label: 'GDA2020 / NSW Lambert - EPSG:8058' },
  { value: '8059', label: 'GDA2020 / SA Lambert - EPSG:8059' },
  { value: '8065', label: 'NAD83(2011) / PCCS zone 1 (ft) - EPSG:8065' },
  { value: '8066', label: 'NAD83(2011) / PCCS zone 2 (ft) - EPSG:8066' },
  { value: '8067', label: 'NAD83(2011) / PCCS zone 3 (ft) - EPSG:8067' },
  { value: '8068', label: 'NAD83(2011) / PCCS zone 4 (ft) - EPSG:8068' },
  {
    value: '8082',
    label: 'NAD83(CSRS)v6 / MTM Nova Scotia zone 4 - EPSG:8082',
  },
  {
    value: '8083',
    label: 'NAD83(CSRS)v6 / MTM Nova Scotia zone 5 - EPSG:8083',
  },
  { value: '8088', label: 'ISN2016 / Lambert 2016 - EPSG:8088' },
  { value: '8090', label: 'NAD83(HARN) / WISCRS Florence (m) - EPSG:8090' },
  { value: '8091', label: 'NAD83(HARN) / WISCRS Florence (ftUS) - EPSG:8091' },
  { value: '8092', label: 'NAD83(HARN) / WISCRS Eau Claire (m) - EPSG:8092' },
  {
    value: '8093',
    label: 'NAD83(HARN) / WISCRS Eau Claire (ftUS) - EPSG:8093',
  },
  { value: '8095', label: 'NAD83(HARN) / WISCRS Wood (m) - EPSG:8095' },
  { value: '8096', label: 'NAD83(HARN) / WISCRS Wood (ftUS) - EPSG:8096' },
  { value: '8097', label: 'NAD83(HARN) / WISCRS Waushara (m) - EPSG:8097' },
  { value: '8098', label: 'NAD83(HARN) / WISCRS Waushara (ftUS) - EPSG:8098' },
  { value: '8099', label: 'NAD83(HARN) / WISCRS Waupaca (m) - EPSG:8099' },
  { value: '8100', label: 'NAD83(HARN) / WISCRS Waupaca (ftUS) - EPSG:8100' },
  { value: '8101', label: 'NAD83(HARN) / WISCRS Waukesha (m) - EPSG:8101' },
  { value: '8102', label: 'NAD83(HARN) / WISCRS Waukesha (ftUS) - EPSG:8102' },
  { value: '8103', label: 'NAD83(HARN) / WISCRS Washington (m) - EPSG:8103' },
  {
    value: '8104',
    label: 'NAD83(HARN) / WISCRS Washington (ftUS) - EPSG:8104',
  },
  { value: '8105', label: 'NAD83(HARN) / WISCRS Washburn (m) - EPSG:8105' },
  { value: '8106', label: 'NAD83(HARN) / WISCRS Washburn (ftUS) - EPSG:8106' },
  { value: '8107', label: 'NAD83(HARN) / WISCRS Walworth (m) - EPSG:8107' },
  { value: '8108', label: 'NAD83(HARN) / WISCRS Walworth (ftUS) - EPSG:8108' },
  { value: '8109', label: 'NAD83(HARN) / WISCRS Vilas (m) - EPSG:8109' },
  { value: '8110', label: 'NAD83(HARN) / WISCRS Vilas (ftUS) - EPSG:8110' },
  { value: '8111', label: 'NAD83(HARN) / WISCRS Vernon (m) - EPSG:8111' },
  { value: '8112', label: 'NAD83(HARN) / WISCRS Vernon (ftUS) - EPSG:8112' },
  { value: '8113', label: 'NAD83(HARN) / WISCRS Trempealeau (m) - EPSG:8113' },
  {
    value: '8114',
    label: 'NAD83(HARN) / WISCRS Trempealeau (ftUS) - EPSG:8114',
  },
  { value: '8115', label: 'NAD83(HARN) / WISCRS Taylor (m) - EPSG:8115' },
  { value: '8116', label: 'NAD83(HARN) / WISCRS Taylor (ftUS) - EPSG:8116' },
  { value: '8117', label: 'NAD83(HARN) / WISCRS St. Croix (m) - EPSG:8117' },
  { value: '8118', label: 'NAD83(HARN) / WISCRS St. Croix (ftUS) - EPSG:8118' },
  { value: '8119', label: 'NAD83(HARN) / WISCRS Shawano (m) - EPSG:8119' },
  { value: '8120', label: 'NAD83(HARN) / WISCRS Shawano (ftUS) - EPSG:8120' },
  { value: '8121', label: 'NAD83(HARN) / WISCRS Sawyer (m) - EPSG:8121' },
  { value: '8122', label: 'NAD83(HARN) / WISCRS Sawyer (ftUS) - EPSG:8122' },
  { value: '8123', label: 'NAD83(HARN) / WISCRS Sauk (m) - EPSG:8123' },
  { value: '8124', label: 'NAD83(HARN) / WISCRS Sauk (ftUS) - EPSG:8124' },
  { value: '8125', label: 'NAD83(HARN) / WISCRS Rusk (m) - EPSG:8125' },
  { value: '8126', label: 'NAD83(HARN) / WISCRS Rusk (ftUS) - EPSG:8126' },
  { value: '8127', label: 'NAD83(HARN) / WISCRS Rock (m) - EPSG:8127' },
  { value: '8128', label: 'NAD83(HARN) / WISCRS Rock (ftUS) - EPSG:8128' },
  { value: '8129', label: 'NAD83(HARN) / WISCRS Richland (m) - EPSG:8129' },
  { value: '8130', label: 'NAD83(HARN) / WISCRS Richland (ftUS) - EPSG:8130' },
  { value: '8131', label: 'NAD83(HARN) / WISCRS Price (m) - EPSG:8131' },
  { value: '8132', label: 'NAD83(HARN) / WISCRS Price (ftUS) - EPSG:8132' },
  { value: '8133', label: 'NAD83(HARN) / WISCRS Portage (m) - EPSG:8133' },
  { value: '8134', label: 'NAD83(HARN) / WISCRS Portage (ftUS) - EPSG:8134' },
  { value: '8135', label: 'NAD83(HARN) / WISCRS Polk (m) - EPSG:8135' },
  { value: '8136', label: 'NAD83(HARN) / WISCRS Polk (ftUS) - EPSG:8136' },
  {
    value: '8137',
    label: 'NAD83(HARN) / WISCRS Pepin and Pierce (m) - EPSG:8137',
  },
  {
    value: '8138',
    label: 'NAD83(HARN) / WISCRS Pepin and Pierce (ftUS) - EPSG:8138',
  },
  { value: '8139', label: 'NAD83(HARN) / WISCRS Oneida (m) - EPSG:8139' },
  { value: '8140', label: 'NAD83(HARN) / WISCRS Oneida (ftUS) - EPSG:8140' },
  { value: '8141', label: 'NAD83(HARN) / WISCRS Oconto (m) - EPSG:8141' },
  { value: '8142', label: 'NAD83(HARN) / WISCRS Oconto (ftUS) - EPSG:8142' },
  { value: '8143', label: 'NAD83(HARN) / WISCRS Monroe (m) - EPSG:8143' },
  { value: '8144', label: 'NAD83(HARN) / WISCRS Monroe (ftUS) - EPSG:8144' },
  { value: '8145', label: 'NAD83(HARN) / WISCRS Menominee (m) - EPSG:8145' },
  { value: '8146', label: 'NAD83(HARN) / WISCRS Menominee (ftUS) - EPSG:8146' },
  { value: '8147', label: 'NAD83(HARN) / WISCRS Marinette (m) - EPSG:8147' },
  { value: '8148', label: 'NAD83(HARN) / WISCRS Marinette (ftUS) - EPSG:8148' },
  { value: '8149', label: 'NAD83(HARN) / WISCRS Marathon (m) - EPSG:8149' },
  { value: '8150', label: 'NAD83(HARN) / WISCRS Marathon (ftUS) - EPSG:8150' },
  { value: '8151', label: 'NAD83(HARN) / WISCRS Lincoln (m) - EPSG:8151' },
  { value: '8152', label: 'NAD83(HARN) / WISCRS Lincoln (ftUS) - EPSG:8152' },
  { value: '8153', label: 'NAD83(HARN) / WISCRS Langlade (m) - EPSG:8153' },
  { value: '8154', label: 'NAD83(HARN) / WISCRS Langlade (ftUS) - EPSG:8154' },
  { value: '8155', label: 'NAD83(HARN) / WISCRS La Crosse (m) - EPSG:8155' },
  { value: '8156', label: 'NAD83(HARN) / WISCRS La Crosse (ftUS) - EPSG:8156' },
  {
    value: '8157',
    label: 'NAD83(HARN) / WISCRS Kewaunee, Manitowoc and Sheboygan (m) - EPSG:8157',
  },
  {
    value: '8158',
    label: 'NAD83(HARN) / WISCRS Kewaunee, Manitowoc and Sheboygan (ftUS) - EPSG:8158',
  },
  {
    value: '8159',
    label: 'NAD83(HARN) / WISCRS Kenosha, Milwaukee, Ozaukee and Racine (m) - EPSG:8159',
  },
  {
    value: '8160',
    label: 'NAD83(HARN) / WISCRS Kenosha, Milwaukee, Ozaukee and Racine (ftUS) - EPSG:8160',
  },
  { value: '8161', label: 'NAD83(HARN) / WISCRS Jackson (m) - EPSG:8161' },
  { value: '8162', label: 'NAD83(HARN) / WISCRS Jackson (ftUS) - EPSG:8162' },
  { value: '8163', label: 'NAD83(HARN) / WISCRS Iron (m) - EPSG:8163' },
  { value: '8164', label: 'NAD83(HARN) / WISCRS Iron (ftUS) - EPSG:8164' },
  { value: '8165', label: 'NAD83(HARN) / WISCRS Iowa (m) - EPSG:8165' },
  { value: '8166', label: 'NAD83(HARN) / WISCRS Iowa (ftUS) - EPSG:8166' },
  {
    value: '8167',
    label: 'NAD83(HARN) / WISCRS Green Lake and Marquette (m) - EPSG:8167',
  },
  {
    value: '8168',
    label: 'NAD83(HARN) / WISCRS Green Lake and Marquette (ftUS) - EPSG:8168',
  },
  {
    value: '8169',
    label: 'NAD83(HARN) / WISCRS Green and Lafayette (m) - EPSG:8169',
  },
  {
    value: '8170',
    label: 'NAD83(HARN) / WISCRS Green and Lafayette (ftUS) - EPSG:8170',
  },
  { value: '8171', label: 'NAD83(HARN) / WISCRS Grant (m) - EPSG:8171' },
  { value: '8172', label: 'NAD83(HARN) / WISCRS Grant (ftUS) - EPSG:8172' },
  { value: '8173', label: 'NAD83(HARN) / WISCRS Forest (m) - EPSG:8173' },
  { value: '8177', label: 'NAD83(HARN) / WISCRS Forest (ftUS) - EPSG:8177' },
  { value: '8179', label: 'NAD83(HARN) / WISCRS Dunn (m) - EPSG:8179' },
  { value: '8180', label: 'NAD83(HARN) / WISCRS Dunn (ftUS) - EPSG:8180' },
  { value: '8181', label: 'NAD83(HARN) / WISCRS Douglas (m) - EPSG:8181' },
  { value: '8182', label: 'NAD83(HARN) / WISCRS Douglas (ftUS) - EPSG:8182' },
  { value: '8184', label: 'NAD83(HARN) / WISCRS Door (m) - EPSG:8184' },
  { value: '8185', label: 'NAD83(HARN) / WISCRS Door (ftUS) - EPSG:8185' },
  {
    value: '8187',
    label: 'NAD83(HARN) / WISCRS Dodge and Jefferson (m) - EPSG:8187',
  },
  {
    value: '8189',
    label: 'NAD83(HARN) / WISCRS Dodge and Jefferson (ftUS) - EPSG:8189',
  },
  { value: '8191', label: 'NAD83(HARN) / WISCRS Dane (m) - EPSG:8191' },
  { value: '8193', label: 'NAD83(HARN) / WISCRS Dane (ftUS) - EPSG:8193' },
  { value: '8196', label: 'NAD83(HARN) / WISCRS Crawford (m) - EPSG:8196' },
  { value: '8197', label: 'NAD83(HARN) / WISCRS Crawford (ftUS) - EPSG:8197' },
  { value: '8198', label: 'NAD83(HARN) / WISCRS Columbia (m) - EPSG:8198' },
  { value: '8200', label: 'NAD83(HARN) / WISCRS Columbia (ftUS) - EPSG:8200' },
  { value: '8201', label: 'NAD83(HARN) / WISCRS Clark (m) - EPSG:8201' },
  { value: '8202', label: 'NAD83(HARN) / WISCRS Clark (ftUS) - EPSG:8202' },
  { value: '8203', label: 'NAD83(HARN) / WISCRS Chippewa (m) - EPSG:8203' },
  { value: '8204', label: 'NAD83(HARN) / WISCRS Chippewa (ftUS) - EPSG:8204' },
  {
    value: '8205',
    label: 'NAD83(HARN) / WISCRS Calumet, Fond du Lac, Outagamie and Winnebago (m) - EPSG:8205',
  },
  {
    value: '8206',
    label: 'NAD83(HARN) / WISCRS Calumet, Fond du Lac, Outagamie and Winnebago (ftUS) - EPSG:8206',
  },
  { value: '8207', label: 'NAD83(HARN) / WISCRS Burnett (m) - EPSG:8207' },
  { value: '8208', label: 'NAD83(HARN) / WISCRS Burnett (ftUS) - EPSG:8208' },
  { value: '8209', label: 'NAD83(HARN) / WISCRS Buffalo (m) - EPSG:8209' },
  { value: '8210', label: 'NAD83(HARN) / WISCRS Buffalo (ftUS) - EPSG:8210' },
  { value: '8212', label: 'NAD83(HARN) / WISCRS Brown (m) - EPSG:8212' },
  { value: '8213', label: 'NAD83(HARN) / WISCRS Brown (ftUS) - EPSG:8213' },
  { value: '8214', label: 'NAD83(HARN) / WISCRS Bayfield (m) - EPSG:8214' },
  { value: '8216', label: 'NAD83(HARN) / WISCRS Bayfield (ftUS) - EPSG:8216' },
  { value: '8218', label: 'NAD83(HARN) / WISCRS Barron (m) - EPSG:8218' },
  { value: '8220', label: 'NAD83(HARN) / WISCRS Barron (ftUS) - EPSG:8220' },
  { value: '8222', label: 'NAD83(HARN) / WISCRS Ashland (m) - EPSG:8222' },
  { value: '8224', label: 'NAD83(HARN) / WISCRS Ashland (ftUS) - EPSG:8224' },
  {
    value: '8225',
    label: 'NAD83(HARN) / WISCRS Adams and Juneau (m) - EPSG:8225',
  },
  {
    value: '8226',
    label: 'NAD83(HARN) / WISCRS Adams and Juneau (ftUS) - EPSG:8226',
  },
  {
    value: '8311',
    label: 'NAD83(2011) / Oregon Burns-Harper zone (m) - EPSG:8311',
  },
  {
    value: '8312',
    label: 'NAD83(2011) / Oregon Burns-Harper zone (ft) - EPSG:8312',
  },
  {
    value: '8313',
    label: 'NAD83(2011) / Oregon Canyon City-Burns zone (m) - EPSG:8313',
  },
  {
    value: '8314',
    label: 'NAD83(2011) / Oregon Canyon City-Burns zone (ft) - EPSG:8314',
  },
  {
    value: '8315',
    label: 'NAD83(2011) / Oregon Coast Range North zone (m) - EPSG:8315',
  },
  {
    value: '8316',
    label: 'NAD83(2011) / Oregon Coast Range North zone (ft) - EPSG:8316',
  },
  {
    value: '8317',
    label: 'NAD83(2011) / Oregon Dayville-Prairie City zone (m) - EPSG:8317',
  },
  {
    value: '8318',
    label: 'NAD83(2011) / Oregon Dayville-Prairie City zone (ft) - EPSG:8318',
  },
  {
    value: '8319',
    label: 'NAD83(2011) / Oregon Denio-Burns zone (m) - EPSG:8319',
  },
  {
    value: '8320',
    label: 'NAD83(2011) / Oregon Denio-Burns zone (ft) - EPSG:8320',
  },
  { value: '8321', label: 'NAD83(2011) / Oregon Halfway zone (m) - EPSG:8321' },
  {
    value: '8322',
    label: 'NAD83(2011) / Oregon Halfway zone (ft) - EPSG:8322',
  },
  {
    value: '8323',
    label: 'NAD83(2011) / Oregon Medford-Diamond Lake zone (m) - EPSG:8323',
  },
  {
    value: '8324',
    label: 'NAD83(2011) / Oregon Medford-Diamond Lake zone (ft) - EPSG:8324',
  },
  {
    value: '8325',
    label: 'NAD83(2011) / Oregon Mitchell zone (m) - EPSG:8325',
  },
  {
    value: '8326',
    label: 'NAD83(2011) / Oregon Mitchell zone (ft) - EPSG:8326',
  },
  {
    value: '8327',
    label: 'NAD83(2011) / Oregon North Central zone (m) - EPSG:8327',
  },
  {
    value: '8328',
    label: 'NAD83(2011) / Oregon North Central zone (ft) - EPSG:8328',
  },
  {
    value: '8329',
    label: 'NAD83(2011) / Oregon Ochoco Summit zone (m) - EPSG:8329',
  },
  {
    value: '8330',
    label: 'NAD83(2011) / Oregon Ochoco Summit zone (ft) - EPSG:8330',
  },
  { value: '8331', label: 'NAD83(2011) / Oregon Owyhee zone (m) - EPSG:8331' },
  { value: '8332', label: 'NAD83(2011) / Oregon Owyhee zone (ft) - EPSG:8332' },
  {
    value: '8333',
    label: 'NAD83(2011) / Oregon Pilot Rock-Ukiah zone (m) - EPSG:8333',
  },
  {
    value: '8334',
    label: 'NAD83(2011) / Oregon Pilot Rock-Ukiah zone (ft) - EPSG:8334',
  },
  {
    value: '8335',
    label: 'NAD83(2011) / Oregon Prairie City-Brogan zone (m) - EPSG:8335',
  },
  {
    value: '8336',
    label: 'NAD83(2011) / Oregon Prairie City-Brogan zone (ft) - EPSG:8336',
  },
  {
    value: '8337',
    label: 'NAD83(2011) / Oregon Riley-Lakeview zone (m) - EPSG:8337',
  },
  {
    value: '8338',
    label: 'NAD83(2011) / Oregon Riley-Lakeview zone (ft) - EPSG:8338',
  },
  {
    value: '8339',
    label: 'NAD83(2011) / Oregon Siskiyou Pass zone (m) - EPSG:8339',
  },
  {
    value: '8340',
    label: 'NAD83(2011) / Oregon Siskiyou Pass zone (ft) - EPSG:8340',
  },
  {
    value: '8341',
    label: 'NAD83(2011) / Oregon Ukiah-Fox zone (m) - EPSG:8341',
  },
  {
    value: '8342',
    label: 'NAD83(2011) / Oregon Ukiah-Fox zone (ft) - EPSG:8342',
  },
  { value: '8343', label: 'NAD83(2011) / Oregon Wallowa zone (m) - EPSG:8343' },
  {
    value: '8344',
    label: 'NAD83(2011) / Oregon Wallowa zone (ft) - EPSG:8344',
  },
  {
    value: '8345',
    label: 'NAD83(2011) / Oregon Warner Highway zone (m) - EPSG:8345',
  },
  {
    value: '8346',
    label: 'NAD83(2011) / Oregon Warner Highway zone (ft) - EPSG:8346',
  },
  {
    value: '8347',
    label: 'NAD83(2011) / Oregon Willamette Pass zone (m) - EPSG:8347',
  },
  {
    value: '8348',
    label: 'NAD83(2011) / Oregon Willamette Pass zone (ft) - EPSG:8348',
  },
  { value: '20004', label: 'Pulkovo 1995 / Gauss-Kruger zone 4 - EPSG:20004' },
  { value: '20005', label: 'Pulkovo 1995 / Gauss-Kruger zone 5 - EPSG:20005' },
  { value: '20006', label: 'Pulkovo 1995 / Gauss-Kruger zone 6 - EPSG:20006' },
  { value: '20007', label: 'Pulkovo 1995 / Gauss-Kruger zone 7 - EPSG:20007' },
  { value: '20008', label: 'Pulkovo 1995 / Gauss-Kruger zone 8 - EPSG:20008' },
  { value: '20009', label: 'Pulkovo 1995 / Gauss-Kruger zone 9 - EPSG:20009' },
  { value: '20010', label: 'Pulkovo 1995 / Gauss-Kruger zone 10 - EPSG:20010' },
  { value: '20011', label: 'Pulkovo 1995 / Gauss-Kruger zone 11 - EPSG:20011' },
  { value: '20012', label: 'Pulkovo 1995 / Gauss-Kruger zone 12 - EPSG:20012' },
  { value: '20013', label: 'Pulkovo 1995 / Gauss-Kruger zone 13 - EPSG:20013' },
  { value: '20014', label: 'Pulkovo 1995 / Gauss-Kruger zone 14 - EPSG:20014' },
  { value: '20015', label: 'Pulkovo 1995 / Gauss-Kruger zone 15 - EPSG:20015' },
  { value: '20016', label: 'Pulkovo 1995 / Gauss-Kruger zone 16 - EPSG:20016' },
  { value: '20017', label: 'Pulkovo 1995 / Gauss-Kruger zone 17 - EPSG:20017' },
  { value: '20018', label: 'Pulkovo 1995 / Gauss-Kruger zone 18 - EPSG:20018' },
  { value: '20019', label: 'Pulkovo 1995 / Gauss-Kruger zone 19 - EPSG:20019' },
  { value: '20020', label: 'Pulkovo 1995 / Gauss-Kruger zone 20 - EPSG:20020' },
  { value: '20021', label: 'Pulkovo 1995 / Gauss-Kruger zone 21 - EPSG:20021' },
  { value: '20022', label: 'Pulkovo 1995 / Gauss-Kruger zone 22 - EPSG:20022' },
  { value: '20023', label: 'Pulkovo 1995 / Gauss-Kruger zone 23 - EPSG:20023' },
  { value: '20024', label: 'Pulkovo 1995 / Gauss-Kruger zone 24 - EPSG:20024' },
  { value: '20025', label: 'Pulkovo 1995 / Gauss-Kruger zone 25 - EPSG:20025' },
  { value: '20026', label: 'Pulkovo 1995 / Gauss-Kruger zone 26 - EPSG:20026' },
  { value: '20027', label: 'Pulkovo 1995 / Gauss-Kruger zone 27 - EPSG:20027' },
  { value: '20028', label: 'Pulkovo 1995 / Gauss-Kruger zone 28 - EPSG:20028' },
  { value: '20029', label: 'Pulkovo 1995 / Gauss-Kruger zone 29 - EPSG:20029' },
  { value: '20030', label: 'Pulkovo 1995 / Gauss-Kruger zone 30 - EPSG:20030' },
  { value: '20031', label: 'Pulkovo 1995 / Gauss-Kruger zone 31 - EPSG:20031' },
  { value: '20032', label: 'Pulkovo 1995 / Gauss-Kruger zone 32 - EPSG:20032' },
  { value: '20135', label: 'Adindan / UTM zone 35N - EPSG:20135' },
  { value: '20136', label: 'Adindan / UTM zone 36N - EPSG:20136' },
  { value: '20137', label: 'Adindan / UTM zone 37N - EPSG:20137' },
  { value: '20138', label: 'Adindan / UTM zone 38N - EPSG:20138' },
  { value: '20249', label: 'AGD66 / AMG zone 49 - EPSG:20249' },
  { value: '20250', label: 'AGD66 / AMG zone 50 - EPSG:20250' },
  { value: '20251', label: 'AGD66 / AMG zone 51 - EPSG:20251' },
  { value: '20252', label: 'AGD66 / AMG zone 52 - EPSG:20252' },
  { value: '20253', label: 'AGD66 / AMG zone 53 - EPSG:20253' },
  { value: '20254', label: 'AGD66 / AMG zone 54 - EPSG:20254' },
  { value: '20255', label: 'AGD66 / AMG zone 55 - EPSG:20255' },
  { value: '20256', label: 'AGD66 / AMG zone 56 - EPSG:20256' },
  { value: '20257', label: 'AGD66 / AMG zone 57 - EPSG:20257' },
  { value: '20258', label: 'AGD66 / AMG zone 58 - EPSG:20258' },
  { value: '20349', label: 'AGD84 / AMG zone 49 - EPSG:20349' },
  { value: '20350', label: 'AGD84 / AMG zone 50 - EPSG:20350' },
  { value: '20351', label: 'AGD84 / AMG zone 51 - EPSG:20351' },
  { value: '20352', label: 'AGD84 / AMG zone 52 - EPSG:20352' },
  { value: '20353', label: 'AGD84 / AMG zone 53 - EPSG:20353' },
  { value: '20354', label: 'AGD84 / AMG zone 54 - EPSG:20354' },
  { value: '20355', label: 'AGD84 / AMG zone 55 - EPSG:20355' },
  { value: '20356', label: 'AGD84 / AMG zone 56 - EPSG:20356' },
  { value: '20436', label: 'Ain el Abd / UTM zone 36N - EPSG:20436' },
  { value: '20437', label: 'Ain el Abd / UTM zone 37N - EPSG:20437' },
  { value: '20438', label: 'Ain el Abd / UTM zone 38N - EPSG:20438' },
  { value: '20439', label: 'Ain el Abd / UTM zone 39N - EPSG:20439' },
  { value: '20440', label: 'Ain el Abd / UTM zone 40N - EPSG:20440' },
  { value: '20499', label: 'Ain el Abd / Bahrain Grid - EPSG:20499' },
  { value: '20538', label: 'Afgooye / UTM zone 38N - EPSG:20538' },
  { value: '20539', label: 'Afgooye / UTM zone 39N - EPSG:20539' },
  {
    value: '20790',
    label: 'Lisbon (Lisbon) / Portuguese National Grid - EPSG:20790',
  },
  { value: '20791', label: 'Lisbon (Lisbon) / Portuguese Grid - EPSG:20791' },
  { value: '20822', label: 'Aratu / UTM zone 22S - EPSG:20822' },
  { value: '20823', label: 'Aratu / UTM zone 23S - EPSG:20823' },
  { value: '20824', label: 'Aratu / UTM zone 24S - EPSG:20824' },
  { value: '20934', label: 'Arc 1950 / UTM zone 34S - EPSG:20934' },
  { value: '20935', label: 'Arc 1950 / UTM zone 35S - EPSG:20935' },
  { value: '20936', label: 'Arc 1950 / UTM zone 36S - EPSG:20936' },
  { value: '21035', label: 'Arc 1960 / UTM zone 35S - EPSG:21035' },
  { value: '21036', label: 'Arc 1960 / UTM zone 36S - EPSG:21036' },
  { value: '21037', label: 'Arc 1960 / UTM zone 37S - EPSG:21037' },
  { value: '21095', label: 'Arc 1960 / UTM zone 35N - EPSG:21095' },
  { value: '21096', label: 'Arc 1960 / UTM zone 36N - EPSG:21096' },
  { value: '21097', label: 'Arc 1960 / UTM zone 37N - EPSG:21097' },
  { value: '21148', label: 'Batavia / UTM zone 48S - EPSG:21148' },
  { value: '21149', label: 'Batavia / UTM zone 49S - EPSG:21149' },
  { value: '21150', label: 'Batavia / UTM zone 50S - EPSG:21150' },
  {
    value: '21291',
    label: 'Barbados 1938 / British West Indies Grid - EPSG:21291',
  },
  {
    value: '21292',
    label: 'Barbados 1938 / Barbados National Grid - EPSG:21292',
  },
  { value: '21413', label: 'Beijing 1954 / Gauss-Kruger zone 13 - EPSG:21413' },
  { value: '21414', label: 'Beijing 1954 / Gauss-Kruger zone 14 - EPSG:21414' },
  { value: '21415', label: 'Beijing 1954 / Gauss-Kruger zone 15 - EPSG:21415' },
  { value: '21416', label: 'Beijing 1954 / Gauss-Kruger zone 16 - EPSG:21416' },
  { value: '21417', label: 'Beijing 1954 / Gauss-Kruger zone 17 - EPSG:21417' },
  { value: '21418', label: 'Beijing 1954 / Gauss-Kruger zone 18 - EPSG:21418' },
  { value: '21419', label: 'Beijing 1954 / Gauss-Kruger zone 19 - EPSG:21419' },
  { value: '21420', label: 'Beijing 1954 / Gauss-Kruger zone 20 - EPSG:21420' },
  { value: '21421', label: 'Beijing 1954 / Gauss-Kruger zone 21 - EPSG:21421' },
  { value: '21422', label: 'Beijing 1954 / Gauss-Kruger zone 22 - EPSG:21422' },
  { value: '21423', label: 'Beijing 1954 / Gauss-Kruger zone 23 - EPSG:21423' },
  { value: '21453', label: 'Beijing 1954 / Gauss-Kruger CM 75E - EPSG:21453' },
  { value: '21454', label: 'Beijing 1954 / Gauss-Kruger CM 81E - EPSG:21454' },
  { value: '21455', label: 'Beijing 1954 / Gauss-Kruger CM 87E - EPSG:21455' },
  { value: '21456', label: 'Beijing 1954 / Gauss-Kruger CM 93E - EPSG:21456' },
  { value: '21457', label: 'Beijing 1954 / Gauss-Kruger CM 99E - EPSG:21457' },
  { value: '21458', label: 'Beijing 1954 / Gauss-Kruger CM 105E - EPSG:21458' },
  { value: '21459', label: 'Beijing 1954 / Gauss-Kruger CM 111E - EPSG:21459' },
  { value: '21460', label: 'Beijing 1954 / Gauss-Kruger CM 117E - EPSG:21460' },
  { value: '21461', label: 'Beijing 1954 / Gauss-Kruger CM 123E - EPSG:21461' },
  { value: '21462', label: 'Beijing 1954 / Gauss-Kruger CM 129E - EPSG:21462' },
  { value: '21463', label: 'Beijing 1954 / Gauss-Kruger CM 135E - EPSG:21463' },
  {
    value: '21500',
    label: 'Belge 1950 (Brussels) / Belge Lambert 50 - EPSG:21500',
  },
  { value: '21780', label: 'Bern 1898 (Bern) / LV03C - EPSG:21780' },
  { value: '21781', label: 'CH1903 / LV03 - EPSG:21781' },
  { value: '21782', label: 'CH1903 / LV03C-G - EPSG:21782' },
  { value: '21818', label: 'Bogota 1975 / UTM zone 18N - EPSG:21818' },
  { value: '21896', label: 'Bogota 1975 / Colombia West zone - EPSG:21896' },
  { value: '21897', label: 'Bogota 1975 / Colombia Bogota zone - EPSG:21897' },
  {
    value: '21898',
    label: 'Bogota 1975 / Colombia East Central zone - EPSG:21898',
  },
  { value: '21899', label: 'Bogota 1975 / Colombia East - EPSG:21899' },
  { value: '22032', label: 'Camacupa / UTM zone 32S - EPSG:22032' },
  { value: '22033', label: 'Camacupa / UTM zone 33S - EPSG:22033' },
  { value: '22091', label: 'Camacupa / TM 11.30 SE - EPSG:22091' },
  { value: '22092', label: 'Camacupa / TM 12 SE - EPSG:22092' },
  { value: '22171', label: 'POSGAR 98 / Argentina 1 - EPSG:22171' },
  { value: '22172', label: 'POSGAR 98 / Argentina 2 - EPSG:22172' },
  { value: '22173', label: 'POSGAR 98 / Argentina 3 - EPSG:22173' },
  { value: '22174', label: 'POSGAR 98 / Argentina 4 - EPSG:22174' },
  { value: '22175', label: 'POSGAR 98 / Argentina 5 - EPSG:22175' },
  { value: '22176', label: 'POSGAR 98 / Argentina 6 - EPSG:22176' },
  { value: '22177', label: 'POSGAR 98 / Argentina 7 - EPSG:22177' },
  { value: '22181', label: 'POSGAR 94 / Argentina 1 - EPSG:22181' },
  { value: '22182', label: 'POSGAR 94 / Argentina 2 - EPSG:22182' },
  { value: '22183', label: 'POSGAR 94 / Argentina 3 - EPSG:22183' },
  { value: '22184', label: 'POSGAR 94 / Argentina 4 - EPSG:22184' },
  { value: '22185', label: 'POSGAR 94 / Argentina 5 - EPSG:22185' },
  { value: '22186', label: 'POSGAR 94 / Argentina 6 - EPSG:22186' },
  { value: '22187', label: 'POSGAR 94 / Argentina 7 - EPSG:22187' },
  { value: '22191', label: 'Campo Inchauspe / Argentina 1 - EPSG:22191' },
  { value: '22192', label: 'Campo Inchauspe / Argentina 2 - EPSG:22192' },
  { value: '22193', label: 'Campo Inchauspe / Argentina 3 - EPSG:22193' },
  { value: '22194', label: 'Campo Inchauspe / Argentina 4 - EPSG:22194' },
  { value: '22195', label: 'Campo Inchauspe / Argentina 5 - EPSG:22195' },
  { value: '22196', label: 'Campo Inchauspe / Argentina 6 - EPSG:22196' },
  { value: '22197', label: 'Campo Inchauspe / Argentina 7 - EPSG:22197' },
  { value: '22234', label: 'Cape / UTM zone 34S - EPSG:22234' },
  { value: '22235', label: 'Cape / UTM zone 35S - EPSG:22235' },

  { value: '22275', label: 'Cape / Lo15 - EPSG:22275' },
  { value: '22277', label: 'Cape / Lo17 - EPSG:22277' },
  { value: '22279', label: 'Cape / Lo19 - EPSG:22279' },
  { value: '22281', label: 'Cape / Lo21 - EPSG:22281' },
  { value: '22283', label: 'Cape / Lo23 - EPSG:22283' },
  { value: '22285', label: 'Cape / Lo25 - EPSG:22285' },
  { value: '22287', label: 'Cape / Lo27 - EPSG:22287' },
  { value: '22289', label: 'Cape / Lo29 - EPSG:22289' },
  { value: '22291', label: 'Cape / Lo31 - EPSG:22291' },
  { value: '22293', label: 'Cape / Lo33 - EPSG:22293' },
  {
    value: '22300',
    label: 'Carthage (Paris) / Tunisia Mining Grid - EPSG:22300',
  },
  { value: '22332', label: 'Carthage / UTM zone 32N - EPSG:22332' },
  { value: '22391', label: 'Carthage / Nord Tunisie - EPSG:22391' },
  { value: '22392', label: 'Carthage / Sud Tunisie - EPSG:22392' },
  {
    value: '22521',
    label: 'Corrego Alegre 1970-72 / UTM zone 21S - EPSG:22521',
  },
  {
    value: '22522',
    label: 'Corrego Alegre 1970-72 / UTM zone 22S - EPSG:22522',
  },
  {
    value: '22523',
    label: 'Corrego Alegre 1970-72 / UTM zone 23S - EPSG:22523',
  },
  {
    value: '22524',
    label: 'Corrego Alegre 1970-72 / UTM zone 24S - EPSG:22524',
  },
  {
    value: '22525',
    label: 'Corrego Alegre 1970-72 / UTM zone 25S - EPSG:22525',
  },
  { value: '22700', label: 'Deir ez Zor / Levant Zone - EPSG:22700' },
  { value: '22770', label: 'Deir ez Zor / Syria Lambert - EPSG:22770' },
  { value: '22780', label: 'Deir ez Zor / Levant Stereographic - EPSG:22780' },
  { value: '22991', label: 'Egypt 1907 / Blue Belt - EPSG:22991' },
  { value: '22992', label: 'Egypt 1907 / Red Belt - EPSG:22992' },
  { value: '22993', label: 'Egypt 1907 / Purple Belt - EPSG:22993' },
  { value: '22994', label: 'Egypt 1907 / Extended Purple Belt - EPSG:22994' },
  { value: '23028', label: 'ED50 / UTM zone 28N - EPSG:23028' },
  { value: '23029', label: 'ED50 / UTM zone 29N - EPSG:23029' },
  { value: '23030', label: 'ED50 / UTM zone 30N - EPSG:23030' },
  { value: '23031', label: 'ED50 / UTM zone 31N - EPSG:23031' },
  { value: '23032', label: 'ED50 / UTM zone 32N - EPSG:23032' },
  { value: '23033', label: 'ED50 / UTM zone 33N - EPSG:23033' },
  { value: '23034', label: 'ED50 / UTM zone 34N - EPSG:23034' },
  { value: '23035', label: 'ED50 / UTM zone 35N - EPSG:23035' },
  { value: '23036', label: 'ED50 / UTM zone 36N - EPSG:23036' },
  { value: '23037', label: 'ED50 / UTM zone 37N - EPSG:23037' },
  { value: '23038', label: 'ED50 / UTM zone 38N - EPSG:23038' },
  { value: '23090', label: 'ED50 / TM 0 N - EPSG:23090' },
  { value: '23095', label: 'ED50 / TM 5 NE - EPSG:23095' },
  { value: '23239', label: 'Fahud / UTM zone 39N - EPSG:23239' },
  { value: '23240', label: 'Fahud / UTM zone 40N - EPSG:23240' },
  { value: '23700', label: 'HD72 / EOV - EPSG:23700' },
  { value: '23830', label: 'DGN95 / Indonesia TM-3 zone 46.2 - EPSG:23830' },
  { value: '23831', label: 'DGN95 / Indonesia TM-3 zone 47.1 - EPSG:23831' },
  { value: '23832', label: 'DGN95 / Indonesia TM-3 zone 47.2 - EPSG:23832' },
  { value: '23833', label: 'DGN95 / Indonesia TM-3 zone 48.1 - EPSG:23833' },
  { value: '23834', label: 'DGN95 / Indonesia TM-3 zone 48.2 - EPSG:23834' },
  { value: '23835', label: 'DGN95 / Indonesia TM-3 zone 49.1 - EPSG:23835' },
  { value: '23836', label: 'DGN95 / Indonesia TM-3 zone 49.2 - EPSG:23836' },
  { value: '23837', label: 'DGN95 / Indonesia TM-3 zone 50.1 - EPSG:23837' },
  { value: '23838', label: 'DGN95 / Indonesia TM-3 zone 50.2 - EPSG:23838' },
  { value: '23839', label: 'DGN95 / Indonesia TM-3 zone 51.1 - EPSG:23839' },
  { value: '23840', label: 'DGN95 / Indonesia TM-3 zone 51.2 - EPSG:23840' },
  { value: '23841', label: 'DGN95 / Indonesia TM-3 zone 52.1 - EPSG:23841' },
  { value: '23842', label: 'DGN95 / Indonesia TM-3 zone 52.2 - EPSG:23842' },
  { value: '23843', label: 'DGN95 / Indonesia TM-3 zone 53.1 - EPSG:23843' },
  { value: '23844', label: 'DGN95 / Indonesia TM-3 zone 53.2 - EPSG:23844' },
  { value: '23845', label: 'DGN95 / Indonesia TM-3 zone 54.1 - EPSG:23845' },
  { value: '23846', label: 'ID74 / UTM zone 46N - EPSG:23846' },
  { value: '23847', label: 'ID74 / UTM zone 47N - EPSG:23847' },
  { value: '23848', label: 'ID74 / UTM zone 48N - EPSG:23848' },
  { value: '23849', label: 'ID74 / UTM zone 49N - EPSG:23849' },
  { value: '23850', label: 'ID74 / UTM zone 50N - EPSG:23850' },
  { value: '23851', label: 'ID74 / UTM zone 51N - EPSG:23851' },
  { value: '23852', label: 'ID74 / UTM zone 52N - EPSG:23852' },
  { value: '23866', label: 'DGN95 / UTM zone 46N - EPSG:23866' },
  { value: '23867', label: 'DGN95 / UTM zone 47N - EPSG:23867' },
  { value: '23868', label: 'DGN95 / UTM zone 48N - EPSG:23868' },
  { value: '23869', label: 'DGN95 / UTM zone 49N - EPSG:23869' },
  { value: '23870', label: 'DGN95 / UTM zone 50N - EPSG:23870' },
  { value: '23871', label: 'DGN95 / UTM zone 51N - EPSG:23871' },
  { value: '23872', label: 'DGN95 / UTM zone 52N - EPSG:23872' },
  { value: '23877', label: 'DGN95 / UTM zone 47S - EPSG:23877' },
  { value: '23878', label: 'DGN95 / UTM zone 48S - EPSG:23878' },
  { value: '23879', label: 'DGN95 / UTM zone 49S - EPSG:23879' },
  { value: '23880', label: 'DGN95 / UTM zone 50S - EPSG:23880' },
  { value: '23881', label: 'DGN95 / UTM zone 51S - EPSG:23881' },
  { value: '23882', label: 'DGN95 / UTM zone 52S - EPSG:23882' },
  { value: '23883', label: 'DGN95 / UTM zone 53S - EPSG:23883' },
  { value: '23884', label: 'DGN95 / UTM zone 54S - EPSG:23884' },
  { value: '23887', label: 'ID74 / UTM zone 47S - EPSG:23887' },
  { value: '23888', label: 'ID74 / UTM zone 48S - EPSG:23888' },
  { value: '23889', label: 'ID74 / UTM zone 49S - EPSG:23889' },
  { value: '23890', label: 'ID74 / UTM zone 50S - EPSG:23890' },
  { value: '23891', label: 'ID74 / UTM zone 51S - EPSG:23891' },
  { value: '23892', label: 'ID74 / UTM zone 52S - EPSG:23892' },
  { value: '23893', label: 'ID74 / UTM zone 53S - EPSG:23893' },
  { value: '23894', label: 'ID74 / UTM zone 54S - EPSG:23894' },
  { value: '23946', label: 'Indian 1954 / UTM zone 46N - EPSG:23946' },
  { value: '23947', label: 'Indian 1954 / UTM zone 47N - EPSG:23947' },
  { value: '23948', label: 'Indian 1954 / UTM zone 48N - EPSG:23948' },
  { value: '24047', label: 'Indian 1975 / UTM zone 47N - EPSG:24047' },
  { value: '24048', label: 'Indian 1975 / UTM zone 48N - EPSG:24048' },
  { value: '24100', label: 'Jamaica 1875 / Jamaica (Old Grid) - EPSG:24100' },
  { value: '24200', label: 'JAD69 / Jamaica National Grid - EPSG:24200' },
  { value: '24305', label: 'Kalianpur 1937 / UTM zone 45N - EPSG:24305' },
  { value: '24306', label: 'Kalianpur 1937 / UTM zone 46N - EPSG:24306' },
  { value: '24311', label: 'Kalianpur 1962 / UTM zone 41N - EPSG:24311' },
  { value: '24312', label: 'Kalianpur 1962 / UTM zone 42N - EPSG:24312' },
  { value: '24313', label: 'Kalianpur 1962 / UTM zone 43N - EPSG:24313' },
  { value: '24342', label: 'Kalianpur 1975 / UTM zone 42N - EPSG:24342' },
  { value: '24343', label: 'Kalianpur 1975 / UTM zone 43N - EPSG:24343' },
  { value: '24344', label: 'Kalianpur 1975 / UTM zone 44N - EPSG:24344' },
  { value: '24345', label: 'Kalianpur 1975 / UTM zone 45N - EPSG:24345' },
  { value: '24346', label: 'Kalianpur 1975 / UTM zone 46N - EPSG:24346' },
  { value: '24347', label: 'Kalianpur 1975 / UTM zone 47N - EPSG:24347' },
  { value: '24370', label: 'Kalianpur 1880 / India zone 0 - EPSG:24370' },
  { value: '24371', label: 'Kalianpur 1880 / India zone I - EPSG:24371' },
  { value: '24372', label: 'Kalianpur 1880 / India zone IIa - EPSG:24372' },
  { value: '24373', label: 'Kalianpur 1880 / India zone IIIa - EPSG:24373' },
  { value: '24374', label: 'Kalianpur 1880 / India zone IVa - EPSG:24374' },
  { value: '24375', label: 'Kalianpur 1937 / India zone IIb - EPSG:24375' },
  { value: '24376', label: 'Kalianpur 1962 / India zone I - EPSG:24376' },
  { value: '24377', label: 'Kalianpur 1962 / India zone IIa - EPSG:24377' },
  { value: '24378', label: 'Kalianpur 1975 / India zone I - EPSG:24378' },
  { value: '24379', label: 'Kalianpur 1975 / India zone IIa - EPSG:24379' },
  { value: '24380', label: 'Kalianpur 1975 / India zone IIb - EPSG:24380' },
  { value: '24381', label: 'Kalianpur 1975 / India zone IIIa - EPSG:24381' },
  { value: '24382', label: 'Kalianpur 1880 / India zone IIb - EPSG:24382' },
  { value: '24383', label: 'Kalianpur 1975 / India zone IVa - EPSG:24383' },
  { value: '24500', label: 'Kertau 1968 / Singapore Grid - EPSG:24500' },
  { value: '24547', label: 'Kertau 1968 / UTM zone 47N - EPSG:24547' },
  { value: '24548', label: 'Kertau 1968 / UTM zone 48N - EPSG:24548' },
  { value: '24600', label: 'KOC Lambert - EPSG:24600' },
  { value: '24718', label: 'La Canoa / UTM zone 18N - EPSG:24718' },
  { value: '24719', label: 'La Canoa / UTM zone 19N - EPSG:24719' },
  { value: '24720', label: 'La Canoa / UTM zone 20N - EPSG:24720' },
  { value: '24817', label: 'PSAD56 / UTM zone 17N - EPSG:24817' },
  { value: '24818', label: 'PSAD56 / UTM zone 18N - EPSG:24818' },
  { value: '24819', label: 'PSAD56 / UTM zone 19N - EPSG:24819' },
  { value: '24820', label: 'PSAD56 / UTM zone 20N - EPSG:24820' },
  { value: '24821', label: 'PSAD56 / UTM zone 21N - EPSG:24821' },
  { value: '24877', label: 'PSAD56 / UTM zone 17S - EPSG:24877' },
  { value: '24878', label: 'PSAD56 / UTM zone 18S - EPSG:24878' },
  { value: '24879', label: 'PSAD56 / UTM zone 19S - EPSG:24879' },
  { value: '24880', label: 'PSAD56 / UTM zone 20S - EPSG:24880' },
  { value: '24881', label: 'PSAD56 / UTM zone 21S - EPSG:24881' },
  { value: '24882', label: 'PSAD56 / UTM zone 22S - EPSG:24882' },
  { value: '24891', label: 'PSAD56 / Peru west zone - EPSG:24891' },
  { value: '24892', label: 'PSAD56 / Peru central zone - EPSG:24892' },
  { value: '24893', label: 'PSAD56 / Peru east zone - EPSG:24893' },
  { value: '25000', label: 'Leigon / Ghana Metre Grid - EPSG:25000' },
  { value: '25231', label: 'Lome / UTM zone 31N - EPSG:25231' },
  { value: '25391', label: 'Luzon 1911 / Philippines zone I - EPSG:25391' },
  { value: '25392', label: 'Luzon 1911 / Philippines zone II - EPSG:25392' },
  { value: '25393', label: 'Luzon 1911 / Philippines zone III - EPSG:25393' },
  { value: '25394', label: 'Luzon 1911 / Philippines zone IV - EPSG:25394' },
  { value: '25395', label: 'Luzon 1911 / Philippines zone V - EPSG:25395' },
  { value: '25828', label: 'ETRS89 / UTM zone 28N - EPSG:25828' },
  { value: '25829', label: 'ETRS89 / UTM zone 29N - EPSG:25829' },
  { value: '25830', label: 'ETRS89 / UTM zone 30N - EPSG:25830' },
  { value: '25831', label: 'ETRS89 / UTM zone 31N - EPSG:25831' },
  { value: '25832', label: 'ETRS89 / UTM zone 32N - EPSG:25832' },
  { value: '25833', label: 'ETRS89 / UTM zone 33N - EPSG:25833' },
  { value: '25834', label: 'ETRS89 / UTM zone 34N - EPSG:25834' },
  { value: '25835', label: 'ETRS89 / UTM zone 35N - EPSG:25835' },
  { value: '25836', label: 'ETRS89 / UTM zone 36N - EPSG:25836' },
  { value: '25837', label: 'ETRS89 / UTM zone 37N - EPSG:25837' },
  { value: '25884', label: 'ETRS89 / TM Baltic93 - EPSG:25884' },
  { value: '25932', label: 'Malongo 1987 / UTM zone 32S - EPSG:25932' },
  { value: '26191', label: 'Merchich / Nord Maroc - EPSG:26191' },
  { value: '26192', label: 'Merchich / Sud Maroc - EPSG:26192' },
  { value: '26194', label: 'Merchich / Sahara Nord - EPSG:26194' },
  { value: '26195', label: 'Merchich / Sahara Sud - EPSG:26195' },
  { value: '26237', label: 'Massawa / UTM zone 37N - EPSG:26237' },
  { value: '26331', label: 'Minna / UTM zone 31N - EPSG:26331' },
  { value: '26332', label: 'Minna / UTM zone 32N - EPSG:26332' },
  { value: '26391', label: 'Minna / Nigeria West Belt - EPSG:26391' },
  { value: '26392', label: 'Minna / Nigeria Mid Belt - EPSG:26392' },
  { value: '26393', label: 'Minna / Nigeria East Belt - EPSG:26393' },
  { value: '26632', label: "M'poraloko / UTM zone 32N - EPSG:26632" },
  { value: '26692', label: "M'poraloko / UTM zone 32S - EPSG:26692" },
  { value: '26701', label: 'NAD27 / UTM zone 1N - EPSG:26701' },
  { value: '26702', label: 'NAD27 / UTM zone 2N - EPSG:26702' },
  { value: '26703', label: 'NAD27 / UTM zone 3N - EPSG:26703' },
  { value: '26704', label: 'NAD27 / UTM zone 4N - EPSG:26704' },
  { value: '26705', label: 'NAD27 / UTM zone 5N - EPSG:26705' },
  { value: '26706', label: 'NAD27 / UTM zone 6N - EPSG:26706' },
  { value: '26707', label: 'NAD27 / UTM zone 7N - EPSG:26707' },
  { value: '26708', label: 'NAD27 / UTM zone 8N - EPSG:26708' },
  { value: '26709', label: 'NAD27 / UTM zone 9N - EPSG:26709' },
  { value: '26710', label: 'NAD27 / UTM zone 10N - EPSG:26710' },
  { value: '26711', label: 'NAD27 / UTM zone 11N - EPSG:26711' },
  { value: '26712', label: 'NAD27 / UTM zone 12N - EPSG:26712' },
  { value: '26713', label: 'NAD27 / UTM zone 13N - EPSG:26713' },
  { value: '26714', label: 'NAD27 / UTM zone 14N - EPSG:26714' },
  { value: '26715', label: 'NAD27 / UTM zone 15N - EPSG:26715' },
  { value: '26716', label: 'NAD27 / UTM zone 16N - EPSG:26716' },
  { value: '26717', label: 'NAD27 / UTM zone 17N - EPSG:26717' },
  { value: '26718', label: 'NAD27 / UTM zone 18N - EPSG:26718' },
  { value: '26719', label: 'NAD27 / UTM zone 19N - EPSG:26719' },
  { value: '26720', label: 'NAD27 / UTM zone 20N - EPSG:26720' },
  { value: '26721', label: 'NAD27 / UTM zone 21N - EPSG:26721' },
  { value: '26722', label: 'NAD27 / UTM zone 22N - EPSG:26722' },
  { value: '26729', label: 'NAD27 / Alabama East - EPSG:26729' },
  { value: '26730', label: 'NAD27 / Alabama West - EPSG:26730' },
  { value: '26731', label: 'NAD27 / Alaska zone 1 - EPSG:26731' },
  { value: '26732', label: 'NAD27 / Alaska zone 2 - EPSG:26732' },
  { value: '26733', label: 'NAD27 / Alaska zone 3 - EPSG:26733' },
  { value: '26734', label: 'NAD27 / Alaska zone 4 - EPSG:26734' },
  { value: '26735', label: 'NAD27 / Alaska zone 5 - EPSG:26735' },
  { value: '26736', label: 'NAD27 / Alaska zone 6 - EPSG:26736' },
  { value: '26737', label: 'NAD27 / Alaska zone 7 - EPSG:26737' },
  { value: '26738', label: 'NAD27 / Alaska zone 8 - EPSG:26738' },
  { value: '26739', label: 'NAD27 / Alaska zone 9 - EPSG:26739' },
  { value: '26740', label: 'NAD27 / Alaska zone 10 - EPSG:26740' },
  { value: '26741', label: 'NAD27 / California zone I - EPSG:26741' },
  { value: '26742', label: 'NAD27 / California zone II - EPSG:26742' },
  { value: '26743', label: 'NAD27 / California zone III - EPSG:26743' },
  { value: '26744', label: 'NAD27 / California zone IV - EPSG:26744' },
  { value: '26745', label: 'NAD27 / California zone V - EPSG:26745' },
  { value: '26746', label: 'NAD27 / California zone VI - EPSG:26746' },

  { value: '26748', label: 'NAD27 / Arizona East - EPSG:26748' },
  { value: '26749', label: 'NAD27 / Arizona Central - EPSG:26749' },
  { value: '26750', label: 'NAD27 / Arizona West - EPSG:26750' },
  { value: '26751', label: 'NAD27 / Arkansas North - EPSG:26751' },
  { value: '26752', label: 'NAD27 / Arkansas South - EPSG:26752' },
  { value: '26753', label: 'NAD27 / Colorado North - EPSG:26753' },
  { value: '26754', label: 'NAD27 / Colorado Central - EPSG:26754' },
  { value: '26755', label: 'NAD27 / Colorado South - EPSG:26755' },
  { value: '26756', label: 'NAD27 / Connecticut - EPSG:26756' },
  { value: '26757', label: 'NAD27 / Delaware - EPSG:26757' },
  { value: '26758', label: 'NAD27 / Florida East - EPSG:26758' },
  { value: '26759', label: 'NAD27 / Florida West - EPSG:26759' },
  { value: '26760', label: 'NAD27 / Florida North - EPSG:26760' },
  { value: '26766', label: 'NAD27 / Georgia East - EPSG:26766' },
  { value: '26767', label: 'NAD27 / Georgia West - EPSG:26767' },
  { value: '26768', label: 'NAD27 / Idaho East - EPSG:26768' },
  { value: '26769', label: 'NAD27 / Idaho Central - EPSG:26769' },
  { value: '26770', label: 'NAD27 / Idaho West - EPSG:26770' },
  { value: '26771', label: 'NAD27 / Illinois East - EPSG:26771' },
  { value: '26772', label: 'NAD27 / Illinois West - EPSG:26772' },
  { value: '26773', label: 'NAD27 / Indiana East - EPSG:26773' },
  { value: '26774', label: 'NAD27 / Indiana West - EPSG:26774' },
  { value: '26775', label: 'NAD27 / Iowa North - EPSG:26775' },
  { value: '26776', label: 'NAD27 / Iowa South - EPSG:26776' },
  { value: '26777', label: 'NAD27 / Kansas North - EPSG:26777' },
  { value: '26778', label: 'NAD27 / Kansas South - EPSG:26778' },
  { value: '26779', label: 'NAD27 / Kentucky North - EPSG:26779' },
  { value: '26780', label: 'NAD27 / Kentucky South - EPSG:26780' },
  { value: '26781', label: 'NAD27 / Louisiana North - EPSG:26781' },
  { value: '26782', label: 'NAD27 / Louisiana South - EPSG:26782' },
  { value: '26783', label: 'NAD27 / Maine East - EPSG:26783' },
  { value: '26784', label: 'NAD27 / Maine West - EPSG:26784' },
  { value: '26785', label: 'NAD27 / Maryland - EPSG:26785' },
  { value: '26786', label: 'NAD27 / Massachusetts Mainland - EPSG:26786' },
  { value: '26787', label: 'NAD27 / Massachusetts Island - EPSG:26787' },
  { value: '26791', label: 'NAD27 / Minnesota North - EPSG:26791' },
  { value: '26792', label: 'NAD27 / Minnesota Central - EPSG:26792' },
  { value: '26793', label: 'NAD27 / Minnesota South - EPSG:26793' },
  { value: '26794', label: 'NAD27 / Mississippi East - EPSG:26794' },
  { value: '26795', label: 'NAD27 / Mississippi West - EPSG:26795' },
  { value: '26796', label: 'NAD27 / Missouri East - EPSG:26796' },
  { value: '26797', label: 'NAD27 / Missouri Central - EPSG:26797' },
  { value: '26798', label: 'NAD27 / Missouri West - EPSG:26798' },
  { value: '26799', label: 'NAD27 / California zone VII - EPSG:26799' },
  { value: '26847', label: 'NAD83 / Maine East (ftUS) - EPSG:26847' },
  { value: '26848', label: 'NAD83 / Maine West (ftUS) - EPSG:26848' },
  { value: '26849', label: 'NAD83 / Minnesota North (ftUS) - EPSG:26849' },
  { value: '26850', label: 'NAD83 / Minnesota Central (ftUS) - EPSG:26850' },
  { value: '26851', label: 'NAD83 / Minnesota South (ftUS) - EPSG:26851' },
  { value: '26852', label: 'NAD83 / Nebraska (ftUS) - EPSG:26852' },
  { value: '26853', label: 'NAD83 / West Virginia North (ftUS) - EPSG:26853' },
  { value: '26854', label: 'NAD83 / West Virginia South (ftUS) - EPSG:26854' },
  { value: '26855', label: 'NAD83(HARN) / Maine East (ftUS) - EPSG:26855' },
  { value: '26856', label: 'NAD83(HARN) / Maine West (ftUS) - EPSG:26856' },
  {
    value: '26857',
    label: 'NAD83(HARN) / Minnesota North (ftUS) - EPSG:26857',
  },
  {
    value: '26858',
    label: 'NAD83(HARN) / Minnesota Central (ftUS) - EPSG:26858',
  },
  {
    value: '26859',
    label: 'NAD83(HARN) / Minnesota South (ftUS) - EPSG:26859',
  },
  { value: '26860', label: 'NAD83(HARN) / Nebraska (ftUS) - EPSG:26860' },
  {
    value: '26861',
    label: 'NAD83(HARN) / West Virginia North (ftUS) - EPSG:26861',
  },
  {
    value: '26862',
    label: 'NAD83(HARN) / West Virginia South (ftUS) - EPSG:26862',
  },
  { value: '26863', label: 'NAD83(NSRS2007) / Maine East (ftUS) - EPSG:26863' },
  { value: '26864', label: 'NAD83(NSRS2007) / Maine West (ftUS) - EPSG:26864' },
  {
    value: '26865',
    label: 'NAD83(NSRS2007) / Minnesota North (ftUS) - EPSG:26865',
  },
  {
    value: '26866',
    label: 'NAD83(NSRS2007) / Minnesota Central (ftUS) - EPSG:26866',
  },
  {
    value: '26867',
    label: 'NAD83(NSRS2007) / Minnesota South (ftUS) - EPSG:26867',
  },
  { value: '26868', label: 'NAD83(NSRS2007) / Nebraska (ftUS) - EPSG:26868' },
  {
    value: '26869',
    label: 'NAD83(NSRS2007) / West Virginia North (ftUS) - EPSG:26869',
  },
  {
    value: '26870',
    label: 'NAD83(NSRS2007) / West Virginia South (ftUS) - EPSG:26870',
  },
  { value: '26891', label: 'NAD83(CSRS) / MTM zone 11 - EPSG:26891' },
  { value: '26892', label: 'NAD83(CSRS) / MTM zone 12 - EPSG:26892' },
  { value: '26893', label: 'NAD83(CSRS) / MTM zone 13 - EPSG:26893' },
  { value: '26894', label: 'NAD83(CSRS) / MTM zone 14 - EPSG:26894' },
  { value: '26895', label: 'NAD83(CSRS) / MTM zone 15 - EPSG:26895' },
  { value: '26896', label: 'NAD83(CSRS) / MTM zone 16 - EPSG:26896' },
  { value: '26897', label: 'NAD83(CSRS) / MTM zone 17 - EPSG:26897' },
  { value: '26898', label: 'NAD83(CSRS) / MTM zone 1 - EPSG:26898' },
  { value: '26899', label: 'NAD83(CSRS) / MTM zone 2 - EPSG:26899' },
  { value: '26901', label: 'NAD83 / UTM zone 1N - EPSG:26901' },
  { value: '26902', label: 'NAD83 / UTM zone 2N - EPSG:26902' },
  { value: '26903', label: 'NAD83 / UTM zone 3N - EPSG:26903' },
  { value: '26904', label: 'NAD83 / UTM zone 4N - EPSG:26904' },
  { value: '26905', label: 'NAD83 / UTM zone 5N - EPSG:26905' },
  { value: '26906', label: 'NAD83 / UTM zone 6N - EPSG:26906' },
  { value: '26907', label: 'NAD83 / UTM zone 7N - EPSG:26907' },
  { value: '26908', label: 'NAD83 / UTM zone 8N - EPSG:26908' },
  { value: '26909', label: 'NAD83 / UTM zone 9N - EPSG:26909' },
  { value: '26910', label: 'NAD83 / UTM zone 10N - EPSG:26910' },
  { value: '26911', label: 'NAD83 / UTM zone 11N - EPSG:26911' },
  { value: '26912', label: 'NAD83 / UTM zone 12N - EPSG:26912' },
  { value: '26913', label: 'NAD83 / UTM zone 13N - EPSG:26913' },
  { value: '26914', label: 'NAD83 / UTM zone 14N - EPSG:26914' },
  { value: '26915', label: 'NAD83 / UTM zone 15N - EPSG:26915' },
  { value: '26916', label: 'NAD83 / UTM zone 16N - EPSG:26916' },
  { value: '26917', label: 'NAD83 / UTM zone 17N - EPSG:26917' },
  { value: '26918', label: 'NAD83 / UTM zone 18N - EPSG:26918' },
  { value: '26919', label: 'NAD83 / UTM zone 19N - EPSG:26919' },
  { value: '26920', label: 'NAD83 / UTM zone 20N - EPSG:26920' },
  { value: '26921', label: 'NAD83 / UTM zone 21N - EPSG:26921' },
  { value: '26922', label: 'NAD83 / UTM zone 22N - EPSG:26922' },
  { value: '26923', label: 'NAD83 / UTM zone 23N - EPSG:26923' },
  { value: '26929', label: 'NAD83 / Alabama East - EPSG:26929' },
  { value: '26930', label: 'NAD83 / Alabama West - EPSG:26930' },
  { value: '26931', label: 'NAD83 / Alaska zone 1 - EPSG:26931' },
  { value: '26932', label: 'NAD83 / Alaska zone 2 - EPSG:26932' },
  { value: '26933', label: 'NAD83 / Alaska zone 3 - EPSG:26933' },
  { value: '26934', label: 'NAD83 / Alaska zone 4 - EPSG:26934' },
  { value: '26935', label: 'NAD83 / Alaska zone 5 - EPSG:26935' },
  { value: '26936', label: 'NAD83 / Alaska zone 6 - EPSG:26936' },
  { value: '26937', label: 'NAD83 / Alaska zone 7 - EPSG:26937' },
  { value: '26938', label: 'NAD83 / Alaska zone 8 - EPSG:26938' },
  { value: '26939', label: 'NAD83 / Alaska zone 9 - EPSG:26939' },
  { value: '26940', label: 'NAD83 / Alaska zone 10 - EPSG:26940' },
  { value: '26941', label: 'NAD83 / California zone 1 - EPSG:26941' },
  { value: '26942', label: 'NAD83 / California zone 2 - EPSG:26942' },
  { value: '26943', label: 'NAD83 / California zone 3 - EPSG:26943' },
  { value: '26944', label: 'NAD83 / California zone 4 - EPSG:26944' },
  { value: '26945', label: 'NAD83 / California zone 5 - EPSG:26945' },
  { value: '26946', label: 'NAD83 / California zone 6 - EPSG:26946' },
  { value: '26948', label: 'NAD83 / Arizona East - EPSG:26948' },
  { value: '26949', label: 'NAD83 / Arizona Central - EPSG:26949' },
  { value: '26950', label: 'NAD83 / Arizona West - EPSG:26950' },
  { value: '26951', label: 'NAD83 / Arkansas North - EPSG:26951' },
  { value: '26952', label: 'NAD83 / Arkansas South - EPSG:26952' },
  { value: '26953', label: 'NAD83 / Colorado North - EPSG:26953' },
  { value: '26954', label: 'NAD83 / Colorado Central - EPSG:26954' },
  { value: '26955', label: 'NAD83 / Colorado South - EPSG:26955' },
  { value: '26956', label: 'NAD83 / Connecticut - EPSG:26956' },
  { value: '26957', label: 'NAD83 / Delaware - EPSG:26957' },
  { value: '26958', label: 'NAD83 / Florida East - EPSG:26958' },
  { value: '26959', label: 'NAD83 / Florida West - EPSG:26959' },
  { value: '26960', label: 'NAD83 / Florida North - EPSG:26960' },
  { value: '26961', label: 'NAD83 / Hawaii zone 1 - EPSG:26961' },
  { value: '26962', label: 'NAD83 / Hawaii zone 2 - EPSG:26962' },
  { value: '26963', label: 'NAD83 / Hawaii zone 3 - EPSG:26963' },
  { value: '26964', label: 'NAD83 / Hawaii zone 4 - EPSG:26964' },
  { value: '26965', label: 'NAD83 / Hawaii zone 5 - EPSG:26965' },
  { value: '26966', label: 'NAD83 / Georgia East - EPSG:26966' },
  { value: '26967', label: 'NAD83 / Georgia West - EPSG:26967' },
  { value: '26968', label: 'NAD83 / Idaho East - EPSG:26968' },
  { value: '26969', label: 'NAD83 / Idaho Central - EPSG:26969' },
  { value: '26970', label: 'NAD83 / Idaho West - EPSG:26970' },
  { value: '26971', label: 'NAD83 / Illinois East - EPSG:26971' },
  { value: '26972', label: 'NAD83 / Illinois West - EPSG:26972' },
  { value: '26973', label: 'NAD83 / Indiana East - EPSG:26973' },
  { value: '26974', label: 'NAD83 / Indiana West - EPSG:26974' },
  { value: '26975', label: 'NAD83 / Iowa North - EPSG:26975' },
  { value: '26976', label: 'NAD83 / Iowa South - EPSG:26976' },
  { value: '26977', label: 'NAD83 / Kansas North - EPSG:26977' },
  { value: '26978', label: 'NAD83 / Kansas South - EPSG:26978' },

  { value: '26980', label: 'NAD83 / Kentucky South - EPSG:26980' },
  { value: '26981', label: 'NAD83 / Louisiana North - EPSG:26981' },
  { value: '26982', label: 'NAD83 / Louisiana South - EPSG:26982' },
  { value: '26983', label: 'NAD83 / Maine East - EPSG:26983' },
  { value: '26984', label: 'NAD83 / Maine West - EPSG:26984' },
  { value: '26985', label: 'NAD83 / Maryland - EPSG:26985' },
  { value: '26986', label: 'NAD83 / Massachusetts Mainland - EPSG:26986' },
  { value: '26987', label: 'NAD83 / Massachusetts Island - EPSG:26987' },
  { value: '26988', label: 'NAD83 / Michigan North - EPSG:26988' },
  { value: '26989', label: 'NAD83 / Michigan Central - EPSG:26989' },
  { value: '26990', label: 'NAD83 / Michigan South - EPSG:26990' },
  { value: '26991', label: 'NAD83 / Minnesota North - EPSG:26991' },
  { value: '26992', label: 'NAD83 / Minnesota Central - EPSG:26992' },
  { value: '26993', label: 'NAD83 / Minnesota South - EPSG:26993' },
  { value: '26994', label: 'NAD83 / Mississippi East - EPSG:26994' },
  { value: '26995', label: 'NAD83 / Mississippi West - EPSG:26995' },
  { value: '26996', label: 'NAD83 / Missouri East - EPSG:26996' },
  { value: '26997', label: 'NAD83 / Missouri Central - EPSG:26997' },
  { value: '26998', label: 'NAD83 / Missouri West - EPSG:26998' },

  { value: '27039', label: 'Nahrwan 1967 / UTM zone 39N - EPSG:27039' },
  { value: '27040', label: 'Nahrwan 1967 / UTM zone 40N - EPSG:27040' },
  { value: '27120', label: 'Naparima 1972 / UTM zone 20N - EPSG:27120' },
  { value: '27200', label: 'NZGD49 / New Zealand Map Grid - EPSG:27200' },
  { value: '27205', label: 'NZGD49 / Mount Eden Circuit - EPSG:27205' },
  { value: '27206', label: 'NZGD49 / Bay of Plenty Circuit - EPSG:27206' },
  { value: '27207', label: 'NZGD49 / Poverty Bay Circuit - EPSG:27207' },
  { value: '27208', label: 'NZGD49 / Hawkes Bay Circuit - EPSG:27208' },
  { value: '27209', label: 'NZGD49 / Taranaki Circuit - EPSG:27209' },
  { value: '27210', label: 'NZGD49 / Tuhirangi Circuit - EPSG:27210' },
  { value: '27211', label: 'NZGD49 / Wanganui Circuit - EPSG:27211' },
  { value: '27212', label: 'NZGD49 / Wairarapa Circuit - EPSG:27212' },
  { value: '27213', label: 'NZGD49 / Wellington Circuit - EPSG:27213' },
  { value: '27214', label: 'NZGD49 / Collingwood Circuit - EPSG:27214' },
  { value: '27215', label: 'NZGD49 / Nelson Circuit - EPSG:27215' },
  { value: '27216', label: 'NZGD49 / Karamea Circuit - EPSG:27216' },
  { value: '27217', label: 'NZGD49 / Buller Circuit - EPSG:27217' },
  { value: '27218', label: 'NZGD49 / Grey Circuit - EPSG:27218' },
  { value: '27219', label: 'NZGD49 / Amuri Circuit - EPSG:27219' },
  { value: '27220', label: 'NZGD49 / Marlborough Circuit - EPSG:27220' },
  { value: '27221', label: 'NZGD49 / Hokitika Circuit - EPSG:27221' },
  { value: '27222', label: 'NZGD49 / Okarito Circuit - EPSG:27222' },
  { value: '27223', label: 'NZGD49 / Jacksons Bay Circuit - EPSG:27223' },
  { value: '27224', label: 'NZGD49 / Mount Pleasant Circuit - EPSG:27224' },
  { value: '27225', label: 'NZGD49 / Gawler Circuit - EPSG:27225' },
  { value: '27226', label: 'NZGD49 / Timaru Circuit - EPSG:27226' },
  { value: '27227', label: 'NZGD49 / Lindis Peak Circuit - EPSG:27227' },
  { value: '27228', label: 'NZGD49 / Mount Nicholas Circuit - EPSG:27228' },
  { value: '27229', label: 'NZGD49 / Mount York Circuit - EPSG:27229' },
  { value: '27230', label: 'NZGD49 / Observation Point Circuit - EPSG:27230' },
  { value: '27231', label: 'NZGD49 / North Taieri Circuit - EPSG:27231' },
  { value: '27232', label: 'NZGD49 / Bluff Circuit - EPSG:27232' },
  { value: '27258', label: 'NZGD49 / UTM zone 58S - EPSG:27258' },
  { value: '27259', label: 'NZGD49 / UTM zone 59S - EPSG:27259' },
  { value: '27260', label: 'NZGD49 / UTM zone 60S - EPSG:27260' },
  { value: '27291', label: 'NZGD49 / North Island Grid - EPSG:27291' },
  { value: '27292', label: 'NZGD49 / South Island Grid - EPSG:27292' },
  { value: '27391', label: 'NGO 1948 (Oslo) / NGO zone I - EPSG:27391' },
  { value: '27392', label: 'NGO 1948 (Oslo) / NGO zone II - EPSG:27392' },
  { value: '27393', label: 'NGO 1948 (Oslo) / NGO zone III - EPSG:27393' },
  { value: '27394', label: 'NGO 1948 (Oslo) / NGO zone IV - EPSG:27394' },
  { value: '27395', label: 'NGO 1948 (Oslo) / NGO zone V - EPSG:27395' },
  { value: '27396', label: 'NGO 1948 (Oslo) / NGO zone VI - EPSG:27396' },
  { value: '27397', label: 'NGO 1948 (Oslo) / NGO zone VII - EPSG:27397' },
  { value: '27398', label: 'NGO 1948 (Oslo) / NGO zone VIII - EPSG:27398' },
  { value: '27429', label: 'Datum 73 / UTM zone 29N - EPSG:27429' },

  { value: '27493', label: 'Datum 73 / Modified Portuguese Grid - EPSG:27493' },
  { value: '27500', label: 'ATF (Paris) / Nord de Guerre - EPSG:27500' },
  { value: '27561', label: 'NTF (Paris) / Lambert Nord France - EPSG:27561' },
  { value: '27562', label: 'NTF (Paris) / Lambert Centre France - EPSG:27562' },
  { value: '27563', label: 'NTF (Paris) / Lambert Sud France - EPSG:27563' },
  { value: '27564', label: 'NTF (Paris) / Lambert Corse - EPSG:27564' },
  { value: '27571', label: 'NTF (Paris) / Lambert zone I - EPSG:27571' },
  { value: '27572', label: 'NTF (Paris) / Lambert zone II - EPSG:27572' },
  { value: '27573', label: 'NTF (Paris) / Lambert zone III - EPSG:27573' },
  { value: '27574', label: 'NTF (Paris) / Lambert zone IV - EPSG:27574' },
  { value: '27700', label: 'OSGB 1936 / British National Grid - EPSG:27700' },
  { value: '28191', label: 'Palestine 1923 / Palestine Grid - EPSG:28191' },
  { value: '28192', label: 'Palestine 1923 / Palestine Belt - EPSG:28192' },
  { value: '28193', label: 'Palestine 1923 / Israeli CS Grid - EPSG:28193' },
  { value: '28232', label: 'Pointe Noire / UTM zone 32S - EPSG:28232' },
  { value: '28348', label: 'GDA94 / MGA zone 48 - EPSG:28348' },
  { value: '28349', label: 'GDA94 / MGA zone 49 - EPSG:28349' },
  { value: '28350', label: 'GDA94 / MGA zone 50 - EPSG:28350' },
  { value: '28351', label: 'GDA94 / MGA zone 51 - EPSG:28351' },
  { value: '28352', label: 'GDA94 / MGA zone 52 - EPSG:28352' },
  { value: '28353', label: 'GDA94 / MGA zone 53 - EPSG:28353' },
  { value: '28354', label: 'GDA94 / MGA zone 54 - EPSG:28354' },
  { value: '28355', label: 'GDA94 / MGA zone 55 - EPSG:28355' },
  { value: '28356', label: 'GDA94 / MGA zone 56 - EPSG:28356' },
  { value: '28357', label: 'GDA94 / MGA zone 57 - EPSG:28357' },
  { value: '28358', label: 'GDA94 / MGA zone 58 - EPSG:28358' },
  { value: '28404', label: 'Pulkovo 1942 / Gauss-Kruger zone 4 - EPSG:28404' },
  { value: '28405', label: 'Pulkovo 1942 / Gauss-Kruger zone 5 - EPSG:28405' },
  { value: '28406', label: 'Pulkovo 1942 / Gauss-Kruger zone 6 - EPSG:28406' },
  { value: '28407', label: 'Pulkovo 1942 / Gauss-Kruger zone 7 - EPSG:28407' },
  { value: '28408', label: 'Pulkovo 1942 / Gauss-Kruger zone 8 - EPSG:28408' },
  { value: '28409', label: 'Pulkovo 1942 / Gauss-Kruger zone 9 - EPSG:28409' },
  { value: '28410', label: 'Pulkovo 1942 / Gauss-Kruger zone 10 - EPSG:28410' },
  { value: '28411', label: 'Pulkovo 1942 / Gauss-Kruger zone 11 - EPSG:28411' },
  { value: '28412', label: 'Pulkovo 1942 / Gauss-Kruger zone 12 - EPSG:28412' },
  { value: '28413', label: 'Pulkovo 1942 / Gauss-Kruger zone 13 - EPSG:28413' },
  { value: '28414', label: 'Pulkovo 1942 / Gauss-Kruger zone 14 - EPSG:28414' },
  { value: '28415', label: 'Pulkovo 1942 / Gauss-Kruger zone 15 - EPSG:28415' },
  { value: '28416', label: 'Pulkovo 1942 / Gauss-Kruger zone 16 - EPSG:28416' },
  { value: '28417', label: 'Pulkovo 1942 / Gauss-Kruger zone 17 - EPSG:28417' },
  { value: '28418', label: 'Pulkovo 1942 / Gauss-Kruger zone 18 - EPSG:28418' },
  { value: '28419', label: 'Pulkovo 1942 / Gauss-Kruger zone 19 - EPSG:28419' },
  { value: '28420', label: 'Pulkovo 1942 / Gauss-Kruger zone 20 - EPSG:28420' },
  { value: '28421', label: 'Pulkovo 1942 / Gauss-Kruger zone 21 - EPSG:28421' },
  { value: '28422', label: 'Pulkovo 1942 / Gauss-Kruger zone 22 - EPSG:28422' },
  { value: '28423', label: 'Pulkovo 1942 / Gauss-Kruger zone 23 - EPSG:28423' },
  { value: '28424', label: 'Pulkovo 1942 / Gauss-Kruger zone 24 - EPSG:28424' },
  { value: '28425', label: 'Pulkovo 1942 / Gauss-Kruger zone 25 - EPSG:28425' },
  { value: '28426', label: 'Pulkovo 1942 / Gauss-Kruger zone 26 - EPSG:28426' },
  { value: '28427', label: 'Pulkovo 1942 / Gauss-Kruger zone 27 - EPSG:28427' },
  { value: '28428', label: 'Pulkovo 1942 / Gauss-Kruger zone 28 - EPSG:28428' },
  { value: '28429', label: 'Pulkovo 1942 / Gauss-Kruger zone 29 - EPSG:28429' },
  { value: '28430', label: 'Pulkovo 1942 / Gauss-Kruger zone 30 - EPSG:28430' },
  { value: '28431', label: 'Pulkovo 1942 / Gauss-Kruger zone 31 - EPSG:28431' },
  { value: '28432', label: 'Pulkovo 1942 / Gauss-Kruger zone 32 - EPSG:28432' },
  { value: '28600', label: 'Qatar 1974 / Qatar National Grid - EPSG:28600' },
  { value: '28991', label: 'Amersfoort / RD Old - EPSG:28991' },
  { value: '28992', label: 'Amersfoort / RD New - EPSG:28992' },
  { value: '29101', label: 'SAD69 / Brazil Polyconic - EPSG:29101' },
  { value: '29168', label: 'SAD69 / UTM zone 18N - EPSG:29168' },
  { value: '29169', label: 'SAD69 / UTM zone 19N - EPSG:29169' },
  { value: '29170', label: 'SAD69 / UTM zone 20N - EPSG:29170' },
  { value: '29171', label: 'SAD69 / UTM zone 21N - EPSG:29171' },
  { value: '29172', label: 'SAD69 / UTM zone 22N - EPSG:29172' },
  { value: '29187', label: 'SAD69 / UTM zone 17S - EPSG:29187' },
  { value: '29188', label: 'SAD69 / UTM zone 18S - EPSG:29188' },
  { value: '29189', label: 'SAD69 / UTM zone 19S - EPSG:29189' },
  { value: '29190', label: 'SAD69 / UTM zone 20S - EPSG:29190' },
  { value: '29191', label: 'SAD69 / UTM zone 21S - EPSG:29191' },
  { value: '29192', label: 'SAD69 / UTM zone 22S - EPSG:29192' },
  { value: '29193', label: 'SAD69 / UTM zone 23S - EPSG:29193' },
  { value: '29194', label: 'SAD69 / UTM zone 24S - EPSG:29194' },
  { value: '29195', label: 'SAD69 / UTM zone 25S - EPSG:29195' },
  { value: '29220', label: 'Sapper Hill 1943 / UTM zone 20S - EPSG:29220' },
  { value: '29221', label: 'Sapper Hill 1943 / UTM zone 21S - EPSG:29221' },
  { value: '29333', label: 'Schwarzeck / UTM zone 33S - EPSG:29333' },
  { value: '29371', label: 'Schwarzeck / Lo22/11 - EPSG:29371' },
  { value: '29373', label: 'Schwarzeck / Lo22/13 - EPSG:29373' },
  { value: '29375', label: 'Schwarzeck / Lo22/15 - EPSG:29375' },
  { value: '29377', label: 'Schwarzeck / Lo22/17 - EPSG:29377' },
  { value: '29379', label: 'Schwarzeck / Lo22/19 - EPSG:29379' },
  { value: '29381', label: 'Schwarzeck / Lo22/21 - EPSG:29381' },
  { value: '29383', label: 'Schwarzeck / Lo22/23 - EPSG:29383' },
  { value: '29385', label: 'Schwarzeck / Lo22/25 - EPSG:29385' },
  { value: '29701', label: 'Tananarive (Paris) / Laborde Grid - EPSG:29701' },
  {
    value: '29702',
    label: 'Tananarive (Paris) / Laborde Grid approximation - EPSG:29702',
  },
  { value: '29738', label: 'Tananarive / UTM zone 38S - EPSG:29738' },
  { value: '29739', label: 'Tananarive / UTM zone 39S - EPSG:29739' },
  { value: '29849', label: 'Timbalai 1948 / UTM zone 49N - EPSG:29849' },
  { value: '29850', label: 'Timbalai 1948 / UTM zone 50N - EPSG:29850' },
  { value: '29871', label: 'Timbalai 1948 / RSO Borneo (ch) - EPSG:29871' },
  { value: '29872', label: 'Timbalai 1948 / RSO Borneo (ftSe) - EPSG:29872' },
  { value: '29873', label: 'Timbalai 1948 / RSO Borneo (m) - EPSG:29873' },
  { value: '29901', label: 'OSNI 1952 / Irish National Grid - EPSG:29901' },
  { value: '29902', label: 'TM65 / Irish Grid - EPSG:29902' },
  { value: '29903', label: 'TM75 / Irish Grid - EPSG:29903' },
  {
    value: '30161',
    label: 'Tokyo / Japan Plane Rectangular CS I - EPSG:30161',
  },
  {
    value: '30162',
    label: 'Tokyo / Japan Plane Rectangular CS II - EPSG:30162',
  },
  {
    value: '30163',
    label: 'Tokyo / Japan Plane Rectangular CS III - EPSG:30163',
  },
  {
    value: '30164',
    label: 'Tokyo / Japan Plane Rectangular CS IV - EPSG:30164',
  },
  {
    value: '30165',
    label: 'Tokyo / Japan Plane Rectangular CS V - EPSG:30165',
  },
  {
    value: '30166',
    label: 'Tokyo / Japan Plane Rectangular CS VI - EPSG:30166',
  },
  {
    value: '30167',
    label: 'Tokyo / Japan Plane Rectangular CS VII - EPSG:30167',
  },
  {
    value: '30168',
    label: 'Tokyo / Japan Plane Rectangular CS VIII - EPSG:30168',
  },
  {
    value: '30169',
    label: 'Tokyo / Japan Plane Rectangular CS IX - EPSG:30169',
  },
  {
    value: '30170',
    label: 'Tokyo / Japan Plane Rectangular CS X - EPSG:30170',
  },
  {
    value: '30171',
    label: 'Tokyo / Japan Plane Rectangular CS XI - EPSG:30171',
  },
  {
    value: '30172',
    label: 'Tokyo / Japan Plane Rectangular CS XII - EPSG:30172',
  },
  {
    value: '30173',
    label: 'Tokyo / Japan Plane Rectangular CS XIII - EPSG:30173',
  },
  {
    value: '30174',
    label: 'Tokyo / Japan Plane Rectangular CS XIV - EPSG:30174',
  },
  {
    value: '30175',
    label: 'Tokyo / Japan Plane Rectangular CS XV - EPSG:30175',
  },
  {
    value: '30176',
    label: 'Tokyo / Japan Plane Rectangular CS XVI - EPSG:30176',
  },
  {
    value: '30177',
    label: 'Tokyo / Japan Plane Rectangular CS XVII - EPSG:30177',
  },
  {
    value: '30178',
    label: 'Tokyo / Japan Plane Rectangular CS XVIII - EPSG:30178',
  },
  {
    value: '30179',
    label: 'Tokyo / Japan Plane Rectangular CS XIX - EPSG:30179',
  },
  { value: '30200', label: 'Trinidad 1903 / Trinidad Grid - EPSG:30200' },
  { value: '30339', label: 'TC(1948) / UTM zone 39N - EPSG:30339' },
  { value: '30340', label: 'TC(1948) / UTM zone 40N - EPSG:30340' },
  {
    value: '30491',
    label: 'Voirol 1875 / Nord Algerie (ancienne) - EPSG:30491',
  },
  {
    value: '30492',
    label: 'Voirol 1875 / Sud Algerie (ancienne) - EPSG:30492',
  },
  {
    value: '30493',
    label: 'Voirol 1879 / Nord Algerie (ancienne) - EPSG:30493',
  },
  {
    value: '30494',
    label: 'Voirol 1879 / Sud Algerie (ancienne) - EPSG:30494',
  },
  { value: '30729', label: 'Nord Sahara 1959 / UTM zone 29N - EPSG:30729' },
  { value: '30730', label: 'Nord Sahara 1959 / UTM zone 30N - EPSG:30730' },
  { value: '30731', label: 'Nord Sahara 1959 / UTM zone 31N - EPSG:30731' },
  { value: '30732', label: 'Nord Sahara 1959 / UTM zone 32N - EPSG:30732' },
  { value: '30791', label: 'Nord Sahara 1959 / Nord Algerie - EPSG:30791' },
  { value: '30792', label: 'Nord Sahara 1959 / Sud Algerie - EPSG:30792' },
  { value: '31028', label: 'Yoff / UTM zone 28N - EPSG:31028' },
  { value: '31121', label: 'Zanderij / UTM zone 21N - EPSG:31121' },
  { value: '31154', label: 'Zanderij / TM 54 NW - EPSG:31154' },
  { value: '31170', label: 'Zanderij / Suriname Old TM - EPSG:31170' },
  { value: '31171', label: 'Zanderij / Suriname TM - EPSG:31171' },
  { value: '31251', label: 'MGI (Ferro) / Austria GK West Zone - EPSG:31251' },
  {
    value: '31252',
    label: 'MGI (Ferro) / Austria GK Central Zone - EPSG:31252',
  },
  { value: '31253', label: 'MGI (Ferro) / Austria GK East Zone - EPSG:31253' },
  { value: '31254', label: 'MGI / Austria GK West - EPSG:31254' },
  { value: '31255', label: 'MGI / Austria GK Central - EPSG:31255' },
  { value: '31256', label: 'MGI / Austria GK East - EPSG:31256' },
  { value: '31257', label: 'MGI / Austria GK M28 - EPSG:31257' },
  { value: '31258', label: 'MGI / Austria GK M31 - EPSG:31258' },
  { value: '31259', label: 'MGI / Austria GK M34 - EPSG:31259' },
  { value: '31281', label: 'MGI (Ferro) / Austria West Zone - EPSG:31281' },
  { value: '31282', label: 'MGI (Ferro) / Austria Central Zone - EPSG:31282' },
  { value: '31283', label: 'MGI (Ferro) / Austria East Zone - EPSG:31283' },
  { value: '31284', label: 'MGI / Austria M28 - EPSG:31284' },
  { value: '31285', label: 'MGI / Austria M31 - EPSG:31285' },
  { value: '31286', label: 'MGI / Austria M34 - EPSG:31286' },
  { value: '31287', label: 'MGI / Austria Lambert - EPSG:31287' },
  { value: '31288', label: 'MGI (Ferro) / M28 - EPSG:31288' },
  { value: '31289', label: 'MGI (Ferro) / M31 - EPSG:31289' },
  { value: '31290', label: 'MGI (Ferro) / M34 - EPSG:31290' },
  { value: '31300', label: 'Belge 1972 / Belge Lambert 72 - EPSG:31300' },
  { value: '31370', label: 'Belge 1972 / Belgian Lambert 72 - EPSG:31370' },
  { value: '31466', label: 'DHDN / 3-degree Gauss-Kruger zone 2 - EPSG:31466' },
  { value: '31467', label: 'DHDN / 3-degree Gauss-Kruger zone 3 - EPSG:31467' },
  { value: '31468', label: 'DHDN / 3-degree Gauss-Kruger zone 4 - EPSG:31468' },
  { value: '31469', label: 'DHDN / 3-degree Gauss-Kruger zone 5 - EPSG:31469' },
  { value: '31528', label: 'Conakry 1905 / UTM zone 28N - EPSG:31528' },
  { value: '31529', label: 'Conakry 1905 / UTM zone 29N - EPSG:31529' },
  { value: '31600', label: 'Dealul Piscului 1930 / Stereo 33 - EPSG:31600' },
  { value: '31838', label: 'NGN / UTM zone 38N - EPSG:31838' },
  { value: '31839', label: 'NGN / UTM zone 39N - EPSG:31839' },
  { value: '31901', label: 'KUDAMS / KTM - EPSG:31901' },
  { value: '31965', label: 'SIRGAS 2000 / UTM zone 11N - EPSG:31965' },
  { value: '31966', label: 'SIRGAS 2000 / UTM zone 12N - EPSG:31966' },
  { value: '31967', label: 'SIRGAS 2000 / UTM zone 13N - EPSG:31967' },
  { value: '31968', label: 'SIRGAS 2000 / UTM zone 14N - EPSG:31968' },
  { value: '31969', label: 'SIRGAS 2000 / UTM zone 15N - EPSG:31969' },
  { value: '31970', label: 'SIRGAS 2000 / UTM zone 16N - EPSG:31970' },
  { value: '31971', label: 'SIRGAS 2000 / UTM zone 17N - EPSG:31971' },
  { value: '31972', label: 'SIRGAS 2000 / UTM zone 18N - EPSG:31972' },
  { value: '31973', label: 'SIRGAS 2000 / UTM zone 19N - EPSG:31973' },
  { value: '31974', label: 'SIRGAS 2000 / UTM zone 20N - EPSG:31974' },
  { value: '31975', label: 'SIRGAS 2000 / UTM zone 21N - EPSG:31975' },
  { value: '31976', label: 'SIRGAS 2000 / UTM zone 22N - EPSG:31976' },
  { value: '31977', label: 'SIRGAS 2000 / UTM zone 17S - EPSG:31977' },
  { value: '31978', label: 'SIRGAS 2000 / UTM zone 18S - EPSG:31978' },
  { value: '31979', label: 'SIRGAS 2000 / UTM zone 19S - EPSG:31979' },
  { value: '31980', label: 'SIRGAS 2000 / UTM zone 20S - EPSG:31980' },
  { value: '31981', label: 'SIRGAS 2000 / UTM zone 21S - EPSG:31981' },
  { value: '31982', label: 'SIRGAS 2000 / UTM zone 22S - EPSG:31982' },
  { value: '31983', label: 'SIRGAS 2000 / UTM zone 23S - EPSG:31983' },
  { value: '31984', label: 'SIRGAS 2000 / UTM zone 24S - EPSG:31984' },
  { value: '31985', label: 'SIRGAS 2000 / UTM zone 25S - EPSG:31985' },
  { value: '31986', label: 'SIRGAS 1995 / UTM zone 17N - EPSG:31986' },
  { value: '31987', label: 'SIRGAS 1995 / UTM zone 18N - EPSG:31987' },
  { value: '31988', label: 'SIRGAS 1995 / UTM zone 19N - EPSG:31988' },
  { value: '31989', label: 'SIRGAS 1995 / UTM zone 20N - EPSG:31989' },
  { value: '31990', label: 'SIRGAS 1995 / UTM zone 21N - EPSG:31990' },
  { value: '31991', label: 'SIRGAS 1995 / UTM zone 22N - EPSG:31991' },
  { value: '31992', label: 'SIRGAS 1995 / UTM zone 17S - EPSG:31992' },
  { value: '31993', label: 'SIRGAS 1995 / UTM zone 18S - EPSG:31993' },
  { value: '31994', label: 'SIRGAS 1995 / UTM zone 19S - EPSG:31994' },
  { value: '31995', label: 'SIRGAS 1995 / UTM zone 20S - EPSG:31995' },
  { value: '31996', label: 'SIRGAS 1995 / UTM zone 21S - EPSG:31996' },
  { value: '31997', label: 'SIRGAS 1995 / UTM zone 22S - EPSG:31997' },
  { value: '31998', label: 'SIRGAS 1995 / UTM zone 23S - EPSG:31998' },
  { value: '31999', label: 'SIRGAS 1995 / UTM zone 24S - EPSG:31999' },
  { value: '32000', label: 'SIRGAS 1995 / UTM zone 25S - EPSG:32000' },
  { value: '32001', label: 'NAD27 / Montana North - EPSG:32001' },
  { value: '32002', label: 'NAD27 / Montana Central - EPSG:32002' },
  { value: '32003', label: 'NAD27 / Montana South - EPSG:32003' },
  { value: '32005', label: 'NAD27 / Nebraska North - EPSG:32005' },
  { value: '32006', label: 'NAD27 / Nebraska South - EPSG:32006' },
  { value: '32007', label: 'NAD27 / Nevada East - EPSG:32007' },
  { value: '32008', label: 'NAD27 / Nevada Central - EPSG:32008' },
  { value: '32009', label: 'NAD27 / Nevada West - EPSG:32009' },
  { value: '32010', label: 'NAD27 / New Hampshire - EPSG:32010' },
  { value: '32011', label: 'NAD27 / New Jersey - EPSG:32011' },
  { value: '32012', label: 'NAD27 / New Mexico East - EPSG:32012' },
  { value: '32013', label: 'NAD27 / New Mexico Central - EPSG:32013' },
  { value: '32014', label: 'NAD27 / New Mexico West - EPSG:32014' },
  { value: '32015', label: 'NAD27 / New York East - EPSG:32015' },
  { value: '32016', label: 'NAD27 / New York Central - EPSG:32016' },
  { value: '32017', label: 'NAD27 / New York West - EPSG:32017' },
  { value: '32019', label: 'NAD27 / North Carolina - EPSG:32019' },
  { value: '32020', label: 'NAD27 / North Dakota North - EPSG:32020' },
  { value: '32021', label: 'NAD27 / North Dakota South - EPSG:32021' },
  { value: '32022', label: 'NAD27 / Ohio North - EPSG:32022' },
  { value: '32023', label: 'NAD27 / Ohio South - EPSG:32023' },
  { value: '32024', label: 'NAD27 / Oklahoma North - EPSG:32024' },
  { value: '32025', label: 'NAD27 / Oklahoma South - EPSG:32025' },
  { value: '32026', label: 'NAD27 / Oregon North - EPSG:32026' },
  { value: '32027', label: 'NAD27 / Oregon South - EPSG:32027' },
  { value: '32028', label: 'NAD27 / Pennsylvania North - EPSG:32028' },
  { value: '32030', label: 'NAD27 / Rhode Island - EPSG:32030' },
  { value: '32031', label: 'NAD27 / South Carolina North - EPSG:32031' },
  { value: '32033', label: 'NAD27 / South Carolina South - EPSG:32033' },
  { value: '32034', label: 'NAD27 / South Dakota North - EPSG:32034' },
  { value: '32035', label: 'NAD27 / South Dakota South - EPSG:32035' },
  { value: '32037', label: 'NAD27 / Texas North - EPSG:32037' },
  { value: '32038', label: 'NAD27 / Texas North Central - EPSG:32038' },
  { value: '32039', label: 'NAD27 / Texas Central - EPSG:32039' },
  { value: '32040', label: 'NAD27 / Texas South Central - EPSG:32040' },
  { value: '32041', label: 'NAD27 / Texas South - EPSG:32041' },
  { value: '32042', label: 'NAD27 / Utah North - EPSG:32042' },
  { value: '32043', label: 'NAD27 / Utah Central - EPSG:32043' },
  { value: '32044', label: 'NAD27 / Utah South - EPSG:32044' },
  { value: '32045', label: 'NAD27 / Vermont - EPSG:32045' },
  { value: '32046', label: 'NAD27 / Virginia North - EPSG:32046' },
  { value: '32047', label: 'NAD27 / Virginia South - EPSG:32047' },
  { value: '32048', label: 'NAD27 / Washington North - EPSG:32048' },
  { value: '32049', label: 'NAD27 / Washington South - EPSG:32049' },
  { value: '32050', label: 'NAD27 / West Virginia North - EPSG:32050' },
  { value: '32051', label: 'NAD27 / West Virginia South - EPSG:32051' },
  { value: '32052', label: 'NAD27 / Wisconsin North - EPSG:32052' },
  { value: '32053', label: 'NAD27 / Wisconsin Central - EPSG:32053' },
  { value: '32054', label: 'NAD27 / Wisconsin South - EPSG:32054' },
  { value: '32055', label: 'NAD27 / Wyoming East - EPSG:32055' },
  { value: '32056', label: 'NAD27 / Wyoming East Central - EPSG:32056' },
  { value: '32057', label: 'NAD27 / Wyoming West Central - EPSG:32057' },
  { value: '32058', label: 'NAD27 / Wyoming West - EPSG:32058' },
  { value: '32064', label: 'NAD27 / BLM 14N (ftUS) - EPSG:32064' },
  { value: '32065', label: 'NAD27 / BLM 15N (ftUS) - EPSG:32065' },
  { value: '32066', label: 'NAD27 / BLM 16N (ftUS) - EPSG:32066' },
  { value: '32067', label: 'NAD27 / BLM 17N (ftUS) - EPSG:32067' },
  { value: '32081', label: 'NAD27 / MTM zone 1 - EPSG:32081' },
  { value: '32082', label: 'NAD27 / MTM zone 2 - EPSG:32082' },
  { value: '32083', label: 'NAD27 / MTM zone 3 - EPSG:32083' },
  { value: '32084', label: 'NAD27 / MTM zone 4 - EPSG:32084' },
  { value: '32085', label: 'NAD27 / MTM zone 5 - EPSG:32085' },
  { value: '32086', label: 'NAD27 / MTM zone 6 - EPSG:32086' },
  { value: '32098', label: 'NAD27 / Quebec Lambert - EPSG:32098' },
  { value: '32099', label: 'NAD27 / Louisiana Offshore - EPSG:32099' },
  { value: '32100', label: 'NAD83 / Montana - EPSG:32100' },
  { value: '32104', label: 'NAD83 / Nebraska - EPSG:32104' },
  { value: '32107', label: 'NAD83 / Nevada East - EPSG:32107' },
  { value: '32108', label: 'NAD83 / Nevada Central - EPSG:32108' },
  { value: '32109', label: 'NAD83 / Nevada West - EPSG:32109' },
  { value: '32110', label: 'NAD83 / New Hampshire - EPSG:32110' },
  { value: '32111', label: 'NAD83 / New Jersey - EPSG:32111' },
  { value: '32112', label: 'NAD83 / New Mexico East - EPSG:32112' },
  { value: '32113', label: 'NAD83 / New Mexico Central - EPSG:32113' },
  { value: '32114', label: 'NAD83 / New Mexico West - EPSG:32114' },
  { value: '32115', label: 'NAD83 / New York East - EPSG:32115' },
  { value: '32116', label: 'NAD83 / New York Central - EPSG:32116' },
  { value: '32117', label: 'NAD83 / New York West - EPSG:32117' },
  { value: '32118', label: 'NAD83 / New York Long Island - EPSG:32118' },
  { value: '32119', label: 'NAD83 / North Carolina - EPSG:32119' },
  { value: '32120', label: 'NAD83 / North Dakota North - EPSG:32120' },
  { value: '32121', label: 'NAD83 / North Dakota South - EPSG:32121' },
  { value: '32122', label: 'NAD83 / Ohio North - EPSG:32122' },
  { value: '32123', label: 'NAD83 / Ohio South - EPSG:32123' },
  { value: '32124', label: 'NAD83 / Oklahoma North - EPSG:32124' },
  { value: '32125', label: 'NAD83 / Oklahoma South - EPSG:32125' },
  { value: '32126', label: 'NAD83 / Oregon North - EPSG:32126' },
  { value: '32127', label: 'NAD83 / Oregon South - EPSG:32127' },
  { value: '32128', label: 'NAD83 / Pennsylvania North - EPSG:32128' },
  { value: '32129', label: 'NAD83 / Pennsylvania South - EPSG:32129' },
  { value: '32130', label: 'NAD83 / Rhode Island - EPSG:32130' },
  { value: '32133', label: 'NAD83 / South Carolina - EPSG:32133' },
  { value: '32134', label: 'NAD83 / South Dakota North - EPSG:32134' },
  { value: '32135', label: 'NAD83 / South Dakota South - EPSG:32135' },
  { value: '32136', label: 'NAD83 / Tennessee - EPSG:32136' },
  { value: '32137', label: 'NAD83 / Texas North - EPSG:32137' },
  { value: '32138', label: 'NAD83 / Texas North Central - EPSG:32138' },
  { value: '32139', label: 'NAD83 / Texas Central - EPSG:32139' },
  { value: '32140', label: 'NAD83 / Texas South Central - EPSG:32140' },
  { value: '32141', label: 'NAD83 / Texas South - EPSG:32141' },
  { value: '32142', label: 'NAD83 / Utah North - EPSG:32142' },
  { value: '32143', label: 'NAD83 / Utah Central - EPSG:32143' },
  { value: '32144', label: 'NAD83 / Utah South - EPSG:32144' },
  { value: '32145', label: 'NAD83 / Vermont - EPSG:32145' },
  { value: '32146', label: 'NAD83 / Virginia North - EPSG:32146' },
  { value: '32147', label: 'NAD83 / Virginia South - EPSG:32147' },
  { value: '32148', label: 'NAD83 / Washington North - EPSG:32148' },
  { value: '32149', label: 'NAD83 / Washington South - EPSG:32149' },
  { value: '32150', label: 'NAD83 / West Virginia North - EPSG:32150' },
  { value: '32151', label: 'NAD83 / West Virginia South - EPSG:32151' },
  { value: '32152', label: 'NAD83 / Wisconsin North - EPSG:32152' },
  { value: '32153', label: 'NAD83 / Wisconsin Central - EPSG:32153' },
  { value: '32154', label: 'NAD83 / Wisconsin South - EPSG:32154' },
  { value: '32155', label: 'NAD83 / Wyoming East - EPSG:32155' },
  { value: '32156', label: 'NAD83 / Wyoming East Central - EPSG:32156' },
  { value: '32157', label: 'NAD83 / Wyoming West Central - EPSG:32157' },
  { value: '32158', label: 'NAD83 / Wyoming West - EPSG:32158' },
  { value: '32161', label: 'NAD83 / Puerto Rico & Virgin Is. - EPSG:32161' },
  { value: '32164', label: 'NAD83 / BLM 14N (ftUS) - EPSG:32164' },
  { value: '32165', label: 'NAD83 / BLM 15N (ftUS) - EPSG:32165' },
  { value: '32166', label: 'NAD83 / BLM 16N (ftUS) - EPSG:32166' },
  { value: '32167', label: 'NAD83 / BLM 17N (ftUS) - EPSG:32167' },
  { value: '32181', label: 'NAD83 / MTM zone 1 - EPSG:32181' },
  { value: '32182', label: 'NAD83 / MTM zone 2 - EPSG:32182' },
  { value: '32183', label: 'NAD83 / MTM zone 3 - EPSG:32183' },
  { value: '32184', label: 'NAD83 / MTM zone 4 - EPSG:32184' },
  { value: '32185', label: 'NAD83 / MTM zone 5 - EPSG:32185' },
  { value: '32186', label: 'NAD83 / MTM zone 6 - EPSG:32186' },
  { value: '32187', label: 'NAD83 / MTM zone 7 - EPSG:32187' },
  { value: '32188', label: 'NAD83 / MTM zone 8 - EPSG:32188' },
  { value: '32189', label: 'NAD83 / MTM zone 9 - EPSG:32189' },
  { value: '32190', label: 'NAD83 / MTM zone 10 - EPSG:32190' },
  { value: '32191', label: 'NAD83 / MTM zone 11 - EPSG:32191' },
  { value: '32192', label: 'NAD83 / MTM zone 12 - EPSG:32192' },
  { value: '32193', label: 'NAD83 / MTM zone 13 - EPSG:32193' },
  { value: '32194', label: 'NAD83 / MTM zone 14 - EPSG:32194' },
  { value: '32195', label: 'NAD83 / MTM zone 15 - EPSG:32195' },
  { value: '32196', label: 'NAD83 / MTM zone 16 - EPSG:32196' },
  { value: '32197', label: 'NAD83 / MTM zone 17 - EPSG:32197' },
  { value: '32198', label: 'NAD83 / Quebec Lambert - EPSG:32198' },
  { value: '32199', label: 'NAD83 / Louisiana Offshore - EPSG:32199' },
  { value: '32201', label: 'WGS 72 / UTM zone 1N - EPSG:32201' },
  { value: '32202', label: 'WGS 72 / UTM zone 2N - EPSG:32202' },
  { value: '32203', label: 'WGS 72 / UTM zone 3N - EPSG:32203' },
  { value: '32204', label: 'WGS 72 / UTM zone 4N - EPSG:32204' },
  { value: '32205', label: 'WGS 72 / UTM zone 5N - EPSG:32205' },
  { value: '32206', label: 'WGS 72 / UTM zone 6N - EPSG:32206' },
  { value: '32207', label: 'WGS 72 / UTM zone 7N - EPSG:32207' },
  { value: '32208', label: 'WGS 72 / UTM zone 8N - EPSG:32208' },
  { value: '32209', label: 'WGS 72 / UTM zone 9N - EPSG:32209' },
  { value: '32210', label: 'WGS 72 / UTM zone 10N - EPSG:32210' },
  { value: '32211', label: 'WGS 72 / UTM zone 11N - EPSG:32211' },
  { value: '32212', label: 'WGS 72 / UTM zone 12N - EPSG:32212' },
  { value: '32213', label: 'WGS 72 / UTM zone 13N - EPSG:32213' },
  { value: '32214', label: 'WGS 72 / UTM zone 14N - EPSG:32214' },
  { value: '32215', label: 'WGS 72 / UTM zone 15N - EPSG:32215' },
  { value: '32216', label: 'WGS 72 / UTM zone 16N - EPSG:32216' },
  { value: '32217', label: 'WGS 72 / UTM zone 17N - EPSG:32217' },
  { value: '32218', label: 'WGS 72 / UTM zone 18N - EPSG:32218' },
  { value: '32219', label: 'WGS 72 / UTM zone 19N - EPSG:32219' },
  { value: '32220', label: 'WGS 72 / UTM zone 20N - EPSG:32220' },
  { value: '32221', label: 'WGS 72 / UTM zone 21N - EPSG:32221' },
  { value: '32222', label: 'WGS 72 / UTM zone 22N - EPSG:32222' },
  { value: '32223', label: 'WGS 72 / UTM zone 23N - EPSG:32223' },
  { value: '32224', label: 'WGS 72 / UTM zone 24N - EPSG:32224' },
  { value: '32225', label: 'WGS 72 / UTM zone 25N - EPSG:32225' },
  { value: '32226', label: 'WGS 72 / UTM zone 26N - EPSG:32226' },
  { value: '32227', label: 'WGS 72 / UTM zone 27N - EPSG:32227' },
  { value: '32228', label: 'WGS 72 / UTM zone 28N - EPSG:32228' },
  { value: '32229', label: 'WGS 72 / UTM zone 29N - EPSG:32229' },
  { value: '32230', label: 'WGS 72 / UTM zone 30N - EPSG:32230' },
  { value: '32231', label: 'WGS 72 / UTM zone 31N - EPSG:32231' },
  { value: '32232', label: 'WGS 72 / UTM zone 32N - EPSG:32232' },
  { value: '32233', label: 'WGS 72 / UTM zone 33N - EPSG:32233' },
  { value: '32234', label: 'WGS 72 / UTM zone 34N - EPSG:32234' },
  { value: '32235', label: 'WGS 72 / UTM zone 35N - EPSG:32235' },
  { value: '32236', label: 'WGS 72 / UTM zone 36N - EPSG:32236' },
  { value: '32237', label: 'WGS 72 / UTM zone 37N - EPSG:32237' },
  { value: '32238', label: 'WGS 72 / UTM zone 38N - EPSG:32238' },
  { value: '32239', label: 'WGS 72 / UTM zone 39N - EPSG:32239' },
  { value: '32240', label: 'WGS 72 / UTM zone 40N - EPSG:32240' },
  { value: '32241', label: 'WGS 72 / UTM zone 41N - EPSG:32241' },
  { value: '32242', label: 'WGS 72 / UTM zone 42N - EPSG:32242' },
  { value: '32243', label: 'WGS 72 / UTM zone 43N - EPSG:32243' },
  { value: '32244', label: 'WGS 72 / UTM zone 44N - EPSG:32244' },
  { value: '32245', label: 'WGS 72 / UTM zone 45N - EPSG:32245' },
  { value: '32246', label: 'WGS 72 / UTM zone 46N - EPSG:32246' },
  { value: '32247', label: 'WGS 72 / UTM zone 47N - EPSG:32247' },
  { value: '32248', label: 'WGS 72 / UTM zone 48N - EPSG:32248' },
  { value: '32249', label: 'WGS 72 / UTM zone 49N - EPSG:32249' },
  { value: '32250', label: 'WGS 72 / UTM zone 50N - EPSG:32250' },
  { value: '32251', label: 'WGS 72 / UTM zone 51N - EPSG:32251' },
  { value: '32252', label: 'WGS 72 / UTM zone 52N - EPSG:32252' },
  { value: '32253', label: 'WGS 72 / UTM zone 53N - EPSG:32253' },
  { value: '32254', label: 'WGS 72 / UTM zone 54N - EPSG:32254' },
  { value: '32255', label: 'WGS 72 / UTM zone 55N - EPSG:32255' },
  { value: '32256', label: 'WGS 72 / UTM zone 56N - EPSG:32256' },
  { value: '32257', label: 'WGS 72 / UTM zone 57N - EPSG:32257' },
  { value: '32258', label: 'WGS 72 / UTM zone 58N - EPSG:32258' },
  { value: '32259', label: 'WGS 72 / UTM zone 59N - EPSG:32259' },
  { value: '32260', label: 'WGS 72 / UTM zone 60N - EPSG:32260' },
  { value: '32301', label: 'WGS 72 / UTM zone 1S - EPSG:32301' },
  { value: '32302', label: 'WGS 72 / UTM zone 2S - EPSG:32302' },
  { value: '32303', label: 'WGS 72 / UTM zone 3S - EPSG:32303' },
  { value: '32304', label: 'WGS 72 / UTM zone 4S - EPSG:32304' },
  { value: '32305', label: 'WGS 72 / UTM zone 5S - EPSG:32305' },
  { value: '32306', label: 'WGS 72 / UTM zone 6S - EPSG:32306' },
  { value: '32307', label: 'WGS 72 / UTM zone 7S - EPSG:32307' },
  { value: '32308', label: 'WGS 72 / UTM zone 8S - EPSG:32308' },
  { value: '32309', label: 'WGS 72 / UTM zone 9S - EPSG:32309' },
  { value: '32310', label: 'WGS 72 / UTM zone 10S - EPSG:32310' },
  { value: '32311', label: 'WGS 72 / UTM zone 11S - EPSG:32311' },
  { value: '32312', label: 'WGS 72 / UTM zone 12S - EPSG:32312' },
  { value: '32313', label: 'WGS 72 / UTM zone 13S - EPSG:32313' },
  { value: '32314', label: 'WGS 72 / UTM zone 14S - EPSG:32314' },
  { value: '32315', label: 'WGS 72 / UTM zone 15S - EPSG:32315' },
  { value: '32316', label: 'WGS 72 / UTM zone 16S - EPSG:32316' },
  { value: '32317', label: 'WGS 72 / UTM zone 17S - EPSG:32317' },
  { value: '32318', label: 'WGS 72 / UTM zone 18S - EPSG:32318' },
  { value: '32319', label: 'WGS 72 / UTM zone 19S - EPSG:32319' },
  { value: '32320', label: 'WGS 72 / UTM zone 20S - EPSG:32320' },
  { value: '32321', label: 'WGS 72 / UTM zone 21S - EPSG:32321' },
  { value: '32322', label: 'WGS 72 / UTM zone 22S - EPSG:32322' },
  { value: '32323', label: 'WGS 72 / UTM zone 23S - EPSG:32323' },
  { value: '32324', label: 'WGS 72 / UTM zone 24S - EPSG:32324' },
  { value: '32325', label: 'WGS 72 / UTM zone 25S - EPSG:32325' },
  { value: '32326', label: 'WGS 72 / UTM zone 26S - EPSG:32326' },
  { value: '32327', label: 'WGS 72 / UTM zone 27S - EPSG:32327' },
  { value: '32328', label: 'WGS 72 / UTM zone 28S - EPSG:32328' },
  { value: '32329', label: 'WGS 72 / UTM zone 29S - EPSG:32329' },
  { value: '32330', label: 'WGS 72 / UTM zone 30S - EPSG:32330' },
  { value: '32331', label: 'WGS 72 / UTM zone 31S - EPSG:32331' },
  { value: '32332', label: 'WGS 72 / UTM zone 32S - EPSG:32332' },
  { value: '32333', label: 'WGS 72 / UTM zone 33S - EPSG:32333' },
  { value: '32334', label: 'WGS 72 / UTM zone 34S - EPSG:32334' },
  { value: '32335', label: 'WGS 72 / UTM zone 35S - EPSG:32335' },
  { value: '32336', label: 'WGS 72 / UTM zone 36S - EPSG:32336' },
  { value: '32337', label: 'WGS 72 / UTM zone 37S - EPSG:32337' },
  { value: '32338', label: 'WGS 72 / UTM zone 38S - EPSG:32338' },
  { value: '32339', label: 'WGS 72 / UTM zone 39S - EPSG:32339' },
  { value: '32340', label: 'WGS 72 / UTM zone 40S - EPSG:32340' },
  { value: '32341', label: 'WGS 72 / UTM zone 41S - EPSG:32341' },
  { value: '32342', label: 'WGS 72 / UTM zone 42S - EPSG:32342' },
  { value: '32343', label: 'WGS 72 / UTM zone 43S - EPSG:32343' },
  { value: '32344', label: 'WGS 72 / UTM zone 44S - EPSG:32344' },
  { value: '32345', label: 'WGS 72 / UTM zone 45S - EPSG:32345' },
  { value: '32346', label: 'WGS 72 / UTM zone 46S - EPSG:32346' },
  { value: '32347', label: 'WGS 72 / UTM zone 47S - EPSG:32347' },
  { value: '32348', label: 'WGS 72 / UTM zone 48S - EPSG:32348' },
  { value: '32349', label: 'WGS 72 / UTM zone 49S - EPSG:32349' },
  { value: '32350', label: 'WGS 72 / UTM zone 50S - EPSG:32350' },
  { value: '32351', label: 'WGS 72 / UTM zone 51S - EPSG:32351' },
  { value: '32352', label: 'WGS 72 / UTM zone 52S - EPSG:32352' },
  { value: '32353', label: 'WGS 72 / UTM zone 53S - EPSG:32353' },
  { value: '32354', label: 'WGS 72 / UTM zone 54S - EPSG:32354' },
  { value: '32355', label: 'WGS 72 / UTM zone 55S - EPSG:32355' },
  { value: '32356', label: 'WGS 72 / UTM zone 56S - EPSG:32356' },
  { value: '32357', label: 'WGS 72 / UTM zone 57S - EPSG:32357' },
  { value: '32358', label: 'WGS 72 / UTM zone 58S - EPSG:32358' },
  { value: '32359', label: 'WGS 72 / UTM zone 59S - EPSG:32359' },
  { value: '32360', label: 'WGS 72 / UTM zone 60S - EPSG:32360' },
  { value: '32401', label: 'WGS 72BE / UTM zone 1N - EPSG:32401' },
  { value: '32402', label: 'WGS 72BE / UTM zone 2N - EPSG:32402' },
  { value: '32403', label: 'WGS 72BE / UTM zone 3N - EPSG:32403' },
  { value: '32404', label: 'WGS 72BE / UTM zone 4N - EPSG:32404' },
  { value: '32405', label: 'WGS 72BE / UTM zone 5N - EPSG:32405' },
  { value: '32406', label: 'WGS 72BE / UTM zone 6N - EPSG:32406' },
  { value: '32407', label: 'WGS 72BE / UTM zone 7N - EPSG:32407' },
  { value: '32408', label: 'WGS 72BE / UTM zone 8N - EPSG:32408' },
  { value: '32409', label: 'WGS 72BE / UTM zone 9N - EPSG:32409' },
  { value: '32410', label: 'WGS 72BE / UTM zone 10N - EPSG:32410' },
  { value: '32411', label: 'WGS 72BE / UTM zone 11N - EPSG:32411' },
  { value: '32412', label: 'WGS 72BE / UTM zone 12N - EPSG:32412' },
  { value: '32413', label: 'WGS 72BE / UTM zone 13N - EPSG:32413' },
  { value: '32414', label: 'WGS 72BE / UTM zone 14N - EPSG:32414' },
  { value: '32415', label: 'WGS 72BE / UTM zone 15N - EPSG:32415' },
  { value: '32416', label: 'WGS 72BE / UTM zone 16N - EPSG:32416' },
  { value: '32417', label: 'WGS 72BE / UTM zone 17N - EPSG:32417' },
  { value: '32418', label: 'WGS 72BE / UTM zone 18N - EPSG:32418' },
  { value: '32419', label: 'WGS 72BE / UTM zone 19N - EPSG:32419' },
  { value: '32420', label: 'WGS 72BE / UTM zone 20N - EPSG:32420' },
  { value: '32421', label: 'WGS 72BE / UTM zone 21N - EPSG:32421' },
  { value: '32422', label: 'WGS 72BE / UTM zone 22N - EPSG:32422' },
  { value: '32423', label: 'WGS 72BE / UTM zone 23N - EPSG:32423' },
  { value: '32424', label: 'WGS 72BE / UTM zone 24N - EPSG:32424' },
  { value: '32425', label: 'WGS 72BE / UTM zone 25N - EPSG:32425' },
  { value: '32426', label: 'WGS 72BE / UTM zone 26N - EPSG:32426' },
  { value: '32427', label: 'WGS 72BE / UTM zone 27N - EPSG:32427' },
  { value: '32428', label: 'WGS 72BE / UTM zone 28N - EPSG:32428' },
  { value: '32429', label: 'WGS 72BE / UTM zone 29N - EPSG:32429' },
  { value: '32430', label: 'WGS 72BE / UTM zone 30N - EPSG:32430' },
  { value: '32431', label: 'WGS 72BE / UTM zone 31N - EPSG:32431' },
  { value: '32432', label: 'WGS 72BE / UTM zone 32N - EPSG:32432' },
  { value: '32433', label: 'WGS 72BE / UTM zone 33N - EPSG:32433' },
  { value: '32434', label: 'WGS 72BE / UTM zone 34N - EPSG:32434' },
  { value: '32435', label: 'WGS 72BE / UTM zone 35N - EPSG:32435' },
  { value: '32436', label: 'WGS 72BE / UTM zone 36N - EPSG:32436' },
  { value: '32437', label: 'WGS 72BE / UTM zone 37N - EPSG:32437' },
  { value: '32438', label: 'WGS 72BE / UTM zone 38N - EPSG:32438' },
  { value: '32439', label: 'WGS 72BE / UTM zone 39N - EPSG:32439' },
  { value: '32440', label: 'WGS 72BE / UTM zone 40N - EPSG:32440' },
  { value: '32441', label: 'WGS 72BE / UTM zone 41N - EPSG:32441' },
  { value: '32442', label: 'WGS 72BE / UTM zone 42N - EPSG:32442' },
  { value: '32443', label: 'WGS 72BE / UTM zone 43N - EPSG:32443' },
  { value: '32444', label: 'WGS 72BE / UTM zone 44N - EPSG:32444' },
  { value: '32445', label: 'WGS 72BE / UTM zone 45N - EPSG:32445' },
  { value: '32446', label: 'WGS 72BE / UTM zone 46N - EPSG:32446' },
  { value: '32447', label: 'WGS 72BE / UTM zone 47N - EPSG:32447' },
  { value: '32448', label: 'WGS 72BE / UTM zone 48N - EPSG:32448' },
  { value: '32449', label: 'WGS 72BE / UTM zone 49N - EPSG:32449' },
  { value: '32450', label: 'WGS 72BE / UTM zone 50N - EPSG:32450' },
  { value: '32451', label: 'WGS 72BE / UTM zone 51N - EPSG:32451' },
  { value: '32452', label: 'WGS 72BE / UTM zone 52N - EPSG:32452' },
  { value: '32453', label: 'WGS 72BE / UTM zone 53N - EPSG:32453' },
  { value: '32454', label: 'WGS 72BE / UTM zone 54N - EPSG:32454' },
  { value: '32455', label: 'WGS 72BE / UTM zone 55N - EPSG:32455' },
  { value: '32456', label: 'WGS 72BE / UTM zone 56N - EPSG:32456' },
  { value: '32457', label: 'WGS 72BE / UTM zone 57N - EPSG:32457' },
  { value: '32458', label: 'WGS 72BE / UTM zone 58N - EPSG:32458' },
  { value: '32459', label: 'WGS 72BE / UTM zone 59N - EPSG:32459' },
  { value: '32460', label: 'WGS 72BE / UTM zone 60N - EPSG:32460' },
  { value: '32501', label: 'WGS 72BE / UTM zone 1S - EPSG:32501' },
  { value: '32502', label: 'WGS 72BE / UTM zone 2S - EPSG:32502' },
  { value: '32503', label: 'WGS 72BE / UTM zone 3S - EPSG:32503' },
  { value: '32504', label: 'WGS 72BE / UTM zone 4S - EPSG:32504' },
  { value: '32505', label: 'WGS 72BE / UTM zone 5S - EPSG:32505' },
  { value: '32506', label: 'WGS 72BE / UTM zone 6S - EPSG:32506' },
  { value: '32507', label: 'WGS 72BE / UTM zone 7S - EPSG:32507' },
  { value: '32508', label: 'WGS 72BE / UTM zone 8S - EPSG:32508' },
  { value: '32509', label: 'WGS 72BE / UTM zone 9S - EPSG:32509' },
  { value: '32510', label: 'WGS 72BE / UTM zone 10S - EPSG:32510' },
  { value: '32511', label: 'WGS 72BE / UTM zone 11S - EPSG:32511' },
  { value: '32512', label: 'WGS 72BE / UTM zone 12S - EPSG:32512' },
  { value: '32513', label: 'WGS 72BE / UTM zone 13S - EPSG:32513' },
  { value: '32514', label: 'WGS 72BE / UTM zone 14S - EPSG:32514' },
  { value: '32515', label: 'WGS 72BE / UTM zone 15S - EPSG:32515' },
  { value: '32516', label: 'WGS 72BE / UTM zone 16S - EPSG:32516' },
  { value: '32517', label: 'WGS 72BE / UTM zone 17S - EPSG:32517' },
  { value: '32518', label: 'WGS 72BE / UTM zone 18S - EPSG:32518' },
  { value: '32519', label: 'WGS 72BE / UTM zone 19S - EPSG:32519' },
  { value: '32520', label: 'WGS 72BE / UTM zone 20S - EPSG:32520' },
  { value: '32521', label: 'WGS 72BE / UTM zone 21S - EPSG:32521' },
  { value: '32522', label: 'WGS 72BE / UTM zone 22S - EPSG:32522' },
  { value: '32523', label: 'WGS 72BE / UTM zone 23S - EPSG:32523' },
  { value: '32524', label: 'WGS 72BE / UTM zone 24S - EPSG:32524' },
  { value: '32525', label: 'WGS 72BE / UTM zone 25S - EPSG:32525' },
  { value: '32526', label: 'WGS 72BE / UTM zone 26S - EPSG:32526' },
  { value: '32527', label: 'WGS 72BE / UTM zone 27S - EPSG:32527' },
  { value: '32528', label: 'WGS 72BE / UTM zone 28S - EPSG:32528' },
  { value: '32529', label: 'WGS 72BE / UTM zone 29S - EPSG:32529' },
  { value: '32530', label: 'WGS 72BE / UTM zone 30S - EPSG:32530' },
  { value: '32531', label: 'WGS 72BE / UTM zone 31S - EPSG:32531' },
  { value: '32532', label: 'WGS 72BE / UTM zone 32S - EPSG:32532' },
  { value: '32533', label: 'WGS 72BE / UTM zone 33S - EPSG:32533' },
  { value: '32534', label: 'WGS 72BE / UTM zone 34S - EPSG:32534' },
  { value: '32535', label: 'WGS 72BE / UTM zone 35S - EPSG:32535' },
  { value: '32536', label: 'WGS 72BE / UTM zone 36S - EPSG:32536' },
  { value: '32537', label: 'WGS 72BE / UTM zone 37S - EPSG:32537' },
  { value: '32538', label: 'WGS 72BE / UTM zone 38S - EPSG:32538' },
  { value: '32539', label: 'WGS 72BE / UTM zone 39S - EPSG:32539' },
  { value: '32540', label: 'WGS 72BE / UTM zone 40S - EPSG:32540' },
  { value: '32541', label: 'WGS 72BE / UTM zone 41S - EPSG:32541' },
  { value: '32542', label: 'WGS 72BE / UTM zone 42S - EPSG:32542' },
  { value: '32543', label: 'WGS 72BE / UTM zone 43S - EPSG:32543' },
  { value: '32544', label: 'WGS 72BE / UTM zone 44S - EPSG:32544' },
  { value: '32545', label: 'WGS 72BE / UTM zone 45S - EPSG:32545' },
  { value: '32546', label: 'WGS 72BE / UTM zone 46S - EPSG:32546' },
  { value: '32547', label: 'WGS 72BE / UTM zone 47S - EPSG:32547' },
  { value: '32548', label: 'WGS 72BE / UTM zone 48S - EPSG:32548' },
  { value: '32549', label: 'WGS 72BE / UTM zone 49S - EPSG:32549' },
  { value: '32550', label: 'WGS 72BE / UTM zone 50S - EPSG:32550' },
  { value: '32551', label: 'WGS 72BE / UTM zone 51S - EPSG:32551' },
  { value: '32552', label: 'WGS 72BE / UTM zone 52S - EPSG:32552' },
  { value: '32553', label: 'WGS 72BE / UTM zone 53S - EPSG:32553' },
  { value: '32554', label: 'WGS 72BE / UTM zone 54S - EPSG:32554' },
  { value: '32555', label: 'WGS 72BE / UTM zone 55S - EPSG:32555' },
  { value: '32556', label: 'WGS 72BE / UTM zone 56S - EPSG:32556' },
  { value: '32557', label: 'WGS 72BE / UTM zone 57S - EPSG:32557' },
  { value: '32558', label: 'WGS 72BE / UTM zone 58S - EPSG:32558' },
  { value: '32559', label: 'WGS 72BE / UTM zone 59S - EPSG:32559' },
  { value: '32560', label: 'WGS 72BE / UTM zone 60S - EPSG:32560' },
  {
    value: '32600',
    label: 'WGS 84 / UTM grid system (northern hemisphere) - EPSG:32600',
  },
  { value: '32601', label: 'WGS 84 / UTM zone 1N - EPSG:32601' },
  { value: '32602', label: 'WGS 84 / UTM zone 2N - EPSG:32602' },
  { value: '32603', label: 'WGS 84 / UTM zone 3N - EPSG:32603' },
  { value: '32604', label: 'WGS 84 / UTM zone 4N - EPSG:32604' },
  { value: '32605', label: 'WGS 84 / UTM zone 5N - EPSG:32605' },
  { value: '32606', label: 'WGS 84 / UTM zone 6N - EPSG:32606' },
  { value: '32607', label: 'WGS 84 / UTM zone 7N - EPSG:32607' },
  { value: '32608', label: 'WGS 84 / UTM zone 8N - EPSG:32608' },
  { value: '32609', label: 'WGS 84 / UTM zone 9N - EPSG:32609' },
  { value: '32610', label: 'WGS 84 / UTM zone 10N - EPSG:32610' },
  { value: '32611', label: 'WGS 84 / UTM zone 11N - EPSG:32611' },
  { value: '32612', label: 'WGS 84 / UTM zone 12N - EPSG:32612' },
  { value: '32613', label: 'WGS 84 / UTM zone 13N - EPSG:32613' },
  { value: '32614', label: 'WGS 84 / UTM zone 14N - EPSG:32614' },
  { value: '32615', label: 'WGS 84 / UTM zone 15N - EPSG:32615' },
  { value: '32616', label: 'WGS 84 / UTM zone 16N - EPSG:32616' },
  { value: '32617', label: 'WGS 84 / UTM zone 17N - EPSG:32617' },
  { value: '32618', label: 'WGS 84 / UTM zone 18N - EPSG:32618' },
  { value: '32619', label: 'WGS 84 / UTM zone 19N - EPSG:32619' },
  { value: '32620', label: 'WGS 84 / UTM zone 20N - EPSG:32620' },
  { value: '32621', label: 'WGS 84 / UTM zone 21N - EPSG:32621' },
  { value: '32622', label: 'WGS 84 / UTM zone 22N - EPSG:32622' },
  { value: '32623', label: 'WGS 84 / UTM zone 23N - EPSG:32623' },
  { value: '32624', label: 'WGS 84 / UTM zone 24N - EPSG:32624' },
  { value: '32625', label: 'WGS 84 / UTM zone 25N - EPSG:32625' },
  { value: '32626', label: 'WGS 84 / UTM zone 26N - EPSG:32626' },
  { value: '32627', label: 'WGS 84 / UTM zone 27N - EPSG:32627' },
  { value: '32628', label: 'WGS 84 / UTM zone 28N - EPSG:32628' },
  { value: '32629', label: 'WGS 84 / UTM zone 29N - EPSG:32629' },
  { value: '32630', label: 'WGS 84 / UTM zone 30N - EPSG:32630' },
  { value: '32631', label: 'WGS 84 / UTM zone 31N - EPSG:32631' },
  { value: '32632', label: 'WGS 84 / UTM zone 32N - EPSG:32632' },
  { value: '32633', label: 'WGS 84 / UTM zone 33N - EPSG:32633' },
  { value: '32634', label: 'WGS 84 / UTM zone 34N - EPSG:32634' },
  { value: '32635', label: 'WGS 84 / UTM zone 35N - EPSG:32635' },
  { value: '32636', label: 'WGS 84 / UTM zone 36N - EPSG:32636' },
  { value: '32637', label: 'WGS 84 / UTM zone 37N - EPSG:32637' },
  { value: '32638', label: 'WGS 84 / UTM zone 38N - EPSG:32638' },
  { value: '32639', label: 'WGS 84 / UTM zone 39N - EPSG:32639' },
  { value: '32640', label: 'WGS 84 / UTM zone 40N - EPSG:32640' },
  { value: '32641', label: 'WGS 84 / UTM zone 41N - EPSG:32641' },
  { value: '32642', label: 'WGS 84 / UTM zone 42N - EPSG:32642' },
  { value: '32643', label: 'WGS 84 / UTM zone 43N - EPSG:32643' },
  { value: '32644', label: 'WGS 84 / UTM zone 44N - EPSG:32644' },
  { value: '32645', label: 'WGS 84 / UTM zone 45N - EPSG:32645' },
  { value: '32646', label: 'WGS 84 / UTM zone 46N - EPSG:32646' },
  { value: '32647', label: 'WGS 84 / UTM zone 47N - EPSG:32647' },
  { value: '32648', label: 'WGS 84 / UTM zone 48N - EPSG:32648' },
  { value: '32649', label: 'WGS 84 / UTM zone 49N - EPSG:32649' },
  { value: '32650', label: 'WGS 84 / UTM zone 50N - EPSG:32650' },
  { value: '32651', label: 'WGS 84 / UTM zone 51N - EPSG:32651' },
  { value: '32652', label: 'WGS 84 / UTM zone 52N - EPSG:32652' },
  { value: '32653', label: 'WGS 84 / UTM zone 53N - EPSG:32653' },
  { value: '32654', label: 'WGS 84 / UTM zone 54N - EPSG:32654' },
  { value: '32655', label: 'WGS 84 / UTM zone 55N - EPSG:32655' },
  { value: '32656', label: 'WGS 84 / UTM zone 56N - EPSG:32656' },
  { value: '32657', label: 'WGS 84 / UTM zone 57N - EPSG:32657' },
  { value: '32658', label: 'WGS 84 / UTM zone 58N - EPSG:32658' },
  { value: '32659', label: 'WGS 84 / UTM zone 59N - EPSG:32659' },
  { value: '32660', label: 'WGS 84 / UTM zone 60N - EPSG:32660' },
  { value: '32661', label: 'WGS 84 / UPS North (N,E) - EPSG:32661' },
  { value: '32664', label: 'WGS 84 / BLM 14N (ftUS) - EPSG:32664' },
  { value: '32665', label: 'WGS 84 / BLM 15N (ftUS) - EPSG:32665' },
  { value: '32666', label: 'WGS 84 / BLM 16N (ftUS) - EPSG:32666' },
  { value: '32667', label: 'WGS 84 / BLM 17N (ftUS) - EPSG:32667' },
  {
    value: '32700',
    label: 'WGS 84 / UTM grid system (southern hemisphere) - EPSG:32700',
  },
  { value: '32701', label: 'WGS 84 / UTM zone 1S - EPSG:32701' },
  { value: '32702', label: 'WGS 84 / UTM zone 2S - EPSG:32702' },
  { value: '32703', label: 'WGS 84 / UTM zone 3S - EPSG:32703' },
  { value: '32704', label: 'WGS 84 / UTM zone 4S - EPSG:32704' },
  { value: '32705', label: 'WGS 84 / UTM zone 5S - EPSG:32705' },
  { value: '32706', label: 'WGS 84 / UTM zone 6S - EPSG:32706' },
  { value: '32707', label: 'WGS 84 / UTM zone 7S - EPSG:32707' },
  { value: '32708', label: 'WGS 84 / UTM zone 8S - EPSG:32708' },
  { value: '32709', label: 'WGS 84 / UTM zone 9S - EPSG:32709' },
  { value: '32710', label: 'WGS 84 / UTM zone 10S - EPSG:32710' },
  { value: '32711', label: 'WGS 84 / UTM zone 11S - EPSG:32711' },
  { value: '32712', label: 'WGS 84 / UTM zone 12S - EPSG:32712' },
  { value: '32713', label: 'WGS 84 / UTM zone 13S - EPSG:32713' },
  { value: '32714', label: 'WGS 84 / UTM zone 14S - EPSG:32714' },
  { value: '32715', label: 'WGS 84 / UTM zone 15S - EPSG:32715' },
  { value: '32716', label: 'WGS 84 / UTM zone 16S - EPSG:32716' },
  { value: '32717', label: 'WGS 84 / UTM zone 17S - EPSG:32717' },
  { value: '32718', label: 'WGS 84 / UTM zone 18S - EPSG:32718' },
  { value: '32719', label: 'WGS 84 / UTM zone 19S - EPSG:32719' },
  { value: '32720', label: 'WGS 84 / UTM zone 20S - EPSG:32720' },
  { value: '32721', label: 'WGS 84 / UTM zone 21S - EPSG:32721' },
  { value: '32722', label: 'WGS 84 / UTM zone 22S - EPSG:32722' },
  { value: '32723', label: 'WGS 84 / UTM zone 23S - EPSG:32723' },
  { value: '32724', label: 'WGS 84 / UTM zone 24S - EPSG:32724' },
  { value: '32725', label: 'WGS 84 / UTM zone 25S - EPSG:32725' },
  { value: '32726', label: 'WGS 84 / UTM zone 26S - EPSG:32726' },
  { value: '32727', label: 'WGS 84 / UTM zone 27S - EPSG:32727' },
  { value: '32728', label: 'WGS 84 / UTM zone 28S - EPSG:32728' },
  { value: '32729', label: 'WGS 84 / UTM zone 29S - EPSG:32729' },
  { value: '32730', label: 'WGS 84 / UTM zone 30S - EPSG:32730' },
  { value: '32731', label: 'WGS 84 / UTM zone 31S - EPSG:32731' },
  { value: '32732', label: 'WGS 84 / UTM zone 32S - EPSG:32732' },
  { value: '32733', label: 'WGS 84 / UTM zone 33S - EPSG:32733' },
  { value: '32734', label: 'WGS 84 / UTM zone 34S - EPSG:32734' },
  { value: '32735', label: 'WGS 84 / UTM zone 35S - EPSG:32735' },
  { value: '32736', label: 'WGS 84 / UTM zone 36S - EPSG:32736' },
  { value: '32737', label: 'WGS 84 / UTM zone 37S - EPSG:32737' },
  { value: '32738', label: 'WGS 84 / UTM zone 38S - EPSG:32738' },
  { value: '32739', label: 'WGS 84 / UTM zone 39S - EPSG:32739' },
  { value: '32740', label: 'WGS 84 / UTM zone 40S - EPSG:32740' },
  { value: '32741', label: 'WGS 84 / UTM zone 41S - EPSG:32741' },
  { value: '32742', label: 'WGS 84 / UTM zone 42S - EPSG:32742' },
  { value: '32743', label: 'WGS 84 / UTM zone 43S - EPSG:32743' },
  { value: '32744', label: 'WGS 84 / UTM zone 44S - EPSG:32744' },
  { value: '32745', label: 'WGS 84 / UTM zone 45S - EPSG:32745' },
  { value: '32746', label: 'WGS 84 / UTM zone 46S - EPSG:32746' },
  { value: '32747', label: 'WGS 84 / UTM zone 47S - EPSG:32747' },
  { value: '32748', label: 'WGS 84 / UTM zone 48S - EPSG:32748' },
  { value: '32749', label: 'WGS 84 / UTM zone 49S - EPSG:32749' },
  { value: '32750', label: 'WGS 84 / UTM zone 50S - EPSG:32750' },
  { value: '32751', label: 'WGS 84 / UTM zone 51S - EPSG:32751' },
  { value: '32752', label: 'WGS 84 / UTM zone 52S - EPSG:32752' },
  { value: '32753', label: 'WGS 84 / UTM zone 53S - EPSG:32753' },
  { value: '32754', label: 'WGS 84 / UTM zone 54S - EPSG:32754' },
  { value: '32755', label: 'WGS 84 / UTM zone 55S - EPSG:32755' },
  { value: '32756', label: 'WGS 84 / UTM zone 56S - EPSG:32756' },
  { value: '32757', label: 'WGS 84 / UTM zone 57S - EPSG:32757' },
  { value: '32758', label: 'WGS 84 / UTM zone 58S - EPSG:32758' },
  { value: '32759', label: 'WGS 84 / UTM zone 59S - EPSG:32759' },
  { value: '32760', label: 'WGS 84 / UTM zone 60S - EPSG:32760' },
  { value: '32761', label: 'WGS 84 / UPS South (N,E) - EPSG:32761' },
  { value: '32766', label: 'WGS 84 / TM 36 SE - EPSG:32766' },
  { value: '3822', label: 'TWD97 - EPSG:3822' },
  { value: '3887', label: 'IGRS - EPSG:3887' },
  { value: '4000', label: 'MOLDREF99 - EPSG:4000' },
  { value: '4039', label: 'RGRDC 2005 - EPSG:4039' },
  { value: '4073', label: 'SREF98 - EPSG:4073' },
  { value: '4079', label: 'REGCAN95 - EPSG:4079' },
  { value: '4328', label: 'WGS 84 (geocentric) - EPSG:4328' },
  { value: '4330', label: 'ITRF88 (geocentric) - EPSG:4330' },
  { value: '4331', label: 'ITRF89 (geocentric) - EPSG:4331' },
  { value: '4332', label: 'ITRF90 (geocentric) - EPSG:4332' },
  { value: '4333', label: 'ITRF91 (geocentric) - EPSG:4333' },
  { value: '4334', label: 'ITRF92 (geocentric) - EPSG:4334' },
  { value: '4335', label: 'ITRF93 (geocentric) - EPSG:4335' },
  { value: '4336', label: 'ITRF94 (geocentric) - EPSG:4336' },
  { value: '4337', label: 'ITRF96 (geocentric) - EPSG:4337' },
  { value: '4338', label: 'ITRF97 (geocentric) - EPSG:4338' },
  { value: '4340', label: 'Australian Antarctic (geocentric) - EPSG:4340' },
  { value: '4342', label: 'EST97 (geocentric) - EPSG:4342' },
  { value: '4344', label: 'CHTRF95 (geocentric) - EPSG:4344' },
  { value: '4346', label: 'ETRS89 (geocentric) - EPSG:4346' },
  { value: '4348', label: 'GDA94 (geocentric) - EPSG:4348' },
  { value: '4350', label: 'Hartebeesthoek94 (geocentric) - EPSG:4350' },
  { value: '4352', label: 'IRENET95 (geocentric) - EPSG:4352' },
  { value: '4354', label: 'JGD2000 (geocentric) - EPSG:4354' },
  { value: '4356', label: 'LKS94 (ETRS89) (geocentric) - EPSG:4356' },
  { value: '4358', label: 'Moznet (geocentric) - EPSG:4358' },
  { value: '4360', label: 'NAD83(CSRS) (geocentric) - EPSG:4360' },
  { value: '4362', label: 'NAD83(HARN) (geocentric) - EPSG:4362' },
  { value: '4364', label: 'NZGD2000 (geocentric) - EPSG:4364' },
  { value: '4366', label: 'POSGAR 98 (geocentric) - EPSG:4366' },
  { value: '4368', label: 'REGVEN (geocentric) - EPSG:4368' },
  { value: '4370', label: 'RGF93 (geocentric) - EPSG:4370' },
  { value: '4372', label: 'RGFG95 (geocentric) - EPSG:4372' },
  { value: '4374', label: 'RGR92 (geocentric) - EPSG:4374' },
  { value: '4376', label: 'SIRGAS (geocentric) - EPSG:4376' },
  { value: '4378', label: 'SWEREF99 (geocentric) - EPSG:4378' },
  { value: '4380', label: 'Yemen NGN96 (geocentric) - EPSG:4380' },
  { value: '4382', label: 'RGNC 1991 (geocentric) - EPSG:4382' },
  { value: '4384', label: 'RRAF 1991 (geocentric) - EPSG:4384' },
  { value: '4385', label: 'ITRF2000 (geocentric) - EPSG:4385' },
  { value: '4387', label: 'ISN93 (geocentric) - EPSG:4387' },
  { value: '4389', label: 'LKS92 (geocentric) - EPSG:4389' },
  { value: '4465', label: 'RGSPM06 - EPSG:4465' },
  { value: '4468', label: 'RGM04 - EPSG:4468' },
  { value: '4473', label: 'Cadastre 1997 - EPSG:4473' },
  { value: '4479', label: 'China Geodetic Coordinate System 2000 - EPSG:4479' },
  { value: '4481', label: 'Mexico ITRF92 - EPSG:4481' },
  { value: '4556', label: 'RRAF 1991 - EPSG:4556' },
  { value: '4882', label: 'Slovenia 1996 - EPSG:4882' },
  { value: '4884', label: 'RSRGD2000 - EPSG:4884' },
  { value: '4886', label: 'BDA2000 - EPSG:4886' },
  { value: '4888', label: 'HTRS96 - EPSG:4888' },
  { value: '4890', label: 'WGS 66 - EPSG:4890' },
  { value: '4892', label: 'NAD83(NSRS2007) - EPSG:4892' },
  { value: '4894', label: 'JAD2001 - EPSG:4894' },
  { value: '4896', label: 'ITRF2005 - EPSG:4896' },
  { value: '4897', label: 'DGN95 - EPSG:4897' },
  { value: '4899', label: 'LGD2006 - EPSG:4899' },
  { value: '4906', label: 'RGNC91-93 - EPSG:4906' },
  { value: '4908', label: 'GR96 - EPSG:4908' },
  { value: '4910', label: 'ITRF88 - EPSG:4910' },
  { value: '4911', label: 'ITRF89 - EPSG:4911' },
  { value: '4912', label: 'ITRF90 - EPSG:4912' },
  { value: '4913', label: 'ITRF91 - EPSG:4913' },
  { value: '4914', label: 'ITRF92 - EPSG:4914' },
  { value: '4915', label: 'ITRF93 - EPSG:4915' },
  { value: '4916', label: 'ITRF94 - EPSG:4916' },
  { value: '4917', label: 'ITRF96 - EPSG:4917' },
  { value: '4918', label: 'ITRF97 - EPSG:4918' },
  { value: '4919', label: 'ITRF2000 - EPSG:4919' },
  { value: '4920', label: 'GDM2000 - EPSG:4920' },
  { value: '4922', label: 'PZ-90 - EPSG:4922' },
  { value: '4924', label: 'Mauritania 1999 - EPSG:4924' },
  { value: '4926', label: 'Korea 2000 - EPSG:4926' },
  { value: '4928', label: 'POSGAR 94 - EPSG:4928' },
  { value: '4930', label: 'Australian Antarctic - EPSG:4930' },
  { value: '4932', label: 'CHTRF95 - EPSG:4932' },
  { value: '4934', label: 'EST97 - EPSG:4934' },
  { value: '4936', label: 'ETRS89 - EPSG:4936' },
  { value: '4938', label: 'GDA94 - EPSG:4938' },
  { value: '4940', label: 'Hartebeesthoek94 - EPSG:4940' },
  { value: '4942', label: 'IRENET95 - EPSG:4942' },
  { value: '4944', label: 'ISN93 - EPSG:4944' },
  { value: '4946', label: 'JGD2000 - EPSG:4946' },
  { value: '4948', label: 'LKS92 - EPSG:4948' },
  { value: '4950', label: 'LKS94 - EPSG:4950' },
  { value: '4952', label: 'Moznet - EPSG:4952' },
  { value: '4954', label: 'NAD83(CSRS) - EPSG:4954' },
  { value: '4956', label: 'NAD83(HARN) - EPSG:4956' },
  { value: '4958', label: 'NZGD2000 - EPSG:4958' },
  { value: '4960', label: 'POSGAR 98 - EPSG:4960' },
  { value: '4962', label: 'REGVEN - EPSG:4962' },
  { value: '4964', label: 'RGF93 - EPSG:4964' },
  { value: '4966', label: 'RGFG95 - EPSG:4966' },
  { value: '4968', label: 'RGNC 1991 - EPSG:4968' },
  { value: '4970', label: 'RGR92 - EPSG:4970' },
  { value: '4972', label: 'RRAF 1991 - EPSG:4972' },
  { value: '4974', label: 'SIRGAS 1995 - EPSG:4974' },
  { value: '4976', label: 'SWEREF99 - EPSG:4976' },
  { value: '4978', label: 'WGS 84 - EPSG:4978' },
  { value: '4980', label: 'Yemen NGN96 - EPSG:4980' },
  { value: '4982', label: 'IGM95 - EPSG:4982' },
  { value: '4984', label: 'WGS 72 - EPSG:4984' },
  { value: '4986', label: 'WGS 72BE - EPSG:4986' },
  { value: '4988', label: 'SIRGAS 2000 - EPSG:4988' },
  { value: '4990', label: 'Lao 1993 - EPSG:4990' },
  { value: '4992', label: 'Lao 1997 - EPSG:4992' },
  { value: '4994', label: 'PRS92 - EPSG:4994' },
  { value: '4996', label: 'MAGNA-SIRGAS - EPSG:4996' },
  { value: '4998', label: 'RGPF - EPSG:4998' },
  { value: '5011', label: 'PTRA08 - EPSG:5011' },
  { value: '5244', label: 'GDBD2009 - EPSG:5244' },
  { value: '5250', label: 'TUREF - EPSG:5250' },
  { value: '5262', label: 'DRUKREF 03 - EPSG:5262' },
  { value: '5322', label: 'ISN2004 - EPSG:5322' },
  { value: '5332', label: 'ITRF2008 - EPSG:5332' },
  { value: '5341', label: 'POSGAR 2007 - EPSG:5341' },
  { value: '5352', label: 'MARGEN - EPSG:5352' },
  { value: '5358', label: 'SIRGAS-Chile - EPSG:5358' },
  { value: '5363', label: 'CR05 - EPSG:5363' },
  { value: '5368', label: 'MACARIO SOLIS - EPSG:5368' },
  { value: '5369', label: 'Peru96 - EPSG:5369' },
  { value: '5379', label: 'SIRGAS-ROU98 - EPSG:5379' },
  { value: '5391', label: 'SIRGAS_ES2007.8 - EPSG:5391' },
  { value: '5487', label: 'RGAF09 - EPSG:5487' },
  { value: '5544', label: 'PNG94 - EPSG:5544' },
  { value: '5558', label: 'UCS-2000 - EPSG:5558' },
  { value: '5591', label: 'FEH2010 - EPSG:5591' },
  { value: '5828', label: 'DB_REF - EPSG:5828' },
  { value: '5884', label: 'TGD2005 - EPSG:5884' },
  { value: '6133', label: 'CIGD11 - EPSG:6133' },
  { value: '6309', label: 'CGRS93 - EPSG:6309' },
  { value: '6317', label: 'NAD83(2011) - EPSG:6317' },
  { value: '6320', label: 'NAD83(PA11) - EPSG:6320' },
  { value: '6323', label: 'NAD83(MA11) - EPSG:6323' },
  { value: '6363', label: 'Mexico ITRF2008 - EPSG:6363' },
  { value: '6666', label: 'JGD2011 - EPSG:6666' },
  { value: '6704', label: 'RDN2008 - EPSG:6704' },
  { value: '6781', label: 'NAD83(CORS96) - EPSG:6781' },
  { value: '6934', label: 'IGS08 - EPSG:6934' },
  { value: '6978', label: 'IGD05 - EPSG:6978' },
  { value: '6981', label: 'IG05 Intermediate CRS - EPSG:6981' },
  { value: '6985', label: 'IGD05/12 - EPSG:6985' },
  { value: '6988', label: 'IG05/12 Intermediate CRS - EPSG:6988' },
  { value: '7071', label: 'RGTAAF07 - EPSG:7071' },
  { value: '7134', label: 'IGD05 - EPSG:7134' },
  { value: '7137', label: 'IGD05/12 - EPSG:7137' },
  { value: '7371', label: 'ONGD14 - EPSG:7371' },
  { value: '7656', label: 'WGS 84 (G730) - EPSG:7656' },
  { value: '7658', label: 'WGS 84 (G873) - EPSG:7658' },
  { value: '7660', label: 'WGS 84 (G1150) - EPSG:7660' },
  { value: '7662', label: 'WGS 84 (G1674) - EPSG:7662' },
  { value: '7664', label: 'WGS 84 (G1762) - EPSG:7664' },
  { value: '7677', label: 'PZ-90.02 - EPSG:7677' },
  { value: '7679', label: 'PZ-90.11 - EPSG:7679' },
  { value: '7681', label: 'GSK-2011 - EPSG:7681' },
  { value: '7684', label: 'Kyrg-06 - EPSG:7684' },
  { value: '7789', label: 'ITRF2014 - EPSG:7789' },
  { value: '7796', label: 'BGS2005 - EPSG:7796' },
  { value: '7815', label: 'WGS 84 (Transit) - EPSG:7815' },
  { value: '7842', label: 'GDA2020 - EPSG:7842' },
  { value: '7879', label: 'St. Helena Tritan - EPSG:7879' },
  { value: '7884', label: 'SHGD2015 - EPSG:7884' },
  { value: '7914', label: 'ETRF89 - EPSG:7914' },
  { value: '7916', label: 'ETRF90 - EPSG:7916' },
  { value: '7918', label: 'ETRF91 - EPSG:7918' },
  { value: '7920', label: 'ETRF92 - EPSG:7920' },
  { value: '7922', label: 'ETRF93 - EPSG:7922' },
  { value: '7924', label: 'ETRF94 - EPSG:7924' },
  { value: '7926', label: 'ETRF96 - EPSG:7926' },
  { value: '7928', label: 'ETRF97 - EPSG:7928' },
  { value: '7930', label: 'ETRF2000 - EPSG:7930' },
  { value: '8084', label: 'ISN2016 - EPSG:8084' },
  { value: '8227', label: 'IGS14 - EPSG:8227' },
  { value: '8230', label: 'NAD83(CSRS96) - EPSG:8230' },
  { value: '8233', label: 'NAD83(CSRS)v2 - EPSG:8233' },
  { value: '8238', label: 'NAD83(CSRS)v3 - EPSG:8238' },
  { value: '8242', label: 'NAD83(CSRS)v4 - EPSG:8242' },
  { value: '8247', label: 'NAD83(CSRS)v5 - EPSG:8247' },
  { value: '8250', label: 'NAD83(CSRS)v6 - EPSG:8250' },
  { value: '8253', label: 'NAD83(CSRS)v7 - EPSG:8253' },
  {
    value: '3901',
    label: 'KKJ / Finland Uniform Coordinate System + N60 height - EPSG:3901',
  },
  { value: '3902', label: 'ETRS89 / TM35FIN(N,E) + N60 height - EPSG:3902' },
  { value: '3903', label: 'ETRS89 / TM35FIN(N,E) + N2000 height - EPSG:3903' },
  { value: '4097', label: 'ETRS89 / DKTM1 + DVR90 height - EPSG:4097' },
  { value: '4098', label: 'ETRS89 / DKTM2 + DVR90 height - EPSG:4098' },
  { value: '4099', label: 'ETRS89 / DKTM3 + DVR90 height - EPSG:4099' },
  { value: '4100', label: 'ETRS89 / DKTM4 + DVR90 height - EPSG:4100' },
  { value: '5318', label: 'ETRS89 / Faroe TM + FVR09 height - EPSG:5318' },
  { value: '5498', label: 'NAD83 + NAVD88 height - EPSG:5498' },
  { value: '5499', label: 'NAD83(HARN) + NAVD88 height - EPSG:5499' },
  { value: '5500', label: 'NAD83(NSRS2007) + NAVD88 height - EPSG:5500' },
  { value: '5554', label: 'ETRS89 / UTM zone 31N + DHHN92 height - EPSG:5554' },
  { value: '5555', label: 'ETRS89 / UTM zone 32N + DHHN92 height - EPSG:5555' },
  { value: '5556', label: 'ETRS89 / UTM zone 33N + DHHN92 height - EPSG:5556' },
  {
    value: '5598',
    label: 'FEH2010 / Fehmarnbelt TM + FCSVR10 height - EPSG:5598',
  },
  { value: '5628', label: 'SWEREF99 + RH2000 height - EPSG:5628' },
  { value: '5698', label: 'RGF93 / Lambert-93 + NGF-IGN69 height - EPSG:5698' },
  { value: '5699', label: 'RGF93 / Lambert-93 + NGF-IGN78 height - EPSG:5699' },
  {
    value: '5707',
    label: 'NTF (Paris) / Lambert zone I + NGF-IGN69 height - EPSG:5707',
  },
  {
    value: '5708',
    label: 'NTF (Paris) / Lambert zone IV + NGF-IGN78 height - EPSG:5708',
  },
  {
    value: '5832',
    label: 'DB_REF / 3-degree Gauss-Kruger zone 2 (E-N) + DHHN92 height - EPSG:5832',
  },
  {
    value: '5833',
    label: 'DB_REF / 3-degree Gauss-Kruger zone 3 (E-N) + DHHN92 height - EPSG:5833',
  },
  {
    value: '5834',
    label: 'DB_REF / 3-degree Gauss-Kruger zone 4 (E-N) + DHHN92 height - EPSG:5834',
  },
  {
    value: '5835',
    label: 'DB_REF / 3-degree Gauss-Kruger zone 5 (E-N) + DHHN92 height - EPSG:5835',
  },
  { value: '5845', label: 'SWEREF99 TM + RH2000 height - EPSG:5845' },
  { value: '5846', label: 'SWEREF99 12 00 + RH2000 height - EPSG:5846' },
  { value: '5847', label: 'SWEREF99 13 30 + RH2000 height - EPSG:5847' },
  { value: '5848', label: 'SWEREF99 15 00 + RH2000 height - EPSG:5848' },
  { value: '5849', label: 'SWEREF99 16 30 + RH2000 height - EPSG:5849' },
  { value: '5850', label: 'SWEREF99 18 00 + RH2000 height - EPSG:5850' },
  { value: '5851', label: 'SWEREF99 14 15 + RH2000 height - EPSG:5851' },
  { value: '5852', label: 'SWEREF99 15 45 + RH2000 height - EPSG:5852' },
  { value: '5853', label: 'SWEREF99 17 15 + RH2000 height - EPSG:5853' },
  { value: '5854', label: 'SWEREF99 18 45 + RH2000 height - EPSG:5854' },
  { value: '5855', label: 'SWEREF99 20 15 + RH2000 height - EPSG:5855' },
  { value: '5856', label: 'SWEREF99 21 45 + RH2000 height - EPSG:5856' },
  { value: '5857', label: 'SWEREF99 23 15 + RH2000 height - EPSG:5857' },
  { value: '5942', label: 'ETRS89 + NN2000 height - EPSG:5942' },
  { value: '5945', label: 'ETRS89 / NTM zone 5 + NN2000 height - EPSG:5945' },
  { value: '5946', label: 'ETRS89 / NTM zone 6 + NN2000 height - EPSG:5946' },
  { value: '5947', label: 'ETRS89 / NTM zone 7 + NN2000 height - EPSG:5947' },
  { value: '5948', label: 'ETRS89 / NTM zone 8 + NN2000 height - EPSG:5948' },
  { value: '5949', label: 'ETRS89 / NTM zone 9 + NN2000 height - EPSG:5949' },
  { value: '5950', label: 'ETRS89 / NTM zone 10 + NN2000 height - EPSG:5950' },
  { value: '5951', label: 'ETRS89 / NTM zone 11 + NN2000 height - EPSG:5951' },
  { value: '5952', label: 'ETRS89 / NTM zone 12 + NN2000 height - EPSG:5952' },
  { value: '5953', label: 'ETRS89 / NTM zone 13 + NN2000 height - EPSG:5953' },
  { value: '5954', label: 'ETRS89 / NTM zone 14 + NN2000 height - EPSG:5954' },
  { value: '5955', label: 'ETRS89 / NTM zone 15 + NN2000 height - EPSG:5955' },
  { value: '5956', label: 'ETRS89 / NTM zone 16 + NN2000 height - EPSG:5956' },
  { value: '5957', label: 'ETRS89 / NTM zone 17 + NN2000 height - EPSG:5957' },
  { value: '5958', label: 'ETRS89 / NTM zone 18 + NN2000 height - EPSG:5958' },
  { value: '5959', label: 'ETRS89 / NTM zone 19 + NN2000 height - EPSG:5959' },
  { value: '5960', label: 'ETRS89 / NTM zone 20 + NN2000 height - EPSG:5960' },
  { value: '5961', label: 'ETRS89 / NTM zone 21 + NN2000 height - EPSG:5961' },
  { value: '5962', label: 'ETRS89 / NTM zone 22 + NN2000 height - EPSG:5962' },
  { value: '5963', label: 'ETRS89 / NTM zone 23 + NN2000 height - EPSG:5963' },
  { value: '5964', label: 'ETRS89 / NTM zone 24 + NN2000 height - EPSG:5964' },
  { value: '5965', label: 'ETRS89 / NTM zone 25 + NN2000 height - EPSG:5965' },
  { value: '5966', label: 'ETRS89 / NTM zone 26 + NN2000 height - EPSG:5966' },
  { value: '5967', label: 'ETRS89 / NTM zone 27 + NN2000 height - EPSG:5967' },
  { value: '5968', label: 'ETRS89 / NTM zone 28 + NN2000 height - EPSG:5968' },
  { value: '5969', label: 'ETRS89 / NTM zone 29 + NN2000 height - EPSG:5969' },
  { value: '5970', label: 'ETRS89 / NTM zone 30 + NN2000 height - EPSG:5970' },
  { value: '5971', label: 'ETRS89 / UTM zone 31 + NN2000 height - EPSG:5971' },
  { value: '5972', label: 'ETRS89 / UTM zone 32 + NN2000 height - EPSG:5972' },
  { value: '5973', label: 'ETRS89 / UTM zone 33 + NN2000 height - EPSG:5973' },
  { value: '5974', label: 'ETRS89 / UTM zone 34 + NN2000 height - EPSG:5974' },
  { value: '5975', label: 'ETRS89 / UTM zone 35 + NN2000 height - EPSG:5975' },
  { value: '5976', label: 'ETRS89 / UTM zone 36 + NN2000 height - EPSG:5976' },
  { value: '6144', label: 'ETRS89 + NN54 height - EPSG:6144' },
  { value: '6145', label: 'ETRS89 / NTM zone 5 + NN54 height - EPSG:6145' },
  { value: '6146', label: 'ETRS89 / NTM zone 6 + NN54 height - EPSG:6146' },
  { value: '6147', label: 'ETRS89 / NTM zone 7 + NN54 height - EPSG:6147' },
  { value: '6148', label: 'ETRS89 / NTM zone 8 + NN54 height - EPSG:6148' },
  { value: '6149', label: 'ETRS89 / NTM zone 9 + NN54 height - EPSG:6149' },
  { value: '6150', label: 'ETRS89 / NTM zone 10 + NN54 height - EPSG:6150' },
  { value: '6151', label: 'ETRS89 / NTM zone 11 + NN54 height - EPSG:6151' },
  { value: '6152', label: 'ETRS89 / NTM zone 12 + NN54 height - EPSG:6152' },
  { value: '6153', label: 'ETRS89 / NTM zone 13 + NN54 height - EPSG:6153' },
  { value: '6154', label: 'ETRS89 / NTM zone 14 + NN54 height - EPSG:6154' },
  { value: '6155', label: 'ETRS89 / NTM zone 15 + NN54 height - EPSG:6155' },
  { value: '6156', label: 'ETRS89 / NTM zone 16 + NN54 height - EPSG:6156' },
  { value: '6157', label: 'ETRS89 / NTM zone 17 + NN54 height - EPSG:6157' },
  { value: '6158', label: 'ETRS89 / NTM zone 18 + NN54 height - EPSG:6158' },
  { value: '6159', label: 'ETRS89 / NTM zone 19 + NN54 height - EPSG:6159' },
  { value: '6160', label: 'ETRS89 / NTM zone 20 + NN54 height - EPSG:6160' },
  { value: '6161', label: 'ETRS89 / NTM zone 21 + NN54 height - EPSG:6161' },
  { value: '6162', label: 'ETRS89 / NTM zone 22 + NN54 height - EPSG:6162' },
  { value: '6163', label: 'ETRS89 / NTM zone 23 + NN54 height - EPSG:6163' },
  { value: '6164', label: 'ETRS89 / NTM zone 24 + NN54 height - EPSG:6164' },
  { value: '6165', label: 'ETRS89 / NTM zone 25 + NN54 height - EPSG:6165' },
  { value: '6166', label: 'ETRS89 / NTM zone 26 + NN54 height - EPSG:6166' },
  { value: '6167', label: 'ETRS89 / NTM zone 27 + NN54 height - EPSG:6167' },
  { value: '6168', label: 'ETRS89 / NTM zone 28 + NN54 height - EPSG:6168' },
  { value: '6169', label: 'ETRS89 / NTM zone 29 + NN54 height - EPSG:6169' },
  { value: '6170', label: 'ETRS89 / NTM zone 30 + NN54 height - EPSG:6170' },
  { value: '6171', label: 'ETRS89 / UTM zone 31 + NN54 height - EPSG:6171' },
  { value: '6172', label: 'ETRS89 / UTM zone 32 + NN54 height - EPSG:6172' },
  { value: '6173', label: 'ETRS89 / UTM zone 33 + NN54 height - EPSG:6173' },
  { value: '6174', label: 'ETRS89 / UTM zone 34 + NN54 height - EPSG:6174' },
  { value: '6175', label: 'ETRS89 / UTM zone 35 + NN54 height - EPSG:6175' },
  { value: '6176', label: 'ETRS89 / UTM zone 36 + NN54 height - EPSG:6176' },
  {
    value: '6190',
    label: 'Belge 1972 / Belgian Lambert 72 + Ostend height - EPSG:6190',
  },
  { value: '6349', label: 'NAD83(2011) + NAVD88 height - EPSG:6349' },
  { value: '6649', label: 'NAD83(CSRS) + CGVD2013 height - EPSG:6649' },
  {
    value: '6650',
    label: 'NAD83(CSRS) / UTM zone 7N + CGVD2013 height - EPSG:6650',
  },
  {
    value: '6651',
    label: 'NAD83(CSRS) / UTM zone 8N + CGVD2013 height - EPSG:6651',
  },
  {
    value: '6652',
    label: 'NAD83(CSRS) / UTM zone 9N + CGVD2013 height - EPSG:6652',
  },
  {
    value: '6653',
    label: 'NAD83(CSRS) / UTM zone 10N + CGVD2013 height - EPSG:6653',
  },
  {
    value: '6654',
    label: 'NAD83(CSRS) / UTM zone 11N + CGVD2013 height - EPSG:6654',
  },
  {
    value: '6655',
    label: 'NAD83(CSRS) / UTM zone 12N + CGVD2013 height - EPSG:6655',
  },
  {
    value: '6656',
    label: 'NAD83(CSRS) / UTM zone 13N + CGVD2013 height - EPSG:6656',
  },
  {
    value: '6657',
    label: 'NAD83(CSRS) / UTM zone 14N + CGVD2013 height - EPSG:6657',
  },
  {
    value: '6658',
    label: 'NAD83(CSRS) / UTM zone 15N + CGVD2013 height - EPSG:6658',
  },
  {
    value: '6659',
    label: 'NAD83(CSRS) / UTM zone 16N + CGVD2013 height - EPSG:6659',
  },
  {
    value: '6660',
    label: 'NAD83(CSRS) / UTM zone 17N + CGVD2013 height - EPSG:6660',
  },
  {
    value: '6661',
    label: 'NAD83(CSRS) / UTM zone 18N + CGVD2013 height - EPSG:6661',
  },
  {
    value: '6662',
    label: 'NAD83(CSRS) / UTM zone 19N + CGVD2013 height - EPSG:6662',
  },
  {
    value: '6663',
    label: 'NAD83(CSRS) / UTM zone 20N + CGVD2013 height - EPSG:6663',
  },
  {
    value: '6664',
    label: 'NAD83(CSRS) / UTM zone 21N + CGVD2013 height - EPSG:6664',
  },
  {
    value: '6665',
    label: 'NAD83(CSRS) / UTM zone 22N + CGVD2013 height - EPSG:6665',
  },
  { value: '6696', label: 'JGD2000 + JGD2000 (vertical) height - EPSG:6696' },
  { value: '6697', label: 'JGD2011 + JGD2011 (vertical) height - EPSG:6697' },
  { value: '6700', label: 'Tokyo + JSLD72 height - EPSG:6700' },
  {
    value: '6871',
    label: 'WGS 84 / Pseudo-Mercator +  EGM2008 geoid height - EPSG:6871',
  },
  {
    value: '6893',
    label: 'WGS 84 / World Mercator +  EGM2008 height - EPSG:6893',
  },
  { value: '6917', label: 'SVY21 + SHD height - EPSG:6917' },
  { value: '6927', label: 'SVY21 / Singapore TM + SHD height - EPSG:6927' },
  { value: '7400', label: 'NTF (Paris) + NGF IGN69 height - EPSG:7400' },
  {
    value: '7401',
    label: 'NTF (Paris) / France II + NGF Lallemand - EPSG:7401',
  },
  { value: '7402', label: 'NTF (Paris) / France II + NGF IGN69 - EPSG:7402' },
  { value: '7403', label: 'NTF (Paris) / France III + NGF IGN69 - EPSG:7403' },
  { value: '7404', label: 'RT90 + RH70 height - EPSG:7404' },
  {
    value: '7405',
    label: 'OSGB 1936 / British National Grid + ODN height - EPSG:7405',
  },
  { value: '7406', label: 'NAD27 + NGVD29 height - EPSG:7406' },
  { value: '7407', label: 'NAD27 / Texas North + NGVD29 height - EPSG:7407' },
  { value: '7408', label: 'RD/NAP - EPSG:7408' },
  { value: '7409', label: 'ETRS89 + EVRF2000 height - EPSG:7409' },
  { value: '7410', label: 'PSHD93 - EPSG:7410' },
  {
    value: '7411',
    label: 'NTF (Paris) / Lambert zone II + NGF Lallemand height - EPSG:7411',
  },
  {
    value: '7412',
    label: 'NTF (Paris) / Lambert zone II + NGF IGN69 - EPSG:7412',
  },
  {
    value: '7413',
    label: 'NTF (Paris) / Lambert zone III + NGF IGN69 - EPSG:7413',
  },
  { value: '7414', label: 'Tokyo + JSLD69 height - EPSG:7414' },
  { value: '7415', label: 'Amersfoort / RD New + NAP height - EPSG:7415' },
  { value: '7416', label: 'ETRS89 / UTM zone 32N + DVR90 height - EPSG:7416' },
  { value: '7417', label: 'ETRS89 / UTM zone 33N + DVR90 height - EPSG:7417' },
  {
    value: '7418',
    label: 'ETRS89 / Kp2000 Jutland + DVR90 height - EPSG:7418',
  },
  {
    value: '7419',
    label: 'ETRS89 / Kp2000 Zealand + DVR90 height - EPSG:7419',
  },
  {
    value: '7420',
    label: 'ETRS89 / Kp2000 Bornholm + DVR90 height - EPSG:7420',
  },
  {
    value: '7421',
    label: 'NTF (Paris) / Lambert zone II + NGF-IGN69 height - EPSG:7421',
  },
  {
    value: '7422',
    label: 'NTF (Paris) / Lambert zone III + NGF-IGN69 height - EPSG:7422',
  },
  { value: '7423', label: 'ETRS89 + EVRF2007 height - EPSG:7423' },
  {
    value: '7954',
    label: 'Astro DOS 71 / UTM zone 30S + Jamestown 1971 height - EPSG:7954',
  },
  {
    value: '7955',
    label: 'St. Helena Tritan / UTM zone 30S +  Tritan 2011 height - EPSG:7955',
  },
  { value: '7956', label: 'SHMG2015 +  SHVD2015 height - EPSG:7956' },
  { value: '8349', label: 'GR96 + GVR2000 height - EPSG:8349' },
  { value: '8350', label: 'GR96 + GVR2016 height - EPSG:8350' },
  { value: '8351', label: 'S-JTSK [JTSK03] - EPSG:8351' },
  { value: '8427', label: 'Hong Kong Geodetic CS - EPSG:8427' },
  { value: '8428', label: 'Macao 1920 - EPSG:8428' },
  { value: '8431', label: 'Macao 2008 - EPSG:8431' },
  { value: '8545', label: 'NAD83(HARN Corrected) - EPSG:8545' },
  { value: '8685', label: 'SRB_ETRS89 - EPSG:8685' },
  { value: '8818', label: 'MTRF-2000 - EPSG:8818' },
  { value: '8860', label: 'NAD83(FBN) - EPSG:8860' },
  { value: '8900', label: 'RGWF96 - EPSG:8900' },
  { value: '8902', label: 'RGWF96 (lon-lat) - EPSG:8902' },
  { value: '8907', label: 'CR-SIRGAS - EPSG:8907' },
  { value: '8988', label: 'ITRF88 - EPSG:8988' },
  { value: '8989', label: 'ITRF89 - EPSG:8989' },
  { value: '8990', label: 'ITRF90 - EPSG:8990' },
  { value: '8991', label: 'ITRF91 - EPSG:8991' },
  { value: '8992', label: 'ITRF92 - EPSG:8992' },
  { value: '8993', label: 'ITRF93 - EPSG:8993' },
  { value: '8994', label: 'ITRF94 - EPSG:8994' },
  { value: '8995', label: 'ITRF96 - EPSG:8995' },
  { value: '8996', label: 'ITRF97 - EPSG:8996' },
  { value: '8997', label: 'ITRF2000 - EPSG:8997' },
  { value: '8998', label: 'ITRF2005 - EPSG:8998' },
  { value: '8999', label: 'ITRF2008 - EPSG:8999' },
  { value: '9000', label: 'ITRF2014 - EPSG:9000' },
  { value: '9003', label: 'IGS97 - EPSG:9003' },
  { value: '9006', label: 'IGS00 - EPSG:9006' },
  { value: '9009', label: 'IGb00 - EPSG:9009' },
  { value: '9012', label: 'IGS05 - EPSG:9012' },
  { value: '9014', label: 'IGS08 - EPSG:9014' },
  { value: '9017', label: 'IGb08 - EPSG:9017' },
  { value: '9019', label: 'IGS14 - EPSG:9019' },
  { value: '5896', label: 'VN-2000 / TM-3 zone 481 - EPSG:5896' },
  { value: '5897', label: 'VN-2000 / TM-3 zone 482 - EPSG:5897' },
  { value: '5898', label: 'VN-2000 / TM-3 zone 491 - EPSG:5898' },
  { value: '5899', label: 'VN-2000 / TM-3 Da Nang zone - EPSG:5899' },
  { value: '8352', label: 'S-JTSK [JTSK03] / Krovak - EPSG:8352' },
  { value: '8353', label: 'S-JTSK [JTSK03] / Krovak East North - EPSG:8353' },
  { value: '8379', label: 'NAD83 / NCRS Las Vegas (m) - EPSG:8379' },
  { value: '8380', label: 'NAD83 / NCRS Las Vegas (ftUS) - EPSG:8380' },
  { value: '8381', label: 'NAD83 / NCRS Las Vegas high (m) - EPSG:8381' },
  { value: '8382', label: 'NAD83 / NCRS Las Vegas high (ftUS) - EPSG:8382' },
  { value: '8383', label: 'NAD83(2011) / NCRS Las Vegas (m) - EPSG:8383' },
  { value: '8384', label: 'NAD83(2011) / NCRS Las Vegas (ftUS) - EPSG:8384' },
  { value: '8385', label: 'NAD83(2011) / NCRS Las Vegas high (m) - EPSG:8385' },
  {
    value: '8387',
    label: 'NAD83(2011) / NCRS Las Vegas high (ftUS) - EPSG:8387',
  },
  { value: '8391', label: 'GDA94 / WEIPA94 - EPSG:8391' },
  { value: '8395', label: 'ETRS89 / Gauss-Kruger CM 9E - EPSG:8395' },
  { value: '8433', label: 'Macao 1920 / Macao Grid - EPSG:8433' },
  { value: '8441', label: 'Tananarive / Laborde Grid - EPSG:8441' },
  { value: '8455', label: 'RGTAAF07 / UTM zone 53S - EPSG:8455' },
  { value: '8456', label: 'RGTAAF07 / UTM zone 54S - EPSG:8456' },
  { value: '8518', label: 'NAD83(2011) / KS RCS zone 1 - EPSG:8518' },
  { value: '8519', label: 'NAD83(2011) / KS RCS zone 2 - EPSG:8519' },
  { value: '8520', label: 'NAD83(2011) / KS RCS zone 3 - EPSG:8520' },
  { value: '8521', label: 'NAD83(2011) / KS RCS zone 4 - EPSG:8521' },
  { value: '8522', label: 'NAD83(2011) / KS RCS zone 5 - EPSG:8522' },
  { value: '8523', label: 'NAD83(2011) / KS RCS zone 6 - EPSG:8523' },
  { value: '8524', label: 'NAD83(2011) / KS RCS zone 7 - EPSG:8524' },
  { value: '8525', label: 'NAD83(2011) / KS RCS zone 8 - EPSG:8525' },
  { value: '8526', label: 'NAD83(2011) / KS RCS zone 9 - EPSG:8526' },
  { value: '8527', label: 'NAD83(2011) / KS RCS zone 10 - EPSG:8527' },
  { value: '8528', label: 'NAD83(2011) / KS RCS zone 11 - EPSG:8528' },
  { value: '8529', label: 'NAD83(2011) / KS RCS zone 12 - EPSG:8529' },
  { value: '8531', label: 'NAD83(2011) / KS RCS zone 13 - EPSG:8531' },
  { value: '8533', label: 'NAD83(2011) / KS RCS zone 14 - EPSG:8533' },
  { value: '8534', label: 'NAD83(2011) / KS RCS zone 15 - EPSG:8534' },
  { value: '8535', label: 'NAD83(2011) / KS RCS zone 16 - EPSG:8535' },
  { value: '8536', label: 'NAD83(2011) / KS RCS zone 17 - EPSG:8536' },
  { value: '8538', label: 'NAD83(2011) / KS RCS zone 18 - EPSG:8538' },
  { value: '8539', label: 'NAD83(2011) / KS RCS zone 19 - EPSG:8539' },
  { value: '8540', label: 'NAD83(2011) / KS RCS zone 20 - EPSG:8540' },
  { value: '8677', label: 'MGI 1901 / Balkans zone 5 - EPSG:8677' },
  { value: '8678', label: 'MGI 1901 / Balkans zone 6 - EPSG:8678' },
  { value: '8679', label: 'MGI 1901 / Balkans zone 8 - EPSG:8679' },
  { value: '8682', label: 'SRB_ETRS89 / UTM zone 34N - EPSG:8682' },
  { value: '8686', label: 'MGI 1901 / Slovenia Grid - EPSG:8686' },
  { value: '8687', label: 'Slovenia 1996 / UTM zone 33N - EPSG:8687' },
  { value: '8692', label: 'NAD83(MA11) / UTM zone 54N - EPSG:8692' },
  { value: '8693', label: 'NAD83(MA11) / UTM zone 55N - EPSG:8693' },
  { value: '8826', label: 'NAD83 / Idaho Transverse Mercator - EPSG:8826' },
  { value: '8836', label: 'MTRF-2000 / UTM zone 36N - EPSG:8836' },
  { value: '8837', label: 'MTRF-2000 / UTM zone 37N - EPSG:8837' },
  { value: '8838', label: 'MTRF-2000 / UTM zone 38N - EPSG:8838' },
  { value: '8839', label: 'MTRF-2000 / UTM zone 39N - EPSG:8839' },
  { value: '8840', label: 'MTRF-2000 / UTM zone 40N - EPSG:8840' },
  { value: '8857', label: 'WGS 84 / Equal Earth Greenwich - EPSG:8857' },
  { value: '8858', label: 'WGS 84 / Equal Earth Americas - EPSG:8858' },
  { value: '8859', label: 'WGS 84 / Equal Earth Asia-Pacific - EPSG:8859' },
  { value: '8903', label: 'RGWF96 / UTM zone 1S - EPSG:8903' },
  { value: '8908', label: 'CR-SIRGAS / CRTM05 - EPSG:8908' },
  { value: '8909', label: 'CR-SIRGAS / UTM zone 16N - EPSG:8909' },
  { value: '8910', label: 'CR-SIRGAS / UTM zone 17N - EPSG:8910' },
  { value: '8397', label: 'ETRF2005 - EPSG:8397' },
  { value: '8401', label: 'ETRF2014 - EPSG:8401' },
  { value: '8425', label: 'Hong Kong Geodetic CS - EPSG:8425' },
  { value: '8429', label: 'Macao 2008 - EPSG:8429' },
  { value: '8541', label: 'NAD83(FBN) - EPSG:8541' },
  { value: '8543', label: 'NAD83(HARN Corrected) - EPSG:8543' },
  { value: '8683', label: 'SRB_ETRS89 - EPSG:8683' },
  { value: '8816', label: 'MTRF-2000 - EPSG:8816' },
  { value: '8898', label: 'RGWF96 - EPSG:8898' },
  { value: '8905', label: 'CR-SIRGAS - EPSG:8905' },
  { value: '9001', label: 'IGS97 - EPSG:9001' },
  { value: '9004', label: 'IGS00 - EPSG:9004' },
  { value: '9007', label: 'IGb00 - EPSG:9007' },
  { value: '9010', label: 'IGS05 - EPSG:9010' },
  { value: '9015', label: 'IGb08 - EPSG:9015' },
  { value: '8360', label: 'ETRS89 + Baltic 1957 height - EPSG:8360' },
  {
    value: '8370',
    label: 'ETRS89 / Belgian Lambert 2008 + Ostend height - EPSG:8370',
  },
  {
    value: '8700',
    label: 'NAD83 / Arizona East (ft) + NAVD88 height (ft) - EPSG:8700',
  },
  {
    value: '8701',
    label: 'NAD83 / Arizona Central (ft) + NAVD88 height (ft) - EPSG:8701',
  },
  {
    value: '8702',
    label: 'NAD83 / Arizona West (ft) + NAVD88 height (ft) - EPSG:8702',
  },
  {
    value: '8703',
    label: 'NAD83 / Michigan North (ft) + NAVD88 height (ft) - EPSG:8703',
  },
  {
    value: '8704',
    label: 'NAD83 / Michigan Central (ft) + NAVD88 height (ft) - EPSG:8704',
  },
  {
    value: '8705',
    label: 'NAD83 / Michigan South (ft) + NAVD88 height (ft) - EPSG:8705',
  },
  {
    value: '8706',
    label: 'NAD83 / Montana (ft) + NAVD88 height (ft) - EPSG:8706',
  },
  {
    value: '8707',
    label: 'NAD83 / North Dakota North (ft) + NAVD88 height (ft) - EPSG:8707',
  },
  {
    value: '8708',
    label: 'NAD83 / North Dakota South (ft) + NAVD88 height (ft) - EPSG:8708',
  },
  {
    value: '8709',
    label: 'NAD83 / Oregon North (ft) + NAVD88 height (ft) - EPSG:8709',
  },
  {
    value: '8710',
    label: 'NAD83 / Oregon South (ft) + NAVD88 height (ft) - EPSG:8710',
  },
  {
    value: '8711',
    label: 'NAD83 / South Carolina (ft) + NAVD88 height (ft) - EPSG:8711',
  },
  {
    value: '8712',
    label: 'NAD83 / Arkansas North (ftUS) + NAVD88 height (ftUS) - EPSG:8712',
  },
  {
    value: '8713',
    label: 'NAD83 / Arkansas South (ftUS) + NAVD88 height (ftUS) - EPSG:8713',
  },
  {
    value: '8714',
    label: 'NAD83 / California zone 1 (ftUS) + NAVD88 height (ftUS) - EPSG:8714',
  },
  {
    value: '8715',
    label: 'NAD83 / California zone 2 (ftUS) + NAVD88 height (ftUS) - EPSG:8715',
  },
  {
    value: '8716',
    label: 'NAD83 / California zone 3 (ftUS) + NAVD88 height (ftUS) - EPSG:8716',
  },
  {
    value: '8717',
    label: 'NAD83 / California zone 4 (ftUS) + NAVD88 height (ftUS) - EPSG:8717',
  },
  {
    value: '8718',
    label: 'NAD83 / California zone 5 (ftUS) + NAVD88 height (ftUS) - EPSG:8718',
  },
  {
    value: '8719',
    label: 'NAD83 / California zone 6 (ftUS) + NAVD88 height (ftUS) - EPSG:8719',
  },
  {
    value: '8720',
    label: 'NAD83 / Colorado North (ftUS) + NAVD88 height (ftUS) - EPSG:8720',
  },
  {
    value: '8721',
    label: 'NAD83 / Colorado Central (ftUS) + NAVD88 height (ftUS) - EPSG:8721',
  },
  {
    value: '8722',
    label: 'NAD83 / Colorado South (ftUS) + NAVD88 height (ftUS) - EPSG:8722',
  },
  {
    value: '8723',
    label: 'NAD83 / Connecticut (ftUS) + NAVD88 height (ftUS) - EPSG:8723',
  },
  {
    value: '8724',
    label: 'NAD83 / Delaware (ftUS) + NAVD88 height (ftUS) - EPSG:8724',
  },
  {
    value: '8725',
    label: 'NAD83 / Florida North (ftUS) + NAVD88 height (ftUS) - EPSG:8725',
  },
  {
    value: '8726',
    label: 'NAD83 / Florida East (ftUS) + NAVD88 height (ftUS) - EPSG:8726',
  },
  {
    value: '8727',
    label: 'NAD83 / Florida West (ftUS) + NAVD88 height (ftUS) - EPSG:8727',
  },
  {
    value: '8728',
    label: 'NAD83 / Georgia East (ftUS) + NAVD88 height (ftUS) - EPSG:8728',
  },
  {
    value: '8729',
    label: 'NAD83 / Georgia West (ftUS) + NAVD88 height (ftUS) - EPSG:8729',
  },
  {
    value: '8730',
    label: 'NAD83 / Idaho East (ftUS) + NAVD88 height (ftUS) - EPSG:8730',
  },
  {
    value: '8731',
    label: 'NAD83 / Idaho Central (ftUS) + NAVD88 height (ftUS) - EPSG:8731',
  },
  {
    value: '8732',
    label: 'NAD83 / Idaho West (ftUS) + NAVD88 height (ftUS) - EPSG:8732',
  },
  {
    value: '8733',
    label: 'NAD83 / Illinois East (ftUS) + NAVD88 height (ftUS) - EPSG:8733',
  },
  {
    value: '8734',
    label: 'NAD83 / Illinois West (ftUS) + NAVD88 height (ftUS) - EPSG:8734',
  },
  {
    value: '8735',
    label: 'NAD83 / Indiana East (ftUS) + NAVD88 height (ftUS) - EPSG:8735',
  },
  {
    value: '8736',
    label: 'NAD83 / Indiana West (ftUS) + NAVD88 height (ftUS) - EPSG:8736',
  },
  {
    value: '8737',
    label: 'NAD83 / Iowa North (ftUS) + NAVD88 height (ftUS) - EPSG:8737',
  },
  {
    value: '8738',
    label: 'NAD83 / Iowa South (ftUS) + NAVD88 height (ftUS) - EPSG:8738',
  },
  {
    value: '8739',
    label: 'NAD83 / Kansas North (ftUS) + NAVD88 height (ftUS) - EPSG:8739',
  },
  {
    value: '8740',
    label: 'NAD83 / Kansas South (ftUS) + NAVD88 height (ftUS) - EPSG:8740',
  },
  {
    value: '8741',
    label: 'NAD83 / Kentucky North (ftUS) + NAVD88 height (ftUS) - EPSG:8741',
  },
  {
    value: '8742',
    label: 'NAD83 / Kentucky South (ftUS) + NAVD88 height (ftUS) - EPSG:8742',
  },
  {
    value: '8743',
    label: 'NAD83 / Louisiana North (ftUS) + NAVD88 height (ftUS) - EPSG:8743',
  },
  {
    value: '8744',
    label: 'NAD83 / Louisiana South (ftUS) + NAVD88 height (ftUS) - EPSG:8744',
  },
  {
    value: '8745',
    label: 'NAD83 / Maine East (ftUS) + NAVD88 height (ftUS) - EPSG:8745',
  },
  {
    value: '8746',
    label: 'NAD83 / Maine West (ftUS) + NAVD88 height (ftUS) - EPSG:8746',
  },
  {
    value: '8747',
    label: 'NAD83 / Maryland (ftUS) + NAVD88 height (ftUS) - EPSG:8747',
  },
  {
    value: '8748',
    label: 'NAD83 / Massachusetts Mainland (ftUS) + NAVD88 height (ftUS) - EPSG:8748',
  },
  {
    value: '8749',
    label: 'NAD83 / Massachusetts Island (ftUS) + NAVD88 height (ftUS) - EPSG:8749',
  },
  {
    value: '8750',
    label: 'NAD83 / Minnesota North (ftUS) + NAVD88 height (ftUS) - EPSG:8750',
  },
  {
    value: '8751',
    label: 'NAD83 / Minnesota Central (ftUS) + NAVD88 height (ftUS) - EPSG:8751',
  },
  {
    value: '8752',
    label: 'NAD83 / Minnesota South (ftUS) + NAVD88 height (ftUS) - EPSG:8752',
  },
  {
    value: '8753',
    label: 'NAD83 / Mississippi East (ftUS) + NAVD88 height (ftUS) - EPSG:8753',
  },
  {
    value: '8754',
    label: 'NAD83 / Mississippi West (ftUS) + NAVD88 height (ftUS) - EPSG:8754',
  },
  {
    value: '8755',
    label: 'NAD83 / Nebraska (ftUS) + NAVD88 height (ftUS) - EPSG:8755',
  },
  {
    value: '8756',
    label: 'NAD83 / Nevada East (ftUS) + NAVD88 height (ftUS) - EPSG:8756',
  },
  {
    value: '8757',
    label: 'NAD83 / Nevada Central (ftUS) + NAVD88 height (ftUS) - EPSG:8757',
  },
  {
    value: '8758',
    label: 'NAD83 / Nevada West (ftUS) + NAVD88 height (ftUS) - EPSG:8758',
  },
  {
    value: '8759',
    label: 'NAD83 / New Hampshire (ftUS) + NAVD88 height (ftUS) - EPSG:8759',
  },
  {
    value: '8760',
    label: 'NAD83 / New Jersey (ftUS) + NAVD88 height (ftUS) - EPSG:8760',
  },
  {
    value: '8761',
    label: 'NAD83 / New Mexico East (ftUS) + NAVD88 height (ftUS) - EPSG:8761',
  },
  {
    value: '8762',
    label: 'NAD83 / New Mexico Central (ftUS) + NAVD88 height (ftUS) - EPSG:8762',
  },
  {
    value: '8763',
    label: 'NAD83 / New Mexico West (ftUS) + NAVD88 height (ftUS) - EPSG:8763',
  },
  {
    value: '8764',
    label: 'NAD83 / New York East (ftUS) + NAVD88 height (ftUS) - EPSG:8764',
  },
  {
    value: '8765',
    label: 'NAD83 / New York Central (ftUS) + NAVD88 height (ftUS) - EPSG:8765',
  },
  {
    value: '8766',
    label: 'NAD83 / New York West (ftUS) + NAVD88 height (ftUS) - EPSG:8766',
  },
  {
    value: '8767',
    label: 'NAD83 / New York Long Island (ftUS) + NAVD88 height (ftUS) - EPSG:8767',
  },
  {
    value: '8768',
    label: 'NAD83 / North Carolina (ftUS) + NAVD88 height (ftUS) - EPSG:8768',
  },
  {
    value: '8769',
    label: 'NAD83 / Ohio North (ftUS) + NAVD88 height (ftUS) - EPSG:8769',
  },
  {
    value: '8770',
    label: 'NAD83 / Ohio South (ftUS) + NAVD88 height (ftUS) - EPSG:8770',
  },
  {
    value: '8771',
    label: 'NAD83 / Oklahoma North (ftUS) + NAVD88 height (ftUS) - EPSG:8771',
  },
  {
    value: '8772',
    label: 'NAD83 / Oklahoma South (ftUS) + NAVD88 height (ftUS) - EPSG:8772',
  },
  {
    value: '8773',
    label: 'NAD83 / Pennsylvania North (ftUS) + NAVD88 height (ftUS) - EPSG:8773',
  },
  {
    value: '8774',
    label: 'NAD83 / Pennsylvania South (ftUS) + NAVD88 height (ftUS) - EPSG:8774',
  },
  {
    value: '8775',
    label: 'NAD83 / Rhode Island (ftUS) + NAVD88 height (ftUS) - EPSG:8775',
  },
  {
    value: '8776',
    label: 'NAD83 / South Dakota North (ftUS) + NAVD88 height (ftUS) - EPSG:8776',
  },
  {
    value: '8777',
    label: 'NAD83 / South Dakota South (ftUS) + NAVD88 height (ftUS) - EPSG:8777',
  },
  {
    value: '8778',
    label: 'NAD83 / Tennessee (ftUS) + NAVD88 height (ftUS) - EPSG:8778',
  },
  {
    value: '8779',
    label: 'NAD83 / Texas North (ftUS) + NAVD88 height (ftUS) - EPSG:8779',
  },
  {
    value: '8780',
    label: 'NAD83 / Texas North Central (ftUS) + NAVD88 height (ftUS) - EPSG:8780',
  },
  {
    value: '8781',
    label: 'NAD83 / Texas Central (ftUS) + NAVD88 height (ftUS) - EPSG:8781',
  },
  {
    value: '8782',
    label: 'NAD83 / Texas South Central (ftUS) + NAVD88 height (ftUS) - EPSG:8782',
  },
  {
    value: '8783',
    label: 'NAD83 / Texas South (ftUS) + NAVD88 height (ftUS) - EPSG:8783',
  },
  {
    value: '8784',
    label: 'NAD83 / Utah North (ftUS) + NAVD88 height (ftUS) - EPSG:8784',
  },
  {
    value: '8785',
    label: 'NAD83 / Utah Central (ftUS) + NAVD88 height (ftUS) - EPSG:8785',
  },
  {
    value: '8786',
    label: 'NAD83 / Utah South (ftUS) + NAVD88 height (ftUS) - EPSG:8786',
  },
  {
    value: '8787',
    label: 'NAD83 / Vermont (ftUS) + NAVD88 height (ftUS) - EPSG:8787',
  },
  {
    value: '8788',
    label: 'NAD83 / Virginia North (ftUS) + NAVD88 height (ftUS) - EPSG:8788',
  },
  {
    value: '8789',
    label: 'NAD83 / Virginia South (ftUS) + NAVD88 height (ftUS) - EPSG:8789',
  },
  {
    value: '8790',
    label: 'NAD83 / Washington North (ftUS) + NAVD88 height (ftUS) - EPSG:8790',
  },
  {
    value: '8791',
    label: 'NAD83 / Washington South (ftUS) + NAVD88 height (ftUS) - EPSG:8791',
  },
  {
    value: '8792',
    label: 'NAD83 / West Virginia North (ftUS) + NAVD88 height (ftUS) - EPSG:8792',
  },
  {
    value: '8793',
    label: 'NAD83 / West Virginia South (ftUS) + NAVD88 height (ftUS) - EPSG:8793',
  },
  {
    value: '8794',
    label: 'NAD83 / Wisconsin North (ftUS) + NAVD88 height (ftUS) - EPSG:8794',
  },
  {
    value: '8795',
    label: 'NAD83 / Wisconsin Central (ftUS) + NAVD88 height (ftUS) - EPSG:8795',
  },
  {
    value: '8796',
    label: 'NAD83 / Wisconsin South (ftUS) + NAVD88 height (ftUS) - EPSG:8796',
  },
  {
    value: '8797',
    label: 'NAD83 / Wyoming East (ftUS) + NAVD88 height (ftUS) - EPSG:8797',
  },
  {
    value: '8798',
    label: 'NAD83 / Wyoming East Central (ftUS) + NAVD88 height (ftUS) - EPSG:8798',
  },
  {
    value: '8799',
    label: 'NAD83 / Wyoming West Central (ftUS) + NAVD88 height (ftUS) - EPSG:8799',
  },
  {
    value: '8800',
    label: 'NAD83 / Wyoming West (ftUS) + NAVD88 height (ftUS) - EPSG:8800',
  },
  { value: '8801', label: 'NAD83 / Alabama East + NAVD88 height - EPSG:8801' },
  { value: '8802', label: 'NAD83 / Alabama West + NAVD88 height - EPSG:8802' },
  { value: '8803', label: 'NAD83 / Alaska zone 1 + NAVD88 height - EPSG:8803' },
  { value: '8804', label: 'NAD83 / Alaska zone 2 + NAVD88 height - EPSG:8804' },
  { value: '8805', label: 'NAD83 / Alaska zone 3 + NAVD88 height - EPSG:8805' },
  { value: '8806', label: 'NAD83 / Alaska zone 4 + NAVD88 height - EPSG:8806' },
  { value: '8807', label: 'NAD83 / Alaska zone 5 + NAVD88 height - EPSG:8807' },
  { value: '8808', label: 'NAD83 / Alaska zone 6 + NAVD88 height - EPSG:8808' },
  { value: '8809', label: 'NAD83 / Alaska zone 7 + NAVD88 height - EPSG:8809' },
  { value: '8810', label: 'NAD83 / Alaska zone 8 + NAVD88 height - EPSG:8810' },
  { value: '8811', label: 'NAD83 / Alaska zone 9 + NAVD88 height - EPSG:8811' },
  {
    value: '8812',
    label: 'NAD83 / Alaska zone 10 + NAVD88 height - EPSG:8812',
  },
  { value: '8813', label: 'NAD83 / Missouri East + NAVD88 height - EPSG:8813' },
  {
    value: '8814',
    label: 'NAD83 / Missouri Central + NAVD88 height - EPSG:8814',
  },
  { value: '8815', label: 'NAD83 / Missouri West + NAVD88 height - EPSG:8815' },
  { value: '8912', label: 'CR-SIRGAS / CRTM05 + DACR52 height - EPSG:8912' },
  { value: '3823', label: 'TWD97 - EPSG:3823' },
  { value: '3888', label: 'IGRS - EPSG:3888' },
  { value: '4017', label: 'MOLDREF99 - EPSG:4017' },
  { value: '4040', label: 'RGRDC 2005 - EPSG:4040' },
  { value: '4074', label: 'SREF98 - EPSG:4074' },
  { value: '4080', label: 'REGCAN95 - EPSG:4080' },
  { value: '4466', label: 'RGSPM06 - EPSG:4466' },
  { value: '4469', label: 'RGM04 - EPSG:4469' },
  { value: '4472', label: 'Cadastre 1997 - EPSG:4472' },
  { value: '4480', label: 'China Geodetic Coordinate System 2000 - EPSG:4480' },
  { value: '4482', label: 'Mexico ITRF92 - EPSG:4482' },
  { value: '4557', label: 'RRAF 1991 - EPSG:4557' },
  { value: '4883', label: 'Slovenia 1996 - EPSG:4883' },
  { value: '4885', label: 'RSRGD2000 - EPSG:4885' },
  { value: '4887', label: 'BDA2000 - EPSG:4887' },
  { value: '4889', label: 'HTRS96 - EPSG:4889' },
  { value: '4891', label: 'WGS 66 - EPSG:4891' },
  { value: '4893', label: 'NAD83(NSRS2007) - EPSG:4893' },
  { value: '4895', label: 'JAD2001 - EPSG:4895' },
  { value: '4898', label: 'DGN95 - EPSG:4898' },
  { value: '4900', label: 'LGD2006 - EPSG:4900' },
  { value: '4907', label: 'RGNC91-93 - EPSG:4907' },
  { value: '4909', label: 'GR96 - EPSG:4909' },
  { value: '4921', label: 'GDM2000 - EPSG:4921' },
  { value: '4923', label: 'PZ-90 - EPSG:4923' },
  { value: '4925', label: 'Mauritania 1999 - EPSG:4925' },
  { value: '4927', label: 'Korea 2000 - EPSG:4927' },
  { value: '4929', label: 'POSGAR 94 - EPSG:4929' },
  { value: '4931', label: 'Australian Antarctic - EPSG:4931' },
  { value: '4933', label: 'CHTRF95 - EPSG:4933' },
  { value: '4935', label: 'EST97 - EPSG:4935' },
  { value: '4937', label: 'ETRS89 - EPSG:4937' },
  { value: '4939', label: 'GDA94 - EPSG:4939' },
  { value: '4941', label: 'Hartebeesthoek94 - EPSG:4941' },
  { value: '4943', label: 'IRENET95 - EPSG:4943' },
  { value: '4945', label: 'ISN93 - EPSG:4945' },
  { value: '4947', label: 'JGD2000 - EPSG:4947' },
  { value: '4949', label: 'LKS92 - EPSG:4949' },
  { value: '4951', label: 'LKS94 - EPSG:4951' },
  { value: '4953', label: 'Moznet - EPSG:4953' },
  { value: '4955', label: 'NAD83(CSRS) - EPSG:4955' },
  { value: '4957', label: 'NAD83(HARN) - EPSG:4957' },
  { value: '4959', label: 'NZGD2000 - EPSG:4959' },
  { value: '4961', label: 'POSGAR 98 - EPSG:4961' },
  { value: '4963', label: 'REGVEN - EPSG:4963' },
  { value: '4965', label: 'RGF93 - EPSG:4965' },
  { value: '4967', label: 'RGFG95 - EPSG:4967' },
  { value: '4971', label: 'RGR92 - EPSG:4971' },
  { value: '4975', label: 'SIRGAS 1995 - EPSG:4975' },
  { value: '4977', label: 'SWEREF99 - EPSG:4977' },
  { value: '4979', label: 'WGS 84 - EPSG:4979' },
  { value: '4981', label: 'Yemen NGN96 - EPSG:4981' },
  { value: '4983', label: 'IGM95 - EPSG:4983' },
  { value: '4985', label: 'WGS 72 - EPSG:4985' },
  { value: '4987', label: 'WGS 72BE - EPSG:4987' },
  { value: '4989', label: 'SIRGAS 2000 - EPSG:4989' },
  { value: '4991', label: 'Lao 1993 - EPSG:4991' },
  { value: '4993', label: 'Lao 1997 - EPSG:4993' },
  { value: '4995', label: 'PRS92 - EPSG:4995' },
  { value: '4997', label: 'MAGNA-SIRGAS - EPSG:4997' },
  { value: '4999', label: 'RGPF - EPSG:4999' },
  { value: '5012', label: 'PTRA08 - EPSG:5012' },
  { value: '5245', label: 'GDBD2009 - EPSG:5245' },
  { value: '5251', label: 'TUREF - EPSG:5251' },
  { value: '5263', label: 'DRUKREF 03 - EPSG:5263' },
  { value: '5323', label: 'ISN2004 - EPSG:5323' },
  { value: '5342', label: 'POSGAR 2007 - EPSG:5342' },
  { value: '5353', label: 'MARGEN - EPSG:5353' },
  { value: '5359', label: 'SIRGAS-Chile - EPSG:5359' },
  { value: '5364', label: 'CR05 - EPSG:5364' },
  { value: '5370', label: 'MACARIO SOLIS - EPSG:5370' },
  { value: '5372', label: 'Peru96 - EPSG:5372' },
  { value: '5380', label: 'SIRGAS-ROU98 - EPSG:5380' },
  { value: '5392', label: 'SIRGAS_ES2007.8 - EPSG:5392' },
  { value: '5488', label: 'RGAF09 - EPSG:5488' },
  { value: '5545', label: 'PNG94 - EPSG:5545' },
  { value: '5560', label: 'UCS-2000 - EPSG:5560' },
  { value: '5592', label: 'FEH2010 - EPSG:5592' },
  { value: '5830', label: 'DB_REF - EPSG:5830' },
  { value: '5885', label: 'TGD2005 - EPSG:5885' },
  { value: '6134', label: 'CIGD11 - EPSG:6134' },
  { value: '6310', label: 'CGRS93 - EPSG:6310' },
  { value: '6319', label: 'NAD83(2011) - EPSG:6319' },
  { value: '6321', label: 'NAD83(PA11) - EPSG:6321' },
  { value: '6324', label: 'NAD83(MA11) - EPSG:6324' },
  { value: '6364', label: 'Mexico ITRF2008 - EPSG:6364' },
  { value: '6667', label: 'JGD2011 - EPSG:6667' },
  { value: '6705', label: 'RDN2008 - EPSG:6705' },
  { value: '6782', label: 'NAD83(CORS96) - EPSG:6782' },
  { value: '6982', label: 'IG05 Intermediate CRS - EPSG:6982' },
  { value: '6989', label: 'IG05/12 Intermediate CRS - EPSG:6989' },
  { value: '7034', label: 'RGSPM06 (lon-lat) - EPSG:7034' },
  { value: '7036', label: 'RGR92 (lon-lat) - EPSG:7036' },
  { value: '7038', label: 'RGM04 (lon-lat) - EPSG:7038' },
  { value: '7040', label: 'RGFG95 (lon-lat) - EPSG:7040' },
  { value: '7042', label: 'RGF93 (lon-lat) - EPSG:7042' },
  { value: '7072', label: 'RGTAAF07 - EPSG:7072' },
  { value: '7085', label: 'RGAF09 (lon-lat) - EPSG:7085' },
  { value: '7087', label: 'RGTAAF07 (lon-lat) - EPSG:7087' },
  { value: '7135', label: 'IGD05 - EPSG:7135' },
  { value: '7138', label: 'IGD05/12 - EPSG:7138' },
  { value: '7372', label: 'ONGD14 - EPSG:7372' },
  { value: '7657', label: 'WGS 84 (G730) - EPSG:7657' },
  { value: '7659', label: 'WGS 84 (G873) - EPSG:7659' },
  { value: '7661', label: 'WGS 84 (G1150) - EPSG:7661' },
  { value: '7663', label: 'WGS 84 (G1674) - EPSG:7663' },
  { value: '7665', label: 'WGS 84 (G1762) - EPSG:7665' },
  { value: '7678', label: 'PZ-90.02 - EPSG:7678' },
  { value: '7680', label: 'PZ-90.11 - EPSG:7680' },
  { value: '7682', label: 'GSK-2011 - EPSG:7682' },
  { value: '7685', label: 'Kyrg-06 - EPSG:7685' },
  { value: '7797', label: 'BGS2005 - EPSG:7797' },
  { value: '7816', label: 'WGS 84 (Transit) - EPSG:7816' },
  { value: '7843', label: 'GDA2020 - EPSG:7843' },
  { value: '7880', label: 'St. Helena Tritan - EPSG:7880' },
  { value: '7885', label: 'SHGD2015 - EPSG:7885' },
  { value: '7900', label: 'ITRF88 - EPSG:7900' },
  { value: '7901', label: 'ITRF89 - EPSG:7901' },
  { value: '7902', label: 'ITRF90 - EPSG:7902' },
  { value: '7903', label: 'ITRF91 - EPSG:7903' },
  { value: '7904', label: 'ITRF92 - EPSG:7904' },
  { value: '7905', label: 'ITRF93 - EPSG:7905' },
  { value: '7906', label: 'ITRF94 - EPSG:7906' },
  { value: '7907', label: 'ITRF96 - EPSG:7907' },
  { value: '7908', label: 'ITRF97 - EPSG:7908' },
  { value: '7909', label: 'ITRF2000 - EPSG:7909' },
  { value: '7910', label: 'ITRF2005 - EPSG:7910' },
  { value: '7911', label: 'ITRF2008 - EPSG:7911' },
  { value: '7912', label: 'ITRF2014 - EPSG:7912' },
  { value: '7915', label: 'ETRF89 - EPSG:7915' },
  { value: '7917', label: 'ETRF90 - EPSG:7917' },
  { value: '7919', label: 'ETRF91 - EPSG:7919' },
  { value: '7921', label: 'ETRF92 - EPSG:7921' },
  { value: '7923', label: 'ETRF93 - EPSG:7923' },
  { value: '7925', label: 'ETRF94 - EPSG:7925' },
  { value: '7927', label: 'ETRF96 - EPSG:7927' },
  { value: '7929', label: 'ETRF97 - EPSG:7929' },
  { value: '7931', label: 'ETRF2000 - EPSG:7931' },
  { value: '8085', label: 'ISN2016 - EPSG:8085' },
  { value: '8231', label: 'NAD83(CSRS96) - EPSG:8231' },
  { value: '8235', label: 'NAD83(CSRS)v2 - EPSG:8235' },
  { value: '8239', label: 'NAD83(CSRS)v3 - EPSG:8239' },
  { value: '8244', label: 'NAD83(CSRS)v4 - EPSG:8244' },
  { value: '8248', label: 'NAD83(CSRS)v5 - EPSG:8248' },
  { value: '8251', label: 'NAD83(CSRS)v6 - EPSG:8251' },
  { value: '8254', label: 'NAD83(CSRS)v7 - EPSG:8254' },
  { value: '8399', label: 'ETRF2005 - EPSG:8399' },
  { value: '8403', label: 'ETRF2014 - EPSG:8403' },
  { value: '8426', label: 'Hong Kong Geodetic CS - EPSG:8426' },
  { value: '8430', label: 'Macao 2008 - EPSG:8430' },
  { value: '8542', label: 'NAD83(FBN) - EPSG:8542' },
  { value: '8544', label: 'NAD83(HARN Corrected) - EPSG:8544' },
  { value: '8684', label: 'SRB_ETRS89 - EPSG:8684' },
  { value: '8817', label: 'MTRF-2000 - EPSG:8817' },
  { value: '8899', label: 'RGWF96 - EPSG:8899' },
  { value: '8901', label: 'RGWF96 (lon-lat) - EPSG:8901' },
  { value: '8906', label: 'CR-SIRGAS - EPSG:8906' },
  { value: '9002', label: 'IGS97 - EPSG:9002' },
  { value: '9005', label: 'IGS00 - EPSG:9005' },
  { value: '9008', label: 'IGb00 - EPSG:9008' },
  { value: '9011', label: 'IGS05 - EPSG:9011' },
  { value: '9013', label: 'IGS08 - EPSG:9013' },
  { value: '9016', label: 'IGb08 - EPSG:9016' },
  { value: '9018', label: 'IGS14 - EPSG:9018' },

  { value: '7848-7', label: 'GDA2020 / MGA zone 48 - EPSG:7848 (7-parameter)' },
  { value: '7849-7', label: 'GDA2020 / MGA zone 49 - EPSG:7849 (7-parameter)' },
  { value: '7850-7', label: 'GDA2020 / MGA zone 50 - EPSG:7850 (7-parameter)' },
  { value: '7851-7', label: 'GDA2020 / MGA zone 51 - EPSG:7851 (7-parameter)' },
  { value: '7852-7', label: 'GDA2020 / MGA zone 52 - EPSG:7852 (7-parameter)' },
  { value: '7853-7', label: 'GDA2020 / MGA zone 53 - EPSG:7853 (7-parameter)' },
  { value: '7854-7', label: 'GDA2020 / MGA zone 54 - EPSG:7854 (7-parameter)' },
  { value: '7855-7', label: 'GDA2020 / MGA zone 55 - EPSG:7855 (7-parameter)' },
  { value: '7856-7', label: 'GDA2020 / MGA zone 56 - EPSG:7856 (7-parameter)' },
  { value: '7857-7', label: 'GDA2020 / MGA zone 57 - EPSG:7857 (7-parameter)' },
  { value: '7858-7', label: 'GDA2020 / MGA zone 58 - EPSG:7858 (7-parameter)' },

  { value: '28348-7', label: 'GDA94 / MGA zone 48 - EPSG:28348 (7-parameter)' },
  { value: '28349-7', label: 'GDA94 / MGA zone 49 - EPSG:28349 (7-parameter)' },
  { value: '28350-7', label: 'GDA94 / MGA zone 50 - EPSG:28350 (7-parameter)' },
  { value: '28351-7', label: 'GDA94 / MGA zone 51 - EPSG:28351 (7-parameter)' },
  { value: '28352-7', label: 'GDA94 / MGA zone 52 - EPSG:28352 (7-parameter)' },
  { value: '28353-7', label: 'GDA94 / MGA zone 53 - EPSG:28353 (7-parameter)' },
  { value: '28354-7', label: 'GDA94 / MGA zone 54 - EPSG:28354 (7-parameter)' },
  { value: '28355-7', label: 'GDA94 / MGA zone 55 - EPSG:28355 (7-parameter)' },
  { value: '28356-7', label: 'GDA94 / MGA zone 56 - EPSG:28356 (7-parameter)' },
  { value: '28357-7', label: 'GDA94 / MGA zone 57 - EPSG:28357 (7-parameter)' },
  { value: '28358-7', label: 'GDA94 / MGA zone 58 - EPSG:28358 (7-parameter)' },
];
