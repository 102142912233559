import { NODES_TABLE_DEFAULT_PAGE_INDEX } from '@/constants/node';
import { ACTION_TYPE } from '@/constants/policy';
import { queryClient } from '@/graphql/client';
import {
  CreateNodeShareLinkMutationVariables,
  SystemNodeDependantDomain,
  UpdateSystemNodeNameMutationVariables,
} from '@/graphql/codegen/graphql';
import { CREATE_NODE_SHARE_LINK, UPDATE_SYSTEM_NODE_NAME } from '@/graphql/mutations';
import { GET_FILES_BY_IDS } from '@/graphql/queries';
import { request } from '@/graphql/request';
import { useSystemNodesUserActionsPermissionsBySystemNodeIds } from '@/hooks/useSystemNodesUserActionsPermissionsBySystemNodeIds';
import { downloadStore } from '@/stores/downloadStore';
import { useFiles } from '@/stores/files';
import { SystemNode } from '@/stores/systemNodes';
import { cn } from '@/utils/classname';
import { copyUrl } from '@/utils/copy';
import { getElementWidth } from '@/utils/elementWidth';
import { removeExtension } from '@/utils/fileExtension';
import { getNodeIcon, getSystemCreatedNodeIcon } from '@/utils/nodeIcons';
import { getShareLinkToken } from '@/utils/shareLink';
import { Button, Menu, MenuItem, toast } from '@skand/ui';
import { useMutation } from '@tanstack/react-query';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Label } from '../Label';
import { MoreMenu } from '../MoreMenu';

interface FileRowProps {
  node: SystemNode;
  pageIndex: number;
  onClick: (value: string) => void;
  onSelectFile: (node: SystemNode) => void;
  setPageIndex: (node: number) => void;
  setSearchKey: (value: string) => void;
  setEditNodeTarget: (node: SystemNode | null) => void;
  editNodeTarget: SystemNode | null;
  handleEditAccessMenu: (id: SystemNode['id'] | null) => void;
  setSystemNodes: (value: []) => void;
  setBreadCrumbParentNodeId: (value: string) => void;
}

export const FileRow = ({
  node,
  pageIndex,
  onClick,
  onSelectFile,
  setPageIndex,
  setSearchKey,
  setEditNodeTarget,
  editNodeTarget,
  handleEditAccessMenu,
  setSystemNodes,
  setBreadCrumbParentNodeId,
}: FileRowProps) => {
  const hasShareLinkToken = getShareLinkToken();

  const { project } = useFiles(state => state);
  const fileNameRef = useRef(null);
  const [fileNameWidth, setFilenNameWidth] = useState(0);
  const [enabledFileNameTooltip, setEnabledFileNameTooltip] = useState(false);
  const [editInput, setEditInput] = useState(node.name);

  const { userHasAdminPermission } = useSystemNodesUserActionsPermissionsBySystemNodeIds({
    systemNodeIds: node.id ? [node.id] : [],
  });

  const isSystemManagedNode = [
    project?.rootFolderNodeId,
    project?.renderObjectsRootFolderNodeId,
    project?.annotationsRootFolderNodeId,
    project?.userFilesRootFolderNodeId,
  ].includes(node.id);

  const isSystemCreatedNode = node.dependants.some(
    dep =>
      dep === SystemNodeDependantDomain['RenderObject'] ||
      dep === SystemNodeDependantDomain['Annotation'],
  );

  useEffect(() => {
    setFilenNameWidth(getElementWidth(fileNameRef));
  }, []);

  const handleClick = () => {
    if (editNodeTarget || node.type === 'file') return;
    if (node.type === 'folder' && node.sourceNodeId) {
      onClick(node.sourceNodeId);
    } else if (node.type === 'folder') {
      onClick(node.id);
    }
    setBreadCrumbParentNodeId(node.id);
    setPageIndex(NODES_TABLE_DEFAULT_PAGE_INDEX);
    setSearchKey('');
  };

  const renderNodeIcon = () => {
    if (isSystemManagedNode) {
      return 'i-fs-folder-lock';
    } else if (isSystemCreatedNode) {
      return getSystemCreatedNodeIcon(node.extension, node.kind);
    } else {
      return getNodeIcon(node.extension, node.kind);
    }
  };

  const createNodeShareLink = useMutation({
    mutationFn: (variables: CreateNodeShareLinkMutationVariables) =>
      request(CREATE_NODE_SHARE_LINK, variables),
  });

  const updateSystemNodeName = useMutation({
    mutationFn: (variables: UpdateSystemNodeNameMutationVariables) =>
      request(UPDATE_SYSTEM_NODE_NAME, variables),
    onSuccess: () => {
      if (pageIndex === 0) {
        queryClient.invalidateQueries(['LIST_SYSTEM_NODES_BY_PARENT_NODE_ID']);
      } else {
        // LIST_SYSTEM_NODES_BY_PARENT_NODE_ID will be triggered when pageIndex is changed
        setPageIndex(NODES_TABLE_DEFAULT_PAGE_INDEX);
      }
      setSystemNodes([]);
      setEditNodeTarget(null);
    },
  });

  const getSignedUrl = useMutation({
    mutationFn: (fileId: string) => request(GET_FILES_BY_IDS, { fileIds: [fileId] }),
  });

  const handleSubmit = async () => {
    let nodeName = editInput;
    if (node.type === 'file') {
      nodeName = `${editInput}.${node.extension}`;
    }
    await updateSystemNodeName.mutateAsync({
      systemNodeId: node.id,
      name: nodeName,
    });
  };

  const handleDownloadFile = () => {
    downloadStore.createTaskManagerAndStart([node.id]);
  };

  const handleCopyShareLink = useCallback(async () => {
    if (node.type === 'folder') {
      const result = await createNodeShareLink.mutateAsync({
        nodeId: node.kind === 'LinkNode' ? (node.sourceNodeId as string) : node.id,
        name: node.name,
        actionType: ACTION_TYPE.READ,
      });
      const shareToken = result?.createNodeShareLink?.shareToken;
      const shareLinkUrl = `${window.location.origin}/datasets/folders?shareLinkToken=${shareToken}`;
      if (shareToken) {
        await copyUrl(shareLinkUrl, node.name);
        toast({
          clickToDismiss: true,
          lifespan: 3000,
          message: 'Link copied!',
          type: 'success',
        });
      }
    } else if (node.fileId) {
      const response = await getSignedUrl.mutateAsync(node.fileId);
      const signedUrl = response.filesByIds?.[0]?.signedGetObjectDownloadUrl;
      if (!signedUrl) return;
      await copyUrl(signedUrl, node.name);
      toast({
        clickToDismiss: true,
        lifespan: 3000,
        message: 'Link copied!',
        type: 'success',
      });
    } else {
      return;
    }
  }, [
    node.type,
    node.fileId,
    node.kind,
    node.sourceNodeId,
    node.id,
    node.name,
    createNodeShareLink,
    getSignedUrl,
  ]);

  return (
    <div className={cn('w-full', 'my-3', 'flex', 'flex-row', 'justify-between', 'cursor-pointer')}>
      <div className={cn('flex felx-row gap-3 items-center mr-3')}>
        <div className={cn('flex flex-row  gap-3')} onClick={handleClick}>
          <div className={cn(`${renderNodeIcon()} text-5 text-neutral-600 cursor-pointer`)} />
          {editNodeTarget === node ? (
            <div className="flex flex-1">
              <input
                className={cn(
                  'px-1',
                  'color-neutral-800',
                  'typo-text-s-em',
                  'rounded',
                  'border-1',
                  'border-solid',
                  'border-primary-400',
                  'outline-none',
                  'w-full',
                )}
                onChange={e => setEditInput(e.target.value)}
                value={editInput}
              />
              <Button className="ml-1" filled onClick={handleSubmit} primary size="xs">
                Save
              </Button>
              <Button className="ml-1" onClick={() => setEditNodeTarget(null)} size="xs">
                Cancel
              </Button>
            </div>
          ) : (
            <div className="relative">
              <p
                className={cn(
                  'w-auto max-w-[280px] text-neutral-800 typo-text-s text-nowrap overflow-hidden',
                )}
                onMouseEnter={() => setEnabledFileNameTooltip(true)}
                onMouseLeave={() => setEnabledFileNameTooltip(false)}
                ref={fileNameRef}
              >
                {node.name}
              </p>
              {fileNameWidth > 279 && enabledFileNameTooltip && (
                <div className={cn('absolute', 'z-1', 'top-5', 'left-[-32px]')}>
                  <Label
                    css="bg-neutral-100 py-1"
                    ellipsis={false}
                    labelTitle={node.name}
                    labelType="default"
                    textLength={node.name.length}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      <MoreMenu className={cn('cursor-pointer')}>
        <Menu className="z-2">
          <MenuItem
            disabled={isSystemManagedNode || node.fileKind === 'Tileset3dFile'}
            onClick={() => handleDownloadFile()}
          >
            Download
          </MenuItem>
          <MenuItem
            className="cursor-pointer"
            onClick={() => {
              onSelectFile(node);
            }}
          >
            View details
          </MenuItem>
          {!isSystemManagedNode && (
            <MenuItem
              disabled={!userHasAdminPermission || node.type === 'file' || node.kind === 'LinkNode'}
              onClick={() => handleEditAccessMenu(node.id)}
            >
              Edit access
            </MenuItem>
          )}

          {!hasShareLinkToken && (
            <>
              {!isSystemCreatedNode && (
                <MenuItem
                  className="cursor-pointer"
                  disabled={isSystemManagedNode}
                  onClick={() => {
                    setEditInput(removeExtension(node.name));
                    setEditNodeTarget(node);
                  }}
                >
                  Rename
                </MenuItem>
              )}

              {!isSystemManagedNode && (
                <MenuItem className="cursor-pointer" onClick={handleCopyShareLink}>
                  Copy link to {node.type === 'folder' ? 'folder' : 'file'}
                </MenuItem>
              )}
            </>
          )}

          {/* <MenuItem className="cursor-pointer" onClick={() => {}}>
            Copy share link
          </MenuItem>
          <MenuItem className="cursor-pointer" disabled={isSystemManagedNode} onClick={() => {}}>
            Delete
          </MenuItem> */}
        </Menu>
      </MoreMenu>
    </div>
  );
};
