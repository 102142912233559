import { TableDense } from '@/components/TableDense';
import { NODES_TABLE_DEFAULT_PAGE_INDEX, NODES_TABLE_PAGE_SIZE_12 } from '@/constants/node';
import { queryClient } from '@/graphql/client';
import {
  CreateLinkNodesBySourceNodeIdsMutationVariables,
  SystemNodeDependantDomain,
  SystemNodeKindInput,
} from '@/graphql/codegen/graphql';
import { CREATE_LINK_NODES_BY_SOURCE_NODE_IDS } from '@/graphql/mutations';
import { request } from '@/graphql/request';
import { useSystemNodes } from '@/hooks/useSystemNodes';
import { useExplore } from '@/stores/explore';
import { setSelectedFiles, useFiles } from '@/stores/files';
import { SystemNode } from '@/stores/systemNodes';
import { cn } from '@/utils/classname';
import { getNodeIcon } from '@/utils/nodeIcons';
import { Button, CheckBox, Input, Modal, toast } from '@skand/ui';
import { useMutation } from '@tanstack/react-query';
import {
  PaginationState,
  RowSelectionState,
  SortingState,
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Breadcrumb } from './Breadcrumb';
import { Pagination } from './Pagination';
import { SelectedFiles } from './SelectedFiles';

interface DataManagementFilesMenuProps {
  closeMenu: () => void;
  setPageIndex: (node: number) => void;
}
const columnHelper = createColumnHelper<SystemNode>();

export const DataManagementFilesMenu = ({
  closeMenu,
  setPageIndex,
}: DataManagementFilesMenuProps) => {
  const { selectedFiles, project } = useFiles(state => state);
  const projectId = useExplore(state => state.projectId);

  const [sorting, setSorting] = useState<SortingState>([]);
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [globalFilter, setGlobalFilter] = useState('');
  const [parentNodeId, setParentNodeId] = useState<string | null>(null);
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: NODES_TABLE_DEFAULT_PAGE_INDEX,
    pageSize: NODES_TABLE_PAGE_SIZE_12,
  });

  const { systemNodes, pageCount } = useSystemNodes({
    nodeKinds: [SystemNodeKindInput.FolderNode, SystemNodeKindInput.FileNode],
    pageIndex,
    pageSize,
    parentNodeId: parentNodeId ?? null,
    searchTerm: globalFilter ?? undefined,
  });

  const createLinkNodes = useMutation({
    mutationFn: (variables: CreateLinkNodesBySourceNodeIdsMutationVariables) => {
      return request(CREATE_LINK_NODES_BY_SOURCE_NODE_IDS, variables);
    },
  });

  const handleChangeFilter = useCallback((text: string) => {
    setGlobalFilter(text);
    setPagination(prev => ({ ...prev, pageIndex: NODES_TABLE_DEFAULT_PAGE_INDEX }));
  }, []);

  const handleEnterFolder = useCallback((folderNodeId: string | null) => {
    setParentNodeId(folderNodeId);
    setPagination(prev => ({ ...prev, pageIndex: NODES_TABLE_DEFAULT_PAGE_INDEX }));
  }, []);

  const handleRemoveFile = (id: string) => {
    setRowSelection(prevState => {
      const newState = { ...prevState };
      delete newState[id];
      return newState;
    });
  };

  const handleNavigateBreadcrumbs = useCallback((folderNodeId: string | null) => {
    setParentNodeId(folderNodeId);
  }, []);

  const groupByParentId = (nodes: SystemNode[]) => {
    const grouped = new Map();

    for (const node of nodes) {
      const parentId = node.parentId ?? null;
      if (!grouped.has(parentId)) {
        grouped.set(parentId, { sourceNodeIds: [], parentId: project?.userFilesRootFolderNodeId });
      }
      grouped.get(parentId).sourceNodeIds.push(node.id);
    }

    return [...grouped.values()];
  };

  const handleAddToProject = async () => {
    const groupedLinkNodes = groupByParentId(selectedFiles);

    const promises = groupedLinkNodes.map(node =>
      createLinkNodes.mutateAsync({
        sourceNodeIds: node.sourceNodeIds,
        parentNodeId: node.parentId,
        dependants: [
          {
            domainName: SystemNodeDependantDomain.Project,
            entityId: projectId as string,
          },
        ],
      }),
    );

    try {
      const results = await Promise.all(promises);
      setPageIndex(NODES_TABLE_DEFAULT_PAGE_INDEX);
      queryClient.invalidateQueries(['LIST_SYSTEM_NODES_BY_PARENT_NODE_ID']);
      toast({
        type: 'success',
        message: `Successfully added link file${selectedFiles.length > 1 ? 's' : ''}!`,
        lifespan: 5000,
      });
      closeMenu();
      return results;
    } catch (error) {
      closeMenu();
      throw error;
    }
  };

  // Store selected files
  useEffect(() => {
    const selectedNodeIds = Object.keys(rowSelection);
    const newSelectedNodes = systemNodes.filter(node => selectedNodeIds.includes(node.id));
    setSelectedFiles(newSelectedNodes, selectedNodeIds);
  }, [rowSelection, systemNodes]);

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        enableGlobalFilter: true,
        id: 'name',
        header: () => <label className="flex items-center gap-3">name</label>,
        cell: ({ row, getValue }) => {
          const handleClick = () => {
            if (row.original.type === 'folder') handleEnterFolder(row.original.id);
          };

          return (
            <div className="flex items-center gap-3 pr-2">
              <CheckBox
                checked={row.getIsSelected()}
                className="cursor-pointer"
                disabled={!row.getCanSelect()}
                onChange={row.getToggleSelectedHandler()}
              />

              <div className={cn(`${getNodeIcon(row.original.extension)} text-5`)} />
              <label
                className={cn(
                  'flex items-center gap-3 cursor-pointer whitespace-nowrap max-w-[220px]	overflow-hidden',
                )}
                onClick={handleClick}
              >
                {getValue()}
              </label>
            </div>
          );
        },
      }),
      columnHelper.accessor('extension', {
        id: 'fileType',
        header: () => <label className="flex items-center gap-3">File Type</label>,
        cell: ({ getValue }) => <span>{getValue()}</span>,
      }),
    ],

    [handleEnterFolder],
  );

  const table = useReactTable({
    columns,
    data: systemNodes,
    enableRowSelection: true,
    getCoreRowModel: getCoreRowModel(),
    getRowId: row => row.id,
    getSortedRowModel: getSortedRowModel(),
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    pageCount: pageCount as number,
    onPaginationChange: setPagination,
    manualFiltering: true,
    manualPagination: true,
    state: { rowSelection, sorting, pagination: { pageIndex, pageSize } },
  });

  return (
    <Modal className="w-[995px] flex">
      <></>
      <div className="flex flex-1 flex-col px-6 pb-6 pt-7">
        <div className="flex justify-between">
          <p className="pb-3 color-neutral-800 typo-text-m">Link files from Data Management</p>
        </div>

        <div className={cn('flex flex-row gap-3')}>
          <div className={cn('b-1 b-neutral-400 rounded-lg b-solid p-3 h-[645px] w-[609px]')}>
            <Input
              onChange={handleChangeFilter}
              placeholder="Search for folders"
              value={globalFilter}
            />

            <div className="m-y-3">
              <Breadcrumb
                divider={
                  <div
                    className={cn(
                      'i-skand-dropdownarrow',
                      'text-neutral-400',
                      'text-2',
                      'rotate-270',
                      'mx-1',
                    )}
                  />
                }
                onNavigate={handleNavigateBreadcrumbs}
                parentNodeId={parentNodeId}
              />
            </div>

            <TableDense
              contentRowClassName="grid grid-cols-2 h-10"
              headerRowClassName="grid grid-cols-2 p-r-2"
              table={table}
              tableBodyClassName={cn(
                'grid overflow-y-auto auto-rows-max m-y-1',
                'scrollbar scrollbar-rounded',
                'h-[480px]',
              )}
            />

            <div className="flex justify-center b-t-1 b-t-neutral-300 pt-3">
              <Pagination
                currentPage={table.getState().pagination.pageIndex + 1}
                isNextPageDisabled={!table.getCanNextPage()}
                isPreviewPageDisabled={!table.getCanPreviousPage()}
                onPageChange={newPage => table.setPageIndex(newPage - 1)}
                totalPages={table.getPageCount()}
              />
            </div>
          </div>
          <SelectedFiles
            handleRemoveAll={() => setRowSelection({})}
            handleRemoveFile={handleRemoveFile}
          />
        </div>

        <div className="mt-6 w-full flex gap-2">
          <Button className="w-full cursor-pointer" onClick={closeMenu} size="s">
            Cancel
          </Button>
          <Button
            className="w-full cursor-pointer"
            disabled={!selectedFiles.length}
            filled
            onClick={handleAddToProject}
            primary
            size="s"
          >
            Add to project
          </Button>
        </div>
      </div>
    </Modal>
  );
};
