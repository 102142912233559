import {
  PolicyActionTypeInput,
  PolicyObjectTypeInput,
  PolicySubjectTypeInput,
} from '@/graphql/codegen/graphql';
import { LIST_PERMISSION_POLICIES_WITH_ACCOUNT_CONTEXT } from '@/graphql/queries';
import { request } from '@/graphql/request';
import { isEmpty } from '@/utils/empty';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

const getQueryKey = (shareLinkId: string | null) => [
  'LIST_SYSTEM_NODE_PERMISSION_POLICIES',
  shareLinkId,
  PolicySubjectTypeInput.ShareLink,
  PolicyObjectTypeInput.SystemNode,
  PolicyActionTypeInput.Read,
  null,
];

export const useFetchShareLinkSystemNodePermissions = (shareLinkId: string | null) => {
  const response = useQuery({
    enabled: !!shareLinkId,
    queryFn: () =>
      request(LIST_PERMISSION_POLICIES_WITH_ACCOUNT_CONTEXT, {
        subjectId: shareLinkId,
        subjectType: PolicySubjectTypeInput.ShareLink,
        objectType: PolicyObjectTypeInput.SystemNode,
        actionType: PolicyActionTypeInput.Read,
        objectId: null,
      }),
    queryKey: getQueryKey(shareLinkId),
  });

  const hasSystemNodePermissions = useMemo(() => {
    if (
      !isEmpty(response.data) &&
      !isEmpty(response.data.listPermissionPoliciesWithAccountContext)
    ) {
      return response.data.listPermissionPoliciesWithAccountContext.length > 0;
    }
    return false;
  }, [response.data]);

  return {
    response,
    hasSystemNodePermissions,
  };
};

useFetchShareLinkSystemNodePermissions.getQueryKey = getQueryKey;
