import { Progress } from '@skand/ui';
import { Uploader, UploadSessionStage } from '@skand/uploader';
import { FAILURE_STAGES, UPLOADED_STAGES, UPLOADING_STAGES } from '../../Upload/constants';
import { formatBytes } from '../../Upload/utils';

interface ProgressViewProps {
  stage: UploadSessionStage;
  uploader: Uploader;
}

export const ProgressView = ({ stage, uploader }: ProgressViewProps) => {
  const { error, uploadProgress } = uploader.useUploader();

  if (error) return <span className="color-alert-400 typo-text-s">Upload failed</span>;

  const speedMeta = formatBytes(uploadProgress.averageSpeed, 1);
  const speed = `${speedMeta.amount} ${speedMeta.unit}/s`;

  if (UPLOADING_STAGES.includes(stage))
    return (
      <div className="flex items-center gap-2">
        <span className="color-neutral-500 typo-text-s">Uploading</span>
        <Progress className="w-80px" progress={uploadProgress.proportion} />
      </div>
    );

  if (UPLOADED_STAGES.includes(stage)) {
    return (
      <div className="flex items-center gap-2">
        <span className="color-success-400 typo-text-s">Upload complete</span>
      </div>
    );
  }

  if (FAILURE_STAGES.includes(stage)) {
    return (
      <div className="flex items-center gap-2">
        <span className="color-neutral-500 typo-text-s">Uploading</span>
        <Progress className="w-80px" progress={uploadProgress.proportion} />
        <span className="color-neutral-500 typo-text-s">{speed}</span>
      </div>
    );
  }
};
