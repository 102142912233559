import { initializeExplore } from '@/stores/explore';
import { useViewer } from '@/stores/viewer';
import { GAME_MODE } from '@/utils/split';
import { useTreatments } from '@splitsoftware/splitio-react';
import { useEffect } from 'react';
import { GameScene } from './GameScene';
import { ProjectExploreScene } from './ProjectExploreScene';

export const ExplorePage = () => {
  const viewer3DSettings = useViewer(state => state.viewer3DSettings);
  const treatment = useTreatments([GAME_MODE]);
  const gameModeFlag = treatment[GAME_MODE].treatment === 'on';

  useEffect(() => {
    initializeExplore();
  }, []);

  return gameModeFlag && viewer3DSettings.enabledGameMode ? <GameScene /> : <ProjectExploreScene />;
};
