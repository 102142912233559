import { FileNode, FolderNode, LinkNode } from '@/graphql/codegen/graphql';

export const getFileType = (node: FileNode | FolderNode | LinkNode) => {
  switch (node.__typename) {
    case 'FolderNode':
      return 'folder';
    case 'FileNode':
      return 'file';
    case 'LinkNode':
      return node.sourceNode?.__typename === 'FileNode' ? 'file' : 'folder';
    default:
      return 'folder';
  }
};
