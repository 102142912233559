import { useFetchSystemNodeChainV2 } from '@/hooks/useFetchSystemNodeChainV2';
import { SystemNode } from '@/stores/systemNodes';
import { cn } from '@/utils/classname';
import { useEffect, useMemo, useState } from 'react';

export interface BreadcrumbProps {
  onChangeParentNodeId: (parentNodeId: null | string) => void;
  parentNodeId: null | string;
  setParentNodeId: (value: string) => void;
}

export const Breadcrumb = ({
  onChangeParentNodeId,
  parentNodeId,
  setParentNodeId,
}: BreadcrumbProps) => {
  const [accessedByLinkNodeId, setAccessedByLinkNodeId] = useState<string | null>(null);
  const chain = useFetchSystemNodeChainV2(parentNodeId ?? undefined, accessedByLinkNodeId);

  const reversed = useMemo(() => {
    return parentNodeId ? (chain ? [...chain].reverse() : []) : [];
  }, [parentNodeId, chain]);

  const isIdAtSameOrAboveIndex = (
    array: SystemNode[],
    compareId: string | null,
    checkId: string,
  ) => {
    const compareIndex = array.findIndex(item => item.id === compareId);
    const checkIndex = array.findIndex(item => item.id === checkId);
    return checkIndex <= compareIndex;
  };

  useEffect(() => {
    if (chain) {
      const linkNode = chain.find(node => node.kind === 'LinkNode');
      setAccessedByLinkNodeId(linkNode ? linkNode.id : null);
    }
  }, [chain, reversed]);

  const handleAccessedByLinkNodeId = (node: SystemNode) => {
    if (isIdAtSameOrAboveIndex(reversed, accessedByLinkNodeId, node.id)) {
      setAccessedByLinkNodeId(null);
    }
  };

  return (
    <div className={cn('mt-3 flex items-center overflow-x-auto overflow-y-hidden')}>
      {reversed?.map((item, index) => {
        return (
          <span
            className={cn(
              'cursor-pointer typo-text-s pb-2',
              index === reversed.length - 1 ? 'color-neutral-500' : 'color-neutral-400 underline',
            )}
            key={item?.id}
            onClick={() => {
              onChangeParentNodeId(item.sourceNodeId ? item.sourceNodeId : (item?.id as string));
              setParentNodeId(item?.id);
              handleAccessedByLinkNodeId(item);
            }}
          >
            {index === 0 ? (
              <span className="whitespace-nowrap hover:underline">All folders</span>
            ) : (
              <div className={cn('flex items-center')}>
                <div
                  className={cn(
                    'i-skand-dropdownarrow',
                    'text-neutral-400',
                    'text-2',
                    'rotate-270',
                    'mx-1',
                  )}
                />

                <span className="whitespace-nowrap hover:underline">{item.name}</span>
              </div>
            )}
          </span>
        );
      })}
    </div>
  );
};
