import { DrawContextMenu } from '@/components/DrawContextMenu';
import { IconButton, MinimapIcon } from '@/components/IconButton';
import { PhotoContextMenu } from '@/components/PhotoContextMenu';
import { InputSlider } from '@/components/SceneTab/Menus/InputSlider';
import { imageLoadingOverlayStore } from '@/stores/imageLoadingOverlay';
import { setViewer3DSettings, stopViewerScreenshot, useViewer } from '@/stores/viewer';
import { cn } from '@/utils/classname';
import { IMAGE_VIEWER_SORT } from '@/utils/split';
import { Rnd } from '@skand/ui';
import { Panorama, toCartesian, toCartographic } from '@skand/viewer-component-v2';
import { useTreatments } from '@splitsoftware/splitio-react';
import { useAtomValue } from 'jotai';
import { useEffect, useState } from 'react';
import { Vector2 } from 'three';
import { PredictorMenu } from '../../PredictorMenu';
import { ActionButtons } from './ActionButtons';
import { InfoBar } from './InfoBar/InfoBar';
import { Minimap } from './Minimap';
import { ScreenshotTool } from './ScreenshotTool';
import { StatisticsMenu } from './StatisticsMenu';
import { StatisticsMonitor } from './StatisticsMonitor';

export const Overlay = () => {
  const treatment = useTreatments([IMAGE_VIEWER_SORT]);
  const imageViewerSortFlag = treatment[IMAGE_VIEWER_SORT].treatment === 'on';

  const api3D = useViewer(state => state.api3D);
  const contextTarget = useViewer(state => state.contextTarget);
  const targetPhoto = useViewer(state => state.targetPhoto);
  const enabledScreenshot = useViewer(state => state.enabledScreenshot);
  const enabledPhotoOverlay = useViewer(state => state.enabledPhotoOverlay);
  const enabledPanoramaWalkthrough = useViewer(state => state.enabledPanoramaWalkthrough);
  const enabledImageRibbon = useViewer(state => state.enabledImageRibbon);
  const visiblePhotoGroups = useViewer(state => state.visiblePhotoGroups);
  const showOpacity = enabledPhotoOverlay || enabledPanoramaWalkthrough;
  const viewer3DSettings = useViewer(state => state.viewer3DSettings);
  const enabledDraw3D = useViewer(state => state.enabledDraw3D);
  const viewer2DSettings = useViewer(state => state.viewer2DSettings);
  const screenshotCallback = useViewer(state => state.screenshotCallback);

  const [hoverContextMenuPosition, setHoverMenuPosition] = useState<Vector2 | null>(null);
  const [sortMarkerPosition, setSortMarkerPosition] = useState<Vector2 | null>(null);
  const [enableDrawContextMenu, setEnableDrawContextMenu] = useState<boolean>(false);
  const [removeVertex, setRemoveVertex] = useState<() => void>(() => {});
  const [enabledMinimap, setEnabledMinimap] = useState(false);
  const [minimapTooltip, setMinimapTooltip] = useState(false);
  const [enabledPredictor, setEnabledPredictor] = useState(false);

  // Register context menu handler
  useEffect(() => {
    api3D?.draw.setContextMenuHandler((position, removeVertex) => {
      setEnableDrawContextMenu(!!position);
      setRemoveVertex(() => removeVertex);
    });
  }, [api3D]);

  // Listen for changes to the current widget hover target
  useEffect(() => {
    let frame = 0;
    const update = () => {
      frame = requestAnimationFrame(update);

      if (contextTarget?.widget && api3D) {
        const worldPosition = contextTarget.widget.getPosition();

        // Adjust based on camera height for panoramic photos
        const model = contextTarget.widget.getModel();
        if (model instanceof Panorama) {
          const cartographic = toCartographic(worldPosition);
          cartographic.height -= model.getCameraHeight(); // Approximate distance to floor
          worldPosition.copy(toCartesian(cartographic));
        }

        const position = api3D.utility.projectToScreen(worldPosition);
        setHoverMenuPosition(prev =>
          !prev || !position || !prev.equals(position) ? position : prev,
        );
      } else {
        setHoverMenuPosition(null);
      }

      if (
        api3D &&
        enabledImageRibbon &&
        viewer2DSettings.showSortPoint &&
        visiblePhotoGroups.size > 0 &&
        !enabledPanoramaWalkthrough &&
        !enabledDraw3D
      ) {
        const position = api3D.utility.projectToScreen(viewer2DSettings.sortPoint);
        setSortMarkerPosition(prev =>
          !prev || !position || !prev.equals(position) ? position : prev,
        );
      } else {
        setSortMarkerPosition(null);
      }
    };
    update();
    return () => cancelAnimationFrame(frame);
  }, [
    api3D,
    contextTarget,
    enabledDraw3D,
    enabledImageRibbon,
    enabledPanoramaWalkthrough,
    viewer2DSettings.showSortPoint,
    viewer2DSettings.sortPoint,
    visiblePhotoGroups.size,
  ]);

  // Handler for closing the minimap
  const handleDisableMinimap = () => {
    setEnabledMinimap(false);
    setMinimapTooltip(false);
  };

  const isImageLoadingOverlayOpen = useAtomValue(imageLoadingOverlayStore.isOpen);

  return (
    <div
      className={cn(
        'h-full',
        'w-full',
        'left-0',
        'top-0',
        'absolute',
        !isImageLoadingOverlayOpen && 'z-2', // otherwise overlay shows on top of image loading overlay
        'pointer-events-none',
      )}
    >
      {enableDrawContextMenu && api3D && (
        <DrawContextMenu
          canClose={api3D.draw.canClosePolygon()}
          closeOnClick={() => api3D.draw.closePolygon()}
          doneOnClick={() => api3D.draw.submit()}
          trashOnClick={removeVertex}
          undoOnClick={() => api3D.draw.undo()}
        />
      )}
      {hoverContextMenuPosition && contextTarget && (
        <PhotoContextMenu photo={contextTarget} position={hoverContextMenuPosition} />
      )}
      {imageViewerSortFlag && sortMarkerPosition && (
        <div
          className="i-skand-closesmall text-20px color-alert-300"
          style={{
            position: 'absolute',
            left: sortMarkerPosition.x,
            top: sortMarkerPosition.y,
            transform: 'translate(-50%, -50%)',
          }}
        />
      )}
      {enabledMinimap && <Minimap disableMinimap={handleDisableMinimap} />}
      <ActionButtons
        enabledPredictor={enabledPredictor}
        togglePredictor={() => setEnabledPredictor(v => !v)}
      />
      <InfoBar />
      {enabledPredictor && targetPhoto && (
        <PredictorMenu handleClose={() => setEnabledPredictor(false)} photo={targetPhoto} />
      )}
      {!enabledMinimap && (
        <IconButton
          buttonIcon={<MinimapIcon />}
          buttonState="default"
          className={cn(
            'absolute',
            'right-0',
            'bottom-0',
            'pointer-events-auto',
            'rounded-tr-0',
            'rounded-bl-0',
            'rounded-br-0',
            minimapTooltip ? 'bg-neutral-400' : 'bg-transparent',
          )}
          onClick={() => setEnabledMinimap(true)}
          onMouseEnter={() => setMinimapTooltip(true)}
          onMouseLeave={() => setMinimapTooltip(false)}
        />
      )}
      {enabledScreenshot && (
        <ScreenshotTool onCancel={stopViewerScreenshot} onScreenshot={screenshotCallback} />
      )}
      {showOpacity && (
        <div className="ml-14px flex flex-row gap-3">
          <span className="mt-3 typo-text-small-em">Opacity</span>
          <InputSlider
            className={cn('w-206px', 'pointer-events-auto')}
            max={1}
            min={0}
            setValue={value => setViewer3DSettings({ overlayOpacity: value })}
            step={0.01}
            value={viewer3DSettings.overlayOpacity}
          />
        </div>
      )}
      {viewer3DSettings.enabledStatistics && (
        <Rnd
          className={cn(
            'w-240px',
            'h-44',
            'p-x-2.5',
            'p-b-2.5',
            'b-rounded',
            'b-solid',
            'b-neutral-4',
            'bg-neutral-100',
            'pointer-events-auto',
          )}
          disableResizeX
          disableResizeY
        >
          <StatisticsMenu />
          <StatisticsMonitor />
        </Rnd>
      )}
    </div>
  );
};
