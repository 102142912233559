import { ANALYTICS_EVENT_OBJECT } from '@/constants/analytics';
import { PROJECT_ROOT } from '@/constants/paths';
import { useAccountV2ByContext } from '@/hooks/useAccountV2ByContext';
import { useEntitlements } from '@/hooks/useEntitlements';
import { useViewer } from '@/stores/viewer';
import {
  EXPLORE_FILES_TAB,
  MANUAL_ANNOTATION_PROCESS_FEATURE_FLAG,
  PANORAMA_PROCESS_FEATURE_FLAG,
} from '@/utils/split';
import { useTreatments } from '@splitsoftware/splitio-react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { TAB_ITEMS, TabKey, useLayout } from '../stores/layout';
import { cn } from '../utils/classname';

interface ItemProps {
  name: TabKey;
  title: string;
  iconClass: string;
  isDisabled?: boolean;
  dataAnalyticsEventObject?: string;
}

export const Item = ({
  name,
  title,
  iconClass,
  isDisabled,
  dataAnalyticsEventObject,
}: ItemProps) => {
  const tab = useLayout(state => state.tab);
  const switchTab = useLayout(state => state.switch);
  const hideLeftSideBarI = useLayout(state => state.hideLeftSideBarI);
  const isShowingLeftSideBarI = useLayout(state => state.isShowingLeftSideBarI);
  const showLeftSideBarI = useLayout(state => state.showLeftSideBarI);
  const isActive = name === tab;
  const hideLeftSideBarII = useLayout(state => state.hideLeftSideBarII);

  const layerGroups = useViewer(state => state.layerGroups);
  const layers = useViewer(state => state.layers);
  const annotationGroups = useViewer(state => state.annotationGroups);
  const photo2DGroups = useViewer(state => state.photo2DGroups);
  const panoramaGroups = useViewer(state => state.panoramaGroups);

  const sceneEntities = [
    ...layerGroups,
    ...layers,
    ...annotationGroups,
    ...photo2DGroups,
    ...panoramaGroups,
  ];

  useEffect(() => {
    if (!sceneEntities.length) {
      switchTab('files');
    } else {
      switchTab('scene');
    }
  }, [sceneEntities.length, switchTab]);

  const toggleTabs = () => {
    hideLeftSideBarII();
    if (isActive && isShowingLeftSideBarI) {
      hideLeftSideBarI();
    }
    if (!isShowingLeftSideBarI) {
      showLeftSideBarI();
    }

    switchTab(name);
  };

  return (
    <div
      className={cn(
        'cursor-pointer',
        'flex-col',
        'flex-nowrap',
        'flex',
        'h-62px',
        'items-center',
        'justify-center',
        'rounded-l-2',
        isActive && 'bg-neutral-100',
      )}
      data-analytics-event-object={dataAnalyticsEventObject}
      onClick={toggleTabs}
    >
      <div
        className={cn(
          'text-18px',
          iconClass,
          isDisabled ? 'color-neutral-300' : isActive ? 'color-neutral-800' : 'color-neutral-600',
        )}
      />
      <span
        className={cn(
          'm-t-2',
          'p-x-1',
          'typo-text-xxs',
          isDisabled ? 'color-neutral-300' : isActive ? 'color-neutral-800' : 'color-neutral-600',
        )}
      >
        {title}
      </span>
    </div>
  );
};

export const LeftTabBar = ({ isDisabled }: { isDisabled?: boolean }) => {
  const tabs = useLayout(state => state.tabs);
  const treatment = useTreatments([
    MANUAL_ANNOTATION_PROCESS_FEATURE_FLAG,
    PANORAMA_PROCESS_FEATURE_FLAG,
    EXPLORE_FILES_TAB,
  ]);
  const processTabFlag =
    treatment[MANUAL_ANNOTATION_PROCESS_FEATURE_FLAG].treatment === 'on' ||
    treatment[PANORAMA_PROCESS_FEATURE_FLAG].treatment === 'on';
  const filesTabFlag = treatment[EXPLORE_FILES_TAB].treatment === 'on';

  const hasWhiteLabelling = useAccountV2ByContext();
  const entitlement = useEntitlements()?.feature('branding');
  const hasBannerSetUp = entitlement?.enabled && hasWhiteLabelling;

  useEffect(() => {
    if (filesTabFlag) {
      useLayout.setState({ tab: TAB_ITEMS[0].key });
    } else {
      useLayout.setState({ tab: TAB_ITEMS[1].key });
    }
  }, [filesTabFlag]);

  return (
    <div
      className={cn(
        ' flex flex-grow flex-col justify-between overflow-hidden',
        hasBannerSetUp ? 'm-t-33px' : 'm-t-108px',
      )}
    >
      <div className="ml-4px flex flex-col">
        {tabs.map((item, index) => {
          if (
            !item.hide &&
            !(item.key === 'process' && !processTabFlag) &&
            !(item.key === 'files' && !filesTabFlag)
          )
            return (
              <Item
                dataAnalyticsEventObject={item.dataAnalyticsEventObject}
                iconClass={item.iconClass}
                isDisabled={isDisabled}
                key={`item-${index}`}
                name={item.key}
                title={item.title}
              />
            );
        })}
      </div>
      <Link className="decoration-none" to={PROJECT_ROOT}>
        <div
          className={cn(
            'cursor-pointer',
            'flex-col',
            'flex-nowrap',
            'flex',
            'h-62px',
            'h-62px',
            'items-center',
            'justify-center',
            'rounded-l-2',
            isDisabled ? 'color-neutral-300' : 'color-neutral-600',
          )}
          data-analytics-event-object={ANALYTICS_EVENT_OBJECT.CLOSE_PROJECT}
        >
          <div
            className={cn(
              'text-14px i-skand-close transform-rotate-90 ',
              isDisabled ? 'color-neutral-300' : 'color-neutral-600',
            )}
          />
          <span className={cn('m-t-2', 'p-x-1', 'typo-text-xxs ')}>Close Project</span>
        </div>
      </Link>
    </div>
  );
};
