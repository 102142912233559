import { Button, EmptyDesc, EmptyRoot, EmptyTitle, EmptyToast } from '@skand/ui';
import { ReactNode } from 'react';

interface EmptyResourceMessageProps {
  emptyResourceContent: {
    actionButton?: () => void;
    emptyDesc?: string | JSX.Element;
    emptyTitle: string | JSX.Element;
    emptyTitleClassName?: string;
    buttonLabel?: string;
    emptyToast?: string;
    emptyDescNode?: ReactNode;
    dataAnalyticsEventObject?: string;
    emptyDescStyles?: string;
  };
}

export const EmptyResourceMessage = ({
  emptyResourceContent: {
    actionButton,
    emptyDesc,
    emptyTitle,
    emptyTitleClassName,
    buttonLabel,
    emptyToast,
    emptyDescNode,
    dataAnalyticsEventObject,
    emptyDescStyles,
  },
}: EmptyResourceMessageProps) => {
  return (
    <EmptyRoot className="h-full">
      <div className="flex flex-col items-center gap-3">
        {emptyToast && <EmptyToast message={emptyToast} />}
        <EmptyTitle className={emptyTitleClassName}>{emptyTitle}</EmptyTitle>
        {emptyDescNode ?? emptyDescNode}
        <EmptyDesc className={emptyDescStyles}>{emptyDesc}</EmptyDesc>
        {buttonLabel && (
          <Button
            className="w-full cursor-pointer"
            data-analytics-event-object={dataAnalyticsEventObject}
            filled
            onClick={actionButton}
            primary
            size="s"
          >
            {buttonLabel}
          </Button>
        )}
      </div>
    </EmptyRoot>
  );
};
