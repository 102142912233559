import { queryClient } from '@/graphql/client';
import { ListRenderObjectsByIdsQuery } from '@/graphql/codegen/graphql';
import { LIST_RENDER_OBJECTS_BY_IDS } from '@/graphql/queries';
import { request } from '@/graphql/request';
import { PhotoGroup, useViewer } from '@/stores/viewer';
import { createCameraModel, createPanorama, createPhoto2D } from '@/utils/transformers';
import { useCallback } from 'react';

const getQueryKey = (renderObjectIds: string[]) => ['LIST_RENDER_OBJECTS_BY_IDS', renderObjectIds];

const fetchPhotos = (groups: PhotoGroup[]) => {
  return groups.map(async group => {
    group.loadState = 'fetching';
    const queryResult = await queryClient.fetchQuery({
      queryFn: () =>
        request(LIST_RENDER_OBJECTS_BY_IDS, { renderObjectIds: [group.renderObjectId] }),
      queryKey: getQueryKey([group.renderObjectId]),
    });
    group.loadState = 'ready';
    return queryResult;
  });
};

export const useFetchPhotos = () => {
  const photo2DGroups = useViewer(state => state.photo2DGroups);
  const panoramaGroups = useViewer(state => state.panoramaGroups);
  const api3D = useViewer(state => state.api3D);

  const createPhotos = useCallback(
    async (queryResult: ListRenderObjectsByIdsQuery, group: PhotoGroup) => {
      const queryGroup = queryResult.listRenderObjectByIds?.[0];
      const newGroup: PhotoGroup = { ...group, photos: [] };

      if (queryGroup?.__typename === 'PanoramicRenderObject') {
        for (const queryPhoto of queryGroup.panoramicImages ?? []) {
          if (!queryPhoto || !api3D) continue;
          const newPhoto = await createPanorama(queryPhoto, newGroup, api3D);
          if (newPhoto) newGroup.photos.push(newPhoto);
        }
      }

      if (queryGroup?.__typename === 'ImageProjectionRenderObject') {
        for (const queryPhoto of queryGroup.images ?? []) {
          if (!queryPhoto || !api3D) continue;
          const newPhoto = await createPhoto2D(queryPhoto, newGroup, api3D);
          if (newPhoto) newGroup.photos.push(newPhoto);
        }

        for (const queryCamera of queryGroup.cameraModels ?? []) {
          if (!queryCamera) continue;
          const newCamera = createCameraModel(queryCamera);
          if (newCamera) newGroup.cameraModels.set(newCamera.id, newCamera);
        }
      }

      return newGroup;
    },
    [api3D],
  );

  const fetch = useCallback(() => {
    const groups = [...panoramaGroups, ...photo2DGroups];
    return fetchPhotos(groups).map(async (query, i) => {
      const queryResult = await query;
      const newGroup = await createPhotos(queryResult, groups[i]);
      return newGroup;
    });
  }, [createPhotos, panoramaGroups, photo2DGroups]);

  return { fetch };
};

useFetchPhotos.getQueryKey = getQueryKey;
