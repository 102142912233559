import { FileGroupIcon } from '@/components/IconButton';
import { useFiles } from '@/stores/files';
import { cn } from '@/utils/classname';
import { getNodeIcon } from '@/utils/nodeIcons';
import { Button } from '@skand/ui';
import { Breadcrumb } from './Breadcrumb';
export interface SelectedFilesProps {
  handleRemoveFile: (id: string) => void;
  handleRemoveAll: () => void;
}

export const SelectedFiles = ({ handleRemoveFile, handleRemoveAll }: SelectedFilesProps) => {
  const { selectedFiles } = useFiles(state => state);

  return (
    <div
      className={cn('b-1 b-neutral-400 rounded-lg b-solid p-3 h-[645px] w-[326px] flex flex-col')}
    >
      <p className="pb-3 color-neutral-800 typo-text-m">
        {selectedFiles.length} Selected file{selectedFiles.length > 1 && 's'}
      </p>
      <div className={cn('flex-1 overflow-y-scroll')}>
        {selectedFiles.length ? (
          selectedFiles.map(selectedFile => (
            <div className="mb-3 h-[38px] flex flex-row justify-between" key={selectedFile.id}>
              <div className={cn('flex flex-col gap-1')}>
                <div className={cn('flex flex-row gap-2')}>
                  <div
                    className={cn(`${getNodeIcon(selectedFile.extension)} text-5 text-neutral-600`)}
                  />
                  <p className="max-w-[250px] overflow-hidden whitespace-nowrap color-neutral-800 typo-text-s">
                    {selectedFile.name}
                  </p>
                </div>
                <Breadcrumb
                  initialRoot="User Files"
                  onNavigate={() => {}}
                  parentNodeId={selectedFile.parentId}
                  previewMode
                  size="small"
                />
              </div>
              <div
                className="i-skand-close cursor-pointer text-3 color-neutral-400"
                onClick={() => handleRemoveFile(selectedFile.id)}
              />
            </div>
          ))
        ) : (
          <div className={cn('flex flex-col items-center gap-3 justify-center h-full')}>
            <FileGroupIcon />
            <p className="color-neutral-500 typo-text-l">Select files</p>
            <p className="color-neutral-500 typo-text-s">
              You can select multiple files to add to your project.
            </p>
          </div>
        )}
      </div>
      {selectedFiles.length && (
        <Button className="w-full cursor-pointer" onClick={handleRemoveAll} size="s">
          Unselect all
        </Button>
      )}
    </div>
  );
};
