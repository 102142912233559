import { CESIUM_API_KEY, GOOGLE_MAP_API_KEY } from '@/constants/env';
import { setViewerAPI3D } from '@/stores/viewer';
import { Viewer } from '@skand/viewer-component-v2';
import { Overlay } from './Overlay';

export const Viewer3D = () => {
  return (
    <div className="relative h-full">
      <Overlay />
      <Viewer
        cesiumToken={CESIUM_API_KEY}
        gameServerUrl="https://explore-game-server.onrender.com"
        googleToken={GOOGLE_MAP_API_KEY}
        ref={setViewerAPI3D}
      />
    </div>
  );
};
