import { cn } from '@skand/ui';

export const DeathCard = () => {
  return (
    <div
      className={cn(
        'relative',
        'display-flex',
        'top-50%',
        'transform-translate-y--50%',
        'justify-center',
        'typo-heading-2',
        'color-[#ff3300]',
      )}
    >
      YOU DIED. RESPAWNING...
    </div>
  );
};
