import { Checkbox } from '@/components/Checkbox';
import { DraggableMenu } from '@/components/DraggableMenu';
import { Controls3DIcon, FindIcon } from '@/components/IconButton';
import { Label } from '@/components/Label';
import { Layer, useViewer } from '@/stores/viewer';
import { persist } from '@/utils/Persist';
import { cn } from '@/utils/classname';
import { search } from '@/utils/search';
import { Button, Input, Toast } from '@skand/ui';
import { Model3D, ModelNode } from '@skand/viewer-component-v2';
import { MouseEvent, ReactNode, useEffect, useMemo, useState } from 'react';

export interface ClippingMenuProps {
  layer: Layer;
  closeMenu: () => void;
}
export interface IconButtonProps {
  label: string;
  icon?: ReactNode;
  onClick: () => void;
}

export const ClippingMenu = ({ closeMenu, layer }: ClippingMenuProps) => {
  const api3D = useViewer(state => state.api3D);
  const clippingBoxTools = useViewer(state => state.clippingBoxTools);
  const layers = useViewer(state => state.layers);

  const [searchKey, setSearchKey] = useState('');
  const [enabledInfoTooltip, setEnabledInfoTooltip] = useState(false);
  const [layerNameTooltip, setLayerNameTooltip] = useState<null | string>(null);
  const [labelPosition, setLabelPosition] = useState({ x: 0, y: 0 });
  const [menuPosition, setMenuPosition] = useState({ x: 390, y: 0 });

  // Target model and clipping box
  const model = (layer.sceneNode as ModelNode).getModel() as Model3D;
  const clippingBox = clippingBoxTools.get(layer.id);

  // Get all clippable layers
  const clippable = useMemo(
    () =>
      layers.filter(query => {
        if (query !== layer && query.sceneNode instanceof ModelNode) {
          const queryModel = query.sceneNode.getModel();
          if (queryModel instanceof Model3D) {
            return true;
          }
        }
        return false;
      }),
    [layer, layers],
  );

  // Filter layers by search key
  const filtered = useMemo(
    () => clippable.filter(query => search(query.name, searchKey)),
    [clippable, searchKey],
  );

  const [enabled, setEnabled] = useState(true);
  const [controls, setControls] = useState(true);
  const [group, setGroup] = useState<Layer[]>([]);

  // Hide controls on dismount
  useEffect(() => {
    return () => {
      clippingBox?.hide();
    };
  }, [clippingBox]);

  // Initialize clipping tool
  useEffect(() => {
    if (api3D) {
      useViewer.setState(prev => {
        const clippingBoxTools = new Map(prev.clippingBoxTools);
        if (!prev.clippingBoxTools.get(layer.id)) {
          const clippingBox = api3D.clipping.createBoxTool();
          clippingBox.addTarget(model);
          clippingBox.reset();
          clippingBoxTools.set(layer.id, clippingBox);
        }
        return { clippingBoxTools };
      });
    }
  }, [layer, api3D, model]);

  // Toggle clipping
  useEffect(() => {
    if (enabled) {
      clippingBox?.start(() => {
        // Update persisted clipping volume
        const persistClippingBoxes = persist.get('clippingBoxes') ?? {};
        persistClippingBoxes[layer.id] = clippingBox.getOBB();
        persist.set('clippingBoxes', persistClippingBoxes);
      });
    } else {
      clippingBox?.stop();
    }
  }, [clippingBox, enabled, model, layer]);

  // Toggle 3D controls
  useEffect(() => {
    if (controls) {
      clippingBox?.show();
      clippingBox?.enableRotate();
    } else {
      clippingBox?.hide();
      clippingBox?.disableRotate();
    }
  }, [clippingBox, controls]);

  // Update clipping group
  useEffect(() => {
    clippingBox?.clear();
    clippingBox?.addTarget(model);
    for (const target of group) {
      const targetModel = (target.sceneNode as ModelNode).getModel() as Model3D;
      clippingBox?.addTarget(targetModel);
    }
  }, [clippingBox, group, model]);

  // Handle adding a layer to the clipping volume
  const handleToggleLayer = (target: Layer) => {
    if (group.includes(target)) {
      setGroup(prev => prev.filter(layer => layer !== target));
    } else {
      setGroup(prev => [...prev, target]);
    }
  };

  // Handle resetting the clipping volume
  const handleReset = () => {
    clippingBox?.reset();
  };

  const handleMouseMove = (event: MouseEvent<HTMLDivElement>) => {
    setLabelPosition({
      x: event.clientX - 50,
      y: event.clientY,
    });
  };

  return (
    <DraggableMenu
      closeMenu={closeMenu}
      containerStyles={cn(
        'w-[232px]',
        'flex',
        'bg-neutral-100',
        'rounded-2',
        'border-1px',
        'border-solid',
        'border-neutral-400',
        'p-3',
      )}
      disableCloseButton
      onDragEnd={(x, y) => {
        setMenuPosition({
          x,
          y,
        });
      }}
    >
      <div className={cn('flex flex-col w-full')}>
        <div className={cn('flex flex-row items-center justify-between')}>
          <p className={cn('typo-text-small-em text-neutral-800')}>Clipping tools</p>
          <div
            className="i-skand-close cursor-pointer text-3 color-neutral-400"
            onClick={closeMenu}
          />
        </div>

        <div className={cn('bg-neutral-400', 'h-[1px] w-full', 'my-3')} />
        <div className={cn('flex flex-row justify-between align-center')}>
          <button
            className={cn(
              'flex',
              'w-99px',
              'flex-row',
              'items-center',
              'border-1px',
              'border-solid',
              'border-neutral-500',
              'rounded-4px',
              'pt-4px',
              'pb-4px',
              'pl-8px',
              'pr-8px',
              'justify-between',
              controls ? 'bg-primary-400' : 'bg-transparent',
              controls ? 'text-neutral-100' : 'color-neutral-800',
            )}
            onClick={() => setControls(!controls)}
          >
            <Controls3DIcon />
            <p className={cn('typo-text-small')}>3D controls</p>
          </button>
          <Button
            className={cn('w-99px normal-case', 'typo-text-small')}
            filled={enabled ? false : true}
            onClick={() => setEnabled(!enabled)}
            primary={enabled ? false : true}
            size="extraSmall"
          >
            {enabled ? 'Stop Clipping' : 'Start Clipping'}
          </Button>
        </div>

        <div>
          <div
            className={cn('flex', 'flex-row', 'items-center', 'justify-between', 'mt-3', 'mb-2')}
          >
            <p className={cn('typo-text-small text-neutral-800')}>
              Select other layers to clip together
            </p>
            {enabledInfoTooltip && (
              <div className="absolute right--85">
                <Toast
                  clickToDismiss
                  dismiss={() => {}}
                  message="Layer must have visibility on to be listed below."
                  size="l"
                  type="info"
                />
              </div>
            )}

            <span
              className="i-skand-info text-3 text-neutral-600"
              onClick={() => setEnabledInfoTooltip(!enabledInfoTooltip)}
            />
          </div>

          <div className="mb-8px mt-8px w-full">
            <Input label="Search" onChange={setSearchKey} tail={<FindIcon />} value={searchKey} />
          </div>
        </div>

        <div className={cn('overflow-scroll', 'max-h-300px')}>
          {clippable.length === 0 && (
            <Label
              ellipsis={false}
              labelTitle={`Enable other layers to include in the clipping volume.`}
              labelType="info"
              textLength={54}
            />
          )}
          {filtered.map((layer, index) => (
            <div
              className={cn('flex flex-row mt-12px self-start items-center relative')}
              key={`layer-${index}`}
            >
              <Checkbox
                checked={group.includes(layer)}
                disabled={false}
                setToggleCheckbox={() => handleToggleLayer(layer)}
              />
              <p
                className={cn('typo-link-small text-neutral-800 ml-8px cursor-pointer')}
                onMouseEnter={() => setLayerNameTooltip(layer.name.length > 26 ? layer.id : null)}
                onMouseLeave={() => setLayerNameTooltip(null)}
                onMouseMove={handleMouseMove}
              >
                {layer.name.length >= 27 ? layer.name.slice(0, 27) + '...' : layer.name}
              </p>
              {layerNameTooltip === layer.id && (
                <div
                  className={cn('fixed', 'z-1', 'w-[330px]')}
                  style={{
                    top: labelPosition.y - menuPosition.y,
                    left: labelPosition.x - menuPosition.x,
                  }}
                >
                  <Label
                    css="bg-neutral-100 max-w-[330px] py-2"
                    ellipsis={false}
                    labelTitle={layer.name}
                    labelType="default"
                    textLength={layer.name.length}
                  />
                </div>
              )}
            </div>
          ))}
        </div>

        <div className={cn('flex gap-2 flex-col mt-3')}>
          <Button className="w-full" onClick={handleReset} size="s">
            Reset
          </Button>
        </div>
      </div>
    </DraggableMenu>
  );
};
