import { cn } from '@/utils/classname';
import { QuickList, QuickListItemProps } from '@skand/ui';
import { flexRender, useReactTable } from '@tanstack/react-table';
import { HTMLAttributes, useCallback, useRef } from 'react';

export interface TableDenseProps<T> extends HTMLAttributes<HTMLTableElement> {
  table: ReturnType<typeof useReactTable<T>>;
}

export const TableDense = <T,>({ table, className }: TableDenseProps<T>) => {
  const parentRef = useRef<HTMLTableElement>(null);

  const { rows } = table.getRowModel();

  const ListItem = useCallback(
    ({ index }: QuickListItemProps) => {
      const row = rows[index];
      return (
        <tr className="w-full flex gap-2" key={row.id}>
          {row.getVisibleCells().map(cell => (
            <td
              className={cn('py-2 typo-text-s color-neutral-800')}
              key={cell.id}
              style={{ width: cell.column.getSize() }}
            >
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </td>
          ))}
        </tr>
      );
    },
    [rows],
  );

  return (
    <div
      className={cn(rows.length === 0 ? 'h-40px' : 'h-180px', 'w-full overflow-auto')}
      ref={parentRef}
    >
      <table className={cn('w-full grid', className)}>
        <thead className="sticky top-0 z-1 grid bg-neutral-100">
          {table.getHeaderGroups().map(headerGroup => {
            return (
              <tr
                className={cn('b-b-1 b-b-neutral-500 b-b-solid text-left flex gap-2')}
                key={headerGroup.id}
              >
                {headerGroup.headers.map(header => {
                  return (
                    <th
                      className={cn('py-2 uppercase typo-button-xs color-neutral-800')}
                      key={header.id}
                      onClick={header.column.getToggleSortingHandler()}
                      style={{ width: header.getSize() }}
                    >
                      <div className="flex items-center">
                        {header.isPlaceholder
                          ? null
                          : flexRender(header.column.columnDef.header, header.getContext())}
                      </div>
                    </th>
                  );
                })}
              </tr>
            );
          })}
        </thead>

        <tbody>
          <QuickList itemCount={rows.length}>{ListItem}</QuickList>
        </tbody>
      </table>
    </div>
  );
};
